import React, { useState, useCallback, useEffect } from 'react';

import { AuctionReduceBetModal } from '../../../../components/AuctionReduceBetModal';
import { useAuctionContext } from '../../../../context/AuctionContext';

export function AuctionReduceBetModalContainer() {
  const { isAuctionEnabled, isReducingBet, incrementBet, currentBet, formik, resetForm } = useAuctionContext();

  const [open, setOpen] = useState(isReducingBet);

  const handleClose = useCallback(() => {
    setOpen(false);
    resetForm();
  }, [resetForm]);

  useEffect(() => {
    if (isReducingBet) {
      setOpen(true);
    }
  }, [isReducingBet]);

  if (!isAuctionEnabled) {
    return null;
  }

  return (
    <AuctionReduceBetModal
      open={open}
      onClose={handleClose}
      incrementBet={incrementBet}
      formik={formik}
      currentBet={currentBet}
    />
  );
}
