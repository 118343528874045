import * as React from 'react';

import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';

export type TOption = {
  label: string;
  value: boolean | null;
};

interface ISubsidisedMortgageProps {
  value: boolean | null;
  options: TOption[];
  onChange(value: boolean | null): void;
}

export const SubsidisedMortgage: React.FC<ISubsidisedMortgageProps> = ({ options, value, onChange }) => {
  return (
    <Filter id="advancedFilter_is_subsidised_mortgage" label="Ипотечные предложения">
      <RadioButtonGroup options={options} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
