import * as React from 'react';

import { NonEmptyArray, EBalconyType } from '../../../../../JsonQuery';
import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface IBalcony {
  label: string;
  value: EBalconyType | null;
}

const BALCONY_TYPES: IBalcony[] = [
  { label: 'Неважно', value: null },
  { label: 'Балкон', value: EBalconyType.Balcony },
  { label: 'Лоджия', value: EBalconyType.Loggia },
];

interface IBalconyProps {
  value: NonEmptyArray<EBalconyType> | null;
  onChange(value: NonEmptyArray<EBalconyType> | null): void;
}

export const Balcony: React.FC<IBalconyProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_balcony" label="Балкон/Лоджия">
      <CheckboxButtonGroupUnselectable options={BALCONY_TYPES} value={value} onChange={onChange} />
    </Filter>
  );
};
