import { ISummarySortOption } from './types';
import { ESortValue } from '../../../../../repositories/common/json_query';

export const items: ISummarySortOption[] = [
  {
    value: ESortValue.Default,
    label: 'По умолчанию',
  },
  {
    value: ESortValue.Price,
    label: 'По цене (сначала дешевле)',
  },
  {
    value: ESortValue.TotalPriceDesc,
    label: 'По цене (сначала дороже)',
  },
  {
    value: ESortValue.SquarePrice,
    label: 'По цене за м\u00b2 (сначала дешевле)',
  },
  {
    value: ESortValue.SquarePriceDesc,
    label: 'По цене за м\u00b2 (сначала дороже)',
  },
  {
    value: ESortValue.Area,
    label: 'По площади дома',
  },
  {
    value: ESortValue.LandArea,
    label: 'По площади участка',
  },
  {
    value: ESortValue.WalkingTime,
    label: 'По времени до метро',
  },
  {
    value: ESortValue.MKAD,
    label: 'По расстоянию до МКАД',
  },
  {
    value: ESortValue.StreetName,
    label: 'По улице',
  },
  {
    value: ESortValue.CreationDateDesc,
    label: 'По дате добавления (сначала новые)',
  },
  {
    value: ESortValue.CreationDateAsc,
    label: 'По дате добавления (сначала старые)',
  },
];
