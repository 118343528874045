import classNames from 'classnames';
import React from 'react';

import styles from './ContentRow.css';

interface IContentRowProps {
  size?: 'L' | 'M';
}

export const ContentRow: React.FC<React.PropsWithChildren<IContentRowProps>> = ({ children, size }) => {
  return (
    <div
      className={classNames(
        styles['container'],
        size === 'L' && styles['container-l'],
        size === 'M' && styles['container-m'],
      )}
    >
      {children}
    </div>
  );
};

ContentRow.displayName = 'ContentRow';
