import * as React from 'react';

import { ETenant } from '../../../../json_query';
import { ITenantsTag } from '../../../state/tags/definitions/tenants';
import { TagNew } from '../TagNew';

export interface ITenantsTagItemProps {
  tag: ITenantsTag;
  onDeleteClick(): void;
}

const tenantsMap = {
  [ETenant.Any]: 'любой',
  [ETenant.Family]: 'семья',
  [ETenant.Man]: 'мужчина',
  [ETenant.Woman]: 'женщина',
};

export const TenantsTagItem = ({ onDeleteClick, tag }: ITenantsTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>Состав съёмщиков: {tenantsMap[tag.value]}</TagNew>;
};
