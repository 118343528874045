import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackModalOpen } from './tracking';
import { closeChat } from '../../actions/chat';
import { ChatPopup } from '../../components/ChatPopup';
import { IOffer } from '../../offer';
import { selectDate } from '../../selectors/filters/selectDate';
import { selectChat } from '../../selectors/selectChat';

export const ChatPopupContainer: React.VFC = () => {
  const dispatch = useDispatch();

  const { isOpened, offer, position } = useSelector(selectChat);
  const dates = useSelector(selectDate);

  React.useEffect(() => {
    if (isOpened && offer && position) {
      trackModalOpen(offer, position);
    }
  }, [isOpened, offer, position]);

  const onClose = React.useCallback(() => dispatch(closeChat()), [dispatch]);

  if (!isOpened) {
    return null;
  }

  return <ChatPopup offer={offer as IOffer} dates={dates} onClose={onClose} />;
};
