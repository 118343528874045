import React, { memo, useMemo } from 'react';

import { AdFoxBanner } from '../../../../../../components/AdfoxBanner';
import { useSelectIsNewbuildingMartechNewDesignTgbListingExperimentEnabled } from '../../../../../../selectors/experiments/useSelectIsNewbuildingMartechNewDesignTgbListingExperimentEnabled';
import { AdfoxContextData } from '../../../../../../types/adfox/AdfoxContextData';
import { AdfoxContextProvider, PParams } from '../../../../../../utils/AdfoxContext';

const POST_VIEW_ANALYTICS_DATA: AdfoxContextData['postViewAnalyticsData'] = {
  banner: {
    place: 'offersSerp',
    name: 'Listing_native_TGB',
    extra: {
      offerType: 'TGB_banner',
    },
  },
};
const SIZE: AdfoxContextData['size'] = { width: '100%', minHeight: '275px' };
const P_PARAMS_NEWBUILDING: PParams = { p1: 'davxz', p2: 'fkhj' };
const P_PARAMS_SUBURBAN: PParams = { p1: 'crulw', p2: 'fkhj' };

type TGBBannerProps = {
  isNewbuilding: boolean;
  isSuburban: boolean;
  position: number;
};

export const TGBBanner = memo<TGBBannerProps>(function TGBBanner(props) {
  const { isNewbuilding, isSuburban, position } = props;

  /**
   * @todo Удалить переменную с экспериментом
   * @description Данный функционал появился в задаче CD-206850, будет удалён в задаче CD-206973
   */
  const isNewbuildingMartechNewDesignTgbListingExperimentEnabled =
    useSelectIsNewbuildingMartechNewDesignTgbListingExperimentEnabled();

  const pParams = useMemo<PParams>(() => {
    if (isNewbuilding) {
      return P_PARAMS_NEWBUILDING;
    }

    if (isSuburban) {
      return P_PARAMS_SUBURBAN;
    }

    /**
     * @todo Удалить логику с экспериментом
     * @description Данный функционал появился в задаче CD-206850, будет удалён в задаче CD-206973
     * Вынести победившие п параметры в константу P_PARAMS по аналогии с P_PARAMS_NEWBUILDING
     * Тут осторожно, нужно узнать у Сергея Юрикова, какие п параметры нужны для каждой из вертикалей,
     * сейчас не совсем понятно, что точно хотят, тезис пока такой, что когда раскатывать будем, тогда и решим
     */
    return isNewbuildingMartechNewDesignTgbListingExperimentEnabled
      ? { p1: 'ddcfw', p2: 'fkhj' }
      : { p1: 'crulw', p2: 'fkhj' };
  }, [isNewbuilding, isNewbuildingMartechNewDesignTgbListingExperimentEnabled, isSuburban]);

  return (
    <AdfoxContextProvider size={SIZE} pParams={pParams} postViewAnalyticsData={POST_VIEW_ANALYTICS_DATA}>
      <AdFoxBanner position={position} />
    </AdfoxContextProvider>
  );
});
