import * as React from 'react';

import { IKpIdTag } from '../../../state/tags/definitions/kp_id';
import { TagNew } from '../TagNew';

export interface IKpIdTagItemProps {
  tag: IKpIdTag;
  onDeleteClick(): void;
}

export const KpIdTagItem = (props: IKpIdTagItemProps) => {
  return (
    <TagNew onDeleteClick={props.onDeleteClick}>
      Коттеджный посёлок {props.tag.text ? `«${props.tag.text}»` : `№${props.tag.value}`}
    </TagNew>
  );
};
