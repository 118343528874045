import { UIText2 } from '@cian/ui-kit';
import React, { FC } from 'react';

import * as styles from './Transport.css';
import { TransportTypes } from '../../../../../../../../../types/TransportTypes';
import { TransportIcon } from '../TransportIcon';

export type TransportProps = {
  name: string;
  iconColor: string | null | undefined;
  remoteness: string | null | undefined;
  iconType: TransportTypes | null | undefined;
};

export const Transport: FC<TransportProps> = props => {
  const { iconColor, name, remoteness, iconType } = props;

  return (
    <div className={styles['container']} data-testid="metro_block">
      {!!iconColor && !!iconType && (
        <div className={styles['icon']}>
          <TransportIcon iconColor={iconColor} iconType={iconType} />
        </div>
      )}
      <UIText2 display="inline">{name}</UIText2>
      <span className={styles['remoteness']}>
        <UIText2 color="gray60_100" display="inline">
          {remoteness}
        </UIText2>
      </span>
    </div>
  );
};
