import * as React from 'react';

import { EStarRating, getTermsValue } from '../../../../../JsonQuery';
import { StarRating } from '../../../components/filters/StarRating';
import { IFilterComponentProps } from '../../../types/filters';
import { TAvailableModifierActions } from '../../../types/modifier';
import { useSearchResultsTooltipOpen } from '../../../utils/searchResultsTooltipOpen';
import { useContext } from '../../../utils/useContext';

export const StarRatingContainer: React.FC<IFilterComponentProps> = ({ filterKey, open, onOpen, onClose }) => {
  const { jsonQuery, onChange, onApply, onApplyClick } = useContext();
  const starRating = getTermsValue('star_rating')(jsonQuery);
  const action: TAvailableModifierActions = 'setStarRating';
  const openTooltip = useSearchResultsTooltipOpen({ filterKey, actions: [action] });

  const handleApply = React.useCallback(() => {
    if (onApplyClick) {
      onApplyClick({ source: 'tooltip' });
    }

    onApply();

    if (onClose) {
      onClose();
    }
  }, [onApply, onApplyClick, onClose]);

  const handleChange = React.useCallback(
    (starRating: EStarRating[] | null) => {
      onChange({ action, arguments: [starRating] });
    },
    [onChange],
  );

  return (
    <StarRating
      open={open}
      label="Количество звёзд"
      value={starRating}
      onChange={handleChange}
      openTooltip={openTooltip}
      onApply={handleApply}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
};
