import { clone } from 'ramda';

import { getDateIndex } from './getDateIndex';
import { getRangeDate } from './getRangeDate';
import { getRangeIndexes } from './getRangeIndexes';
import { updateCalendarDay } from './updateCalendarDay';
import { ICalendarMonth } from '../types';

interface IParams {
  calendar: Array<ICalendarMonth>;
  from: Date | null;
  to: Date | null;
}

export const updateCalendarData = (params: IParams): Array<ICalendarMonth> => {
  const { calendar, from, to } = params;

  const fromDate = getRangeDate(from);
  const toDate = getRangeDate(to);

  const calendarData = clone(calendar);

  if (fromDate) {
    const fromIndex = getDateIndex({ calendar: calendarData, date: fromDate });

    const type = toDate ? 'range_start' : 'selected';

    updateCalendarDay({ calendar: calendarData, index: fromIndex, type });

    if (toDate) {
      const toIndex = getDateIndex({ calendar: calendarData, date: toDate });

      updateCalendarDay({ calendar: calendarData, index: toIndex, type: 'range_end' });

      const inRangeIndexes = getRangeIndexes({ calendar: calendarData, fromIndex, toIndex });

      inRangeIndexes.forEach(index => {
        updateCalendarDay({ calendar: calendarData, index, type: 'in_range' });
      });
    }
  }

  return calendarData;
};
