import * as React from 'react';

import { ERepair } from '../../../../json_query';
import { IRepairTag } from '../../../state/tags/definitions/repair';
import { TagNew } from '../TagNew';

export interface IRepairTagItemProps {
  tag: IRepairTag;
  onDeleteClick(): void;
}

const materialsMap = {
  [ERepair.Cosmetic]: 'Косметический ремонт',
  [ERepair.Design]: 'Дизайнерский ремонт',
  [ERepair.Euro]: 'Евроремонт',
  [ERepair.Without]: 'Без ремонта',
};

export const RepairTagItem = (props: IRepairTagItemProps) => {
  return <TagNew onDeleteClick={props.onDeleteClick}>{materialsMap[props.tag.value]}</TagNew>;
};
