import { ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import { ComplaintModalContent } from '../ComplaintModalContent';

export interface IComplaintProps {
  openModal: boolean;
  onCloseModal(): void;
}

export const ComplaintModal: React.FC<IComplaintProps> = props => {
  const { onCloseModal, openModal } = props;

  return (
    <ModalWindow
      size="XS"
      width={600}
      open={openModal}
      onClose={onCloseModal}
      content={openModal && <ComplaintModalContent onCloseModal={onCloseModal} />}
    />
  );
};
