import * as React from 'react';

import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';

interface IFlatShare {
  label: string;
  value: number | null;
}

const FLAT_SHARE: IFlatShare[] = [
  { label: 'Неважно', value: null },
  { label: 'Не показывать', value: 2 },
  { label: 'Только доли', value: 1 },
];

export interface IFlatShareProps {
  value: number | null;
  onChange(value: number | null): void;
}

export const FlatShare: React.FC<IFlatShareProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_flatShare" label="Доли">
      <RadioButtonGroup spliced={false} options={FLAT_SHARE} value={value} onChange={onChange} />
    </Filter>
  );
};
