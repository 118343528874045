import IMask from 'imask';

import { KeysOfUnion, TInputProps, IMaskedInputOwnProps } from './types';

export const MASK_PROPS: KeysOfUnion<IMask.AnyMaskedOptions>[] = [
  'autofix',
  'blocks',
  'commit',
  'definitions',
  'format',
  'lazy',
  'mapToRadix',
  'mask',
  'max',
  'min',
  'normalizeZeros',
  'overwrite',
  'padFractionalZeros',
  'parse',
  'pattern',
  'placeholderChar',
  'prepare',
  'radix',
  'scale',
  'signed',
  'thousandsSeparator',
  'validate',
];

export const OWN_PROPS: (keyof Omit<IMaskedInputOwnProps, keyof TInputProps>)[] = ['value', 'onAccept', 'onComplete'];
