import { ca } from '@cian/analytics';

export const trackSuburbanMultiOfferClick = (id: number, label: string, position: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'to_multioffer_listing',
    label,
    products: [
      {
        id,
        offerType: 'KP',
        position,
      },
    ],
  });
};
