import { IAppState } from '../../common/state';

export function isNewbuildingSelector(state: IAppState) {
  return state.isNewbuildingsOnly;
}

export function isCommonFilterSelector(state: IAppState) {
  const { building_status, _type } = state.results.jsonQuery;

  return !building_status && _type === 'flatsale';
}
