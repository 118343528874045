import * as React from 'react';

import { getRangeValue } from '../../../../../JsonQuery';
import { SuburbanArea } from '../../../components/filters/SuburbanArea';
import { IFilterComponentProps } from '../../../types/filters';
import { useSearchResultsTooltipOpen } from '../../../utils/searchResultsTooltipOpen';
import { useContext } from '../../../utils/useContext';
import { useOfferType } from '../../../utils/useOfferType';

export const SuburbanAreaContainer: React.FC<IFilterComponentProps> = ({ filterKey, open, onOpen, onClose }) => {
  const { jsonQuery, jsonQueryCount, jsonQueryCountRefreshing, onChange, onApply, onApplyClick } = useContext();
  const offerType = useOfferType();
  const houseArea = getRangeValue('total_area')(jsonQuery);
  const landArea = getRangeValue('site')(jsonQuery);
  const openTooltip = useSearchResultsTooltipOpen({
    filterKey,
    actions: ['setAreaMin', 'setAreaMax', 'setLandAreaMin', 'setLandAreaMax'],
  });

  /* istanbul ignore next */
  const handleApply = React.useCallback(() => {
    if (onApplyClick) {
      onApplyClick({ source: 'tooltip' });
    }

    onApply();

    if (onClose) {
      onClose();
    }
  }, [onApply, onApplyClick, onClose]);

  const handleHouseAreaMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setAreaMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleHouseAreaMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setAreaMax', arguments: [lte] });
    },
    [onChange],
  );

  const handleLandAreaMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setLandAreaMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleLandAreaMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setLandAreaMax', arguments: [lte] });
    },
    [onChange],
  );

  return (
    <SuburbanArea
      offerType={offerType}
      houseArea={houseArea}
      landArea={landArea}
      onHouseAreaMinChange={handleHouseAreaMinChange}
      onHouseAreaMaxChange={handleHouseAreaMaxChange}
      onLandAreaMinChange={handleLandAreaMinChange}
      onLandAreaMaxChange={handleLandAreaMaxChange}
      open={open}
      offersCount={jsonQueryCount}
      offersCountLoading={jsonQueryCountRefreshing}
      openTooltip={openTooltip}
      onApply={handleApply}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
};
