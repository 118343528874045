import { ca } from '@cian/analytics';

/* istanbul ignore next */
export function trackPrintOffersClicked() {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'print_ads',
  });
}

/** https://conf.cian.tech/pages/viewpage.action?pageId=1342865981 */

/** 1. Клик на кнопку "Сохранить эксель" */
export const trackDownloadExcelBtnClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'Click',
    label: 'DownloadXls_sopr',
  });
};

/** 2. Отображение модалки "Скачать эксель" для поискуна */
export const trackSearcherModalShow = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'Open',
    label: 'DownloadXlsModalSearcher_sopr',
  });
};

/** 3. Отображение модалки "Скачать эксель" для агента */
export const trackRealtorModalShow = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'Open',
    label: 'DownloadXlsModalAgent_sopr',
  });
};

/** 4. Клик на кнопку Подробнее (переход в b2b лендинг) */
/* istanbul ignore next */
export const trackGoToB2bClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'Click',
    label: 'GoToB2BLanding',
  });
};

/** 5. Клик на кнопку Сохранить файл в Excel внутри модалки (поискун/агент) */
export const trackDownloadExcelInModalClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'Click',
    label: 'DownloadXlsNow_sopr',
  });
};

/** 6. Не удаспешное скачивание Excel (уперлись в лимит) */
export const trackDownloadExcelFailLimit = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'Click',
    label: 'DownloadXlsFailLimit_sopr',
  });
};

/** 7. Клик на кнопку "В профпоиск" внутри модалки скачивания эксель для агента */
/* istanbul ignore next */
export const trackGoToProfSearchClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'Click',
    label: 'GoToProffSearch_sopr',
  });
};
