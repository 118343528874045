export interface INewbuildingPromoBuilder {
  cardUrl: string;
  logoUrl?: string | null;
  name: string;
  realtyUserId: number;
}

export interface INewbuildingPromoBuilderNewbuilding {
  id: number;
  name: string;
  url: string;
  address?: string | null;
}

export interface INewbuildingPromoBuilderUnderground {
  cityType?: ECityType | null;
  lineColor?: string | null;
  name?: string | null;
  timeDisplay: string;
  transportType: ETransportType;
}

export interface INewbuildingPromoBuilderOffer {
  id: number;
  imageUrl: string;
  cardUrl: string;
  description: string;
  priceDisplay: string;
  newbuilding?: INewbuildingPromoBuilderNewbuilding | null;
  underground?: INewbuildingPromoBuilderUnderground | null;
}

export interface INewbuildingPromoBuilderOffers {
  offers: INewbuildingPromoBuilderOffer[];
  allOffersUrl: string;
  needShowAdfoxBlock: boolean;
  builder: INewbuildingPromoBuilder;
}

export enum ECityType {
  /** Екатеринбург **/
  Ekb = 'Ekb',
  /** Казань **/
  Kazan = 'Kazan',
  /** Москва **/
  Msk = 'Msk',
  /** Нижний новгород **/
  Nizhniy = 'Nizhniy',
  /** Новосибирск **/
  Novosib = 'Novosib',
  /** Самара **/
  Samara = 'Samara',
  /** Санкт-Петербург **/
  Spb = 'Spb',
}
export enum ETransportType {
  /** Транспортом **/
  Transport = 'transport',
  /** Пешком **/
  Walk = 'walk',
}
