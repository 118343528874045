import { IJsonQueryUnderground } from '../../../../../json_query';
import { ensureSingleTagExists } from '../../helpers';
import { ITagDefinition, TTag } from '../../index';

const IGeoUndergroundTagType = 'geo-underground';

export interface IGeoUndergroundTag {
  type: 'geo-underground';
  value: number;
  text: string;
  color?: string;
  releaseYear?: number;
}

export function isGeoUndergroundTag(tag: TTag): tag is IGeoUndergroundTag {
  return tag.type === IGeoUndergroundTagType;
}

export const geoUndergroundTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery, tagsData) => {
    const values =
      jsonQuery.geo &&
      (jsonQuery.geo.value.filter(geo => geo.type === 'underground') as IJsonQueryUnderground[]).map(geo => geo.id);

    let nextTags = tags.filter(
      tag => tag.type !== IGeoUndergroundTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce((result, value) => {
        const tagData = tagsData.undergrounds[value];

        return ensureSingleTagExists(
          result,
          (tag: TTag): tag is IGeoUndergroundTag => isGeoUndergroundTag(tag) && tag.value === value,
          () => ({
            type: IGeoUndergroundTagType,
            value,
            color: tagData && tagData.color,
            text: (tagData && tagData.name) || 'Неизвестная станция',
            releaseYear: tagData && tagData.releaseYear,
          }),
        );
      }, nextTags);
    }

    return nextTags;
  },
};
