import { ResponseError } from '../../errors';
import { prepareSimilarNewobjects } from '../../mappers/prepareSimilarNewobjects';
import {
  fetchGetNewobjectsBuilder,
  TGetNewobjectsBuilderResponse,
} from '../../repositories/monolith-python/v1/get-newobjects-builder';
import { IApplicationContext } from '../../types/applicationContext';
import { ISimilarNewobject } from '../../types/similarNewobjects';

interface IParameters {
  newobjectId: number;
}

export async function fetchNewobjectsBuilder(
  context: IApplicationContext,
  parameters: IParameters,
): Promise<ISimilarNewobject[] | null> {
  const { httpApi, logger } = context;

  try {
    const newobjectsBuilderResponse: TGetNewobjectsBuilderResponse = await fetchGetNewobjectsBuilder({
      httpApi,
      parameters,
    });

    if (newobjectsBuilderResponse.statusCode !== 200) {
      throw new ResponseError({
        domain: 'fetchNewobjectsBuilder',
        message: newobjectsBuilderResponse.response.message,
        details: { errors: newobjectsBuilderResponse.response.errors },
      });
    }

    return prepareSimilarNewobjects(newobjectsBuilderResponse.response);
  } catch (ex) {
    logger.warning(ex, {
      degradation: 'Similar newobjects from builder',
    });

    return null;
  }
}
