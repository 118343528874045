import * as React from 'react';

import { getTermValue } from '../../../../../JsonQuery';
import { Demolition } from '../../../components/advancedFilters/Demolition';
import { useContext } from '../../../utils/useContext';

export const DemolitionContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const demolition = getTermValue('demolished_in_moscow_programm')(jsonQuery);

  const handleChange = React.useCallback(
    (demolition: boolean | null) => {
      onChange({ action: 'setDemolition', arguments: [demolition] });
    },
    [onChange],
  );

  return <Demolition value={demolition} onChange={handleChange} />;
};
