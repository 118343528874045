import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { useDeviceType } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackDealSaleBannerClick, trackDealSaleBannerShow } from './tracking';
import { configSelector } from '../../../selectors/configSelector';
import { DealSaleBanner } from '../../components/DealSaleBanner';

/**
 * Контейнер баннера "Циан.Сделка" для смешанной выдачи.
 */
export const DealSaleBannerContainer = () => {
  const config = useSelector(configSelector);
  const url = config.get<string>('deal-sale-promo-frontend.baseUrl') || '';
  const isMobile = useDeviceType() === 'phone';

  return (
    <ActionAfterViewObserver callback={trackDealSaleBannerShow} percentVisible={0.8} triggerOnce>
      <DealSaleBanner isMobile={isMobile} url={url} onClick={trackDealSaleBannerClick} />
    </ActionAfterViewObserver>
  );
};

DealSaleBannerContainer.displayName = 'DealSaleBannerContainer';
