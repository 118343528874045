import { ICalendarMonth, TCalendarMonths } from '../../types';

export interface ICalendarContainerProps {
  minDate: Date;
  maxDate: Date;
  fromValue: Date | null;
  toValue: Date | null;
  onChange(from: Date | undefined, to: Date | undefined): void;
}

export interface ICalendarState {
  initialCalendar: TCalendarMonths;
  calendar: ICalendarMonth[];
  from: Date | null;
  to: Date | null;
  currentPage: number;
  selecting: boolean;
  updated: boolean;
  isNextPageAvailable: boolean;
  isPrevPageAvailable: boolean;
}

export enum ECalendarActionTypes {
  ClickOnDate = 'calendar/click-on-date',
  NextPage = 'calendar/next-page',
  PrevPage = 'calendar/prev-page',
  ResetUpdated = 'calendar/reset-updated',
}
