import { ESuburbanWCType } from '../../../../json_query';
import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const ISuburbanWCTypeTagType = 'advanced-suburbanWC';

export interface ISuburbanWCTypeTag {
  type: 'advanced-suburbanWC';
  value: ESuburbanWCType;
}

export const isSuburbanWCTypeTag = (tag: TTag): tag is ISuburbanWCTypeTag => tag.type === ISuburbanWCTypeTagType;

export const suburbanWCTypeTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.wc_site && jsonQuery.wc_site.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isSuburbanWCTypeTag, () => ({ type: ISuburbanWCTypeTagType, value }));
    } else {
      return ensureTagNotExists(tags, isSuburbanWCTypeTag);
    }
  },
};
