import { IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';

export interface IHasFlatTourBookingChangedAction {
  type: 'filters/has_flat_tour_booking/HAS_FLAT_TOUR_BOOKING_CHANGED';
  value: boolean | undefined;
}

export function changeHasFlatTourBooking(value: boolean | undefined): IHasFlatTourBookingChangedAction {
  return {
    type: 'filters/has_flat_tour_booking/HAS_FLAT_TOUR_BOOKING_CHANGED',
    value,
  };
}

export function hasFlatTourBookingReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case 'filters/has_flat_tour_booking/HAS_FLAT_TOUR_BOOKING_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setHasFlatTourBooking(action.value || undefined),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'has-flat-tour-booking') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              has_flat_tour_booking: undefined,
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
}
