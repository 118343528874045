import { ca } from '@cian/analytics';

export const trackVillagesInView = (page: number, kpIds: number[]) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'homes_pro_tariff',
    action: 'search_results',
    label: 'show_catalog',
    page: {
      listingType: 'list',
      pageNumber: page,
      extra: {
        kp_PRO_ids: kpIds,
      },
    },
    useLastProducts: true,
  });
};

export const trackVillageClick = (id: number, position: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'homes_pro_tariff',
    action: 'search_results',
    label: 'click_catalog',
    products: [
      {
        offerType: 'KP',
        id,
        position,
      },
    ],
  });
};

export const trackShowAllVillagesClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'homes_pro_tariff',
    action: 'search_results',
    label: 'click_full_catalog',
  });
};
