import * as React from 'react';

import { IFloorTag } from '../../../state/tags/definitions/floor';
import { TagNew } from '../TagNew';

export interface IFloorTagItemProps {
  tag: IFloorTag;
  onDeleteClick(): void;
}

export const FloorTagItem = ({ tag, onDeleteClick }: IFloorTagItemProps) => {
  const text = [];

  if (tag.min !== undefined) {
    text.push(`от ${tag.min}`);
  }

  if (tag.max !== undefined) {
    text.push(`до ${tag.max}`);
  }

  return <TagNew onDeleteClick={onDeleteClick}>Этаж {text.join(' ')}</TagNew>;
};
