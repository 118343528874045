import * as React from 'react';

import * as styles from './Pagination.css';
import { PaginationButton } from './PaginationButton';
import { IPaginationItem } from '../../../types/pagination';

interface IPaginationProps {
  prevButton?: IPaginationItem;
  nextButton?: IPaginationItem;
  onClick(e: React.SyntheticEvent<HTMLAnchorElement>, page: number): void;
  pages: IPaginationItem[];
}

export function Pagination({ onClick, pages, prevButton, nextButton }: IPaginationProps) {
  return (
    <nav className={styles['pagination']}>
      {prevButton && <PaginationButton {...prevButton} onClick={onClick} />}
      <ul className={styles['pages-list']}>
        {pages.map(page => (
          <li className={styles['page']} key={page.page}>
            <PaginationButton {...page} onClick={onClick} />
          </li>
        ))}
      </ul>
      {nextButton && <PaginationButton {...nextButton} onClick={onClick} />}
    </nav>
  );
}
