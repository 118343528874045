import { pathOr } from 'ramda';
import * as React from 'react';

import { Layout } from '../../../components/advancedFilters/Layout';
import { ELayoutType } from '../../../types/jsonQuery';
import { useContext } from '../../../utils/useContext';
import { useOfferType } from '../../../utils/useOfferType';

export const LayoutContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const offerType = useOfferType();
  const layout = pathOr<ELayoutType | null>(null, ['room_type', 'value'], jsonQuery);
  const withLayout = pathOr<boolean | null>(null, ['with_layout', 'value'], jsonQuery);

  const handleLayoutTypeChange = React.useCallback(
    (layout: ELayoutType | null) => {
      onChange({ action: 'setLayoutType', arguments: [layout] });
    },
    [onChange],
  );

  const handleWithLayoutChange = React.useCallback(
    (withLayout: boolean | null) => {
      onChange({ action: 'setWithLayout', arguments: [withLayout] });
    },
    [onChange],
  );

  return (
    <Layout
      offerType={offerType}
      layoutType={layout}
      withLayout={withLayout}
      onLayoutTypeChange={handleLayoutTypeChange}
      onWithLayoutChange={handleWithLayoutChange}
    />
  );
};
