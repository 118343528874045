import { ca } from '@cian/analytics';

export function trackGoToKPCardFromListingClick(kpId: number) {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'goto_CardKP',
    label: '',
    oid: kpId,
  });
}
