import { CheckboxButtonGroup, IOption as IUiKitOption } from '@cian/ui-kit';
import * as React from 'react';

type NonEmptyArray<T> = [T, ...T[]];

type TBaseCheckboxButtonGroupProps = Omit<
  React.ComponentPropsWithoutRef<typeof CheckboxButtonGroup>,
  'options' | 'value' | 'onChange'
>;
type TOptionValue = string | number;
type TValue = NonEmptyArray<TOptionValue> | null;

interface IOption {
  value: TOptionValue | null;
  label: string | number;
}

export interface ICheckboxButtonGroupProps extends TBaseCheckboxButtonGroupProps {
  options: IOption[];
  value: TValue;
  onChange(value: TValue): void;
}

/**
 * Обертка над китовым CheckboxButtonGroup
 * Для поддержки null и boolean в качестве значения
 */
export function CheckboxButtonGroupUnselectable(props: ICheckboxButtonGroupProps) {
  const { onChange, options: optionsProp, value: valueProp } = props;
  const { options, optionsMap } = React.useMemo(() => {
    return optionsProp.reduce(
      (result, item) => {
        const value = String(item.value);

        result.options.push({
          ...item,
          value,
        });

        result.optionsMap[value] = item.value;

        return result;
      },
      {
        options: [] as IUiKitOption[],
        optionsMap: {} as { [value: string]: TOptionValue | null },
      },
    );
  }, [optionsProp]);
  const value = React.useMemo(() => {
    if (valueProp === null) {
      return [String(null)];
    }

    return valueProp.map(String);
  }, [valueProp]);

  const isNullValue = valueProp === null;
  const handleChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, value: string[]) => {
      const unmappedValue = value.map(v => optionsMap[v]);

      function getNextValue(): TValue | null {
        if (isNullValue) {
          if (unmappedValue.length > 1) {
            return unmappedValue.filter(value => value !== null) as NonEmptyArray<TOptionValue>;
          }

          return null;
        } else {
          if (unmappedValue.indexOf(null) !== -1) {
            return null;
          }

          return unmappedValue as NonEmptyArray<TOptionValue>;
        }
      }

      onChange(getNextValue());
    },
    [onChange, optionsMap, isNullValue],
  );

  return <CheckboxButtonGroup {...props} options={options} value={value} onChange={handleChange} />;
}
