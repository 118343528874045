import * as React from 'react';

import { IMultiIdTag } from '../../../state/tags/definitions/multi_id';
import { TagNew } from '../TagNew';

export interface IMultiIdTagItemProps {
  tag: IMultiIdTag;
  onDeleteClick(): void;
}

export const MultiIdTagItem = (props: IMultiIdTagItemProps) => {
  return <TagNew onDeleteClick={props.onDeleteClick}>Мультиобъявление №{props.tag.value}</TagNew>;
};
