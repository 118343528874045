import { pathOr } from 'ramda';
import * as React from 'react';

import { ExcludeWords } from '../../../components/advancedFilters/ExcludeWords';
import { useContext } from '../../../utils/useContext';

export const ExcludeWordsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const excludeWords = pathOr<string[] | null>(null, ['description_exclude', 'value'], jsonQuery);

  const handleChange = React.useCallback(
    (excludeWords: string[] | null) => {
      onChange({ action: 'setExcludeWords', arguments: [excludeWords] });
    },
    [onChange],
  );

  return <ExcludeWords value={excludeWords} onChange={handleChange} />;
};
