import * as React from 'react';

import { IPhoneTag } from '../../../state/tags/definitions/phone';
import { TagNew } from '../TagNew';

export interface IPhoneTagItemProps {
  tag: IPhoneTag;
  onDeleteClick(): void;
}

export const PhoneTagItem = ({ onDeleteClick, tag }: IPhoneTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>Телефон {tag.value}</TagNew>;
};
