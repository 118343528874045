import { makeContextModule } from '@cian/react-utils';
import { getNearestUpperMultiple, keepValueInRange } from '@cian/utils';
import { useFormik } from 'formik';
import React, { FC, useMemo, useState, useCallback } from 'react';

import { auctionBetValidator } from './utils/auctionBetValidator';
import { getAuctionMaxBet } from './utils/getAuctionMaxBet';
import { getAuctionMinBet } from './utils/getAuctionMinBet';
import { getInitialBet } from './utils/getInitialBet';
import { getTooltipView } from './utils/getTooltipView';
import { trackAuctionChangeBetClick, trackAuctionHint } from './utils/tracking';
import { updateBet } from './utils/updateBet';
import { useApplicationContext } from '../../../../../shared/utils/applicationContext';
import { EAuctionTooltipView, IBetFormValues, TAuctionContext } from '../../types/auction';
import { IOfferCardFragmentProps } from '../../types/offer-card';

export const { Context: AuctionContext, useContext: useAuctionContext } = makeContextModule<TAuctionContext>({
  contextName: 'AuctionContext',
});

export const AuctionContextProvider: FC<React.PropsWithChildren<IOfferCardFragmentProps>> = ({
  children,
  ...props
}) => {
  const ctx = useApplicationContext();
  const { offer, maxAuctionService, maxAuctionBet } = props;

  // Максимальная ставка на аукционе
  const auctionMaxBet = useMemo(() => getAuctionMaxBet(props, ctx), [props, ctx]);
  // Минимальная ставка на аукционе
  const auctionMinBet = useMemo(() => getAuctionMinBet(props, ctx), [props, ctx]);
  // Шаг ставки на аукционе
  const stepBet = useMemo(() => offer.auction?.stepBet, [offer]);
  // Валидация кратности ставок шагу
  const validateStep = ctx.config.get<boolean>('validateStep') || false;
  // принадлежность объявления с аукционом пользователю
  const isPersonalBet = useMemo(() => Boolean(offer.auction?.isOwn && offer.auction.isEditable), [offer]);
  // принадлежность объявления с аукционом конкуренту
  const isConcurrentBet = useMemo(() => Boolean(offer.isAuction && offer.auction && !offer.auction.isOwn), [offer]);
  // нужно ли показывать блок с аукционом
  const isAuctionEnabled = useMemo(() => isPersonalBet || isConcurrentBet, [isPersonalBet, isConcurrentBet]);
  // текущая ставка объявления
  const [currentBet, setCurrentBet] = useState(offer.auction?.currentBet || undefined);
  // следующая ставка объявления
  const [nextBet, setNextBet] = useState(offer.auction?.nextBet || undefined);
  // дата начала работы следующей ставки
  const [applyNewBetDate, setApplyNewBetDate] = useState(offer.auction?.applyNewBetDate || undefined);
  // при обновлении, ставка была уменьшена
  const [isReducingBet, setIsReducingBet] = useState(false);
  // текущая вьюха тултипа аукционов
  const [tooltipView, setTooltipView] = useState(getTooltipView(offer, isPersonalBet, maxAuctionService));
  // балы ликвидности
  const liquidityPoints = offer.liquidityPoints;

  const formik = useFormik<IBetFormValues>({
    validate(values: IBetFormValues) {
      const betError = auctionBetValidator(values.bet, auctionMinBet || stepBet || 0, auctionMaxBet, stepBet || 1, {
        isZeroValid: true,
        validateStep,
      });

      return betError ? { bet: betError.message } : undefined;
    },
    async onSubmit({ bet }: IBetFormValues) {
      const reducingBet = Boolean(currentBet && bet < currentBet);

      setIsReducingBet(reducingBet);

      trackAuctionHint('up_search', 'click', offer);

      // в случае понижения ставки не обновляем ее при первом вызове чтобы показать модалку с подтверждением
      // при повторном вызове обновляем ставку
      if (!reducingBet || (reducingBet && reducingBet === isReducingBet)) {
        const { nextBet, applyDate, currentBet } = await updateBet(
          {
            bet,
            offer,
            isReducing: reducingBet,
          },
          ctx,
        );

        if (nextBet) {
          setNextBet(nextBet);
        }
        if (currentBet) {
          setCurrentBet(currentBet);
        }
        if (reducingBet && applyDate) {
          setApplyNewBetDate(applyDate);
        }
      }
    },
    initialValues: {
      bet: getInitialBet(isPersonalBet ? maxAuctionBet : 0, auctionMaxBet, validateStep, stepBet),
    },
  });

  const incrementBet = useCallback(() => {
    const { bet } = formik.values;
    const newBet = validateStep ? getNearestUpperMultiple(bet + 1, stepBet || 1) || 0 : bet + 1;
    formik.setFieldValue('bet', keepValueInRange(newBet, 0, auctionMaxBet));
    trackAuctionChangeBetClick(offer);
  }, [auctionMaxBet, formik, offer, stepBet, validateStep]);

  const resetForm = useCallback(() => {
    formik.resetForm({
      values: {
        bet: getInitialBet(isPersonalBet ? maxAuctionBet : 0, auctionMaxBet, validateStep, stepBet),
      },
    });

    setIsReducingBet(false);
  }, [auctionMaxBet, formik, isPersonalBet, maxAuctionBet, stepBet, validateStep]);

  const context = useMemo(
    () => ({
      setTooltipView(view?: EAuctionTooltipView) {
        setTooltipView(view || getTooltipView(offer, isPersonalBet, maxAuctionService));
      },
      incrementBet,
      resetForm,
      isReducingBet,
      isPersonalBet,
      isConcurrentBet,
      formik,
      tooltipView,
      applyNewBetDate,
      auctionMaxBet,
      auctionMinBet,
      currentBet,
      isAuctionEnabled,
      nextBet,
      liquidityPoints,
    }),
    [
      incrementBet,
      resetForm,
      isReducingBet,
      isPersonalBet,
      isConcurrentBet,
      formik,
      tooltipView,
      applyNewBetDate,
      auctionMaxBet,
      auctionMinBet,
      currentBet,
      isAuctionEnabled,
      nextBet,
      liquidityPoints,
      maxAuctionService,
      offer,
    ],
  ) as TAuctionContext;

  return <AuctionContext.Provider value={context}>{children}</AuctionContext.Provider>;
};
