import { IAppState } from '../../common/state';

export const getIsPageBrokerVisible = (state: IAppState) => {
  const partnerRewardsRegistrationStatus = state.newbuildingBrokerCommon?.partnerRewardsRegistrationStatus;
  const isCorrectPartnerRewardsRegistrationStatus =
    partnerRewardsRegistrationStatus === 'available' ||
    partnerRewardsRegistrationStatus === 'connected' ||
    partnerRewardsRegistrationStatus === 'inProgress';

  return isCorrectPartnerRewardsRegistrationStatus;
};
