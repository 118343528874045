import * as React from 'react';

import { MultiSelectDeferButton } from './internal/MultiSelectDeferButton';
import { MultiSelectDesktopOption } from './internal/MultiSelectDesktopOption';
import * as styles from './styles.css';
import { ensureChildrenVisibility, SelectDropdown } from '../internal';
import { IOption, ISelectActions, TOptionValue } from '../types';

interface IMultiSelectDesktopDropdownProps {
  open: boolean;
  defer?: boolean;
  options: IOption[];
  onKeyDown(e: React.KeyboardEvent): void;
  value?: TOptionValue[] | null;
  name?: string;
  focusedIndex: number;
  actions: ISelectActions;
  onComplete(): void;
}

export function MultiSelectDesktopDropdown(props: IMultiSelectDesktopDropdownProps) {
  const { open, value, options, name, focusedIndex, actions, onKeyDown, onComplete } = props;
  const optionsContainerRef = React.useRef<HTMLDivElement>(null);
  const focusedOptionRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const optionsContainer = optionsContainerRef.current;
    const focusedOption = focusedOptionRef.current;

    if (open && optionsContainer && focusedOption) {
      ensureChildrenVisibility(optionsContainer, focusedOption);
      focusedOption.focus();
    }
  }, [open, focusedIndex]);

  const bindHandleOptionClickIndex = (index: number) => () => {
    actions.toggleOptionState(index);
  };

  const bindHandleOptionMouseMoveIndex = (index: number) => () => {
    if (focusedIndex !== index) {
      actions.setFocusedIndex(index);
    }
  };

  return (
    <SelectDropdown open={open} onClose={actions.closeDropdown}>
      <div ref={optionsContainerRef} className={styles['checkbox-group']} role="listbox" onKeyDown={onKeyDown}>
        {options.map((option, index) => {
          const optionValue = option.value;
          const key = String(optionValue);
          const focused = index === focusedIndex;
          const selected = (value || []).includes(optionValue);

          return (
            <MultiSelectDesktopOption
              ref={focused ? focusedOptionRef : undefined}
              name={name}
              key={key}
              value={key}
              focused={focused}
              selected={selected}
              label={option.label}
              description={option.description}
              rightAdornment={option.rightAdornment}
              onClick={bindHandleOptionClickIndex(index)}
              onMouseMove={bindHandleOptionMouseMoveIndex(index)}
            />
          );
        })}
      </div>
      {props.defer && <MultiSelectDeferButton onComplete={onComplete} actions={actions} />}
    </SelectDropdown>
  );
}
