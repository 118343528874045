import { useEventCallback } from '@cian/react-utils';
import { useMemo, useRef } from 'react';

import { TListElements } from '../../../types/regionalDistricts';
import { scrollToElement } from '../utils/scrollToElement';

/**
 * Подскролливает к элементу списка по айди
 */
export const useScrollToListElementById = () => {
  const listElementsRefs = useRef<TListElements>({});
  const listRef = useRef<HTMLDivElement>(null);

  const scrollToListElementById = useEventCallback((id: number) => {
    const key = String(id);

    const listElement = listElementsRefs.current[key];

    scrollToElement(listRef.current, listElement);
  });

  return useMemo(
    () => ({ scrollToListElementById, listRefs: { listElementsRefs, listRef } }),
    [scrollToListElementById],
  );
};
