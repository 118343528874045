import { Input } from '@cian/ui-kit';
import * as React from 'react';

import { onKeyDown } from '../../utils/keyboard';
import { InputError } from '../InputError';

interface IPhoneInputProps {
  value: string;
  error: string;
  changeValue(value: string): void;
}

export function PhoneInput({ value, error, changeValue }: IPhoneInputProps) {
  const handleChangeValue = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const newValue = value.replace(/[^\d]/g, '');

    if (newValue.length > 10) {
      return;
    }

    changeValue(newValue);
  };

  return (
    <div>
      <Input
        type="text"
        size="XS"
        leftAdornment="+7"
        value={value}
        invalid={!!error}
        onKeyDown={onKeyDown}
        onChange={handleChangeValue}
      />
      <InputError error={error} />
    </div>
  );
}
