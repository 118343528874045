import { setTerm } from '../helpers';
import { JsonQuery } from '../jsonQuery';
import { ERentTime, TJsonQuery } from '../types';

export const createDefaultHotelsJsonQuery = (jsonQuery: TJsonQuery) => {
  const { dates, beds_count, region, price } = jsonQuery;

  let nextJsonQuery = new JsonQuery({
    _type: 'flatrent',
    engine_version: { type: 'term', value: 2 },
  }).toJSON();

  nextJsonQuery = setTerm('for_day')(nextJsonQuery)(ERentTime.Daily);
  nextJsonQuery = setTerm('show_hotels')(nextJsonQuery)(true);

  nextJsonQuery = { ...nextJsonQuery, dates, beds_count, region, price };

  return nextJsonQuery;
};
