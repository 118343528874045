/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetVillageForMapModel,
  IGetVillageForMapRequest,
  IMappers,
  IGetVillageForMapResponse,
  IGetVillageForMapResponseError,
  TGetVillageForMapResponse,
} from './types';

const defaultConfig: TGetVillageForMapModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'countryside-search',
  pathApi: '/v1/get-village-for-map/',
  hostType: 'api',
} as TGetVillageForMapModel;

function createGetVillageForMapModel(parameters: IGetVillageForMapRequest): TGetVillageForMapModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetVillageForMapOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetVillageForMapRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetVillageForMap<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetVillageForMapOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetVillageForMapResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetVillageForMapModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetVillageForMapResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetVillageForMapResponseError);
    }
  }

  return { response, statusCode } as TGetVillageForMapResponse;
}

export { defaultConfig, createGetVillageForMapModel, fetchGetVillageForMap };
