import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getUser } from '../../selectors/user';
import { IBrokerAnalyticsUser } from '../../tracking/newbuilding/broker';

export const useNewbuildingBrokerAnalyticsUser = () => {
  const user = useSelector(getUser);

  const userId = user?.isAuthenticated ? user.userId : undefined;

  return useMemo<IBrokerAnalyticsUser>(
    () => ({
      userId,
    }),
    [userId],
  );
};
