import { IAppState, TActions } from '../../../common/state';

const IComissionTypeChangedActionType = 'filters/comission/COMISSION_TYPE_CHANGED';
const IAgentCallsForbiddenChangedActionType = 'filters/comission/AGENT_CALLS_FORBIDDEN_CHANGED';

export interface IComissionTypeChangedAction {
  type: 'filters/comission/COMISSION_TYPE_CHANGED';
  commissionType: number | undefined;
}

export interface IAgentCallsForbiddenChangedAction {
  type: 'filters/comission/AGENT_CALLS_FORBIDDEN_CHANGED';
  isForbidden: boolean;
}

export function changeComissionType(commissionType: number | undefined): IComissionTypeChangedAction {
  return {
    type: IComissionTypeChangedActionType,
    commissionType,
  };
}

export function changeAgentCallsForbidden(isForbidden: boolean): IAgentCallsForbiddenChangedAction {
  return {
    type: IAgentCallsForbiddenChangedActionType,
    isForbidden,
  };
}

export function comissionReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IComissionTypeChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            commission_type:
              action.commissionType !== undefined
                ? {
                    type: 'term',
                    value: action.commissionType,
                  }
                : undefined,
          },
        },
      };

    case IAgentCallsForbiddenChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            cl: !action.isForbidden
              ? {
                  type: 'term',
                  value: action.isForbidden,
                }
              : undefined,
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-comission-comissionType') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              commission_type: undefined,
            },
          },
        };
      } else if (action.tag.type === 'advanced-comission-agentCallsForbidden') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              cl: undefined,
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
}
