import { IAppState, TActions } from '../../../common/state';

const BALCONY_CHANGED_ACTION_TYPE = 'filters/balconies/BALCONY_CHANGED';
const LOGGIA_CHANGED_ACTION_TYPE = 'filters/balconies/LOGGIA_CHANGED';
const BALCONIES_CHANGED_ACTION_TYPE = 'filters/balconies/BALCONIES_CHANGED';

export interface IBalconyChangedAction {
  type: 'filters/balconies/BALCONY_CHANGED';
  value: boolean;
}

export interface ILoggiaChangedAction {
  type: 'filters/balconies/LOGGIA_CHANGED';
  value: boolean;
}

export interface IBalconiesChangedAction {
  type: 'filters/balconies/BALCONIES_CHANGED';
  value: boolean;
}

export function changeBalcony(value: boolean): IBalconyChangedAction {
  return {
    type: BALCONY_CHANGED_ACTION_TYPE,
    value,
  };
}

export function changeLoggia(value: boolean): ILoggiaChangedAction {
  return {
    type: LOGGIA_CHANGED_ACTION_TYPE,
    value,
  };
}

export function changeBalconies(value: boolean): IBalconiesChangedAction {
  return {
    type: BALCONIES_CHANGED_ACTION_TYPE,
    value,
  };
}

export function balconiesReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case BALCONY_CHANGED_ACTION_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            balconies: action.value
              ? {
                  type: 'range',
                  value: {
                    gte: 1,
                  },
                }
              : undefined,
          },
        },
      };

    case LOGGIA_CHANGED_ACTION_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            loggia: action.value
              ? {
                  type: 'term',
                  value: true,
                }
              : undefined,
          },
        },
      };

    case BALCONIES_CHANGED_ACTION_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            balconies: action.value
              ? {
                  type: 'range',
                  value: {
                    gte: 1,
                  },
                }
              : undefined,
            loggia: action.value
              ? {
                  type: 'term',
                  value: true,
                }
              : undefined,
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-balconies-balcony') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              balconies: undefined,
            },
          },
        };
      } else if (action.tag.type === 'advanced-balconies-loggia') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              loggia: undefined,
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
}
