import { IAppState, TActions } from '../../../../common/state';
import { SetComparisonNotificationType, ClearComparisonNotificationType } from '../index';

export function comparisonNotificationReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case SetComparisonNotificationType:
      return {
        ...state,
        comparisonNotification: {
          text: action.text,
          url: action.url,
          category: action.category,
          offerId: action.offerId,
        },
      };
    case ClearComparisonNotificationType:
      return {
        ...state,
        comparisonNotification: null,
      };
    default:
      return state;
  }
}
