import { Paragraph3 } from '@cian/ui-kit';
import React from 'react';

import { ContentRow } from '../ContentRow';

export function AuctionTitlePopup() {
  return (
    <ContentRow>
      <Paragraph3>
        Объект реализуется через проведение торгов
        <br />в соответствии с ГК РФ.&nbsp;
        <a href={'https://cian.zendesk.com/hc/ru/articles/360037867052'}>Справочная информация</a>
      </Paragraph3>
    </ContentRow>
  );
}
