import { EPublishPeriod } from '../../../../json_query';
import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IPublishPeriodTagType = 'publishPeriod';

export interface IPublishPeriodTag {
  type: 'publishPeriod';
  value: EPublishPeriod;
}

function isPublishPeriodTag(tag: TTag): tag is IPublishPeriodTag {
  return tag.type === IPublishPeriodTagType;
}

export const publishPeriodTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.publish_period && jsonQuery.publish_period.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isPublishPeriodTag, () => ({ type: IPublishPeriodTagType, value }));
    } else {
      return ensureTagNotExists(tags, isPublishPeriodTag);
    }
  },
};
