import { TAdFoxBannerTemplate } from '@cian/adfox-component';
import { AdFoxTemplates } from '@cian/adfox-templates';
import * as React from 'react';

import { IWithTrackingProps } from '../../../../../serp/hoc/withTracking';
import { trackAdFoxMortgage } from '../../../../../tracking/adFoxMortgage';
import { isMortgageCampaign } from '../../utils/mortgage';

export type TAdFoxBannerTrackerProps = IWithTrackingProps & TAdFoxBannerTemplate;

export class AdFoxBannerTracker extends React.Component<TAdFoxBannerTrackerProps> {
  public trackOnMountSent = false;

  public componentDidUpdate(): void {
    const { bundleParams } = this.props;

    if (!this.trackOnMountSent && bundleParams && bundleParams.campaignId) {
      // Отправляем статистику по показу не на componentDidMount, потому что
      // фактически баннер подгружается лишь через какое-то время.
      this.track(true);
      this.trackOnMountSent = true;
    }
  }

  public render() {
    return (
      <div onClick={this.onClick}>
        <AdFoxTemplates {...this.props} />
      </div>
    );
  }

  /**
   * Отправляет аналитику в SOPR
   * @param isShow
   */
  public track = (isShow?: boolean) => {
    const { tracking, bundleParams } = this.props;

    if (!bundleParams) {
      return;
    }

    if (isMortgageCampaign(bundleParams.campaignId)) {
      trackAdFoxMortgage(
        isShow ? 'show' : 'click',
        'BannerIpoteka',
        bundleParams.bannerId,
        tracking.user,
        tracking.page,
      );
    }
  };

  private onClick = () => {
    this.track(false);
  };
}
