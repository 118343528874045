import { isFromBuilder } from './agent/isFromBuilder';
import { isFromLeadFactory } from './agent/isFromLeadFactory';
import { isFromSeller } from './agent/isFromSeller';
import { TComputedPropMapper } from '../../types/computed-props-mapper';
import { TAgentType } from '../../types/offer-card';

export const agentType: TComputedPropMapper<TAgentType> = (...args) => {
  const [fromSeller, fromBuilder, fromLeadFactory] = [isFromSeller, isFromBuilder, isFromLeadFactory].map(fn =>
    fn(...args),
  );

  /**
   * @todo Удалить фича-тогл
   * @description Данный функционал появился в задаче CD-187921, будет удалён в задаче CD-189395
   */
  const isElectronicTradingEnabled = args[1].config.getStrict<boolean>('Audience.ElectronicTrading.Enabled');

  const {
    offer: { isByHomeowner, user },
  } = args[0];

  /**
   * Специфичный тип агента только для случаев эл. торгов
   */
  if (isElectronicTradingEnabled && user?.electronicTradingUserType) {
    switch (user.electronicTradingUserType) {
      case 'tradingPlatform':
      case 'government':
      case 'bank':
        return user.electronicTradingUserType;
    }
  }

  if (isByHomeowner) {
    return 'homeowner';
  }

  if (fromBuilder && !fromLeadFactory) {
    return 'builder';
  }

  if (!fromBuilder && fromSeller && !fromLeadFactory) {
    return 'builderRepresentative';
  }

  if (fromLeadFactory) {
    return 'consultant';
  }

  if (user?.masterAgent || user?.accountType === 'agency') {
    return 'agency';
  }

  if (user?.accountType === 'rentDepartment') {
    return 'rentDepartment';
  }

  if (user?.accountType === 'specialist') {
    return 'realtor';
  }

  if (user?.accountType === 'managementCompany') {
    return 'managementCompany';
  }

  if (user?.bank) {
    return 'bank';
  }

  return 'user';
};
