import { IAppState } from '../../common/state';

export function selectDate(state: IAppState): { checkin: string; checkout: string } | null {
  const {
    filters: { jsonQuery },
  } = state;

  return jsonQuery.dates && jsonQuery.dates.value
    ? { checkin: jsonQuery.dates.value.gte, checkout: jsonQuery.dates.value.lt }
    : null;
}
