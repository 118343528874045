import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger';

import {
  createDelayedMarkSavedSearchAsReadestopModel,
  IDelayedMarkSavedSearchAsReadDesktopRequestSchema,
} from '../../../packages/api-models/saved-searches/delayed-mark-saved-search-as-read-desktop';

export function readAllOffersForSavedSearches(
  httpApi: IHttpApi,
  parameters: IDelayedMarkSavedSearchAsReadDesktopRequestSchema,
  logger: ILogger,
) {
  return httpApi.fetch(createDelayedMarkSavedSearchAsReadestopModel({ parameters })).then(response => {
    if (response.statusCode !== 200) {
      const error = new Error('Failed send /v1/delayed-mark-saved-search-as-read-desktop/');

      logger.error(error);
    }
  });
}
