import { TCurrentLocation } from '../../shared/types/location';

export function getRegionId(location: TCurrentLocation) {
  if (location === 'moscow_mo') {
    return -1;
  }
  if (location === 'spb_lo') {
    return -2;
  }

  return location.parentId || location.id;
}
