import * as React from 'react';
import { useSelector } from 'react-redux';

import { ModalContent } from '../../components/NewbuildingLayoutOffersModal/components/ModalContent';
import { ModalHeader } from '../../components/NewbuildingLayoutOffersModal/components/ModalHeader';
import { OffersTable } from '../../components/NewbuildingLayoutOffersModal/components/OffersTable';
import { residentialComplexLayoutOffersSelector } from '../../selectors/newbuildingLayouts';

interface INewbuildingLayoutDetailsProps {
  contactsButton: React.ReactNode;
  layoutKey?: string;
  newbuildingId?: number;
  houseId?: number;
}

export const NewbuildingLayoutDetails = ({ contactsButton }: INewbuildingLayoutDetailsProps) => {
  const layout = useSelector(residentialComplexLayoutOffersSelector);

  if (!layout) {
    return null;
  }

  return (
    <ModalContent>
      <ModalHeader contactsButton={contactsButton} layout={layout} />
      <OffersTable layout={layout} />
    </ModalContent>
  );
};
