import { IAppState, TActions } from '../../../common/state';
import { ESuburbanUtility, jq } from '../../../json_query';

const ISuburbanUtilitiesChangedActionType = 'filters/suburban_utilities/SUBURBAN_UTILITIES_CHANGED';

export interface ISuburbanUtilitiesChangedAction {
  type: 'filters/suburban_utilities/SUBURBAN_UTILITIES_CHANGED';
  param: ESuburbanUtility;
  value: boolean;
}

export function changeSuburbanUtility(param: ESuburbanUtility, value: boolean): ISuburbanUtilitiesChangedAction {
  return {
    type: ISuburbanUtilitiesChangedActionType,
    param,
    value,
  };
}

export function suburbanUtilitiesReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case ISuburbanUtilitiesChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setSuburbanUtility(action.param, action.value),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-suburbanUtility') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).setSuburbanUtility(action.tag.value, false),
          },
        };
      }

      return state;

    default:
      return state;
  }
}
