import { IConfig } from '@cian/config/shared';

import { IOffer } from '../types/offer';

export function isSubsidisedMortgageEnabled(config: IConfig) {
  return Boolean(config.get<boolean>('frontend-serp.subsidizedMortgage.Enabled'));
}

export function getSubsidisedMortgageLabelText(config: IConfig) {
  return config.get<string>('frontend-serp.subsidizedMortgageLabelText') || '';
}

export function isSubsidisedMortgageAvailable({ isSubsidisedMortgage }: IOffer, config: IConfig) {
  const subsidisedMortgageEnabled = isSubsidisedMortgageEnabled(config);

  return Boolean(subsidisedMortgageEnabled && isSubsidisedMortgage);
}
