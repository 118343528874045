import { ITypedReduxAction, TThunkAction } from '../../types/redux';

export enum EAlertType {
  Favorites = 'favorites',
  Chats = 'chats',
  Hide = 'hide',
}

export interface IRequestAuthenticationParameters {
  alert?: EAlertType;
  onSuccess?(): TThunkAction;
  onCloseNoAuth?(): TThunkAction;
}

export interface IAuthenticateEvent {
  autoRefresh?: boolean;
}

export enum EAuthenticationActionType {
  Succeed = 'authentication/succeed',
  Cancelled = 'authentication/cancelled',
}

export type TAuthenticationSucceed = ITypedReduxAction<EAuthenticationActionType.Succeed>;
export type TAuthenticationCancelled = ITypedReduxAction<EAuthenticationActionType.Cancelled>;

export type TAuthenticationActions = TAuthenticationSucceed | TAuthenticationCancelled;
