import { ca } from '@cian/analytics';

export function trackKpLinkClick(url: string) {
  ca('event', {
    name: 'oldevent',
    category: 'card',
    action: 'to_KPCard_main_block',
    label: url,
  });
}
