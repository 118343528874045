import * as React from 'react';

import { MAX_VALUE, MIN_VALUE, DEFAULT_VALUE } from './constants';
import { getNormalizedValue } from './helpers';
import { checkIsHotelsSearch } from '../../../../../../shared/utils/checkIsHotelsSearch';
import { getRangeValue } from '../../../../../JsonQuery';
import { Beds } from '../../../components/filters/Beds';
import { IFilterComponentProps } from '../../../types/filters';
import { useSearchResultsTooltipOpen } from '../../../utils/searchResultsTooltipOpen';
import { useContext } from '../../../utils/useContext';

export const BedsContainer: React.FC<IFilterComponentProps> = ({ filterKey, open, onOpen, onClose }) => {
  const { jsonQuery, jsonQueryCount, jsonQueryCountRefreshing, onChange, onApply } = useContext();

  const isHotelSearch = checkIsHotelsSearch(jsonQuery);
  const beds = getRangeValue('beds_count')(jsonQuery);
  const openTooltip = useSearchResultsTooltipOpen({
    filterKey,
    actions: ['setBeds'],
  });

  const handleChange = React.useCallback(
    (value: number) => {
      const gte = value;
      const lte = null;

      onChange({ action: 'setBeds', arguments: [gte > DEFAULT_VALUE ? gte : null, lte] });
    },
    [onChange],
  );

  const value = getNormalizedValue(beds?.gte);

  return (
    <Beds
      open={open}
      value={value}
      min={MIN_VALUE}
      max={MAX_VALUE}
      hideTooltipCount={isHotelSearch}
      offersCount={jsonQueryCount}
      offersCountLoading={jsonQueryCountRefreshing}
      openTooltip={openTooltip}
      onOpen={onOpen}
      onChange={handleChange}
      onApply={onApply}
      onClose={onClose}
    />
  );
};
