import React, { useCallback, MouseEvent } from 'react';

import { SimilarLink } from '../../../components/SimilarLink';
import { useOfferContext } from '../../../context';

export function IdenticalOffersLinkContainer() {
  const {
    offer: { identicalOffers },
    tracking: { trackShowAllIdenticalOffers },
  } = useOfferContext();

  const handleClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>): void => {
      e.stopPropagation();
      trackShowAllIdenticalOffers();
    },
    [trackShowAllIdenticalOffers],
  );

  if (!identicalOffers) {
    return null;
  }

  return (
    <SimilarLink
      text="Посмотреть копии объявления от других продавцов"
      url={identicalOffers.url}
      onClick={handleClick}
    />
  );
}
