import React from 'react';

import * as styles from './Link.css';

interface ILinkProps {
  target: '_blank' | '_self' | '_parent' | '_top';
  href: string;
  text: string;
  onClick(e: React.MouseEvent): void;
}

export const Link: React.VFC<ILinkProps> = ({ target, href, onClick, text }) => {
  return (
    <a className={styles['link']} target={target} href={href} onClick={onClick} rel="noreferrer">
      {text}
    </a>
  );
};
