export function parseTime(displayTime: string) {
  const [hours, minutes] = displayTime.split(':');

  return {
    hours: +hours,
    minutes: +minutes,
  };
}

export function inTimeRange(time: string, from: string, to: string) {
  const { hours, minutes } = parseTime(time);
  const { hours: fromHours, minutes: fromMinutes } = parseTime(from);
  const { hours: toHours, minutes: toMinutes } = parseTime(to);

  if (hours > fromHours && hours < toHours) {
    return true;
  }

  if (hours === fromHours) {
    return minutes >= fromMinutes;
  }

  if (hours === toHours) {
    return minutes <= toMinutes;
  }

  return false;
}

export function validateTime(time: string) {
  return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
}

export const transformToMinutes = (time: string): number => {
  const { hours, minutes } = parseTime(time);

  return hours * 60 + minutes;
};

const MS_IN_MINUTE = 60000;

export const getDayOfCallText = (callTime: string, jkTimezone: number) => {
  const localDate = new Date();
  const utc = localDate.getTime() + localDate.getTimezoneOffset() * MS_IN_MINUTE; //timezoneOffset в минутах
  const jkDate = new Date(utc + jkTimezone * MS_IN_MINUTE * 60); //jkTimezone в часах
  const jkTimeInMinutes = jkDate.getHours() * 60 + jkDate.getMinutes();
  const callTimeInMinutes = transformToMinutes(callTime);

  return callTimeInMinutes < jkTimeInMinutes ? 'завтра' : 'сегодня';
};
