import * as React from 'react';

import { IWordsIncludeTag } from '../../../state/tags/definitions/words_include';
import { TagNew } from '../TagNew';

export interface IWordsIncludeTagItemProps {
  tag: IWordsIncludeTag;
  onDeleteClick(): void;
}

export const WordsIncludeTagItem = ({ tag, onDeleteClick }: IWordsIncludeTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>Содержит &quot;{tag.value}&quot;</TagNew>;
};
