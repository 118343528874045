import { connect } from 'react-redux';

import { IAppState } from '../../../common/state';
import { Newobjects, INewobjectsProps } from '../newobjects';

export const NewobjectsFromDeveloperContainer = connect<INewobjectsProps>((state: IAppState) => ({
  similarNewobjects: state.similarNewobjectsFromDeveloper,
  aggregatedOffers: state.results.aggregatedOffers,
  fromDeveloper: true,
}))(Newobjects);
