import { Spinner, UIHeading1, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './RegionalDistrictsModal.css';
import { ERegionalDistrictsStatus } from '../../types/regionalDistricts';

interface IRegionalDistrictsModalProps {
  status: ERegionalDistrictsStatus;
  map: React.ReactNode;
  list: React.ReactNode;
  listRef: React.RefObject<HTMLDivElement>;
}

export const RegionalDistrictsModal: React.FC<IRegionalDistrictsModalProps> = ({ list, map, status, listRef }) => {
  return (
    <div className={styles['content-container']}>
      {(status === ERegionalDistrictsStatus.Loading || status === ERegionalDistrictsStatus.Success) && (
        <div className={styles.preloaderWrapper}>
          <Spinner size={50} />
        </div>
      )}

      {status === ERegionalDistrictsStatus.Failed && (
        <div className={styles['error-container']}>
          <div className={styles['error-image']} />
          <div className={styles['error-title']}>
            <UIHeading1>Что-то пошло не так</UIHeading1>
          </div>
          <UIText1 color="gray60_100">Попробуйте перезагрузить страницу, уже должно работать</UIText1>
        </div>
      )}

      {(status === ERegionalDistrictsStatus.Success || status === ERegionalDistrictsStatus.Ready) && (
        <div className={styles['inner']}>
          <div className={styles['list']} ref={listRef}>
            {list}
          </div>
          <div className={styles['map']}>{map}</div>
        </div>
      )}
    </div>
  );
};
