import { ca } from '@cian/analytics';

export function trackClearClick() {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'clear_filters',
    label: 'From_cat_filters',
  });
}
