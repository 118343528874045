import { IAppState } from '../../common/state';

export function selectOffersCount(state: IAppState) {
  const {
    results: { totalOffers },
    hideOffer: { hiddenOffers },
  } = state;

  return totalOffers - hiddenOffers.length;
}
