import { makeContextModule } from '@cian/react-utils';
import React, { FC, JSX, useMemo } from 'react';

import { useAdfoxParameters } from './internal/hooks/useAdfoxParameters';
import { PParams } from './types';
import { AdfoxContextData } from '../../types/adfox/AdfoxContextData';

const contextData = makeContextModule<AdfoxContextData>({ contextName: 'AdfoxContextData' });

const {
  Context: { Provider },
} = contextData;

export const { useContext: useAdfoxContext, withContext: withAdfoxContext } = contextData;

type AdfoxContextProviderProps = Omit<AdfoxContextData, 'params'> & {
  children: JSX.Element | JSX.Element[];
  pParams: PParams;
};

export const AdfoxContextProvider: FC<AdfoxContextProviderProps> = props => {
  const { children, pParams, size, postViewAnalyticsData } = props;

  const params = useAdfoxParameters(pParams);

  const contextValue = useMemo<AdfoxContextData>(
    () => ({ params, size, postViewAnalyticsData }),
    [params, postViewAnalyticsData, size],
  );

  return <Provider value={contextValue}>{children}</Provider>;
};
