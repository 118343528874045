import * as React from 'react';

import { NonEmptyArray, getTermsValue, offerTypeFromJsonQuery } from '../../../../../JsonQuery';
import { OfferSeller } from '../../../components/advancedFilters/OfferSeller';
import { EOfferSeller } from '../../../types/jsonQuery';
import { useContext } from '../../../utils/useContext';

export const OfferSellerContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();

  const offerType = offerTypeFromJsonQuery(jsonQuery);
  const offerSellers = getTermsValue('offer_seller_type')(jsonQuery);

  const handleOfferSellerChange = React.useCallback(
    (offerSellers: NonEmptyArray<EOfferSeller> | null) => {
      onChange({ action: 'setOfferSeller', arguments: [offerSellers] });
    },
    [onChange],
  );

  return <OfferSeller value={offerSellers} onChange={handleOfferSellerChange} offerType={offerType} />;
};
