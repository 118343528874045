import { FOfferType, offerTypeFromJsonQuery } from '../../../../utils/category';
import { isGeoAvailable, moscowMoIds } from '../../../../utils/geo';
import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const ISiteAreaTagType = 'siteArea';

export interface ISiteAreaTag {
  type: 'siteArea';
  min: number | undefined;
  max: number | undefined;
}

function isSiteAreaTag(tag: TTag): tag is ISiteAreaTag {
  return tag.type === ISiteAreaTagType;
}

export const siteAreaTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery, tagsData, currentLocation) => {
    const value = jsonQuery.site && jsonQuery.site.value;
    const offerType = offerTypeFromJsonQuery(jsonQuery);

    if (value !== undefined && ((offerType & FOfferType.Land) === 0 || isGeoAvailable(moscowMoIds, currentLocation))) {
      return ensureSingleTagExists(tags, isSiteAreaTag, () => ({
        type: ISiteAreaTagType,
        min: value.gte,
        max: value.lte,
      }));
    } else {
      return ensureTagNotExists(tags, isSiteAreaTag);
    }
  },
};
