import { isSearchResultsTooltipOpen } from './isSearchResultsTooltipOpen';
import { TFilter } from '../../types/filters';
import { TAvailableModifierActions } from '../../types/modifier';
import { useContext } from '../useContext';

interface IParameters {
  filterKey: TFilter;
  actions: TAvailableModifierActions[];
}

interface IAdvancedFiltersParameters {
  filterKey: 'advancedFilters';
}

type TParameters = IParameters | IAdvancedFiltersParameters;

export function useSearchResultsTooltipOpen(parameters: TParameters) {
  const { lastAppliedModifiers, isTooltipAvailable, openedFilters } = useContext();

  return (
    isTooltipAvailable &&
    isSearchResultsTooltipOpen({
      openedFilters,
      modifiers: lastAppliedModifiers,
      ...parameters,
    })
  );
}
