import { Image, ArticleHeading4, ArticleParagraph1, ArticleParagraph2 } from '@cian/ui-kit';
import { numberToPrettyString } from '@cian/utils';
import * as React from 'react';

import * as styles from './ProjectItem.css';
import { LinkArea } from '../../../../../fragments/OfferCardWidget/shared/components/LinkArea';
import { ISuburbanProjectSuggest } from '../../../../types/suburbanBuildersProjects';

interface IProjectItemProps extends ISuburbanProjectSuggest {
  onClick(): void;
}

export const ProjectItem: React.VFC<IProjectItemProps> = ({
  projectId,
  projectName,
  startPrice,
  area,
  materials,
  image,
  onClick,
}) => {
  const projectInfo = React.useMemo(
    () => [area && `${area} м²`, !!materials.length && materials.join(', ')].filter(Boolean).join(' ･ '),
    [area, materials],
  );

  const href = React.useMemo(() => `/country-projects/${projectId}/`, [projectId]);

  return (
    <LinkArea href={href} className={styles['link']} target="_blank" onClick={onClick}>
      <div className={styles['photo']}>
        <Image src={image} width="100%" objectFit="cover" borderRadius={0} />
      </div>
      <div className={styles['info']}>
        <ArticleHeading4 fontWeight="bold">{projectName}</ArticleHeading4>
        <div className={styles['price']}>
          <ArticleParagraph1>от {numberToPrettyString(startPrice)} ₽</ArticleParagraph1>
        </div>
        <ArticleParagraph2>{projectInfo}</ArticleParagraph2>
      </div>
    </LinkArea>
  );
};
