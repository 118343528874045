import { IOffer, TLandAreaUnitType } from '../../../types/offer';

export function getLandArea(offer: IOffer): string | undefined {
  if (offer.land && offer.land.area) {
    const unitTypes: { [K in TLandAreaUnitType]: string } = {
      hectare: 'га',
      sotka: 'сот.',
    };

    return `${Number(offer.land.area)} ${(offer.land.areaUnitType && unitTypes[offer.land.areaUnitType]) || 'сот.'}`;
  }

  return undefined;
}
