import { getMultiSelectionParamTagItem } from './fabrics/multi_selection_param_tag';
import { EGarageStatus } from '../../../../json_query';
import { IGarageStatusesTag } from '../../../state/tags/definitions/garage_status';

const garageStatusesMap = {
  [EGarageStatus.Ownership]: 'Собственность',
  [EGarageStatus.Agreement]: 'По доверенности',
  [EGarageStatus.Cooperative]: 'Кооператив',
};

export const GarageStatusesTagItem = getMultiSelectionParamTagItem<EGarageStatus, IGarageStatusesTag>(
  garageStatusesMap,
);
