import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { Label } from '@cian/ui-kit';
import * as React from 'react';

import { mapLabelBgColor, mapLabelColor } from './mapColors';
import { mapLabelIcon } from './mapIcons';
import { mapLabelTextToTestId } from './mapTestId';
import * as styles from './styles.css';
import { ILabel } from '../../types/label';
import { GeneralInfoSectionRow } from '../GeneralInfoSectionRow';
import { LabelTooltip } from '../LabelTooltip';

interface ILabelsListProps {
  labels: ILabel[];
  onShow: () => void;
}

export function LabelsList({ labels, onShow }: ILabelsListProps) {
  return (
    <GeneralInfoSectionRow>
      <ActionAfterViewObserver callback={onShow} triggerOnce={true}>
        <div className={styles['container']} onClick={e => e.stopPropagation()} data-name="LabelsList">
          {labels.map((label, idx) => (
            <div className={styles['label']} key={idx}>
              <LabelTooltip tooltip={label.tooltip}>
                <Label
                  background={mapLabelBgColor(label.color)}
                  color={mapLabelColor(label.color)}
                  icon={mapLabelIcon(label.color)}
                  data-testid={mapLabelTextToTestId(label.text)}
                >
                  {label.text}
                </Label>
              </LabelTooltip>
            </div>
          ))}
        </div>
      </ActionAfterViewObserver>
    </GeneralInfoSectionRow>
  );
}
