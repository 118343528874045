import { useControlled } from '@cian/ui-kit/internal/hooks';
import * as React from 'react';

interface IUseControlledSelectValueProps<T> {
  value?: T;
  onChange?(value: T): void;
}

export function useControlledValue<T>(props: IUseControlledSelectValueProps<T>, defaultValue: T) {
  const { onChange } = props;
  const [value, setValue] = useControlled({
    controlled: props.value,
    default: defaultValue,
  });
  const handleChange = React.useCallback(
    (value: T) => {
      setValue(value);

      if (onChange) {
        onChange(value);
      }
    },
    [onChange, setValue],
  );

  return { value, onChange: handleChange };
}
