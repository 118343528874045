import * as React from 'react';

import { TagNew } from '../TagNew';

export interface IAgentCallsForbiddenTagItemProps {
  onDeleteClick(): void;
}

export const AgentCallsForbiddenTagItem = ({ onDeleteClick }: IAgentCallsForbiddenTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>Без «Агентам не звонить»</TagNew>;
};
