import { ca } from '@cian/analytics';

import { IJsonQuery } from '../../../repositories/common/json_query';
import { INewbuildingSimilarOfferProduct } from '../../../types/newbuildingSimilarOffer';

export const trackNewbuildingEntryResaleBlockButtonClick =
  (jsonQuery: IJsonQuery) => (products: INewbuildingSimilarOfferProduct[]) => {
    ca('eventSite', {
      name: 'oldevent',
      category: 'Listing',
      action: 'new_building_snippet_click_all',
      label: 'resale',
      sc: jsonQuery,
      products,
    });
  };

export const trackNewbuildingEntryResaleBlockItemClick =
  (jsonQuery: IJsonQuery) => (products: INewbuildingSimilarOfferProduct[]) => {
    ca('eventSite', {
      name: 'oldevent',
      category: 'Listing',
      action: 'new_building_snippet_click_offer',
      label: 'resale',
      sc: jsonQuery,
      products,
    });
  };

export const trackNewbuildingEntryResaleBlockShow =
  (jsonQuery: IJsonQuery) => (products: INewbuildingSimilarOfferProduct[]) => {
    ca('eventSite', {
      name: 'soprevent',
      category: 'Listing',
      action: 'new_building_snippet_show',
      label: 'resale',
      sc: jsonQuery,
      products,
    });
  };
