import { ISuburbanFacility } from './types';
import { EFacilityType, FDealType } from '../../../../../JsonQuery';

const SUBURBAN_FACILITIES: ISuburbanFacility[] = [
  { label: 'Неважно', value: null },
  { label: 'Баня', value: EFacilityType.Sauna },
  { label: 'Гараж', value: EFacilityType.Garage },
  { label: 'Бассейн', value: EFacilityType.Pool },
];

const SALE_SUBURBAN_FACILITIES: ISuburbanFacility[] = [
  { label: 'Баня', value: EFacilityType.Sauna },
  { label: 'Гараж', value: EFacilityType.Garage },
  { label: 'Охрана', value: EFacilityType.Security },
  { label: 'Бассейн', value: EFacilityType.Pool },
];

export function getSuburbanFacilitiesList(dealType: FDealType): ISuburbanFacility[] {
  if (dealType === FDealType.Sale) {
    return SALE_SUBURBAN_FACILITIES;
  }

  return SUBURBAN_FACILITIES;
}
