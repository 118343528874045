import { isTop } from './isTop';
import { TComputedPropMapper } from '../../types/computed-props-mapper';

export const agentOffersLink: TComputedPropMapper<string | undefined> = (props, ctx) => {
  const { user, dealType, cianUserId, offerType } = props.offer;
  const userId = user?.cianUserId || cianUserId || undefined;
  const userIdForLink = user?.masterAgent?.id || userId;
  const userIdQuery = userIdForLink ? `id_user=${userIdForLink}&` : '';

  const url = `/cat.php?${userIdQuery}deal_type=${dealType}&${offerType}=yes&engine_version=2`;

  return isTop(props, ctx) ? url : undefined;
};
