import { ca } from '@cian/analytics';

const baseEvent = {
  name: 'oldevent',
  category: 'Listing',
  action: 'banner_listingKP_click',
};

export function trackBannerKpLinkClick() {
  ca('event', {
    ...baseEvent,
    label: '',
  });
}

export function trackTrapKpLinkClick() {
  ca('eventSite', {
    ...baseEvent,
    label: 'banner_in_similar',
  });
}

export function trackBannerBuildersClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'CountryBuilders',
    action: 'Click',
    label: 'entering_point_action',
    page: {
      extra: {
        entering_point: 'listing_desktop',
      },
    },
  });
}
