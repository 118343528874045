import { COMMON_PROPERTIES, COMMON_PROPERTIES_SUBURBAN } from './constants';
import { NonEmptyArray, TJsonQueryKeys, TJsonQueryOptionalKeys } from '../../../../JsonQuery';
import { IJsonQuery } from '../../types/jsonQuery';

export function getUncommonProperties(jsonQuery: IJsonQuery): NonEmptyArray<TJsonQueryOptionalKeys> | null {
  const jsonQueryKeys = Object.keys(jsonQuery) as TJsonQueryKeys[];
  const uncommonProperties = jsonQueryKeys.filter(property => {
    const commonProperties = jsonQuery._type === 'suburbansale' ? COMMON_PROPERTIES_SUBURBAN : COMMON_PROPERTIES;

    return !commonProperties.includes(property);
  });

  return uncommonProperties.length > 0 ? (uncommonProperties as NonEmptyArray<TJsonQueryOptionalKeys>) : null;
}
