import { getBuildingDeadline } from './building/getBuildingDeadline';
import { TComputedPropMapper } from '../../types/computed-props-mapper';
import { IJKInfo } from '../../types/jk';

export const jk: TComputedPropMapper<IJKInfo | undefined> = ({ offer }) => {
  const { displayName, fullUrl } = offer.geo?.jk || {};

  if (offer.dealType === 'rent' || displayName === undefined || displayName === null) {
    return undefined;
  }

  const solid = Boolean(offer.newbuilding?.showJkReliableFlag);

  return {
    name: displayName,
    deadline: getBuildingDeadline(offer),
    url: fullUrl || undefined,
    solid,
  };
};
