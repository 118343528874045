import { UIHeading1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './VillagesRecommendations.css';
import { IRecommendedVillage } from '../../types/recommendedVillages';
import { VillageItem } from '../VillageItem';

export interface IVillagesRecommendationsProps {
  villageList: IRecommendedVillage[];
  onVillageClick: (id: number, position: number) => void;
  onShowAllClick(): void;
  link: string;
}

export const VillagesRecommendations: React.VFC<IVillagesRecommendationsProps> = ({
  villageList,
  onVillageClick,
  onShowAllClick,
  link,
}) => {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['title-container']}>
        <UIHeading1>Рекомендуем коттеджные посёлки</UIHeading1>
        <a onClick={onShowAllClick} href={link} className={styles['button']} target="_blank" rel="noreferrer">
          Смотреть все
        </a>
      </div>
      <div className={styles['villages-list']}>
        {villageList.map((village, index) => (
          <VillageItem {...village} key={village.id} onClick={() => onVillageClick(village.id, index + 1)} />
        ))}
      </div>
    </div>
  );
};
