import { ILogger } from '@cian/logger';

import { ICoordinatesOffers, ISuggestionsDistances } from '../serp/state/suggestions';
import { IMakeRequest } from '../utils/request';

interface ISuggestionsGeoResponse {
  data: {
    offerDistances: ISuggestionsDistances[];
  };
  status: 'ok';
}

export interface ISuggestionsDistancesReqBody {
  queryString: string;
  coordinatesOffers: ICoordinatesOffers[];
}

interface IDependencies {
  makeRequest: IMakeRequest;
  logger: ILogger;
}

export async function getSuggestionsDistances(
  dependencies: IDependencies,
  reqBody: ISuggestionsDistancesReqBody,
): Promise<ISuggestionsDistances[] | null> {
  const { makeRequest, logger } = dependencies;

  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/get-qs-coordinates-distances/',
    parameters: reqBody,
    method: 'POST',
  })
    .then((res: ISuggestionsGeoResponse) => {
      if (res.status !== 'ok') {
        logger.warning('Получен невалидный статус ответа get-qs-coordinates-distances', {
          domain: 'shared.api.suggestions.getSuggestionsDistances',
          degradation: 'Запрашивались данные из get-qs-coordinates-distances',
        });

        return null;
      }

      return res.data.offerDistances;
    })
    .catch(ex => {
      logger.warning(ex, {
        domain: 'shared.api.suggestions.getSuggestionsDistances',
        degradation: 'Запрашивались данные из get-qs-coordinates-distances',
      });

      return null;
    });
}
