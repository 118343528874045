import classNames from 'classnames';
import * as React from 'react';

import * as styles from './GalleryLabels.css';
import { IGalleryLabel } from '../../types/gallery-label';

interface IGalleryLabelsProps {
  labels: IGalleryLabel[];
  hasFeatures: boolean;
}

export const GalleryLabels = ({ labels, hasFeatures }: IGalleryLabelsProps) => {
  return (
    <div className={classNames(styles['container'], hasFeatures && styles['has-features'])}>
      {labels.map(({ label, color, customColor, iconUrl }) => (
        <div
          key={label}
          className={classNames(styles['label'], { [styles[`${color}-label`]]: !customColor })}
          style={{ backgroundColor: customColor }}
        >
          {iconUrl && <span className={styles['icon']} style={{ backgroundImage: `url(${iconUrl})` }} />}
          {label}
        </div>
      ))}
    </div>
  );
};
