import * as React from 'react';

export function ArrowTopRight() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="2 0 15 15">
      <path
        fill="#0087e2"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        d="M7.99997 4.4142L7.99997 19 5.99997 19 5.99997 4.41422 1.99998 8.41421 0.58576 7 6.99998 0.585785 13.4142 7 12 8.41421z"
        transform="translate(-3 -4) rotate(45 8.293 17.536)"
      />
    </svg>
  );
}
