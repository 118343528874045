import { IOnChangeParameters } from '../../../packages/Filters';
import { IAppState, TActions } from '../../common/state';

export interface IJsonQueryChangedAction {
  type: 'filters/json_query/change';
  value: IOnChangeParameters;
}

export function changeJsonQuery(value: IOnChangeParameters): IJsonQueryChangedAction {
  return {
    type: 'filters/json_query/change',
    value,
  };
}

export function changeJsonQueryReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/json_query/change':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: action.value.nextJsonQuery,
        },
      };
    default:
      return state;
  }
}
