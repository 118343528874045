import { Button } from '@cian/ui-kit';
import classNames from 'classnames';
import * as React from 'react';

import { getValueFromComputedStyle } from '../../../../../browser/utils/dom';

const styles = require('./index.css');

export interface IFitProps {
  children?: React.ReactNode[];
  enabled: boolean;
  maxWidth: number;
  toggle(value: boolean): void;
}

export interface IFitState {
  shouldRecalc: boolean;
  itemsWidths: number[];
}

const BUTTON_WIDTH = 95;

export class FitInOneLine extends React.PureComponent<IFitProps, IFitState> {
  public state = {
    shouldRecalc: true,
    itemsWidths: [] as number[],
  };

  private wrapperRef = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    this.recalc();
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IFitProps) {
    if (this.props.children !== nextProps.children) {
      this.setState({
        shouldRecalc: true,
      });
    }
  }

  public componentDidUpdate() {
    this.recalc();
  }

  public render() {
    const { children, enabled } = this.props;

    if (!this.props.maxWidth) {
      return null;
    }

    const { itemsWidths, shouldRecalc } = this.state;

    const childrensArray = React.Children.toArray(children);
    let lastVisibleItemIndex: number | undefined;

    const sumOfVisibleItemsWidths = itemsWidths.reduce((acc, item, index) => {
      if (lastVisibleItemIndex !== undefined) {
        return acc;
      }

      const nextAcc = acc + item;

      if (!lastVisibleItemIndex && nextAcc > this.props.maxWidth) {
        lastVisibleItemIndex = index;

        return acc;
      }

      return nextAcc;
    }, 0);

    const isButtonVisible = Boolean(lastVisibleItemIndex);

    // Если нужно показать кнопку "Показать ещё", проверяем влезает ли она
    if (isButtonVisible && this.props.maxWidth - sumOfVisibleItemsWidths < BUTTON_WIDTH) {
      lastVisibleItemIndex = Number(lastVisibleItemIndex) - 1;
    }

    if (!enabled) {
      return (
        <div className={styles['wrapper--inline']} ref={this.wrapperRef}>
          {children}
          {isButtonVisible && (
            <div className={styles['button']}>
              <Button theme="fill_secondary" size="XS" onClick={this.handleHide}>
                Свернуть
              </Button>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={styles['wrapper']} ref={this.wrapperRef}>
        {childrensArray.map((item, index) => {
          if (shouldRecalc) {
            return item;
          } else {
            if (lastVisibleItemIndex && index >= Number(lastVisibleItemIndex)) {
              return null;
            }

            return item;
          }
        })}
        {isButtonVisible && (
          <div className={classNames(styles['button'], styles['button--with-dots'])}>
            <Button theme="fill_secondary" size="XS" onClick={this.handleShow}>
              •••
            </Button>
          </div>
        )}
      </div>
    );
  }

  private handleShow = () => {
    this.props.toggle(false);
  };

  private handleHide = () => {
    this.props.toggle(true);
  };

  private recalc() {
    const wrapper = this.wrapperRef.current;

    if (!this.state.shouldRecalc || !wrapper) {
      return;
    }

    const items = wrapper.childNodes;
    const itemsWidths = [];
    let i = 0;
    // Берём только первые 15 элементов, чтобы не пересчитывать лишнее
    // Предполагается, что в одну строку не помещается больше 15 тегов
    const lastIndex = Math.min(items.length, 15);
    for (i; i < lastIndex; i++) {
      const element = items[i] as HTMLDivElement;
      if (element && element.tagName.toLowerCase() !== 'button' && !element.classList.contains(styles['button'])) {
        const computedStyle = window.getComputedStyle(element);
        itemsWidths.push(
          element.getBoundingClientRect().width + getValueFromComputedStyle(computedStyle, 'marginRight'),
        );
      }
    }

    this.setState({
      shouldRecalc: false,
      itemsWidths,
    });
  }
}
