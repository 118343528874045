import cx from 'classnames';
import * as React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Arrow } from './parts/Arrow';
import * as styles from './styles.css';
import './swiper.css';

SwiperCore.use([Navigation]);

export interface ICarouselProps extends Swiper {
  className?: string;
  testId?: string;
  children: React.ReactElement[];
}

export const Carousel: React.FC<React.PropsWithChildren<ICarouselProps>> = props => {
  const { children, testId, className, ...swiperOptions } = props;

  const prevRef = React.useRef<HTMLDivElement>(null);
  const nextRef = React.useRef<HTMLDivElement>(null);

  return (
    <div className={cx(styles['wrapper'], `${className}_wrapper`)} data-testid={testId}>
      <div className={styles['container']}>
        <Swiper
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
            disabledClass: `${className}-button-disabled`,
          }}
          onSwiper={swiper => {
            setTimeout(() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line no-param-reassign
              swiper.params.navigation.prevEl = prevRef.current;
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line no-param-reassign
              swiper.params.navigation.nextEl = nextRef.current;
              // Re-init navigation
              swiper.navigation.destroy();
              swiper.navigation.init();
              swiper.navigation.update();
            });
          }}
          {...swiperOptions}
        >
          {React.Children.map(children, (child, index) => (
            <SwiperSlide key={index} className={styles['slide']}>
              {child}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {swiperOptions.slidesPerView && React.Children.count(children) > swiperOptions.slidesPerView && (
        <>
          <Arrow direction={-1} ref={prevRef} />
          <Arrow direction={1} ref={nextRef} />
        </>
      )}
    </div>
  );
};

Carousel.displayName = 'Carousel';
