import * as React from 'react';

import * as styles from './PaginationSection.css';

interface IPaginationSectionProps {
  pagination?: React.ReactNode;
  buttons?: React.ReactNode;
}

export function PaginationSection({ pagination, buttons }: IPaginationSectionProps) {
  return (
    <div className={styles['pagination-section']}>
      <div className={styles['pagination']}>{pagination}</div>
      <div className={styles['buttons']}>{buttons}</div>
    </div>
  );
}
