import { EVariant, RealtorRequestTrapV2 } from '@cian/demand-trap-widget';
import * as React from 'react';

import { trackSendFormClick } from './tracking';
import { useHandleTrackShow } from './utils/useHandleTrackShow';
import { useRealtorFormUrl } from './utils/useRealtorFormUrl';
import { ElementInViewport } from '../../../common/components/element_in_viewport';

export const RealtorRequestTrapContainer: React.FC = () => {
  const url = useRealtorFormUrl();
  const handleTrackShow = useHandleTrackShow();

  if (!url) {
    return null;
  }

  return (
    <ElementInViewport onChange={handleTrackShow}>
      <RealtorRequestTrapV2 href={url} variant={EVariant.C} openBlank onClick={trackSendFormClick} />
    </ElementInViewport>
  );
};

RealtorRequestTrapContainer.displayName = 'RealtorRequestTrapContainer';
