import { ELabelColor } from '../types/label';
import { IOffer } from '../types/offer';

export const prepareGoodPriceLabel = (offer: IOffer) => {
  if (!offer.goodPrice) {
    return;
  }

  return {
    color: ELabelColor.SimpleBlue,
    text: offer.goodPrice.priceLabel,
    tooltip: {
      title: 'Почему цена хорошая',
      // eslint-disable-next-line no-irregular-whitespace
      description: `Хорошей мы считаем цену, которая попадает в диапазон оценки Циан. Для этой квартиры диапазон: ${offer.goodPrice.estimationRange}`,
    },
  };
};
