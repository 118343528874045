import { call, put, select, take, takeLatest } from 'redux-saga/effects';

import { TActions } from '../../../common/state/actions';
import { IAppState, Dispatch } from '../../../common/state/app_state';
import { jq } from '../../../json_query';
import { ILocation } from '../../../types/location';
import { IRequestCitiesResponse, ISearchLocationsResponse, requestCities, searchLocations } from '../../api';

export interface IAutocompleteRequestedAction {
  type: 'filters/location_switcher/AUTOCOMPLETE_REQUESTED';
  value: string;
}

export interface IAutocompleteSuggestionRegionSelectionRequestedAction {
  type: 'filters/location_switcher/AUTOCOMPLETE_SUGGESTION_REGION_SELECTION_REQUESTED';
  region: ILocation;
}

export interface ICityAutocompleteSelectedAction {
  type: 'filters/location_switcher/CITY_SUGGESTION_SELECTED';
  city: ILocation;
  cities: ILocation[];
}

export interface IAutocompleteSuggestionCitySelectedRequestedAction {
  type: 'filters/location_switcher/AUTOCOMPLETE_SUGGESTION_CITY_SELECTION_REQUESTED';
  city: ILocation;
}

export interface IAutocompleteSuggestionsFetchedAction {
  type: 'filters/location_switcher/AUTOCOMPLETE_SUGGESTIONS_FETCHED';
  suggestions: ILocation[];
}

export function requestAutocomplete(value: string): IAutocompleteRequestedAction {
  return {
    type: 'filters/location_switcher/AUTOCOMPLETE_REQUESTED',
    value,
  };
}

export function selectAutocompleteSuggestion(location: ILocation) {
  return (dispatch: Dispatch) => {
    if (location.parentId) {
      dispatch({
        type: 'filters/location_switcher/AUTOCOMPLETE_SUGGESTION_CITY_SELECTION_REQUESTED',
        city: location,
      } as IAutocompleteSuggestionCitySelectedRequestedAction);
    } else {
      dispatch({
        type: 'filters/location_switcher/AUTOCOMPLETE_SUGGESTION_REGION_SELECTION_REQUESTED',
        region: location,
      } as IAutocompleteSuggestionRegionSelectionRequestedAction);
    }
  };
}

export function* locationSwitcherAutocompleteSearch(action: IAutocompleteRequestedAction) {
  const state: IAppState = yield select();
  const result: ISearchLocationsResponse = yield call(searchLocations, state.makeRequest, action.value);

  yield put({
    type: 'filters/location_switcher/AUTOCOMPLETE_SUGGESTIONS_FETCHED',
    suggestions: result.items,
  } as IAutocompleteSuggestionsFetchedAction);
}

export function* locationSwitcherAutocompleteSaga() {
  yield takeLatest('filters/location_switcher/AUTOCOMPLETE_REQUESTED', locationSwitcherAutocompleteSearch);
}

export function* locationSwitcherCitySelectionSaga() {
  while (true) {
    const action: IAutocompleteSuggestionCitySelectedRequestedAction = yield take([
      'filters/location_switcher/AUTOCOMPLETE_SUGGESTION_CITY_SELECTION_REQUESTED',
    ]);

    if (action.city.parentId == null) {
      continue;
    }

    const state: IAppState = yield select();

    let cities: ILocation[] = [];
    const result: IRequestCitiesResponse = yield call(requestCities, state.makeRequest, action.city.parentId);
    cities = result.items;

    yield put({
      type: 'filters/location_switcher/CITY_SUGGESTION_SELECTED',
      city: action.city,
      cities,
    } as ICityAutocompleteSelectedAction);
  }
}

export function locationSwitcherAutocompleteReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/location_switcher/AUTOCOMPLETE_REQUESTED':
      return {
        ...state,
        filters: {
          ...state.filters,
          locationSwitcher: {
            ...state.filters.locationSwitcher,
            autocompleteSuggestions: undefined,
            isAutocompleteLoading: true,
          },
        },
      };

    case 'filters/location_switcher/AUTOCOMPLETE_SUGGESTION_CITY_SELECTION_REQUESTED':
      return {
        ...state,
        filters: {
          ...state.filters,
          locationSwitcher: {
            ...state.filters.locationSwitcher,
            isCitiesLoading: true,
          },
        },
      };

    case 'filters/location_switcher/AUTOCOMPLETE_SUGGESTIONS_FETCHED':
      return {
        ...state,
        filters: {
          ...state.filters,
          locationSwitcher: {
            ...state.filters.locationSwitcher,
            autocompleteSuggestions: action.suggestions,
            isAutocompleteLoading: false,
          },
        },
      };

    case 'filters/location_switcher/CITY_SUGGESTION_SELECTED': {
      const selectedRegion = state.filters.regions.filter(r => r.id === action.city.parentId).shift();

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setLocation(action.city, state.filters.currentLocation),
          locationSwitcher: {
            ...state.filters.locationSwitcher,
            cities: action.cities,
            selectedCity: action.city,
            isCitiesLoading: false,
            selectedRegion,
          },
        },
      };
    }

    default:
      return state;
  }
}
