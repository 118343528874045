import { Button } from '@cian/ui-kit/button';
import { UIHeading1, UIText1 } from '@cian/ui-kit/typography';
import * as React from 'react';

import * as styles from './NewbuildingConsultBanner.css';

interface INewbuildingConsultBannerProps {
  onClick?(): void;
  url: string;
}

export function NewbuildingConsultBanner({ onClick, url }: INewbuildingConsultBannerProps) {
  return (
    <a
      className={styles['container']}
      href={url}
      target="_blank"
      onClick={onClick}
      rel="noreferrer"
      data-testid="NewbuildingKpnBanner"
    >
      <UIHeading1>Бесплатно подберём квартиру</UIHeading1>
      <div className={styles['description']}>
        <UIText1>Консультант найдёт квартиру по вашим параметрам&nbsp;и&nbsp;ответит на все вопросы</UIText1>
      </div>
      <div className={styles['button']}>
        <Button theme="fill_primary">Подобрать квартиру</Button>
      </div>
    </a>
  );
}
