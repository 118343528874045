import { ca } from '@cian/analytics';

export function trackOpenGeoModal() {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'popup_GEO_open',
    label: '',
  });
}
