import * as React from 'react';

import { TagNew } from '../TagNew';

export interface IMortgagePossibleTagItemProps {
  onDeleteClick(): void;
}

export const MortgagePossibleTagItem = ({ onDeleteClick }: IMortgagePossibleTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>Возможна ипотека</TagNew>;
};
