import { getParamChangedAction } from './fabrics/multiselect_param_reducer';
import { IAppState, TActions } from '../../../common/state';
import { EGarageStatus, jq } from '../../../json_query';

type TGarageStatusChangedActionType = 'filters/garage/GARAGE_STATUS_CHANGED';
export const IGarageStatusChangedActionType = 'filters/garage/GARAGE_STATUS_CHANGED' as TGarageStatusChangedActionType;

export interface IGarageStatusChangedAction {
  type: 'filters/garage/GARAGE_STATUS_CHANGED';
  paramType: EGarageStatus;
  value: boolean;
}

export const changeGarageStatus = getParamChangedAction<EGarageStatus, TGarageStatusChangedActionType>(
  IGarageStatusChangedActionType,
);

export function garageStatusReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IGarageStatusChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: action.value
            ? jq(state.filters.jsonQuery).setGarageStatus(action.paramType)
            : jq(state.filters.jsonQuery).unsetGarageStatus(action.paramType),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-garageStatus') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).unsetGarageStatus(action.tag.value),
          },
        };
      }

      return state;

    default:
      return state;
  }
}
