import { ca } from '@cian/analytics';

import { FOfferType } from '../../../utils/category';

interface ITrackSelectGeoParams {
  geoType: string;
  isYandexGeo: boolean;
}

export function trackSelectGeo(params: ITrackSelectGeoParams) {
  const { geoType, isYandexGeo } = params;

  const source = isYandexGeo ? 'yandex' : 'cian';

  ca('eventSite', {
    name: 'oldevent',
    category: 'GeoSuggest',
    action: 'Change',
    label: `${geoType}/${source}`,
  });
}

export function trackNewbuildingSuggestLinkClick() {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'click_suggest',
    label: 'jk',
  });
}

export function trackGeoSuggestClick() {
  ca('event', {
    name: 'oldevent',
    category: 'GeoSuggest',
    action: 'Click',
    label: '',
  });
}

export function trackGeoSuggestNotFound(label: string) {
  ca('event', {
    name: 'oldevent',
    category: 'GeoSuggest',
    action: 'NotFound',
    label,
  });
}

export function trackGeoSuggestNoResults(label: string) {
  ca('event', {
    name: 'oldevent',
    category: 'GeoSuggest',
    action: 'NoResults',
    label,
  });
}

export const OFFER_TYPE_TRACKING_LABEL: { [key: number]: string } = {
  [FOfferType.FlatOld]: 'flatold',
  [FOfferType.FlatNew]: 'flatnew',
  [FOfferType.Flat]: 'flat',
  [FOfferType.Room]: 'room',
  [FOfferType.Bed]: 'bed',
  [FOfferType.Urban]: 'urban',
  [FOfferType.FlatShared]: 'flatshared',
  [FOfferType.House]: 'house',
  [FOfferType.HousePart]: 'housepart',
  [FOfferType.Townhouse]: 'townhouse',
  [FOfferType.Land]: 'land',
  [FOfferType.Suburban]: 'suburban',
  [FOfferType.Residential]: 'residential',
  [FOfferType.Garage]: 'garage',
  [FOfferType.Any]: 'any',
};

export function trackGeoSuggestAutoOfferChange(kpId: number, oldOfferType: FOfferType) {
  ca('event', {
    name: 'oldevent',
    category: 'GeoSuggest',
    action: 'AutoOfferChange',
    label: `${kpId}/${OFFER_TYPE_TRACKING_LABEL[oldOfferType]}/suburban`,
  });
}
