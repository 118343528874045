import { IOffer } from '../../../../types/offer';

export function getAgentFee(offer: IOffer): string {
  const { agentFee, clientFee } = offer.bargainTerms;
  const fee: string[] = [];

  if (agentFee != null) {
    fee.push(`аг.\u00A0${agentFee}%`);
  }

  if (clientFee != null) {
    fee.push(`кл.\u00A0${clientFee}%`);
  }

  return fee.join(', ');
}
