import { IJsonQueryGeo, IJsonQueryRegionalDistrict } from '../../../json_query';

export function removeRegionalDistrictFromJsonQuery(
  geo: IJsonQueryGeo | undefined,
  id: number,
): IJsonQueryGeo | undefined {
  const value = geo?.value.filter(item => item.type !== 'regional_district_id' || item.id !== id);

  return value?.length ? { type: 'geo', value } : undefined;
}

export function addRegionalDistrictToJsonQuery(geo: IJsonQueryGeo | undefined, id: number): IJsonQueryGeo | undefined {
  const regionalDistrictItem: IJsonQueryRegionalDistrict = { id, type: 'regional_district_id' };

  return {
    type: 'geo',
    value: geo ? [...geo.value, regionalDistrictItem] : [regionalDistrictItem],
  };
}

export function getRegionalDistrictsFromJsonQuery(geo: IJsonQueryGeo | undefined): IJsonQueryRegionalDistrict[] {
  if (!geo) {
    return [];
  }

  return geo.value.filter(value => value.type === 'regional_district_id') as IJsonQueryRegionalDistrict[];
}

export const unselectRegionalDistrict = (selectedDistricts: IJsonQueryRegionalDistrict[], id: number) => {
  return selectedDistricts.filter(district => district.id !== id);
};
