import * as R from 'ramda';

import { resetTerms, setTerm } from './helpers';
import { EFacilityType, NonEmptyArray, TJsonQuery } from './types';

export function setFacilityTypes(jsonQuery: TJsonQuery) {
  return (facilityTypes: NonEmptyArray<EFacilityType> | null): TJsonQuery => {
    let nextJsonQuery = R.clone(jsonQuery);

    nextJsonQuery = resetTerms([
      'conditioner',
      'has_furniture',
      'internet',
      'has_fridge',
      'phone',
      'dish_washer',
      'has_kitchen_furniture',
      'tv',
      'has_washer',
    ])(nextJsonQuery);

    if (facilityTypes) {
      if (facilityTypes.includes(EFacilityType.Conditioner)) {
        nextJsonQuery = setTerm('conditioner')(nextJsonQuery)(true);
      }

      if (facilityTypes.includes(EFacilityType.WithoutFurniture)) {
        nextJsonQuery = setTerm('has_furniture')(nextJsonQuery)(false);
        nextJsonQuery = setTerm('has_kitchen_furniture')(nextJsonQuery)(false);
      }

      if (facilityTypes.includes(EFacilityType.RoomFurniture)) {
        nextJsonQuery = setTerm('has_furniture')(nextJsonQuery)(true);
      }

      if (facilityTypes.includes(EFacilityType.KitchenFurniture)) {
        nextJsonQuery = setTerm('has_kitchen_furniture')(nextJsonQuery)(true);
      }

      if (facilityTypes.includes(EFacilityType.Internet)) {
        nextJsonQuery = setTerm('internet')(nextJsonQuery)(true);
      }

      if (facilityTypes.includes(EFacilityType.Fridge)) {
        nextJsonQuery = setTerm('has_fridge')(nextJsonQuery)(true);
      }

      if (facilityTypes.includes(EFacilityType.Phone)) {
        nextJsonQuery = setTerm('phone')(nextJsonQuery)(true);
      }

      if (facilityTypes.includes(EFacilityType.Dishwasher)) {
        nextJsonQuery = setTerm('dish_washer')(nextJsonQuery)(true);
      }

      if (facilityTypes.includes(EFacilityType.TV)) {
        nextJsonQuery = setTerm('tv')(nextJsonQuery)(true);
      }

      if (facilityTypes.includes(EFacilityType.Washer)) {
        nextJsonQuery = setTerm('has_washer')(nextJsonQuery)(true);
      }
    }

    return nextJsonQuery;
  };
}
