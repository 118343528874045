import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackButtonClick } from './tracking';
import { MultiSelectDesktop } from '../../components/MultiSelectDesktop';
import { NewbuildingHousesFilter } from '../../components/NewbuildingHousesFilter';
import { getHouses, getNewbuildingId, getActiveHouses } from '../../selectors/newbuildingHeader';

export const NewbuildingHousesFilterContainer = () => {
  const houseIdSelected = useSelector(getActiveHouses);
  const housesFilterOptions = useSelector(getHouses);
  const newbuildingId = useSelector(getNewbuildingId);
  const [value, setValue] = React.useState(houseIdSelected);
  const dispatch = useDispatch();

  const onChange = React.useCallback(
    (value: number[]) => {
      setValue(value);
      dispatch({
        type: 'filters/newbuildig_houses/SET_NEWBUILDING_HOUSE_IDS',
        houseIds: value,
        newbuildingId,
      });

      dispatch({
        type: 'filters/search/SEARCH_REQUEST_START_REQUESTED',
      });

      trackButtonClick(newbuildingId as number);
    },
    [dispatch, newbuildingId],
  );

  if (!housesFilterOptions) {
    return null;
  }

  return (
    <NewbuildingHousesFilter>
      <MultiSelectDesktop
        defer={true}
        placeholderActive={true}
        placeholder="Все корпуса"
        value={value}
        options={housesFilterOptions.map(house => ({
          value: house.id,
          label: house.name,
          description: house.finishStatus,
        }))}
        onChange={onChange}
      />
    </NewbuildingHousesFilter>
  );
};
