import { InputAdornmentButton } from '@cian/ui-kit';
import * as React from 'react';

import { FDealType, dealTypeFromJsonQuery } from '../../../../../JsonQuery';
import { GeoInputAddonSection } from '../../../components/GeoInputAddonSection';
import { useContext } from '../../../utils/useContext';

export function GeoSwitcherButtonsContainer() {
  const { onGeoPopupOpen } = useContext();

  return (
    <GeoInputAddonSection>
      <InputAdornmentButton type="button" onClick={onGeoPopupOpen}>
        Регион
      </InputAdornmentButton>
      <RegionalDistrictsPopupButtonContainer />
      <AdditionalGeoPopupButtonContainer />
      <UndergroundPopupButtonContainer />
    </GeoInputAddonSection>
  );
}

function AdditionalGeoPopupButtonContainer() {
  const {
    hasDistricts,
    hasHighways,
    hasRegionalDistricts,
    onDistrictPopupOpen,
    onHighwaysPopupOpen,
    jsonQuery,
    hasUnderground,
  } = useContext();

  const dealType = dealTypeFromJsonQuery(jsonQuery);

  if (dealType === FDealType.RentDaily && hasUnderground) {
    return null;
  }

  switch (true) {
    case hasDistricts && !hasRegionalDistricts:
      return (
        <InputAdornmentButton type="button" onClick={onDistrictPopupOpen}>
          Район
        </InputAdornmentButton>
      );

    case hasHighways:
      return (
        <InputAdornmentButton type="button" onClick={onHighwaysPopupOpen}>
          Шоссе
        </InputAdornmentButton>
      );
    default:
      return null;
  }
}

function RegionalDistrictsPopupButtonContainer() {
  const { hasRegionalDistricts, onRegionalDistrictsOpen } = useContext();

  if (!hasRegionalDistricts) {
    return null;
  }

  return (
    <InputAdornmentButton type="button" onClick={onRegionalDistrictsOpen}>
      Район
    </InputAdornmentButton>
  );
}

function UndergroundPopupButtonContainer() {
  const { hasUnderground, onUndergroundPopupOpen } = useContext();

  if (!hasUnderground) {
    return null;
  }

  return (
    <InputAdornmentButton type="button" onClick={onUndergroundPopupOpen}>
      Метро
    </InputAdornmentButton>
  );
}
