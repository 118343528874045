import * as React from 'react';

import { ERentTime } from '../../../../json_query';
import { IRentTimeTag } from '../../../state/tags/definitions/rent_time';
import { TagNew } from '../TagNew';

export interface IRentTimeTagItemProps {
  tag: IRentTimeTag;
  onDeleteClick(): void;
}

const rentTimeMap = {
  [ERentTime.FromYear]: 'Аренда от года',
  [ERentTime.FewMonths]: 'От нескольких месяцев',
};

export const RentTimeTagItem = ({ tag, onDeleteClick }: IRentTimeTagItemProps) => {
  if (tag.value === ERentTime.FromYear || tag.value === ERentTime.FewMonths) {
    return <TagNew onDeleteClick={onDeleteClick}>{rentTimeMap[tag.value]}</TagNew>;
  }

  return null;
};
