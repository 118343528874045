import { pathOr } from 'ramda';
import * as React from 'react';

import { PublicationDate } from '../../../components/advancedFilters/PublicationDate';
import { EPublishPeriod } from '../../../types/jsonQuery';
import { useContext } from '../../../utils/useContext';

export const PublicationDateContainer: React.FC = () => {
  const { onChange, jsonQuery } = useContext();

  const period = pathOr<EPublishPeriod | null>(null, ['publish_period', 'value'], jsonQuery);
  const hasPhoto = pathOr<boolean | null>(null, ['wp', 'value'], jsonQuery);
  const hasVideo = pathOr<boolean | null>(null, ['has_video', 'value'], jsonQuery);

  const handlePublishPeriodChange = React.useCallback(
    (period: EPublishPeriod | null) => {
      onChange({ action: 'setPublishPeriod', arguments: [period] });
    },
    [onChange],
  );

  const handleHasVideoChange = React.useCallback(
    (hasVideo: boolean | null) => {
      onChange({ action: 'setHasVideo', arguments: [hasVideo] });
    },
    [onChange],
  );

  const handleHasPhotoChange = React.useCallback(
    (hasPhoto: boolean | null) => {
      onChange({ action: 'setHasPhoto', arguments: [hasPhoto] });
    },
    [onChange],
  );

  return (
    <PublicationDate
      publishPeriod={period}
      hasPhoto={hasPhoto}
      hasVideo={hasVideo}
      onPublishPeriodChange={handlePublishPeriodChange}
      onHasPhotoChange={handleHasPhotoChange}
      onHasVideoChange={handleHasVideoChange}
    />
  );
};
