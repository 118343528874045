/* eslint-disable max-lines */
import * as R from 'ramda';

import { removeNewbuildingHouses, setRootType, setTerm, setTerms } from './helpers';
import { setDealType } from './setDealType';
import { setRoomTypes } from './setRoomTypes';
import {
  EBuildingStatus,
  EObjectType,
  EOfficeType,
  ERoomType,
  IJsonQuery,
  IJsonQueryType,
} from '../../repositories/common/json_query';
import { FDealType, FOfferType, isSuburban, offerTypeFromJsonQuery } from '../../utils/category';

export function selectOfferType(jsonQuery: IJsonQuery): (nextOfferType: FOfferType) => IJsonQuery {
  let nextJsonQuery = R.clone(jsonQuery);

  return nextOfferType => {
    const offerType = offerTypeFromJsonQuery(jsonQuery);
    const fromDeveloper = jsonQuery.from_developer && jsonQuery.from_developer.value;
    const newObject = jsonQuery.geo && jsonQuery.geo.value.find(geo => geo.type === 'newobject');

    /**
     * @description Удаляет корпус ЖК если выбран тип здания не новстройка
     */
    if ((nextOfferType & FOfferType.FlatNew) !== 0) {
      nextJsonQuery = removeNewbuildingHouses()(nextJsonQuery);
    }

    if (!(isSuburban(offerType) && isSuburban(nextOfferType))) {
      nextJsonQuery = setTerm('object_type')(nextJsonQuery)(undefined);
    }
    nextJsonQuery = setTerm('building_status')(nextJsonQuery)(undefined);

    if (fromDeveloper !== undefined && newObject && nextOfferType !== FOfferType.FlatOld) {
      nextJsonQuery = setTerm('from_developer')(nextJsonQuery)(fromDeveloper);
    } else {
      nextJsonQuery = setTerm('from_developer')(nextJsonQuery)(undefined);
    }
    nextJsonQuery = setTerm('with_newobject')(nextJsonQuery)(undefined);
    nextJsonQuery = setTerm('show_hotels')(nextJsonQuery)(undefined);

    const offerTypeIsRoomType = (offerType & (FOfferType.FlatShared | FOfferType.Room | FOfferType.Bed)) !== 0;
    const nextOfferTypeIsRoomType = [FOfferType.FlatShared, FOfferType.Room, FOfferType.Bed].includes(nextOfferType);
    if ((offerTypeIsRoomType && !nextOfferTypeIsRoomType) || (!offerTypeIsRoomType && nextOfferTypeIsRoomType)) {
      nextJsonQuery = setTerm('room')(nextJsonQuery)(undefined);
    }

    switch (nextOfferType) {
      case FOfferType.Flat:
        nextJsonQuery = setOfferType(nextJsonQuery)('flat');
        break;
      case FOfferType.FlatOld:
        nextJsonQuery = setOfferType(nextJsonQuery)('flat');
        nextJsonQuery = setTerm('building_status')(nextJsonQuery)(EBuildingStatus.Old);
        break;
      case FOfferType.FlatNew:
        nextJsonQuery = setOfferType(nextJsonQuery)('flat');
        nextJsonQuery = setTerm('building_status')(nextJsonQuery)(EBuildingStatus.New);
        break;
      case FOfferType.FlatShared:
        nextJsonQuery = setOfferType(nextJsonQuery)('flat');
        nextJsonQuery = selectRoomType(nextJsonQuery)(ERoomType.FlatShared);
        break;
      case FOfferType.Room:
        nextJsonQuery = setOfferType(nextJsonQuery)('flat');
        nextJsonQuery = selectRoomType(nextJsonQuery)(ERoomType.Room);
        break;
      case FOfferType.Bed:
        nextJsonQuery = setOfferType(nextJsonQuery)('flat');
        nextJsonQuery = selectRoomType(nextJsonQuery)(ERoomType.Bed);
        break;
      case FOfferType.Suburban:
        nextJsonQuery = setDealType(nextJsonQuery)(FDealType.Sale);
        nextJsonQuery = setOfferType(nextJsonQuery)('suburban');
        nextJsonQuery = setTerms('object_type')(nextJsonQuery)([
          EObjectType.House,
          EObjectType.Townhouse,
          EObjectType.Housepart,
          EObjectType.Land,
        ]);
        break;
      case FOfferType.House:
        nextJsonQuery = setOfferType(nextJsonQuery)('suburban');
        nextJsonQuery = selectSuburbanObjectType(nextJsonQuery)(EObjectType.House);
        break;
      case FOfferType.Townhouse:
        nextJsonQuery = setOfferType(nextJsonQuery)('suburban');
        nextJsonQuery = selectSuburbanObjectType(nextJsonQuery)(EObjectType.Townhouse);
        break;
      case FOfferType.HousePart:
        nextJsonQuery = setOfferType(nextJsonQuery)('suburban');
        nextJsonQuery = selectSuburbanObjectType(nextJsonQuery)(EObjectType.Housepart);
        break;
      case FOfferType.Land:
        nextJsonQuery = setDealType(nextJsonQuery)(FDealType.Sale);
        nextJsonQuery = setOfferType(nextJsonQuery)('suburban');
        nextJsonQuery = selectSuburbanObjectType(nextJsonQuery)(EObjectType.Land);
        break;
      case FOfferType.Garage:
        nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = setTerms('office_type')(nextJsonQuery)([EOfficeType.Garage]);
        break;
      case FOfferType.Hotel:
        nextJsonQuery = setOfferType(nextJsonQuery)('flat');
        nextJsonQuery = setTerm('show_hotels')(nextJsonQuery)(true);
        break;
      default:
    }

    return nextJsonQuery;
  };
}

function selectSuburbanObjectType(jsonQuery: IJsonQuery) {
  return (objectType: EObjectType): IJsonQuery => {
    if (jsonQuery.object_type) {
      if (!jsonQuery.object_type.value.includes(objectType)) {
        return setTerms('object_type')(jsonQuery)(jsonQuery.object_type.value.concat(objectType));
      } else {
        return setTerms('object_type')(jsonQuery)(jsonQuery.object_type.value.filter(type => type !== objectType));
      }
    } else {
      return setTerms('object_type')(jsonQuery)([objectType]);
    }
  };
}

function selectRoomType(jsonQuery: IJsonQuery) {
  return (roomType: ERoomType): IJsonQuery => {
    if (jsonQuery.room) {
      if (!jsonQuery.room.value.includes(roomType)) {
        return setRoomTypes(jsonQuery)(jsonQuery.room.value.concat(roomType));
      } else {
        return setRoomTypes(jsonQuery)(jsonQuery.room.value.filter(type => type !== roomType));
      }
    } else {
      return setRoomTypes(jsonQuery)([roomType]);
    }
  };
}

function setOfferType(jsonQuery: IJsonQuery) {
  return (offerType: 'flat' | 'commercial' | 'suburban' | 'newobject'): IJsonQuery => {
    const currentType = jsonQuery._type;
    const nextType: IJsonQueryType = currentType.replace(
      /flat|commercial|suburban|newobject/,
      offerType,
    ) as IJsonQueryType;

    return setRootType(nextType)(jsonQuery);
  };
}
