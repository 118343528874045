import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import { FromUndergroundTime } from './parts/FromUndergroundTime';
import { UndergroundIcon } from './parts/UndergroundIcon';
import * as styles from './styles.css';
import { INewbuildingPromoBuilderUnderground } from '../../types/newbuildingPromoBuilderOffers';

type INewbuildingPromoUndergroundProps = INewbuildingPromoBuilderUnderground;

export function NewbuildingPromoUnderground({
  lineColor,
  name,
  transportType,
  cityType,
  timeDisplay,
}: INewbuildingPromoUndergroundProps) {
  return (
    <div className={styles['underground']}>
      <div className={styles['underground-station']}>
        {lineColor && cityType && <UndergroundIcon lineColor={lineColor} undergroundCityType={cityType} />}
        <UIText2>{name}</UIText2>
      </div>
      {transportType && <FromUndergroundTime transportType={transportType} timeTo={timeDisplay} />}
    </div>
  );
}
