import * as React from 'react';

import GeoSuggest, { IGeoSuggestItem } from '../../../common/components/geo_suggest';
import { ILocation } from '../../../types/location';
import { GeoSuggestItemType } from '../geo_suggest/helpers';

export interface ILocationSwitherAutocompleteProps {
  suggestions: ILocation[];
  loading: boolean;
  onValueChange(value: string): void;
  onSelect(suggestion: IGeoSuggestItem<ILocation>): void;
}

export class LocationSwitherAutocomplete extends React.Component<ILocationSwitherAutocompleteProps> {
  private suggest: GeoSuggest | null = null;

  public render() {
    return (
      <div style={{ width: '360px' }}>
        <GeoSuggest
          {...this.props}
          ref={suggest => (this.suggest = suggest)}
          onSelect={this.onSelect}
          placeholder="Введите регион или город"
          suggestions={
            this.props.suggestions &&
            this.props.suggestions.map(suggestion => ({
              title: suggestion.displayName,
              value: suggestion,
              suggestionType: GeoSuggestItemType.Address,
            }))
          }
        />
      </div>
    );
  }

  private onSelect = (suggestion: IGeoSuggestItem<ILocation>) => {
    if (this.suggest) {
      this.suggest.reset();
    }
    this.props.onSelect(suggestion);
  };
}
