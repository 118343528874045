import { EMetroTravelType } from '../../../../json_query';
import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const UNDERGROUND_TIME_TAG_TYPE = 'advanced-undergroundTime';

export interface IUndergroundTimeTag {
  type: 'advanced-undergroundTime';
  travelType: EMetroTravelType;
  travelTime: number;
}

function isUndergroundTimeTag(tag: TTag): tag is IUndergroundTimeTag {
  return tag.type === UNDERGROUND_TIME_TAG_TYPE;
}

export const undergroundTimeDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const travelType = jsonQuery['only_foot'] && jsonQuery['only_foot'].value;
    const travelTime = jsonQuery['foot_min'] && jsonQuery['foot_min'].value.lte;

    if (travelType && travelTime) {
      return ensureSingleTagExists(tags, isUndergroundTimeTag, () => ({
        type: UNDERGROUND_TIME_TAG_TYPE,
        travelTime,
        travelType,
      }));
    } else {
      return ensureTagNotExists(tags, isUndergroundTimeTag);
    }
  },
};
