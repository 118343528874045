// Данные для посуточных объявлений и отелей
export interface IDailyRentData {
  realtyId: number;
  /** Тип действия для кнопки **/
  actionType: EActionType;
  // Данные для отелей
  hotelData?: {
    // Рейтинг отеля в звездах
    starRating?: string;
    // Подпись под ценой для отелей например "за 1 номер, 2 гостя"
    priceSubtitle?: string;
    // Основной признак принадлежности объявки к отелям
    isHotel?: boolean;
  };
  // Отзывы объектов циан и партнерских объявлений
  rating?: {
    // Оценка
    rating?: string;
    // Количество отзывов
    reviewsCount?: string;
  };
  /** Можно ли посуточное объявление забронировать онлайн */
  isOnlineBooking: boolean;
}

export enum EActionType {
  /** Возможность связаться напрямую **/
  'Contact' = 'contact',
  /** Бронирование **/
  'OtaBooking' = 'otaBooking',
  /** Проверка дат **/
  'OtaCheckDates' = 'otaCheckDates',
  /** Забронировать партнёрский объект **/
  'PartnerBooking' = 'partnerBooking',
  /** Проверка дат у партнёрского объекта **/
  'PartnerCheckDates' = 'partnerCheckDates',
}
