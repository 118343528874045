import { ca } from '@cian/analytics';

export const trackProfessionalSearchClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'popup_more_param',
    label: 'ListingLK',
  });
};
