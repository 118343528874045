import * as React from 'react';

import { IIncludeNewMoscowTag } from '../../../state/tags/definitions/new_moscow';
import { TagNew } from '../TagNew';

export interface INewMoscowTagItemProps {
  tag: IIncludeNewMoscowTag;
  onDeleteClick(): void;
}

export const NewMoscowTagItem = (props: INewMoscowTagItemProps) => {
  return <TagNew onDeleteClick={props.onDeleteClick}>{props.tag.value ? 'Новая Москва' : 'Без Новой Москвы'}</TagNew>;
};
