import * as React from 'react';

import { wrapDisplayName } from '../../displayName/shared';

export function withEmptyRendering<T>(validator: (props: T) => boolean) {
  return function wrap(Component: React.ComponentType<T>): React.ComponentType<T> {
    function Wrapper(props: T) {
      const isInvalid = validator(props);

      if (isInvalid) {
        return null;
      }

      // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any
      return <Component {...(props as any)} />;
    }

    Wrapper.displayName = wrapDisplayName(Component, 'withEmptyRendering');

    return Wrapper;
  };
}
