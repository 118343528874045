import { useBooleanState } from '@cian/react-utils';
import { Button } from '@cian/ui-kit';
import { IconFileExcel16, IconFilePrint16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectIsUserAgent } from '../../../packages/Filters/shared/selectors';
import { selectProfessionalSearchUrl } from '../../selectors/selectProfessionalSearchUrl';
import {
  DownloadExcelModalRealtors,
  DownloadExcelModalSearchers,
} from '../../serp/components/bottom_print_excel/components/DownloadExcelModal';
import { PrintPopupContainer } from '../../serp/components/bottom_print_excel/print_popup/container';
import { trackDownloadExcelBtnClick, trackPrintOffersClicked } from '../../serp/components/bottom_print_excel/tracking';
import { useDownloadExcelFile } from '../../serp/components/bottom_print_excel/utils/useDownloadExcelFile';

export function PaginationButtonsContainer() {
  const { state: excelPopupOpen, setFalse: closeExcelPopup, setTrue: openExcelPopup } = useBooleanState(false);
  const { state: printPopupOpen, setFalse: closePrintPopup, setTrue: openPrintPopup } = useBooleanState(false);
  const profsearchUrl = useSelector(selectProfessionalSearchUrl);
  const isAgent = useSelector(selectIsUserAgent);

  const { error, handleDownloadExcelFile, isLoading, resetError } = useDownloadExcelFile();

  /** Сбросит ошибку при закрытии модалки */
  React.useEffect(() => {
    if (!excelPopupOpen) {
      resetError();
    }
  }, [excelPopupOpen, resetError]);

  const handleOpenExcelPopup = React.useCallback(() => {
    openExcelPopup();
    trackDownloadExcelBtnClick();
  }, [openExcelPopup]);

  const handleOpenPrintPopup = React.useCallback(() => {
    openPrintPopup();
    trackPrintOffersClicked();
  }, [openPrintPopup]);

  const DownloadExcelModalComponent = isAgent ? DownloadExcelModalRealtors : DownloadExcelModalSearchers;

  return (
    <>
      <Button
        size="XS"
        beforeIcon={<IconFileExcel16 color="current_color" />}
        theme="fill_white_primary"
        onClick={handleOpenExcelPopup}
      >
        Сохранить файл Excel
      </Button>

      <Button
        size="XS"
        beforeIcon={<IconFilePrint16 color="current_color" />}
        theme="fill_white_primary"
        onClick={handleOpenPrintPopup}
      >
        Распечатать объявления
      </Button>
      <PrintPopupContainer open={printPopupOpen} close={closePrintPopup} />
      <DownloadExcelModalComponent
        isLoading={isLoading}
        isOpened={excelPopupOpen}
        onClose={closeExcelPopup}
        onDownloadClick={handleDownloadExcelFile}
        error={error}
        profsearchUrl={profsearchUrl}
      />
    </>
  );
}
