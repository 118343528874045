import * as React from 'react';

import { ESuburbanUtility } from '../../../../json_query';
import { ISuburbanUtilityTag } from '../../../state/tags/definitions/suburban_utilities';
import { TagNew } from '../TagNew';

export interface ISuburbanUtilityTagItemProps {
  tag: ISuburbanUtilityTag;
  onDeleteClick(): void;
}

const utilitiesMap = {
  [ESuburbanUtility.Gas]: 'С газом',
  [ESuburbanUtility.Water]: 'Есть водоснабжение',
  [ESuburbanUtility.Drainage]: 'С канализацией',
  [ESuburbanUtility.Electricity]: 'С электричеством',
};

export const SuburbanUtilityTagItem = ({ tag, onDeleteClick }: ISuburbanUtilityTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>{utilitiesMap[tag.value]}</TagNew>;
};
