import * as React from 'react';

export const ZoomIn: React.FC = () => {
  return (
    // FIXME
    // eslint-disable-next-line react/forbid-elements
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Увеличить"
    >
      <line x1="8" x2="8" y2="16" stroke="#152242" strokeWidth="2" />
      <line x1="16" y1="8" y2="8" stroke="#152242" strokeWidth="2" />
    </svg>
  );
};

ZoomIn.displayName = 'ZoomIn';
