import { IAppState, TActions } from '../../../common/state';
import { jq } from '../../../json_query';

export const IDeveloperTypeChangedActionType = 'filters/developer/DEVELOPER_TYPE_CHANGED';

export interface IDeveloperTypeChangedAction {
  type: 'filters/developer/DEVELOPER_TYPE_CHANGED';
  value: boolean | undefined;
}

export function changeDeveloperType(value: boolean | undefined): IDeveloperTypeChangedAction {
  return {
    type: IDeveloperTypeChangedActionType,
    value,
  };
}

export function developerTypeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IDeveloperTypeChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setDeveloperType(action.value),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-developer-type') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).setDeveloperType(undefined),
          },
        };
      }

      return state;

    default:
      return state;
  }
}
