import { numberToPrettyString } from '@cian/utils';

import { IOffer } from '../../../../types/offer';
import { getCurrencySign } from '../getCurrencySign';

export function getDepositPrice({ bargainTerms: { deposit, currency } }: IOffer): string {
  if (deposit) {
    const price = numberToPrettyString(deposit);

    if (currency === 'rur') {
      return `залог ${price}\u00A0₽`;
    } else {
      return `залог ${getCurrencySign(currency)}${price}`;
    }
  }

  return 'без залога';
}
