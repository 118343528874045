import { ca } from '@cian/analytics';

import { IOffer } from '../../../types/offer';
import { getAnalyticsInfo } from '../../../utils/getAnalyticsInfo';

export type THintType = 'no_offer' | 'with_offer' | 'up_search' | 'not_premium';
export type TActionType = 'show' | 'click' | 'close';

export const trackAuctionHint = (hintType: THintType, actionType: TActionType, offer: IOffer) => {
  const product = getAnalyticsInfo(offer);

  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'auction_hint',
    label: `${hintType}_${actionType}`,
    products: [product],
  });
};

export const trackAuctionChangeBetClick = (offer: IOffer) => {
  const product = getAnalyticsInfo(offer);

  ca('event', {
    name: 'oldevent',
    category: 'Auction_block',
    action: 'Change_bid',
    label: 'bid',
    products: [product],
  });
};

export const trackAuctionSendBetClick = (offer: IOffer, bet: number) => {
  const product = getAnalyticsInfo(offer);

  ca('event', {
    name: 'oldevent',
    category: 'Auction_block',
    action: 'Send_bid',
    label: bet,
    products: [product],
  });
};

export const trackAuctionReduceBetPopupShow = () => {
  ca('event', {
    name: 'oldevent',
    category: 'Auction_block',
    action: 'Open_need_confirm_popup',
    label: 'decrease_bid',
  });
};

export const trackAuctionNotEnoughMoneyPopupShow = () => {
  ca('event', {
    name: 'oldevent',
    category: 'Auction_block',
    action: 'Open_not_enough_money_popup',
  });
};

export const trackAuctionNotEnoughMoneyPopupPayClick = () => {
  ca('event', {
    name: 'oldevent',
    category: 'Auction_block',
    action: 'Click_not_enough_money_popup',
    label: 'go_to_pay',
  });
};

export const trackAuctionErrorPopupShow = () => {
  ca('event', {
    name: 'oldevent',
    category: 'Auction_block',
    action: 'Open_error_popup',
  });
};

export const trackAuctionErrorPopupRetry = () => {
  ca('event', {
    name: 'oldevent',
    category: 'Auction_block',
    action: 'Click_error_popup',
    label: 'retry',
  });
};
