import { IAppState } from '../../common/state';

export const getSnippetNewbuildingBrokerParams = (state: IAppState) => {
  const brokerParams = state.newbuildingInformation?.newbuildingBrokerCommon;
  const isBrokerWidgetAvailable =
    brokerParams?.partnerRewardsRegistrationStatus === 'connected' ||
    brokerParams?.partnerRewardsRegistrationStatus === 'inProgress';

  if (isBrokerWidgetAvailable && brokerParams?.accountLegalType) {
    return {
      accountLegalType: brokerParams.accountLegalType,
      hasConfirmedPhone: brokerParams.hasConfirmedPhone,
    };
  }

  return null;
};
