import { IconActionArrowUpRight16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './postfixPopupLink.css';

interface IPostfixPopupLinkProps {
  href: string;
  text: string;
}

export const PostfixPopupLink: React.FC<IPostfixPopupLinkProps> = ({ text, href }) => {
  return (
    <a href={href} className={styles['container']}>
      <span className={styles['text']}>{text}</span>
      <IconActionArrowUpRight16 color="current_color" />
    </a>
  );
};
