import * as React from 'react';

import { ISuburbanHouseTypeTag } from '../../../state/tags/definitions/suburban_house_type';
import { TagNew } from '../TagNew';

export interface ISuburbanHouseTypeTagItemProps {
  tag: ISuburbanHouseTypeTag;
  onDeleteClick(): void;
}

export const SuburbanHouseTypeTagItem = ({ tag, onDeleteClick }: ISuburbanHouseTypeTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>Тип дома: {tag.value ? 'Дача' : 'Для постоянного проживания'}</TagNew>;
};
