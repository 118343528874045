import { dealTypeFromJsonQuery } from './category';
import { getObjectTypeWithJsonQuery, getDealType } from './trackings';
import { IFiltersState, IResultsState, ITrackingData, ITrackingUserData } from '../common/state';
import { IBreadcrumb } from '../offer';
import { ESortValue } from '../repositories/common/json_query';
import { ITrackingPageData } from '../serp/state/tracking';
import { TUser } from '../types/user';

/**
 * Подготавливает данные трекинга для страницы
 */
export const prepareTrackingData = (
  filters: IFiltersState,
  results: IResultsState,
  breadcrumbs: IBreadcrumb[],
  userData: TUser,
): ITrackingData => {
  let user: ITrackingUserData = {
    type: 'not_authorized',
  };

  if (userData.isAuthenticated) {
    user = {
      type: userData.userType,
    };

    if (user.type !== 'not_authorized') {
      user.userId = userData.userId;
      user.cianUserId = userData.id;
      user.email = userData.email;
    }
  }

  const { jsonQuery, totalOffers, queryString } = results;
  const { jsonQuery: jsonQueryFromFilters } = filters;

  const region = jsonQuery.region
    ? String(jsonQuery.region.value[0])
    : jsonQueryFromFilters && jsonQueryFromFilters.region
      ? String(jsonQueryFromFilters.region.value[0])
      : '1';

  const page: ITrackingPageData = {
    pageType: 'Listing',
    siteType: 'desktop',
    breadCrumbs: breadcrumbs ? breadcrumbs.map(b => b.title || '') : [],
    dealType: getDealType(dealTypeFromJsonQuery(jsonQuery)),
    objectType: getObjectTypeWithJsonQuery(jsonQuery),
    region,
    listingType: 'list',
    sortType: jsonQuery.sort ? jsonQuery.sort.value : ESortValue.Default,
    pageNumber: jsonQuery.page ? Number(jsonQuery.page.value) : 1,
    offersQty: totalOffers,
    queryString,
  } as ITrackingPageData;

  return {
    page,
    user,
  };
};
