import * as React from 'react';

import { TagNew } from '../TagNew';

export interface IDomrfTagItemProps {
  onDeleteClick(): void;
}

export const DomrfTagItem = (props: IDomrfTagItemProps) => {
  return <TagNew onDeleteClick={props.onDeleteClick}>Аренда от ДОМ.РФ</TagNew>;
};
