import { IAppState } from '../../../../common/state';
import { INewbuildingInfoForTgbSchema } from '../../../../repositories/newbuilding-search/entities/get_newbuildings_info_for_tgb/NewbuildingInfoForTgbSchema';
import { EInfoForTgbActions } from '../actionTypes';
import { FetchNewbuildingsInfoForTgbSuccess } from '../setNewbuildingsInfoForTgb';

export type InfoForTgbState = {
  jkInfoForTgbCollection: Record<number, INewbuildingInfoForTgbSchema>;
};

export function infoForTgbReducer(state: IAppState, action: FetchNewbuildingsInfoForTgbSuccess): IAppState {
  switch (action.type) {
    case EInfoForTgbActions.fetchNewbuildingsInfoForTgbSuccess:
      return {
        ...state,
        infoForTgb: {
          ...state.infoForTgb,
          jkInfoForTgbCollection: action.payload,
        },
      };
    default:
      return state;
  }
}
