import { ca } from '@cian/analytics';

export function trackMetroModalOpenEvent() {
  ca('event', {
    action: 'popup_metro_open',
    category: 'Listing',
    label: '',
    name: 'oldevent',
  });
}
