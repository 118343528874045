/* eslint-disable max-lines */
import { Spinner } from '@cian/ui-kit';
import classNames from 'classnames';
import { equals } from 'ramda';
import * as React from 'react';

import { loadYmapsApi } from './../../../utils/ymaps';
import { Canvas } from './canvas';
import { BaseDrawObject } from './draw_objects/base_draw_object';
import { Circle, ICircleObjectData } from './draw_objects/circle';
import { getPolygonGeometryFromMousePath } from './draw_objects/helpers';
import { Polygon, IPolygonObjectData } from './draw_objects/polygon';
import { MapTopMenu } from './top_menu';
import { DrawMode, TDrawMode } from './top_menu/helpers';
import { TMapObjectData } from '../../../filters/state/map/map_changed';
import { IJsonQueryCircle, IJsonQueryPolygon } from '../../../json_query';
import { coordsArrayNumberToString } from '../../../utils/geo';

const style = require('./index.css');

type TMapAreaObjectData = IPolygonObjectData | ICircleObjectData;
type TMapAreaObjectModel = IJsonQueryCircle | IJsonQueryPolygon;
type TMapAreaObject = BaseDrawObject<TMapAreaObjectData, TMapAreaObjectModel>;

export interface IMapAreaModels {
  polygons: IJsonQueryPolygon[];
  circles: IJsonQueryCircle[];
}

export interface IMapProps {
  areaModels: IMapAreaModels | null;
  center?: [number, number];

  onChange?(pointsData: TMapObjectData[]): void;
  getRanges?(): void;
  onDelete(area: TMapObjectData): void;
}

export interface IMapState {
  drawMode: TDrawMode;
  editedObject: TMapAreaObject | null;
  isRemoveMenuVisible: boolean;
  removeMenuPosition: { x: number; y: number };
  isLoaded: boolean;
  isCanvasVisible: boolean;
  isLimitReachedMessageVisible: boolean;
}

export class Map extends React.Component<IMapProps, IMapState> {
  public static defaultProps = {
    onChange: () => {},
    getRanges: () => {},
    center: [55.76, 37.64],
  };

  public state: IMapState = {
    drawMode: DrawMode.None,
    editedObject: null,
    isRemoveMenuVisible: false,
    removeMenuPosition: { x: 0, y: 0 },
    isLoaded: false,
    isCanvasVisible: false,
    isLimitReachedMessageVisible: false,
  };

  private timer: number | null = null;

  private map: IYmapsMap;
  private ymaps: YMaps.IYMaps;
  private container: Element | null = null;
  private areaObjects: TMapAreaObject[];

  public componentDidMount() {
    loadYmapsApi({
      require: [
        'Map',
        'control.ZoomControl',
        'geoObject.addon.editor',
        'geometryEditor.Polygon',
        'GeoObject',
        'Circle',
        'geoQuery',
      ],
    }).then(ymaps => {
      this.setState({ isLoaded: true });
      this.initMap(ymaps);
      this.createAreaObjects();
      this.applyBounds();
    });
  }

  public componentWillUnmount() {
    this.areaObjects.forEach(item => item.delete());

    if (this.timer) {
      window.clearTimeout(this.timer);
    }

    this.map.destroy();
  }

  public componentDidUpdate() {
    if (this.state.isLimitReachedMessageVisible) {
      if (this.timer) {
        window.clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = window.setTimeout(() => {
        this.setState({
          isLimitReachedMessageVisible: false,
        });
      }, 2000);
    }
  }

  public render() {
    return (
      <div className={style.map}>
        {this.state.isLoaded ? (
          <MapTopMenu drawMode={this.state.drawMode} onChange={this.menuHandler} />
        ) : (
          <div className={style['preloaderWrapper']}>
            <Spinner size={50} />
          </div>
        )}
        <div className={style.mapContainer} ref={container => (this.container = container)} />

        {this.state.isCanvasVisible && (
          <div className={style.canvasContainer}>
            <Canvas onDrawFinished={this.onDrawFinished} />
          </div>
        )}

        {this.state.isRemoveMenuVisible && (
          <button
            className={style['deleteMenu']}
            onClick={this.removeArea}
            style={{
              top: this.state.removeMenuPosition.y,
              left: this.state.removeMenuPosition.x,
            }}
          >
            Удалить область
          </button>
        )}

        <div
          className={classNames(
            style['warning'],
            !this.state.isLimitReachedMessageVisible && style['warning--inactive'],
          )}
        >
          Можно выделить максимум пять зон поиска
        </div>
      </div>
    );
  }

  private initMap = (ymaps: YMaps.IYMaps) => {
    if (this.container) {
      this.ymaps = ymaps;

      this.map = new ymaps.Map(this.container, {
        center: this.props.center || [0, 0],
        zoom: 10,
        controls: ['zoomControl'],
      });

      this.map.events.add('actionbegin', () => {
        this.hideRemoveMenu();
      });
    }
  };

  private createAreaObjects = () => {
    this.areaObjects = [];

    if (this.props.areaModels) {
      this.props.areaModels.polygons.forEach(polygonModel => {
        this.createPolygon(polygonModel);
      });
      this.props.areaModels.circles.forEach(circleModel => {
        this.createCircle(circleModel);
      });
    }
  };

  private applyBounds = () => {
    if (this.areaObjects.length === 0) {
      return;
    }

    const geoObjects: YMaps.IGeoObject[] = this.areaObjects.map((item: TMapAreaObject) => item.getGeoObject());

    this.ymaps.geoQuery(geoObjects).applyBoundsToMap(this.map);
  };

  private showRemoveMenu = (posX: number, posY: number, editedObject: TMapAreaObject) => {
    this.setState({
      isRemoveMenuVisible: true,
      editedObject,
      removeMenuPosition: {
        x: posX - 20,
        y: posY + 20,
      },
    });
  };

  private hideRemoveMenu = () => {
    if (this.state.isRemoveMenuVisible) {
      this.setState({
        isRemoveMenuVisible: false,
        removeMenuPosition: {
          x: 0,
          y: 0,
        },
      });
    }
  };

  private removeArea = () => {
    if (this.state.editedObject) {
      this.state.editedObject.delete();

      const id = this.areaObjects.indexOf(this.state.editedObject);
      if (id >= 0) {
        this.areaObjects.splice(id, 1);
      }

      const coords = this.state.editedObject && this.state.editedObject.getCoords();
      this.props.onDelete(coords);
    }

    this.setState({
      isRemoveMenuVisible: false,
      drawMode: DrawMode.None,
    });
  };

  private createDrawObject = (drawMode: TDrawMode) => {
    switch (drawMode) {
      case DrawMode.Polygon:
        this.createPolygon();
        break;
      case DrawMode.Circle:
        this.createCircle();
        break;
      case DrawMode.Shape:
        this.startCanvasDraw();
        break;
      default:
        break;
    }
  };

  private createPolygon = (polygonModel?: IJsonQueryPolygon): Polygon => {
    const polygon = new Polygon(
      this.ymaps,
      this.map,
      this.showRemoveMenu,
      this.hideRemoveMenu,
      this.updateHandler,
      `Область поиска ${this.areaObjects.length + 1}`,
    );

    polygon.startDraw(polygonModel);

    if (polygonModel) {
      polygon.stopDraw();
    }

    this.setState({
      editedObject: polygon,
    });

    this.areaObjects.push(polygon);

    return polygon;
  };

  private createCircle = (circleModel?: IJsonQueryCircle): Circle => {
    const circle = new Circle(
      this.ymaps,
      this.map,
      this.showRemoveMenu,
      this.hideRemoveMenu,
      this.updateHandler,
      `Область поиска ${this.areaObjects.length + 1}`,
    );

    circle.startDraw(circleModel);

    if (circleModel) {
      circle.stopDraw();
      circle.stopEditing();
    }

    this.setState({
      editedObject: circle,
    });

    this.areaObjects.push(circle);

    return circle;
  };

  private isFiguresLimitReached = () => {
    const LIMIT = 5;
    const { areaModels } = this.props;
    let totalFigures = 0;

    if (areaModels) {
      totalFigures = areaModels.polygons.length + areaModels.circles.length;
    }

    return totalFigures >= LIMIT;
  };

  private menuHandler = (drawMode: TDrawMode) => {
    if (this.isFiguresLimitReached()) {
      this.setState({
        isLimitReachedMessageVisible: true,
      });

      return;
    }

    if (drawMode !== DrawMode.None && this.state.drawMode === DrawMode.None) {
      this.setDrawMode(drawMode);
    } else if (drawMode === DrawMode.None && this.state.drawMode !== DrawMode.None) {
      this.unsetDrawModes();
    } else {
      this.unsetDrawModes();
      this.setDrawMode(drawMode);
    }
  };

  private setDrawMode = (drawMode: TDrawMode) => {
    this.createDrawObject(drawMode);
    this.setState({
      drawMode,
    });
  };

  private unsetDrawModes = () => {
    this.removeArea();
    this.updateHandler();
  };

  private updateHandler = () => {
    this.setState({
      drawMode: DrawMode.None,
    });

    const objectData = this.areaObjects.reduce((acc: TMapAreaObjectData[], item) => {
      const coords = item.getCoords();

      if (coords) {
        acc.push(coords);
      }

      return acc;
    }, []);

    if (this.props.onChange) {
      this.props.onChange(objectData);
    }
  };

  private startCanvasDraw = () =>
    this.setState({
      isCanvasVisible: true,
    });

  private onDrawFinished = (points: YMaps.TCoord[]) => {
    this.setState({
      isCanvasVisible: false,
    });

    let polygonCoords = getPolygonGeometryFromMousePath(points, this.map);
    if (polygonCoords.length < 3) {
      this.unsetDrawModes();

      return;
    }

    if (!equals(polygonCoords[0], polygonCoords[polygonCoords.length - 1])) {
      polygonCoords = polygonCoords.concat([polygonCoords[0]]);
    }

    this.createPolygon({
      type: 'polygon',
      name: `Область поиска ${this.areaObjects.length + 1}`,
      coordinates: coordsArrayNumberToString(polygonCoords),
    });

    this.updateHandler();
  };
}
