import { ResponseError } from '../../errors';
import { prepareUser } from '../../mappers/prepareUser';
import {
  fetchGetUserDataByRequest,
  TGetUserDataByRequestResponse,
} from '../../repositories/monolith-python/v1/get-user-data-by-request';
import { IApplicationContext } from '../../types/applicationContext';
import { TUser } from '../../types/user';

interface IFetchUserParameters {
  subdomain: string;
}

export async function fetchUser({ httpApi }: IApplicationContext, { subdomain }: IFetchUserParameters): Promise<TUser> {
  const userData: TGetUserDataByRequestResponse = await fetchGetUserDataByRequest({
    httpApi,
    parameters: {},
    config: {
      subdomain,
    },
  });

  if (userData.statusCode !== 200) {
    throw new ResponseError({
      domain: 'fetchUser',
      message: userData.response.message,
      details: {
        error: userData.response.errors,
      },
    });
  }

  return prepareUser(userData.response);
}

export async function fetchUserSafe(context: IApplicationContext, parameters: IFetchUserParameters): Promise<TUser> {
  try {
    return await fetchUser(context, parameters);
  } catch (ex) {
    const { logger } = context;

    logger.warning(ex, { degradation: 'Информация о пользователе' });

    return { isAuthenticated: false };
  }
}
