import { IJsonQueryHouse } from '../../../../../json_query';
import { ensureSingleTagExists } from '../../helpers';
import { ITagDefinition, TTag } from '../../index';

export const IGeoHouseTagType = 'geo-house';

export interface IGeoHouseTag {
  type: 'geo-house';
  value: number;
  text: string;
}

function isGeoHouseTag(tag: TTag): tag is IGeoHouseTag {
  return tag.type === IGeoHouseTagType;
}

export const geoHouseTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery, tagsData) => {
    const values =
      jsonQuery.geo &&
      (jsonQuery.geo.value.filter(geo => geo.type === 'house') as IJsonQueryHouse[]).map(geo => geo.id);

    let nextTags = tags.filter(
      tag => tag.type !== IGeoHouseTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce((result, value) => {
        const tagData = tagsData.houses[value];

        return ensureSingleTagExists(
          result,
          (tag: TTag): tag is IGeoHouseTag => isGeoHouseTag(tag) && tag.value === value,
          () => ({
            type: IGeoHouseTagType,
            value,
            text: tagData || 'Неизвестный адрес',
          }),
        );
      }, nextTags);
    }

    return nextTags;
  },
};
