import { EGalleryLabelColor, IGalleryLabel } from '../../../types/gallery-label';
import { EPhotoLabelColor, IOffer } from '../../../types/offer';

const COLORS_MAP = {
  [EPhotoLabelColor.Green]: EGalleryLabelColor.Green,
  [EPhotoLabelColor.Blue]: EGalleryLabelColor.Blue,
  [EPhotoLabelColor.Orange]: EGalleryLabelColor.Orange,
  [EPhotoLabelColor.Grey]: EGalleryLabelColor.Grey,
};

export function getOfferRankingLabels(offer: IOffer): IGalleryLabel[] {
  if (!offer.photoLabels || !offer.photoLabels.length) {
    return [];
  }

  return offer.photoLabels.map(label => ({
    color: COLORS_MAP[label.color],
    label: label.text,
  }));
}
