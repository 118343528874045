import { connect } from 'react-redux';

import { IMortgageSimpleBannerOwnProps, IMortgageSimpleBannerStateProps, MortgageSimpleBanner } from './mortgage';
import { IAppState } from '../../../../../../common/state';
import { prepareTrackingData } from '../../../../../../utils/prepareTrackingData';

export function mapStateToProps(state: IAppState): IMortgageSimpleBannerStateProps {
  const { filters, results, user, breadcrumbs } = state;

  return {
    tracking: prepareTrackingData(filters, results, breadcrumbs, user),
  };
}

export const MortgageSimpleBannerContainer = connect<
  IMortgageSimpleBannerStateProps,
  {},
  IMortgageSimpleBannerOwnProps
>(mapStateToProps)(MortgageSimpleBanner);
