export interface ILabel {
  /** Текст в тултипе при наведении **/
  description?: string | null;
  /** Текст на шильдике **/
  title: string;
  /** Тип **/
  type: ELabelType;
}

export enum ELabelType {
  Problem = 'problem',
  Reliable = 'reliable',
  SalesLeader = 'salesLeader',
}
