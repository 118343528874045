import { Tooltip } from '@cian/ui-kit/tooltip';
import React from 'react';

import styles from './FeatureLabel.css';

interface IFeatureLabelProps {
  icon: React.ReactNode;
  title: string;
}

export function FeatureLabel({ icon, title }: IFeatureLabelProps) {
  return (
    <Tooltip title={title} placement="bottom">
      <div className={styles['label']}>{icon}</div>
    </Tooltip>
  );
}
