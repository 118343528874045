import * as React from 'react';

import { Column } from '../../../common/Column';

interface ILayoutProps {
  columnCount?: number;
  children: JSX.Element;
}

export const Layout: React.FC<React.PropsWithChildren> = ({ children, columnCount = 3 }: ILayoutProps) => {
  const childrenArray = React.Children.toArray(children) as JSX.Element[];
  const columns = childrenArray.reduce(
    (acc, item, index) => {
      const i = index % columnCount;

      acc[i].push(item);

      return acc;
    },
    Array.from({ length: columnCount }, (): JSX.Element[] => []),
  );

  return (
    <>
      {columns.map((column, index: number) => (
        <Column key={`column_${index}`}>{column.map(child => React.cloneElement(child))}</Column>
      ))}
    </>
  );
};
