import { Checkbox, IOption, TOptionValue } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './DistrictsList.css';
import { TListElements } from '../../../../types/regionalDistricts';

interface IDistrictsListProps {
  options: IOption[];
  selected: TOptionValue[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  refs: React.MutableRefObject<TListElements>;
  onHover: (districtId?: string) => void;
}

export const DistrictsList: React.FC<IDistrictsListProps> = ({ options, selected, onChange, refs, onHover }) => {
  return (
    <div className={styles['list']}>
      {options.map(option => {
        const optionValue = option.value;
        const key = String(optionValue);

        return (
          <Checkbox
            key={key}
            value={key}
            checked={selected.includes(optionValue)}
            label={option.label}
            onChange={onChange}
            ref={checkboxElement => {
              if (checkboxElement) {
                refs.current[key] = checkboxElement;
              }
            }}
            onMouseEnter={() => onHover(key)}
            onMouseLeave={() => onHover()}
          />
        );
      })}
    </div>
  );
};
