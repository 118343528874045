import React from 'react';

import { VasList } from '../../../../components/VasList';
import { useOfferContext } from '../../../../context';
import { useAuctionContext } from '../../../../context/AuctionContext';
import { AuctionApplyNewBetHintContainer } from '../AuctionApplyNewBetHintContainer';

export function VASListContainer() {
  const { isAgent, vasList, isFromSuburbanBuilder } = useOfferContext();
  const { currentBet, liquidityPoints } = useAuctionContext();

  if (!isAgent || isFromSuburbanBuilder || vasList.length === 0) {
    return null;
  }

  return (
    <VasList
      items={vasList}
      bet={currentBet}
      liquidityPoints={liquidityPoints}
      nextBetTip={<AuctionApplyNewBetHintContainer />}
    />
  );
}
