/* eslint-disable react/no-danger */
import * as React from 'react';

import { trackServicePlaceClick } from './tracking';
import { IIntegrateAdOptions } from '../../offers/helpers';
import { BannerServicePlaceFallback } from '../banner_fallback/BannerServicePlaceFallback';

export interface IBannerServicePlaceInternalProps {
  observerKey?: string | number;
  options?: IIntegrateAdOptions;
  position: number;
  containerRef?: React.RefObject<HTMLDivElement>;
}

type ServicePlaceLabel =
  | 'servisnoe_mesto_oldstyle_3_show'
  | 'servisnoe_mesto_oldstyle_33_show'
  | 'servisnoe_mesto_oldstyle_63_show';

export class BannerServicePlaceInternal extends React.Component<IBannerServicePlaceInternalProps> {
  public render() {
    const { position, containerRef } = this.props;

    if ([2, 32, 62].includes(position)) {
      return (
        <div
          ref={containerRef}
          onClick={() =>
            this.handleServicePlaceClick(`servisnoe_mesto_oldstyle_${position + 1}_show` as ServicePlaceLabel)
          }
        >
          <BannerServicePlaceFallback position={position} />
        </div>
      );
    }

    return null;
  }

  public handleServicePlaceClick = (label: ServicePlaceLabel) => {
    trackServicePlaceClick(label);
  };
}
