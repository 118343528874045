/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IUserLogOnDataSchema } from '../../entities/create_saved_search/UserLogOnDataSchema';

export type TWebCreateSavedSearchModel = IModel<IWebCreateSavedSearchRequest, TWebCreateSavedSearchResponse>;

export interface IWebCreateSavedSearchRequest {
  /** Указанный еmail **/
  email: string;
  /** Частота рассылки уведомлений **/
  frequency: EFrequency;
  /** Набор фильтров словарем **/
  jsonQuery: any;
  /** Где пользователь создал СП: карте, выдача **/
  source?: ESource | null;
  /** Подписать ли на новости **/
  subscribeNews: boolean;
  /** Название сохраненного поиска **/
  title: string;
}

export type TWebCreateSavedSearchResponse = IWebCreateSavedSearchResponse200 | IWebCreateSavedSearchResponse400;

export interface IWebCreateSavedSearchResponse200 extends IModelResponse<IWebCreateSavedSearchResponse> {
  statusCode: 200;
}

export interface IWebCreateSavedSearchResponse400 extends IModelResponse<IWebCreateSavedSearchResponseError> {
  statusCode: 400;
}

export interface IWebCreateSavedSearchResponse {
  /** Информация для авторегистрации анонимного пользователя **/
  logonInfo?: IUserLogOnDataSchema | null;
  /** Статус создания СП и подписки **/
  status: EStatus;
}

export interface IWebCreateSavedSearchResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IWebCreateSavedSearchResponse | IWebCreateSavedSearchResponseError;

export enum EStatus {
  /** Для активации email-подписки требуется подтверждение. Отправлено письмо с активацией **/
  EmailConfirmationRequired = 'emailConfirmationRequired',
  /** Ошибка создания СП. Возможно что-то внутри сдеградировало **/
  Error = 'error',
  /** Только для авторизованных пользователей **/
  NotAuthUser = 'notAuthUser',
  /** СП с указанным фильтром поиска уже существует у пользователя **/
  SavedSearchAlreadyExist = 'savedSearchAlreadyExist',
  /** СП создан **/
  Success = 'success',
  /** Пользователь с указанным email уже зарегистрирован **/
  UserAlreadyRegistered = 'userAlreadyRegistered',
}

export enum EFrequency {
  /** Получать мнгновенно **/
  Asap = 'asap',
  /** Получать каждый день **/
  Daily = 'daily',
  /** Получать каждый час **/
  Hourly = 'hourly',
  /** Получать еженедельно **/
  Weekly = 'weekly',
}

export enum ESource {
  /**  **/
  BookmarksScreen = 'bookmarksScreen',
  /** deprecated (для поддержки старых клиентов) **/
  Card = 'card',
  /**  **/
  FavoriteHousesScreen = 'favoriteHousesScreen',
  /**  **/
  FiltersScreen = 'filtersScreen',
  /** deprecated (для поддержки старых клиентов) **/
  Listing = 'listing',
  /** deprecated (для поддержки старых клиентов) **/
  Map = 'map',
  /**  **/
  NearbySearchScreen = 'nearbySearchScreen',
  /**  **/
  OfferScreen = 'offerScreen',
  /**  **/
  PersonalFeedScreen = 'personalFeedScreen',
  /**  **/
  ProfilingScreen = 'profilingScreen',
  /**  **/
  RecommendationsScreen = 'recommendationsScreen',
  /**  **/
  SaveSearchListScreen = 'saveSearchListScreen',
  /**  **/
  SearchHistoryScreen = 'searchHistoryScreen',
  /**  **/
  SearchResultsList = 'searchResultsList',
  /**  **/
  SearchResultsMap = 'searchResultsMap',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IWebCreateSavedSearchResponse): TResponse200;
  400(response: IWebCreateSavedSearchResponseError): TResponse400;
}
