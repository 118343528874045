import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import React, { useCallback } from 'react';

import { useApplicationContext } from '../../../../../../shared/utils/applicationContext';
import { Card } from '../../../components/Card';
import { useOfferContext } from '../../../context';
import { AgentContainer } from '../AgentContainer';
import { ControlsContainer } from '../ControlsContainer';
import { GalleryContainer } from '../GalleryContainer';
import { OfferGeneralInfoContainer } from '../OfferGeneralInfoContainer';
import { PessimizationTogglerContainer } from '../PessimizationTogglerContainer';
import { ThumbnailsContainer } from '../ThumbnailsContainer';
import { VASContainer } from '../VAS/VASContainer';

export function OfferCardContainer() {
  const { config } = useApplicationContext();

  const isNewHighlighting = config.get<boolean>('new_highlighting') || false;

  const {
    isColored,
    offer: { fullUrl, isCianPartner, externalOfferUrl, showGalleryThumbnails, dailyrent },
    api: { isPopupsOpened, onPageJumped },
    kp,
    tracking,
    mortgageBanner,
  } = useOfferContext();

  const href = isCianPartner ? externalOfferUrl : fullUrl;

  const handleCardClick = useCallback(() => {
    if (isPopupsOpened()) {
      return;
    }
    onPageJumped('card_detail_in_newlist');
  }, [isPopupsOpened, onPageJumped]);

  const onAfterViewedHandle = () => tracking.trackOfferCardShow();

  return (
    <ActionAfterViewObserver percentVisible={0.5} callback={onAfterViewedHandle} triggerOnce callbackDelay={1000}>
      <Card
        href={href}
        onClick={handleCardClick}
        isWide={showGalleryThumbnails || Boolean(kp?.villageAdvantages)}
        colored={!isNewHighlighting && isColored}
        gallery={<GalleryContainer />}
        thumbnails={<ThumbnailsContainer />}
        content={<OfferGeneralInfoContainer isNewHighlighting={isNewHighlighting} mortgageBanner={mortgageBanner} />}
        agent={<AgentContainer />}
        controls={<ControlsContainer />}
        showVas={!dailyrent?.hotelData?.isHotel}
        vas={<VASContainer />}
        pessimization={<PessimizationTogglerContainer />}
        comment={null}
      />
    </ActionAfterViewObserver>
  );
}
