export function formatTime(time: string): string {
  return time.replace(/[^\d]/g, '').replace(/(\d{2})(\d{1,2})/, '$1:$2');
}

export function formatPhoneNumber(phoneNumber: string): string {
  if (phoneNumber.length <= 6) {
    return phoneNumber.replace(/(\d{3})(\d{1,3})/, '$1 $2');
  } else if (phoneNumber.length <= 8) {
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{0,2})/, '$1 $2-$3');
  }

  return phoneNumber.replace(/(\d{3})(\d{3})(\d{2})(\d{0,2})/, '$1 $2-$3-$4');
}
