import { ITypedReduxAction } from '../../types/redux';
import { actionGenerator } from '../../utils/redux';

export enum EJournalActionTypes {
  Reset = 'Journal/Reset',
}

export type TJournalReset = ITypedReduxAction<EJournalActionTypes.Reset>;

export const resetJournalHighlights = actionGenerator<EJournalActionTypes.Reset>(EJournalActionTypes.Reset);

export type TJournalActions = TJournalReset;
