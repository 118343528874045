import { ERoomType } from '../../../../../JsonQuery';
import { capitalize } from '../../../utils/string';

const COMMON_ROOM_TYPE_NAMES = {
  [ERoomType.FlatOneRoom]: '1',
  [ERoomType.FlatTwoRooms]: '2',
  [ERoomType.FlatThreeRooms]: '3',
  [ERoomType.FlatFourRooms]: '4',
  [ERoomType.FlatFiveRooms]: '5',
  [ERoomType.FlatManyRooms]: '6+',
};

const SPECIAL_ROOM_TYPE_NAMES = {
  [ERoomType.Studio]: 'студия',
  [ERoomType.FlatFreeLayout]: 'своб. план.',
};

type TCommonRoomType = keyof typeof COMMON_ROOM_TYPE_NAMES;
type TSpecialRoomType = keyof typeof SPECIAL_ROOM_TYPE_NAMES;

export function getRoomTypeLabel(roomTypes: ERoomType[]): string | null {
  const commonRoomTypeNames = getCommonRoomTypeNames(roomTypes);
  const specialRoomTypeNames = getSpecialRoomTypeNames(roomTypes);

  let postfix = '';
  if (commonRoomTypeNames.length > 0) {
    postfix =
      !specialRoomTypeNames.length &&
      commonRoomTypeNames.length === 1 &&
      !roomTypes.includes(ERoomType.FlatManyRooms) &&
      !commonRoomTypeNames[0].includes('-')
        ? '-комнатную'
        : ' комн.';
  }

  const roomTypeName = [commonRoomTypeNames.join(', ') + postfix, specialRoomTypeNames.join(', ')]
    .filter(Boolean)
    .join(', ');

  return (roomTypeName && capitalize(roomTypeName)) || null;
}

function filterCommonRoomTypes(roomTypes: ERoomType[]): TCommonRoomType[] {
  return roomTypes.filter(type => type in COMMON_ROOM_TYPE_NAMES) as TCommonRoomType[];
}

function filterSpecialRoomTypes(roomTypes: ERoomType[]): TSpecialRoomType[] {
  return roomTypes.filter(type => type in SPECIAL_ROOM_TYPE_NAMES) as TSpecialRoomType[];
}

function getCommonRoomTypeNames(roomTypes: ERoomType[]): string[] {
  const roomTypesSorted = filterCommonRoomTypes(roomTypes).sort();

  if (roomTypesSorted.length < 3) {
    return roomTypesSorted.map(type => COMMON_ROOM_TYPE_NAMES[type]);
  }

  const sequences: [TCommonRoomType, TCommonRoomType][] = [];

  let seq: [TCommonRoomType, TCommonRoomType] = [roomTypesSorted[0], roomTypesSorted[0]];
  roomTypesSorted.forEach((value, index) => {
    if (index === 0) {
      return;
    } else if (value - 1 === seq[1]) {
      seq[1] = value;
    } else {
      sequences.push(seq);
      seq = [value, value];
    }
  });

  sequences.push(seq);

  return sequences.map(([start, end]) => {
    return start === end
      ? COMMON_ROOM_TYPE_NAMES[start]
      : `${COMMON_ROOM_TYPE_NAMES[start]}${end - start > 1 ? ' - ' : ', '}${COMMON_ROOM_TYPE_NAMES[end]}`;
  });
}

function getSpecialRoomTypeNames(roomTypes: ERoomType[]): string[] {
  const roomTypesSorted = filterSpecialRoomTypes(roomTypes).sort();

  return roomTypesSorted.map(type => SPECIAL_ROOM_TYPE_NAMES[type]);
}
