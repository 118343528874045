import * as React from 'react';

import { IAppStore } from '../../common/state';
import { useAuthenticationSubscription } from '../../hooks/useAuthenticationSubscription';

interface ISubscriptionsContainerProps {
  reduxStore: IAppStore;
}

export const SubscriptionsContainer: React.FC<ISubscriptionsContainerProps> = ({ reduxStore }) => {
  useAuthenticationSubscription({ reduxStore });

  return null;
};
