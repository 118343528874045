import { ArticleHeading2, Header2, Label, Paragraph2 } from '@cian/ui-kit';
import classNames from 'classnames';
import React, { memo } from 'react';

import styles from './Price.css';
import { IDiscount } from '../../types/offer';
import { ContentRow } from '../ContentRow';
import { GeneralInfoSectionRow } from '../GeneralInfoSectionRow';

interface IPriceProps {
  priceInfo?: string;
  price: string;
  discount?: IDiscount;
  isHighlighted?: boolean;
  MortgageAdFoxBanner?: React.ReactNode;
}

/** Компонент цены */
export const PriceComponent: React.FC<IPriceProps> = ({
  priceInfo,
  price,
  isHighlighted,
  MortgageAdFoxBanner,
  discount,
}) => {
  return (
    <GeneralInfoSectionRow>
      <ContentRow>
        {discount ? (
          <div className={styles['price-container']} data-testid="offer-discount-block">
            <ArticleHeading2>
              <span
                className={classNames(isHighlighted && /* istanbul ignore next*/ styles['highlighted'])}
                data-testid="offer-discount-new-price"
              >
                {discount.newPrice}
              </span>
            </ArticleHeading2>
            <Label background="#EBF9E6" color="#34AC0A" data-testid="offer-discount-label">
              {discount.percent}
            </Label>
            <span className={styles['strikethrough']} data-testid="offer-discount-old-price">
              {price}
            </span>
          </div>
        ) : (
          <Header2 data-mark="MainPrice">
            <span className={classNames(isHighlighted && /* istanbul ignore next*/ styles['highlighted'])}>
              {price}
            </span>
          </Header2>
        )}
      </ContentRow>
      {priceInfo && (
        <ContentRow>
          <Paragraph2 data-mark="PriceInfo" color="gray60_100">
            {priceInfo}
          </Paragraph2>
        </ContentRow>
      )}
      {MortgageAdFoxBanner}
    </GeneralInfoSectionRow>
  );
};

PriceComponent.displayName = 'Price';

/** @see {@link PriceComponent} */
export const Price = memo(PriceComponent);
