import { FOfferType } from '../../../../../../../JsonQuery';
import { IOfferType } from '../../types';

export const OFFER_TYPES_RENT_RESIDENTIAL: IOfferType[][] = [
  [{ label: 'Квартира', value: FOfferType.Flat }],
  [
    { label: 'Комната', value: FOfferType.Room },
    { label: 'Койко-место', value: FOfferType.Bed },
  ],
  [
    { label: 'Дом, дача', value: FOfferType.House },
    { label: 'Часть дома', value: FOfferType.HousePart },
    { label: 'Таунхаус', value: FOfferType.Townhouse },
  ],
  [{ label: 'Гараж', value: FOfferType.Garage }],
];
