import { beautify } from '../../../utils/number';

interface IParams {
  houseAreaMin: number | null;
  houseAreaMax: number | null;
  landAreaMin: number | null;
  landAreaMax: number | null;
}

export function getAreaLabel({ houseAreaMin, houseAreaMax, landAreaMin, landAreaMax }: IParams): string {
  return (
    [getTitle(houseAreaMin, houseAreaMax, 'м²'), getTitle(landAreaMin, landAreaMax, 'сот.')]
      .filter(Boolean)
      .join('・') || 'Площадь'
  );
}

export function getTitle(min: number | null, max: number | null, sign: string): string | null {
  if (min !== null && max === null) {
    return `от ${beautify(min)} ${sign}`;
  } else if (min === null && max !== null) {
    return `до ${beautify(max)} ${sign}`;
  } else if (min !== null && max !== null) {
    return `${beautify(min)} - ${beautify(max)} ${sign}`;
  }

  return null;
}
