import { ca } from '@cian/analytics';

interface IAnalyticsProduct {
  id: number;
  position: number;
}

interface ITrackingProps {
  id: number;
  position: number;
  offersQty: number;
  pageNumber: number;
  queryString: string;
  caLabel: 'ToCardBook' | 'ToCardCheckDates';
}

export const trackOnlineBookingBookButton = ({
  id,
  position,
  offersQty,
  pageNumber,
  queryString,
  caLabel,
}: ITrackingProps) => {
  const product: IAnalyticsProduct = {
    id,
    position,
  };

  ca('eventSite', {
    name: 'oldevent',
    category: 'DailyrentButton',
    action: 'Click',
    label: caLabel,
    page: {
      pageType: 'Listing',
      listingType: 'list',
      offersQty,
      pageNumber,
      queryString,
    },
    products: [product],
  });
};
