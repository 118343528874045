import * as React from 'react';

import { IKitchenAreaTag } from '../../../state/tags/definitions/kitchen_area';
import { ILivingAreaTag } from '../../../state/tags/definitions/living_area';
import { IRoomAreaTag } from '../../../state/tags/definitions/room_area';
import { ISiteAreaTag } from '../../../state/tags/definitions/site_area';
import { ITotalAreaTag } from '../../../state/tags/definitions/total_area';
import { TagNew } from '../TagNew';

export interface IAreaTagItemProps {
  tag: ITotalAreaTag | IKitchenAreaTag | ILivingAreaTag | ISiteAreaTag | IRoomAreaTag;
  title?: string;
  unit?: string;
  onDeleteClick(): void;
}

export const AreaTagItem: React.FC<IAreaTagItemProps> = props => {
  const text = [];

  if (props.tag.min !== undefined) {
    text.push(`от ${props.tag.min}`);
  }

  if (props.tag.max !== undefined) {
    text.push(`до ${props.tag.max}`);
  }

  return <TagNew onDeleteClick={props.onDeleteClick}>{`${props.title} ${text.join(' ')} ${props.unit}`}</TagNew>;
};

AreaTagItem.defaultProps = {
  title: 'Площадь',
  unit: 'м\u00B2',
};
