/**
 * @todo Логику с экспериментом
 * @description Данный функционал появился в задаче CD-206850, будет удалён в задаче CD-206973
 * Если эксперимент будет удачным, удалить директорию и артефакты. Тут внимательно, нужно уточнить у Сергея Юрикова,
 * оставляем ли мы старый дизайн или переходим на новый, так как сейчас старый дизайн на 100% случаев
 * используется на загородке, если мы переходим на загородке на новый дизайн, то можно удалят, если остаёмся на старом
 * дизайне, то ничего удалять не нужно
 *
 * Если эксперимент будет не удачным, то удалить только комментарий
 */

import { TTgbNativedescGeneralContext } from '@cian/adfox-templates';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { ResidentialNativeTGBBanner } from './ResidentialNativeTGBBanner';
import { TGSimplifieldBannerWrapper } from './TGSimplifieldBannerWrapper';
import { getJsonQuery } from '../../../../../../selectors/getJsonQuery';

export interface INativeTGBBannerContainerProps {
  context: TTgbNativedescGeneralContext;
  campaignId: string;
  bannerId: string;
}

export function NativeTGBBannerContainer(props: INativeTGBBannerContainerProps) {
  const { context, campaignId, bannerId } = props;

  const jsonQuery = useSelector(getJsonQuery);

  const locationId = React.useMemo(() => jsonQuery.region && jsonQuery.region.value[0], [jsonQuery]);

  return (
    <TGSimplifieldBannerWrapper>
      <ResidentialNativeTGBBanner
        campaignId={campaignId}
        context={context}
        locationId={locationId}
        bannerId={bannerId}
      />
    </TGSimplifieldBannerWrapper>
  );
}
