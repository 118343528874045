import { getFloorRange } from './getFloorRange';
import { IOffer } from '../../../types/offer';
import { isGarage } from '../category/isGarage';
import { isSuburban } from '../category/isSuburban';

export function getFloor(offer: IOffer): string | undefined {
  const isSuburbanCategory = isSuburban(offer);
  const isGarageCategory = isGarage(offer);

  if (!isSuburbanCategory && !isGarageCategory) {
    return getFloorRange(offer);
  }

  return undefined;
}
