import { ScrollFade } from '@cian/adfox-templates';
import { useBooleanState } from '@cian/react-utils';
import {
  Avatar,
  Button,
  IconButton,
  Image,
  Label,
  UIText2,
  UIText3,
  UIText1,
  UIHeading2,
  UIHeading4,
  Overline,
} from '@cian/ui-kit';
import { IconActionClose16 } from '@cian/ui-kit-design-tokens/icons';
import React, { FC, useCallback } from 'react';

import * as styles from './TgbNativedescGeneral.css';
import { Transport, TransportProps } from './internal/components/Transport';

type TransportIterable = TransportProps & {
  id: number;
};

export type TgbNativedescGeneralProps = {
  url: string;
  image: string;
  logoImageUrl: string | undefined;
  title: string;
  description: string;
  address: string;
  builderName: string;
  annotation: string;
  button: string | undefined;
  label: string | undefined;
  transports: TransportIterable[];
};

export const TgbNativedescGeneral: FC<TgbNativedescGeneralProps> = props => {
  const { title, address, description, annotation, image, transports, url, button, label, logoImageUrl, builderName } =
    props;

  const { state: isClosed, setTrue: setClosed } = useBooleanState();

  const handleClose = useCallback<(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void>(
    event => {
      event.preventDefault();

      setClosed();
    },
    [setClosed],
  );

  if (isClosed) {
    return <div className={styles['empty']} data-testid="empty_native_adfox_block" />;
  }

  return (
    <a href={url} target="_blank" className={styles['container']} rel="noopener noreferrer">
      <div className={styles['photo-block']}>
        <Image src={image} objectFit="cover" />
      </div>
      <div className={styles['main-content-block']}>
        <div className={styles['content-container']}>
          <div>
            <div className={styles['title-wrapper']}>
              <UIHeading2 as="h2" color="primary_100">
                {title}
              </UIHeading2>
            </div>
            <div className={styles['subtitle-wrapper']}>
              <UIHeading4 as="h4">{description}</UIHeading4>
            </div>

            <div className={styles['main-content']}>
              {!!transports.length && (
                <div className={styles['transports-container']}>
                  {transports.map(transport => (
                    <Transport
                      key={transport.id}
                      name={transport.name}
                      remoteness={transport.remoteness}
                      iconColor={transport.iconColor}
                      iconType={transport.iconType}
                    />
                  ))}
                </div>
              )}

              <UIText2 data-testid="address" color="gray60_100">
                {address}
              </UIText2>
            </div>
          </div>
        </div>
        <div className={styles['footer-container']}>
          <UIText3 color="gray40_100">{annotation}</UIText3>
          <ScrollFade size={12} />
        </div>
      </div>
      <div className={styles['publisher-block']}>
        <div className={styles['publisher-block-content']}>
          <div className={styles['publisher-avatar-wrapper']}>
            <Avatar size={64} src={logoImageUrl} type="builder" />
          </div>

          <Overline color="gray60_100">Компания</Overline>

          <UIText1 data-testid="builderName" fontWeight="bold">
            {builderName}
          </UIText1>

          <div className={styles['more-info-button-wrapper']}>
            <Button data-testid="more-info-button" fullWidth size="XS">
              {button || 'Узнать подробности'}
            </Button>
          </div>
        </div>

        {!!label && (
          <div>
            <Label color="#152242" background="rgba(0, 0, 0, 0.06)">
              {label}
            </Label>
          </div>
        )}
      </div>
      <div className={styles['close-block']} data-testid="close-block">
        <div>
          <IconButton data-testid="close-button" icon={<IconActionClose16 />} onClick={handleClose} />
        </div>
      </div>
    </a>
  );
};
