import { selectProfessionalSearchUrl } from './selectProfessionalSearchUrl';
import { IAppState } from '../../../../shared/common/state';

export const selectProfessionalSearchUrlWithFilters = (state: IAppState) => {
  const hostPart = selectProfessionalSearchUrl(state);

  const {
    filters: { queryString },
  } = state;

  const encodeQueryString =
    queryString && queryString.includes('cat.php?') ? encodeURIComponent(queryString.split('cat.php?')[1]) : '';

  return encodeQueryString ? `${hostPart}?from_full_url=${encodeQueryString}` : hostPart;
};
