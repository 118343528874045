import { Checkbox } from '@cian/ui-kit';
import * as React from 'react';

import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';

interface IOnlineBookingProps {
  onlineBooking: boolean | null;
  onChange(value: boolean | null): void;
}

export const OnlineBooking: React.FC<IOnlineBookingProps> = ({ onlineBooking, onChange }) => {
  return (
    <Filter id="advancedFilter_onlineBooking" label="Формат бронирования">
      <InlineFilterGroup>
        <InlineFilter>
          <Checkbox
            label="Мгновенное бронирование"
            checked={Boolean(onlineBooking)}
            onChange={event => onChange(event.target.checked)}
          />
        </InlineFilter>
      </InlineFilterGroup>
    </Filter>
  );
};
