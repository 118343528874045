import { IJsonQuery } from '../../repositories/common/json_query';

export function getRailwayAndHighwayPuids(jsonQuery: IJsonQuery): Record<string, string> {
  const railwayAndHighwayPuids: Record<string, string> = {};

  if (jsonQuery.geo?.value && jsonQuery._type.includes('suburban')) {
    const { puid34Arr, puid35Arr } = jsonQuery.geo.value.reduce(
      (accumulator, geoValue) => {
        switch (geoValue.type) {
          case 'railway':
            accumulator.puid34Arr.push(geoValue.id);

            break;
          case 'highway':
            accumulator.puid35Arr.push(geoValue.id);

            break;
        }

        return accumulator;
      },
      { puid34Arr: Array.of<number>(), puid35Arr: Array.of<number>() },
    );

    if (puid34Arr.length) {
      railwayAndHighwayPuids.puid34 = puid34Arr.join(':');
    }

    if (puid35Arr.length) {
      railwayAndHighwayPuids.puid35 = puid35Arr.join(':');
    }
  }

  return railwayAndHighwayPuids;
}
