import * as React from 'react';

import { useApplicationContext } from '../../../../../../shared/utils/applicationContext';
import { PessimizationContainer } from '../PessimizationContainer';
import { PessimizationFlexibleContainer } from '../PessimizationFlexibleContainer';

export const PessimizationTogglerContainer = () => {
  const { config } = useApplicationContext();
  const isFlexibleSanctions = config.get<boolean>('Moderation.CustomSanction.NewModerationInfo.Enabled');

  return isFlexibleSanctions ? <PessimizationFlexibleContainer /> : <PessimizationContainer />;
};

PessimizationTogglerContainer.displayName = 'PessimizationTogglerContainer';
