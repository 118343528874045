import { useEventCallback } from '@cian/react-utils';
import { ModalWindow } from '@cian/ui-kit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RegionalDistrictsContentContainerLoadable } from './RegionalDistrictsContentContainer';
import { RegionalDistrictsSearchContainer } from './RegionalDistrictsSearchContainer';
import { useScrollToListElementById } from './hooks/useScrollToListElementById';
import { LoadableErrorBoundary } from '../../../packages/LoadableHelpers';
import { Dispatch } from '../../common/state';
import { ModalFooter, ModalHeader } from '../../components/RegionalDistrictsModal';
import { SearchButtonContainer } from '../../filters/components/search_button/container';
import {
  applyRegionalDistricts,
  closeRegionalDistrictsModal,
  selectRegionalDistrict,
} from '../../filters/state/regionalDistricts';
import { selectRegionalDistrictsStatus } from '../../selectors/filters';
import { ERegionalDistrictsStatus } from '../../types/regionalDistricts';
import { useApplicationContext } from '../../utils/applicationContext';

export const RegionalDistrictsModalContainer = () => {
  const { logger } = useApplicationContext();

  const dispatch = useDispatch<Dispatch>();

  const status = useSelector(selectRegionalDistrictsStatus);

  const { scrollToListElementById, listRefs } = useScrollToListElementById();

  const handleClose = useEventCallback(() => {
    dispatch(closeRegionalDistrictsModal());
  });

  const handleApply = useEventCallback(() => {
    dispatch(applyRegionalDistricts());

    dispatch({
      type: 'filters/search/SEARCH_REQUEST_START_REQUESTED',
    });
  });

  const handleSearchSuggestSelect = React.useCallback(
    (id: number) => {
      dispatch(selectRegionalDistrict(id));

      scrollToListElementById(id);
    },
    [dispatch, scrollToListElementById],
  );

  const handleRetry = useEventCallback(() => RegionalDistrictsContentContainerLoadable.load());

  return (
    <ModalWindow
      fixed
      size="M"
      escape
      width="100%"
      maxWidth={928}
      height={684}
      open
      onClose={handleClose}
      header={
        <ModalHeader>
          {status === ERegionalDistrictsStatus.Ready && (
            <RegionalDistrictsSearchContainer onSelect={handleSearchSuggestSelect} />
          )}
        </ModalHeader>
      }
      footer={
        <ModalFooter>
          <SearchButtonContainer onClick={handleApply} />
        </ModalFooter>
      }
    >
      <LoadableErrorBoundary logger={logger} onRetry={handleRetry}>
        <RegionalDistrictsContentContainerLoadable refs={listRefs} />
      </LoadableErrorBoundary>
    </ModalWindow>
  );
};
