import * as R from 'ramda';

import { resetTerms, setTerm } from './helpers';
import { EFacilityType, NonEmptyArray, TJsonQuery } from './types';

export function setLivingConditions(jsonQuery: TJsonQuery) {
  return (facilityTypes: NonEmptyArray<EFacilityType> | null): TJsonQuery => {
    let nextJsonQuery = R.clone(jsonQuery);

    nextJsonQuery = resetTerms(['kids', 'pets'])(nextJsonQuery);

    if (facilityTypes) {
      if (facilityTypes.includes(EFacilityType.KidsAllowed)) {
        nextJsonQuery = setTerm('kids')(nextJsonQuery)(true);
      }

      if (facilityTypes.includes(EFacilityType.PetsAllowed)) {
        nextJsonQuery = setTerm('pets')(nextJsonQuery)(true);
      }
    }

    return nextJsonQuery;
  };
}
