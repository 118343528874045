import { Button, CustomTooltip, IOnboardingTooltipProps } from '@cian/ui-kit';
import { useClickController } from '@cian/ui-kit/tooltip/internal';
import { plural } from '@cian/utils';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import * as styles from './SearchResultsTooltip.css';

interface ISearchResultsTooltipProps
  extends Pick<IOnboardingTooltipProps, 'open' | 'placement' | 'onOpen' | 'onClose' | 'children'> {
  hideCount?: boolean;
  offersCount: number;
  hostElement?: HTMLElement | null;
  loading?: boolean;
  onApply?(): void;
}

export const SearchResultsTooltip: React.FC<ISearchResultsTooltipProps> = props => {
  const { hideCount, offersCount, placement, children, loading, hostElement, onApply } = props;
  const { childrenProps, childrenRef, open, handleClose } = useClickController(props);
  const text = `Найдено ${offersCount} ${plural(offersCount, ['объявление', 'объявления', 'объявлений'])}`;

  const tooltipElement = (
    <CustomTooltip
      open={open}
      anchorRef={childrenRef}
      arrow
      flip
      preventOverflow
      minHeight={56}
      maxWidth={320}
      theme="black"
      placement={placement}
      portal={false}
      content={
        <div>
          {!hideCount && <div className={styles['text']}>{text}</div>}
          <Button size="XS" theme="fill_white_secondary" loading={loading} onClick={onApply}>
            Применить
          </Button>
        </div>
      }
      onClose={handleClose}
    />
  );

  return (
    <>
      {React.cloneElement(children, childrenProps)}
      {hostElement ? ReactDOM.createPortal(tooltipElement, hostElement) : tooltipElement}
    </>
  );
};
