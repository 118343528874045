import * as React from 'react';

import { getConstructionTypesList } from './helpers';
import { NonEmptyArray, FOfferType } from '../../../../../JsonQuery';
import { EHouseMaterial } from '../../../types/jsonQuery';
import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface IConstructionTypeProps {
  offerType: FOfferType;
  value: NonEmptyArray<EHouseMaterial> | null;
  onChange(value: NonEmptyArray<EHouseMaterial> | null): void;
  label: string;
}

export const ConstructionType: React.FC<IConstructionTypeProps> = ({ offerType, value, onChange, label }) => {
  const constructionTypes = getConstructionTypesList(offerType);

  return (
    <Filter id="advancedFilter_constructionType" label={label}>
      <CheckboxButtonGroupUnselectable options={constructionTypes} value={value} onChange={onChange} />
    </Filter>
  );
};
