/* eslint-disable max-lines */
import { IDistrictSelectedAction } from './districts/popup';
import { unselectDistrict } from './districts/utils';
import { ILocationAppliedAction, TAppliedLocation } from './location_switcher';
import { unselectRegionalDistrict } from './regionalDistricts';
import { TTag } from './tags';
import { isGeoCircleTag, isGeoRegionalDistrictTag, isGeoTag } from './tags/definitions/geo';
import { isGeoDistrictTag } from './tags/definitions/geo/district';
import { TGeoObjectTag } from './tags/definitions/geo/helpers';
import { isGeoPolygonTag } from './tags/definitions/geo/polygon';
import { isGeoUndergroundTag } from './tags/definitions/geo/underground';
import { isSuburban, offerTypeFromJsonQuery } from '../../../packages/JsonQuery';
import { IAppState, ITagsData, TActions, Dispatch } from '../../common/state';
import { IFiltersState } from '../../common/state/app_state';
import { MOSCOW_AND_REGION, SPB_AND_REGION } from '../../constants/regions';
import { IJsonQuery, IJsonQueryGeo, jq, EMetroTravelType } from '../../json_query';
import { needClearGeo } from '../../json_query/reducers/helpers';
import { ILocation, TLocation } from '../../types/location';
import { circlesAreEqual, coordsArrayNumberToString, coordsIsEqual, coordsNumberToString } from '../../utils/geo';
import { getDefaultTravelTimeForType } from '../../utils/underground';

export type TGeoType /*'country' | */ =
  | 'location'
  | 'street'
  | 'highway'
  | 'district'
  | 'underground'
  | 'house'
  | 'newobject'
  | 'builder'
  | 'railway'
  | 'village'
  | 'regional_district_id';

export interface IDistrict {
  name: string;
  id: number;
}

export interface IGeoObject {
  type: TGeoType;
  id: number;
  text: string;
  coordinates?: YMaps.TCoord;
  regionId: number;
  locationId?: number;
  locationInfo?: ILocation;
  district?: IDistrict;
}

export interface IPolygonObject extends IGeoObject {
  polygon: YMaps.TCoord[];
}

export interface ICircleObject extends IGeoObject {
  center: YMaps.TCoord;
  radius: number;
}

export type TGeoObject = IGeoObject | IPolygonObject | ICircleObject;

export const isIPolygonObject = (value: TGeoObject): value is IPolygonObject => 'polygon' in value;

export const isICircleObject = (value: TGeoObject): value is ICircleObject => 'center' in value;

export interface IGeoSelectedAction {
  type: 'filters/geo/GEO_SELECTED';
  value: TGeoObject;
  currentLocation: TLocation;
}

export interface IUserInputSavedAction {
  type: 'filters/USER_INPUT_SAVED';
  userInput: string;
}

export interface IGeoClearRailwayTags {
  type: 'filters/tags/CLEAR_RAILWAY_TAGS';
}

export function clearRailwayTags(): IGeoClearRailwayTags {
  return {
    type: 'filters/tags/CLEAR_RAILWAY_TAGS',
  };
}

export function applyLocation(location: TAppliedLocation): ILocationAppliedAction {
  return {
    type: 'filters/location_switcher/LOCATION_APPLIED',
    location,
  };
}

export function selectDistrict({ id, name }: IDistrict): IDistrictSelectedAction {
  return {
    type: 'filters/districts/DISTRICT_SELECTED',
    id,
    name,
  };
}

export function selectGeoAction(value: TGeoObject, currentLocation: IFiltersState['currentLocation']) {
  return {
    type: 'filters/geo/GEO_SELECTED',
    value,
    currentLocation,
  };
}

export function saveUserInput(userInput: string): IUserInputSavedAction {
  return {
    type: 'filters/USER_INPUT_SAVED',
    userInput,
  };
}

export function selectGeo(value: TGeoObject, userInput: string) {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const { locationInfo, district } = value;
    const state = getState();

    if (locationInfo) {
      dispatch(applyLocation(locationInfo));
    } else {
      const location =
        state.filters.regions.find(r => r.id === value.id) || state.filters.regions.find(r => r.id === value.regionId);

      let extendedLocation: TLocation | undefined = location;

      const offerType = offerTypeFromJsonQuery(state.filters.jsonQuery);

      if (isSuburban(offerType) && location) {
        switch (true) {
          case MOSCOW_AND_REGION.includes(location.id):
            extendedLocation = 'moscow_mo';
            break;
          case SPB_AND_REGION.includes(location.id):
            extendedLocation = 'spb_lo';
            break;
        }
      }

      dispatch(applyLocation(extendedLocation));
    }

    if (district) {
      dispatch(selectDistrict(district));
    }

    dispatch(selectGeoAction(value, state.filters.currentLocation));
    dispatch(saveUserInput(userInput));
  };
}

function getNewJsonQuery(tag: TTag, jsonQuery: IJsonQuery) {
  if (tag.type === 'IncludeNewMoscow') {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { include_new_moscow, ...jsonQueryWithoutNewMoscow } = jsonQuery;

    return jsonQueryWithoutNewMoscow;
  }

  return jsonQuery;
}

export function geoSuggestReducer(state: IAppState, action: TActions): IAppState {
  let nextJsonQuery = { ...state.filters.jsonQuery };

  let geo: IJsonQueryGeo | undefined = {
    type: 'geo',
    value: state.filters.jsonQuery.geo ? [...state.filters.jsonQuery.geo.value] : [],
  };

  const tagsData: ITagsData = { ...state.filters.tagsData };

  switch (action.type) {
    case 'filters/geo/GEO_SELECTED': {
      const value = action.value;

      if (!isIPolygonObject(value) && !isICircleObject(value)) {
        if (geo.value.filter(geoValue => geoValue.type === value.type && geoValue.id === value.id).length > 0) {
          return state;
        }

        if (value.locationInfo && needClearGeo(value.locationInfo, action.currentLocation)) {
          geo.value = [];
        }

        switch (value.type) {
          case 'location':
            if (!value.locationInfo || value.locationInfo.id !== value.id) {
              if (!nextJsonQuery.region || !nextJsonQuery.region.value.includes(value.regionId)) {
                nextJsonQuery.region = { type: 'terms', value: [value.regionId] };
                geo.value = [];
              }

              geo.value.push({
                type: value.type,
                id: value.id,
              });

              tagsData.locations = { ...tagsData.locations, [value.id]: value.text };
            }
            break;
          case 'street':
            geo.value.push({
              type: value.type,
              id: value.id,
            });

            tagsData.streets = { ...tagsData.streets, [value.id]: value.text };
            break;
          case 'highway':
            geo.value.push({
              type: value.type,
              id: value.id,
            });
            break;
          case 'district':
            geo.value.push({
              type: value.type,
              id: value.id,
            });

            tagsData.districts = { ...tagsData.districts, [value.id]: value.text };
            break;
          case 'underground':
            geo.value.push({
              type: value.type,
              id: value.id,
            });

            tagsData.undergrounds = { ...tagsData.undergrounds, [value.id]: { name: value.text } };
            break;
          case 'house':
            geo.value.push({
              type: value.type,
              id: value.id,
            });

            tagsData.houses = { ...tagsData.houses, [value.id]: value.text };
            break;
          case 'newobject':
            geo.value.push({
              type: value.type,
              id: value.id,
            });

            tagsData.newbuildings = { ...tagsData.newbuildings, [value.id]: value.text };
            break;

          case 'railway':
            geo.value.push({
              type: value.type,
              id: value.id,
            });

            tagsData.railways = { ...tagsData.railways, [value.id]: value.text };
            break;

          case 'regional_district_id':
            geo.value.push({
              type: value.type,
              id: value.id,
            });

            tagsData.regionalDistricts = { ...tagsData.regionalDistricts, [value.id]: value.text };
            break;

          default:
            break;
        }
      } else if (isIPolygonObject(value)) {
        if (
          geo.value.filter(
            geoValue =>
              geoValue.type === 'polygon' &&
              coordsIsEqual(geoValue.coordinates, value.polygon ? coordsArrayNumberToString(value.polygon) : []),
          ).length > 0
        ) {
          return state;
        }

        geo.value.push({
          type: 'polygon',
          name: value.text,
          coordinates: value.polygon ? coordsArrayNumberToString(value.polygon) : [],
        });
      } else if (isICircleObject(value)) {
        if (
          geo.value.filter(
            geoValue =>
              geoValue.type === 'distance' &&
              circlesAreEqual(geoValue, { radius: value.radius, center: coordsNumberToString(value.center) }),
          ).length > 0
        ) {
          return state;
        }

        geo.value.push({
          type: 'distance',
          name: value.text,
          radius: value.radius,
          center: coordsNumberToString(value.center),
        });
      }

      if (value.type === 'underground') {
        const wasNoUndergrounds =
          !nextJsonQuery.geo || !nextJsonQuery.geo.value.some(geo => geo.type === 'underground');
        const wasNoTravelType = !nextJsonQuery.only_foot || !nextJsonQuery.only_foot.value;
        const wasNoTravelTime = !nextJsonQuery.foot_min || !nextJsonQuery.foot_min.value;

        if (wasNoUndergrounds && wasNoTravelTime && wasNoTravelType) {
          nextJsonQuery = jq(nextJsonQuery).setUndergroundTravel(
            EMetroTravelType.MapFoot,
            getDefaultTravelTimeForType(EMetroTravelType.MapFoot),
          );
        }
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...nextJsonQuery,
            geo,
          },
          tagsData,
        },
      };
    }

    case 'filters/tags/CLEAR_RAILWAY_TAGS': {
      const { jsonQuery } = state.filters;

      if (jsonQuery.geo) {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...jsonQuery,
              geo: {
                ...jsonQuery.geo,
                value: jsonQuery.geo.value.filter(geo => geo.type !== 'railway'),
              },
            },
            tagsData: {
              ...state.filters.tagsData,
              railways: {},
            },
          },
        };
      }

      return state;
    }

    case 'filters/tags/TAG_REMOVED': {
      const { tag } = action;
      const { districtsSwitcher, regionalDistrictsModal, jsonQuery } = state.filters;

      const newJsonQuery = getNewJsonQuery(tag, jsonQuery);

      if (isGeoTag(tag)) {
        if (isGeoUndergroundTag(tag)) {
          return state;
        }
        if (isGeoPolygonTag(tag)) {
          geo.value = geo.value.filter(item => item.type !== 'polygon' || !coordsIsEqual(item.coordinates, tag.value));
        } else if (isGeoCircleTag(tag)) {
          geo.value = geo.value.filter(item => item.type !== 'distance' || !circlesAreEqual(item, tag));
        } else if (isGeoRegionalDistrictTag(tag)) {
          geo.value = geo.value.filter(item => item.type !== 'regional_district_id' || item.id !== tag.value);
        } else {
          geo.value = geo.value.filter(
            item => item.type !== (tag.type.substring(4) as TGeoType) || item.id !== (tag as TGeoObjectTag).value,
          );
        }

        if (geo.value.length === 0) {
          geo = undefined;
        }

        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...newJsonQuery,
              geo,
            },
            districtsSwitcher: {
              ...state.filters.districtsSwitcher,
              selectedDistricts: isGeoDistrictTag(tag)
                ? unselectDistrict(districtsSwitcher.selectedDistricts, tag.value)
                : districtsSwitcher.selectedDistricts,
            },
            regionalDistrictsModal: {
              ...state.filters.regionalDistrictsModal,
              selectedDistricts: isGeoRegionalDistrictTag(tag)
                ? unselectRegionalDistrict(regionalDistrictsModal.selectedDistricts, tag.value)
                : regionalDistrictsModal.selectedDistricts,
            },
          },
        };
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...newJsonQuery,
          },
        },
      };
    }

    case 'filters/tags/ALL_TAGS_REMOVED': {
      return {
        ...state,
        filters: {
          ...state.filters,
          // jsonQuery здесь не модифицируем, теперь этим занимается src/packages/JsonQuery/clearTags.ts
          districtsSwitcher: {
            ...state.filters.districtsSwitcher,
            districts: undefined,
            selectedLocation: undefined,
            selectedDistricts: [],
          },
          regionalDistrictsModal: {
            ...state.filters.regionalDistrictsModal,
            districts: [],
            selectedDistricts: [],
          },
        },
      };
    }

    case 'filters/USER_INPUT_SAVED': {
      const { filters, ...rest } = state;

      return {
        ...rest,
        filters: {
          ...filters,
          userInput: action.userInput,
        },
      };
    }

    default:
      return state;
  }
}
