import { IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';

export const HAS_PROFITBASE_BOOKING_CHANGED_TYPE = 'filters/has_profitbase_booking/HAS_PROFITBASE_BOOKING_CHANGED';

export interface IHasProfitbaseBookingChangedAction {
  type: 'filters/has_profitbase_booking/HAS_PROFITBASE_BOOKING_CHANGED';
  value: boolean | undefined;
}

export function changeHasProfitbaseBooking(value: boolean | undefined): IHasProfitbaseBookingChangedAction {
  return {
    type: HAS_PROFITBASE_BOOKING_CHANGED_TYPE,
    value,
  };
}

export function hasProfitbaseBookingReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case HAS_PROFITBASE_BOOKING_CHANGED_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setHasProfitbaseBooking(action.value || undefined),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'has-profitbase-booking') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              has_profitbase_booking: undefined,
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
}
