import * as React from 'react';

import { ISelectKeyboardControllerOptions } from './types';

const handleSpace = (e: React.KeyboardEvent, options: ISelectKeyboardControllerOptions) => {
  e.preventDefault();

  if (options.opened) {
    options.actions.toggleFocusedOptionState();
  } else {
    options.actions.openDropdown();
  }
};

const handleEnter = (e: React.KeyboardEvent, options: ISelectKeyboardControllerOptions) => {
  if (options.opened) {
    e.preventDefault();

    options.actions.setFocusedOptionState(true);
    options.actions.closeDropdown();
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleEscape = (_: any, options: ISelectKeyboardControllerOptions) => {
  if (options.opened) {
    options.actions.closeDropdown();
  }
};

const handleVerticalArrow = (e: React.KeyboardEvent, options: ISelectKeyboardControllerOptions) => {
  e.preventDefault();

  if (options.opened) {
    const delta = e.nativeEvent.key === 'ArrowUp' ? -1 : 1;

    options.actions.moveFocus(delta);
  } else {
    options.actions.openDropdown();
  }
};

export const processMultiple = (e: React.KeyboardEvent, options: ISelectKeyboardControllerOptions) => {
  switch (e.nativeEvent.key) {
    case ' ':
      return handleSpace(e, options);

    case 'Enter':
      return handleEnter(e, options);

    case 'Escape':
      return handleEscape(e, options);

    case 'ArrowUp':
    case 'ArrowDown':
      return handleVerticalArrow(e, options);
  }
};
