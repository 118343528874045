import { IAppState, TActions } from '../../common/state';
import { jq, ERoomType } from '../../json_query';

export interface IRoomChangedAction {
  type: 'filters/room/ROOM_CHANGED';
  rooms: ERoomType[];
}

export function changeRoom(rooms: ERoomType[]): IRoomChangedAction {
  return {
    type: 'filters/room/ROOM_CHANGED',
    rooms,
  };
}

export function roomReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case 'filters/room/ROOM_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setRoom(action.rooms),
        },
      };
    default:
      return state;
  }
}
