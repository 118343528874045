export function getShortCategory(category: string) {
  if (category.endsWith('Sale')) {
    return category.replace('Sale', '');
  }
  if (category.endsWith('Rent')) {
    return category.replace('Rent', '');
  }

  return category;
}
