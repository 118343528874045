import * as React from 'react';

import { ComparisonControlContainer } from './ComparisonControlContainer';
import { ComplainControlContainer } from './ComplainControlContainer';
import * as styles from './ControlsContainer.css';
import { DownloadPDFControlContainer } from './DownloadPDFControlContainer';
import { FavoritesControlContainer } from './FavoritesControlContainer';
import { HideOfferControlContainer } from './HideOfferControlContainer';
import { ShowOnMapControlContainer } from './ShowOnMapControlContainer';
import { useOfferContext } from '../../../context';

// TODO добавить
// import { AddNoteControlContainer } from './AddNoteControlContainer';
// import { RequestCallContainer } from './RequestCallContainer';

export function ControlsContainer() {
  const { offer } = useOfferContext();

  if (offer.dailyrent?.hotelData?.isHotel) {
    return null;
  }

  return (
    <div className={styles['wrapper']}>
      <FavoritesControlContainer />
      <ComparisonControlContainer />
      <HideOfferControlContainer />
      <ComplainControlContainer />
      <ShowOnMapControlContainer />
      <DownloadPDFControlContainer />
    </div>
  );
}
