import { IHttpApi } from '@cian/http-api';
import { ILogger } from '@cian/logger';

import {
  createHideOfferModel,
  THideOfferResponseScheme,
  IHideOfferRequestScheme,
} from '../../packages/api-models/hidden-objects/v1/hide-offer-website';

interface IDependencies {
  httpApi: IHttpApi;
  logger: ILogger;
}

export async function postHideOffer(
  dependencies: IDependencies,
  parameters?: IHideOfferRequestScheme,
): Promise<THideOfferResponseScheme> {
  const { httpApi, logger } = dependencies;
  try {
    const response: THideOfferResponseScheme = await httpApi.fetch(createHideOfferModel({ parameters }), undefined);

    return response;
  } catch (ex) {
    logger.error(ex, { domain: 'shared.api.hidden_objects.postHideOffer' });

    throw ex;
  }
}
