import * as React from 'react';

import { EAction, EStatus, IState, TActions } from './types';

function reducer(prevState: IState, action: TActions): IState {
  switch (action.type) {
    case EAction.SetEmail:
      return {
        ...prevState,
        email: action.payload,
      };

    case EAction.SetSubscribeToNews:
      return {
        ...prevState,
        subscribeToNews: action.payload,
      };

    case EAction.SetStatusLoading:
      return {
        ...prevState,
        status: EStatus.Loading,
      };

    case EAction.SetStatusSucceed:
      return {
        ...prevState,
        status: EStatus.Succeed,
        emailConfirmationRequired: action.payload,
      };

    case EAction.SetStatusFailed:
      return {
        ...prevState,
        status: EStatus.Failed,
        errors: action.payload,
      };
  }
}

const defaultState: IState = {
  status: EStatus.Initial,
  email: '',
  subscribeToNews: true,
  emailConfirmationRequired: null,
  errors: null,
};

export function useState() {
  return React.useReducer(reducer, defaultState);
}
