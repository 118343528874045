import { IAppState, TActions, Dispatch } from '../../common/state';
import {
  CHAT_FORCE_OPEN_KEY,
  CHAT_FORCE_OPEN_INTERVAL,
  MOTIVATION_POPUP_KEY,
  MOTIVATION_POPUP_OPEN_INTERVAL,
} from '../../constants/newbuildingConsultant';
import { isForceNeedOpenSelector } from '../../selectors/newbuildingConsultant';
import { TThunkAction } from '../../types/redux';
import { isNeedToShow, updateShownTimestamp } from '../../utils/newbuildingConsultant/periodicActions';

export function newbuildingChatToggle(isOpen: boolean) {
  return {
    type: 'newbuildingConsultant/CHAT_TOGGLE',
    payload: isOpen,
  };
}

export function newbuildingOpenMotivationPopup(isOpen: boolean) {
  return {
    type: 'newbuildingConsultant/OPEN_MOTIVATION_POPUP',
    payload: isOpen,
  };
}

export function newbuildingOpenConsultantPopup(isOpen: boolean) {
  return {
    type: 'newbuildingConsultant/OPEN_CONSULTANT_POPUP',
    payload: isOpen,
  };
}

export function newbuildingChatOpenToggle(isOpen: boolean): TThunkAction {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const needForceOpen = isForceNeedOpenSelector(state);

    if (isOpen && needForceOpen && isNeedToShow(MOTIVATION_POPUP_KEY)) {
      updateShownTimestamp(CHAT_FORCE_OPEN_KEY, CHAT_FORCE_OPEN_INTERVAL);
    }

    dispatch(newbuildingChatToggle(isOpen));
  };
}

export function newbuildingOpenMotivationToggle(isOpen: boolean) {
  return (dispatch: Dispatch) => {
    if (isOpen) {
      updateShownTimestamp(MOTIVATION_POPUP_KEY, MOTIVATION_POPUP_OPEN_INTERVAL);
    }

    dispatch(newbuildingOpenMotivationPopup(isOpen));
  };
}

export interface INewbuildingChatToggle {
  type: 'newbuildingConsultant/CHAT_TOGGLE';
  payload: boolean;
}

export interface INewbuildingOpenMotivationPopup {
  type: 'newbuildingConsultant/OPEN_MOTIVATION_POPUP';
  payload: boolean;
}

export interface INewbuildingOpenConsultantPopup {
  type: 'newbuildingConsultant/OPEN_CONSULTANT_POPUP';
  payload: boolean;
}

export type INewbuildingChatActions =
  | INewbuildingChatToggle
  | INewbuildingOpenMotivationPopup
  | INewbuildingOpenConsultantPopup;

export function newbuildingConsultant(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'newbuildingConsultant/CHAT_TOGGLE':
      return {
        ...state,
        newbuildingConsultant: {
          ...state.newbuildingConsultant,
          isOpen: action.payload,
          showMotivationPopup: action.payload ? false : state.newbuildingConsultant.showMotivationPopup,
        },
      };
    case 'newbuildingConsultant/OPEN_MOTIVATION_POPUP':
      return {
        ...state,
        newbuildingConsultant: {
          ...state.newbuildingConsultant,
          showMotivationPopup: action.payload,
        },
      };
    case 'newbuildingConsultant/OPEN_CONSULTANT_POPUP':
      return {
        ...state,
        newbuildingConsultant: {
          ...state.newbuildingConsultant,
          consultantPopupOpen: action.payload,
        },
      };
    default:
      return state;
  }
}
