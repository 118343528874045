import { UnknownError } from '@cian/peperrors/shared';

import { TAddOfferToComparisonResponse } from '../../../../repositories/offers-comparison/v2/add-offer-to-comparison';

export const assertUnknownError = (response: TAddOfferToComparisonResponse): void => {
  if (response.statusCode !== 400) {
    return;
  }

  const { message, errors } = response.response;

  throw new UnknownError({
    domain: 'src/shared/services/offersComparison/addOfferToComparison.ts',
    message,
    details: {
      errors,
    },
  });
};
