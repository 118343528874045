import * as React from 'react';

import { IDeveloperTag } from '../../../state/tags/definitions/developer';
import { TagNew } from '../TagNew';

export interface IDeveloperTagItemProps {
  tag: IDeveloperTag;
  onDeleteClick(): void;
}

export const DeveloperTagItem = ({ onDeleteClick, tag }: IDeveloperTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>{tag.value ? 'От застройщиков' : 'От агентов и частных лиц'}</TagNew>;
};
