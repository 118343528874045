import { IAppState, TActions } from '../../../common/state';
import { IWordsIncludeTag } from '../tags/definitions/words_include';

export const IWordIncludeAddedActionType = 'filters/words_include/WORD_INCLUDE_ADDED';
export const IWordIncludeRemovedActionType = 'filters/words_include/WORD_INCLUDE_REMOVED';

export interface IWordIncludeAddedAction {
  type: 'filters/words_include/WORD_INCLUDE_ADDED';
  tag: string;
}

export interface IWordIncludeRemovedAction {
  type: 'filters/words_include/WORD_INCLUDE_REMOVED';
  tag: string;
}

export function wordIncludeAdded(tag: string): IWordIncludeAddedAction {
  return {
    type: IWordIncludeAddedActionType,
    tag,
  };
}

export function wordIncludeRemoved(tag: string): IWordIncludeRemovedAction {
  return {
    type: IWordIncludeRemovedActionType,
    tag,
  };
}

export function wordsIncludeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IWordIncludeAddedActionType: {
      const words =
        (state.filters.jsonQuery.description_include && state.filters.jsonQuery.description_include.value) || [];

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            description_include: {
              type: 'terms',
              value: words.concat(action.tag),
            },
          },
        },
      };
    }

    case IWordIncludeRemovedActionType: {
      let prevWords =
        (state.filters.jsonQuery.description_include && state.filters.jsonQuery.description_include.value) || [];

      prevWords = prevWords.filter(word => word !== action.tag);

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            description_include: prevWords.length
              ? {
                  type: 'terms',
                  value: prevWords,
                }
              : undefined,
          },
        },
      };
    }

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-wordsInclude') {
        let oldWords =
          (state.filters.jsonQuery.description_include && state.filters.jsonQuery.description_include.value) || [];

        oldWords = oldWords.filter(word => word !== (action.tag as IWordsIncludeTag).value);

        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              description_include: oldWords.length
                ? {
                    type: 'terms',
                    value: oldWords,
                  }
                : undefined,
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
}
