import { Checkbox } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';
import { SearchResultsTooltip } from '../../SearchResultsTooltip';

interface ICottageVillageProps {
  onChange(value: boolean | null): void;
  isCottageVillage: boolean | null;
  onApply?(): void;
  offersCountLoading?: boolean;
  offersCount?: number;
  openTooltip?: boolean;
  open?: boolean;
  onOpen?(): void;
  onClose?(): void;
}

export const CottageVillage: React.FC<ICottageVillageProps> = ({
  onChange,
  isCottageVillage,
  onApply,
  offersCountLoading,
  offersCount,
  openTooltip,
  open,
  onOpen,
  onClose,
}) => {
  const handleClick = React.useCallback(() => {
    if (!open && onOpen && isCottageVillage) {
      onOpen();
    } else if (open && onClose) {
      onClose();
    }
  }, [open, onOpen, onClose, isCottageVillage]);
  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked || null);
    },
    [onChange],
  );

  return (
    <div className={styles['container']}>
      <SearchResultsTooltip
        open={!open && openTooltip}
        offersCount={offersCount || 0}
        loading={offersCountLoading}
        placement="bottom-start"
        onApply={onApply}
      >
        <Checkbox
          label="В коттеджном посёлке"
          checked={!!isCottageVillage}
          onChange={handleChange}
          onClick={handleClick}
        />
      </SearchResultsTooltip>
    </div>
  );
};
