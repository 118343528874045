import { IBathroom } from './types';
import { ESuburbanWCType, FDealType } from '../../../../../JsonQuery';

const SUBURBAN_BATHROOM_OPTIONS: IBathroom[] = [
  { label: 'Неважно', value: null },
  { label: 'На улице', value: ESuburbanWCType.Outside },
  { label: 'В доме', value: ESuburbanWCType.Inside },
];

const SALE_SUBURBAN_BATHROOM_OPTIONS: IBathroom[] = [
  { label: 'Неважно', value: null },
  { label: 'Только в доме', value: ESuburbanWCType.Inside },
];

export function getSuburbanBathroomOptions(dealType: FDealType): IBathroom[] {
  if (dealType === FDealType.Sale) {
    return SALE_SUBURBAN_BATHROOM_OPTIONS;
  }

  return SUBURBAN_BATHROOM_OPTIONS;
}
