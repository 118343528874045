import { IAppState } from '../common/state';
import { INewbuildingSimilarOfferTransformed } from '../types/newbuildingSimilarOffer';
import { mapNewbuildingSimilarOffers } from '../utils/offer/mapNewbuildingSimilarOffers';

export const getNewbuildingSimilarOffers = ({
  newbuildingSimilarOffersBlock: block,
}: IAppState): INewbuildingSimilarOfferTransformed[] | null => {
  if (!block || !block.offers.length) {
    return null;
  }

  return mapNewbuildingSimilarOffers(block.offers);
};
