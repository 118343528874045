import { ca } from '@cian/analytics';

import { IBrokerAnalyticsUser } from './types';
import { TJsonQuery } from '../../../../packages/JsonQuery';

export const trackBannerShown = (sc: TJsonQuery, user: IBrokerAnalyticsUser) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'Show_sopr',
    label: 'BrokerDealBanner',
    sc,
    page: {
      pageType: 'Listing',
      siteType: 'desktop',
    },
    user,
  });
};
