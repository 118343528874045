import * as React from 'react';

import { UndergroundIcon } from '../../../../../common/components/underground_icon';
import { IGeoUndergroundTag } from '../../../../state/tags/definitions/geo';
import { TagNew } from '../../TagNew';

const style = require('./underground.css');

export interface IGeoUndergroundTagItemProps {
  tag: IGeoUndergroundTag;
  onDeleteClick(): void;
}

export const GeoUndergroundTagItem = (props: IGeoUndergroundTagItemProps) => {
  return (
    <TagNew onDeleteClick={props.onDeleteClick}>
      {props.tag.color && (
        <span className={style['icon-wrapper']}>
          <UndergroundIcon color={props.tag.color} />
        </span>
      )}
      {props.tag.text}
      {props.tag.releaseYear && <> &#183; {props.tag.releaseYear}</>}
    </TagNew>
  );
};
