import * as React from 'react';
import { connect } from 'react-redux';

import { LocationSwither } from './index';
import { IAppState, Dispatch } from '../../../common/state';
import { ILocation, TLocation } from '../../../types/location';
import {
  applySelectedLocation,
  closeLocationSwitcher,
  selectCity,
  selectCountry,
  selectRegion,
} from '../../state/location_switcher';
import { LocationSwitherAutocompleteContainer } from '../location_switcher_autocomplete/container';

const LocationSwitherContainer = connect(
  (state: IAppState) => {
    return {
      autocomplete: (<LocationSwitherAutocompleteContainer />) as React.ReactNode,
      selectedRegion: state.filters.locationSwitcher.selectedRegion,
      selectedCity: state.filters.locationSwitcher.selectedCity,
      isDataLoading: state.filters.locationSwitcher.isCitiesLoading,
      regions: state.filters.regions,
      cities: state.filters.locationSwitcher.cities,
      queryString: state.filters.queryString,
    };
  },
  (dispatch: Dispatch) => ({
    applySelectedLocation: () => {
      dispatch(applySelectedLocation());
    },
    onCountrySelected: () => {
      dispatch(selectCountry());
    },
    onRegionSelected: (region: TLocation) => {
      dispatch(selectRegion(region));
    },
    onCitySelected: (city: ILocation) => {
      dispatch(selectCity(city));
    },
    onClose: () => {
      dispatch(closeLocationSwitcher());
    },
  }),
)(LocationSwither);

// eslint-disable-next-line import/no-default-export
export default LocationSwitherContainer;
