import { IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';
import { FDealType } from '../../utils/category';

export interface IDealTypeChangedAction {
  type: 'filters/deal_type/DEAL_TYPE_CHANGED';
  value: FDealType;
}

export function changeDealType(value: FDealType): IDealTypeChangedAction {
  return {
    type: 'filters/deal_type/DEAL_TYPE_CHANGED',
    value,
  };
}

export function dealTypeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/deal_type/DEAL_TYPE_CHANGED': {
      const jsonQuery = jq(state.filters.jsonQuery);

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setDealType(action.value),
        },
      };
    }
    default:
      return state;
  }
}
