import { IConstructionType } from './types';
import { FOfferType } from '../../../../../JsonQuery';
import { EHouseMaterial } from '../../../types/jsonQuery';

const FULL_LIST: IConstructionType[] = [
  { label: 'Неважно', value: null },
  { label: 'Кирпичный', value: EHouseMaterial.Brick },
  { label: 'Деревянный', value: EHouseMaterial.Wood },
  { label: 'Монолитный', value: EHouseMaterial.Monolith },
  { label: 'Панельный', value: EHouseMaterial.Panel },
  { label: 'Блочный', value: EHouseMaterial.Block },
  { label: 'Кирпично-монолитный', value: EHouseMaterial.BrickMonolith },
  { label: 'Сталинский', value: EHouseMaterial.Stalin },
];

const FLAT_NEW_LIST: IConstructionType[] = [
  { label: 'Неважно', value: null },
  { label: 'Панельный', value: EHouseMaterial.Panel },
  { label: 'Кирпичный', value: EHouseMaterial.Brick },
  { label: 'Монолитный', value: EHouseMaterial.Monolith },
];

const SUBURBAN_LIST: IConstructionType[] = [
  { label: 'Неважно', value: null },
  { label: 'Кирпичный', value: EHouseMaterial.Brick },
  { label: 'Деревянный', value: EHouseMaterial.Wood },
  { label: 'Монолитный', value: EHouseMaterial.Monolith },
  { label: 'Щитовой', value: EHouseMaterial.Shield },
  { label: 'Каркасный', value: EHouseMaterial.Wireframe },
  { label: 'Газобетонный блок', value: EHouseMaterial.AerocreteBlock },
  { label: 'Газосиликатный блок', value: EHouseMaterial.GasSilicateBlock },
  { label: 'Пенобетонный блок', value: EHouseMaterial.FoamConcreteBlock },
];

export function getConstructionTypesList(offerType: FOfferType): IConstructionType[] {
  if (offerType === FOfferType.FlatNew) {
    return FLAT_NEW_LIST;
  }

  if ((offerType & FOfferType.Suburban & ~FOfferType.Land) !== 0) {
    return SUBURBAN_LIST;
  }

  return FULL_LIST;
}
