import * as React from 'react';

import { getTermValue } from '../../../../../JsonQuery';
import { DealType } from '../../../components/advancedFilters/DealType';
import { useContext } from '../../../utils/useContext';
import { useDealType } from '../../../utils/useDealType';
import { useOfferType } from '../../../utils/useOfferType';

export const DealTypeContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const dealType = useDealType();
  const offerType = useOfferType();
  const isByHomeowner = getTermValue('is_by_homeowner')(jsonQuery);

  const handleIsByHomeownerChange = React.useCallback(
    (value: boolean | null) => {
      onChange({ action: 'setIsByHomeowner', arguments: [value] });
    },
    [onChange],
  );

  return (
    <DealType
      dealType={dealType}
      offerType={offerType}
      isByHomeowner={isByHomeowner}
      onIsByHomeownerChange={handleIsByHomeownerChange}
    />
  );
};
