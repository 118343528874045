import { getTermValue } from './helpers';
import { ESuburbanUtility, NonEmptyArray, TJsonQuery } from './types';

export function getSuburbanUtilities(jsonQuery: TJsonQuery): NonEmptyArray<ESuburbanUtility> | null {
  const hasElectricity = getTermValue('electricity')(jsonQuery);
  const hasDrainage = getTermValue('has_drainage')(jsonQuery);
  const hasWater = getTermValue('has_water')(jsonQuery);
  const hasGas = getTermValue('gas')(jsonQuery);

  const suburbanUtilities: ESuburbanUtility[] = [];

  if (hasElectricity) {
    suburbanUtilities.push(ESuburbanUtility.Electricity);
  }

  if (hasDrainage) {
    suburbanUtilities.push(ESuburbanUtility.Drainage);
  }

  if (hasWater) {
    suburbanUtilities.push(ESuburbanUtility.Water);
  }

  if (hasGas) {
    suburbanUtilities.push(ESuburbanUtility.Gas);
  }

  return suburbanUtilities.length > 0 ? (suburbanUtilities as NonEmptyArray<ESuburbanUtility>) : null;
}
