import * as React from 'react';

import * as styles from './ArrowButton.css';
import { IArrowButtonProps } from './types';

export const ArrowButton: React.FC<React.PropsWithChildren<IArrowButtonProps>> = props => {
  const { onClick, children } = props;

  return (
    <button type="button" className={styles['button']} onClick={onClick}>
      {children}
    </button>
  );
};
