import { connect } from 'react-redux';

import { LocationSwitherAutocomplete } from './index';
import { IGeoSuggestItem } from '../../../common/components/geo_suggest';
import { IAppState, Dispatch } from '../../../common/state';
import { ILocation } from '../../../types/location';
import { requestAutocomplete, selectAutocompleteSuggestion } from '../../state/location_switcher';

export const LocationSwitherAutocompleteContainer = connect(
  (state: IAppState) => {
    return {
      suggestions: state.filters.locationSwitcher.autocompleteSuggestions,
      loading: state.filters.locationSwitcher.isAutocompleteLoading,
    };
  },
  (dispatch: Dispatch) => ({
    onValueChange: (value: string) => {
      dispatch(requestAutocomplete(value));
    },
    onSelect: (suggestion: IGeoSuggestItem<ILocation>) => {
      dispatch(selectAutocompleteSuggestion(suggestion.value));
    },
  }),
)(LocationSwitherAutocomplete);
