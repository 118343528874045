import * as React from 'react';

import { IHouseYearTag } from '../../../state/tags/definitions/house_year';
import { TagNew } from '../TagNew';

export interface IHouseYearTagItemProps {
  tag: IHouseYearTag;
  onDeleteClick(): void;
}

export const HouseYearTagItem = ({ onDeleteClick, tag }: IHouseYearTagItemProps) => {
  const text = [];

  if (tag.min !== undefined) {
    text.push(`от ${tag.min}`);
  }

  if (tag.max !== undefined) {
    text.push(`до ${tag.max}`);
  }

  return <TagNew onDeleteClick={onDeleteClick}>Год постройки {text.join(' ')}</TagNew>;
};
