import { ActionAfterViewed } from '@cian/action-after-viewed-component';
import { TTgbNativedescGeneralContext } from '@cian/adfox-templates';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { getTrackingData } from '../../../../../../../selectors/getTrackingData';
import { trackAdFoxMortgage } from '../../../../../../../tracking/adFoxMortgage';
import { isMortgageCampaign } from '../../../../utils/mortgage';
import { NativeTGBAsideInfo } from '../tgb_in_card/simplified';

export interface IResidentialNativeTGBBannerProps {
  context: TTgbNativedescGeneralContext;
  campaignId: string;
  bannerId: string;
  locationId: number | undefined;
}

export function ResidentialNativeTGBBanner(props: IResidentialNativeTGBBannerProps) {
  const { context, locationId, campaignId, bannerId } = props;

  const tracking = useSelector(getTrackingData);

  const onTrack = React.useCallback(
    (isShow: boolean) => {
      const { user, page } = tracking;

      if (!isMortgageCampaign(campaignId)) {
        return;
      }

      trackAdFoxMortgage(isShow ? 'show' : 'click', 'LinkIpoteka', bannerId, user, page);
    },
    [tracking, campaignId, bannerId],
  );

  const onView = React.useCallback(() => {
    if (!isMortgageCampaign(campaignId)) {
      return;
    }
  }, [campaignId]);

  return (
    <ActionAfterViewed callback={onView} percentVisible={0.5}>
      <NativeTGBAsideInfo context={context} locationId={locationId} onTrack={onTrack} />
    </ActionAfterViewed>
  );
}
