import { Link } from '@cian/frontend-black-friday-banner/es/components/Link';
import { UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';
import { INewbuildingLayoutOffer } from '../../../../types/residentialComplexLayouts/layoutWithOffers/offer';

interface IOffersRowProps {
  offer: INewbuildingLayoutOffer;
}

export const OffersRow = ({ offer }: IOffersRowProps) => {
  return (
    <div className={style['row']}>
      <div className={style['offer-title']}>
        <UIText1>
          <Link href={`offer.offerId`} target="_blank">
            {offer.description}
          </Link>
        </UIText1>
      </div>
      <div className={style['description-cell']}>
        <UIText1 color="current_color">{offer.buildingDeadline}</UIText1>
      </div>
      <div className={style['description-cell']}>
        <UIText1 color="current_color">{offer.hasDecoration}</UIText1>
      </div>
      <div className={style['description-cell']}>
        <UIText1 color="black_100" textAlign="right" as="span">
          {offer.priceText}
        </UIText1>
      </div>
      <div className={style['controls-cell']}></div>
    </div>
  );
};
