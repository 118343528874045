import { EStatus } from '../../../../../fragments/OfferCardWidget/shared/types/offer-comparison';
import { IAppState, TActions } from '../../../../common/state';
import { AddToComparisonActionType, DeleteFromComparisonActionType } from '../index';

export function offerComparisonStatusReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case AddToComparisonActionType:
      return {
        ...state,
        results: {
          ...state.results,
          offers: state.results.offers.map(offer => {
            return offer.cianId === action.offerId || offer.id === action.offerId
              ? Object.assign({}, offer, {
                  comparisonStatus: {
                    status: EStatus.Added,
                    description: 'Добавлено в сравнение',
                  },
                })
              : offer;
          }),
        },
      };
    case DeleteFromComparisonActionType:
      return {
        ...state,
        results: {
          ...state.results,
          offers: state.results.offers.map(offer => {
            return offer.cianId === action.offerId || offer.id === action.offerId
              ? Object.assign({}, offer, {
                  comparisonStatus: {
                    status: EStatus.Available,
                    description: 'Добавить к сравнению',
                  },
                })
              : offer;
          }),
        },
      };
    default:
      return state;
  }
}
