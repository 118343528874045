import { IAppState } from '../common/state';
import { TTrackingUser } from '../types/user';

export const getTrackingUser = (state: IAppState): TTrackingUser => {
  const { user } = state;

  if (user.isAuthenticated) {
    return {
      userId: user.userId,
    };
  }

  return {};
};
