import * as React from 'react';

import {
  OnlineRentalAgreementTooltipContent,
  trackTooltipHover,
} from '../../../components/OnlineRentalAgreementTooltip';
import { ELabelColor, ILabel } from '../../../types/label';

export const getOnlineRentalAgreementLabel = (): ILabel => {
  return {
    text: 'Онлайн-договор',
    color: ELabelColor.Gray,
    tooltip: {
      title: 'Онлайн-договор',
      description: <OnlineRentalAgreementTooltipContent />,
      onOpen: trackTooltipHover,
    },
  };
};
