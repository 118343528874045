import { addSavedOffer } from '@cian/favorites-assign-widget';

import { IAppState, TActions, Dispatch } from '../../common/state';
import { IOffer } from '../../offer';
import { showMotivationPopup } from '../../serp/state/login_motivation_popup';
import { changeFavorite as apiChangeFavorite, IFavoriteChangeResponse } from '../api';

export const FavoriteChangedActionType = 'filters/favorite/FAVORITE_CHANGED';
export const FavoriteChangedErrorActionType = 'filters/favorite/FAVORITE_CHANGED_ERROR';

export interface IFavoriteChangedAction {
  type: 'filters/favorite/FAVORITE_CHANGED';
  offerId: number;
}

export interface IFavoriteChangedErrorAction {
  type: 'filters/favorite/FAVORITE_CHANGED_ERROR';
  offerId: number;
}

const FAVORITES_LIMIT_FOR_UNAUTH_USER = 5;

export function changeFavorite(offer: IOffer, isFavorite: boolean) {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const { user, loginMotivationPopupOnFavorite, makeRequest } = getState();
    const offerId = offer.cianId || offer.id;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentCount = (window as any).__header_favorites_count__ || 0;

    if (isFavorite && !user.isAuthenticated && currentCount >= FAVORITES_LIMIT_FOR_UNAUTH_USER) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any).__headerLoginPopupShowMotivation) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).__headerLoginPopupShowMotivation();
      }
    } else {
      dispatch({
        type: FavoriteChangedActionType,
        offerId,
      });

      if (isFavorite) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).__header_favorites_count__ = currentCount + 1;

        if (!user.isAuthenticated && loginMotivationPopupOnFavorite.isActive) {
          dispatch(showMotivationPopup(offerId));
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).__header_favorites_count__ = currentCount - 1;
      }

      apiChangeFavorite(makeRequest, offer, isFavorite).then(
        (data: IFavoriteChangeResponse) => {
          if (isFavorite) {
            const userHasFolders = Boolean(data.folderInfo && data.folderInfo.hasFolders);
            const folder = data.folderInfo?.folder;

            let savedInFolders = null;
            if (folder?.name && folder?.id) {
              savedInFolders = [
                {
                  id: folder.id,
                  name: folder.name,
                },
              ];
            }

            dispatch(addSavedOffer({ offerIds: [offer.id], savedInFolders, userHasFolders }));
          }

          if (data.newbuilding_id !== undefined && window.__header_updateFavorites__) {
            window.__header_updateFavorites__(true);
          }

          return window.__header_updateFavorites__ && window.__header_updateFavorites__(isFavorite);
        },
        () => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).__header_favorites_count__ = currentCount - 1;

          changeFavoriteError(offerId);
        },
      );
    }
  };
}

export function changeFavoriteError(offerId: number): IFavoriteChangedErrorAction {
  return {
    type: FavoriteChangedErrorActionType,
    offerId,
  };
}

const getChangedState = (state: IAppState, action: IFavoriteChangedAction | IFavoriteChangedErrorAction): IAppState => {
  return {
    ...state,
    results: {
      ...state.results,
      offers: state.results.offers.map(offer => {
        return offer.cianId === action.offerId || offer.id === action.offerId
          ? Object.assign({}, offer, {
              isFavorite: !offer.isFavorite,
            })
          : offer;
      }),
    },
  };
};

export function favoriteReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case FavoriteChangedActionType:
      return getChangedState(state, action);
    case FavoriteChangedErrorActionType:
      return getChangedState(state, action);
  }

  return state;
}
