import { ca } from '@cian/analytics';

export function trackSubsidisedMortgageBannerClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'Click',
    label: 'mortgage_discount_secondary_banner',
  });
}

export function trackSubsidisedMortgageBannerShow() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'show_sopr',
    label: 'mortgage_discount_secondary_banner',
  });
}
