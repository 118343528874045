import { ECalendarActionTypes, ICalendarState } from './types';
import { MONTHS_TO_DISPLAY } from '../../constants/widget';
import { ICalendarDay } from '../../types';
import { updateCalendarData } from '../../utils';

interface IClickOnDate {
  type: ECalendarActionTypes.ClickOnDate;
  day: ICalendarDay;
}

interface IClickNextPage {
  type: ECalendarActionTypes.NextPage;
}

interface IClickPrevPage {
  type: ECalendarActionTypes.PrevPage;
}

interface IResetUpdated {
  type: ECalendarActionTypes.ResetUpdated;
}

type TCalendarActions = IClickOnDate | IClickNextPage | IClickPrevPage | IResetUpdated;

export function calendarReducer(state: ICalendarState, action: TCalendarActions): ICalendarState {
  switch (action.type) {
    case ECalendarActionTypes.ClickOnDate: {
      let from = action.day.date;
      let to = null;
      let updated = false;

      if (state.selecting) {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        from = state.from!;
        to = action.day.date;
        updated = true;

        if (from > to) {
          to = from;
          from = action.day.date;
        }
      }

      return {
        ...state,
        from,
        to,
        calendar: updateCalendarData({ calendar: state.initialCalendar, from, to }),
        selecting: !state.selecting,
        updated,
      };
    }
    case ECalendarActionTypes.NextPage:
      if (state.isNextPageAvailable) {
        const nextCurrentPage = state.currentPage + 2;

        return {
          ...state,
          currentPage: nextCurrentPage,
          isPrevPageAvailable: nextCurrentPage > 1,
          isNextPageAvailable: nextCurrentPage < state.initialCalendar.length - MONTHS_TO_DISPLAY - 1,
        };
      }

      return state;
    case ECalendarActionTypes.PrevPage:
      if (state.isPrevPageAvailable) {
        const nextCurrentPage = state.currentPage - 2;

        return {
          ...state,
          currentPage: nextCurrentPage,
          isPrevPageAvailable: nextCurrentPage > 1,
          isNextPageAvailable: nextCurrentPage < state.initialCalendar.length - MONTHS_TO_DISPLAY - 1,
        };
      }

      return state;

    case ECalendarActionTypes.ResetUpdated:
      return {
        ...state,
        updated: false,
      };

    default:
      return state;
  }
}
