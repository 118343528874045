import * as React from 'react';

import { useApplicationContext } from '../../../../../../shared/utils/applicationContext';
import { getTermValue } from '../../../../../JsonQuery';
import { EElectronicTradingType } from '../../../../../JsonQuery/types/electronicTradingType';
import { ElectronicTrading } from '../../../components/advancedFilters/ElectronicTrading';
import { useContext } from '../../../utils/useContext';

export const ElectronicTradingContainer: React.FC = () => {
  const { config } = useApplicationContext();
  const { jsonQuery, onChange } = useContext();
  const value = getTermValue('electronic_trading')(jsonQuery);

  /**
   * @todo Удалить фича-тогл
   * @description Данный функционал появился в задаче CD-187920, будет удалён в задаче CD-189395
   */
  const isElectronicTradingEnabled = config.getStrict<boolean>('Audience.ElectronicTrading.Enabled');

  const handleChange = React.useCallback(
    (electronicTradingType: EElectronicTradingType | null) => {
      onChange({ action: 'setElectronicTradingType', arguments: [electronicTradingType] });
    },
    [onChange],
  );

  if (!isElectronicTradingEnabled) {
    return null;
  }

  return <ElectronicTrading value={value} onChange={handleChange} />;
};
