import * as React from 'react';

import { EFacility } from '../../../../json_query/types';
import { IFacilityTag } from '../../../state/tags/definitions/facilities';
import { TagNew } from '../TagNew';

export interface IFacilityTagItemProps {
  tag: IFacilityTag;
  onDeleteClick(): void;
}

const facilitiesMap = {
  [EFacility.Bath]: 'С ванной',
  [EFacility.Conditioner]: 'С кондиционером',
  [EFacility.Dishwasher]: 'С посудомоечной машиной',
  [EFacility.Fridge]: 'С холодильником',
  [EFacility.Garage]: 'С гаражом',
  [EFacility.Internet]: 'С интернетом',
  [EFacility.KidsAllowed]: 'Можно с детьми',
  [EFacility.KitchenFurniture]: 'С кухонной мебелью',
  [EFacility.PetsAllowed]: 'Можно с животными',
  [EFacility.Phone]: 'С телефоном',
  [EFacility.Pool]: 'С бассейном',
  [EFacility.RoomFurniture]: 'Комнатная мебель',
  [EFacility.Sauna]: 'С баней',
  [EFacility.Security]: 'С охраной',
  [EFacility.Shower]: 'С душевой кабиной',
  [EFacility.TV]: 'С телевизором',
  [EFacility.Washer]: 'Со стиральной машиной',
  [EFacility.RoomFurniture]: 'Комнатная мебель',
};

export const FacilityTagItem = ({ tag, onDeleteClick }: IFacilityTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>{facilitiesMap[tag.value]}</TagNew>;
};
