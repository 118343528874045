import React from 'react';

import * as styles from './SeoSuggestion.css';

interface ISeoSuggestionProps {
  text: string;
  url?: string;
  distance?: string;
}

export function SeoSuggestion({ text, distance, url }: ISeoSuggestionProps) {
  return (
    <span className={styles['suggestion']}>
      {' '}
      <span>от</span>{' '}
      <a
        href={url}
        target="_blank"
        onClick={e => {
          e.stopPropagation();
        }}
        className={styles['link']}
        rel="noreferrer"
      >
        {text}
      </a>
      {distance}
    </span>
  );
}
