import * as React from 'react';

import { getTermsValue, isSuburban, NonEmptyArray } from '../../../../../JsonQuery';
import { ConstructionType } from '../../../components/advancedFilters/ConstructionType';
import { EHouseMaterial } from '../../../types/jsonQuery';
import { useContext } from '../../../utils/useContext';
import { useOfferType } from '../../../utils/useOfferType';

export const ConstructionTypeContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const offerType = useOfferType();
  const label = isSuburban(offerType) ? 'Материал дома' : 'Тип дома';
  let constructionType = getTermsValue('house_material')(jsonQuery);
  if (constructionType && constructionType.length === 0) {
    constructionType = null;
  }

  const handleChange = React.useCallback(
    (constructionType: NonEmptyArray<EHouseMaterial> | null) => {
      onChange({ action: 'setConstructionTypes', arguments: [constructionType] });
    },
    [onChange],
  );

  return (
    <ConstructionType
      offerType={offerType}
      value={constructionType as NonEmptyArray<EHouseMaterial> | null}
      onChange={handleChange}
      label={label}
    />
  );
};
