import * as React from 'react';

import { FOfferType } from '../../../../utils/category';
import { TagNew } from '../TagNew';

export interface IBlackFridayTagItemProps {
  offerType: FOfferType;
  onDeleteClick(): void;
}

export const BlackFridayTagItem = ({ offerType, onDeleteClick }: IBlackFridayTagItemProps) => {
  const text = offerType === FOfferType.FlatNew ? 'Только до 29 сентября' : 'Скидки и акции: до 29 сентября';

  return <TagNew onDeleteClick={onDeleteClick}>{text}</TagNew>;
};
