import { CheckboxButton } from '@cian/ui-kit';
import * as React from 'react';

const style = require('./index.css');

export interface IHeaderProps {
  includeNewMoscow: boolean | undefined;
  onNewMoscowSwitched(includeNewMoscow: boolean | undefined): void;
}

export class MoscowDistrictsHeader extends React.Component<IHeaderProps> {
  public render() {
    return (
      <div className={style.header}>
        <CheckboxButton
          label="Новая Москва"
          checked={this.props.includeNewMoscow === undefined ? false : this.props.includeNewMoscow}
          onChange={() => {
            this.props.onNewMoscowSwitched(this.props.includeNewMoscow ? undefined : true);
          }}
        />

        <CheckboxButton
          label="Москва без Новой Москвы"
          onChange={() => {
            this.props.onNewMoscowSwitched(
              this.props.includeNewMoscow === undefined
                ? false
                : this.checkWithoutNewMoscow(this.props.includeNewMoscow),
            );
          }}
          checked={this.props.includeNewMoscow === undefined ? false : !this.props.includeNewMoscow}
        />
      </div>
    );
  }

  private checkWithoutNewMoscow = (includeNewMoscow: boolean) => {
    return includeNewMoscow ? false : undefined;
  };
}
