import { getConfig } from '@cian/config/browser';
import * as React from 'react';

export const useRealtorFormUrl = (): string | null => {
  const [url, setUrl] = React.useState<string | null>(null);

  React.useEffect(() => {
    const config = getConfig();

    const urlFromCfg = config.getStrict<string>('requestFormRealtorFrontend.pagePath');
    setUrl(urlFromCfg);
  }, []);

  return url;
};
