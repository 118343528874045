import { Checkbox } from '@cian/ui-kit/checkbox';
import { IconFunctionalSuccess16 } from '@cian/ui-kit-design-tokens/icons';
import classNames from 'classnames';
import * as React from 'react';

import * as styles from './selectPopupOption.css';
import { SearchResultsTooltip } from '../../../SearchResultsTooltip';
import { ISelectOption } from '../../types';

interface ISelectPopupOptionProps {
  option: ISelectOption;
  index: number;
  multiple?: boolean;
  focused: boolean;
  selected: boolean;
  offersCount?: number;
  offersCountLoading?: boolean;
  hideTooltipCount?: boolean;
  tooltipHostElement?: HTMLElement | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick(e: React.MouseEvent<HTMLDivElement>, index: number): any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMouseMove(e: React.MouseEvent<HTMLDivElement>, index: number): any;
  forwardedRef?: React.RefObject<HTMLDivElement>;
  openTooltip?: boolean;
  onApply?(): void;
}

export class SelectPopupOption extends React.Component<ISelectPopupOptionProps> {
  public render() {
    return (
      <SearchResultsTooltip
        hideCount={this.props.hideTooltipCount}
        offersCount={this.props.offersCount || 0}
        open={this.props.openTooltip}
        loading={this.props.offersCountLoading}
        hostElement={this.props.tooltipHostElement}
        placement="right"
        onApply={this.props.onApply}
      >
        <div
          ref={this.props.forwardedRef}
          data-name="SelectPopupOption"
          className={classNames(
            styles['option'],
            this.props.focused && styles['focused'],
            this.props.multiple && styles['multiple'],
          )}
          onClick={this.handleClick}
          onMouseMove={this.handleMouseMove}
        >
          {this.renderIcon()}

          {this.props.option.label}

          {this.renderMarker()}
        </div>
      </SearchResultsTooltip>
    );
  }

  private renderIcon() {
    if (this.props.multiple) {
      return (
        <div className={styles['checkbox']}>
          <Checkbox checked={this.props.selected} onClick={this.handlePreventClick} />
        </div>
      );
    }

    return (
      this.props.selected && (
        <div className={styles['icon']}>
          <IconFunctionalSuccess16 color="primary_100" />
        </div>
      )
    );
  }

  private handlePreventClick = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  private renderMarker() {
    if (!this.props.option.marker) {
      return null;
    }

    return <span className={styles['marker']}>{this.props.option.marker}</span>;
  }

  private handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    this.props.onClick(e, this.props.index);
  };

  private handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    this.props.onMouseMove(e, this.props.index);
  };
}
