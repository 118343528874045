import { EGetUserStatus } from '../../actions/user';
import { IAppState, TActions } from '../../common/state';

export function userReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case EGetUserStatus.Succeed: {
      return {
        ...state,
        user: action.payload,
      };
    }
  }

  return state;
}
