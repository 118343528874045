import { FOfferType } from '../../../JsonQuery';
import { IOfferType } from '../components/filters/OfferType/types';

export const getSaleResidentialOfferTypes = (isSearchFlatShareEnabled: boolean): IOfferType[][] => [
  [
    { label: 'Квартира в новостройке', value: FOfferType.FlatNew },
    { label: 'Квартира во вторичке', value: FOfferType.FlatOld },
  ],
  [
    { label: 'Комната', value: FOfferType.Room },
    !isSearchFlatShareEnabled && { label: 'Доля', value: FOfferType.FlatShared },
  ].filter(Boolean) as IOfferType[],
  [
    { label: 'Дом, дача', value: FOfferType.House },
    { label: 'Часть дома', value: FOfferType.HousePart },
    { label: 'Таунхаус', value: FOfferType.Townhouse },
    { label: 'Участок', value: FOfferType.Land },
  ],
  [{ label: 'Гараж', value: FOfferType.Garage }],
];
