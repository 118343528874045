import * as React from 'react';

import { EElevatorType } from '../../../../../JsonQuery';
import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';

interface IElevators {
  label: string;
  value: EElevatorType | null;
}

const ELEVATOR_TYPES: IElevators[] = [
  { label: 'Неважно', value: null },
  { label: 'Есть любой', value: EElevatorType.Any },
  { label: 'Есть грузовой', value: EElevatorType.Service },
];

interface IElevatorsProps {
  value: EElevatorType | null;
  onChange(value: EElevatorType | null): void;
}

export const Elevators: React.FC<IElevatorsProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_elevators" label="Лифт">
      <RadioButtonGroup options={ELEVATOR_TYPES} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
