/* istanbul ignore file */

/**
 * @todo Удалить файл с экспериментом
 * @description Данный функционал появился в задаче CD-206850, будет удалён в задаче CD-206973
 */

import { useSelector } from 'react-redux';

import { isSuburban, offerTypeFromJsonQuery } from '../../../packages/JsonQuery';
import { IAppState } from '../../common/state';
import { MOSCOW_AND_REGION } from '../../constants/regions';
import { useApplicationContext } from '../../utils/applicationContext';
import { isAbUseExperimentGroup } from '../../utils/experiments/isAbUseExperimentGroup';
import { getIsNewbuildingFromJsonQuery } from '../../utils/getIsNewbuildingFromJsonQuery';
import { selectJsonQuery } from '../results';
import { selectExperiments } from '../selectExperiments';

export const NEWBUILDING_MARTECH_NEW_DESIGN_TGB_LISTING_EXPERIMENT = 'newbuilding_martech_new_design_tgb_listing';

function isMiMoSelector(state: IAppState): boolean {
  if (typeof state.filters.currentLocation === 'object') {
    const { parentId, id, mainTownId } = state.filters.currentLocation;

    const currentRegionId: number = parentId ?? mainTownId ?? id;

    return MOSCOW_AND_REGION.includes(currentRegionId);
  }

  if (state.filters.currentLocation === 'moscow_mo') {
    return true;
  }

  return state.currentSubdomain === 'www';
}

export function useSelectIsNewbuildingMartechNewDesignTgbListingExperimentEnabled(): boolean {
  const { config } = useApplicationContext();

  const abUseExperiments = useSelector(selectExperiments);

  const jsonQuery = useSelector(selectJsonQuery);
  const isMiMo = useSelector(isMiMoSelector);

  return (
    !!config.get<boolean>('NewbuildingMartech.NewDesignTgbListing.Enabled') &&
    isMiMo &&
    !getIsNewbuildingFromJsonQuery(jsonQuery) &&
    !isSuburban(offerTypeFromJsonQuery(jsonQuery)) &&
    isAbUseExperimentGroup(abUseExperiments, NEWBUILDING_MARTECH_NEW_DESIGN_TGB_LISTING_EXPERIMENT, 'new')
  );
}
