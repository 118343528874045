import { isBefore, startOfToday } from 'date-fns';

import { IRangeDate } from '../types';

type TRangeDate = IRangeDate | null;

export const getRangeDate = (date: Date | null): TRangeDate => {
  const today = startOfToday();

  const isBeforeToday = !date || isBefore(date, today);

  if (isBeforeToday) {
    return null;
  }

  return {
    day: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
  };
};
