import { IconFunctionalLock16 } from '@cian/ui-kit-design-tokens/icons';
import React from 'react';

import * as styles from './Booked.css';

export interface IBookedProps {
  fromCian?: boolean;
  children: React.ReactNode;
}

export const Booked: React.FC<IBookedProps> = ({ fromCian, children }) => {
  return (
    <div className={styles['container']}>
      {children}
      <div className={styles['banner']}>
        <IconFunctionalLock16 />
        <span className={styles['text']}>Квартира забронирована {fromCian && 'на Циан'}</span>
      </div>
    </div>
  );
};
