import { MOSCOW_AND_REGION } from '../../../packages/Filters/shared/constants/regions';
import { IAppState } from '../../common/state';
import { NB_CONSULTANT_TRIGGERING_MESSAGE } from '../../constants/experiments';
import { isAbUseExperimentGroup } from '../../utils/experiments/isAbUseExperimentGroup';
import { isInRegionSelector } from '../isInRegion';

export function motivationPopupOpenSelector(state: IAppState) {
  return state.newbuildingConsultant.showMotivationPopup;
}

export function motivationPopupAvailableSelector(state: IAppState) {
  const { config, isNewbuildingsOnly, abUseExperiments } = state;

  return (
    !!config.get<string>('webim.accountName') &&
    isNewbuildingsOnly &&
    isInRegionSelector(MOSCOW_AND_REGION)(state) &&
    isAbUseExperimentGroup(abUseExperiments, NB_CONSULTANT_TRIGGERING_MESSAGE, 'enabled')
  );
}
