import * as React from 'react';

import { ITotalFloorsTag } from '../../../state/tags/definitions/total_floors';
import { TagNew } from '../TagNew';

export interface ITotalFloorsTagItemProps {
  tag: ITotalFloorsTag;
  onDeleteClick(): void;
}

export const TotalFloorsTagItem = ({ tag, onDeleteClick }: ITotalFloorsTagItemProps) => {
  const { min, max } = tag;

  const text = React.useMemo(() => {
    if (min && max && min === max) {
      return max;
    }

    return [min && `от ${min}`, max && `до ${max}`].filter(Boolean).join(' ');
  }, [max, min]);

  return <TagNew onDeleteClick={onDeleteClick}>Этажей в доме {text}</TagNew>;
};
