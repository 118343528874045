import { IAppState, TActions } from '../../common/state';
import { EPriceType, jq } from '../../json_query';

export interface IPriceTypeChangedAction {
  type: 'filters/priceType/PRICE_TYPE_CHANGED';
  priceType: EPriceType;
}

export function setPriceType(priceType: EPriceType): IPriceTypeChangedAction {
  return {
    type: 'filters/priceType/PRICE_TYPE_CHANGED',
    priceType,
  };
}

export function priceTypeReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case 'filters/priceType/PRICE_TYPE_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setPriceType(action.priceType),
        },
      };
    default:
      return state;
  }
}
