import * as React from 'react';

export function useDebouncedCallback<T extends (...args: never[]) => unknown>(
  callback: T,
  delay: number,
  dependencies: ReadonlyArray<unknown>,
): T {
  const debouncedFunction = React.useRef<T>(callback);
  debouncedFunction.current = callback;

  const isComponentUnmounted = React.useRef(false);
  const functionTimeoutHandler = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  React.useEffect(
    () => () => {
      isComponentUnmounted.current = true;
    },
    [],
  );

  return React.useCallback(
    ((...args) => {
      if (functionTimeoutHandler.current) {
        clearTimeout(functionTimeoutHandler.current);
      }

      functionTimeoutHandler.current = setTimeout(() => {
        functionTimeoutHandler.current = null;

        if (!isComponentUnmounted.current) {
          debouncedFunction.current(...args);
        }
      }, delay);
    }) as T,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [delay, ...dependencies],
  );
}
