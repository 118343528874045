import { getBuilders } from './getBuilders';
import { IAppState } from '../../common/state';
import { IBuilder } from '../../types/newbuildingHeader';

export function getBuilder(state: IAppState): IBuilder | null {
  const builders = getBuilders(state);

  if (builders) {
    return builders[0];
  }

  return null;
}
