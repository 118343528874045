import * as React from 'react';

import { getTermValue } from '../../../../../JsonQuery';
import { CottageVillage } from '../../../components/filters/CottageVillage';
import { IFilterComponentProps } from '../../../types/filters';
import { ESuburbanOfferFilter } from '../../../types/jsonQuery';
import { TAvailableModifierActions } from '../../../types/modifier';
import { useSearchResultsTooltipOpen } from '../../../utils/searchResultsTooltipOpen';
import { useContext } from '../../../utils/useContext';

export const CottageVillageContainer: React.FC<IFilterComponentProps> = ({ filterKey, open, onOpen, onClose }) => {
  const { jsonQuery, onChange, onApply, jsonQueryCountRefreshing, jsonQueryCount } = useContext();
  const action: TAvailableModifierActions = 'setPossessor';
  const isCottageVillage = !!getTermValue('suburban_offer_filter')(jsonQuery);

  const handleChange = React.useCallback(
    (posessorType: boolean | null) => {
      if (posessorType) {
        onChange({ action, arguments: [ESuburbanOfferFilter.Builder] });
      } else {
        onChange({ action, arguments: [ESuburbanOfferFilter.Any] });
      }
    },
    [onChange],
  );
  const openTooltip = useSearchResultsTooltipOpen({
    filterKey,
    actions: ['setPossessor'],
  });

  return (
    <CottageVillage
      onChange={handleChange}
      isCottageVillage={isCottageVillage}
      onApply={onApply}
      offersCountLoading={jsonQueryCountRefreshing}
      offersCount={jsonQueryCount}
      open={open}
      openTooltip={openTooltip}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
};
