import { ENewbuildingLayoutOffersActionTypes, TNewbuildingLayoutOffersFinishedPayload } from './types';
import { fetchResidentialComplexLayoutWithOffers } from '../../services/fetchResidentialComplexLayoutWithOffers';
import { TThunkAction } from '../../types/redux';
import { actionGenerator } from '../../utils/redux';
import { newbuildingLayoutsOff } from '../newbuildingLayouts';

export const setNewbuildingLayoutOffersStartRequest = actionGenerator<ENewbuildingLayoutOffersActionTypes.StartRequest>(
  ENewbuildingLayoutOffersActionTypes.StartRequest,
);
export const setNewbuildingLayoutOffersFinished = actionGenerator<
  ENewbuildingLayoutOffersActionTypes.Finished,
  TNewbuildingLayoutOffersFinishedPayload
>(ENewbuildingLayoutOffersActionTypes.Finished);

export const setNewbuildingLayoutOffersError = actionGenerator<ENewbuildingLayoutOffersActionTypes.Error>(
  ENewbuildingLayoutOffersActionTypes.Error,
);

interface INewbuildingLayoutOffersParams {
  layoutKey: string;
}

export function getNewbuildingLayoutOffers({ layoutKey }: INewbuildingLayoutOffersParams): TThunkAction<Promise<void>> {
  return async (dispatch, getState, context) => {
    dispatch(setNewbuildingLayoutOffersStartRequest());

    const { logger } = context;

    const state = getState();

    const prevJsonQuery = state.filters.jsonQuery;
    const { jsonQueryFullUrl } = state.filters;
    const { currentSubdomain } = state;

    const jsonQuery = {
      ...prevJsonQuery,
      layout_key: {
        type: 'term',
        value: layoutKey,
      },
    };

    try {
      const newbuildingLayoutOffers = await fetchResidentialComplexLayoutWithOffers(context, {
        subdomain: currentSubdomain,
        path: jsonQueryFullUrl || '',
        jsonQuery,
      });

      if (!newbuildingLayoutOffers) {
        dispatch(newbuildingLayoutsOff());
      }

      dispatch(setNewbuildingLayoutOffersFinished(newbuildingLayoutOffers));
    } catch (error) {
      logger.error(error);

      dispatch(setNewbuildingLayoutOffersError());
    }
  };
}
