import React from 'react';

import { AuctionApplyNewBetHint } from '../../../../components/AuctionApplyNewBetHint';
import { useAuctionContext } from '../../../../context/AuctionContext';

export function AuctionApplyNewBetHintContainer() {
  const { nextBet, applyNewBetDate } = useAuctionContext();

  if (!nextBet || !applyNewBetDate) {
    return null;
  }

  return <AuctionApplyNewBetHint nextBet={nextBet} applyNewBetDate={applyNewBetDate} />;
}
