import { makeSearch } from './search';
import { IAppState, TActions, Dispatch } from '../../common/state';
import { EMetroTravelType, jq } from '../../json_query';

export interface IUndergroundTravelChangedAction {
  type: 'filters/underground_travel/UNDERGROUND_TRAVEL_CHANGED';
  travelType: EMetroTravelType;
  travelTime: number | undefined;
}

export function changeUndergroundTravel(
  travelType: EMetroTravelType,
  travelTime: number | undefined,
): IUndergroundTravelChangedAction {
  return {
    type: 'filters/underground_travel/UNDERGROUND_TRAVEL_CHANGED',
    travelType,
    travelTime,
  };
}

export function changeUndergroundTravelWithSearch(
  travelType: EMetroTravelType,
  travelTime: number | undefined,
  withSearch?: boolean,
) {
  return (dispatch: Dispatch) => {
    /**
     * Не скрывать input ввода расстояния,
     * пока не сменился travelType на Any
     */
    if (travelType !== EMetroTravelType.Any && !travelTime) {
      return;
    }

    dispatch(changeUndergroundTravel(travelType, travelTime));

    if (withSearch) {
      dispatch(makeSearch());
    }
  };
}

export function undergroundTravelReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case 'filters/underground_travel/UNDERGROUND_TRAVEL_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery:
            action.travelType !== EMetroTravelType.Any && action.travelTime
              ? jsonQuery.setUndergroundTravel(action.travelType, action.travelTime)
              : jsonQuery.unsetUndergroundTravel(),
        },
      };
    default:
      return state;
  }
}
