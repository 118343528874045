import { ArticleHeading3, LinkButton } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './JournalHighlights.css';
import { JournalHighlightsItem } from './components/JournalHighlightsItem';
import { Carousel } from '../../../packages/Carousel';
import { IJournalHighlightsItem } from '../../types/journal';

export function JournalHighlightsSection({ children }: React.PropsWithChildren<{}>) {
  return (
    <section className={styles['section']} data-name="JournalHighlights">
      <div className={styles['section-heading']}>
        <ArticleHeading3 as="span">Полезное от Циан.Журнала</ArticleHeading3>
        <LinkButton size="XS" theme="fill_white_primary" href="https://www.cian.ru/magazine/" target="_blank">
          Циан Журнал
        </LinkButton>
      </div>

      {children}
    </section>
  );
}

interface IJournalHighlightsProps {
  items: IJournalHighlightsItem[];
  onLinkClick(e: React.SyntheticEvent<HTMLAnchorElement>): void;
}

export function JournalHighlightsSlider({ items, onLinkClick }: IJournalHighlightsProps) {
  return (
    <div className={styles['wrapper']}>
      <Carousel
        spaceBetween={16}
        slidesPerView={5}
        slidesPerGroup={5}
        allowTouchMove={false}
        className="journal-highlights-carousel"
        testId="JournalHighlightsCarousel"
      >
        {items.map(({ url, title, img, description }) => (
          <JournalHighlightsItem
            key={title}
            className={styles['card']}
            description={description}
            img={img}
            title={title}
            url={url}
            onClick={onLinkClick}
          />
        ))}
      </Carousel>
    </div>
  );
}
