import * as React from 'react';

import { IQuickLinksProps } from '../../QuickLinks';
import { QuickLinksBanner } from '../QuickLinksBanner';
import { QuickLinksSuburbanBlackFriday } from '../QuickLinksSuburbanBlackFriday';

export type TQuickLinksSuburbanTrapProps = Pick<IQuickLinksProps, 'countrysideBlackFridayLink' | 'banner'>;

export function QuickLinksSuburbanTrap({ countrysideBlackFridayLink, banner }: TQuickLinksSuburbanTrapProps) {
  if (countrysideBlackFridayLink) {
    return (
      <QuickLinksBanner hasLeftBorder>
        <QuickLinksSuburbanBlackFriday link={countrysideBlackFridayLink} />
      </QuickLinksBanner>
    );
  }

  if (banner) {
    return <QuickLinksBanner hasLeftBorder>{banner}</QuickLinksBanner>;
  }

  return null;
}
