import { getTermValue, getRangeValue } from './helpers';
import { NonEmptyArray, EBalconyType, TJsonQuery } from './types';

export function getBalconyTypes(jsonQuery: TJsonQuery): NonEmptyArray<EBalconyType> | null {
  const balconies = getRangeValue('balconies')(jsonQuery);
  const loggia = getTermValue('loggia')(jsonQuery);

  const balconyTypes: EBalconyType[] = [];

  if (balconies && balconies.gte && balconies.gte > 0) {
    balconyTypes.push(EBalconyType.Balcony);
  }

  if (loggia) {
    balconyTypes.push(EBalconyType.Loggia);
  }

  return balconyTypes.length > 0 ? (balconyTypes as NonEmptyArray<EBalconyType>) : null;
}
