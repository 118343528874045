import { MAIN_REGIONS_IDS, PETERSBURG_AND_REGION } from '../../packages/Filters/shared/constants/regions';
import { IAppState } from '../common/state';
import { getRegionId } from '../utils/geo';

export const selectSuburbanBlackFridayBannerUrl = (state: IAppState) => {
  const suburbanBannerUrls = state.config.get<Record<'spb' | 'msk', string>>('suburbanBlackFriday.geoUrls');

  const regionId = getRegionId(state.filters.currentLocation);

  if (!suburbanBannerUrls || !MAIN_REGIONS_IDS.includes(regionId)) {
    return;
  }

  return PETERSBURG_AND_REGION.includes(regionId) ? suburbanBannerUrls.spb : suburbanBannerUrls.msk;
};
