import * as R from 'ramda';

import { resetTerms as resetJsonQueryTerms } from './helpers';
import { resetForDay } from './resetForDay';
import { TJsonQuery, TJsonQueryOptionalKeys } from './types';

export function resetTerms(jsonQuery: TJsonQuery) {
  return (terms: TJsonQueryOptionalKeys[]): TJsonQuery => {
    let nextJsonQuery = R.clone(jsonQuery);

    if (terms.includes('for_day')) {
      nextJsonQuery = resetForDay(nextJsonQuery)();
    }

    const ignoredTerms = ['for_day'];
    const termsToReset = terms.filter(t => !ignoredTerms.includes(t));
    nextJsonQuery = resetJsonQueryTerms(termsToReset)(nextJsonQuery);

    return nextJsonQuery;
  };
}
