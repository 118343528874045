import { ActionAfterViewed } from '@cian/action-after-viewed-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  trackBuilderButtonClick,
  trackBuilderLogoClick,
  trackNewbuildingClick,
  trackOfferClick,
  trackPromoBuilderOffersShow,
  trackSlideChange,
} from './tracking';
import { Carousel as NewbuildingPromoBuilderOffersCarousel } from '../../../packages/Carousel/Carousel';
import { NewbuildingPromoBuilder } from '../../components/NewbuildingPromoBuilder';
import { NewbuildingPromoBuilderOffersLayout } from '../../components/NewbuildingPromoBuilderOffersLayout';
import { NewbuildingPromoOfferCard } from '../../components/NewbuildingPromoOfferCard';
import { NEWBUILDING_PROMO_BUILDER_OFFERS_CAROUSEL_OPTIONS } from '../../constants/newbuildingPromoBuilderOffers';
import { getJsonQuery } from '../../selectors/getJsonQuery';
import { selectNewbuildingPromoBuilderOffers } from '../../selectors/newbuildingPromoBuilderOffers';
import { selectIsSerpFetching } from '../../selectors/selectIsSerpFetching';
import { INewbuildingPromoBuilderOffers } from '../../types/newbuildingPromoBuilderOffers';

export function NewbuildingPromoBuilderOffersContainer() {
  const newbuildingPromoBuilderOffers = useSelector(
    selectNewbuildingPromoBuilderOffers,
  ) as INewbuildingPromoBuilderOffers;
  const jsonQuery = useSelector(getJsonQuery);
  const isFetching = useSelector(selectIsSerpFetching);

  const [shownSlides, setShownSlides] = React.useState<number[]>([]);

  const onSlideChangeHandle = React.useCallback(
    (activeSlideIndex: number) => {
      const newSlides = newbuildingPromoBuilderOffers.offers
        .map(offer => offer.id)
        .slice(activeSlideIndex, activeSlideIndex + NEWBUILDING_PROMO_BUILDER_OFFERS_CAROUSEL_OPTIONS.slidesPerView);

      const isPossibleSendEvent = !!newSlides.filter(newSlide => !shownSlides.includes(newSlide)).length;

      if (!isPossibleSendEvent) {
        return;
      }

      setShownSlides(oldSlides => [...new Set([...oldSlides, ...newSlides])]);

      trackSlideChange({
        activeSlideIndex,
        jsonQuery,
        realtyUserId: newbuildingPromoBuilderOffers.builder.realtyUserId,
        offers: newbuildingPromoBuilderOffers.offers,
      });
    },
    [newbuildingPromoBuilderOffers, shownSlides, jsonQuery],
  );

  const slides = React.useMemo(() => {
    return newbuildingPromoBuilderOffers.offers.map((offer, index) => (
      <NewbuildingPromoOfferCard
        key={offer.id}
        {...offer}
        onOfferClick={() =>
          trackOfferClick({
            jsonQuery,
            realtyUserId: newbuildingPromoBuilderOffers.builder.realtyUserId,
            position: index,
            offerId: offer.id,
          })
        }
        onNewbuilldingClick={() =>
          trackNewbuildingClick({
            jsonQuery,
            realtyUserId: newbuildingPromoBuilderOffers.builder.realtyUserId,
            position: index,
            newbuildingId: offer?.newbuilding?.id,
          })
        }
      />
    ));
  }, [newbuildingPromoBuilderOffers.offers, newbuildingPromoBuilderOffers.builder.realtyUserId, jsonQuery]);

  const onAfterInitHandle = React.useCallback(() => {
    trackPromoBuilderOffersShow({
      jsonQuery,
      realtyUserId: newbuildingPromoBuilderOffers.builder.realtyUserId,
      offers: newbuildingPromoBuilderOffers.offers,
    });

    const newSlides = newbuildingPromoBuilderOffers.offers
      .map(offer => offer.id)
      .slice(0, NEWBUILDING_PROMO_BUILDER_OFFERS_CAROUSEL_OPTIONS.slidesPerView);

    setShownSlides(newSlides);
  }, [jsonQuery, newbuildingPromoBuilderOffers]);

  const carousel = React.useMemo(() => {
    return (
      <NewbuildingPromoBuilderOffersLayout
        offersFewed={
          newbuildingPromoBuilderOffers.offers.length < NEWBUILDING_PROMO_BUILDER_OFFERS_CAROUSEL_OPTIONS.slidesPerView
        }
        builder={
          <NewbuildingPromoBuilder
            allOffersUrl={newbuildingPromoBuilderOffers.allOffersUrl}
            onLogoClick={() => trackBuilderLogoClick(jsonQuery, newbuildingPromoBuilderOffers.builder.realtyUserId)}
            onButtonClick={() => trackBuilderButtonClick(jsonQuery, newbuildingPromoBuilderOffers.builder.realtyUserId)}
            {...newbuildingPromoBuilderOffers.builder}
          />
        }
        carousel={
          <NewbuildingPromoBuilderOffersCarousel
            onSlideChange={swiper => onSlideChangeHandle(swiper.activeIndex)}
            {...NEWBUILDING_PROMO_BUILDER_OFFERS_CAROUSEL_OPTIONS}
          >
            {slides}
          </NewbuildingPromoBuilderOffersCarousel>
        }
      />
    );
  }, [jsonQuery, newbuildingPromoBuilderOffers, onSlideChangeHandle, slides]);

  if (isFetching) {
    return carousel;
  }

  return (
    <ActionAfterViewed percentVisible={0.5} callback={onAfterInitHandle}>
      {carousel}
    </ActionAfterViewed>
  );
}
