import { IJsonQueryStreet } from '../../../../../json_query';
import { ensureSingleTagExists } from '../../helpers';
import { ITagDefinition, TTag } from '../../index';

const IGeoStreetTagType = 'geo-street';

export interface IGeoStreetTag {
  type: 'geo-street';
  value: number;
  text: string;
}

const isGeoStreetTag = (tag: TTag): tag is IGeoStreetTag => tag.type === IGeoStreetTagType;

export const geoStreetTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery, tagsData) => {
    const values =
      jsonQuery.geo &&
      (jsonQuery.geo.value.filter(geo => geo.type === 'street') as IJsonQueryStreet[]).map(geo => geo.id);

    let nextTags = tags.filter(
      tag => tag.type !== IGeoStreetTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce((result, value) => {
        const tagData = tagsData.streets[value];

        return ensureSingleTagExists(
          result,
          (tag: TTag): tag is IGeoStreetTag => isGeoStreetTag(tag) && tag.value === value,
          () => ({
            type: IGeoStreetTagType,
            value,
            text: tagData || 'Неизвестная улица',
          }),
        );
      }, nextTags);
    }

    return nextTags;
  },
};
