import { TAgentType } from '../types/offer-card';

export const AGENT_TYPE_TITLE: Record<string, string | undefined> = {
  managementCompany: 'Ук・оф.Представитель',
  realtor: 'Риелтор',
  agent: 'Агент',
  agency: 'Агентство недвижимости',
  homeowner: 'Собственник',
  builder: 'Застройщик',
  builderRepresentative: 'Представитель застройщика',
  consultant: 'Консультант',
  bank: 'Банк',
  rentDepartment: 'Отдел аренды',

  // TODO: уточнить эти 2 типа публикантов
  government: 'Федеральное агентство',
  tradingPlatform: 'Электронная торговая площадка',
};

export const getAgentTypeTitle = (agentType: TAgentType): string => {
  return AGENT_TYPE_TITLE[agentType] || '';
};
