import React from 'react';

import { getNewbuildingBrokerEnabled } from '../../../../../../../shared/utils/newbuildingBroker';
import { ContactActionsLayout } from '../../../../components/ContactActionsLayout';
import { useOfferContext } from '../../../../context';
import { getIsDailyRent } from '../../../../utils/computedProps/price/rent/getIsDailyRent';
import { AgentPhoneButtonContainer } from '../AgentPhoneButtonContainer';
import { AgentShowMoreOffersContainer } from '../AgentShowMoreOffersContainer';
import { ChatButtonContainer } from '../ChatButtonContainer';
import { DailyrentActionButtonsContainer } from '../DailyrentActionButtonsContainer';
import { JoinElectronicTradingButtonContainer } from '../JoinElectronicTradingButtonContainer';
import { NewbuildingBrokerBlockContainer } from '../NewbuildingBrokerBlockContainer';

export const ContactActionsContainer = () => {
  const offerData = useOfferContext();
  const {
    offer: { category },
  } = offerData;
  const isNewbuildingBrokerEnabled = getNewbuildingBrokerEnabled(offerData.offer);

  const isDailyrent = getIsDailyRent(category);

  if (isDailyrent) {
    return <DailyrentActionButtonsContainer />;
  }

  return (
    <ContactActionsLayout
      showMoreOffersLink={<AgentShowMoreOffersContainer />}
      chatButton={<ChatButtonContainer />}
      brokerBlock={isNewbuildingBrokerEnabled && <NewbuildingBrokerBlockContainer />}
      phoneButton={<AgentPhoneButtonContainer />}
      electronicTradingButton={<JoinElectronicTradingButtonContainer />}
    />
  );
};
