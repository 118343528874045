import * as React from 'react';

import { ELandStatusType, getTermsValue, NonEmptyArray } from '../../../../../JsonQuery';
import { LandStatus } from '../../../components/advancedFilters/LandStatus';
import { useContext } from '../../../utils/useContext';

export const LandStatusContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  let landStatus = getTermsValue('land_status')(jsonQuery);
  if (landStatus && landStatus.length === 0) {
    landStatus = null;
  }

  const handleChange = React.useCallback(
    (landStatus: NonEmptyArray<ELandStatusType> | null) => {
      onChange({ action: 'setLandStatusTypes', arguments: [landStatus] });
    },
    [onChange],
  );

  return <LandStatus value={landStatus} onChange={handleChange} />;
};
