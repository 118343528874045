import { ru as convertLayoutRu } from 'convert-layout';

export interface IMatches {
  /**
   * Массив из найденных вхождений в формате [начальный индекс, конечный].
   * Конечный индекс не включён во вхождение.
   *
   * @type {[number, number][]}
   * @memberOf IMatches
   */
  matches: [number, number][];
  /**
   * Точность совпадения. Число от 0 до 1. Чем выше число, тем больше совпадение.
   *
   * @type {number}
   * @memberOf IMatches
   */
  accuracy: number;
}

/**
 * Возвращает список вхождений строки для поиска в исходной строке и точность этих вхождений
 *
 * @export
 * @param {string} str Исходная строка
 * @param {string} searchStr Строка для поиска
 * @param {boolean} convertLayout Конвертировать английскую раскладку
 * @returns {IMatches} Результаты поиска
 */
export function match(str: string, searchStr: string, convertLayout: boolean = true): IMatches {
  const searchStrings = searchStr.match(/\S+/g) || [];
  const matchesRaw: number[] = new Array(str.length).fill(0);

  for (const searchString of searchStrings) {
    let currentPosition = 0;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const startIndex = indexOf(str, searchString, currentPosition);
      if (startIndex === -1) {
        break;
      }
      Array.prototype.splice.apply(
        matchesRaw,
        [startIndex, searchString.length].concat(new Array(searchString.length).fill(1)),
      );

      currentPosition = startIndex + searchString.length;
      if (currentPosition >= str.length - 1 || str.length - 1 - currentPosition < 0) {
        break;
      }
    }
  }

  let matches: [number, number][] = [];
  let currentPosition = 0;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const startIndex = matchesRaw.indexOf(1, currentPosition);
    if (startIndex === -1) {
      break;
    }

    let endIndex = matchesRaw.indexOf(0, startIndex);
    if (endIndex === -1) {
      endIndex = matchesRaw.length;
    }

    matches.push([startIndex, endIndex]);

    currentPosition = endIndex;
    if (currentPosition >= matchesRaw.length - 1) {
      break;
    }
  }

  let fullnessRank = (matchesRaw.filter(x => x === 1).length / searchStrings.join('').length) * 0.5;
  if (fullnessRank > 0.5) {
    fullnessRank = 0.5;
  }
  const matchingRank = (matchesRaw.filter(x => x === 1).length / (str.match(/\S+/g) || []).join('').length) * 0.5;

  let accuracy = fullnessRank + matchingRank;

  if (convertLayout) {
    const convertedMatch = match(str, convertLayoutRu.fromEn(searchStr), false);
    if (convertedMatch.accuracy > accuracy) {
      matches = convertedMatch.matches;
      accuracy += (convertedMatch.accuracy - accuracy) / 2;
    }
  }

  return {
    matches,
    accuracy,
  };
}

/**
 * Возвращает начальный индекс вхождения строки для поиска в исходной строке
 *
 * @export
 * @param {string} str Исходная строка
 * @param {string} searchStr Строка для поиска
 * @returns {string} Начальный индекс вхождения
 */
export function indexOf(str: string, searchStr: string, position?: number | undefined) {
  return normalize(str).indexOf(normalize(searchStr), position);
}

/**
 * Нормализация строки для поиска вхождений
 *
 * @export
 * @param {string} str Строка для нормализации
 * @returns {string} Нормализованная строка
 */
export function normalize(str: string): string {
  return (typeof str.normalize === 'function' ? str.normalize() : str)
    .toLowerCase()
    .replace(/ё/g, 'е')
    .replace(/й/g, 'и');
}
