import { IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';
import { ESuburbanOfferFilter } from '../../repositories/common/json_query';
import { FOfferType } from '../../utils/category';

export enum ESuburbanOfferFilterActions {
  Changed = 'filters/suburban_offer_filter/SET_SUBURBAN_OFFER_FILTER',
  Removed = 'filters/suburban_offer_filter/UNSET_SUBURBAN_OFFER_FILTER',
}

export interface ISuburbanOfferFilterChangedAction {
  type: ESuburbanOfferFilterActions.Changed;
  suburbanOfferFilter: ESuburbanOfferFilter;
}

export interface ISuburbanOfferFilterRemovedAction {
  type: ESuburbanOfferFilterActions.Removed;
}

export function setSuburbanOfferFilter(suburbanOfferFilter: ESuburbanOfferFilter): ISuburbanOfferFilterChangedAction {
  return {
    type: ESuburbanOfferFilterActions.Changed,
    suburbanOfferFilter,
  };
}

export function unsetSuburbanOfferFilter(): ISuburbanOfferFilterRemovedAction {
  return {
    type: ESuburbanOfferFilterActions.Removed,
  };
}

export function suburbanOfferFilterReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case ESuburbanOfferFilterActions.Changed:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setSuburbanOfferFilter(action.suburbanOfferFilter),
        },
      };

    case ESuburbanOfferFilterActions.Removed:
    case 'filters/deal_type/DEAL_TYPE_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.unsetSuburbanOfferFilter(),
        },
      };

    case 'filters/offer_type/OFFER_TYPE_SELECTED':
      if ([FOfferType.House, FOfferType.Townhouse, FOfferType.Land, FOfferType.HousePart].includes(action.offerType)) {
        return state;
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.unsetSuburbanOfferFilter(),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.unsetSuburbanOfferFilter(),
        },
      };

    default:
      return state;
  }
}
