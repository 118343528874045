import { IAppState } from '../../../common/state';
import { EMetroTravelType } from '../../../repositories/common/json_query';
import { selectLocationIdForMetroMap } from '../../../selectors/selectLocationIdForMetroMap';
import { isSuburban, offerTypeFromJsonQuery } from '../../../utils/category';

const availableMetroScheme: number[] = require('@cian/metro/ready');

export function hasMetroSelector(state: IAppState): boolean {
  const locationId = selectLocationIdForMetroMap(state);

  return availableMetroScheme.some(id => id === locationId);
  // TODO вернуть старую логику с проверкой региона для метро в задаче CD-181986, когда будет фикс на бекенде AUD-2226
  // currentLocation === 'spb_lo' || (isAvailableRegion(REGIONS_WITH_UNDERGROUND)(jsonQuery) &&
  //   availableMetroScheme.some(id => id === currentLocation.id))
}

export function hasDistrictsSelector(state: IAppState): boolean {
  const { currentLocation } = state.filters;

  if (currentLocation === 'moscow_mo') {
    return false;
  }

  return currentLocation === 'spb_lo' || currentLocation.hasDistricts;
}

export function hasHighwaysSelector(state: IAppState): boolean {
  const { currentLocation, jsonQuery } = state.filters;

  if (currentLocation === 'spb_lo') {
    return false;
  }

  return (
    (currentLocation === 'moscow_mo' || currentLocation.hasHighway) && isSuburban(offerTypeFromJsonQuery(jsonQuery))
  );
}

export function hasRegionalDistrictsSelector(state: IAppState): boolean {
  const { currentLocation, jsonQuery } = state.filters;
  const offerType = offerTypeFromJsonQuery(jsonQuery);
  const isSuburbanSearch = isSuburban(offerType);

  return (
    isSuburbanSearch &&
    (currentLocation === 'moscow_mo' || currentLocation === 'spb_lo' || currentLocation.hasRegionalDistricts)
  );
}

export function areTagsAvailableSelector(state: IAppState): boolean {
  const {
    filters: { tags, jsonQuery },
  } = state;

  return (
    tags.length > 0 ||
    (jsonQuery.only_foot && jsonQuery.only_foot.value !== EMetroTravelType.Any) ||
    !!(jsonQuery.foot_min && jsonQuery.foot_min.value.lte)
  );
}

export function isSearchResultsTooltipHidden(state: IAppState): boolean {
  return (
    state.isFetching ||
    state.isMapModalVisible ||
    state.isMetroModalVisible ||
    state.filters.jsonQueryCount == null ||
    state.filters.directionsModal.isVisible ||
    state.filters.locationSwitcher.isLocationSwitcherOpened
  );
}
