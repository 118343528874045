import { Input } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';
import { CALL_FROM } from '../../constants/agent';
import { formatTime } from '../../utils/formatters';
import { onKeyDown } from '../../utils/keyboard';
import { InputError } from '../InputError';

interface ITimeInputProps {
  value: string;
  error: string;
  changeValue(value: string): void;
}

export function TimeInput({ value, error, changeValue }: ITimeInputProps) {
  const handleChangeValue = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (value.length > 5) {
      return;
    }

    const newValue = formatTime(value);

    changeValue(newValue);
  };

  return (
    <div className={styles['time']}>
      <Input
        type="text"
        size="XS"
        value={value}
        placeholder={CALL_FROM}
        invalid={!!error}
        onKeyDown={onKeyDown}
        onChange={handleChangeValue}
      />
      <InputError error={error} />
    </div>
  );
}
