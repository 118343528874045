import * as React from 'react';

import { FDealType } from '../../../../../JsonQuery';
import { Select, Option } from '../../Select';

interface IDealType {
  label: string;
  value: FDealType;
}

const DEAL_TYPES: IDealType[] = [
  { label: 'Купить', value: FDealType.Sale },
  { label: 'Снять', value: FDealType.RentLongterm },
  { label: 'Посуточно', value: FDealType.RentDaily },
];

interface IDealTypeProps {
  open?: boolean;
  value: FDealType;
  hideTooltipCount?: boolean;
  offersCount: number | undefined;
  offersCountLoading?: boolean;
  openTooltip?: boolean;
  onChange(value: FDealType): void;
  onApply?(): void;
  onOpen?(): void;
  onClose?(): void;
  popupPostfix: React.ReactNode;
}

export const DealType: React.FC<IDealTypeProps> = ({
  open,
  value,
  hideTooltipCount,
  offersCount,
  offersCountLoading,
  popupPostfix,
  openTooltip,
  onChange,
  onApply,
  onOpen,
  onClose,
}) => {
  return (
    <Select
      open={open}
      withoutArrow
      placeholder="Тип сделки"
      value={value}
      hideTooltipCount={hideTooltipCount}
      offersCount={offersCount}
      offersCountLoading={offersCountLoading}
      openTooltip={openTooltip}
      onChange={(_, value: FDealType) => onChange(value)}
      onApply={onApply}
      onOpen={onOpen}
      onClose={onClose}
      popupPostfix={popupPostfix}
    >
      {DEAL_TYPES.map(dealType => (
        <Option key={`${dealType.label}_${dealType.value}`} value={dealType.value}>
          {dealType.label}
        </Option>
      ))}
    </Select>
  );
};
