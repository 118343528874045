import * as React from 'react';

import { IGeoHighwayTag } from '../../../../state/tags/definitions/geo';
import { TagNew } from '../../TagNew';

export interface IGeoHighwayTagItemProps {
  tag: IGeoHighwayTag;
  onDeleteClick(): void;
}

export const GeoHighwayTagItem = (props: IGeoHighwayTagItemProps) => {
  return <TagNew onDeleteClick={props.onDeleteClick}>{props.tag.text}</TagNew>;
};
