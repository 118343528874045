import { call, put, select, takeLatest } from 'redux-saga/effects';

import { IGeoDistrict, requestDistrictsTree } from '../../../api/geo';
import { IAppState, TActions } from '../../../common/state';
import { IJsonQueryDistrict, IJsonQueryGeo } from '../../../json_query';

export const DistrictClosedActionType = 'filters/districts/DISTRICT_MODAL_CLOSED';
export const DistrictModalOpenRequestedActionType = 'filters/districts/DISTRICT_MODAL_OPENED_REGUESTED';
export const DistrictModalReguestCompletedAction = 'filters/districts/DISTRICT_MODAL_REQUEST_COMPLETED';
export const DistrictModalSelectedAction = 'filters/districts/DISTRICT_SELECTED';
export const DistrictModalRemovedAction = 'filters/districts/DISTRICT_REMOVED';
export const DistrictModalNewMoscowSwitcheddAction = 'filters/districts/DISTRICT_NEW_MOSCOW_SWITCHED';

export interface IDistrictsModalCloseAction {
  type: 'filters/districts/DISTRICT_MODAL_CLOSED';
}

export interface IDistrictModalOpenRequestedAction {
  type: 'filters/districts/DISTRICT_MODAL_OPENED_REGUESTED';
}

export interface IDistrictSelectedAction {
  type: 'filters/districts/DISTRICT_SELECTED';
  id: number;
  name: string;
}

export interface IDistrictRemovedAction {
  type: 'filters/districts/DISTRICT_REMOVED';
  id: number;
}

export interface IDistrictModalRequestCompletedAction {
  type: 'filters/districts/DISTRICT_MODAL_REQUEST_COMPLETED';
  districts: IGeoDistrict[];
}

export interface IDistrictModalNewMoscowSwitcheddAction {
  type: 'filters/districts/DISTRICT_NEW_MOSCOW_SWITCHED';
  includeNewMoscow: boolean | undefined;
}

export function closeModal(): IDistrictsModalCloseAction {
  return {
    type: DistrictClosedActionType,
  };
}

export function selectDistrict(id: number, name: string): IDistrictSelectedAction {
  return {
    type: DistrictModalSelectedAction,
    id,
    name,
  };
}

export function removeDistrict(id: number): IDistrictRemovedAction {
  return {
    type: DistrictModalRemovedAction,
    id,
  };
}

export function switchNewMoscowDistrict(includeNewMoscow: boolean | undefined) {
  return {
    type: DistrictModalNewMoscowSwitcheddAction,
    includeNewMoscow,
  };
}

export function openDistrictSwitcher(): IDistrictModalOpenRequestedAction {
  return {
    type: DistrictModalOpenRequestedActionType,
  };
}

export function* districtSwitcherLoad() {
  const state: IAppState = yield select();

  const currentLocation = state.filters.currentLocation;
  let districts: IGeoDistrict[] = [];

  if (currentLocation !== 'moscow_mo' && currentLocation !== 'spb_lo') {
    const res: IGeoDistrict[] = yield call(requestDistrictsTree, state.makeRequest, currentLocation.id);
    districts = res;
  } else {
    const id = currentLocation === 'moscow_mo' ? 1 : 2;
    const res: IGeoDistrict[] = yield call(requestDistrictsTree, state.makeRequest, id);
    districts = res;
  }

  yield put({
    type: 'filters/districts/DISTRICT_MODAL_REQUEST_COMPLETED',
    districts,
  } as IDistrictModalRequestCompletedAction);
}

export function* districtSwitcherOpenSaga() {
  yield takeLatest(DistrictModalOpenRequestedActionType, districtSwitcherLoad);
}

function removeDistrictFromJsonQuery(geo: IJsonQueryGeo | undefined, id: number): IJsonQueryGeo | undefined {
  if (geo) {
    const value = geo.value.filter(item => {
      return item.type === 'district' && item.id === id ? false : true;
    });

    return value.length === 0
      ? undefined
      : {
          type: 'geo',
          value,
        };
  }

  return geo;
}

function addDistrictToJsonQuery(geo: IJsonQueryGeo | undefined, id: number): IJsonQueryGeo | undefined {
  let result;

  if (geo) {
    result = geo.value.concat();
    result.push({ id, type: 'district' });
  }

  return {
    type: 'geo',
    value: result ? result : [{ id, type: 'district' }],
  };
}

function getDistrictsFromJsonQuery(geo: IJsonQueryGeo | undefined): IJsonQueryDistrict[] {
  if (geo) {
    return geo.value.filter(value => {
      return value.type === 'district';
    }) as IJsonQueryDistrict[];
  }

  return [];
}

export function toggleDistrictsModalReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case DistrictModalOpenRequestedActionType:
      return {
        ...state,
        isDistrictModalVisible: true,
        filters: {
          ...state.filters,
          backup: {
            jsonQuery: state.filters.jsonQuery,
            tags: state.filters.tags,
          },
          districtsSwitcher: {
            ...state.filters.districtsSwitcher,
            districts: undefined,
            isDataLoading: true,
            selectedDistricts: getDistrictsFromJsonQuery(state.filters.jsonQuery.geo),
          },
        },
      };

    case DistrictClosedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...state.filters.backup,
          backup: undefined,
        },
        isDistrictModalVisible: false,
      };

    case DistrictModalReguestCompletedAction:
      return {
        ...state,
        isDistrictModalVisible: true,
        filters: {
          ...state.filters,
          districtsSwitcher: {
            ...state.filters.districtsSwitcher,
            isDataLoading: false,
            districts: action.districts,
          },
        },
      };

    case DistrictModalSelectedAction: {
      const { tagsData } = state.filters;

      const district: IJsonQueryDistrict = { id: action.id, type: 'district' };

      const dist = state.filters.districtsSwitcher.selectedDistricts.concat(district);

      if (!tagsData.districts[action.id]) {
        const newDistricts = Object.assign({}, tagsData.districts);
        newDistricts[action.id] = action.name;
        tagsData.districts = newDistricts;
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          tagsData,
          jsonQuery: {
            ...state.filters.jsonQuery,
            geo: addDistrictToJsonQuery(state.filters.jsonQuery.geo, action.id),
          },
          districtsSwitcher: {
            ...state.filters.districtsSwitcher,
            selectedDistricts: dist,
          },
        },
      };
    }

    case DistrictModalRemovedAction: {
      const dists = state.filters.districtsSwitcher.selectedDistricts.filter(value => {
        return value.id !== action.id;
      });

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            geo: removeDistrictFromJsonQuery(state.filters.jsonQuery.geo, action.id),
          },
          districtsSwitcher: {
            ...state.filters.districtsSwitcher,
            selectedDistricts: dists,
          },
        },
      };
    }

    case DistrictModalNewMoscowSwitcheddAction:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            include_new_moscow:
              action.includeNewMoscow !== undefined
                ? {
                    type: 'term',
                    value: action.includeNewMoscow,
                  }
                : undefined,
          },
        },
      };

    default:
      return state;
  }
}
