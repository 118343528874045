import { EWCCount, EWCType } from '../../../../json_query';
import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const IWCCountTagType = 'advanced-wc-wcCount';

export interface IWCCountTag {
  type: 'advanced-wc-wcCount';
  value: number;
}

export const isWCCountTag = (tag: TTag): tag is IWCCountTag => tag.type === IWCCountTagType;

const shouldShowWCCountTag = (wcType: EWCType, wcCount: EWCCount) =>
  (wcCount === EWCCount.One && wcType === EWCType.Any) || (wcCount !== EWCCount.Any && wcCount !== EWCCount.One);

export const wcCountTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const { wc_type } = jsonQuery;
    const { wc } = jsonQuery;

    const wcType = (wc_type && wc_type.value) || EWCType.Any;
    const wcCount = (wc && (wc.value.gte as EWCCount)) || EWCCount.Any;

    if (shouldShowWCCountTag(wcType, wcCount)) {
      return ensureSingleTagExists(tags, isWCCountTag, () => ({
        type: IWCCountTagType,
        value: wcCount || EWCCount.One,
      }));
    } else {
      return ensureTagNotExists(tags, isWCCountTag);
    }
  },
};
