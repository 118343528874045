export interface IHighwaySchema {
  /** Растояние строкой **/
  distance: string;
  /** Название **/
  name: string;
}

export interface IRailwaySchema {
  /** Растояние строкой **/
  distance: string;
  /** Название **/
  name: string;
}

export enum ETransportType {
  /** Транспортом **/
  Transport = 'transport',
  /** Пешком **/
  Walk = 'walk',
}

export interface IUndergroundSchema {
  /** Цвет линии **/
  lineColor: string;
  /** Название **/
  name: string;
  /** Время в пути до метро в минутах **/
  time: number;
  /** Способ передвижения **/
  transportType: ETransportType;
  /** Текстовый код метро (нужно для отображения иконки метро) **/
  undergroundType?: string | null;
}

export interface IAnalyticsSchema {
  /** Информация о пользователе, разместившим объявление **/
  brand: string;
  /** Категория объявления строкой **/
  category: string;
  /** Id объявления **/
  id: number;
  /** Цена объявления **/
  price: number;
  /** Количество объектов **/
  quantity: number;
  /** Информация размещении - top3, premium, paid. Для данного блока всегда None **/
  variant?: string | null;
}

export interface INewbuildingSimilarBlockOfferForDesktopSchema {
  /** Адресс **/
  address: string;
  /** Этаж строкой **/
  floor: string;
  /** Данные для аналитики **/
  analytics: IAnalyticsSchema;
  /** Ближайшее шоссе **/
  highway?: IHighwaySchema | null;
  /** Id объявления **/
  id: number;
  /** Ссылка на изображение планировки **/
  imageUrl: string;
  /** Id ЖК **/
  newbuildingId: number;
  /** Название ЖК **/
  newbuildingName: string;
  /** Ссылка на карточку объявления **/
  offerUrl: string;
  /** Цена объявления **/
  price: string;
  /** Ближайшая станция ж.д. **/
  railway?: IRailwaySchema | null;
  /** Комнатность **/
  rootCount: string;
  /** Площадь **/
  totalArea: string;
  /** Ближайшая станция метро **/
  /**
   "spb" -> R.drawable.ic_underground_spb
   "smr" -> R.drawable.ic_underground_samara
   "ekb" -> R.drawable.ic_underground_ekb
   "kzn" -> R.drawable.ic_underground_kazan
   "nn" -> R.drawable.ic_underground_nizhniy
   "nsk" -> R.drawable.ic_underground_novosib
   else -> R.drawable.ic_underground_msk
   * **/
  underground?: IUndergroundSchema | null;
}
