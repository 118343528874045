import { Spinner } from '@cian/ui-kit/loader';
import * as React from 'react';

import * as styles from './LoadableFallback.css';

interface ILoadableFallbackProps {
  spinnerSize?: number;
}

export const LoadableFallback: React.VFC<ILoadableFallbackProps> = ({ spinnerSize }) => {
  return (
    <div className={styles['container']}>
      <Spinner size={spinnerSize || 24} />
    </div>
  );
};
