/* eslint-disable react/no-danger */
import { IconActionChevronUp16 } from '@cian/ui-kit-design-tokens/icons';
import classNames from 'classnames';
import * as React from 'react';

import * as styles from './SeoMarkedListItem.css';
import { IMarkedListItem } from '../../../../offer';

const SPACE_KEY_CODE = 32;

export interface ISeoMarkedListItemProps extends IMarkedListItem {
  id: string;
}

export const SeoMarkedListItem: React.VFC<ISeoMarkedListItemProps> = ({
  id,
  title,
  content,
  expanded: initialExpanded,
}) => {
  const [expanded, setExpanded] = React.useState<boolean>(initialExpanded);
  const headingId = `${id}_heading`;
  const bodyId = `${id}_body`;

  const toggleExpandedState = () => {
    setExpanded(!expanded);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === SPACE_KEY_CODE) {
      event.preventDefault();

      toggleExpandedState();
    }
  };

  return (
    <div className={classNames(styles['container'], !expanded && styles['closed'])}>
      {/**
        Почему здесь не стоит использовать button описано тут
        https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/button_role
      */}
      <div
        id={headingId}
        role="button"
        aria-expanded={expanded}
        aria-controls={bodyId}
        tabIndex={0}
        className={styles['title-block']}
        onClick={toggleExpandedState}
        onKeyDown={handleKeyDown}
      >
        <div className={styles['title']} dangerouslySetInnerHTML={{ __html: title }} />
        <div className={styles['arrow-wrapper']}>
          <IconActionChevronUp16 />
        </div>
      </div>
      <div
        id={bodyId}
        aria-describedby={headingId}
        className={styles['content']}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};
