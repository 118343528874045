import { ca } from '@cian/analytics';

interface IParameters {
  currentPage: number;
  nextPage: number;
}

export function trackPaginationClick({ currentPage, nextPage }: IParameters) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'pagination',
    action: 'click_sopr',
    label: nextPage,
    page: {
      pageType: 'Listing',
      listingType: 'list',
      pageNumber: currentPage,
    },
  });
}
