import { TComputedPropMapper } from '../../types/computed-props-mapper';

export const agentPhoneWarningMessage: TComputedPropMapper<string | undefined> = ({
  offer: { showWarningMessage },
}) => {
  return showWarningMessage
    ? `Не вносите предоплату
до просмотра объекта
и заключения договора!`
    : undefined;
};
