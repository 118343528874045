import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  IHideOfferRequestScheme,
  THideOfferResponseScheme,
} from '../../../packages/api-models/hidden-objects/v1/hide-offer-website';
import { EOfferType, EDealType } from '../../../packages/api-models/hidden-objects/v1/types';
import { postHideOffer } from '../../api/hidden_objects';
import { IAppState, TActions } from '../../common/state';
import { IOffer } from '../../offer/types';
import { trackHiddenObjects } from '../../tracking/hide_offer';

const ANIMATION_TIME = 700;
const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export enum EHideOfferState {
  INIT = 'INIT',
  FETCHING = 'FETCHING',
  ANIMATING = 'ANIMATING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface IHideOffer {
  hiddenOffers: number[];
  hiddenSuggestions: number[];
  hidingOffer: IHidingOfferInfo | null;
  hideOfferState: EHideOfferState;
  hideOfferErrorMessage: string;
  isTopPopupOpened: boolean;
}

export interface IHidingOfferInfo {
  isSimilar: boolean;
  offer: IOffer;
}
export interface IHideOfferTopPopupToggleAction {
  isTopPopupOpened: boolean;
  type: 'serp/hide_offer/TOP_POPUP_TOGGLE';
}

export interface IHideOfferRequested {
  type: 'serp/hide_offer/HIDE_OFFER_REQUESTED';
  hidingOffer: IHidingOfferInfo;
}

export interface IHideOfferReceived {
  type: 'serp/hide_offer/HIDE_OFFER_RECEIVED';
  hidingOffer: IHidingOfferInfo;
  hideOfferState: EHideOfferState;
  hideOfferErrorMessage?: string;
}

export interface ISetHideOfferState {
  type: 'serp/hide_offer/SET_HIDE_OFFER_STATE';
  hideOfferState: EHideOfferState;
}

const errorMessages = {
  limitExceeded: 'Вы можете скрыть не более 1000 объявлений.',
  default: 'Ошибка при попытке скрыть объявление',
};

export function toggleTopPopup(isTopPopupOpened: boolean): IHideOfferTopPopupToggleAction {
  return {
    isTopPopupOpened,
    type: 'serp/hide_offer/TOP_POPUP_TOGGLE',
  };
}

export function hideOfferAction(hidingOffer: IHidingOfferInfo): IHideOfferRequested {
  return {
    hidingOffer,
    type: 'serp/hide_offer/HIDE_OFFER_REQUESTED',
  };
}

export function setHideOfferState(hideOfferState: EHideOfferState): ISetHideOfferState {
  return {
    hideOfferState,
    type: 'serp/hide_offer/SET_HIDE_OFFER_STATE',
  };
}

export function selectOfferRequestParams(offer: IOffer): IHideOfferRequestScheme {
  let houseId;
  if (offer.geo) {
    const house = offer.geo.address.find(a => a.type === 'house');
    houseId = house && house.id;
  }

  return {
    offerId: offer.id,
    dealType: offer.dealType === 'daily' ? EDealType.Rent : (offer.dealType as EDealType),
    offerType: offer.offerType as EOfferType,
    publishedUserId: offer.publishedUserId || 0,
    houseId,
  };
}

export function* hideOffer(action: IHideOfferRequested) {
  const state: IAppState = yield select();
  const { hidingOffer } = action;
  const {
    user: { isAuthenticated },
    httpApi,
    logger,
    filters: { jsonQuery },
  } = state;
  const request = selectOfferRequestParams(hidingOffer.offer);

  if (!isAuthenticated && window.waitForLoginPopup) {
    const callback = (): Promise<void> => {
      return postHideOffer({ httpApi, logger }, request)
        .then(result => {
          const status = result.statusCode === 200 ? 'SUCCESS' : 'ERROR';
          window.location.hash = window.location.hash + `HIDE_${status}`;
          if (status === 'SUCCESS') {
            trackHiddenObjects({ offer: hidingOffer.offer, jsonQuery });
          }

          window.location.reload();
        })
        .catch(() => {
          window.location.hash = window.location.hash + `HIDE_ERROR`;
        });
    };

    window.waitForLoginPopup('hide', callback);

    return;
  }

  yield put({
    type: 'serp/hide_offer/SET_HIDE_OFFER_STATE',
    hideOfferState: EHideOfferState.FETCHING,
  });

  try {
    const requestHideOffer: THideOfferResponseScheme = yield call(postHideOffer, { httpApi, logger }, request);
    if (requestHideOffer.statusCode === 200) {
      yield put({
        type: 'serp/hide_offer/SET_HIDE_OFFER_STATE',
        hideOfferState: EHideOfferState.ANIMATING,
      });
      yield delay(ANIMATION_TIME);
      trackHiddenObjects({ offer: hidingOffer.offer, jsonQuery });
      yield put({
        type: 'serp/hide_offer/HIDE_OFFER_RECEIVED',
        hideOfferState: EHideOfferState.SUCCESS,
        hidingOffer,
      });
    } else {
      if (requestHideOffer.statusCode === 400) {
        const { errors } = requestHideOffer.response;

        const messageError =
          errors && errors.length > 0 && errors[0].code === 'limitExceeded'
            ? errorMessages[errors[0].code as keyof typeof errorMessages]
            : errorMessages.default;

        yield put({
          type: 'serp/hide_offer/HIDE_OFFER_RECEIVED',
          hideOfferState: EHideOfferState.ERROR,
          hideOfferErrorMessage: messageError,
        });

        return;
      }

      yield put({
        type: 'serp/hide_offer/HIDE_OFFER_RECEIVED',
        hideOfferState: EHideOfferState.ERROR,
        hideOfferErrorMessage: errorMessages.default,
      });
    }
  } catch (ex) {
    yield put({
      type: 'serp/hide_offer/HIDE_OFFER_RECEIVED',
      hideOfferState: EHideOfferState.ERROR,
      hideOfferErrorMessage: errorMessages.default,
    });
  }
}

export function* hideOfferSaga() {
  yield takeLatest('serp/hide_offer/HIDE_OFFER_REQUESTED', hideOffer);
}

export const defaultHideOfferState = {
  hiddenOffers: [],
  hiddenSuggestions: [],
  hidingOffer: null,
  hideOfferState: EHideOfferState.INIT,
  hideOfferErrorMessage: '',
  isTopPopupOpened: false,
};

export function hideOfferReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'serp/hide_offer/TOP_POPUP_TOGGLE':
      return {
        ...state,
        hideOffer: {
          ...state.hideOffer,
          isTopPopupOpened: action.isTopPopupOpened,
        },
      };

    case 'serp/hide_offer/SET_HIDE_OFFER_STATE': {
      let isTopPopupOpened = state.hideOffer.isTopPopupOpened;
      let hidingOffer = state.hideOffer.hidingOffer;
      if (action.hideOfferState === EHideOfferState.SUCCESS || action.hideOfferState === EHideOfferState.ERROR) {
        isTopPopupOpened = true;
        hidingOffer = null;
      }

      return {
        ...state,
        hideOffer: {
          ...state.hideOffer,
          hideOfferState: action.hideOfferState,
          isTopPopupOpened,
          hidingOffer,
          hideOfferErrorMessage: action.hideOfferState === EHideOfferState.ERROR ? errorMessages.default : '',
        },
      };
    }

    case 'serp/hide_offer/HIDE_OFFER_REQUESTED':
      return {
        ...state,
        hideOffer: {
          ...state.hideOffer,
          isTopPopupOpened: false,
          hidingOffer: action.hidingOffer,
        },
      };

    case 'serp/hide_offer/HIDE_OFFER_RECEIVED': {
      let { hiddenOffers, hiddenSuggestions } = state.hideOffer;

      if (action.hideOfferState === EHideOfferState.SUCCESS) {
        if (action.hidingOffer.isSimilar) {
          hiddenSuggestions = [...state.hideOffer.hiddenSuggestions, action.hidingOffer.offer.id];
        } else {
          hiddenOffers = [...state.hideOffer.hiddenOffers, action.hidingOffer.offer.id];
        }
      }

      return {
        ...state,
        hideOffer: {
          ...state.hideOffer,
          hideOfferState: action.hideOfferState,
          hiddenOffers,
          hiddenSuggestions,
          isTopPopupOpened: true,
          hidingOffer: null,
          hideOfferErrorMessage:
            action.hideOfferState === EHideOfferState.ERROR && action.hideOfferErrorMessage
              ? action.hideOfferErrorMessage
              : '',
        },
      };
    }

    default:
      return state;
  }
}
