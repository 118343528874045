import { ca } from '@cian/analytics';

import { NEWBUILDING_PROMO_BUILDER_OFFERS_CAROUSEL_OPTIONS } from '../../constants/newbuildingPromoBuilderOffers';
import { IJsonQuery } from '../../repositories/common/json_query';
import { INewbuildingPromoBuilderOffer } from '../../types/newbuildingPromoBuilderOffers';
import { getNewbuildingOffersPositions } from '../../utils/newbuildingPromoBuilderOffers';

export const trackBuilderLogoClick = (jsonQuery: IJsonQuery, realtyUserId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'click',
    label: 'specproject_carousel_to_card_builder',
    page: {
      extra: {
        is_specproject: true,
      },
    },
    sc: jsonQuery,
    products: [
      {
        id: realtyUserId,
        offerType: 'userid',
      },
    ],
  });
};

export const trackBuilderButtonClick = (jsonQuery: IJsonQuery, realtyUserId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'click',
    label: 'specproject_carousel_to_listing',
    page: {
      extra: {
        is_specproject: true,
      },
    },
    sc: jsonQuery,
    products: [
      {
        id: realtyUserId,
        offerType: 'userid',
      },
    ],
  });
};

export const trackNewbuildingClick = ({
  jsonQuery,
  realtyUserId,
  position,
  newbuildingId,
}: {
  jsonQuery: IJsonQuery;
  realtyUserId: number;
  position: number;
  newbuildingId?: number | null;
}) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'click',
    label: 'specproject_carousel_to_cardjk',
    page: {
      extra: {
        is_specproject: true,
        to_object: newbuildingId,
        object_type: 'JK',
        position: position + 1,
      },
    },
    sc: jsonQuery,
    products: [
      {
        id: realtyUserId,
        offerType: 'userid',
      },
    ],
  });
};

export const trackOfferClick = ({
  jsonQuery,
  realtyUserId,
  position,
  offerId,
}: {
  jsonQuery: IJsonQuery;
  realtyUserId: number;
  position: number;
  offerId: number;
}) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'click',
    label: 'specproject_carousel_to_card',
    page: {
      extra: {
        is_specproject: true,
        to_object: offerId,
        object_type: 'offer',
        position: position + 1,
      },
    },
    sc: jsonQuery,
    products: [
      {
        id: realtyUserId,
        offerType: 'userid',
      },
    ],
  });
};

export const trackPromoBuilderOffersShow = ({
  jsonQuery,
  realtyUserId,
  offers,
}: {
  jsonQuery: IJsonQuery;
  realtyUserId: number;

  offers: INewbuildingPromoBuilderOffer[];
}) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'show_sopr',
    label: 'specproject_carousel',
    page: {
      extra: {
        is_specproject: true,
        content: getNewbuildingOffersPositions({
          step: NEWBUILDING_PROMO_BUILDER_OFFERS_CAROUSEL_OPTIONS.slidesPerView,
          activeSlideIndex: 0,
          offers,
        }),
      },
    },
    sc: jsonQuery,
    products: [
      {
        id: realtyUserId,
        offerType: 'userid',
      },
    ],
  });
};

export const trackSlideChange = ({
  jsonQuery,
  realtyUserId,
  offers,
  activeSlideIndex,
}: {
  jsonQuery: IJsonQuery;
  realtyUserId: number;
  activeSlideIndex: number;
  offers: INewbuildingPromoBuilderOffer[];
}) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'click',
    label: 'specproject_carousel_scroll',
    page: {
      extra: {
        is_specproject: true,
        content: getNewbuildingOffersPositions({
          step: NEWBUILDING_PROMO_BUILDER_OFFERS_CAROUSEL_OPTIONS.slidesPerView,
          activeSlideIndex,
          offers,
        }),
      },
    },
    sc: jsonQuery,
    products: [
      {
        id: realtyUserId,
        offerType: 'userid',
      },
    ],
  });
};
