import * as React from 'react';

import { IHiddenBaseTag } from '../../../state/tags/definitions/hidden_base';
import { TagNew } from '../TagNew';

export interface IHiddenBaseTagItemProps {
  tag: IHiddenBaseTag;
  onDeleteClick(): void;
}

export const HiddenBaseTagItem = (props: IHiddenBaseTagItemProps) => {
  return <TagNew onDeleteClick={props.onDeleteClick}>Только видимые агентам</TagNew>;
};
