import { EVas } from '../../../types/vas';

const VAS_NAMES: { [key in EVas]: string } = {
  [EVas.Auction]: 'Аукцион',
  [EVas.Standard]: 'Стандарт',
  [EVas.Premium]: 'Премиум',
  [EVas.Top]: 'Топ',
};

const ML_VAS_NAMES: { [key in EVas]: string } = {
  [EVas.Auction]: 'Аукцион',
  [EVas.Standard]: 'Стандарт',
  [EVas.Premium]: 'Премиум',
  [EVas.Top]: 'Топ',
};

/**
 * Возвращает имя VAS по заданной мнемонике (с учетом ранжированной выдачи)
 * например, premium => Премиум
 * @param vas мнемоника VAS
 */
export function getMLVasName(vas: EVas) {
  return ML_VAS_NAMES[vas];
}

/**
 * Возвращает имя VAS по заданной мнемонике (например, premium => Премиум)
 * @param vas мнемоника VAS
 * @deprecated повсеместно используется новый нейминг ML ранжированной выдачи
 */
export function getVasName(vas: EVas) {
  return VAS_NAMES[vas];
}

export function getName(vas: EVas, isMl: boolean) {
  return isMl ? getMLVasName(vas) : getVasName(vas);
}
