import { ca } from '@cian/analytics';

export function trackCianDobroBannerClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'Click',
    label: 'cian_dobro_banner',
  });
}

export function trackCianDobroBannerShow() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'show_sopr',
    label: 'cian_dobro_banner',
  });
}
