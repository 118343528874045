import { CheckboxButtonGroup, TControlOptions } from '@cian/ui-kit';
import { IUndergroundMapApi } from '@cian/underground-map-widget';
import { pathOr } from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectUndergroundStations } from '../../selectors/filters/selectUndergroundStations';
import { selectLocationIdForMetroMap } from '../../selectors/selectLocationIdForMetroMap';
import { IUndergroundShortcuts, IUndergroundShortcutsValue } from '../../types/undergroundStationData';
import { useApplicationContext } from '../../utils/applicationContext';
import { includesAll } from '../../utils/includesAll';

interface IUndergroundMapLineSelectorProps {
  api: React.RefObject<IUndergroundMapApi>;
}

export const UndergroundMapLineSelector: React.VFC<IUndergroundMapLineSelectorProps> = ({ api }) => {
  const { config } = useApplicationContext();
  const locationId = useSelector(selectLocationIdForMetroMap);
  const selectedStations = useSelector(selectUndergroundStations);

  const metroData = config.get<IUndergroundShortcuts>('undergroundMapShortcuts');

  /**
   * В value хранится массив с index-ами, по которым можно получить доступ к массиву с id-шниками метро.
   * Если index = 0, 1, ..., то на карте будут выбраны все станции, которые хранятся в этом массиве.
   * Таким образом реализован выбор множества станций сразу.
   *
   * У реализации CheckboxButtonGroup есть проблемы с интерпретацией цифры 0 в этом массиве.
   * Если value = [0, 1], то чекбокс который отвечает за выбор индекса 0 будет все равно в состоянии unchecked.
   * Чтобы эту проблему побороть я преобразовываю индексы в строки (value = ['0', '1']).
   */
  const { options, shortcuts } = React.useMemo(() => {
    const shortcuts = pathOr<IUndergroundShortcutsValue[]>([], [locationId], metroData);
    const options: TControlOptions = shortcuts.map((shortcut, index) => ({
      label: shortcut.text,
      value: String(index),
    }));

    return {
      shortcuts,
      options,
    };
  }, [metroData, locationId]);

  const value = React.useMemo(
    () =>
      shortcuts
        .map((shortcut, index) => (includesAll(selectedStations, shortcut.ids) ? String(index) : null))
        .filter((value): value is string => value !== null),
    [selectedStations, shortcuts],
  );

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, nextValue: string[]) => {
      for (let i = 0; i < shortcuts.length; i++) {
        const shortcut = shortcuts[i];
        const index = String(i);

        if (nextValue.includes(index)) {
          // Если устанавливаемое значение не содержалось ранее в массиве value - то нужно его выбрать
          if (!value.includes(index)) {
            api.current?.onSelect(shortcut.ids);
          }
        } else {
          // Если устанавливаемое значение уже было ранее в массиве value - то нужно его убрать
          // Если проверку не сделать, то будут удаляться станции метро, которые пользователь просто натыкал на карте
          if (value.includes(index)) {
            api.current?.onRemove(shortcut.ids);
          }
        }
      }
    },
    [api, shortcuts, value],
  );

  if (!options || options.length === 0) {
    return null;
  }

  return <CheckboxButtonGroup value={value} options={options} onChange={handleChange} />;
};
