import * as React from 'react';

import { checkIsHotelsSearch } from '../../../../../../shared/utils/checkIsHotelsSearch';
import { FDealType } from '../../../../../JsonQuery';
import { PostfixPopupLink } from '../../../components/Select';
import { DealType } from '../../../components/filters/DealType';
import { IFilterComponentProps } from '../../../types/filters';
import { TAvailableModifierActions } from '../../../types/modifier';
import { useSearchResultsTooltipOpen } from '../../../utils/searchResultsTooltipOpen';
import { useContext } from '../../../utils/useContext';
import { useDealType } from '../../../utils/useDealType';

export const DealTypeContainer: React.FC<IFilterComponentProps> = ({ filterKey, open, onOpen, onClose }) => {
  const { onChange, jsonQuery, jsonQueryCount, jsonQueryCountRefreshing, onApply, onApplyClick } = useContext();
  const isHotelSearch = checkIsHotelsSearch(jsonQuery);
  const dealType = useDealType();
  const action: TAvailableModifierActions = 'setDealType';
  const openTooltip = useSearchResultsTooltipOpen({ filterKey, actions: [action] });

  const handleApply = React.useCallback(() => {
    if (onApplyClick) {
      onApplyClick({ source: 'tooltip' });
    }

    onApply();

    if (onClose) {
      onClose();
    }
  }, [onApply, onApplyClick, onClose]);

  const handleChange = React.useCallback(
    (dealType: FDealType) => {
      onChange({ action, arguments: [dealType] });
    },
    [onChange],
  );

  return (
    <DealType
      open={open}
      value={dealType}
      hideTooltipCount={isHotelSearch}
      offersCount={jsonQueryCount}
      offersCountLoading={jsonQueryCountRefreshing}
      popupPostfix={
        <PostfixPopupLink href="/country-builders-projects/?utm_medium=listing&utm_content=link" text="Построить дом" />
      }
      openTooltip={openTooltip}
      onChange={handleChange}
      onApply={handleApply}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
};
