import * as React from 'react';

import { IGeoCircleTag } from '../../../../../state/tags/definitions/geo';
import { TagNew } from '../../../TagNew';

export interface IGeoCircleTagItemProps {
  tag: IGeoCircleTag;
  onDeleteClick(): void;
}

export const GeoCircleTagItem = (props: IGeoCircleTagItemProps) => {
  return <TagNew onDeleteClick={props.onDeleteClick}>{props.tag.text}</TagNew>;
};
