import * as R from 'ramda';

import { setGeo } from './helpers';
import { NonEmptyArray, TJsonQuery } from './types';
import { IJsonQueryUnderground } from '../api-models/common/json_query';

export function setUndergrounds(
  jsonQuery: TJsonQuery,
): (...geoUndergrounds: NonEmptyArray<IJsonQueryUnderground>) => TJsonQuery {
  return (...geoUndergrounds) => {
    const geo = (jsonQuery.geo && R.clone(jsonQuery.geo.value)) || [];

    const nextGeo = geo.filter(g => g.type !== 'underground' || geoUndergrounds.some(u => u.id === g.id));
    const addedGeoUndegrounds = geoUndergrounds.filter(v => !geo.some(g => R.equals(v, g)));

    return setGeo()(jsonQuery)([...nextGeo, ...addedGeoUndegrounds]);
  };
}
