import * as React from 'react';

import { IGeoDeveloperTag } from '../../../../state/tags/definitions/geo';
import { TagNew } from '../../TagNew';

export interface IGeoBuilderTagItemProps {
  tag: IGeoDeveloperTag;
  onDeleteClick(): void;
}

export const GeoBuilderTagItem = (props: IGeoBuilderTagItemProps) => {
  return <TagNew onDeleteClick={props.onDeleteClick}>{props.tag.text}</TagNew>;
};
