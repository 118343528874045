import { EMetroTravelType } from '../json_query';

export function getDefaultTravelTimeForType(type?: EMetroTravelType): number {
  switch (type) {
    case EMetroTravelType.AgentTransport:
    case EMetroTravelType.MapTransport:
      return 30;

    case EMetroTravelType.Any:
    case EMetroTravelType.AgentFoot:
    case EMetroTravelType.MapFoot:
      return 45;
  }

  return 45;
}
