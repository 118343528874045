import React from 'react';

import styles from './SimilarLink.css';
import { ISimilarLink } from '../../types/similar-link';
import { GeneralInfoSectionRow } from '../GeneralInfoSectionRow';

interface ISimilarLinkProps extends ISimilarLink {
  onClick(e: React.MouseEvent<HTMLAnchorElement>): void;
}

export function SimilarLink(props: ISimilarLinkProps) {
  return (
    <GeneralInfoSectionRow>
      <a
        className={styles['link']}
        href={props.url}
        rel="nofollow noopener noreferrer"
        target="_blank"
        onClick={props.onClick}
      >
        {props.text}
      </a>
    </GeneralInfoSectionRow>
  );
}
