import { ca } from '@cian/analytics';

import { IJsonQuery, ESortValue } from '../../../../json_query';
import { IBreadcrumb, IOffer, offerHelper } from '../../../../offer';
import { ECategory } from '../../../../repositories/offers-comparison/v2/add-offer-to-comparison';
import { TUser } from '../../../../types/user';
import { getExtendedOfferEventExtra, getOfferListingType } from '../../../../utils/offer/tracking';

export interface ITrackPageJumpOptions {
  jsonQuery: IJsonQuery;
  user?: TUser;
  position: number;
  isSimilarFromML?: boolean;
  label: string;
  pageNum?: number;
  breadcrumbs: IBreadcrumb[];
  queryString: string;
  offersQty: number;
  salesLabel?: string;
  mlRankingGuid: string | null;
  mlRankingModelVersion: string | null;
}

export const LABEL_WHITE_LIST = ['card_header_in_newlist', 'card_detail_in_newlist', 'card_areas_in_newlist'];
export const trackPageJumped = (offer: IOffer, options: ITrackPageJumpOptions) => {
  const {
    jsonQuery,
    breadcrumbs,
    queryString,
    pageNum,
    offersQty,
    user,
    label,
    salesLabel,
    mlRankingGuid,
    mlRankingModelVersion,
  } = options;
  const { id, variant, ownerId } = offerHelper(offer).getAnalyticsInfo();
  let extra: Record<string, string | number | boolean | object> = {};
  const labelString = LABEL_WHITE_LIST.includes(label) ? label : '';
  const rosreestrStatus = offer.isRosreestrChecked ? { status: 1 } : {};

  if (salesLabel) {
    extra.sales_label = salesLabel;
  }

  if (offer.isCianPartner) {
    extra = {
      ...extra,
      sutochno_feed_test: true,
      sutochno_url: offer.fullUrl,
    };
  }

  if (offer.category === ECategory.FlatSale) {
    extra = {
      ...extra,
      rosreestrData: rosreestrStatus,
    };
  }

  const ebcObject = {
    name: 'oldevent',
    category: 'Listing',
    action: options.isSimilarFromML ? 'to_card_infinite_search' : 'to_card',
    label: labelString,
    searchConditions: options.jsonQuery,
    modelVersion:
      typeof offer.modelVersion !== 'object' && typeof offer.modelVersion !== 'undefined'
        ? String(offer.modelVersion)
        : undefined,
    products: [
      {
        id,
        offerType: 'offer',
        position: Number(options.position),
        published: offer.status === 'published',
        variant: variant ? variant.split('/') : [],
        ownerId,
        extra,
      },
    ],
    page: {
      listingType: getOfferListingType(offer),
      sortType: jsonQuery.sort ? jsonQuery.sort.value : ESortValue.Default,
      pageNumber: Number(pageNum || 1),
      breadCrumbs: breadcrumbs.map(breadcrumb => breadcrumb.title),
      queryString,
      ...getExtendedOfferEventExtra(offer),
      offersQty,
      mlRankingGuid,
      mlRankingModelVersion,
      pageType: 'Listing',
      siteType: 'desktop',
    },
    user: {
      userId: user && user.isAuthenticated && user.userId ? Number(user.userId) : undefined,
      isAuthorized: Boolean(user && user.isAuthenticated),
    },
  };

  ca('eventEbc', ebcObject);
};
