import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Defer } from './defer';
import { requestAuthentication } from '../../../../actions/authentication';
import { fetchXlsListingFile } from '../../../../api/downloadXlsListingFile';
import { isAuthenticatedUserSelector } from '../../../../selectors/isAuthenticatedUserSelector';
import { selectQueryString } from '../../../../selectors/results/selectQueryString';
import { TThunkDispatch } from '../../../../types/redux';
import { useApplicationContext } from '../../../../utils/applicationContext';
import { downloadFromBlob } from '../../../../utils/downloadFromBlob';
import { trackDownloadExcelInModalClick, trackDownloadExcelFailLimit } from '../tracking';

export const DEFAULT_SERVER_ERROR = 'Ошибка сервера. Попробуйте еще раз или зайдите позже';
export const AUTH_ERROR = 'Необходимо авторизоваться';

export const useDownloadExcelFile = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { config } = useApplicationContext();
  const queryString = useSelector(selectQueryString);
  const isAuthenticated = useSelector(isAuthenticatedUserSelector);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const handleDownloadExcelFile = React.useCallback(async () => {
    const authDefer = new Defer<boolean>();

    trackDownloadExcelInModalClick();
    setIsLoading(true);
    setError(null);

    try {
      if (isAuthenticated) {
        authDefer.resolve(true);
      } else {
        dispatch(
          requestAuthentication({
            onSuccess: () => () => authDefer.resolve(true),
            onCloseNoAuth: () => () => authDefer.resolve(false),
          }),
        );
      }

      const authPassed = await authDefer.promise;

      if (!authPassed) {
        setError(AUTH_ERROR);

        return;
      }

      const res = await fetchXlsListingFile({
        config,
        parameters: { queryString },
      });

      if (res.statusCode === 200) {
        downloadFromBlob(res.response, 'offers.xlsx');
      } else {
        setError(res.response.message);
        trackDownloadExcelFailLimit();
      }
    } catch (e) {
      setError(DEFAULT_SERVER_ERROR);
    } finally {
      setIsLoading(false);
    }
  }, [config, dispatch, isAuthenticated, queryString]);

  const resetError = React.useCallback(() => setError(null), []);

  return { handleDownloadExcelFile, isLoading, error, resetError };
};
