import { IAppState } from '../../common/state';

export function selectUndergroundStations(state: IAppState): number[] {
  return state.filters.jsonQuery.geo
    ? state.filters.jsonQuery.geo.value.reduce((acc: number[], value) => {
        if (value.type === 'underground') {
          acc.push(Number(value.id));
        }

        return acc;
      }, [])
    : [];
}
