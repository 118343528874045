import { OFFER_TYPES_RENT_RESIDENTIAL } from './constants/offerTypes';
import { IOfferType } from './types';
import { FDealType } from '../../../../../JsonQuery';
import { getDailyRentOfferTypes } from '../../../utils/getDailyRentOfferTypes';
import { getSaleResidentialOfferTypes } from '../../../utils/getSaleResidentialOfferTypes';

const getOfferTypes = (
  isSearchFlatShareEnabled: boolean,
  isHotelsEnabled: boolean,
): { dealType: FDealType; list: IOfferType[][] }[] => [
  {
    dealType: FDealType.Sale,
    list: getSaleResidentialOfferTypes(isSearchFlatShareEnabled),
  },
  {
    dealType: FDealType.RentLongterm,
    list: OFFER_TYPES_RENT_RESIDENTIAL,
  },
  {
    dealType: FDealType.RentDaily,
    list: getDailyRentOfferTypes(isHotelsEnabled),
  },
];

export function getOfferTypeList(
  dealType: FDealType,
  isSearchFlatShareEnabled: boolean,
  isHotelsEnabled: boolean,
): IOfferType[][] {
  const type = getOfferTypes(isSearchFlatShareEnabled, isHotelsEnabled).find(t => t.dealType === dealType);

  return (type && type.list) || [];
}
