import { getTermValue } from './helpers';
import { FDealType, FOfferType, TJsonQuery } from './types';
import { dealTypeFromJsonQuery, offerTypeFromJsonQuery } from './utils';

export function getIsEncumbranceRealty(jsonQuery: TJsonQuery): boolean | null {
  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const offerType = offerTypeFromJsonQuery(jsonQuery);

  const isSale = dealType === FDealType.Sale;
  const isFlat = (offerType & FOfferType.Flat) !== 0;

  if (!isSale || !isFlat) {
    return null;
  }

  return getTermValue('is_encumbrance_realty')(jsonQuery);
}
