import { getDescription } from './vas/getDescription';
import { getName } from './vas/getName';
import { sortVas } from './vas/sortVas';
import { TComputedPropMapper } from '../../types/computed-props-mapper';
import { EVas, IVas } from '../../types/vas';

export const vasList: TComputedPropMapper<IVas[]> = (
  { offer: { geo, isAuction, isStandard, isTop3, isPremium } },
  { config },
) => {
  const offerLocation =
    geo && geo.address && geo.address.length > 0 ? geo.address.find(addr => addr.type === 'location') : null;
  const mlSearchRegions = config.get<number[]>('mlSearchRegions') || [];
  const isMlSearchForAll = config.get<boolean>('isMlSearchForAll.Enabled') || false;

  const isMLSearchByRegion = !!offerLocation && (mlSearchRegions || []).includes(offerLocation.id);
  const isMLSearch = isMlSearchForAll || isMLSearchByRegion;

  const vasList = [
    isAuction && EVas.Auction,
    isTop3 && EVas.Top,
    isPremium && EVas.Premium,
    isStandard && EVas.Standard,
  ].filter(Boolean) as EVas[];

  return sortVas(vasList).map(vas => ({
    type: vas,
    name: getName(vas, isMLSearch),
    description: getDescription(vas, isMLSearch),
  }));
};
