import * as React from 'react';

import * as styles from './styles.css';

export interface IMarqueeProps {
  text: string;
}

/* Компонент бегущей строки */
export function Marquee({ text }: IMarqueeProps) {
  const container = React.useRef<HTMLDivElement | null>(null);

  const mouseOverHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    if (container.current) {
      const target = (event.target as HTMLSpanElement) || ({ offsetWidth: 0 } as HTMLSpanElement);
      const containerWith = container.current.offsetWidth;
      const childWith = target.offsetWidth;

      const diff = childWith - containerWith;

      if (diff > 0) {
        target.style.transition = `transform ${diff / 100}s linear`;
        target.style.transform = `translate(-${diff}px, 0)`;
      }
    }
  };

  const mouseLeaveHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLSpanElement;

    if (target) {
      target.style.transform = '';
      target.style.transition = '';
    }
  };

  return (
    <div
      ref={container}
      className={styles['container']}
      onMouseOver={mouseOverHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <span className={styles['text']} data-testid="MarqueeText">
        {text}
      </span>
    </div>
  );
}
