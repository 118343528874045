import * as React from 'react';

import * as styles from './styles.css';
import { UndergroundIcon } from '../../../../../../../../../common/components/underground_icon';

export interface IMetroProps {
  metroColor?: string | null;
  locationId?: number;
  metro: string;
  metroDistance?: string | null;
}

export function Metro({ metroColor, locationId, metro, metroDistance }: IMetroProps) {
  const metroDistanceView = <span className={styles['metro-distance']}>{metroDistance}</span>;

  return (
    <div className={styles['metro-container']} data-testid="metro_block">
      {metroColor && (
        <div className={styles['metro-icon']}>
          <UndergroundIcon
            width={locationId ? 16 : 12}
            height={locationId ? 16 : 14}
            color={metroColor}
            regionId={locationId}
          />
        </div>
      )}
      <div className={styles['metro-name']}>
        {metro}
        {metroDistanceView}
      </div>
    </div>
  );
}
