import { IAppState, TActions } from '../../../common/state';
import { ESaleType, jq } from '../../../json_query';

export const SALE_TYPE_CHANGED_ACTION_TYPE = 'filters/sale_type/SALE_TYPE_CHANGED';
export const SALE_TYPE_SET_ACTION_TYPE = 'filters/sale_type/SALE_TYPE_SET';
export const MORTGAGE_CHANGED_ACTION_TYPE = 'filters/sale_type/MORTGAGE_CHANGED';

export interface ISaleTypeChangedAction {
  type: 'filters/sale_type/SALE_TYPE_CHANGED';
  saleType: ESaleType;
  value: boolean;
}

export interface ISaleTypeSetAction {
  type: 'filters/sale_type/SALE_TYPE_SET';
  value: ESaleType[];
}

export interface IMortgageChangedAction {
  type: 'filters/sale_type/MORTGAGE_CHANGED';
  mortgagePossible: boolean;
}

export function changeSaleTypes(saleType: ESaleType, value: boolean): ISaleTypeChangedAction {
  return {
    type: SALE_TYPE_CHANGED_ACTION_TYPE,
    saleType,
    value,
  };
}

export function setSaleTypes(value: ESaleType[]): ISaleTypeSetAction {
  return {
    type: SALE_TYPE_SET_ACTION_TYPE,
    value,
  };
}

export function changeMortgageCheckbox(mortgagePossible: boolean): IMortgageChangedAction {
  return {
    type: MORTGAGE_CHANGED_ACTION_TYPE,
    mortgagePossible,
  };
}

export function saleTypeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case SALE_TYPE_CHANGED_ACTION_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: action.value
            ? jq(state.filters.jsonQuery).addSaleType(action.saleType)
            : jq(state.filters.jsonQuery).removeSaleType(action.saleType),
        },
      };

    case SALE_TYPE_SET_ACTION_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            sost_type: action.value.length
              ? {
                  type: 'terms',
                  value: action.value,
                }
              : undefined,
          },
        },
      };

    case MORTGAGE_CHANGED_ACTION_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setMortgage(action.mortgagePossible),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-saleType-mortgagePossible') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).setMortgage(false),
          },
        };
      } else if (action.tag.type === 'advanced-saleType-saleType') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).removeSaleType(action.tag.value),
          },
        };
      }

      return state;

    default:
      return state;
  }
}
