import { Outside } from '@cian/ui-kit';
import { Button } from '@cian/ui-kit/button';
import * as React from 'react';

import * as styles from './Bedrooms.css';
import { getBedroomsLabel } from './helpers';
import { IJsonQueryRangeValue } from '../../../types/jsonQuery';
import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { RangeInput } from '../../RangeInput';
import { SearchResultsTooltip } from '../../SearchResultsTooltip';

interface IBedroomsProps {
  open?: boolean;
  bedrooms: IJsonQueryRangeValue | null;
  offersCount?: number;
  offersCountLoading?: boolean;
  openTooltip?: boolean;
  onBedroomsMinChange(gte: number | null): void;
  onBedroomsMaxChange(lte: number | null): void;
  onApply?(): void;
  onOpen?(): void;
  onClose?(): void;
}

export const Bedrooms: React.FC<IBedroomsProps> = ({
  open,
  bedrooms,
  offersCount,
  offersCountLoading,
  openTooltip,
  onBedroomsMinChange,
  onBedroomsMaxChange,
  onApply,
  onOpen,
  onClose,
}) => {
  const { gte: bedroomsMin = null, lte: bedroomsMax = null } = bedrooms || {};
  const bedroomsLabel = getBedroomsLabel(bedroomsMin, bedroomsMax);

  const handleClick = React.useCallback(() => {
    if (!open && onOpen) {
      onOpen();
    } else if (open && onClose) {
      onClose();
    }
  }, [open, onOpen, onClose]);

  const handleClose = React.useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleBedroomsMinChange = useDebouncedCallback(
    (gte: number | null) => {
      onBedroomsMinChange(gte);
    },
    300,
    [onBedroomsMinChange],
  );

  const handleBedroomsMaxChange = useDebouncedCallback(
    (lte: number | null) => {
      onBedroomsMaxChange(lte);
    },
    300,
    [onBedroomsMaxChange],
  );

  return (
    <Outside onOutside={handleClose} active={open}>
      <div className={styles['container']}>
        <SearchResultsTooltip
          open={!open && openTooltip}
          offersCount={offersCount || 0}
          loading={offersCountLoading}
          placement="bottom-start"
          onApply={onApply}
        >
          <Button theme="stroke_secondary" size="XS" onClick={handleClick}>
            {bedroomsLabel || 'Спальни'}
          </Button>
        </SearchResultsTooltip>
        {open && (
          <SearchResultsTooltip
            open={openTooltip}
            offersCount={offersCount || 0}
            loading={offersCountLoading}
            placement="right"
            onApply={onApply}
          >
            <div className={styles['dropdown']}>
              <RangeInput
                min={0}
                max={50}
                joined={false}
                valueMin={bedroomsMin}
                valueMax={bedroomsMax}
                onChangeMin={handleBedroomsMinChange}
                onChangeMax={handleBedroomsMaxChange}
              />
            </div>
          </SearchResultsTooltip>
        )}
      </div>
    </Outside>
  );
};
