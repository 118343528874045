import { EGetUserStatus } from './types';
import { fetchUser } from '../../services/fetchUser';
import { TThunkAction } from '../../types/redux';
import { TUser } from '../../types/user';
import { actionGenerator } from '../../utils/redux/actionGenerator';

const setStatusLoading = actionGenerator<EGetUserStatus.Loading>(EGetUserStatus.Loading);
const setStatusSucceed = actionGenerator<EGetUserStatus.Succeed, TUser>(EGetUserStatus.Succeed);
const setStatusFailed = actionGenerator<EGetUserStatus.Failed>(EGetUserStatus.Failed);

export interface IGetUserParameters {
  timeout?: number;
}

export function getUser(): TThunkAction<Promise<void>> {
  return async (dispatch, getState, context) => {
    dispatch(setStatusLoading());

    const { logger } = context;

    const { currentSubdomain } = getState();

    try {
      const user = await fetchUser(context, { subdomain: currentSubdomain });

      dispatch(setStatusSucceed(user));
    } catch (error) {
      logger.error(error);

      dispatch(setStatusFailed());
    }
  };
}
