import { connect } from 'react-redux';

import { HeaderVillage } from './village';
import { IAppState } from '../../../../common/state';

export function mapStateToProps(state: IAppState) {
  return {
    breadcrumbs: state.breadcrumbs,
    villageInfo: state.results.villageInfo,
    jsonQuery: state.results.jsonQuery,
    h1: state.results.seo.h1,
  };
}

export const HeaderVillageContainer = connect(mapStateToProps)(HeaderVillage);
