import { connect } from 'react-redux';

import { GeoSuggest } from './index';
import { Dispatch, IAppState } from '../../../common/state';
import { ILocation, TLocation } from '../../../types/location';
import { dealTypeFromJsonQuery, offerTypeFromJsonQuery } from '../../../utils/category';
import { IDirection } from '../../api/directions';
import { changeBuilder } from '../../state/builder';
import { handleReceiveDirectionsData, selectHighways } from '../../state/directions';
import { IGeoObject, IPolygonObject, selectGeo } from '../../state/geo';
import { ISelectKPParams, selectKP } from '../../state/kp_id';
import { makeSearch } from '../../state/search';

export interface IGeoSuggestContainerOwnProps {
  rightAddon?: React.ReactNode;
  redesign?: boolean;
  onHighwaySelected?(): void;
  onGeoSelected?(): void;
  onKPSelected?(id: number): void;
  onBuilderSelected?(props: { builderId: number; name: string }): void;
}

export function mapStateToProps(state: IAppState) {
  const { jsonQuery, currentLocation, regions, directionsModal } = state.filters;

  return {
    makeRequest: state.makeRequest,
    logger: state.logger,
    dealType: dealTypeFromJsonQuery(jsonQuery),
    offerType: offerTypeFromJsonQuery(jsonQuery),
    highwaysData: directionsModal.data,
    currentLocation,
    boundedBy: getBoundedBy(currentLocation, regions),
    httpApi: state.httpApi,
  };
}

export function mapDispatchToProps(dispatch: Dispatch, ownProps: IGeoSuggestContainerOwnProps) {
  const { redesign, onGeoSelected, onHighwaySelected, onKPSelected, onBuilderSelected } = ownProps;

  return {
    onGeoSelected: (value: IGeoObject | IPolygonObject, userInput: string) => {
      if (onGeoSelected) {
        onGeoSelected();
      }

      dispatch(selectGeo(value, userInput));

      if (redesign && (value.type === 'location' || value.type === 'regional_district_id')) {
        dispatch(makeSearch());
      }
    },
    onHighwaySelected: (highwaysIds: number[], regionId?: number) => {
      if (onHighwaySelected) {
        onHighwaySelected();
      }

      dispatch(selectHighways(highwaysIds, regionId));
    },
    directionsDataReceived: (directionsData: IDirection[], regionId: number) => {
      dispatch(handleReceiveDirectionsData(directionsData, regionId));
    },
    onKPSelected: (params: ISelectKPParams) => {
      if (onKPSelected) {
        onKPSelected(params.id);
      }

      dispatch(selectKP(params));
    },
    onBuilderSelected: (params: { builderId: number; name: string }) => {
      if (onBuilderSelected) {
        onBuilderSelected(params);
      }

      dispatch(changeBuilder({ id: params.builderId, text: params.name }));
    },
  };
}

export const GeoSuggestContainer = connect(mapStateToProps, mapDispatchToProps)(GeoSuggest);

export function getBoundedBy(currentLocation: TLocation, regions: ILocation[]) {
  let location: ILocation | undefined;

  if (currentLocation === 'moscow_mo') {
    const region = regions.filter(r => r.id === 4593).shift();

    if (region) {
      location = region;
    }
  } else if (currentLocation === 'spb_lo') {
    const region = regions.filter(r => r.id === 4588).shift();

    if (region) {
      location = region;
    }
  } else if (!currentLocation.boundedBy && currentLocation.parentId) {
    const region = regions.filter(r => r.id === currentLocation.parentId).shift();

    if (region) {
      location = region;
    }
  } else {
    location = currentLocation;
  }

  if (!location || !location.boundedBy) {
    return undefined;
  }

  return [
    [location.boundedBy.upperCorner.lat, location.boundedBy.upperCorner.lng],
    [location.boundedBy.lowerCorner.lat, location.boundedBy.lowerCorner.lng],
  ] as [YMaps.TCoord, YMaps.TCoord];
}
