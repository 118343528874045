import {
  COMPLAIN_COMPLAINTS_FETCHED,
  COMPLAIN_FAIL_REQUEST,
  IComplainComplaintsFetched,
  IComplainFailRequest,
} from './complain';
import {
  IGetModerationComplainMicrofrontendResponse200,
  fetchGetModerationComplainMicrofrontend,
} from '../../repositories/moderation-complain-frontend/v1';
import { TThunkAction } from '../../types/redux';

/* istanbul ignore next */
export function getComplaints(): TThunkAction<Promise<void>> {
  return async (dispatch, getState, context) => {
    const { httpApi, microfrontendsRegistry } = context;

    try {
      const { statusCode, response } =
        await fetchGetModerationComplainMicrofrontend<IGetModerationComplainMicrofrontendResponse200>({ httpApi });

      if (statusCode === 200) {
        microfrontendsRegistry.register(response.manifest);
        dispatch<IComplainComplaintsFetched>({
          manifest: response.manifest,
          type: COMPLAIN_COMPLAINTS_FETCHED,
        });
      }
    } catch {
      dispatch<IComplainFailRequest>({
        isError: true,
        type: COMPLAIN_FAIL_REQUEST,
      });
    }
  };
}
