import * as React from 'react';

import { IIdenticalOffersIdTag } from '../../../state/tags/definitions/identical_offers_id';
import { TagNew } from '../TagNew';

export interface IIdenticalOffersIdTagItemProps {
  tag: IIdenticalOffersIdTag;
  onDeleteClick(): void;
}

export const IdenticalOffersIdTagItem = ({ tag, onDeleteClick }: IIdenticalOffersIdTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>Мультиобъявление №{tag.value}</TagNew>;
};
