import { UIHeading2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './NewbuildingBrokerBanner.css';
import imgLeftSideCenter from './assets/img_left_side--center.png';
import imgLeftSideLeft from './assets/img_left_side--left.png';
import imgLeftSideRight from './assets/img_left_side--right.png';
import imgRightSideCenter from './assets/img_right_side--center.png';
import imgRightSideLeft from './assets/img_right_side--left.png';
import imgRightSideRight from './assets/img_right_side--right.png';

export interface INewbuildingBrokerBannerProps {
  button: React.ReactNode;
}

export const NewbuildingBrokerBanner = ({ button }: INewbuildingBrokerBannerProps) => {
  return (
    <div className={styles['banner']} data-testid="NewbuildingBrokerBanner">
      <img className={styles['img_left_side--left']} src={imgLeftSideLeft} />
      <img className={styles['img_left_side--center']} src={imgLeftSideCenter} />
      <img className={styles['img_left_side--right']} src={imgLeftSideRight} />
      <img className={styles['img_right_side--left']} src={imgRightSideLeft} />
      <img className={styles['img_right_side--center']} src={imgRightSideCenter} />
      <img className={styles['img_right_side--right']} src={imgRightSideRight} />
      <div className={styles['content']}>
        <div className={styles['header']}>
          <UIHeading2 color="white_100">Продайте квартиру от застройщика и получите комиссию за сделку</UIHeading2>
        </div>
        <div className={styles['button']} data-testid="NewbuildingBrokerBannerButton">
          {button}
        </div>
      </div>
    </div>
  );
};
