import {
  IconProductHighDemand16,
  IconProductCianLogo12,
  IconProductTrust16,
  IconFinancePercent12,
  IconProductHotLeads16,
} from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { ELabelColor, TLabelColor } from '../../types/label';

export const labelIconMap: Record<ELabelColor, React.ReactElement | undefined> = {
  [ELabelColor.Red]: undefined,
  [ELabelColor.Green]: undefined,
  [ELabelColor.DarkGreen]: undefined,
  [ELabelColor.Blue]: <IconProductCianLogo12 color="current_color" />,
  [ELabelColor.Gray]: undefined,
  [ELabelColor.DarkGray]: undefined,
  [ELabelColor.Yellow]: undefined,
  [ELabelColor.OrangePercent]: <IconFinancePercent12 color="current_color" />,
  [ELabelColor.SimpleBlue]: undefined,
  [ELabelColor.SurfaceBlueIconed]: <IconProductHotLeads16 color="primary_100" />,
  [ELabelColor.SurfaceBlue]: undefined,
  [ELabelColor.BlackBlueGradient]: undefined,
  [ELabelColor.BlueVerifiedByCian]: <IconProductTrust16 color="white_100" />,
  [ELabelColor.Purple]: <IconProductHighDemand16 color="current_color" />,
};

export function mapLabelIcon(color: TLabelColor): React.ReactElement | undefined {
  return labelIconMap[color];
}
