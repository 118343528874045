import { plural } from '@cian/utils';
import * as React from 'react';

import { getTravelTypeByOfferType } from './utils/getTravelTypeByOfferType';
import { FOfferType } from '../../../../../JsonQuery';
import { EMetroTravelType } from '../../../types/jsonQuery';
import { MaskedInput } from '../../MaskedInput';
import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';

interface IUndergroundTimeOption {
  value: EMetroTravelType | null;
  label: string;
}

const UNDERGROUND_TRAVEL_OPTIONS: IUndergroundTimeOption[] = [
  {
    value: EMetroTravelType.MapFoot,
    label: 'Пешком',
  },
  {
    value: EMetroTravelType.MapTransport,
    label: 'Транспортом',
  },
];

const DEFAULT_TRAVEL_TIME = 45;

interface ITravelDataChange {
  travelTime: number | null;
  travelType: EMetroTravelType | null;
}

interface IUndergroundTimeProps {
  offerType: FOfferType;
  travelType: EMetroTravelType | null;
  travelTime: number | null;
  onTravelTypeChange(type: EMetroTravelType | null): void;
  onTravelTimeChange(time: number | null): void;
  onTravelDataChange(args: ITravelDataChange): void;
}

export const UndergroundTime: React.FC<IUndergroundTimeProps> = ({
  offerType,
  travelType,
  travelTime,
  onTravelTypeChange,
  onTravelTimeChange,
  onTravelDataChange,
}) => {
  const handleTravelTimeChange = React.useCallback(
    (time: string) => {
      const nextTime = time ? Number(time) : null;

      if (nextTime && !travelType) {
        const nextTravelType = getTravelTypeByOfferType(offerType);

        onTravelDataChange({ travelTime: nextTime, travelType: nextTravelType });
      } else if (!nextTime && travelType) {
        onTravelDataChange({ travelTime: nextTime, travelType: null });
      } else {
        onTravelTimeChange(nextTime);
      }
    },
    [offerType, onTravelDataChange, onTravelTimeChange, travelType],
  );

  const handleTravelTypeChange = React.useCallback(
    (type: EMetroTravelType | null) => {
      if (!travelTime && type) {
        onTravelDataChange({ travelTime: DEFAULT_TRAVEL_TIME, travelType: type });
      } else {
        onTravelTypeChange(type);
      }
    },
    [travelTime, onTravelDataChange, onTravelTypeChange],
  );

  return (
    <Filter id="advancedFilter_undergroundTime" label="До метро">
      <InlineFilterGroup>
        <InlineFilter
          prefix="Не более"
          postfix={(travelTime && plural(travelTime, ['минуты', 'минут', 'минут'])) || 'минут'}
        >
          <MaskedInput
            inline
            width={50}
            placeholder="10"
            min={1}
            max={300}
            mask={Number}
            value={travelTime === null ? '' : String(travelTime)}
            onComplete={handleTravelTimeChange}
          />
        </InlineFilter>
        <InlineFilter>
          <RadioButtonGroup
            options={UNDERGROUND_TRAVEL_OPTIONS}
            value={travelType}
            onChange={handleTravelTypeChange}
            spliced={false}
          />
        </InlineFilter>
      </InlineFilterGroup>
    </Filter>
  );
};
