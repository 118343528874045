import { Image } from '@cian/ui-kit/image';
import { Header4, InlineText2 } from '@cian/ui-kit/typography';
import * as React from 'react';

import * as styles from './styles.css';

export interface INewbuildingEntryResaleItemProps {
  onClick(): void;
  imageUrl: string;
  title: string;
  price: string;
  description: string[];
  address: string;
  locationIcon: React.ReactNode | null;
  locationIconColor: string | null;
  locationText: string | null;
  timeIcon: React.ReactNode | null;
  timeText: string | null;
  url: string;
}

export function NewbuildingEntryResaleItem({
  onClick,
  imageUrl,
  title,
  price,
  description,
  address,
  locationIcon,
  locationIconColor,
  locationText,
  timeIcon,
  timeText,
  url,
}: INewbuildingEntryResaleItemProps) {
  return (
    <a className={styles['container']} onClick={onClick} href={url}>
      <div className={styles['image']}>
        <Image src={imageUrl} alt={title} height={'140px'} width={'100%'} objectFit={'contain'} borderRadius={0} />
      </div>
      <div className={styles['content']}>
        <div className={styles['title']}>
          <InlineText2 whiteSpace={'nowrap'} as={'span'}>
            {title}
          </InlineText2>
        </div>
        <div className={styles['price']}>
          <Header4 whiteSpace={'nowrap'} as={'span'}>
            {price}
          </Header4>
        </div>
        <div className={styles['description']}>
          {description.map((item, idx) => (
            <React.Fragment key={`description-${idx}`}>
              <InlineText2 as={'span'} whiteSpace={'nowrap'}>
                {item}
              </InlineText2>{' '}
            </React.Fragment>
          ))}
        </div>
        <div className={styles['address']}>
          <InlineText2 whiteSpace={'nowrap'} as={'span'} color={'gray60_100'}>
            {address}
          </InlineText2>
        </div>
        {locationIconColor && (
          <div className={styles['locationBlock']}>
            <div className={styles['location']}>
              <span className={styles['locationIcon']} style={{ color: locationIconColor }}>
                {locationIcon}
              </span>
              <span className={styles['locationText']}>
                <InlineText2 whiteSpace={'nowrap'} as={'span'}>
                  {locationText}
                </InlineText2>
              </span>
            </div>
            <div className={styles['time']}>
              {timeIcon && <span className={styles['timeIcon']}>{timeIcon}</span>}
              <span className={styles['timeText']}>
                <InlineText2 whiteSpace={'nowrap'} as={'span'} color={'gray60_100'}>
                  {timeText}
                </InlineText2>
              </span>
            </div>
          </div>
        )}
      </div>
    </a>
  );
}
