import * as React from 'react';

import { useApplicationContext } from '../../../../../../shared/utils/applicationContext';
import { JsonQuery } from '../../../../../JsonQuery';
import { SubsidisedMortgage, TOption } from '../../../components/advancedFilters/SubsidisedMortgage';
import { useContext } from '../../../utils/useContext';

export const SubsidisedMortgageContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const isSubsidisedMortgage = new JsonQuery(jsonQuery).getIsSubsidisedMortgage();
  const { config } = useApplicationContext();

  const label = config.get<string>('frontend-serp.subsidizedMortgageLabelText') || '';

  const options: TOption[] = [
    { label: 'Неважно', value: null },
    { label, value: true },
  ];

  const handleChange = React.useCallback(
    (isSubsidisedMortgage: boolean | null) => {
      onChange({ action: 'setIsSubsidisedMortgage', arguments: [isSubsidisedMortgage] });
    },
    [onChange],
  );

  if (!label) {
    return null;
  }

  return <SubsidisedMortgage onChange={handleChange} value={isSubsidisedMortgage} options={options} />;
};
