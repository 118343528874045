import { ResponseError } from '../../errors';
import {
  IRegionalDistrictsGetByLocationIdRequest,
  TRegionalDistrictsGetByLocationIdResponse,
  fetchRegionalDistrictsGetByLocationId,
} from '../../repositories/geo/v1/regional-districts-get-by-location-id';
import { IApplicationContext } from '../../types/applicationContext';
import { IRegionalDistrict } from '../../types/regionalDistricts';

export const fetchRegionalDistrictsByLocation = async (
  { httpApi }: IApplicationContext,
  parameters: IRegionalDistrictsGetByLocationIdRequest,
): Promise<IRegionalDistrict[]> => {
  const responseData: TRegionalDistrictsGetByLocationIdResponse = await fetchRegionalDistrictsGetByLocationId({
    httpApi,
    parameters,
  });

  if (responseData.statusCode !== 200) {
    throw new ResponseError({
      domain: 'fetchRegionalDistrictsByLocation',
      message: responseData.response.message,
      details: {
        errors: responseData.response.errors,
        locationId: parameters.locationId,
      },
    });
  }

  return responseData.response.districts;
};

export async function fetchRegionalDistrictsByLocationSafe(
  context: IApplicationContext,
  parameters: IRegionalDistrictsGetByLocationIdRequest,
): Promise<IRegionalDistrict[]> {
  try {
    return await fetchRegionalDistrictsByLocation(context, parameters);
  } catch (ex) {
    context.logger.warning(ex, { degradation: 'Не удалось получить областные районы по id' });

    return [];
  }
}
