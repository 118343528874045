import { IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';

export interface IWithLayoutChangedAction {
  type: 'filters/with_layout/WITH_LAYOUT_CHANGED';
  value: boolean | undefined;
}

export function changeWithLayout(value: boolean | undefined): IWithLayoutChangedAction {
  return {
    type: 'filters/with_layout/WITH_LAYOUT_CHANGED',
    value,
  };
}

export function withLayoutReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case 'filters/with_layout/WITH_LAYOUT_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setWithLayout(action.value || undefined),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-withLayout') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jsonQuery.setWithLayout(undefined),
          },
        };
      }

      return state;

    default:
      return state;
  }
}
