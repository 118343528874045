import * as R from 'ramda';

import { resetTerms, setTerm } from './helpers';
import { ESuburbanUtility, NonEmptyArray, TJsonQuery } from './types';

export function setSuburbanUtilities(jsonQuery: TJsonQuery) {
  return (suburbanUtilities: NonEmptyArray<ESuburbanUtility> | null): TJsonQuery => {
    let nextJsonQuery = R.clone(jsonQuery);

    nextJsonQuery = resetTerms(['electricity', 'has_drainage', 'has_water', 'gas'])(nextJsonQuery);

    if (suburbanUtilities) {
      if (suburbanUtilities.includes(ESuburbanUtility.Electricity)) {
        nextJsonQuery = setTerm('electricity')(nextJsonQuery)(true);
      }

      if (suburbanUtilities.includes(ESuburbanUtility.Drainage)) {
        nextJsonQuery = setTerm('has_drainage')(nextJsonQuery)(true);
      }

      if (suburbanUtilities.includes(ESuburbanUtility.Water)) {
        nextJsonQuery = setTerm('has_water')(nextJsonQuery)(true);
      }

      if (suburbanUtilities.includes(ESuburbanUtility.Gas)) {
        nextJsonQuery = setTerm('gas')(nextJsonQuery)(true);
      }
    }

    return nextJsonQuery;
  };
}
