import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';

import {
  fetchApiCallbackAdd,
  IApiCallbackAddRequest,
  IApiCallbackAddResponse,
  IApiCallbackAddResponseError,
  IApiCallbackAddResponse200,
  IApiCallbackAddResponse400,
} from '../../../shared/repositories/monolith-python/unversioned/api-callback-add';

export async function requestCallback(
  parameters: IApiCallbackAddRequest,
): Promise<IApiCallbackAddResponse | IApiCallbackAddResponseError> {
  const httpApi = getHttpApi();

  try {
    const { response } = await fetchApiCallbackAdd<IApiCallbackAddResponse200, IApiCallbackAddResponse400>({
      httpApi,
      parameters,
      config: {
        requestConfig: {
          withCredentials: false,
        },
      },
    });

    return response;
  } catch (error) {
    const logger = getLogger();

    logger.error(error, {
      domain: 'requestCallback',
    });

    throw error;
  }
}
