import * as React from 'react';

import { EParkingType, getTermsValue } from '../../../../../JsonQuery';
import { Parking } from '../../../components/advancedFilters/Parking';
import { useContext } from '../../../utils/useContext';

export const ParkingContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const parkingType = getTermsValue('parking_type')(jsonQuery);

  const handleChange = React.useCallback(
    (parkingType: EParkingType[] | null) => {
      onChange({ action: 'setParkingType', arguments: [parkingType] });
    },
    [onChange],
  );

  return <Parking value={parkingType} onChange={handleChange} />;
};
