import {
  IGeoCircleTag,
  IGeoDeveloperTag,
  IGeoDistrictTag,
  IGeoHighwayTag,
  IGeoHouseTag,
  IGeoLocationTag,
  IGeoNewbuildingTag,
  IGeoPolygonTag,
  IGeoStreetTag,
  IGeoUndergroundTag,
  IGeoRailwayTag,
  IGeoBboxTag,
  IGeoRegionalDistrictTag,
} from './index';
import { TGeoType } from '../../../geo';
import { TTag } from '../../index';

export interface IGeoTag {
  type: TGeoType;
}

export type TGeoMapTag = IGeoPolygonTag | IGeoCircleTag | IGeoBboxTag;

export type TGeoObjectTag =
  | IGeoLocationTag
  | IGeoNewbuildingTag
  | IGeoStreetTag
  | IGeoHouseTag
  | IGeoUndergroundTag
  | IGeoDistrictTag
  | IGeoHighwayTag
  | IGeoDeveloperTag
  | IGeoRailwayTag
  | IGeoRegionalDistrictTag;

export type TGeoTag = TGeoMapTag | TGeoObjectTag;

export const isGeoTag = (tag: TTag): tag is TGeoTag => tag.type.startsWith('geo-');
