import { IAppState, ITagsData, TActions } from '../../common/state';
import { jq } from '../../json_query';

export interface IBuilderChangedPayload {
  id: number;
  text: string;
}

export interface IBuilderChangedAction {
  type: 'filters/builder/BUILDER_CHANGED';
  payload: IBuilderChangedPayload;
}

export function changeBuilder(payload: IBuilderChangedPayload): IBuilderChangedAction {
  return {
    type: 'filters/builder/BUILDER_CHANGED',
    payload,
  };
}

export function builderReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);
  const tagsData: ITagsData = { ...state.filters.tagsData };

  switch (action.type) {
    case 'filters/builder/BUILDER_CHANGED':
      tagsData.developers = { ...tagsData.locations, [action.payload.id]: action.payload.text };

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setBuilder(action.payload.id),
          tagsData,
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'builder' || action.tag.type === 'geo-builder') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jsonQuery.setBuilder(undefined),
          },
        };
      }

      return state;

    default:
      return state;
  }
}
