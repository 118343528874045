import { getSalePricePerMeter } from './getSalePricePerMeter';
import { IOffer } from '../../../../types/offer';
import { getAgentBonusPrice } from '../getAgentBonusPrice';

export function getSalePriceInfo(offer: IOffer, isAgent: boolean): string | undefined {
  const infoParts = [getSalePricePerMeter(offer), getAgentBonusPrice(offer, isAgent)].filter(Boolean);

  if (infoParts.length > 0) {
    return infoParts.join(', ');
  }

  return undefined;
}
