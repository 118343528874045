import { Icon3DTour16, Image, UIHeading2, UIHeading3, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';
import { INewbuildingLayoutWithOffers } from '../../../../types/residentialComplexLayouts/layoutWithOffers/layoutWithOffers';

interface IModalHeaderProps {
  contactsButton: React.ReactNode;
  layout: INewbuildingLayoutWithOffers;
}

export const ModalHeader = ({ contactsButton, layout }: IModalHeaderProps) => {
  return (
    <div className={style['container']}>
      <div className={style['image']}>
        <Image src={layout.imageUrl || ''} alt="Планировка" width={240} height={188} objectFit="contain" />
        {layout.tourUrl && (
          <div className={style['tour']}>
            <Icon3DTour16 color="primary_100" />
          </div>
        )}
      </div>
      <div className={style['info-block']}>
        <div className={style['layout']}>
          <div className={style['layout-params']}>
            <UIText1>{layout.title}</UIText1>
            <UIHeading3>{layout.tourAreaText}</UIHeading3>
          </div>
          <div className={style['layout-price']}>
            <UIHeading2 textAlign="right">{layout.tourAreaText}</UIHeading2>
            <UIText1 color="current_color" textAlign="right">
              {layout.tourPriceText}
            </UIText1>
          </div>
        </div>
        <div className={style['contacts']}>
          <div className={style['contacts-control']} data-testid="OffersModalContactButton">
            {contactsButton}
          </div>
        </div>
      </div>
    </div>
  );
};
