/* eslint-disable react/no-danger */
import * as React from 'react';
import { useSelector } from 'react-redux';
import sanitize from 'sanitize-html';

import * as styles from './styles.css';
import { trackPreInfiniteEmptyShow } from './tracking';
import { selectSeo } from '../../../../selectors/seo';

export const EmptySearchContent: React.FC = () => {
  const seo = useSelector(selectSeo);

  React.useEffect(() => {
    trackPreInfiniteEmptyShow();
  }, []);

  const sanitizedSeoNotFoundText: string = React.useMemo(() => {
    if (!seo.notFoundText) {
      return '';
    }

    return sanitize(seo.notFoundText);
  }, [seo]);

  if (!seo.notFoundText) {
    return null;
  }

  return <div className={styles['seo-text']} dangerouslySetInnerHTML={{ __html: sanitizedSeoNotFoundText }} />;
};
