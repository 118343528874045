import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';
import { INewbuildingLayoutWithOffers } from '../../../../types/residentialComplexLayouts/layoutWithOffers/layoutWithOffers';
import { OffersRow } from '../OffersRow';

export const HEADER_ITEMS: string[] = ['Площадь', 'Срок сдачи', 'Этаж', 'Стоимость', ''];

interface IOffersTableProps {
  layout: INewbuildingLayoutWithOffers;
}

export const OffersTable = ({ layout }: IOffersTableProps) => {
  return (
    <div className={style['container']}>
      <div className={style['table-header']}>
        {HEADER_ITEMS.map((item, index) => (
          <div className={style['header-cell']} key={index}>
            <UIText2 color="current_color" textAlign={index === 3 ? 'right' : 'left'}>
              {item}
            </UIText2>
          </div>
        ))}
        <div className={style['divider']} />
      </div>
      <div className={style['table']}>
        {layout.offers.map(offer => (
          <OffersRow key={offer.offerId} offer={offer} />
        ))}
        <div className={style['offers-link-block']}></div>
      </div>
    </div>
  );
};
