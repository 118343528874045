import * as React from 'react';

import { NonEmptyArray } from '../../../../../JsonQuery';
import { ERepair } from '../../../types/jsonQuery';
import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface IRepair {
  label: string;
  value: ERepair | null;
}

const REPAIRS: IRepair[] = [
  { label: 'Неважно', value: null },
  { label: 'Без ремонта', value: ERepair.Without },
  { label: 'Косметический', value: ERepair.Cosmetic },
  { label: 'Евроремонт', value: ERepair.Euro },
  { label: 'Дизайнерский', value: ERepair.Design },
];

interface IRepairProps {
  value: NonEmptyArray<ERepair> | null;
  onChange(value: NonEmptyArray<ERepair> | null): void;
}

export const Repair: React.FC<IRepairProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_repair" label="Ремонт">
      <CheckboxButtonGroupUnselectable options={REPAIRS} value={value} onChange={onChange} />
    </Filter>
  );
};
