import * as React from 'react';

import { getRangeValue } from '../../../../../JsonQuery';
import { Bedrooms } from '../../../components/filters/Bedrooms';
import { IFilterComponentProps } from '../../../types/filters';
import { useSearchResultsTooltipOpen } from '../../../utils/searchResultsTooltipOpen';
import { useContext } from '../../../utils/useContext';

export const BedroomsContainer: React.FC<IFilterComponentProps> = ({ filterKey, open, onOpen, onClose }) => {
  const { jsonQuery, jsonQueryCount, jsonQueryCountRefreshing, onChange, onApply, onApplyClick } = useContext();
  const bedrooms = getRangeValue('bedroom_total')(jsonQuery);
  const openTooltip = useSearchResultsTooltipOpen({
    filterKey,
    actions: ['setBedroomsCountMin', 'setBedroomsCountMax'],
  });

  const handleApply = React.useCallback(() => {
    if (onApplyClick) {
      onApplyClick({ source: 'tooltip' });
    }

    onApply();

    if (onClose) {
      onClose();
    }
  }, [onApply, onApplyClick, onClose]);

  const handleBedroomsMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setBedroomsCountMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleBedroomsMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setBedroomsCountMax', arguments: [lte] });
    },
    [onChange],
  );

  return (
    <Bedrooms
      open={open}
      bedrooms={bedrooms}
      offersCount={jsonQueryCount}
      offersCountLoading={jsonQueryCountRefreshing}
      openTooltip={openTooltip}
      onBedroomsMinChange={handleBedroomsMinChange}
      onBedroomsMaxChange={handleBedroomsMaxChange}
      onApply={handleApply}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
};
