import { ArticleHeading4, UIText2, UIHeading5, TooltipDesktop } from '@cian/ui-kit';
import { numberToPrettyString } from '@cian/utils';
import React from 'react';

import styles from './VasList.css';
import { EVas, IVas } from '../../types/vas';
import { ContentRow } from '../ContentRow';

interface IVasListProps {
  items: IVas[];
  bet?: string | number;
  liquidityPoints: number | null;
  nextBetTip?: React.ReactNode;
}

export function VasList({ items, bet, liquidityPoints, nextBetTip }: IVasListProps) {
  return (
    <div className={styles['cont']}>
      <TooltipDesktop
        theme="white"
        placement="top-end"
        title={
          <>
            <ContentRow size="M">
              <ArticleHeading4>Действуют услуги</ArticleHeading4>
            </ContentRow>
            {items.map(vas => (
              <ContentRow size="M" key={`vas-${vas.type}`}>
                <div className={styles['row']}>
                  <UIHeading5 color="warning_100" fontWeight="bold">
                    {vas.name}
                  </UIHeading5>
                </div>
                <UIText2>{vas.description}</UIText2>
              </ContentRow>
            ))}
            <div className={styles['button']}>
              <a href="https://support.cian.ru/ru/knowledge_base/art/94/cat/179/" target="_blank" rel="noreferrer">
                Узнать подробнее
              </a>
            </div>
          </>
        }
      >
        <div className={styles['inner']}>
          {items
            .filter(({ type }) => type !== EVas.Auction)
            .map(vas => (
              <div data-vas={vas.type} className={styles['icon']} key={`vas-icon-${vas.type}`}>
                <UIHeading5 color="warning_100">{vas.name}</UIHeading5>
              </div>
            ))}
          {liquidityPoints && (
            <div className={styles['liquidity-points']}>
              <UIHeading5 color="warning_100">+&nbsp;{numberToPrettyString(liquidityPoints)}&nbsp;₽ от Циан</UIHeading5>
            </div>
          )}
          {!liquidityPoints && bet && (
            <div className={styles['bet']}>
              <UIHeading5 color="warning_100">+&nbsp;{numberToPrettyString(Number(bet))}&nbsp;₽</UIHeading5>
            </div>
          )}
          {nextBetTip && <div className={styles['tip']}>{nextBetTip}</div>}
        </div>
      </TooltipDesktop>
    </div>
  );
}
