import { pathOr } from 'ramda';
import * as React from 'react';

import { CeilingHeight } from '../../../components/advancedFilters/CeilingHeight';
import { useContext } from '../../../utils/useContext';

export const CeilingHeightContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const ceilingHeight = pathOr(null, ['ceiling_height', 'value', 'gte'], jsonQuery);

  const handleChange = React.useCallback(
    (ceilingHeight: number | null) => {
      onChange({ action: 'setMinCeilingHeight', arguments: [ceilingHeight] });
    },
    [onChange],
  );

  return <CeilingHeight value={(ceilingHeight !== null && Number(ceilingHeight)) || null} onChange={handleChange} />;
};
