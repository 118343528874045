import { INewbuildingLayoutOffer } from './offer';

export interface INewbuildingLayoutWithOffers {
  /** Параметры для аналитики **/
  gaLabel: string;
  /** Полная ссылка на планировку **/
  imageUrl?: string | null;
  /** Объект параметров запроса в строковом представлении **/
  jsonQuery: string;
  /** Список офферов **/
  offers: INewbuildingLayoutOffer[];
  /** Количество офферов **/
  offersCount: number;
  /** Количество офферов в текстовом представлении **/
  offersCountText: string;
  /** Описание **/
  title: string;
  /** Описание предложений в формате: 2-комн. кв., от 42.7 м² **/
  tourAreaText: string;
  /** Цена в формате: от 16 432 800 ₽ **/
  tourPriceText: string;
  /** Ссылка на 3Д тур **/
  tourUrl?: string | null;
}

export enum ENewbuildingLayoutFetchStatus {
  Initial = 'initial',
  Error = 'error',
  Loading = 'loading',
  Done = 'done',
}

export interface INewbuildingLayoutWithOffersState {
  fetchStatus: ENewbuildingLayoutFetchStatus;
  data: INewbuildingLayoutWithOffers | null;
}
