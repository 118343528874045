import * as React from 'react';

import { EElevators } from '../../../../json_query';
import { IElevatorsMinCountTag } from '../../../state/tags/definitions/elevators_min_count';
import { TagNew } from '../TagNew';

export interface IElevatorsMinCountTagItemProps {
  tag: IElevatorsMinCountTag;
  onDeleteClick(): void;
}

export const ElevatorsMinCountTagItem = ({ tag, onDeleteClick }: IElevatorsMinCountTagItemProps) => {
  if (tag.value === EElevators.Any) {
    return null;
  }

  return <TagNew onDeleteClick={onDeleteClick}>Есть лифт</TagNew>;
};
