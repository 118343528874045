export interface IOfferSettings {
  regionId: number;
  includedInPrice: boolean;
}

export interface IUtilitiesTermsSettings {
  useIncludedUtilitiesTermsRegions: boolean;
  excludedUtilitiesTermsRegions: number[];
  includedUtilitiesTermsRegions: number[];
}

export const isUtilitiesMustIncludedInPrice = (
  offerSettings: IOfferSettings,
  featureToggleSettings: IUtilitiesTermsSettings,
) => {
  const { regionId, includedInPrice } = offerSettings;
  const { useIncludedUtilitiesTermsRegions, includedUtilitiesTermsRegions, excludedUtilitiesTermsRegions } =
    featureToggleSettings;

  if (includedInPrice) {
    return false;
  }

  return useIncludedUtilitiesTermsRegions
    ? includedUtilitiesTermsRegions.includes(regionId)
    : !excludedUtilitiesTermsRegions.includes(regionId);
};
