import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';

import { DownloadExcelModal } from './DownloadExcelModal';
import { IDownloadExcelModalProps } from './types';
import { trackGoToB2bClick, trackGoToProfSearchClick, trackRealtorModalShow } from '../../tracking';
import { DoneList } from '../DoneList';
import { RealtorsFooter } from '../RealtorsFooter';

const doneList = [
  'Без ограничений на скачивание',
  'Поиск объектов по топ-4 площадкам',
  'Формирование подборки с объявлениями',
];

export const DownloadExcelModalRealtors: React.VFC<IDownloadExcelModalProps> = ({ profsearchUrl, ...props }) => {
  return (
    <ActionAfterViewObserver callback={trackRealtorModalShow} callbackDelay={1000} wrapper={false}>
      <DownloadExcelModal
        {...props}
        title="Выгружайте объявления в профпоиске"
        linkButtonText="В профпоиск"
        linkButtonHref={profsearchUrl}
        onLinkBtnClick={trackGoToProfSearchClick}
        doneList={<DoneList items={doneList} />}
        footer={<RealtorsFooter link="/analiz-rynka-nedvizhimosti-b2b/" onLinkClick={trackGoToB2bClick} />}
      />
    </ActionAfterViewObserver>
  );
};
