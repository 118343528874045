import * as React from 'react';

import { TContext, TSaveSearchParams } from '../../types/context';
import { TFetchManifestResult } from '../../types/microfrontend';
import { ISaveSearchModal } from '../../types/saveSearchModal';
import { context as Context } from '../../utils/context';

import type { CianMFUIElement } from '@cian/mf-registry/browser/internal/cianMFUI';
import type { IMicrofrontendManifest } from '@cian/mf-registry/shared';

interface IProviderProps {
  fetchManifest: () => Promise<TFetchManifestResult>;
  params: TSaveSearchParams;
}

export const OPEN_MODAL = '/v1/open-modal';
export const CLOSE_MODAL = '/v1/close-modal';

export const Provider: React.FC<React.PropsWithChildren<IProviderProps>> = ({ children, fetchManifest, params }) => {
  const microfrontendRef = React.useRef<CianMFUIElement | null>(null);
  const [manifest, setManifest] = React.useState<IMicrofrontendManifest | null>(null);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [subscribeToPushAvailable, setSubscribeToPushAvailable] = React.useState(false);

  const getLocalApi = React.useCallback(() => {
    const api = (microfrontendRef.current?.instance?.customElement as ISaveSearchModal)?.api;
    if (!api) {
      throw new Error('Не удалось получить доступ к локальному API микрофронтенда "save-search-microfrontend"');
    }

    return api;
  }, []);

  const subscribeToPush = React.useCallback(
    (isSubscribeAvailable: boolean) => {
      setSubscribeToPushAvailable(isSubscribeAvailable);
    },
    [setSubscribeToPushAvailable],
  );

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpen = React.useCallback(async () => {
    setOpen(true);

    if (!manifest) {
      setLoading(true);
      const result = await fetchManifest();
      setLoading(false);

      if (result) {
        setManifest(result.manifest);
      } else {
        return;
      }

      // Ждём пока микрофронт замаунтится и будет готов к тому чтобы мы постучались к инстансу.
      // Сейчас это необходимо для доступа к локальному API.
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    getLocalApi()[OPEN_MODAL]();
  }, [fetchManifest, getLocalApi, manifest]);

  const context = React.useMemo<TContext>(() => {
    const commonParameters = {
      microfrontendRef,
      params: {
        ...params,
        subscribeToPushAvailable,
      },
      open,
      onOpen: handleOpen,
      onClose: handleClose,
      subscribeToPush,
    };

    if (loading) {
      return {
        ...commonParameters,
        status: 'loading',
      };
    }

    if (!manifest) {
      return {
        ...commonParameters,
        status: 'failed',
      };
    }

    return {
      ...commonParameters,
      manifest,
      status: 'succeed',
    };
  }, [open, handleOpen, handleClose, subscribeToPush, loading, manifest, subscribeToPushAvailable, params]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
};
