interface IScrollTo {
  containerElement: HTMLElement;
  targetElement: HTMLElement;
  center: boolean;
}

export const scrollTo = (params: IScrollTo) => {
  const containerElementClientHeight = params.containerElement.clientHeight;
  const containerElementScrollTop = params.containerElement.scrollTop;
  const targetElementOffsetTop = params.targetElement.offsetTop;
  const targetElementScrollHeight = params.targetElement.scrollHeight;

  if (params.center) {
    params.containerElement.scrollTop =
      targetElementOffsetTop - containerElementClientHeight / 2 + targetElementScrollHeight / 2;
  } else {
    const containerBottom = containerElementScrollTop + containerElementClientHeight;
    const valueBottom = targetElementOffsetTop + targetElementScrollHeight;

    if (valueBottom > containerBottom) {
      params.containerElement.scrollTop = valueBottom - containerElementClientHeight;
    } else if (targetElementOffsetTop < containerElementScrollTop) {
      params.containerElement.scrollTop = targetElementOffsetTop;
    }
  }
};
