import { FOfferType, isSuburban } from '../../../../../../JsonQuery';
import { EOfferSeller } from '../../../../types/jsonQuery';

interface IOfferSellerOption {
  label: string;
  value: EOfferSeller | null;
}

export const COMMON_OPTIONS: IOfferSellerOption[] = [
  { label: 'Неважно', value: null },
  { label: 'Собственник', value: EOfferSeller.Homeowner },
  { label: 'Агент', value: EOfferSeller.Agent },
];

export const FLAT_SELLER_OPTIONS: IOfferSellerOption[] = [
  ...COMMON_OPTIONS,
  { label: 'Застройщик', value: EOfferSeller.Builder },
];

export const SUBURBAN_SELLER_OPTIONS: IOfferSellerOption[] = FLAT_SELLER_OPTIONS;

export const getOptionsByOfferType = (offerType: FOfferType) => {
  switch (true) {
    case offerType === FOfferType.Flat:
      return FLAT_SELLER_OPTIONS;

    case isSuburban(offerType):
      return SUBURBAN_SELLER_OPTIONS;

    default:
      return COMMON_OPTIONS;
  }
};
