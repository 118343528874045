import { ca } from '@cian/analytics';

export function trackButtonClick(newbuildingId: number) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'Click',
    label: 'corpus_filter',
    products: [
      {
        id: newbuildingId,
        offerType: 'JK',
      },
    ],
  });
}
