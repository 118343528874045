import { ModalWindow, Spinner } from '@cian/ui-kit';
import * as React from 'react';

import { Direction } from './direction';
import { DirectionsModalHeader } from './header';
import { IJsonQueryRangeValue } from '../../../../json_query';
import { IDirection } from '../../../../types/direction';
import { SearchButtonContainer } from '../../search_button/container';

const style = require('./index.css');

export interface IDirectionModalProps {
  directions: IDirection[];
  fromMKAD: IJsonQueryRangeValue;
  isLoading: boolean;
  selectedHighwayIds: number[];
  label: string;
  changeMinFromMKAD(value: number | undefined): void;
  changeMaxFromMKAD(value: number | undefined): void;
  close(): void;
  deselectHighways(highwaysIds: number[]): void;
  requestDirectionsData(): void;
  searchBySelectedHighways(): void;
  selectHighways(highwaysIds: number[]): void;
}

export class DirectionsModal extends React.Component<IDirectionModalProps> {
  public componentDidMount() {
    const { directions, requestDirectionsData } = this.props;

    if (!directions.length) {
      requestDirectionsData();
    }
  }

  public render() {
    const {
      changeMinFromMKAD,
      changeMaxFromMKAD,
      close,
      directions,
      fromMKAD,
      isLoading,
      label,
      searchBySelectedHighways,
    } = this.props;

    return (
      <ModalWindow
        fixed
        size="M"
        escape
        width="100%"
        maxWidth={1300}
        open
        onClose={close}
        header={
          <DirectionsModalHeader
            fromMKAD={fromMKAD}
            onChangeMin={changeMinFromMKAD}
            onChangeMax={changeMaxFromMKAD}
            label={label}
          />
        }
        footer={
          <div className={style['footer']}>
            <SearchButtonContainer onClick={searchBySelectedHighways} />
          </div>
        }
      >
        {directions.length > 0 && this.renderColumns()}
        {isLoading && (
          <div className={style['preloader-wrapper']}>
            <Spinner size={50} />
          </div>
        )}
      </ModalWindow>
    );
  }

  private renderColumns = () => {
    return (
      <div className={style['directions']}>
        {this.getColumns().map((column, index) => (
          <div key={`directions-modal-column-${index}`} className={style['directions-column']}>
            {this.renderColumn(column)}
          </div>
        ))}
      </div>
    );
  };

  private renderColumn(column: IDirection[]) {
    return column.map(({ name, highways }, index) => (
      <Direction
        key={`directions-modal-item-${index}`}
        name={name}
        highways={highways}
        selectHighways={this.props.selectHighways}
        deselectHighways={this.props.deselectHighways}
        selectedHighwayIds={this.props.selectedHighwayIds}
      />
    ));
  }

  private getDirectionsSize = (direction: IDirection[]) => {
    // Высота заголовка относительно детей
    const REL_TITLE_HEIGHT = 2.5;

    // Размер колонки с учётом количества шоссе и названия направления
    return direction.reduce((prev, curr) => {
      return prev + curr.highways.length + REL_TITLE_HEIGHT;
    }, 0);
  };

  private getColumnSize = () => {
    const COLUMNS_COUNT = 4;
    const allDirectionsSize = this.getDirectionsSize(this.props.directions);

    return Math.round(allDirectionsSize / COLUMNS_COUNT);
  };

  /**
   * Возвращает отсортированный по убыванию массив направлений
   */
  private getSortedDirections = () => {
    const directionsClone = Array.from(this.props.directions);

    return directionsClone.sort((a, b) => {
      return b.highways.length - a.highways.length;
    });
  };

  private getColumns() {
    const directions = this.getSortedDirections();
    const columnSize = this.getColumnSize();
    const columns: IDirection[][] = [];

    directions.forEach(direction => {
      const index = columns.findIndex(column => {
        const currentColumnSize = this.getDirectionsSize(column);

        return currentColumnSize + direction.highways.length <= columnSize;
      });

      if (index >= 0) {
        columns[index].push(direction);
      } else {
        columns.push([direction]);
      }
    });

    return columns;
  }
}
