import { selectJsonQuery } from './selectJsonQuery';
import { IAppState } from '../../common/state';
import { getRailwayAndHighwayPuids } from '../../utils/adfox/getRailwayAndHighwayPuids';

export const selectResultPuids = (state: IAppState): Record<string, string | null | undefined> => {
  const jsonQuery = selectJsonQuery(state);

  const puids: Record<string, string | null | undefined> = {
    ...getRailwayAndHighwayPuids(jsonQuery),
    ...state.results.puids,
  };

  const commercialReg = /commercial$/gm;

  /**
   * @description Сейчас существует баг, что для гаражей приходит puid2 = offer_commercial, а не offer_flat
   * Это происходит из-за того, что когда гаражи переезжали из коммерческой в жилую,
   * не поменяли отправляемый в jsonQuery _type
   */
  if (puids.puid2 && puids.puid2.match(commercialReg)) {
    puids.puid2 = puids.puid2.replace(commercialReg, 'flat');
  }

  if (state.isQaMode) {
    puids.puid32 = 'test';
  }

  return puids;
};
