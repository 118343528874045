import { IAppState, TActions } from '../../../common/state';
import { ERentTime } from '../../../json_query';

const IRentTimeChangedActionType = 'filters/rent_time/RENT_TIME_CHANGED';

export interface IRentTimeChangedAction {
  type: 'filters/rent_time/RENT_TIME_CHANGED';
  rentTime: ERentTime;
}

export function changeRentTime(rentTime: ERentTime): IRentTimeChangedAction {
  return {
    type: IRentTimeChangedActionType,
    rentTime,
  };
}

export function rentTimeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IRentTimeChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            for_day: {
              type: 'term',
              value: action.rentTime,
            },
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-rentTime') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              for_day: {
                type: 'term',
                value: ERentTime.NotDaily,
              },
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
}
