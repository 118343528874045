import { ModalWindow, Spinner } from '@cian/ui-kit';
import * as React from 'react';

import { MoscowDistrictsHeader } from './header/';
import { DistrictsLayout, isMoscowLocation } from './layout';
import { trackSaveDistrictsEvent } from './tracking';
import { IGeoDistrict } from '../../../api/geo';
import { IJsonQueryDistrict } from '../../../json_query';
import { TLocation } from '../../../types/location';
import { SearchButtonContainer } from '../search_button/container';

const style = require('./index.css');

export interface IStateProps {
  isDataLoading: boolean;
  districts: IGeoDistrict[] | undefined;
  selectedLocation: TLocation;
  selectedDistricts: IJsonQueryDistrict[];
  includeNewMoscow: boolean | undefined;
}

export interface IDispatchProps {
  onClose(): void;
  onSave(): void;
  onSelected(id: number, name: string): void;
  onNewMoscowSwitched(includeNewMoscow: boolean | undefined): void;
  onRemoved(id: number): void;
  onSearchButtonClick(): void;
}

export type TDistrictsModalProps = IStateProps & IDispatchProps;

export class DistrictsModal extends React.Component<TDistrictsModalProps> {
  public render() {
    return (
      <ModalWindow
        fixed
        size="M"
        escape
        width="100%"
        maxWidth={1300}
        open
        onClose={this.props.onClose}
        header={
          isMoscowLocation(this.props.selectedLocation) && (
            <MoscowDistrictsHeader
              includeNewMoscow={this.props.includeNewMoscow}
              onNewMoscowSwitched={this.props.onNewMoscowSwitched}
            />
          )
        }
        footer={
          <div className={style.footer}>
            <SearchButtonContainer onClick={this.handleClickOnSearchButton} />
          </div>
        }
      >
        <div>
          {this.props.isDataLoading && (
            <div className={style.preloaderWrapper}>
              <Spinner size={50} />
            </div>
          )}
          {this.props.districts && this.renderDistricts(this.props.districts)}
        </div>
      </ModalWindow>
    );
  }

  private renderDistricts(districts: IGeoDistrict[]) {
    return (
      <div className={style.districts}>
        <DistrictsLayout
          areas={districts}
          selectedLocation={this.props.selectedLocation}
          selectedDistricts={this.props.selectedDistricts}
          onSelected={this.props.onSelected}
          onRemoved={this.props.onRemoved}
        />
      </div>
    );
  }

  private handleClickOnSearchButton = () => {
    trackSaveDistrictsEvent(this.props.selectedDistricts.length);
    this.props.onSearchButtonClick();
  };
}
