import * as React from 'react';

import { TagNew } from '../TagNew';

export interface IWithPhotoTagItemProps {
  onDeleteClick(): void;
}

export const WithPhotoTagItem = (props: IWithPhotoTagItemProps) => {
  return <TagNew onDeleteClick={props.onDeleteClick}>С фото</TagNew>;
};
