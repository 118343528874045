import * as R from 'ramda';

import { getRangeValue, setTerm, setRangeMin, resetTerms } from './helpers';
import { NonEmptyArray, EBalconyType, TJsonQuery } from './types';

export function setBalconyTypes(jsonQuery: TJsonQuery) {
  return (balconyTypes: NonEmptyArray<EBalconyType> | null): TJsonQuery => {
    let nextJsonQuery = R.clone(jsonQuery);

    nextJsonQuery = resetTerms(['balconies', 'loggia'])(nextJsonQuery);

    if (balconyTypes) {
      if (balconyTypes.includes(EBalconyType.Balcony)) {
        const balconies = getRangeValue('balconies')(jsonQuery);

        nextJsonQuery = setRangeMin('balconies')(nextJsonQuery)((balconies && balconies.gte) || 1);
      }

      if (balconyTypes.includes(EBalconyType.Loggia)) {
        nextJsonQuery = setTerm('loggia')(nextJsonQuery)(true);
      }
    }

    return nextJsonQuery;
  };
}
