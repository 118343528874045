import { ElectronicTradingWidgetProps } from '@cian/frontend-electronic-trading-widget';
import * as React from 'react';

import { useGetIsElectronicTrading } from '../../../../../../../shared/hooks/useGetIsElectronicTrading';
import { useApplicationContext } from '../../../../../../../shared/utils/applicationContext';
import { JoinElectronicTradingButton } from '../../../../components/JoinElectronicTradingButton/JoinElectronicTradingButton';
import {
  trackJoinTradeClick,
  trackShowTradingWidgetModalClick,
} from '../../../../components/JoinElectronicTradingButton/tracking';
import { useOfferContext } from '../../../../context';
import { formatPhone } from '../AgentPhoneButtonContainer';

export const JoinElectronicTradingButtonContainer: React.FC = () => {
  const { config } = useApplicationContext();
  const { agentType, offer, agentImageLink, agentName } = useOfferContext();

  const isElectronicTrading = useGetIsElectronicTrading();

  const legalLink = config.getStrict<string>('electronicTrading.legalDocsUrl');

  const trackJoinTrade = React.useCallback((): void => {
    trackJoinTradeClick(offer);
  }, [offer]);

  const trackShowTradingWidgetModal = React.useCallback((): void => {
    trackShowTradingWidgetModalClick(offer);
  }, [offer]);

  /**
   * Эти события трекать не требуется. Добавить, если требования поменяются.
   */
  const trackLegalLinkClick = React.useCallback((): void => {}, []);
  const trackPublisherPhoneNumberClick = React.useCallback((): void => {}, []);

  const widgetProps = React.useMemo((): ElectronicTradingWidgetProps => {
    const prettyPhones = offer.phones?.map(formatPhone) || [];

    return {
      deviceType: 'desktop',
      joinTradeButtonLink: offer.tradingLink || '',
      legalLink,
      publisherAvatarSrc: agentImageLink || offer.user?.agentAvatarUrl || null,
      publisherName: agentName || offer.user?.agencyName || '',
      publisherPhoneNumbers: prettyPhones,
      publisherTypeLabel: null,
      trackJoinTrade,
      trackLegalLinkClick,
      trackPublisherPhoneNumberClick,
    };
  }, [
    legalLink,
    agentType,
    agentImageLink,
    agentName,
    offer.tradingLink,
    offer.phones,
    offer.user,
    trackJoinTrade,
    trackLegalLinkClick,
    trackPublisherPhoneNumberClick,
  ]);

  if (!isElectronicTrading) {
    return null;
  }

  return <JoinElectronicTradingButton {...widgetProps} trackShowTradingWidgetModal={trackShowTradingWidgetModal} />;
};
