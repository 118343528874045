import * as React from 'react';

import { EBedrooms } from '../../../types/bedrooms';
import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';

interface IBedrooms {
  label: string;
  value: EBedrooms | null;
}

const BEDROOMS_OPTIONS: IBedrooms[] = [
  { label: 'Неважно', value: null },
  { label: 'от 1', value: EBedrooms.OneAndMore },
  { label: 'от 2', value: EBedrooms.TwoAndMore },
  { label: 'от 3', value: EBedrooms.ThreeAndMore },
  { label: 'от 4', value: EBedrooms.FourAndMore },
  { label: 'от 5', value: EBedrooms.FiveAndMore },
];

interface IElevatorsProps {
  value: EBedrooms | null;
  onChange(value: EBedrooms | null): void;
}

export const Bedrooms: React.FC<IElevatorsProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_bedrooms" label="Спален в доме">
      <RadioButtonGroup options={BEDROOMS_OPTIONS} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
