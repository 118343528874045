import { connect } from 'react-redux';

import { MapFooter } from './index';
import { IAppState, Dispatch } from '../../../../common/state';
import { applyMapModal } from '../../../state/map/save_modal';

export const MapFooterContainer = connect(
  (state: IAppState) => {
    const { geo } = state.filters.jsonQuery;

    return {
      mapRegions: (geo && geo.value.filter(val => val.type === 'polygon' || val.type === 'distance').length) || 0,
    };
  },
  (dispatch: Dispatch) => ({
    applyMapModal: () => {
      dispatch(applyMapModal());
    },
  }),
)(MapFooter);
