import { IAppState, TActions } from '../../common/state';
import { FDealType, FOfferType } from '../../utils/category';
import { getRegionIds, MoscowId } from '../../utils/geo';

const removeDomrfFilter = (state: IAppState): IAppState => ({
  ...state,
  filters: {
    ...state.filters,
    jsonQuery: {
      ...state.filters.jsonQuery,
      domrf: undefined,
    },
  },
});

export function domrfReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type !== 'domrf') {
        return state;
      }

      return removeDomrfFilter(state);

    case 'filters/deal_type/DEAL_TYPE_CHANGED':
      if (action.value & FDealType.RentLongterm) {
        return state;
      }

      return removeDomrfFilter(state);

    case 'filters/offer_type/OFFER_TYPE_SELECTED':
      if (action.offerType & FOfferType.Flat) {
        return state;
      }

      return removeDomrfFilter(state);

    case 'filters/location_switcher/REGION_SELECTED': {
      const region = action.region;
      if (region && getRegionIds(region).includes(MoscowId)) {
        return state;
      }

      return removeDomrfFilter(state);
    }

    default:
      return state;
  }
}
