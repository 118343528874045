import { ModalWindow } from '@cian/ui-kit';
import loadable from '@loadable/component';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackMetroModalOpenEvent } from './tracking';
import { LoadableErrorBoundary, LoadableFallback } from '../../../packages/LoadableHelpers';
import { UndergroundMapModalFooter } from '../../common/components/UndergroundMap/UndergroundMapModalFooter';
import { IAppState } from '../../common/state';
import { MetroModalHeader } from '../../filters/components/metro/header';
import { SearchButtonContainer } from '../../filters/components/search_button/container';
import { removeMetro, selectMetro } from '../../filters/state/metro/changed';
import { applyMetroModal } from '../../filters/state/metro/save_modal';
import { closeMetroModal } from '../../filters/state/metro/toggle_modal';
import { changeUndergroundTravelWithSearch } from '../../filters/state/underground_travel';
import { EMetroTravelType } from '../../json_query';
import { selectTravelTime } from '../../selectors/filters/selectTravelTime';
import { selectTravelType } from '../../selectors/filters/selectTravelType';
import { selectUndergroundStations } from '../../selectors/filters/selectUndergroundStations';
import { selectLocationIdForMetroMap } from '../../selectors/selectLocationIdForMetroMap';
import { fetchUndergroundMapData } from '../../services/fetchUndergroundMapData';
import { TThunkDispatch } from '../../types/redux';
import { IStationData } from '../../types/undergroundStationData';
import { useApplicationContext } from '../../utils/applicationContext';
import { UndergroundMapLineSelector } from '../UndergroundMapLineSelector';

import type { IUndergroundMapApi } from '@cian/underground-map-widget';

const UndergroundMapContainerLoadable = loadable(() => import('./UndergroundMapContainer'), {
  fallback: <LoadableFallback spinnerSize={42} />,
  ssr: false,
});

export const UndergroundMapModalContainer: React.VFC = () => {
  const ctx = useApplicationContext();
  const dispatch = useDispatch<TThunkDispatch>();
  const undergroundMapApiRef = React.useRef<IUndergroundMapApi>(null);
  const open = useSelector<IAppState, boolean>(state => state.isMetroModalVisible);
  const selectedStations = useSelector(selectUndergroundStations);
  const locationId = useSelector(selectLocationIdForMetroMap);
  const travelTime = useSelector(selectTravelTime);
  const travelType = useSelector(selectTravelType);

  const handleRemove = React.useCallback(
    (stations: IStationData[]) => {
      dispatch(removeMetro(stations));
    },
    [dispatch],
  );

  const handleSelect = React.useCallback(
    (stations: IStationData[]) => {
      dispatch(selectMetro(stations));
    },
    [dispatch],
  );

  const handleClose = React.useCallback(() => {
    dispatch(closeMetroModal());
  }, [dispatch]);

  const handleTravelInfoChange = (travelType: EMetroTravelType, newTravelTime?: number) => {
    dispatch(changeUndergroundTravelWithSearch(travelType, newTravelTime));
  };

  const handleApplyUnderground = () => {
    dispatch(applyMetroModal());
  };

  const handleFetchData = React.useCallback((id: number) => fetchUndergroundMapData(id, ctx), [ctx]);

  const handleRetry = () => UndergroundMapContainerLoadable.load();

  React.useEffect(() => {
    if (open) {
      trackMetroModalOpenEvent();
    }
  }, [open]);

  return (
    <ModalWindow
      fixed
      size="M"
      escape
      width="100%"
      maxWidth={1300}
      open={open}
      onClose={handleClose}
      header={
        <MetroModalHeader
          fastSelectionButtons={<UndergroundMapLineSelector api={undergroundMapApiRef} />}
          travelType={travelType}
          travelTime={travelTime}
          onUndergroundTravelChanged={handleTravelInfoChange}
        />
      }
      footer={
        <UndergroundMapModalFooter>
          <SearchButtonContainer onClick={handleApplyUnderground} />
        </UndergroundMapModalFooter>
      }
    >
      <LoadableErrorBoundary logger={ctx.logger} onRetry={handleRetry}>
        <UndergroundMapContainerLoadable
          apiRef={undergroundMapApiRef}
          locationId={locationId}
          onDataRequested={handleFetchData}
          onRemove={handleRemove}
          onSelect={handleSelect}
          selectedStations={selectedStations}
        />
      </LoadableErrorBoundary>
    </ModalWindow>
  );
};
