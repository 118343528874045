import * as React from 'react';

import { TagNew } from '../TagNew';

export interface IBalconyTagItemProps {
  onDeleteClick(): void;
}

export const BalconyTagItem = ({ onDeleteClick }: IBalconyTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>Есть балкон</TagNew>;
};
