import * as React from 'react';

import { EParkingType, NonEmptyArray } from '../../../../../JsonQuery';
import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { Filter } from '../common/Filter';

export interface IParking {
  label: string;
  value: EParkingType | null;
}

const PARKING_TYPES: IParking[] = [
  { label: 'Неважно', value: null },
  { label: 'Наземная', value: EParkingType.Ground },
  { label: 'Многоуровневая', value: EParkingType.Multilevel },
  { label: 'Подземная', value: EParkingType.Underground },
];

interface IParkingProps {
  value: NonEmptyArray<EParkingType> | null;
  onChange(value: NonEmptyArray<EParkingType> | null): void;
}

export const Parking: React.FC<IParkingProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_parking" label="Парковка">
      <CheckboxButtonGroupUnselectable options={PARKING_TYPES} value={value} onChange={onChange} />
    </Filter>
  );
};
