import { numberToPrettyString } from '@cian/utils';

import { getCurrencySign } from './getCurrencySign';
import { IOffer } from '../../../types/offer';

export function getAgentBonusPrice(offer: IOffer, isAgent: boolean): string | undefined {
  const { agentBonus } = offer.bargainTerms;
  const isSale = offer.dealType === 'sale';

  if (isAgent && agentBonus && isSale) {
    let bonus = numberToPrettyString(agentBonus.value);

    if (agentBonus.paymentType === 'percent') {
      bonus += '%';

      return `Бонус агенту ${bonus}`;
    }

    if (agentBonus.currency === 'rur') {
      bonus += `\u00A0${getCurrencySign(agentBonus.currency)}`;
    } else {
      bonus = `${getCurrencySign(agentBonus.currency || '')}${bonus}`;
    }

    return `Бонус агенту ${bonus}`;
  }

  return undefined;
}
