import React from 'react';

import { ContentRow } from '../../../components/ContentRow';
import { AuctionTitleContainer } from '../AuctionTitleContainer';
import { GeoContainer } from '../GeoContainer';
import { IdenticalOffersLinkContainer } from '../IdenticalOffersLinkContainer';
import { LabelsListContainer } from '../LabelsListContainer';
import { PriceContainer } from '../PriceContainer';
import { RatingContainer } from '../RatingContainer';
import { ResizableContainer } from '../ResizableContainer';
import { SimilarLinkContainer } from '../SimilarLinkContainer';
import { TitleContainer } from '../TitleContainer';

interface IOfferGeneralInfoContainerProps {
  isNewHighlighting: boolean;
  mortgageBanner?: React.ReactNode;
}

export function OfferGeneralInfoContainer({ isNewHighlighting, mortgageBanner }: IOfferGeneralInfoContainerProps) {
  return (
    <>
      <TitleContainer />
      <LabelsListContainer />
      <RatingContainer />
      <GeoContainer />
      <AuctionTitleContainer />
      <PriceContainer
        isNewHighlighting={isNewHighlighting}
        MortgageAdFoxBanner={<ContentRow>{mortgageBanner}</ContentRow>}
      />
      <ResizableContainer />
      <SimilarLinkContainer />
      <IdenticalOffersLinkContainer />
    </>
  );
}
