"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isObserverSupported = function () {
    if (typeof window !== 'undefined' && 'IntersectionObserver' in window) {
        return true;
    }
    return false;
};
function isHTMLElement(element) {
    return element instanceof HTMLElement;
}
exports.isHTMLElement = isHTMLElement;
