import { clone } from 'ramda';

import { setTerm } from './helpers';
import { TJsonQuery } from './types';

export function setIsEncumbranceRealty(jsonQuery: TJsonQuery) {
  return (isEncumbranceRealty: boolean | null): TJsonQuery => {
    const nextJsonQuery = clone(jsonQuery);

    return setTerm('is_encumbrance_realty')(nextJsonQuery)(isEncumbranceRealty);
  };
}
