import * as React from 'react';

import { TagNew } from '../TagNew';

export interface IElevatorsHasServiceTagItemProps {
  onDeleteClick(): void;
}

export const ElevatorsHasServiceTagItem = ({ onDeleteClick }: IElevatorsHasServiceTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>Есть грузовой лифт</TagNew>;
};
