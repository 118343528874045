import * as React from 'react';

import * as styles from './styles.css';

interface IComparisonControlTooltipProp {
  text: string | null;
}

export const ComparisonControlTooltip: React.FC<IComparisonControlTooltipProp> = ({ text }) => {
  return <div className={styles['container']}>{text}</div>;
};
