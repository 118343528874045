import { IConfig } from '@cian/config/shared';
import { ILogger } from '@cian/logger';

import { TNewbuildingEntryType } from '../types/newbuildingEntryBanner';

export interface INewbuildingEntryBannerInfo {
  description: string;
  title: string;
  url?: string;
}

export type TNewbuildingEntryBannerInfoObject = Record<TNewbuildingEntryType, INewbuildingEntryBannerInfo>;

export function getNewbuildingEntryBannerInfo(
  type: TNewbuildingEntryType,
  config: IConfig,
  logger: ILogger,
): INewbuildingEntryBannerInfo {
  let bannerInfo = config.get<TNewbuildingEntryBannerInfoObject>('frontend-serp.newbuildingEntryBannerInfo');

  if (!bannerInfo) {
    logger.error('frontend-serp.newbuildingEntryBannerInfo is not defined', {
      domain: 'getNewbuildingEntryBannerInfo',
    });

    bannerInfo = {
      mixed: {
        title: 'Новая квартира в современном доме',
        description: 'Новостройки с выгодными ставками по ипотеке',
      },
      mixed_promo: {
        title: 'Возьмите выгодную ипотеку',
        description: 'Льготная ипотека и специальные предложения',
      },
      resale: {
        title: 'Похожие квартиры в новостройках рядом',
        description: 'Новые жилые комплексы с выгодными ставками по ипотеке',
      },
    };
  }

  return bannerInfo[type];
}
