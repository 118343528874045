import { isSeoSuggestAvailable } from './seo/isSeoSuggestAvailable';
import { TComputedPropMapper } from '../../types/computed-props-mapper';

export const seoSuggestionDistance: TComputedPropMapper<string | undefined> = ({ offer, jsonQuery }) => {
  const { suggestionDistances } = offer;

  if (!isSeoSuggestAvailable(offer, jsonQuery) || suggestionDistances === undefined) {
    return undefined;
  }

  return suggestionDistances > 0 ? ` ${String(suggestionDistances).replace('.', ',')}\u00a0км` : ' менее 100 метров';
};
