import { pathOr } from 'ramda';
import * as React from 'react';

import { getEnumValue } from './utils/getEnumValue';
import { SuburbanFloors } from '../../../components/advancedFilters/SuburbanFloors';
import { IJsonQueryRangeValue } from '../../../types/jsonQuery';
import { ESuburbanFloors } from '../../../types/suburbanFloors';
import { useContext } from '../../../utils/useContext';

export const SuburbanFloorsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const floors = pathOr<IJsonQueryRangeValue>({ gte: undefined, lte: undefined }, ['floorn', 'value'], jsonQuery);

  const value = React.useMemo(() => getEnumValue(floors), [floors]);

  const handleFloorsChange = React.useCallback(
    (floors: ESuburbanFloors | null) => {
      switch (floors) {
        case ESuburbanFloors.One:
          onChange({ action: 'setFloorsMin', arguments: [1] }, { action: 'setFloorsMax', arguments: [1] });
          break;

        case ESuburbanFloors.TwoAndMore:
          onChange({ action: 'setFloorsMin', arguments: [2] }, { action: 'setFloorsMax', arguments: [null] });
          break;

        default:
          onChange({ action: 'setFloorsMin', arguments: [null] }, { action: 'setFloorsMax', arguments: [null] });
      }
    },
    [onChange],
  );

  return <SuburbanFloors value={value} onChange={handleFloorsChange} />;
};
