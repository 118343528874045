import React, { memo } from 'react';

import { AdFoxBanner } from '../../../../../../components/AdfoxBanner';
import { AdfoxContextData } from '../../../../../../types/adfox/AdfoxContextData';
import { AdfoxContextProvider, PParams } from '../../../../../../utils/AdfoxContext';

const POST_VIEW_ANALYTICS_DATA: AdfoxContextData['postViewAnalyticsData'] = null;
const SIZE: AdfoxContextData['size'] = { width: '100%', minHeight: '200px' };
const P_PARAMS: PParams = { p1: 'czsow', p2: 'y' };

type SuburbanListingBannerProps = {
  position: number;
};

export const SuburbanListingBanner = memo<SuburbanListingBannerProps>(function SuburbanListingBanner(props) {
  const { position } = props;

  return (
    <AdfoxContextProvider size={SIZE} pParams={P_PARAMS} postViewAnalyticsData={POST_VIEW_ANALYTICS_DATA}>
      <AdFoxBanner position={position} />
    </AdfoxContextProvider>
  );
});
