import { PROPERTIES } from './properties';
import { TJsonQuery, TJsonQueryKeys } from './types';

export function convertProperties(jsonQuery: TJsonQuery): TJsonQuery {
  const keys = Object.keys(jsonQuery) as TJsonQueryKeys[];

  const nextJsonQuery = keys.reduce((nextJsonQuery, currentKey) => {
    if (!!nextJsonQuery[currentKey] && PROPERTIES[currentKey]) {
      const { converter } = PROPERTIES[currentKey];
      if (converter) {
        return converter(nextJsonQuery);
      }
    }

    return nextJsonQuery;
  }, jsonQuery);

  return nextJsonQuery;
}
