import * as React from 'react';

import { TagNew } from '../TagNew';

export interface IPromoTagItemProps {
  onDeleteClick(): void;
}

export const PromoTagItem = ({ onDeleteClick }: IPromoTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>По акции</TagNew>;
};
