import { IGetNewbuildingLayoutWithOffersResponse } from '../../repositories/search-offers/v1/get-newbuilding-layout-with-offers';
import { INewbuildingLayoutWithOffers } from '../../types/residentialComplexLayouts/layoutWithOffers/layoutWithOffers';

export const prepareNewbuildingLayoutWithOffers = (
  response: IGetNewbuildingLayoutWithOffersResponse,
): INewbuildingLayoutWithOffers | null => {
  if (!response || !response.layout) {
    return null;
  }

  return response.layout;
};
