import { call, put, select, take } from 'redux-saga/effects';

import { TActions } from '../../../common/state/actions';
import { IAppState, Dispatch } from '../../../common/state/app_state';
import { jq } from '../../../json_query';
import { ILocation, TLocation } from '../../../types/location';
import { IRequestCitiesResponse, requestCities } from '../../api';
import { saveUserInput } from '../geo';
import { makeSearch } from '../search';

export interface IRegionSelectionRequestedAction {
  type: 'filters/location_switcher/REGION_SELECTION_REQUESTED';
  region: TLocation;
}

export interface ICountrySelectedAction {
  type: 'filters/location_switcher/COUNTRY_SELECTED';
}

export interface ICitySelectedAction {
  type: 'filters/location_switcher/CITY_SELECTED';
  city: ILocation;
}

export interface IRegionSelectedAction {
  type: 'filters/location_switcher/REGION_SELECTED';
  region: TLocation;
  cities: ILocation[];
}

export type TAppliedLocation = TLocation | undefined;

export interface ILocationAppliedAction {
  type: 'filters/location_switcher/LOCATION_APPLIED';
  location: TAppliedLocation;
}

export function selectCountry(): ICountrySelectedAction {
  return {
    type: 'filters/location_switcher/COUNTRY_SELECTED',
  };
}

export function selectRegion(region: TLocation): IRegionSelectionRequestedAction {
  return {
    type: 'filters/location_switcher/REGION_SELECTION_REQUESTED',
    region,
  };
}

export function selectCity(city: ILocation): ICitySelectedAction {
  return {
    type: 'filters/location_switcher/CITY_SELECTED',
    city,
  };
}

export function applySelectedLocation() {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const location = state.filters.locationSwitcher.selectedCity || state.filters.locationSwitcher.selectedRegion;

    dispatch({
      type: 'filters/location_switcher/LOCATION_APPLIED',
      location,
    } as ILocationAppliedAction);

    dispatch(saveUserInput(''));

    dispatch(makeSearch());
  };
}

export function* locationSwitcherRegionSelectionSaga() {
  while (true) {
    const action: IRegionSelectionRequestedAction = yield take([
      'filters/location_switcher/AUTOCOMPLETE_SUGGESTION_REGION_SELECTION_REQUESTED',
      'filters/location_switcher/REGION_SELECTION_REQUESTED',
    ]);

    const state: IAppState = yield select();

    let cities: ILocation[] = [];
    if (action.region !== 'moscow_mo' && action.region !== 'spb_lo') {
      const result: IRequestCitiesResponse = yield call(requestCities, state.makeRequest, action.region.id);
      cities = result.items;
    }

    yield put({
      type: 'filters/location_switcher/REGION_SELECTED',
      region: action.region,
      cities,
    } as IRegionSelectedAction);
  }
}

export function locationSwitcherReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/location_switcher/REGION_SELECTION_REQUESTED':
    case 'filters/location_switcher/AUTOCOMPLETE_SUGGESTION_REGION_SELECTION_REQUESTED':
      return {
        ...state,
        filters: {
          ...state.filters,
          locationSwitcher: {
            ...state.filters.locationSwitcher,
            isCitiesLoading: true,
          },
        },
      };

    case 'filters/location_switcher/REGION_SELECTED':
      return {
        ...state,
        filters: {
          ...state.filters,
          locationSwitcher: {
            ...state.filters.locationSwitcher,
            isCitiesLoading: false,
            selectedRegion: action.region,
            cities: action.cities,
            selectedCity: undefined,
          },
          jsonQuery: jq(state.filters.jsonQuery).setLocation(action.region, state.filters.currentLocation),
        },
      };

    case 'filters/location_switcher/CITY_SELECTED':
      return {
        ...state,
        filters: {
          ...state.filters,
          locationSwitcher: {
            ...state.filters.locationSwitcher,
            selectedCity: action.city,
          },
          jsonQuery: jq(state.filters.jsonQuery).setLocation(action.city, state.filters.currentLocation),
        },
      };

    case 'filters/location_switcher/COUNTRY_SELECTED':
      return {
        ...state,
        filters: {
          ...state.filters,
          locationSwitcher: {
            isLocationSwitcherOpened: true,
            selectedRegion: undefined,
            selectedCity: undefined,
            cities: undefined,
            isCitiesLoading: false,
            autocompleteSuggestions: state.filters.locationSwitcher.autocompleteSuggestions,
            isAutocompleteLoading: state.filters.locationSwitcher.isAutocompleteLoading,
          },
        },
      };

    case 'filters/location_switcher/LOCATION_APPLIED':
      return {
        ...state,
        filters: {
          ...state.filters,
          currentLocation: action.location || state.filters.currentLocation,
          jsonQuery: jq(state.filters.jsonQuery).setLocation(
            action.location || state.filters.currentLocation,
            state.filters.currentLocation,
          ),
        },
      };

    default:
      return state;
  }
}
