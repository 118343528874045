import { sort } from 'ramda';

import { EMediaType, IMedia } from '../../../../../shared/components/Carousel';
import { TComputedPropMapper } from '../../types/computed-props-mapper';
import { IOfferPhoto } from '../../types/offer';

export const media: TComputedPropMapper<IMedia[]> = ({ offer }) => {
  return sort<IOfferPhoto>((a, b) => Number(b.isDefault) - Number(a.isDefault), offer.photos || []).map(photo => ({
    type: photo.isLayout ? EMediaType.Layout : EMediaType.Photo,
    src: photo.thumbnail2Url,
  }));
};
