import { triggerHeaderShowNBConsultantPhone } from './events/triggerHeaderShowNBConsultantPhone';
import { IAppState } from '../common/state';
import { isInRegionSelector } from '../selectors/isInRegion';
import { isNewbuildingSelector } from '../selectors/newbuildingConsultant';

export function displayConsultantPhone(state: IAppState, enabledRegions: number[], phone?: string | null) {
  const isNewbuilding = isNewbuildingSelector(state);
  const availableInRegion = isInRegionSelector(enabledRegions)(state);

  const valueToSet = phone && availableInRegion && isNewbuilding ? phone : '';

  triggerHeaderShowNBConsultantPhone(valueToSet);
}
