import { getTermValue, getRangeValue } from './helpers';
import { EElevatorType, TJsonQuery } from './types';
import { EElevators } from '../api-models/common/json_query';

export function getElevatorType(jsonQuery: TJsonQuery): EElevatorType | null {
  const serviceElevators = getRangeValue('lift_service')(jsonQuery);
  const elevators = getTermValue('lifts')(jsonQuery);

  switch (true) {
    case serviceElevators && serviceElevators.gte && serviceElevators.gte > 0:
      return EElevatorType.Service;

    case elevators !== null && elevators !== EElevators.Any:
      return EElevatorType.Any;
  }

  return null;
}
