import { createSubscriber, TSubscriberHandler } from '@cian/events-log';

import { getUserConsumer } from './getUserConsumer';

export function consumeUser(eventHandler: TSubscriberHandler) {
  const consumer = getUserConsumer();
  const subscriber = createSubscriber(eventHandler);

  consumer.subscribe(subscriber);

  return () => consumer.unsubscribe(subscriber);
}
