import * as React from 'react';

import * as styles from './styles.css';

interface ISpecialProjectBannerSkeletonProps {
  children: React.ReactNode;
}

export function SpecialProjectBannerSkeleton({ children }: ISpecialProjectBannerSkeletonProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['wrapper']}>
        <div className={styles['preview']} />
        <div className={styles['content']}>
          {[1, 2].map(item => (
            <div key={item} className={styles['line']} />
          ))}
        </div>
        <div className={styles['layout']}>{children}</div>
      </div>
    </div>
  );
}
