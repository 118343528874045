import { UIText2, Image } from '@cian/ui-kit';
import { IconMapHighway16 } from '@cian/ui-kit-design-tokens/icons';
import { numberToPrettyString } from '@cian/utils';
import * as React from 'react';

import * as styles from './VillageItem.css';
import { LinkArea } from '../../../fragments/OfferCardWidget/shared/components/LinkArea';
import { IRecommendedVillage } from '../../types/recommendedVillages';

interface IVillageItemProps extends IRecommendedVillage {
  onClick(): void;
}

export const VillageItem: React.VFC<IVillageItemProps> = ({
  imageUrl,
  landSquareUnits,
  minArea,
  minPrice,
  roadInfo,
  squareUnits,
  minLandArea,
  url,
  villageTitle,
  onClick,
}) => {
  const villageSquareInfo = React.useMemo(
    () =>
      [minArea && `от ${minArea} ${squareUnits}`, minLandArea && `от ${minLandArea} ${landSquareUnits}`]
        .filter(Boolean)
        .join(' ･ '),
    [minArea, squareUnits, minLandArea, landSquareUnits],
  );

  return (
    <LinkArea href={url} className={styles['link']} onClick={onClick} target="_blank">
      <div className={styles['photo']}>
        <Image src={imageUrl} width="100%" objectFit="cover" borderRadius={0} />
      </div>
      <div className={styles['info']}>
        <UIText2 fontWeight="bold">{villageTitle}</UIText2>
        <div className={styles['price']}>
          <UIText2>от {numberToPrettyString(minPrice)} ₽</UIText2>
        </div>
        <UIText2>{villageSquareInfo}</UIText2>
        {roadInfo && (
          <div className={styles['location-container']}>
            <div className={styles['highway-icon']}>
              <IconMapHighway16 />
            </div>
            <UIText2>{roadInfo?.name}</UIText2>
          </div>
        )}
      </div>
    </LinkArea>
  );
};
