import { IAppState, TActions } from '../../../common/state';
import { EHouseMaterial, IJsonQueryTerms } from '../../../json_query';

const TOGGLED_ACTION_TYPE = 'filters/house_material/TOGGLED';
const SET_ACTION_TYPE = 'filters/house_material/SET';

export interface IHouseMaterialToggledAction {
  type: 'filters/house_material/TOGGLED';
  material: EHouseMaterial;
  value: boolean;
}

export interface IHouseMaterialSetAction {
  type: 'filters/house_material/SET';
  value: EHouseMaterial[];
}

export function toggleHouseMaterial(material: EHouseMaterial, value: boolean): IHouseMaterialToggledAction {
  return {
    type: TOGGLED_ACTION_TYPE,
    material,
    value,
  };
}

export function setHouseMaterial(value: EHouseMaterial[]): IHouseMaterialSetAction {
  return {
    type: SET_ACTION_TYPE,
    value,
  };
}

export function houseMaterialReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case TOGGLED_ACTION_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            house_material: changeHouseMaterial(
              state.filters.jsonQuery.house_material && state.filters.jsonQuery.house_material.value,
              action.material,
              action.value,
            ),
          },
        },
      };

    case SET_ACTION_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            house_material: action.value.length
              ? {
                  type: 'terms',
                  value: action.value,
                }
              : undefined,
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'houseMaterial') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              house_material: changeHouseMaterial(
                state.filters.jsonQuery.house_material && state.filters.jsonQuery.house_material.value,
                action.tag.value,
                false,
              ),
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
}

export function changeHouseMaterial(
  materials: EHouseMaterial[] | undefined,
  material: EHouseMaterial,
  value: boolean,
): IJsonQueryTerms<EHouseMaterial> | undefined {
  if (value) {
    return {
      type: 'terms',
      value: (materials && materials.concat(material)) || [material],
    };
  } else {
    if (materials && materials.length > 1) {
      return {
        type: 'terms',
        value: materials.filter(item => item !== material),
      };
    }

    return undefined;
  }
}
