import { IOffer } from '../../../types/offer';
import { getLandArea } from '../area/getLandArea';
import { isSuburban } from '../category/isSuburban';

/**
 * получение подзаголовка для загородной недвижимости
 */
export function getLandAreaTitle(offer: IOffer): string | undefined {
  if (!isSuburban(offer)) {
    return undefined;
  }

  return getLandArea(offer);
}
