import { isSuburban, offerTypeFromJsonQuery } from '../../packages/JsonQuery';
import { IJsonQuery } from '../repositories/common/json_query';

/**
 * @description Отдаёт true, если выбран поиск только по новостройкам
 * @param {IJsonQuery} jsonQuery
 * @returns {boolean}
 */
export function getIsSuburbanFromJsonQuery(jsonQuery: IJsonQuery): boolean {
  const offerType = offerTypeFromJsonQuery(jsonQuery);

  return isSuburban(offerType);
}
