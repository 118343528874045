import { IAppState, TActions } from '../../../common/state';

export type TBedroomsTotalRangeType = 'gte' | 'lte';

export const IMinBedroomsTotalChangedActionType = 'filters/total_bedrooms/MIN_TOTAL_BEDROOMS_CHANGED';
export const IMaxBedroomsTotalChangedActionType = 'filters/total_bedrooms/MAX_TOTAL_BEDROOMS_CHANGED';

export interface IMinBedroomsTotalChangedAction {
  type: 'filters/total_bedrooms/MIN_TOTAL_BEDROOMS_CHANGED';
  value: number | undefined;
}

export interface IMaxBedroomsTotalChangedAction {
  type: 'filters/total_bedrooms/MAX_TOTAL_BEDROOMS_CHANGED';
  value: number | undefined;
}

export function getNewValue(
  state: IAppState,
  value: number | undefined,
  currentField: TBedroomsTotalRangeType,
  otherField: TBedroomsTotalRangeType,
) {
  if (!value && !(state.filters.jsonQuery.bedroom_total && state.filters.jsonQuery.bedroom_total.value[otherField])) {
    return undefined;
  }

  return {
    type: 'range' as const,
    value: {
      [currentField]: value,
      [otherField]: state.filters.jsonQuery.bedroom_total && state.filters.jsonQuery.bedroom_total.value[otherField],
    },
  };
}

export function bedroomsTotalReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IMinBedroomsTotalChangedActionType: {
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            bedroom_total: getNewValue(state, action.value, 'gte', 'lte'),
          },
        },
      };
    }

    case IMaxBedroomsTotalChangedActionType: {
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            bedroom_total: getNewValue(state, action.value, 'lte', 'gte'),
          },
        },
      };
    }

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-totalBedrooms') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              bedroom_total: undefined,
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
}
