import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackAllProjectsClick, trackProjectClick, trackSuggestShow } from './tracking';
import { SuburbanBuildersProjects } from '../../components/SuburbanBuildersProjects';
import { getJsonQuery } from '../../selectors/getJsonQuery';
import { selectSuburbanBuildersProjects } from '../../selectors/suburbanBuildersProjects';

export const SuburbanBuildersProjectsContainer = () => {
  const suburbanBuildersProjects = useSelector(selectSuburbanBuildersProjects);
  const jsonQuery = useSelector(getJsonQuery);

  const handleSuggestShow = React.useCallback(() => {
    const products = suburbanBuildersProjects.map(({ projectId }, index) => ({
      id: projectId,
      offerType: 'CSP' as const,
      position: index + 1,
    }));

    trackSuggestShow(jsonQuery, products);
  }, [jsonQuery, suburbanBuildersProjects]);

  const handleProjectClick = React.useCallback(
    (id: number, position: number) => trackProjectClick(id, position, jsonQuery),
    [jsonQuery],
  );

  const handleAllProjectsButtonClick = React.useCallback(() => trackAllProjectsClick(jsonQuery), [jsonQuery]);

  return (
    <ActionAfterViewObserver callback={handleSuggestShow} percentVisible={0.5} triggerOnce callbackDelay={1000}>
      <SuburbanBuildersProjects
        items={suburbanBuildersProjects}
        link="/country-builders-projects/?utm_medium=listing"
        onProjectClick={handleProjectClick}
        onAllProjectsButtonClick={handleAllProjectsButtonClick}
      />
    </ActionAfterViewObserver>
  );
};
