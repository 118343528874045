import { GenericError, IErrorDetails } from '@cian/peperrors/shared';

export interface IInvalidLocationErrorDetails extends IErrorDetails {
  redirectLocation: string;
  redirectStatusCode: 301 | 302;
}

export class InvalidLocationError extends GenericError {
  public readonly _invalidLocationErrorDetails: IInvalidLocationErrorDetails;

  public constructor(configuration: { domain: string; message: string; details: IInvalidLocationErrorDetails }) {
    super({
      ...configuration,
      type: 'InvalidLocationError',
    }) /* istanbul ignore next */;

    this._invalidLocationErrorDetails = configuration.details;

    Object.setPrototypeOf(this, InvalidLocationError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidLocationError);
    } else {
      this.stack = new Error().stack;
    }
  }

  public get details(): IInvalidLocationErrorDetails {
    return this._invalidLocationErrorDetails;
  }
}
