import { EGalleryLabelColor, IGalleryLabel } from '../../../types/gallery-label';
import { IOffer } from '../../../types/offer';

export function getOfferPromoLabels(offer: IOffer): IGalleryLabel[] {
  if (!offer.promoInfo) {
    return [];
  }

  return [
    {
      label: offer.promoInfo.name,
      color: offer.promoInfo.specProject ? EGalleryLabelColor.Red : EGalleryLabelColor.Grey,
    },
  ];
}
