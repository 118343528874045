import * as React from 'react';

import { ERoomType, getTermsValue, NonEmptyArray } from '../../../../../JsonQuery';
import { RoomType } from '../../../components/filters/RoomType';
import { IFilterComponentProps } from '../../../types/filters';
import { TAvailableModifierActions } from '../../../types/modifier';
import { useSearchResultsTooltipOpen } from '../../../utils/searchResultsTooltipOpen';
import { useContext } from '../../../utils/useContext';

export const RoomTypeContainer: React.FC<IFilterComponentProps> = ({ filterKey, open, onOpen, onClose }) => {
  const { jsonQuery, jsonQueryCount, jsonQueryCountRefreshing, onChange, onApply, onApplyClick } = useContext();
  const action: TAvailableModifierActions = 'setRoomTypes';
  const roomType = getTermsValue('room')(jsonQuery);
  const openTooltip = useSearchResultsTooltipOpen({ filterKey, actions: [action] });

  const handleApply = React.useCallback(() => {
    if (onApplyClick) {
      onApplyClick({ source: 'tooltip' });
    }

    onApply();

    if (onClose) {
      onClose();
    }
  }, [onApply, onApplyClick, onClose]);

  const handleChange = React.useCallback(
    (roomType: NonEmptyArray<ERoomType>) => {
      onChange({ action, arguments: [roomType] });
    },
    [onChange],
  );

  return (
    <RoomType
      open={open}
      value={roomType}
      offersCount={jsonQueryCount}
      offersCountLoading={jsonQueryCountRefreshing}
      onChange={handleChange}
      openTooltip={openTooltip}
      onApply={handleApply}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
};
