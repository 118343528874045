import * as React from 'react';
import { useSelector } from 'react-redux';

import { SummaryHeaderContainer } from './containers/SummaryHeaderContainer';
import { SummaryMapViewButtonContainer } from './containers/SummaryMapViewButtonContainer';
import { SummarySortDropdownContainer } from './containers/SummarySortDropdownContainer';
import { NewbuildingHousesFilterContainer } from '../../../containers/NewbuildingHousesFilter';
import { NewbuildingLayoutsFilterContainer } from '../../../containers/NewbuildingLayoutsFilterContainer';
import { getIsHousesFilterEnabled } from '../../../selectors/newbuildingHeader';
import { selectAggregatedOffers, selectJsonQuery } from '../../../selectors/results';
import { checkIsHotelsSearch } from '../../../utils/checkIsHotelsSearch';
import { SummarySection } from '../../components/Summary';
import { ModerateLinkButtonContainer } from '../ModerateButtonContainer';

export function SummaryContainer() {
  const jsonQuery = useSelector(selectJsonQuery);
  const isNewbuildingHousesFilterEnabled = useSelector(getIsHousesFilterEnabled);
  const aggregatedOffers = useSelector(selectAggregatedOffers);
  const isHotelsSearch = checkIsHotelsSearch(jsonQuery);

  if (aggregatedOffers === 0) {
    return null;
  }

  if (isHotelsSearch) {
    return <SummarySection leftColumn={<SummaryMapViewButtonContainer />} />;
  }

  if (isNewbuildingHousesFilterEnabled) {
    return (
      <SummarySection
        leftColumn={
          <>
            <NewbuildingLayoutsFilterContainer />
            <NewbuildingHousesFilterContainer />
            <SummaryHeaderContainer />
          </>
        }
        rightColumn={
          <>
            <SummarySortDropdownContainer />
            <SummaryMapViewButtonContainer />
            <ModerateLinkButtonContainer />
          </>
        }
      />
    );
  }

  return (
    <SummarySection
      leftColumn={
        <>
          <SummaryHeaderContainer />
          <SummarySortDropdownContainer />
          <SummaryMapViewButtonContainer />
        </>
      }
      rightColumn={<ModerateLinkButtonContainer />}
    />
  );
}
