import * as React from 'react';

import * as styles from './QuickLinks.css';
import { QuickLinksList } from './components/QuickLinksList/QuickLinksList';
import { QuickLinksSuburbanTrap } from './components/QuickLinksSuburbanTrap';
import { trackQuickLinksView } from './utils/tracking';
import { IQuickLink } from '../../../types/quickLinks';

export interface IQuickLinksStateProps {
  level: number | null;
  linkGroups: IQuickLink[][] | null;
  countrysideBlackFridayLink?: string;
  isSuburbanListing: boolean;
}

export interface IQuickLinksProps extends IQuickLinksStateProps {
  level: number;
  linkGroups: IQuickLink[][];
  banner?: React.ReactNode;
}

export class QuickLinks extends React.PureComponent<IQuickLinksProps> {
  public componentDidMount() {
    trackQuickLinksView(this.props.level);
  }

  public render() {
    const { isSuburbanListing, level, linkGroups, countrysideBlackFridayLink, banner } = this.props;

    return (
      <div className={styles['wrapper']}>
        <QuickLinksList linkGroups={linkGroups} level={level} />
        {isSuburbanListing && (
          <QuickLinksSuburbanTrap countrysideBlackFridayLink={countrysideBlackFridayLink} banner={banner} />
        )}
      </div>
    );
  }
}
