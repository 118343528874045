import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingBrokerWidgetContext } from '../../../../../../../shared/containers/NewbuildingBrokerWidgetProvider';
import { useBrokerLinks } from '../../../../../../../shared/hooks/useBrokerLinks';
import { useNewbuildingBrokerAnalyticsUser } from '../../../../../../../shared/hooks/useNewbuildingBrokerAnalyticsUser';
import { getJsonQuery } from '../../../../../../../shared/selectors/getJsonQuery';
import {
  getIsPageBrokerVisible,
  getPageBrokerButtonShouldRedirect,
  getPageNewbuildingBrokerParams,
} from '../../../../../../../shared/selectors/newbuildingBroker';
import { trackSellApartmentClick } from '../../../../../../../shared/tracking/newbuilding/broker';
import { NewbuildingBrokerBlock } from '../../../../components/NewbuildingBrokerBlock';
import { useOfferContext } from '../../../../context';

export const NewbuildingBrokerBlockContainer: React.FC = () => {
  const {
    offer: { newbuilding },
  } = useOfferContext();

  const newbuildingId = Number(newbuilding?.id) || null;
  const { landingPageLink, rewardTariffsLink } = useBrokerLinks();
  const user = useNewbuildingBrokerAnalyticsUser();
  const pageBrokerButtonShouldRedirect = useSelector(getPageBrokerButtonShouldRedirect);
  const isPageBrokerButtonVisible = useSelector(getIsPageBrokerVisible);
  const brokerWidgetParams = useSelector(getPageNewbuildingBrokerParams);
  const buttonText = brokerWidgetParams ? 'Провести сделку' : 'Заработать комиссию';
  const jsonQuery = useSelector(getJsonQuery);

  const openWidget = React.useContext(NewbuildingBrokerWidgetContext);

  const [loading, setLoading] = React.useState(false);

  const openLandingOrWidget = React.useCallback(async () => {
    setLoading(true);

    if (pageBrokerButtonShouldRedirect) {
      window.open(landingPageLink, '_blank', 'noopener');
    } else {
      if (brokerWidgetParams) {
        const { accountLegalType, hasConfirmedPhone } = brokerWidgetParams;

        await openWidget({
          accountLegalType,
          hasConfirmedPhone,
          newbuildingId,
        });
      }
    }

    setLoading(false);
  }, [brokerWidgetParams, landingPageLink, newbuildingId, openWidget, pageBrokerButtonShouldRedirect]);

  const handleButtonClick = React.useCallback(async () => {
    trackSellApartmentClick(jsonQuery, user);
    await openLandingOrWidget();
  }, [openLandingOrWidget, jsonQuery, user]);

  const handleContainerClick: React.MouseEventHandler = React.useCallback(event => event.stopPropagation(), []);

  if (!isPageBrokerButtonVisible) {
    return null;
  }

  return (
    <div onClick={handleContainerClick}>
      <NewbuildingBrokerBlock
        loading={loading}
        onClick={handleButtonClick}
        buttonText={buttonText}
        rewardTariffsLink={rewardTariffsLink}
      />
    </div>
  );
};
