import * as React from 'react';

import { getRangeValue } from '../../../../../JsonQuery';
import { RoomsInDeal } from '../../../components/advancedFilters/RoomsInDeal';
import { useContext } from '../../../utils/useContext';
import { useDealType } from '../../../utils/useDealType';

export const RoomsInDealContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const dealType = useDealType();
  const rooms = getRangeValue('rooms_for_sale')(jsonQuery);

  const handleRoomsInDealMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setRoomsForSaleMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleRoomsInDealMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setRoomsForSaleMax', arguments: [lte] });
    },
    [onChange],
  );

  return (
    <RoomsInDeal
      dealType={dealType}
      rooms={rooms}
      onRoomsInDealMinChange={handleRoomsInDealMinChange}
      onRoomsInDealMaxChange={handleRoomsInDealMaxChange}
    />
  );
};
