import * as React from 'react';

import { IGeoRegionalDistrictTag } from '../../../../state/tags/definitions/geo';
import { TagNew } from '../../TagNew';

export interface IGeoRegionalDistrictTagItemProps {
  tag: IGeoRegionalDistrictTag;
  onDeleteClick(): void;
}

export const GeoRegionalDistrictTagItem = (props: IGeoRegionalDistrictTagItemProps) => {
  return <TagNew onDeleteClick={props.onDeleteClick}>{props.tag.text}</TagNew>;
};
