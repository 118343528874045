import * as R from 'ramda';

import { resetTerms as resetJsonQueryTerms } from './helpers';
import { resetForDay } from './resetForDay';
import { TJsonQuery, TJsonQueryOptionalKeysToReset } from './types';

export function clearTags(jsonQuery: TJsonQuery) {
  return (termsToReset: TJsonQueryOptionalKeysToReset[]): TJsonQuery => {
    let nextJsonQuery = R.clone(jsonQuery);

    nextJsonQuery = resetForDay(nextJsonQuery)();
    nextJsonQuery = resetJsonQueryTerms(termsToReset)(nextJsonQuery);

    return nextJsonQuery;
  };
}
