import { Button, TooltipDesktop } from '@cian/ui-kit';
import { IconMapPin16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { ControlWrapper } from '../../../../components/ControlWrapper';
import { MapModal } from '../../../../components/MapModal/MapModal';
import { useOfferContext } from '../../../../context';

export function ShowOnMapControlContainer() {
  const {
    offer,
    tracking: { trackMapOpened, trackMapClosed },
  } = useOfferContext();

  const [mapShown, setMapShown] = React.useState(false);

  const handleOpenMap = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      trackMapOpened();
      setMapShown(true);
    },
    [trackMapOpened],
  );

  const handleMapClose = React.useCallback(() => {
    trackMapClosed();
    setMapShown(false);
  }, [trackMapClosed]);

  if (!offer.geo?.coordinates) {
    return null;
  }

  return (
    <ControlWrapper>
      <TooltipDesktop theme="black" placement="left" title="Показать на карте">
        <Button
          data-mark="ShowOfferOnMapControl"
          theme="stroke_secondary"
          onClick={handleOpenMap}
          size="XS"
          beforeIcon={<IconMapPin16 />}
        />
      </TooltipDesktop>

      <MapModal
        open={mapShown}
        isFavorite={Boolean(offer.isFavorite)}
        coordinates={offer.geo.coordinates}
        onClose={handleMapClose}
      />
    </ControlWrapper>
  );
}
