import { pathOr } from 'ramda';

import { IJsonQuery } from '../../repositories/common/json_query';
import { ISearchNewbuildingLayoutsDesktopResponse } from '../../repositories/search-offers/v1/search-newbuilding-layouts-desktop';
import { IOffersData } from '../../types/offersData';

export const prepareNewbuildingLayouts = (response: ISearchNewbuildingLayoutsDesktopResponse) => {
  return {
    maxAuctionBet: pathOr(null, ['layouts', 'maxAuctionBet'], response),
    maxAuctionService: pathOr(null, ['layouts', 'maxAuctionService'], response),
    qsToUris: pathOr(null, ['layouts', 'qsToUris'], response),
    quickLinks: pathOr(null, ['layouts', 'quickLinks'], response),
    ymEvents: pathOr(null, ['layouts', 'ymEvents'], response),
    avgPriceInformer: pathOr(null, ['layouts', 'avgPriceInformer'], response),
    breadcrumbs: pathOr([], ['layouts', 'breadcrumbs'], response),
    fullUrl: pathOr('/', ['layouts', 'fullUrl'], response),
    jsonQuery: response?.layouts?.jsonQuery as IJsonQuery, //?
    layouts: pathOr([], ['layouts', 'layouts'], response),
    offerCount: pathOr(0, ['layouts', 'offerCount'], response),
    puids: pathOr(null, ['layouts', 'puids'], response),
    queryString: pathOr('', ['layouts', 'queryString'], response),
    searchRequestId: pathOr('', ['layouts', 'searchRequestId'], response),
    seoData: pathOr({}, ['layouts', 'seoData'], response) as IOffersData['seoData'],
    seoLinks: pathOr(null, ['layouts', 'seoLinks'], response),
  };
};
