import * as React from 'react';

import * as styles from './Filter.css';

interface IFilterProps {
  id?: string;
  label: React.ReactNode;
}

export const Filter: React.FC<React.PropsWithChildren<IFilterProps>> = ({ id, label, children }) => {
  return (
    <div role="group" aria-labelledby={id} className={styles['container']}>
      <div id={id} className={styles['label']}>
        {label}
      </div>
      <div className={styles['content']}>{children}</div>
    </div>
  );
};
