import { IAppState } from '../../common/state';
import { selectIsAgent } from '../user';

export const selectIsNewbuildingPromoBuilderOffersVisible = (state: IAppState) => {
  const isAgent = selectIsAgent(state);

  if (isAgent) {
    return false;
  }

  if (!state.featureToggle.isNewbuildingPromoBuilderOffersEnabled) {
    return false;
  }

  if (state.newbuildingPromoBuilderOffers === null) {
    return false;
  }

  return !state.newbuildingPromoBuilderOffers.needShowAdfoxBlock;
};
