import * as React from 'react';

import { checkIsHotelsSearch } from '../../../../../../shared/utils/checkIsHotelsSearch';
import { FDealType, getRangeValue, getTermValue } from '../../../../../JsonQuery';
import { Price } from '../../../components/filters/Price';
import { IFilterComponentProps } from '../../../types/filters';
import { useSearchResultsTooltipOpen } from '../../../utils/searchResultsTooltipOpen';
import { useContext } from '../../../utils/useContext';
import { useDealType } from '../../../utils/useDealType';
import { useOfferType } from '../../../utils/useOfferType';

export const PriceContainer: React.FC<IFilterComponentProps> = ({ filterKey, open, onOpen, onClose }) => {
  const { jsonQuery, jsonQueryCount, jsonQueryCountRefreshing, onChange, onApply, onApplyClick } = useContext();
  const dealType = useDealType();
  const offerType = useOfferType();
  const price = getRangeValue('price')(jsonQuery);
  const isSquareMeterPrice = getTermValue('price_sm')(jsonQuery);
  const showHotels = getTermValue('show_hotels')(jsonQuery);
  const isDailyRent = dealType === FDealType.RentDaily;
  const isHotelSearch = checkIsHotelsSearch(jsonQuery);
  const defaultPriceLabel = isDailyRent && !showHotels ? 'Цена за сутки' : 'Цена';
  const openTooltip = useSearchResultsTooltipOpen({
    filterKey,
    actions: ['setPriceMin', 'setPriceMax', 'setPriceType'],
  });

  const handleApply = React.useCallback(() => {
    if (onApplyClick) {
      onApplyClick({ source: 'tooltip' });
    }

    onApply();

    if (onClose) {
      onClose();
    }
  }, [onApply, onApplyClick, onClose]);

  const handlePriceMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setPriceMin', arguments: [gte] });
    },
    [onChange],
  );

  const handlePriceMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setPriceMax', arguments: [lte] });
    },
    [onChange],
  );

  const handlePriceTypeChange = React.useCallback(
    (isSquareMeterPrice: boolean | null) => {
      onChange({ action: 'setPriceType', arguments: [isSquareMeterPrice] });
    },
    [onChange],
  );

  return (
    <Price
      open={open}
      dealType={dealType}
      offerType={offerType}
      offersCount={jsonQueryCount}
      hideTooltipCount={isHotelSearch}
      offersCountLoading={jsonQueryCountRefreshing}
      price={price}
      defaultPriceLabel={defaultPriceLabel}
      openTooltip={openTooltip}
      isSquareMeterPrice={isSquareMeterPrice}
      onPriceMinChange={handlePriceMinChange}
      onPriceMaxChange={handlePriceMaxChange}
      onPriceTypeChange={handlePriceTypeChange}
      onApply={handleApply}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
};
