import * as React from 'react';

import { EGarageType, getTermsValue, NonEmptyArray } from '../../../../../JsonQuery';
import { GarageType } from '../../../components/advancedFilters/GarageType';
import { useContext } from '../../../utils/useContext';

export const GarageTypeContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  let garageTypes = getTermsValue('garage_type')(jsonQuery);
  if (garageTypes && garageTypes.length === 0) {
    garageTypes = null;
  }

  const handleChange = React.useCallback(
    (garageTypes: NonEmptyArray<EGarageType> | null) => {
      onChange({ action: 'setGarageTypes', arguments: [garageTypes] });
    },
    [onChange],
  );

  return <GarageType value={garageTypes as NonEmptyArray<EGarageType> | null} onChange={handleChange} />;
};
