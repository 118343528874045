import { Spinner } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './ComplainsModalLoader.css';

export const ComplainsModalLoader: React.FC = () => {
  return (
    <div className={styles['container']}>
      <Spinner size={40} />
    </div>
  );
};
