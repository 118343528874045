import { IAppState, TActions } from '../../../common/state';

export interface IAdvancedModalOpenedAction {
  type: 'filters/advanced/MODAL_OPENED';
}

export interface IAdvancedModalClosedAction {
  type: 'filters/advanced/MODAL_CLOSED';
}

export function openAdvancedModal(): IAdvancedModalOpenedAction {
  return {
    type: 'filters/advanced/MODAL_OPENED',
  };
}

export function closeAdvancedModal(): IAdvancedModalClosedAction {
  return {
    type: 'filters/advanced/MODAL_CLOSED',
  };
}

export function toggleAdvancedModalReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/advanced/MODAL_OPENED':
      return {
        ...state,
        filters: {
          ...state.filters,
          backup: {
            jsonQuery: state.filters.jsonQuery,
            tags: state.filters.tags,
          },
        },
        isAdvancedModalVisible: true,
      };
    case 'filters/advanced/MODAL_CLOSED':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...state.filters.backup,
          backup: undefined,
        },
        isAdvancedModalVisible: false,
      };
    default:
      return state;
  }
}
