import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IBuilderTagType = 'builder';
const IGeoBuilderTagType = 'geo-builder';

export interface IBuilderTag {
  type: 'builder';
  name: string;
  id: number;
}

const isBuilderTag = (tag: TTag): tag is IBuilderTag => [IBuilderTagType, IGeoBuilderTagType].includes(tag.type);

export const builderTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery, tagsData) => {
    const value = jsonQuery.builder && jsonQuery.builder.value;
    const { developers } = tagsData;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isBuilderTag, tag => ({
        type: IBuilderTagType,
        id: value,
        name: developers[value] || tag?.name || `Неизвестный застройщик #${value}`,
      }));
    } else {
      return ensureTagNotExists(tags, isBuilderTag);
    }
  },
};
