import * as React from 'react';

import { getUndergroundIcon } from './utils';
import { UndergroundIconWrapper } from '../../../components/UndergroundIconWrapper';
import { useOfferContext } from '../../../context';
import { ELocationTypeId, IAddress } from '../../../types/offer';

export function UndergroundIconContainer() {
  const { offer, underground } = useOfferContext();

  const id = React.useMemo(() => {
    const { id } =
      (offer.geo && offer.geo.address.find(item => item.locationTypeId === ELocationTypeId.City)) || ({} as IAddress);

    return id;
  }, [offer.geo]);

  const color = React.useMemo(() => (underground && `#${underground.lineColor}`) || undefined, [underground]);

  const icon = React.useMemo(() => getUndergroundIcon({ underground, regionId: id }), [id, underground]);

  if (!underground) {
    return null;
  }

  return <UndergroundIconWrapper color={color}>{icon}</UndergroundIconWrapper>;
}
