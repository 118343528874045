import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingConsultBanner } from './NewbuildingConsultBanner';
import { trackBannerClick, trackBannerShow } from './tracking';
import { getJsonQuery } from '../../../selectors/getJsonQuery';
import { useApplicationContext } from '../../../utils/applicationContext';

export function NewbuildingConsultBannerContainer() {
  const { config } = useApplicationContext();
  const landingLink = config.getStrict<string>('newbuildingConsultant.landingLink');

  const jsonQuery = useSelector(getJsonQuery);

  const handleClick = React.useCallback(() => trackBannerClick(jsonQuery), [jsonQuery]);
  const handleShow = React.useCallback(() => trackBannerShow(jsonQuery), [jsonQuery]);

  return (
    <ActionAfterViewObserver callback={handleShow} percentVisible={0.5} triggerOnce callbackDelay={1000}>
      <NewbuildingConsultBanner onClick={handleClick} url={landingLink} />
    </ActionAfterViewObserver>
  );
}
