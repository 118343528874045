import * as React from 'react';

import { EHouseMaterial } from '../../../../json_query';
import { FOfferType, isSuburban } from '../../../../utils/category';
import { IHouseMaterialTag } from '../../../state/tags/definitions/house_material';
import { TagNew } from '../TagNew';

export interface IHouseMaterialTagItemProps {
  tag: IHouseMaterialTag;
  onDeleteClick(): void;
  offerType: FOfferType;
}

const materialsMap = {
  [EHouseMaterial.Brick]: 'кирпичный',
  [EHouseMaterial.Monolith]: 'монолитный',
  [EHouseMaterial.Panel]: 'панельный',
  [EHouseMaterial.Block]: 'блочный',
  [EHouseMaterial.Wood]: 'деревянный',
  [EHouseMaterial.Stalin]: 'сталинский',
  [EHouseMaterial.Shield]: 'щитовой',
  [EHouseMaterial.BrickMonolith]: 'кирпично-монолитный',
  [EHouseMaterial.Wireframe]: 'каркасный',
  [EHouseMaterial.AerocreteBlock]: 'газобетонный блок',
  [EHouseMaterial.GasSilicateBlock]: 'газосиликатный блок',
  [EHouseMaterial.FoamConcreteBlock]: 'пенобетонный блок',
};

export const HouseMaterialTagItem = (props: IHouseMaterialTagItemProps) => {
  const label = isSuburban(props.offerType) ? 'Материал дома' : 'Тип дома';

  return (
    <TagNew onDeleteClick={props.onDeleteClick}>
      {label}: {materialsMap[props.tag.value]}
    </TagNew>
  );
};
