import { ca } from '@cian/analytics';

export const trackOpenTooltip = (id: number, superAgent?: boolean) => {
  const userType = superAgent ? 'agent' : 'homeowner';

  ca('eventSite', {
    name: 'oldevent',
    category: 'quality_of_service',
    action: 'superagent_tooltip_open',
    label: `superagent_tooltip_${userType}`,
    products: [{ id }],
  });
};
