import { ca } from '@cian/analytics';

type TMediaType = 'gallery' | 'map' | 'building_plan';

interface ITrackMediaItemClick {
  target: TMediaType;
  newbuildingId: number;
}

export function trackMediaItemClick({ target, newbuildingId }: ITrackMediaItemClick) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'Click',
    label: target,
    ds: 'desktop',
    products: [{ id: newbuildingId, offerType: 'JK' }],
  });
}
