export enum EFacilityType {
  Bath = 'bath',
  Conditioner = 'conditioner',
  Fridge = 'fridge',
  Garage = 'garage',
  Internet = 'internet',
  Phone = 'phone',
  Pool = 'pool',
  Sauna = 'sauna',
  Security = 'security',
  Shower = 'shower',
  TV = 'tv',
  Washer = 'washer',
  Dishwasher = 'dishwasher',
  WithoutFurniture = 'withoutFurniture',
  RoomFurniture = 'roomFurniture',
  KitchenFurniture = 'kitchenFurniture',
  KidsAllowed = 'kidsAllowed',
  PetsAllowed = 'petsAllowed',
}
