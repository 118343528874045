import { FavoriteButtonWrapperContainer } from '@cian/favorites-assign-widget';
import { Button, TooltipDesktop } from '@cian/ui-kit';
import { IconToggleHeartOn16, IconToggleHeartOff16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { configSelector } from '../../../../../../../shared/selectors/configSelector';
import { ControlWrapper } from '../../../../components/ControlWrapper';
import { OfferContext } from '../../../../context';
import { TOfferContextData } from '../../../../types/offer-card';

export function FavoritesControlContainer() {
  const config = useSelector(configSelector);
  const {
    user: { isAuthenticated },
    offer,
    api: { onFavoritesChange },
  } = React.useContext(OfferContext) as TOfferContextData;
  const isPartnersFavoritesEnabled = Boolean(config.get<boolean>('dailyrent.partners_favorites.Enabled'));

  const [isFavorite, setFavorite] = React.useState(Boolean(offer.isFavorite));

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onFavoritesChange(!isFavorite);
    setFavorite(!isFavorite);
  };

  const handleLogin = React.useCallback(() => {
    if (window.waitForLoginPopup) {
      window.waitForLoginPopup('favorites');
    }
  }, []);

  const button = (
    <TooltipDesktop theme="black" placement="left" title={isFavorite ? 'В избранном' : 'Добавить в избранное'}>
      <Button
        data-mark="FavoritesControl"
        size="XS"
        theme="stroke_secondary"
        onClick={handleClick}
        beforeIcon={isFavorite ? <IconToggleHeartOn16 color="error_100" /> : <IconToggleHeartOff16 />}
      />
    </TooltipDesktop>
  );

  if (offer.isCianPartner && !isPartnersFavoritesEnabled) {
    return null;
  }

  return (
    <ControlWrapper visible={true}>
      <FavoriteButtonWrapperContainer
        offerId={offer.cianId}
        placement="bottom-end"
        isAuthenticated={isAuthenticated}
        onWaitForLogin={handleLogin}
      >
        {button}
      </FavoriteButtonWrapperContainer>
    </ControlWrapper>
  );
}
