import * as React from 'react';

import { GeoSuggestContainer } from '../../../../../../shared/filters/components/geo_suggest/container';
import { useContext } from '../../../utils/useContext';
import { GeoSwitcherButtonsContainer } from '../GeoSwitcherButtonsContainer';

export const GeoContainer: React.FC = () => {
  const { onChange } = useContext();

  const selectGeoFake = React.useCallback(() => {
    onChange({ action: 'selectGeoFake', arguments: [] });
  }, [onChange]);

  const setKPIdFake = React.useCallback(
    (id: number) => {
      onChange({ action: 'setKPIdFake', arguments: [id] });
    },
    [onChange],
  );

  return (
    <GeoSuggestContainer
      rightAddon={<GeoSwitcherButtonsContainer />}
      redesign
      onGeoSelected={selectGeoFake}
      onHighwaySelected={selectGeoFake}
      onKPSelected={setKPIdFake}
    />
  );
};
