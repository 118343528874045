import { ERoomType } from '../repositories/common/json_query';

export const roomTypeMap = {
  [ERoomType.Room]: 'комнату',
  [ERoomType.FlatOneRoom]: 'однокомнатную квартиру',
  [ERoomType.FlatTwoRooms]: 'двухкомнатную квартиру',
  [ERoomType.FlatThreeRooms]: 'трехкомнатную квартиру',
  [ERoomType.FlatFourRooms]: 'четырехкомнатную квартиру',
  [ERoomType.FlatFiveRooms]: 'пятикомнатную квартиру',
  [ERoomType.FlatManyRooms]: 'многокомнатную квартиру',
  [ERoomType.FlatFreeLayout]: 'квартиру свободной планировки',
  [ERoomType.FlatShared]: 'долю в квартире',
  [ERoomType.Studio]: 'квартиру-студию',
  [ERoomType.Bed]: 'койко-место',
};
