import loadable from '@loadable/component';
import * as React from 'react';

import { LoadableFallback } from '../../../../packages/LoadableHelpers';

export const RegionalDistrictsContentContainerLoadable = loadable(() => import('./RegionalDistrictsContentContainer'), {
  resolveComponent: component => component.RegionalDistrictsContentContainer,
  fallback: <LoadableFallback spinnerSize={42} />,
  ssr: false,
});
