import { IOffer } from '../../../types/offer';

export function getFloorRange(offer: IOffer): string | undefined {
  if (offer.floorNumber) {
    let floorInfo = `${offer.floorNumber}`;

    if (offer.building && offer.building.floorsCount) {
      floorInfo += `/${offer.building.floorsCount}`;
    }

    floorInfo += ' этаж';

    return floorInfo;
  }

  return undefined;
}
