import { TColors } from '@cian/ui-kit';
import colorsStyles from '@cian/ui-kit/colors/colors.module.css';
import * as React from 'react';

export interface ISVGProps extends Omit<React.ComponentPropsWithRef<'svg'>, 'style' | 'className'> {
  color?: TColors;
}

export const IconHide16 = React.forwardRef<SVGSVGElement, ISVGProps>((props, ref) => {
  const { color = 'gray_icons_100', ...svgProps } = props;
  const generatedId = React.useId();
  const fullId = `IconHide16a_${generatedId}`;

  return (
    // eslint-disable-next-line react/forbid-elements
    <svg
      {...svgProps}
      className={colorsStyles[`color_${color}`]}
      ref={ref}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" clipPath={`url(#${fullId})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.805 3.21 13.016 2h2.828l-12 12H1.016l2.063-2.063C1.1 10.2 0 8 0 8s3-6 8-6c1.443 0 2.72.5 3.805 1.21Zm-6.36 6.362 4.127-4.128a3 3 0 0 0-4.128 4.128Z"
        />
        <path d="m6.446 13.804 8.062-8.063C15.478 6.957 16 8 16 8s-3 6-8 6c-.542 0-1.06-.07-1.554-.196Z" />
      </g>
      <defs>
        <clipPath id={fullId}>
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

IconHide16.displayName = 'IconHide16';
