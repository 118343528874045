import * as React from 'react';

import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { MaskedInput } from '../../MaskedInput';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';

interface IContactPhoneProps {
  offerId: number | null;
  onOfferIdChange(offerId: number | null): void;
}

export const OfferId: React.FC<IContactPhoneProps> = ({ offerId, onOfferIdChange }) => {
  const handleChangeOfferId = useDebouncedCallback(
    (offerId: string) => {
      onOfferIdChange(offerId ? Number(offerId) : null);
    },
    300,
    [onOfferIdChange],
  );

  return (
    <Filter id="advancedFilter_offerId" label="Номер объявления">
      <InlineFilterGroup>
        <InlineFilter>
          <MaskedInput
            min={1}
            width={260}
            value={offerId === null ? '' : String(offerId)}
            mask={Number}
            onAccept={handleChangeOfferId}
          />
        </InlineFilter>
      </InlineFilterGroup>
    </Filter>
  );
};
