import React from 'react';

import { Price } from '../../../components/Price';
import { useOfferContext } from '../../../context';
interface IPriceContainerProps {
  MortgageAdFoxBanner?: React.ReactNode;
  isNewHighlighting: boolean;
}

export function PriceContainer({ isNewHighlighting, MortgageAdFoxBanner }: IPriceContainerProps) {
  const { priceInfo, price, isColored, discount } = useOfferContext();

  return (
    <Price
      priceInfo={priceInfo}
      price={price}
      discount={discount}
      isHighlighted={isNewHighlighting && /* istanbul ignore next*/ isColored}
      MortgageAdFoxBanner={MortgageAdFoxBanner}
    />
  );
}

PriceContainer.displayName = 'PriceContainer';
