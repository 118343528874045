import { Button } from '@cian/ui-kit';
import classNames from 'classnames';
import * as React from 'react';

import * as styles from './styles.css';
import { NBSP, LF } from '../../../constants/symbols';

interface IDealSaleBannerProps {
  isMobile: boolean;
  url: string;
  onClick: () => void;
}

/**
 * Компонент баннера "Циан.Сделка" для смешанной выдачи.
 */
export function DealSaleBanner({ isMobile, url, onClick }: IDealSaleBannerProps) {
  return (
    <div className={classNames(styles['container'])} data-testid="deal-sale-banner">
      <a
        href={`${url}?cian_source=listing_banner`}
        target="_blank"
        rel="noreferrer"
        data-testid="deal-sale-banner-inner-wrapper"
        onClick={onClick}
        className={classNames(styles['wrapper'])}
      >
        <div className={styles['title']}>Циан.Сделка — это{NBSP}удобно</div>
        <div className={styles['description']}>
          Проведите сделку с{NBSP}недвижимостью без посещения офисов банка{LF} и{NBSP}МФЦ
        </div>
        <div className={styles['button']}>
          <Button size="M" theme="fill_primary" fullWidth={isMobile}>
            Подробнее
          </Button>
        </div>
      </a>
    </div>
  );
}
