import { FOfferType } from '../../../JsonQuery';
import { IOfferType } from '../components/filters/OfferType/types';

export const getDailyRentOfferTypes = (isSearchFlatShareEnabled: boolean): IOfferType[][] =>
  [
    [{ label: 'Квартира', value: FOfferType.Flat }],
    [
      { label: 'Комната', value: FOfferType.Room },
      { label: 'Койко-место', value: FOfferType.Bed },
    ],
    [{ label: 'Дом, дача', value: FOfferType.House }],
    isSearchFlatShareEnabled && [{ label: 'Отели и гостиницы', value: FOfferType.Hotel }],
  ].filter(Boolean) as IOfferType[][];
