import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { newbuildingLayoutsOff, newbuildingLayoutsOn } from '../../actions/newbuildingLayouts';
import { NewbuildingLayoutsFilter } from '../../components/NewbuildingLayoutsFilter';
import { makeSearch } from '../../filters/state/search';
import {
  isListingForResidentialComplexSelector,
  residentialComplexLayoutsCountSelector,
  residentialComplexLayoutsOnSelector,
} from '../../selectors/newbuildingLayouts';
import { useApplicationContext } from '../../utils/applicationContext';

export const NewbuildingLayoutsFilterContainer = () => {
  const { config } = useApplicationContext();
  const dispatch = useDispatch();
  const isSwitcherEnabled = config.get('Newbuilding.LayoutsListingDesktop.Enabled');
  const isListingForResidentialComplex = useSelector(isListingForResidentialComplexSelector);
  const residentialComplexLayoutsCount = useSelector(residentialComplexLayoutsCountSelector);
  const checkedInitialValue = useSelector(residentialComplexLayoutsOnSelector);
  const [checked, setChecked] = React.useState(checkedInitialValue);

  const newbuildingLayoutsSwitch = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      if (value) {
        setChecked(true);
        dispatch(newbuildingLayoutsOn());
        dispatch(makeSearch());
      } else {
        setChecked(false);
        dispatch(newbuildingLayoutsOff());
      }
    },
    [newbuildingLayoutsOn, newbuildingLayoutsOff],
  );

  if (!isSwitcherEnabled || !isListingForResidentialComplex || !residentialComplexLayoutsCount) {
    return null;
  }

  return <NewbuildingLayoutsFilter checked={checked} onChange={newbuildingLayoutsSwitch} />;
};
