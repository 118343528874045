function reverseString(s: string): string {
  return s.split('').reverse().join('');
}

function parseNumber(s: string) {
  const sign = s[0] === '-' ? '-' : '';
  const [integer, fractional] = s.split('.');

  return {
    sign,
    integer: integer.replace(/[^0-9]/g, ''),
    fractional: fractional != null ? fractional.replace(/[^0-9]/g, '') : null,
  };
}

export function numberToPrettyString(n: number | void): string {
  if (typeof n !== 'number') {
    return '';
  }

  // eslint-disable-next-line prefer-const
  let { sign, integer, fractional } = parseNumber(String(n));

  integer = (reverseString(integer).match(/.{1,3}/g) as RegExpMatchArray).reverse().map(reverseString).join(' ');

  if (!fractional) {
    return `${sign}${integer}`;
  }

  return `${sign}${integer}.${fractional}`;
}
