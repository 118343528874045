import { ca } from '@cian/analytics';

export function trackSearchButtonClick(label: string) {
  ca('event', {
    name: 'oldevent',
    category: 'FastFilters',
    action: 'click',
    label,
  });
}
