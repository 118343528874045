import { createConsumer, IConsumer } from '@cian/events-log';

import { TOPIC_USER } from '../topics';

type TConsumerSingleton = (() => IConsumer) & { _instance?: IConsumer };

export const getUserConsumer: TConsumerSingleton = (): IConsumer => {
  if (!getUserConsumer._instance) {
    getUserConsumer._instance = createConsumer({ topic: TOPIC_USER });
  }

  return getUserConsumer._instance;
};
