import * as R from 'ramda';

import { setRootType, setTerm } from './helpers';
import { selectOfferType } from './selectOfferType';
import { IJsonQuery, IJsonQueryType, ERentTime } from '../../repositories/common/json_query';
import { FDealType, offerTypeFromJsonQuery, FOfferType } from '../../utils/category';

const FDealTypeName = new Map<FDealType, string>([
  [FDealType.Sale, 'sale'],
  [FDealType.RentDaily, 'rent'],
  [FDealType.RentLongterm, 'rent'],
]);

export function setDealType(jsonQuery: IJsonQuery) {
  return (nextDealType: FDealType): IJsonQuery => {
    let nextJsonQuery = R.clone(jsonQuery);

    const type = nextJsonQuery._type;

    let nextType: IJsonQueryType = 'flatrent';

    const nextDealTypeString = FDealTypeName.get(nextDealType);
    if (nextDealTypeString) {
      nextType = type.replace(/rent|sale/, nextDealTypeString) as IJsonQueryType;
    }

    if (nextDealType !== FDealType.RentDaily) {
      nextJsonQuery = setTerm('for_day')(nextJsonQuery)(ERentTime.NotDaily);
    } else {
      nextJsonQuery = setTerm('for_day')(nextJsonQuery)(ERentTime.Daily);
    }

    nextJsonQuery = setRootType(nextType)(nextJsonQuery);

    const offerType = offerTypeFromJsonQuery(jsonQuery);
    const nextOfferType = resetNextOfferType(nextDealType, offerType);
    if (nextOfferType) {
      nextJsonQuery = selectOfferType(nextJsonQuery)(nextOfferType);
    }

    return nextJsonQuery;
  };
}

function resetNextOfferType(dealType: FDealType, offerType: FOfferType) {
  switch (dealType) {
    case FDealType.RentLongterm:
      if (offerType & (FOfferType.FlatNew | FOfferType.FlatShared)) {
        return FOfferType.FlatOld;
      }
      break;
    case FDealType.RentDaily:
      if (offerType & (FOfferType.Any & ~FOfferType.FlatOld & ~FOfferType.Room & ~FOfferType.Bed & ~FOfferType.House)) {
        return FOfferType.FlatOld;
      }
      break;
    case FDealType.Sale:
      if (offerType & (FOfferType.FlatOld | FOfferType.Bed)) {
        return FOfferType.Flat;
      }
      break;
    default:
      return null;
  }

  return null;
}
