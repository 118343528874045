import { TLocation } from '../../../types/location';
import { FDealType, FOfferType } from '../../../utils/category';
import { getRegionId, isNewbuildingsRegion } from '../../../utils/geo';

export type TGeoSuggestItemType = 1 | 2 | 3 | 4 | 5;
export const GeoSuggestItemType = {
  Address: 1 as const,
  Newbuilding: 2 as const,
  BSCenter: 3 as const,
  Highway: 4 as const,
  Railway: 5 as const,
};

export const isNewbuildingsAvailable = (dealType: FDealType, offerType: FOfferType, currentLocation: TLocation) =>
  dealType & FDealType.Any && offerType & FOfferType.Urban && isNewbuildingsRegion(getRegionId(currentLocation));

export const removeCountryName = (value: string) => value.replace(/(^Россия, )|(, Россия$)/i, '');
