import * as React from 'react';

import { FOfferType } from '../../../../../JsonQuery';
import { IJsonQueryRangeValue } from '../../../types/jsonQuery';
import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';
import { RangeInput } from '../common/RangeInput';

interface IFloorsProps {
  offerType: FOfferType;
  floors: IJsonQueryRangeValue;
  onFloorsMinChange(gte: number | null): void;
  onFloorsMaxChange(lte: number | null): void;
}

export const Floors: React.FC<IFloorsProps> = ({
  floors: { gte: floorsMin = null, lte: floorsMax = null },
  onFloorsMinChange,
  onFloorsMaxChange,
}) => {
  const handleFloorsMinChange = useDebouncedCallback(
    (gte: number | null) => {
      onFloorsMinChange(gte);
    },
    300,
    [onFloorsMinChange],
  );

  const handleFloorsMaxChange = useDebouncedCallback(
    (lte: number | null) => {
      onFloorsMaxChange(lte);
    },
    300,
    [onFloorsMaxChange],
  );

  return (
    <Filter id="advancedFilter_floors" label="Этажей в доме">
      <InlineFilterGroup>
        <InlineFilter>
          <RangeInput
            min={1}
            max={200}
            valueMin={floorsMin}
            valueMax={floorsMax}
            onChangeMin={handleFloorsMinChange}
            onChangeMax={handleFloorsMaxChange}
          />
        </InlineFilter>
      </InlineFilterGroup>
    </Filter>
  );
};
