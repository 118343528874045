import { numberToPrettyString } from '@cian/utils';

import { getPaymentPeriod } from './getPaymentPeriod';
import { TOfferCategory } from '../../../../types/offer';

export interface IGetRentPriceParams {
  priceRur: number;
  category: TOfferCategory | null;
  shouldAddPretext?: boolean;
}

export function getRentPrice({ priceRur, category, shouldAddPretext = false }: IGetRentPriceParams): string {
  const price = `${numberToPrettyString(priceRur)} ₽/${getPaymentPeriod(category)}`;

  if (shouldAddPretext) {
    return `от ${price}`;
  }

  return price;
}
