import { ca } from '@cian/analytics';

import { IJsonQuery } from '../../../repositories/common/json_query';

export function trackBannerShow(jsonQuery: IJsonQuery): void {
  ca('eventSite', {
    action: 'show_sopr',
    category: 'newbuilding_banner',
    label: 'KPN',
    event: 'oldevent',
    sc: jsonQuery,
    useLastProducts: true,
  });
}

export function trackBannerClick(jsonQuery: IJsonQuery): void {
  ca('eventSite', {
    action: 'click',
    category: 'newbuilding_banner',
    label: 'KPN',
    name: 'oldevent',
    sc: jsonQuery,
    useLastProducts: true,
  });
}
