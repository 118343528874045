import { TTgbNativedescGeneralContext, ScrollFade } from '@cian/adfox-templates';
import { IconActionClose16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './styles.css';
import { GoToButton } from '../GoToButton';
import { Metro } from '../Metro';

export interface INativeTGBAsideInfoProps {
  context: TTgbNativedescGeneralContext;
  locationId: number | undefined;
  onTrack(isShow: boolean): void;
}

/*
 * Новое отображение нативного тгб: картинка размером с галерею объявления с ВАСами, текст о застройщике сбоку
 */

export function NativeTGBAsideInfo(props: INativeTGBAsideInfoProps) {
  const { context, locationId, onTrack } = props;

  const [isClosed, setClosedState] = React.useState(false);

  React.useEffect(() => {
    onTrack(true);
  }, [onTrack]);

  const onClick = React.useCallback(() => {
    onTrack(false);
  }, [onTrack]);

  const onClose = React.useCallback(() => {
    setClosedState(true);
  }, []);

  if (isClosed) {
    return <div className={styles['empty']} data-testid="empty_native_adfox_block" />;
  }

  const {
    title,
    location,
    description,
    annotation,
    image,
    metro,
    site,
    url,
    metroDistance,
    metroColor,
    button,
    label,
  } = context;

  return (
    <div className={styles['container']} onClick={onClick} data-testid="adfox_native_aside_info">
      <a href={url} target="_blank" className={styles['main-link']} rel="noreferrer">
        <div className={styles['photo-container']}>
          <img className={styles['photo']} src={image} />
        </div>
        <div className={styles['main-content']}>
          <div className={styles['title']}>{title}</div>
          <div className={styles['description']}>{description}</div>
          <div className={styles['address-container']}>
            {metro && (
              <Metro metro={metro} metroDistance={metroDistance} locationId={locationId} metroColor={metroColor} />
            )}
            <div className={styles['address']}>{location}</div>
          </div>
          <div className={styles['links-container']}>
            <div className={styles['goto-button-container']}>
              <GoToButton buttonText={button} />
            </div>
            <div className={styles['link-view']}>{site}</div>
          </div>
        </div>
        <div className={styles['aside']}>
          {label && <span className={styles['ad-label']}>{label}</span>}
          <div className={styles['ad-info']}>
            {annotation}
            <ScrollFade size={12} />
          </div>
        </div>
      </a>
      <button className={styles['close']} aria-label="Закрыть" onClick={onClose}>
        <IconActionClose16 />
      </button>
    </div>
  );
}
