import { ICalendarMonth, IDateIndex, IRangeDate } from '../types';

interface IParams {
  calendar: Array<ICalendarMonth>;
  date: IRangeDate;
}

export const getDateIndex = (params: IParams): IDateIndex => {
  const { calendar, date } = params;

  const monthIndex = calendar.findIndex(({ year, number }) => year === date.year && number === date.month);
  const dayIndex = calendar[monthIndex].days.findIndex(day => day?.number === date.day);

  return {
    month: monthIndex,
    day: dayIndex,
  };
};
