import { Header3, TooltipDesktop } from '@cian/ui-kit';
import React from 'react';

import { AuctionTitlePopup } from '../../../components/AuctionTitlePopup';
import { ContentRow } from '../../../components/ContentRow';
import { useOfferContext } from '../../../context';

export const AuctionTitleContainer: React.FC = () => {
  const { withAuction } = useOfferContext();

  if (!withAuction) {
    return null;
  }

  return (
    <ContentRow size="M">
      <Header3 data-mark="AuctionTitle">
        Начальная цена, аукцион&nbsp;
        <TooltipDesktop title={<AuctionTitlePopup />} placement={'top'} theme={'white'}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#0468FF" fillOpacity="0.1" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 6C8.55228 6 9 5.55228 9 5C9 4.44772 8.55228 4 8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6ZM9 12V7H7V12H9Z"
              fill="#0468FF"
            />
          </svg>
        </TooltipDesktop>
      </Header3>
    </ContentRow>
  );
};

AuctionTitleContainer.displayName = 'AuctionTitleContainer';
