import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IDeveloperTagType = 'advanced-developer-type';

export interface IDeveloperTag {
  type: 'advanced-developer-type';
  value: boolean;
}

const isDeveloperTag = (tag: TTag): tag is IDeveloperTag => tag.type === IDeveloperTagType;

export const developerTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.from_developer && jsonQuery.from_developer.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isDeveloperTag, () => ({ type: IDeveloperTagType, value }));
    } else {
      return ensureTagNotExists(tags, isDeveloperTag);
    }
  },
};
