/* eslint-disable */
//tslint:disable

export interface IPhotoStubSchema {
  /** Цвет фона заглушки на фото **/
  background?: EBackground | null;
  /** Текст заглушки на фото **/
  text?: string | null;
}

export enum EBackground {
  /** Серый **/
  Gray = 'gray',
  /** Красный **/
  Red = 'red',
}
