import * as React from 'react';

import { ELayoutType } from '../../../../json_query';
import { ILayoutTypeTag } from '../../../state/tags/definitions/layout';
import { TagNew } from '../TagNew';

export interface ILayoutTypeTagItemProps {
  tag: ILayoutTypeTag;
  onDeleteClick(): void;
}

const LayoutTypeMap = {
  [ELayoutType.IsolatedRooms]: 'Изолированные',
  [ELayoutType.UnitedRooms]: 'Смежные',
};

export const LayoutTypeTagItem = ({ tag, onDeleteClick }: ILayoutTypeTagItemProps) => {
  if (tag.value === ELayoutType.Any) {
    return null;
  }

  return <TagNew onDeleteClick={onDeleteClick}>{LayoutTypeMap[tag.value]} комнаты</TagNew>;
};
