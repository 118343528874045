import { Checkbox } from '@cian/ui-kit';
import * as React from 'react';

import { FOfferType, isUrban } from '../../../../../JsonQuery';
import { ELayoutType } from '../../../types/jsonQuery';
import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';

interface ILayout {
  label: string;
  value: ELayoutType | null;
}

const LAYOUT: ILayout[] = [
  { label: 'Неважно', value: null },
  { label: 'Смежная', value: ELayoutType.UnitedRooms },
  { label: 'Изолированная', value: ELayoutType.IsolatedRooms },
];

interface ILayoutProps {
  offerType: FOfferType;
  layoutType: ELayoutType | null;
  withLayout: boolean | null;
  onLayoutTypeChange(value: ELayoutType | null): void;
  onWithLayoutChange(value: boolean | null): void;
}

export const Layout: React.FC<ILayoutProps> = ({
  offerType,
  layoutType,
  withLayout,
  onLayoutTypeChange,
  onWithLayoutChange,
}) => {
  const isLayoutTypeAvailable = isUrban(offerType);

  return (
    <Filter id="advancedFilter_layout" label="Планировка">
      <InlineFilterGroup>
        {isLayoutTypeAvailable && (
          <InlineFilter>
            <RadioButtonGroup options={LAYOUT} value={layoutType} onChange={onLayoutTypeChange} spliced={false} />
          </InlineFilter>
        )}
        <InlineFilter>
          <Checkbox
            label="Схема планировки"
            checked={!!withLayout}
            onChange={event => onWithLayoutChange(event.target.checked ? true : null)}
          />
        </InlineFilter>
      </InlineFilterGroup>
    </Filter>
  );
};
