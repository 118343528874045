import { IAppState } from '../../common/state';
import { selectLocationId } from '../selectLocationId';

/**
 * фильтр пригородов работает только в загородке, в некоторых регионах
 */
export function selectIsWithNeighborsFilterEnabled(state: IAppState): boolean {
  const regionId = selectLocationId(state);

  const isRegionIncluded = state.featureToggle.withNeighborsFilterSettings.includedRegions.includes(regionId);

  const isSuburban = state.filters.jsonQuery._type.startsWith('suburban');

  return isSuburban || isRegionIncluded;
}
