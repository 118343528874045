import { IAppState } from '../../common/state';
import { isFlatRentQuery } from '../../utils/queryString/isFlatRentQuery';
import { selectQueryString } from '../results';

const rentSeoUrlRegex = new RegExp('/snyat-(.+)');

/**
 * Эксперимент "Карта на ссылках ЧПУ" AUD-2284
 * @todo удалить в рамках CD-168864
 */
export function selectIsSeoUrlOnMapExperimentEnabled(state: IAppState): boolean {
  const queryString = selectQueryString(state);
  const url = (state.results.qsToUris || {})[queryString];
  const isCurrentQueryHasSeoURL = url && rentSeoUrlRegex.test(url);
  const allowedDomains = state.featureToggle.mapSeoUrlsSettings.allowedDomains;

  const isFlatRent = isFlatRentQuery(queryString);

  return (
    Boolean(isCurrentQueryHasSeoURL) &&
    isFlatRent &&
    (allowedDomains.length === 0 || allowedDomains.includes(state.currentSubdomain))
  );
}
