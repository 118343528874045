import React, { memo } from 'react';

import { AdFoxBanner } from '../../../../../../components/AdfoxBanner';
import { AdfoxContextData } from '../../../../../../types/adfox/AdfoxContextData';
import { AdfoxContextProvider, PParams } from '../../../../../../utils/AdfoxContext';

const POST_VIEW_ANALYTICS_DATA: AdfoxContextData['postViewAnalyticsData'] = null;
const SIZE: AdfoxContextData['size'] = { width: '100%', minHeight: '20px' };
const P_PARAMS: PParams = { p1: 'ctbng', p2: 'gbvb' };

export const MortgageCardCreatorBanner = memo(function MortgageCardCreatorBanner() {
  return (
    <AdfoxContextProvider size={SIZE} pParams={P_PARAMS} postViewAnalyticsData={POST_VIEW_ANALYTICS_DATA}>
      <AdFoxBanner position={null} />
    </AdfoxContextProvider>
  );
});
