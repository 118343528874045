import * as React from 'react';

import * as styles from './Tags.css';
import { ITagProps } from './components/Tag';

interface ITagsProps {
  id: string;
  children: React.ReactElement<ITagProps> | React.ReactElement<ITagProps>[];
  onRemove(value: string): void;
}

export const Tags: React.FC<ITagsProps> = ({ id, children, onRemove }) => {
  const handleRemove = React.useCallback(
    (value: string) => {
      onRemove(value);
    },
    [onRemove],
  );

  const tags = React.Children.toArray(children) as React.ReactElement<ITagProps>[];

  return (
    <ul className={styles['container']}>
      {tags.map((tag, index) =>
        React.cloneElement(tag, {
          id: `${id}_tag${index}`,
          onRemove: handleRemove,
        }),
      )}
    </ul>
  );
};
