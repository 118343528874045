import * as React from 'react';

import * as styles from './SeoCrossLinks.css';
import { ICrossLinks } from '../../../types/crossLinks';

interface ISeoLinksProps {
  seoBlocks: ICrossLinks[];
}

/* istanbul ignore next */
export function SeoCrossLinks({ seoBlocks }: ISeoLinksProps) {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['container']}>
        {seoBlocks.map((seoBlock, blockIndex) => {
          return (
            seoBlock.links && (
              <div key={`seo-block-${blockIndex}`} className={styles['seo-block']}>
                <div className={styles['title']}>{seoBlock.title}</div>
                <div className={styles['links']}>
                  {seoBlock.links.map((seoLink, linkIndex) => {
                    return (
                      <a key={`seo-link-${linkIndex}`} className={styles['link']} href={seoLink.uri || ''}>
                        {seoLink.name}
                      </a>
                    );
                  })}
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}
