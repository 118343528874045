import * as R from 'ramda';

import { resetTerms, setTerm, setTerms } from './helpers';
import { ECompletionYearType, NonEmptyArray, TJsonQuery } from './types';

export interface ISetCompletionYearTypesParams {
  completionYearTypes: NonEmptyArray<ECompletionYearType> | null;
  yearOffset: number;
  availableYearTypes: ECompletionYearType[];
}

export function setCompletionYearTypes(jsonQuery: TJsonQuery) {
  return ({ completionYearTypes, yearOffset, availableYearTypes }: ISetCompletionYearTypesParams): TJsonQuery => {
    let nextJsonQuery = R.clone(jsonQuery);

    nextJsonQuery = resetTerms(['hand_over', 'year', 'yeargte'])(nextJsonQuery);

    if (completionYearTypes) {
      if (completionYearTypes.includes(ECompletionYearType.HandedOver)) {
        nextJsonQuery = setTerm('hand_over')(nextJsonQuery)(true);
      }

      const year = completionYearTypes
        .filter(value => availableYearTypes.includes(value))
        .map(value => yearOffset + (value as number));

      if (year.length > 0) {
        nextJsonQuery = setTerms('year')(nextJsonQuery)(year);
      }

      if (completionYearTypes.includes(ECompletionYearType.Later)) {
        nextJsonQuery = setTerm('yeargte')(nextJsonQuery)(yearOffset + ECompletionYearType.Later);
      }
    }

    return nextJsonQuery;
  };
}
