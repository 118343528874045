import * as React from 'react';

import { EParkingType } from '../../../../json_query';
import { IParkingTypesTag } from '../../../state/tags/definitions/parking';
import { TagNew } from '../TagNew';

export interface IParkingTypesTagItemProps {
  tag: IParkingTypesTag;
  onDeleteClick(): void;
}

const parkingTypeMap = {
  [EParkingType.Ground]: 'Наземная',
  [EParkingType.Multilevel]: 'Многоуровневая',
  [EParkingType.Underground]: 'Подземная',
  [EParkingType.Rooftop]: 'на крыше',
};

export const ParkingTypesTagItem = ({ onDeleteClick, tag }: IParkingTypesTagItemProps) => {
  return (
    <TagNew onDeleteClick={onDeleteClick}>
      {tag.value === EParkingType.Rooftop
        ? `Парковка ${parkingTypeMap[tag.value]}`
        : `${parkingTypeMap[tag.value]} парковка`}
    </TagNew>
  );
};
