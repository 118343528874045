import * as React from 'react';

import { trackOpenTooltip } from './tracking';
import { useApplicationContext } from '../../../../../../../shared/utils/applicationContext';
import { SuperAgentLevelView } from '../../../../components/SuperAgentLevelView';
import { useOfferContext } from '../../../../context';
import { getQualityLevelSuperAgent, getQualityLevelSuperHost } from '../AgentWorkHonestlyProofsContainer/utils';

export const SuperAgentLevelViewContainer: React.FC = () => {
  const offerData = useOfferContext();
  const superAgent = getQualityLevelSuperAgent(offerData);
  const superHost = getQualityLevelSuperHost(offerData);
  const { config } = useApplicationContext();
  const list = config.get<string>('moderation.QualityLevelTooltip') || '';
  const tooltip = list.split(',');

  const handleOpenTooltip = () => {
    trackOpenTooltip(offerData.offer.id, superAgent);
  };

  if (!superAgent && !superHost) {
    return null;
  }

  return <SuperAgentLevelView isSuperAgent={!!superAgent} tooltip={tooltip} onOpenTooltip={handleOpenTooltip} />;
};
