import * as React from 'react';

import * as styles from './Tag.css';

export interface ITagProps {
  id?: string;
  value: string;
  onRemove?(value: string): void;
}

export const Tag: React.FC<ITagProps> = ({ id, value, onRemove }) => {
  const handleRemove = React.useCallback(() => {
    if (onRemove) {
      onRemove(value);
    }
  }, [onRemove, value]);

  return (
    <li id={id} className={styles['container']} aria-labelledby={`${id}_label`}>
      <span id={`${id}_label`}>{value}</span>
      <button
        className={styles['button']}
        title="Удалить тег"
        aria-controls={id}
        aria-describedby={`${id}_label`}
        aria-keyshortcuts="Delete"
        aria-label="Удалить тег"
        onClick={handleRemove}
      />
    </li>
  );
};
