import { ca } from '@cian/analytics';

export function trackSafeRentFillFormClick(offerId: number) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'click',
    label: 'SafeRentFillForm',
    products: [
      {
        id: offerId,
      },
    ],
  });
}
