import { ca } from '@cian/analytics';

import { getDeveloperContactsLabel } from '../../serp/components/header/developers_contacts';

export function trackPhoneShow(newbuildingId: number) {
  ca('eventEbc', {
    name: 'oldevent',
    category: 'Phones',
    action: 'Open_jk_head_block',
    label: getDeveloperContactsLabel(null),
    products: [{ id: newbuildingId, offerType: 'JK' }],
  });
}
