import { FOfferType, offerTypeFromJsonQuery } from '../../../../utils/category';
import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const ITotalBedroomsTagType = 'advanced-totalBedrooms';

export interface ITotalBedroomsTag {
  type: 'advanced-totalBedrooms';
  offerType: FOfferType;
  min: number | undefined;
  max: number | undefined;
}

export function isTotalBedroomsTag(tag: TTag): tag is ITotalBedroomsTag {
  return tag.type === ITotalBedroomsTagType;
}

export const totalBedroomsTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.bedroom_total && jsonQuery.bedroom_total.value;

    if (value !== undefined) {
      const offerType = offerTypeFromJsonQuery(jsonQuery);

      return ensureSingleTagExists(tags, isTotalBedroomsTag, () => ({
        type: ITotalBedroomsTagType,
        offerType,
        min: value.gte,
        max: value.lte,
      }));
    } else {
      return ensureTagNotExists(tags, isTotalBedroomsTag);
    }
  },
};
