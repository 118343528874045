import { ca } from '@cian/analytics';

export const trackShowBannerFastSearch = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'FastSearch',
    action: 'Show',
    label: 'Banner/from=popup_filters',
  });
};

export const trackOpenChatFastSearch = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'FastSearch',
    action: 'Click',
    label: 'Chat_button/from=banner',
  });
};

export const trackCallbackClickFastSearch = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'FastSearch',
    action: 'Click',
    label: 'Callback_button',
  });
};

export const trackCallbackSuccessFastSearch = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'FastSearch',
    action: 'Submit',
    label: 'Callback',
  });
};
