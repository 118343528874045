import { ENewbuildingLayoutsActionTypes } from '../../actions/newbuildingLayouts';
import { IAppState, TActions } from '../../common/state';
import { INewbuildingLayoutsOn } from '../../types/residentialComplexLayouts';

const defaultState: INewbuildingLayoutsOn = {
  checked: false,
};

export function newbuildingLayoutsOnReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case ENewbuildingLayoutsActionTypes.On: {
      return {
        ...state,
        newbuildingLayoutsOn: {
          checked: true,
        },
      };
    }
    case ENewbuildingLayoutsActionTypes.Off: {
      return {
        ...state,
        newbuildingLayoutsOn: defaultState,
      };
    }
  }

  return state;
}
