import { TNewbuildingPromoSearchTypes } from '../../types/newbuildingPromoSearchTypes';

export function getValueToTagLabelMap(newbuildingPromoSearchTypes: NonNullable<TNewbuildingPromoSearchTypes>) {
  const valueToTagLabelMap = new Map<string, string>();

  const allPromoSearchTypes = newbuildingPromoSearchTypes.mortgage.concat(newbuildingPromoSearchTypes.discount);

  allPromoSearchTypes.forEach(item => {
    valueToTagLabelMap.set(item.value, item.fullName);
  });

  return valueToTagLabelMap;
}
