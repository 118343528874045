/** Тип сделки */
export enum FDealType {
  /** Do not use */
  Unexpected = 0,
  /** Продажа */
  Sale = 1 << 0,
  /** Аренда длительная */
  RentLongterm = 1 << 1,
  /** Аренда посуточная */
  RentDaily = 1 << 2,
  /** Аренда */
  Rent = RentLongterm | RentDaily,
  /** Любой тип сделки */
  Any = Sale | Rent,
}
