import React from 'react';

import styles from './KPName.css';
import { ContentRow } from '../ContentRow';

interface IKPNameProps {
  name: string;
  url?: string;
  onClick?(e: React.MouseEvent<HTMLElement>): void;
}

export function KPName({ url, name, onClick }: IKPNameProps) {
  const Tag = url ? 'a' : 'div';

  return (
    <ContentRow>
      <Tag className={styles['kp']} href={url} target={'_blank'} onClick={onClick}>
        {`КП «‎${name}»`}
      </Tag>
    </ContentRow>
  );
}
