import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPaginationUrl, preparePagination } from './utils';
import { changePage } from '../../filters/state/pagination';
import { selectOffersPerPage } from '../../selectors/experiments/selectOffersPerPage';
import {
  selectAggregatedOffers,
  selectJsonQuery,
  selectPaginationUrls,
  selectQueryString,
} from '../../selectors/results';
import { IPaginationItem } from '../../types/pagination';
import { TThunkDispatch } from '../../types/redux';
import { getTotalPagesCount } from '../../utils/pagination';

interface IPagination {
  pages: IPaginationItem[];
  onPageSelect(page: number): void;
  nextPage?: IPaginationItem;
  prevPage?: IPaginationItem;
}

export function usePagination(): IPagination {
  const dispatch = useDispatch<TThunkDispatch>();

  const offersPerPage = useSelector(selectOffersPerPage);
  const queryString = useSelector(selectQueryString);
  const jsonQuery = useSelector(selectJsonQuery);
  const aggregatedOffers = useSelector(selectAggregatedOffers);
  const paginationUrls = useSelector(selectPaginationUrls);

  const totalPages = React.useMemo(
    () => getTotalPagesCount(offersPerPage, aggregatedOffers),
    [aggregatedOffers, offersPerPage],
  );

  const page = React.useMemo(() => {
    return jsonQuery.page?.value || 1;
  }, [jsonQuery.page?.value]);

  const onPageSelect = React.useCallback((page: number) => dispatch(changePage(page)), [dispatch]);

  const pages = React.useMemo(
    () =>
      totalPages <= 1
        ? []
        : preparePagination({ currentPage: page, totalPages, urls: paginationUrls, query: queryString }),
    [page, paginationUrls, queryString, totalPages],
  );

  const prevPage = React.useMemo<IPaginationItem>(
    () => ({
      disabled: page === 1,
      page: page - 1,
      url: paginationUrls[page - 2] || getPaginationUrl(page - 1, queryString),
      title: 'Назад',
    }),
    [page, paginationUrls, queryString],
  );

  const nextPage = React.useMemo<IPaginationItem>(
    () => ({
      disabled: page === totalPages,
      page: page + 1,
      url: paginationUrls[page] || getPaginationUrl(page + 1, queryString),
      title: 'Дальше',
    }),
    [page, paginationUrls, queryString, totalPages],
  );

  return React.useMemo(
    () => ({
      nextPage,
      prevPage,
      pages,
      onPageSelect,
    }),
    [nextPage, onPageSelect, pages, prevPage],
  );
}
