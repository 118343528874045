import { getTermValue } from './helpers';
import { FDealType, FOfferType, TJsonQuery } from './types';
import { dealTypeFromJsonQuery, offerTypeFromJsonQuery } from './utils';

export function getIsSubsidisedMortgage(jsonQuery: TJsonQuery): boolean | null {
  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const offerType = offerTypeFromJsonQuery(jsonQuery);

  const isSale = dealType === FDealType.Sale;
  const isFlatOld = (offerType & FOfferType.FlatOld) !== 0;

  if (!isSale || !isFlatOld) {
    return null;
  }

  return getTermValue('is_subsidised_mortgage')(jsonQuery);
}
