import * as React from 'react';
import { useSelector } from 'react-redux';
import sanitize from 'sanitize-html';

import { SeoMarkedList } from '../../components/SeoMarkedList';
import { selectMarkedListItems } from '../../selectors/markedList';

export const SeoMarkedListContainer: React.VFC = () => {
  const items = useSelector(selectMarkedListItems);
  const sanitizedItems = React.useMemo(
    () =>
      items.map(item => ({
        ...item,
        title: sanitize(item.title),
        content: sanitize(item.content),
      })),
    [items],
  );

  if (items.length === 0) {
    return null;
  }

  return <SeoMarkedList items={sanitizedItems} />;
};
