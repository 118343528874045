import * as React from 'react';
import { useSelector } from 'react-redux';

import * as styles from './styles.css';
import { configSelector } from '../../../../../../shared/selectors/configSelector';
import { Rating } from '../../../components/Rating';
import { useOfferContext } from '../../../context';
import { isDailyRent } from '../../../utils/computedProps/category/isDailyRent';

export function RatingContainer() {
  const config = useSelector(configSelector);
  const dailyRentReviewsEnabled = config.get<boolean>('Dailyrent.CianReviewsFrontend.Enabled');
  const { offer } = useOfferContext();
  const rating = offer.dailyrent?.rating?.rating;
  const reviewsCount = offer.dailyrent?.rating?.reviewsCount;

  if (!dailyRentReviewsEnabled || !isDailyRent(offer)) {
    return null;
  }

  return <Rating className={styles['rating']} rating={rating} reviewsCount={reviewsCount} />;
}
