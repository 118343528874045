import React, { useMemo, useCallback, MouseEvent } from 'react';

import styles from './GeoLabel.css';
import { IGeoLabel } from '../../types/geo';

interface IGeoLabelProps extends IGeoLabel {
  onLabelClick(label: string): void;
}

export function GeoLabel({ url, label, type, onLabelClick }: IGeoLabelProps) {
  const Elem = useMemo(() => (url ? 'a' : 'span'), [url]);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      onLabelClick(type);
    },
    [onLabelClick, type],
  );

  return (
    <Elem className={styles['link']} href={url} target="_blank" onClick={handleClick}>
      {label}
    </Elem>
  );
}
