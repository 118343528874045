import * as React from 'react';

import { IGeoRailwayTag } from '../../../../state/tags/definitions/geo';
import { TagNew } from '../../TagNew';

export interface IGeoRailwayTagItemProps {
  tag: IGeoRailwayTag;
  onDeleteClick(): void;
}

export const RailwayTagItem = (props: IGeoRailwayTagItemProps) => {
  return <TagNew onDeleteClick={props.onDeleteClick}>{props.tag.text}</TagNew>;
};
