import { Header2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './SeoBotCatcherLinks.css';
import { ILink } from '../../../offer';

interface ISeoBotCatcherLinksProps {
  title: string;
  links: ILink[];
}

export function SeoBotCatcherLinks({ title, links }: ISeoBotCatcherLinksProps) {
  return (
    <div className={styles['container']}>
      <Header2>{title}</Header2>

      <div className={styles['links']}>
        {links.map(item => (
          <a href={item.url} className={styles['link']} key={item.url} title={item.title}>
            {item.title}
          </a>
        ))}
      </div>
    </div>
  );
}
