import * as React from 'react';

import { INotLastFloorTag } from '../../../state/tags/definitions/not_last_floor';
import { TagNew } from '../TagNew';

export interface INotLastFloorTagItemProps {
  tag: INotLastFloorTag;
  onDeleteClick(): void;
}

export const NotLastFloorTagItem = ({ onDeleteClick, tag }: INotLastFloorTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>{(tag.value && 'Не') || 'Только'} последний этаж</TagNew>;
};
