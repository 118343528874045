import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';

import { DownloadExcelModal } from './DownloadExcelModal';
import { IDownloadExcelModalProps } from './types';
import { trackGoToB2bClick, trackSearcherModalShow } from '../../tracking';
import { DoneList } from '../DoneList';

const doneList = [
  'Без ограничений по числу объявлений в файле',
  'Формат таблицы под ваши задачи',
  'Возможность анализировать любой объект и район',
];

export const DownloadExcelModalSearchers: React.VFC<IDownloadExcelModalProps> = props => {
  return (
    <ActionAfterViewObserver callback={trackSearcherModalShow} callbackDelay={1000} wrapper={false}>
      <DownloadExcelModal
        {...props}
        title="Посмотрите аналитику для бизнеса"
        doneList={<DoneList items={doneList} />}
        linkButtonText="Подробнее"
        linkButtonHref="/analiz-rynka-nedvizhimosti-b2b/"
        onLinkBtnClick={trackGoToB2bClick}
      />
    </ActionAfterViewObserver>
  );
};
