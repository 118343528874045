import * as React from 'react';
import { useSelector } from 'react-redux';

import { SummaryHeaderContainer } from './containers/SummaryHeaderContainer';
import { NewbuildingHousesFilterContainer } from '../../../containers/NewbuildingHousesFilter';
import { getIsHousesFilterEnabled } from '../../../selectors/newbuildingHeader';
import { getAggregatedOffers } from '../../../selectors/offers';
import { SummarySection } from '../../components/Summary';

export function EmptyPageSummaryContainer() {
  const aggregatedOffers = useSelector(getAggregatedOffers);
  const isNewbuildingHousesFilterEnabled = useSelector(getIsHousesFilterEnabled);

  if (aggregatedOffers === 0 && isNewbuildingHousesFilterEnabled) {
    return (
      <SummarySection
        leftColumn={
          <>
            <NewbuildingHousesFilterContainer />
            <SummaryHeaderContainer />
          </>
        }
        rightColumn={null}
      />
    );
  }

  return null;
}
