import { IAppState, TActions } from '../../../common/state';
import { ERepair, IJsonQueryTerms } from '../../../json_query';

const TOGGLE_REPAIR_ACTION_TYPE = 'filters/repair/REPAIR_TOGGLED';
const SET_REPAIR_ACTION_TYPE = 'filters/repair/SET';

export interface IRepairToggledAction {
  type: 'filters/repair/REPAIR_TOGGLED';
  repair: ERepair;
  value: boolean;
}

export interface IRepairSetAction {
  type: 'filters/repair/SET';
  value: ERepair[];
}

export function toggleRepair(repair: ERepair, value: boolean): IRepairToggledAction {
  return {
    type: TOGGLE_REPAIR_ACTION_TYPE,
    repair,
    value,
  };
}

export function setRepair(value: ERepair[]): IRepairSetAction {
  return {
    type: SET_REPAIR_ACTION_TYPE,
    value,
  };
}

export function repairReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case SET_REPAIR_ACTION_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            repair: action.value.length
              ? {
                  type: 'terms',
                  value: action.value,
                }
              : undefined,
          },
        },
      };

    case TOGGLE_REPAIR_ACTION_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            repair: changeRepair(
              state.filters.jsonQuery.repair && state.filters.jsonQuery.repair.value,
              action.repair,
              action.value,
            ),
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'repair') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              repair: changeRepair(
                state.filters.jsonQuery.repair && state.filters.jsonQuery.repair.value,
                action.tag.value,
                false,
              ),
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
}

function changeRepair(
  repairs: ERepair[] | undefined,
  repair: ERepair,
  value: boolean,
): IJsonQueryTerms<ERepair> | undefined {
  if (!value) {
    if (repairs && repairs.length > 1) {
      return {
        type: 'terms',
        value: repairs.filter(item => item !== repair),
      };
    }

    return undefined;
  } else {
    return {
      type: 'terms',
      value: (repairs && repairs.concat(repair)) || [repair],
    };
  }
}
