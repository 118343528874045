import * as React from 'react';

import { ELandStatusType, NonEmptyArray } from '../../../../../JsonQuery';
import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface ILandStatusOption {
  label: string;
  value: ELandStatusType | null;
}

const LAND_STATUS_OPTIONS: ILandStatusOption[] = [
  { label: 'Неважно', value: null },
  { label: 'ИЖС', value: ELandStatusType.IndividualHousingConstruction },
  { label: 'Садоводство', value: ELandStatusType.Gardening },
  { label: 'ДНП', value: ELandStatusType.CountryhouseNonCommercialPartnership },
  { label: 'Личное подсобное хозяйство', value: ELandStatusType.PrivateFarm },
  { label: 'Фермерское хозяйство', value: ELandStatusType.Farm },
  { label: 'Земля промназначения', value: ELandStatusType.IndustrialLand },
];

interface ILandStatusProps {
  value: NonEmptyArray<ELandStatusType> | null;
  onChange(value: NonEmptyArray<ELandStatusType> | null): void;
}

export const LandStatus: React.FC<ILandStatusProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_landStatus" label="Статус участка">
      <CheckboxButtonGroupUnselectable options={LAND_STATUS_OPTIONS} value={value} onChange={onChange} />
    </Filter>
  );
};
