import { ELabelColor, ILabel } from '../../../types/label';
import { IOffer } from '../../../types/offer';

export function getNewbuildingFeaturePromoLabels(offer: IOffer): ILabel[] | null {
  if (!offer.promoInfo) {
    return null;
  }

  if (offer.promoInfo.isBlackFriday) {
    const labels: ILabel[] = [];

    if (offer.promoInfo.isExclusive) {
      labels.push({
        color: ELabelColor.BlackBlueGradient,
        text: 'Эксклюзив',
      });
    }

    labels.push({
      color: ELabelColor.SurfaceBlueIconed,
      text: offer.promoInfo.name,
    });

    return labels;
  }

  return [
    {
      color: ELabelColor.Red,
      text: offer.promoInfo.name,
    },
  ];
}
