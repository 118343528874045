import * as React from 'react';

import { ERoomsTotal } from '../../../../json_query';
import { IRoomsTotalTag } from '../../../state/tags/definitions/rooms_total';
import { TagNew } from '../TagNew';

export interface IRoomsTotalTagItemProps {
  tag: IRoomsTotalTag;
  onDeleteClick(): void;
}

const roomsTotalMap = {
  [ERoomsTotal.One]: 'от 1',
  [ERoomsTotal.Two]: 'от 2',
  [ERoomsTotal.Three]: 'от 3',
  [ERoomsTotal.Four]: 'от 4',
  [ERoomsTotal.Five]: 'от 5',
};

export const RoomsTotalTagItem = ({ tag, onDeleteClick }: IRoomsTotalTagItemProps) => {
  if (tag.value === ERoomsTotal.Any) {
    return null;
  }

  return <TagNew onDeleteClick={onDeleteClick}>Комнат в квартире: {roomsTotalMap[tag.value]}</TagNew>;
};
