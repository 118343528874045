import { ITypedReduxAction } from '../../types/redux';
import { actionGenerator } from '../../utils/redux';

export enum ENewbuildingLayoutsActionTypes {
  On = 'NewbuildingLayouts/On',
  Off = 'NewbuildingLayouts/Off',
}

export type TNewbuildingLayoutsOn = ITypedReduxAction<ENewbuildingLayoutsActionTypes.On>;
export type TNewbuildingLayoutsOff = ITypedReduxAction<ENewbuildingLayoutsActionTypes.Off>;

/**
 * Action включения выдачи по планировкам
 */
export const newbuildingLayoutsOn: () => TNewbuildingLayoutsOn = actionGenerator<ENewbuildingLayoutsActionTypes.On>(
  ENewbuildingLayoutsActionTypes.On,
);

/**
 * Action выключения выдачи по планировкам
 */
export const newbuildingLayoutsOff: () => TNewbuildingLayoutsOff = actionGenerator<ENewbuildingLayoutsActionTypes.Off>(
  ENewbuildingLayoutsActionTypes.Off,
);

export type TNewbuildingLayoutsSwitcherActions = TNewbuildingLayoutsOn | TNewbuildingLayoutsOff;
