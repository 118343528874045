import { ResponseError } from '../../errors';
import { prepareSimilarNewobjects } from '../../mappers/prepareSimilarNewobjects';
import {
  fetchGetSimilarNewobjects,
  TGetSimilarNewobjectsResponse,
} from '../../repositories/monolith-python/v1/get-similar-newobjects';
import { IApplicationContext } from '../../types/applicationContext';
import { ISimilarNewobject } from '../../types/similarNewobjects';

interface IParameters {
  newobjectId: number;
}

export async function fetchSimilarNewobjects(
  context: IApplicationContext,
  parameters: IParameters,
): Promise<ISimilarNewobject[] | null> {
  const { httpApi, logger } = context;

  try {
    const similarNewobjectsResponse: TGetSimilarNewobjectsResponse = await fetchGetSimilarNewobjects({
      httpApi,
      parameters,
    });

    if (similarNewobjectsResponse.statusCode !== 200) {
      throw new ResponseError({
        domain: 'fetchSimilarNewobjects',
        message: similarNewobjectsResponse.response.message,
        details: { errors: similarNewobjectsResponse.response.errors },
      });
    }

    return prepareSimilarNewobjects(similarNewobjectsResponse.response);
  } catch (ex) {
    logger.warning(ex, {
      degradation: 'Similar newobjects',
    });

    return null;
  }
}
