import { ca } from '@cian/analytics';

export function trackApplySelectedLocation(label: string | undefined) {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'popup_GEO_save',
    label,
  });
}
