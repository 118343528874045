import { IOffer } from '../../../types/offer';

export function getFlatTitle(offer: IOffer) {
  const { isApartments, flatType, roomsCount } = offer;
  if (flatType === 'studio') {
    return isApartments ? 'Апартаменты-студия' : 'Студия';
  }

  if (flatType === 'openPlan') {
    return 'Своб. планировка';
  }

  return roomsCount ? `${roomsCount}-комн. ${isApartments ? 'апартаменты' : 'квартира'}` : '';
}
