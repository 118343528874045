import { Switch, UIHeading4 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './NewbuildingLayoutsFilter.css';

interface INewbuildingLayoutsFilterProps {
  checked: boolean;
  onChange(event: React.ChangeEvent<HTMLInputElement>, value: boolean): void;
}

export const NewbuildingLayoutsFilter = ({ onChange, checked }: INewbuildingLayoutsFilterProps) => {
  return (
    <div className={styles['container']}>
      <label htmlFor="newbuildingLayoutsFilterSwitch" className={styles['label']}>
        <UIHeading4>Показать планировки</UIHeading4>
      </label>
      <Switch id="newbuildingLayoutsFilterSwitch" checked={checked} onChange={onChange} />
    </div>
  );
};
