import cn from 'classnames';
import * as React from 'react';

import { ZoomIn, ZoomOut } from './Icons';
import * as styles from './MapControls.css';

interface IMapControlsProps {
  zoomIn(): void;
  zoomOut(): void;
}

export const MapControls: React.FC<IMapControlsProps> = ({ zoomIn, zoomOut }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['map-controls']}>
        <button className={cn(styles['button'], styles['zoom-in'])} onClick={zoomIn}>
          <ZoomIn />
        </button>
        <button className={cn(styles['button'], styles['zoom-out'])} onClick={zoomOut}>
          <ZoomOut />
        </button>
      </div>
    </div>
  );
};
