import * as React from 'react';

import { ESaleType } from '../../../../json_query';
import { ISaleTypesTag } from '../../../state/tags/definitions/sale_type';
import { TagNew } from '../TagNew';

export interface ISaleTypesTagItemProps {
  tag: ISaleTypesTag;
  onDeleteClick(): void;
}

const SaleTypeMap = {
  [ESaleType.F3]: '214-Ф3',
  [ESaleType.GSKContract]: 'Договор ЖСК',
  [ESaleType.Reassignment]: 'Переуступка',
  [ESaleType.PreliminaryContract]: 'Предварительный договор купли-продажи',
  [ESaleType.InvestmentContract]: 'Договор инвестирования',
  [ESaleType.Free]: 'Свободная',
  [ESaleType.Alternative]: 'Альтернатива',
};

export const SaleTypesTagItem = ({ tag, onDeleteClick }: ISaleTypesTagItemProps) => {
  if (tag.value === ESaleType.Any) {
    return null;
  }

  return <TagNew onDeleteClick={onDeleteClick}>{SaleTypeMap[tag.value]}</TagNew>;
};
