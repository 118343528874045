import { NonEmptyArray } from '../types';

export const MOSCOW_ID = 1;
export const MOSCOW_REGION_ID = 4593;
export const REGIONS_WITH_UNDERGROUND: NonEmptyArray<number> = [
  MOSCOW_ID,
  2,
  MOSCOW_REGION_ID,
  4743,
  4777,
  4885,
  4897,
  4966,
];
export const PIK_PROMO_REGIONS: NonEmptyArray<number> = [MOSCOW_ID, MOSCOW_REGION_ID];
