import { EParkingType } from '../../../../json_query';
import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IParkingTypesTagType = 'advanced-parking';

export interface IParkingTypesTag {
  type: 'advanced-parking';
  value: EParkingType;
}

function isParkingTypesTag(tag: TTag): tag is IParkingTypesTag {
  return tag.type === IParkingTypesTagType;
}

export const parkingTypesTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.parking_type && jsonQuery.parking_type.value;

    let nextTags = tags.filter(
      tag => tag.type !== IParkingTypesTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: EParkingType) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IParkingTypesTag => isParkingTypesTag(tag) && tag.value === value,
            () => ({ type: IParkingTypesTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
