import classNames from 'classnames';
import * as React from 'react';

import * as style from './style.css';

interface INewbuildingPromoCarouselLayoutProps {
  children?: React.ReactNode;
  isShown?: boolean;
}

export function NewbuildingPromoCarouselLayout({ children, isShown }: INewbuildingPromoCarouselLayoutProps) {
  return (
    <div
      className={classNames(style['container'], isShown && style['is-visible'])}
      data-testid="NewbuildingPromoCarouselLayout"
    >
      {children}
    </div>
  );
}
