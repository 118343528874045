import { range } from 'ramda';

import { getPaginationUrl } from './getPaginationUrl';
import { IPaginationItem } from '../../../types/pagination';

interface IPreparePaginationOptions {
  currentPage: number;
  totalPages: number;
  query: string;
  urls: string[];
}

const PAGINATION_WIDTH = 7;

export function preparePagination(options: IPreparePaginationOptions): IPaginationItem[] {
  const { totalPages, currentPage } = options;
  // если общее количеств страниц не больше кол-ва элементов в блоке PAGINATION_WIDTH, выводим все
  if (totalPages <= PAGINATION_WIDTH) {
    return range(1, totalPages + 1).map(page => preparePage({ page, ...options }));
  }

  const pagination: IPaginationItem[] = [];

  const endPage = Math.min(Math.max(PAGINATION_WIDTH, currentPage + 3), totalPages);
  const startPage = endPage - 5;

  // В блоке пагинации всегда отображаем 1 страницу
  // [1] [startPage] [ ] [ ] [ ] [ ] [endPage]
  pagination.push(preparePage({ page: 1, ...options }));

  const mainRange = range(startPage, endPage + 1);

  mainRange.forEach((page, index) => {
    let title: string | undefined;

    if ((index === 0 && page > 2) || (index === mainRange.length - 1 && page < totalPages)) {
      title = '...';
    }
    pagination.push(preparePage({ page, title, ...options }));
  });

  return pagination;
}

interface IPreparePageOptions extends IPreparePaginationOptions {
  page: number;
  title?: string;
}

function preparePage({ page, urls, query, currentPage, title }: IPreparePageOptions): IPaginationItem {
  return {
    page,
    url: urls[page - 1] || getPaginationUrl(page, query),
    disabled: page === currentPage,
    title: title || page.toString(),
  };
}
