import { InlineText2 } from '@cian/ui-kit';
import React from 'react';

import styles from './TimeLabel.css';

interface ITimeLabelProps {
  added: string;
  humanizedTime: string;
}

export const TimeLabel = (props: ITimeLabelProps) => {
  return (
    <div className={styles['timeLabel']}>
      <div className={styles['relative']}>
        <InlineText2 color="gray60_100">{props.humanizedTime}</InlineText2>
      </div>
      <div className={styles['absolute']}>
        <InlineText2 color="gray60_100">{props.added}</InlineText2>
      </div>
    </div>
  );
};
