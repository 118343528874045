import { Button, FormFieldInput } from '@cian/ui-kit';
import { IconActionPlus16 } from '@cian/ui-kit-design-tokens/icons';
import React from 'react';

import { ContentRow } from '../../../../components/ContentRow';
import { useAuctionContext } from '../../../../context/AuctionContext';

export interface IAuctionBetManagerProps {
  onClose(): void;
}

export function AuctionBetManagerContainer({ onClose }: IAuctionBetManagerProps) {
  const { isPersonalBet, formik, currentBet, incrementBet } = useAuctionContext();

  const handleSubmit = async () => {
    if (!formik.isSubmitting) {
      await formik.submitForm();
      onClose();
    }
  };

  return (
    <>
      <ContentRow size="M">
        {isPersonalBet ? (
          <>
            Хотите поднять объявление
            <br />
            на первую страницу поиска?
            <br />
            Повысьте ставку:
          </>
        ) : (
          <>
            Хотите опередить объявление
            <br />
            конкурента? Повысьте ставку:
          </>
        )}
      </ContentRow>

      <ContentRow size="M">
        <FormFieldInput
          id="bet"
          name="bet"
          type="number"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={`${formik.values.bet}`}
          leftAdornment="₽"
          rightAdornment={<IconActionPlus16 cursor="pointer" color="primary_100" onClick={incrementBet} />}
          errorMessage={formik.errors.bet}
          invalid={!!formik.errors.bet}
          disabled={formik.isSubmitting}
        />
      </ContentRow>

      {formik.values.bet !== currentBet && (
        <Button
          size="XS"
          onClick={handleSubmit}
          disabled={!!formik.errors.bet || formik.isSubmitting}
          loading={formik.isSubmitting}
        >
          Ок
        </Button>
      )}
    </>
  );
}
