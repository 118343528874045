import { numberToPrettyString } from '@cian/utils';

import { IOffer } from '../../../types/offer';

export function getRoomArea(offer: IOffer): string | undefined {
  if (offer.roomArea) {
    return numberToPrettyString(Number(offer.roomArea));
  }

  return undefined;
}
