import * as React from 'react';

import { ESuburbanWCType } from '../../../../json_query';
import { ISuburbanWCTypeTag } from '../../../state/tags/definitions/suburban_wc';
import { TagNew } from '../TagNew';

export interface ISuburbanWCTypeTagItemProps {
  tag: ISuburbanWCTypeTag;
  onDeleteClick(): void;
}

const suburbanWCTypeMap = {
  [ESuburbanWCType.Inside]: 'в доме',
  [ESuburbanWCType.Outside]: 'на улице',
};

export const SuburbanWCTypeTagItem = ({ tag, onDeleteClick }: ISuburbanWCTypeTagItemProps) => {
  if (tag.value === ESuburbanWCType.Any) {
    return null;
  }

  return <TagNew onDeleteClick={onDeleteClick}>Санузел {suburbanWCTypeMap[tag.value]}</TagNew>;
};
