import { ca } from '@cian/analytics';

export function trackTooltipSearchClick(label: string) {
  ca('event', {
    name: 'oldevent',
    category: 'FastFilters',
    action: 'click_tooltip',
    label,
  });
}
