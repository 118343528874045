import { TOfferContextData } from '../../../../types/offer-card';

export const getIsUserIdentifiedByDocuments = (offerData: TOfferContextData) => {
  return Boolean(offerData.offer.user?.agentModerationInfo?.showUserIdentifiedByDocuments);
};

export const getQualityLevelSuperAgent = (offerData: TOfferContextData) => {
  return offerData.offer.user?.agentModerationInfo?.qualityLevelInfo?.showSuperAgent;
};

export const getQualityLevelSuperHost = (offerData: TOfferContextData) => {
  return offerData.offer.user?.agentModerationInfo?.qualityLevelInfo?.showSuperHost;
};
