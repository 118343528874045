import { IConfig } from '@cian/config/shared';

/**
 * Эти ручки нельзя сгенерить свагером из-за того, что они возвращают файл (тип файла не поддерживается)
 */

import { IDownloadXlsListingFileRequest, TDownloadXlsListingFileResponse } from './types';

const defaultConfig = {
  method: 'POST',
  microserviceName: 'priceEstimator',
  pathApi: 'v1/download-xls-listing-file/',
};

export interface IDownloadXlsListingFileOptions {
  parameters: IDownloadXlsListingFileRequest;
  config: IConfig;
}

export async function fetchXlsListingFile({
  parameters,
  config,
}: IDownloadXlsListingFileOptions): Promise<TDownloadXlsListingFileResponse> {
  const { microserviceName, pathApi, method } = defaultConfig;

  const url = getBaseUriForService(microserviceName, config) + pathApi;
  const options: RequestInit = {
    body: JSON.stringify(parameters),
    method,
    credentials: 'include',
  };

  const response = await fetch(url, options);

  if (response.status !== 200) {
    const jsonResponse = await response.json();

    return { statusCode: response.status as 400, response: jsonResponse };
  }

  const blob = await response.blob();

  return { statusCode: response.status, response: blob };
}

function getBaseUriForService(mcsName: string, config: IConfig) {
  return config.get<string>(`${mcsName}.browserUrl`);
}
