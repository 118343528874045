import { IMicrofrontendManifest } from '@cian/mf-registry/shared';
import { Action } from 'redux';

export type TComplainComplaintsFetched = 'complain/complaintsFetched';
export type TComplainFailRequest = 'complain/failRequest';

export interface IComplainFailRequest extends Action<TComplainFailRequest> {
  isError: boolean;
  type: TComplainFailRequest;
}

export interface IComplainComplaintsFetched extends Action<TComplainComplaintsFetched> {
  manifest: IMicrofrontendManifest | null;
  type: TComplainComplaintsFetched;
}

export const COMPLAIN_FAIL_REQUEST: TComplainFailRequest = 'complain/failRequest';
export const COMPLAIN_COMPLAINTS_FETCHED: TComplainComplaintsFetched = 'complain/complaintsFetched';
