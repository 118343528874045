import { ca } from '@cian/analytics';

export const trackTrynowClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'popup_more_param_banner',
    label: 'ListingLK',
  });
};

export const trackNotnowClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'popup_more_param_banner',
    label: 'Cancel',
  });
};

export const trackBannerShow = () => {
  ca('eventSite', {
    name: 'soprevent',
    category: 'Listing',
    action: 'show',
    label: 'popup_more_param_banner',
  });
};
