import { ca } from '@cian/analytics';

import { IJsonQuery } from '../../repositories/common/json_query';

interface IProjectsProduct {
  id: number;
  offerType: 'CSP';
  position: number;
}

export function trackSuggestShow(sc: IJsonQuery, products: IProjectsProduct[]): void {
  ca('eventSite', {
    action: 'show_sopr',
    category: 'Listing',
    label: 'suburban_csp',
    event: 'oldevent',
    sc,
    products,
  });
}

export function trackProjectClick(id: number, position: number, sc: IJsonQuery): void {
  ca('eventSite', {
    action: 'Click_sopr',
    category: 'Listing',
    label: 'suburban_csp',
    name: 'oldevent',
    sc,
    products: [
      {
        id,
        offerType: 'CSP',
        position,
      },
    ],
  });
}

export function trackAllProjectsClick(sc: IJsonQuery): void {
  ca('eventSite', {
    action: 'show_all_sopr',
    category: 'Listing',
    label: 'suburban_csp',
    name: 'oldevent',
    sc,
    useLastProducts: true,
  });
}
