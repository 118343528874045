import { LinkButton, Button } from '@cian/ui-kit';
import * as React from 'react';

import { IPaginationItem } from '../../../types/pagination';

interface IPaginationButtonProps extends IPaginationItem {
  onClick(e: React.SyntheticEvent<HTMLAnchorElement>, page: number): void;
}

export function PaginationButton({ onClick, page, url, disabled, title }: IPaginationButtonProps) {
  return disabled ? (
    <Button theme="stroke_secondary" disabled>
      {title}
    </Button>
  ) : (
    <LinkButton theme="stroke_secondary" href={url} onClick={e => onClick(e, page)}>
      {title}
    </LinkButton>
  );
}
