import { ca } from '@cian/analytics';

export const trackBannerShow = () => {
  ca('event', {
    name: 'oldevent',
    category: 'Application_to_realtor',
    action: 'Show',
    label: 'Banner',
  });
};

export const trackSendFormClick = () => {
  ca('event', {
    name: 'oldevent',
    category: 'Application_to_realtor',
    action: 'Click_to_send',
    label: 'Page',
  });
};
