import { isSuburban } from './category/isSuburban';
import { getAgentListsLabels } from './labels/getAgentListsLabels';
import { getOfferPromoLabels } from './labels/getOfferPromoLabels';
import { getOfferRankingLabels } from './labels/getOfferRankingLabels';
import { getOfferSavedSearchLabels } from './labels/getOfferSavedSearchLabels';
import { TComputedPropMapper } from '../../types/computed-props-mapper';
import { EGalleryLabelColor, IGalleryLabel } from '../../types/gallery-label';

export const mediaLabels: TComputedPropMapper<IGalleryLabel[]> = (props, ctx) => {
  const { offer, withAuction, villagePromotionLabel } = props;

  /**
   * @todo Удалить фича-тогл
   * @description Данный функционал появился в задаче CD-187921, будет удалён в задаче CD-189395
   */
  const isElectronicTradingEnabled = ctx.config.getStrict<boolean>('Audience.ElectronicTrading.Enabled');

  let labels: IGalleryLabel[] = [];

  if (isSuburban(offer)) {
    const { villageMortgageAllowed } = offer;

    const promotion = (offer.kp?.promotions || [])[0];
    if (villagePromotionLabel && promotion) {
      return [
        {
          color: EGalleryLabelColor.Orange,
          customColor: promotion.color,
          label: promotion.name,
          iconUrl: promotion.labelPromoLogoUrl,
        },
      ];
    }

    if (villageMortgageAllowed) {
      return [
        {
          color: EGalleryLabelColor.Orange,
          label: 'Сельская ипотека 3%',
        },
      ];
    }
  }

  /**
   * Для случаев, когда эл. торги включены, лейблы "Залоговая недвижимость" и "Аукцион" из галереи необходимо убрать.
   */
  if (!isElectronicTradingEnabled) {
    if (!offer.user?.agentLists && !!offer.user && !!offer.user.bank && !withAuction) {
      labels.push({ label: 'Залоговая недвижимость', color: EGalleryLabelColor.LightGrey } as IGalleryLabel);
    }

    if (!offer.user?.agentLists && withAuction) {
      labels.push({ label: 'Залоговая недвижимость, аукцион', color: EGalleryLabelColor.LightGrey } as IGalleryLabel);
    }
  }

  if (offer.user?.agentLists) {
    labels = [...labels, ...getAgentListsLabels(offer.user?.agentLists, ctx)];
  }

  if (offer.savedSearchLabels) {
    labels = [...labels, ...getOfferSavedSearchLabels(offer.savedSearchLabels)];
  }

  if (offer.promoInfo) {
    labels = [...labels, ...getOfferPromoLabels(offer)];
  }

  if (offer.photoLabels) {
    labels = [...labels, ...getOfferRankingLabels(offer)];
  }

  return labels;
};
