import classNames from 'classnames';
import React, { useRef, useState, useCallback, useMemo, FC, useEffect } from 'react';
import { throttle } from 'throttle-debounce';

import styles from './FiltersSection.css';
import { getCoords } from '../../../browser/utils/dom';
import { EPosition } from '../../filters/components/filters/types';

interface IFiltersSectionProps {
  position: EPosition;
  setUpdateHeightCb?(cb: () => void): void;
  onChangePosition(position: EPosition): void;
}

interface IFiltersSectionState {
  minHeight: number;
  withoutTransition: boolean;
}

export const FiltersSection: FC<React.PropsWithChildren<IFiltersSectionProps>> = ({
  children,
  position,
  setUpdateHeightCb,
  onChangePosition,
}) => {
  const filtersRef = useRef<HTMLDivElement>(null);
  const currentScrollTop = useRef<number>(0);
  const filtersOffsetTop = useRef<number>(0);
  const initialized = useRef<boolean>(false);

  const [{ minHeight, withoutTransition }, setState] = useState<IFiltersSectionState>({
    minHeight: 0,
    withoutTransition: false,
  });

  const updateFiltersHeight = useCallback(() => {
    if (!filtersRef.current) {
      return;
    }

    const filtersHeight = Math.round(filtersRef.current.getBoundingClientRect().height);

    if (filtersHeight !== minHeight) {
      setState(state => ({ ...state, minHeight: filtersHeight }));
    }
  }, [minHeight]);

  const handleScroll = useMemo(
    () =>
      throttle(50, () => {
        if (!filtersRef.current) {
          return;
        }

        const filtersHeight = Math.round(filtersRef.current.getBoundingClientRect().height);

        // Если изменилась высота фильтров, то не обрабатываем сработавший скролл
        if (filtersHeight !== minHeight) {
          setState(state => ({ ...state, minHeight: filtersHeight }));

          return;
        }

        const newScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        const areFiltersVisible = newScrollTop <= filtersOffsetTop.current + filtersHeight;
        const scrollingDown = newScrollTop > currentScrollTop.current;
        const scrollingUp = newScrollTop < currentScrollTop.current;
        currentScrollTop.current = newScrollTop;

        const switchPosition = (newPosition: EPosition) => {
          switch (newPosition) {
            case EPosition.FixedHidden:
              setState(state => ({
                ...state,
                withoutTransition: position === EPosition.default,
              }));
              onChangePosition(EPosition.FixedHidden);
              break;

            case EPosition.Fixed:
              setState(state => ({
                ...state,
                withoutTransition: false,
              }));
              onChangePosition(EPosition.Fixed);
              break;

            default:
              onChangePosition(EPosition.default);
          }
        };

        if (scrollingUp) {
          if (newScrollTop <= filtersOffsetTop.current) {
            switchPosition(EPosition.default);
          } else {
            switchPosition(EPosition.Fixed);
          }
        } else if (scrollingDown) {
          if (!areFiltersVisible) {
            switchPosition(EPosition.FixedHidden);
          }
        }
      }),
    [minHeight],
  );

  useEffect(() => {
    function initialize() {
      if (!filtersRef.current) {
        return;
      }
      const coords = getCoords(filtersRef.current);

      filtersOffsetTop.current = coords.top;
      currentScrollTop.current = document.body.scrollTop;

      initialized.current = true;
    }

    if (!initialized.current) {
      initialize();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      removeEventListener('scroll', handleScroll);
      handleScroll.cancel();
    };
  }, [handleScroll]);

  useEffect(() => {
    if (setUpdateHeightCb) {
      setUpdateHeightCb(updateFiltersHeight);
    }
  }, [setUpdateHeightCb, updateFiltersHeight]);

  return (
    <div
      style={{ minHeight: `${minHeight}px` }}
      className={classNames(
        styles['filters'],
        (position === EPosition.Fixed || position === EPosition.FixedHidden) && styles['fixed'],
        position === EPosition.FixedHidden && styles['hidden'],
        withoutTransition && styles['notransition'],
      )}
    >
      <div ref={filtersRef} className={classNames(styles['filters-container'], styles['main-filters-container'])}>
        {children}
      </div>
    </div>
  );
};
