import { useOfferContext } from '../../../fragments/OfferCardWidget/shared/context';
import { useApplicationContext } from '../../utils/applicationContext';

export function useGetIsElectronicTrading(): boolean {
  const { config } = useApplicationContext();
  const { offer } = useOfferContext();
  const isElectronicTrading = !!offer.tradingLink;
  /**
   * @todo Удалить фича-тогл
   * @description Данный функционал появился в задаче CD-187922, будет удалён в задаче CD-189395
   */
  const isElectronicTradingEnabled = config.getStrict<boolean>('Audience.ElectronicTrading.Enabled');

  return isElectronicTradingEnabled && isElectronicTrading;
}
