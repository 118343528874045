import { EJournalActionTypes } from '../../actions/journal';
import { IAppState, TActions } from '../../common/state';

export function journalHighlightsReducer(state: IAppState, action: TActions) {
  if (action.type === EJournalActionTypes.Reset) {
    return {
      ...state,
      journalHighlights: [],
    };
  }

  return state;
}
