import { IconMapTrain16 } from '@cian/ui-kit-design-tokens/icons';
import { plural } from '@cian/utils';
import React, { useMemo, useCallback, MouseEvent } from 'react';

import { SpecialGeo } from '../../../components/SpecialGeo';
import { useOfferContext } from '../../../context';

export function RailwayContainer() {
  const { railway, tracking, qsToUris } = useOfferContext();

  const url = useMemo(() => {
    if (railway) {
      return (qsToUris && qsToUris[railway.qs]) || `/cat.php?${railway.qs}`;
    }

    return '';
  }, [qsToUris, railway]);

  const remoteness = useMemo(() => {
    if (railway && railway.time && railway.time > 0) {
      const unit = plural(railway.time, ['минута', 'минуты', 'минут']);
      const transportType = railway.travelType === 'byFoot' ? 'пешком' : 'на транспорте';

      return `${railway.time} ${unit} ${transportType}`;
    }

    return undefined;
  }, [railway]);

  const handleUndergroundClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      tracking.trackAddressClick('railway');
    },
    [tracking.trackAddressClick],
  );

  if (!railway) {
    return null;
  }

  return (
    <SpecialGeo
      url={url}
      label={railway.name}
      icon={<IconMapTrain16 color="gray_icons_100" />}
      remoteness={remoteness}
      onClick={handleUndergroundClick}
    />
  );
}
