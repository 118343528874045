import { compose } from 'ramda';
import { connect } from 'react-redux';

import { linkGroupsSelector } from './utils/selectors';
import { IAppState } from '../../../common/state';
import { selectSuburbanBlackFridayBannerUrl } from '../../../selectors/selectSuburbanBlackFridayBannerUrl';
import { EDealType } from '../../../types/dealType';
import { dealTypeFromJsonQuery, isSuburban, offerTypeFromJsonQuery } from '../../../utils/category';
import { withEmptyRendering } from '../../../utils/hoc/withEmptyRendering';
import { IQuickLinksProps, IQuickLinksStateProps, QuickLinks } from '../../components/QuickLinks';

export function mapStateToProps(state: IAppState): IQuickLinksStateProps {
  const { jsonQuery } = state.results;
  const offerType = offerTypeFromJsonQuery(jsonQuery);
  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const countrysideBlackFridayLink = selectSuburbanBlackFridayBannerUrl(state);

  return {
    countrysideBlackFridayLink: dealType === EDealType.Sale ? countrysideBlackFridayLink : undefined,
    level: (state.results.quickLinks && state.results.quickLinks.level) || null,
    linkGroups: linkGroupsSelector(state),
    isSuburbanListing: isSuburban(offerType),
  };
}

export function shouldHideQuickLinks(props: IQuickLinksProps) {
  return !props.level || !props.linkGroups;
}

export const QuickLinksContainer = compose(
  connect(mapStateToProps),
  withEmptyRendering(shouldHideQuickLinks),
)(QuickLinks);
