import { EActionType } from '../../../../../../../shared/repositories/search-offers.legacy/v2/types/dailyrentData';

export const getTitleByActionType = (actionType?: EActionType) => {
  switch (actionType) {
    case EActionType.OtaBooking:
      return 'Забронировать';
    case EActionType.OtaCheckDates:
      return 'Проверить даты';
    default:
      return undefined;
  }
};

export const getCaLabelByActionType = (actionType?: EActionType) => {
  switch (actionType) {
    case EActionType.OtaBooking:
      return 'ToCardBook';
    case EActionType.OtaCheckDates:
      return 'ToCardCheckDates';
    default:
      return undefined;
  }
};
