import { isSuburban } from './category/isSuburban';
import { getOrder } from './geo/getOrder';
import { TComputedPropMapper } from '../../types/computed-props-mapper';
import { IJsonQueryRailway } from '../../types/jsonQuery';
import { IHighwayInfo } from '../../types/offer';

export const highway: TComputedPropMapper<IHighwayInfo | undefined> = props => {
  const highways = props.offer.geo?.highways || [];
  const sortedGeoIds = props.offer.geo?.sortedGeoIds || [];

  const highwaysOrderMap: Map<number, number> = (
    sortedGeoIds.filter(item => item.type === 'highway') as IJsonQueryRailway[]
  ).reduce((map, item, index) => {
    map.set(item.id, index);

    return map;
  }, new Map<number, number>());

  const offerHighways = highways
    .concat()
    .sort((a, b) => getOrder(highwaysOrderMap, a.id, Infinity) - getOrder(highwaysOrderMap, b.id, Infinity));

  return isSuburban(props.offer) ? offerHighways[0] : undefined;
};
