import * as React from 'react';

import { TagNew } from '../TagNew';

export interface IWithoutDepositTagItemProps {
  onDeleteClick(): void;
}

export const WithoutDepositTagItem = ({ onDeleteClick }: IWithoutDepositTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>Без залога</TagNew>;
};
