import { connect } from 'react-redux';

import { DirectionsModal } from './index';
import { PETERSBURG_AND_REGION } from '../../../../../packages/Filters/shared/constants/regions';
import { IAppState, Dispatch } from '../../../../common/state';
import { getRegionId } from '../../../../utils/geo';
import { changeMaxFromMKAD, changeMinFromMKAD } from '../../../state/advanced/from_mkad';
import {
  closeDirectionsModal,
  deselectHighways,
  getHighwaysFromJsonQuery,
  requestDirectionsData,
  searchBySelectedHighways,
  selectHighways,
} from '../../../state/directions';

export const DirectionsContainer = connect(
  (state: IAppState) => {
    const { directionsModal, jsonQuery, currentLocation } = state.filters;
    const label = PETERSBURG_AND_REGION.includes(getRegionId(currentLocation)) ? 'От КАД:' : 'От МКАД:';

    return {
      fromMKAD: (jsonQuery.from_mcad_km && jsonQuery.from_mcad_km.value) || {},
      isLoading: directionsModal.isLoading,
      directions: directionsModal.data,
      selectedHighwayIds: getHighwaysFromJsonQuery(jsonQuery.geo),
      label,
    };
  },
  (dispatch: Dispatch) => ({
    changeMinFromMKAD: (value: number | undefined) => {
      dispatch(changeMinFromMKAD(value));
    },
    changeMaxFromMKAD: (value: number | undefined) => {
      dispatch(changeMaxFromMKAD(value));
    },
    close: () => {
      dispatch(closeDirectionsModal());
    },
    requestDirectionsData: () => {
      dispatch(requestDirectionsData());
    },
    selectHighways: (highwaysIds: number[]) => {
      dispatch(selectHighways(highwaysIds));
    },
    deselectHighways: (highwaysIds: number[]) => {
      dispatch(deselectHighways(highwaysIds));
    },
    searchBySelectedHighways: () => {
      dispatch(searchBySelectedHighways());
    },
  }),
)(DirectionsModal);
