import * as React from 'react';

import * as style from './style.css';

export const ModalContent = ({ children }: React.PropsWithChildren) => {
  return (
    <div className={style['container']} data-testid="LayoutOffersModal">
      {children}
    </div>
  );
};
