import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectIsCountrysideTrapEnabled, selectVillagesLink } from '../../../../selectors';
import { ISuburbanCatalog, TSuburbanCatalog } from '../../../../types/suburbanCatalog';
import { trackBannerBuildersClick, trackBannerKpLinkClick, trackTrapKpLinkClick } from '../tracking';

export const useCatalogs = (catalogsTypes: TSuburbanCatalog[]): ISuburbanCatalog[] => {
  const isCountrysideTrapEnabled = useSelector(selectIsCountrysideTrapEnabled);
  const villagesLink = useSelector(selectVillagesLink);

  return useMemo(() => {
    return catalogsTypes.reduce<ISuburbanCatalog[]>((catalogs, type) => {
      let catalog: ISuburbanCatalog | undefined;

      if (type === 'villages' && villagesLink) {
        catalog = {
          title: 'Коттеджные посёлки',
          description: 'Выгодные предложения и скидки от застройщиков',
          icon: 'villages',
          link: villagesLink,
          onClick: isCountrysideTrapEnabled ? trackTrapKpLinkClick : trackBannerKpLinkClick,
        };
      } else if (type === 'builders') {
        catalog = {
          title: 'Построить дом',
          description: 'Надёжные компании и проекты домов на любой вкус',
          icon: 'builders',
          link: '/country-builders-projects/?utm_medium=listing&utm_content=teaser',
          onClick: trackBannerBuildersClick,
        };
      }

      return catalog ? catalogs.concat(catalog) : catalogs;
    }, []);
  }, [catalogsTypes, villagesLink, isCountrysideTrapEnabled]);
};
