import * as R from 'ramda';

import { DEFAULT_LONGTERM_RENT_OPTION, LONGTERM_RENT_OPTIONS } from './constants';
import { getTermValue, setTerm } from './helpers';
import { TJsonQuery } from './types';

export function resetForDay(jsonQuery: TJsonQuery): () => TJsonQuery {
  return () => {
    let nextJsonQuery = R.clone(jsonQuery);

    const rentTime = getTermValue('for_day')(nextJsonQuery);
    if (rentTime !== null && LONGTERM_RENT_OPTIONS.includes(rentTime)) {
      nextJsonQuery = setTerm('for_day')(nextJsonQuery)(DEFAULT_LONGTERM_RENT_OPTION);
    }

    return nextJsonQuery;
  };
}
