import classNames from 'classnames';
import * as React from 'react';

import * as styles from './index.css';
import { ISeoState } from '../../../../common/state';
import { IBreadcrumb } from '../../../../offer';
import { Breadcrumbs } from '../../Breadcrumbs';

export interface IHeaderDefaultProps {
  seo: ISeoState;
  breadcrumbs: IBreadcrumb[];
}

export const HeaderDefault: React.FC<IHeaderDefaultProps> = ({ breadcrumbs, seo }) => {
  const { h1 } = seo;

  return (
    <div className={classNames(styles['wrapper'], styles['wrapper_redesign'])}>
      <Breadcrumbs breadcrumbs={breadcrumbs} h1={h1} />
    </div>
  );
};
