import { connect } from 'react-redux';

import { ISearchButtonProps, SearchButton } from '../../../common/components/search_button';
import { IAppState, Dispatch } from '../../../common/state';
import { makeSearch } from '../../state/search';

export type TStateProps = Pick<
  ISearchButtonProps,
  'count' | 'loading' | 'nextQueryString' | 'userInput' | 'searchButtonVariants' | 'searchButtonLabelText'
>;
export type TDispatchProps = Pick<ISearchButtonProps, 'onClick'>;
export type TOwnProps = Pick<ISearchButtonProps, 'onClick' | 'searchButtonLabelText'> & {
  isCountHidden?: boolean;
};

export const SearchButtonContainer = connect<TStateProps, TDispatchProps, TOwnProps, ISearchButtonProps>(
  (state: IAppState, ownProps: TOwnProps) => {
    const { filters } = state;

    const { jsonQueryCount, jsonQueryCountRefresing, queryString, userInput } = filters;

    const searchButtonVariants: [string, string, string] = ['объект', 'объекта', 'объектов'];

    const searchButtonLabelText = ownProps.searchButtonLabelText || 'Показать объекты';

    return {
      count: jsonQueryCount,
      loading: jsonQueryCountRefresing,
      nextQueryString: queryString,
      userInput,
      searchButtonVariants,
      searchButtonLabelText,
    };
  },
  (dispatch: Dispatch) => ({
    onClick: () => dispatch(makeSearch()),
  }),
  (stateProps, dispatchProps, ownProps) => {
    const props = {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
    };

    if (typeof ownProps.onClick === 'function') {
      props.onClick = ownProps.onClick;
    }

    if (ownProps.isCountHidden) {
      props.count = undefined;
    }

    return props;
  },
)(SearchButton);
