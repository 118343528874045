import { IAppState } from '../../common/state';
import { TTag } from '../../filters/state/tags';

export function tagsSelector(state: IAppState): Array<TTag> {
  const { filters } = state;

  const tags = filters.backup?.tags ?? filters.tags;

  return tags;
}
