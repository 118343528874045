import * as React from 'react';

import { JsonQuery } from '../../../../../JsonQuery';
import { Apartments } from '../../../components/advancedFilters/Apartments';
import { useContext } from '../../../utils/useContext';

export const ApartmentsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const apartments = new JsonQuery(jsonQuery).getApartments();

  const handleChange = React.useCallback(
    (apartments: boolean | null) => {
      onChange({ action: 'setApartments', arguments: [apartments] });
    },
    [onChange],
  );

  return <Apartments value={apartments} onChange={handleChange} />;
};
