import * as React from 'react';

import { NewbuildingSpecialProject } from './NewbuildingSpecialProject';
import { INewbuildingSpecialProjectBannerContext } from '../../../../../../types/adfox';

export interface INewbuildingSpecialProjectContainerProps {
  context: INewbuildingSpecialProjectBannerContext;
}

export function NewbuildingSpecialProjectContainer({ context }: INewbuildingSpecialProjectContainerProps) {
  return <NewbuildingSpecialProject context={context} />;
}
