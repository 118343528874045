import { getGroupedLinks } from './groupLinks';
import { IAppState } from '../../../../common/state';
import { IQuickLink } from '../../../../types/quickLinks';
import { isArrayWithItems } from '../../../../utils';

export function linkGroupsSelector(state: IAppState): IQuickLink[][] | null {
  const links = state.results.quickLinks && state.results.quickLinks.links;
  if (!links) {
    return null;
  }

  const formattedLinks = getGroupedLinks(links);

  return isArrayWithItems(formattedLinks) ? formattedLinks : null;
}
