import { TComputedPropMapper } from '../../types/computed-props-mapper';

export const agentImageLink: TComputedPropMapper<string | undefined> = props => {
  const {
    offer: { user },
  } = props;

  if (user) {
    if (user.masterAgent) {
      return user.masterAgent.absoluteAvatarUrl || undefined;
    }

    return user.agentAvatarUrl || undefined;
  }

  return undefined;
};
