import { Header1, Paragraph1 } from '@cian/ui-kit/typography';
import * as React from 'react';

import { NewbuildingEntryResaleItem } from './NewbuildingEntryResaleItem';
import * as styles from './styles.css';
import {
  INewbuildingSimilarOfferTransformed,
  INewbuildingSimilarOfferProduct,
} from '../../../types/newbuildingSimilarOffer';

interface INewbuildingEntryResaleBlockProps {
  onButtonClick(products: INewbuildingSimilarOfferProduct[]): void;
  onItemClick(products: INewbuildingSimilarOfferProduct[]): void;
  onShow(products: INewbuildingSimilarOfferProduct[]): void;
  url: string;
  title: string;
  description: string;
  items: INewbuildingSimilarOfferTransformed[];
}

export function NewbuildingEntryResaleBlock({
  onButtonClick,
  onItemClick,
  onShow,
  url,
  title,
  description,
  items,
}: INewbuildingEntryResaleBlockProps) {
  const products = items.map(({ product }) => product);
  /* istanbul ignore next */
  React.useEffect(() => onShow(products), [url]);

  return (
    <div className={styles['container']}>
      <div className={styles['title']}>
        <Header1 as={'span'}>{title}</Header1>
      </div>
      <Paragraph1>{description}</Paragraph1>
      <div className={styles['list']}>
        {items.map(({ product, ...item }: INewbuildingSimilarOfferTransformed, index) => {
          return <NewbuildingEntryResaleItem key={index} onClick={() => onItemClick([product])} {...item} />;
        })}
      </div>
      <a className={styles['button']} onClick={() => onButtonClick(products)} href={url}>
        Смотреть все предложения
      </a>
    </div>
  );
}
