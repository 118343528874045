import { Outside } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

interface ISelectDropdownContentLayoutProps {
  open: boolean;
  onClose(): void;
  children: React.ReactNode;
}

export function SelectDropdown({ children, open, onClose }: ISelectDropdownContentLayoutProps) {
  return (
    <Outside onOutside={onClose} active={open} insideRefs={[]}>
      <div className={styles['dropdown']}>{children}</div>
    </Outside>
  );
}
