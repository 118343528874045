import { Button } from '@cian/ui-kit';
import { IconActionSort16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { items } from './constants';
import { ISummarySortOption } from './types';
import { trackChangeSortEvent } from './utils/tracking';
import { Option, Select } from '../../../../../../packages/Select';
import { ESortValue, IJsonQuery } from '../../../../../json_query';
import { TLocation } from '../../../../../types/location';
import {
  dealTypeFromJsonQuery,
  FDealType,
  FOfferType,
  isFlat,
  isSuburban,
  isUrban,
  offerTypeFromJsonQuery,
} from '../../../../../utils/category';
import { MOId } from '../../../../../utils/geo';
import { SummaryButtonWrapper } from '../SummaryButtonWrapper';

export interface ISortDropdownProps {
  currentLocation: TLocation;
  jsonQuery: IJsonQuery;
  onSortChanged(sort: ESortValue): void;
}

export interface ISummarySortDropdownState {
  isOpen: boolean;
}

export class SummarySortDropdown extends React.Component<ISortDropdownProps, ISummarySortDropdownState> {
  public state = {
    isOpen: false,
  };

  public render() {
    const itemList = this.getSortValues();
    const { value: currentValue } = this.getCurrentSort();

    return (
      <SummaryButtonWrapper>
        <Select
          open={this.state.isOpen}
          value={currentValue}
          onChange={this.handleSortChange}
          onOpen={this.open}
          onClose={this.close}
          renderButton={({ label, onClick }) => (
            <Button
              size="XS"
              theme="fill_secondary"
              data-mark="SortDropdownButton"
              beforeIcon={<IconActionSort16 color="primary_100" />}
              onClick={onClick}
            >
              {label}
            </Button>
          )}
        >
          {itemList.map(sortType => (
            <Option key={`${sortType.label}_${sortType.value}`} value={sortType.value}>
              {sortType.label}
            </Option>
          ))}
        </Select>
      </SummaryButtonWrapper>
    );
  }

  private close = () => {
    this.setState({ isOpen: false });
  };

  private open = () => {
    this.setState({ isOpen: true });
  };

  private getCurrentSort() {
    const itemList = this.getSortValues();
    const { jsonQuery } = this.props;
    const { sort } = jsonQuery;

    let activeItem: ISummarySortOption = itemList[0];

    if (sort && sort.value) {
      activeItem = itemList.find(({ value }) => value === sort.value) || itemList[0];
    }

    return {
      value: activeItem.value as ESortValue,
    };
  }

  private handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>, value: ESortValue) => {
    const { value: prevValue } = this.getCurrentSort();

    if (prevValue !== value) {
      trackChangeSortEvent(value);
      this.props.onSortChanged(value);
    }
  };

  private getSortValues() {
    const { currentLocation, jsonQuery } = this.props;
    const offerType = offerTypeFromJsonQuery(jsonQuery);
    const dealType = dealTypeFromJsonQuery(jsonQuery);

    return items
      .filter(({ value }) => {
        switch (value) {
          case ESortValue.SquarePrice:
          case ESortValue.SquarePriceDesc:
            return isFlat(offerType) && dealType === FDealType.Sale;
          case ESortValue.Area:
            return offerType !== FOfferType.Land;
          case ESortValue.LandArea:
            return isSuburban(offerType);
          case ESortValue.WalkingTime:
            return !isSuburban(offerType) && (typeof currentLocation === 'string' || currentLocation.hasMetro);
          case ESortValue.MKAD: {
            let isMosowArea = false;
            if (jsonQuery.region && Array.isArray(jsonQuery.region.value)) {
              isMosowArea = jsonQuery.region.value.includes(MOId);
            }

            return isSuburban(offerType) && isMosowArea;
          }
          case ESortValue.StreetName:
            return isUrban(offerType);
          default:
            return true;
        }
      })
      .map(({ value, label }) => {
        if (value === ESortValue.Area) {
          return { label: isSuburban(offerType) ? 'По площади дома' : 'По общей площади', value };
        }

        return { value, label };
      });
  }
}
