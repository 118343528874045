import { EWCType } from '../../../../json_query';
import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const IWCTypeTagType = 'advanced-wc-wcType';

export interface IWCTypeTag {
  type: 'advanced-wc-wcType';
  value: EWCType;
}

export const isWCTypeTag = (tag: TTag): tag is IWCTypeTag => tag.type === IWCTypeTagType;

export const wcTypeTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.wc_type && jsonQuery.wc_type.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isWCTypeTag, () => ({ type: IWCTypeTagType, value }));
    } else {
      return ensureTagNotExists(tags, isWCTypeTag);
    }
  },
};
