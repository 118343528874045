import * as React from 'react';
import { findDOMNode } from 'react-dom';

interface IElementInViewportProps {
  children: JSX.Element;
  onChange(isVisible: boolean): void;
}

interface IElementInViewportState {
  isVisible: boolean;
}

export class ElementInViewport extends React.PureComponent<IElementInViewportProps, IElementInViewportState> {
  public constructor(props: IElementInViewportProps) {
    super(props);

    this.state = {
      isVisible: false,
    };
  }

  public UNSAFE_componentWillUpdate(nextProps: IElementInViewportProps, nextState: IElementInViewportState) {
    if (this.state.isVisible !== nextState.isVisible) {
      this.props.onChange(nextState.isVisible);
    }
  }

  public componentDidMount() {
    document.addEventListener('resize', this.handleResize);
    document.addEventListener('scroll', this.handleScroll);

    this.setVisibility();
  }

  public componentWillUnmount() {
    document.removeEventListener('resize', this.handleResize);
    document.removeEventListener('scroll', this.handleScroll);
  }

  public render() {
    return this.props.children;
  }

  private setVisibility() {
    if (this.state.isVisible) {
      return;
    }

    // eslint-disable-next-line react/no-find-dom-node
    const component = findDOMNode(this) as HTMLElement;
    const { top, height } = component.getBoundingClientRect();
    const offset = Math.max(window.innerHeight - height, 0);

    const isVisible = top <= offset && top >= -height;

    if (isVisible !== this.state.isVisible) {
      this.setState({
        isVisible,
      });
    }
  }

  private handleResize = () => {
    this.setVisibility();
  };

  private handleScroll = () => {
    this.setVisibility();
  };
}
