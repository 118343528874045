import * as React from 'react';

import styles from './SeoText.css';

interface ISeoTextProps {
  text: string;
}

export const SeoText: React.FC<ISeoTextProps> = ({ text }) => (
  // eslint-disable-next-line react/no-danger
  <div data-name="SeoText" className={styles['seo-text']} dangerouslySetInnerHTML={{ __html: text }} />
);
