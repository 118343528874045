import React, { useCallback } from 'react';

import { GeoBlock } from '../../../components/GeoBlock';
import { useOfferContext } from '../../../context';
import { HighwayContainer } from '../HighwayContainer';
import { JKNameContainer } from '../JKNameContainer';
import { RailwayContainer } from '../RailwayContainer';
import { SeoSuggestionContainer } from '../SeoSuggestionContainer';
import { UndergroundContainer } from '../UndergroundContainer';

export function GeoContainer() {
  const { geoLabels, tracking } = useOfferContext();

  const handleLabelClick = useCallback(
    (label: string) => {
      tracking.trackAddressClick(label);
    },
    [tracking.trackAddressClick],
  );

  return (
    <GeoBlock geoLabels={geoLabels} onLabelClick={handleLabelClick} seoSuggestion={<SeoSuggestionContainer />}>
      <JKNameContainer />
      <HighwayContainer />
      <UndergroundContainer />
      <RailwayContainer />
    </GeoBlock>
  );
}
