import { Image, LinkButton, UIHeading2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';
import { INewbuildingPromoBuilder } from '../../types/newbuildingPromoBuilderOffers';

interface INewbuildingPromoBuilderProps extends INewbuildingPromoBuilder {
  onLogoClick(): void;
  onButtonClick(): void;
  allOffersUrl: string;
}

export function NewbuildingPromoBuilder({
  cardUrl,
  logoUrl,
  name,
  allOffersUrl,
  onLogoClick,
  onButtonClick,
}: INewbuildingPromoBuilderProps) {
  return (
    <div className={styles['container']}>
      <a
        className={styles['logo']}
        data-testid="NewbuildingPromoBuilderLogo"
        target="_blank"
        rel="noopener noreferrer"
        href={cardUrl}
        onClick={onLogoClick}
      >
        {logoUrl && <Image src={logoUrl} objectFit="contain" preloader theme="light" />}
      </a>
      <div className={styles['title']}>
        <UIHeading2 color="black_100">Квартиры от застройщика {name}</UIHeading2>
      </div>
      <div className={styles['control']}>
        <LinkButton
          size="XS"
          fullWidth
          theme="fill_secondary"
          href={allOffersUrl}
          target="_blank"
          data-testid="NewbuildingPromoBuilderButton"
          onClick={onButtonClick}
        >
          Смотреть все
        </LinkButton>
      </div>
    </div>
  );
}
