/* eslint-disable @typescript-eslint/no-unused-vars */
import { EXCLUDED_ADVANCED_FILTERS_TOOLTIP_ACTIONS } from './constants';
import { TFilter, TOpenedFiltersState } from '../../types/filters';
import { TAvailableModifierActions, TModifier } from '../../types/modifier';

interface IBaseParameters {
  filterKey: TFilter;
  modifiers: TModifier[] | null;
  openedFilters: TOpenedFiltersState;
}

interface IParameters extends IBaseParameters {
  actions: TAvailableModifierActions[];
}

interface IAdvancedFiltersParameters extends IBaseParameters {
  filterKey: 'advancedFilters';
}

type TParameters = IParameters | IAdvancedFiltersParameters;

export function isSearchResultsTooltipOpen(parameters: TParameters): boolean {
  if (parameters.modifiers == null) {
    return false;
  }

  /* istanbul ignore next */
  const { [parameters.filterKey]: _, ...otherOpenedFilters } = parameters.openedFilters;
  const hasOtherOpenedFilters = Object.values(otherOpenedFilters).some(Boolean);
  const includesCurrentFilterModifiers =
    parameters.filterKey === 'advancedFilters'
      ? parameters.modifiers.every(m => !EXCLUDED_ADVANCED_FILTERS_TOOLTIP_ACTIONS.includes(m.action))
      : parameters.modifiers.some(m => parameters.actions.includes(m.action));

  return includesCurrentFilterModifiers && !hasOtherOpenedFilters;
}
