import * as React from 'react';

import { trackCianDobroBannerClick, trackCianDobroBannerShow } from './tracking';
import { useApplicationContext } from '../../../utils/applicationContext';
import { CianDobroBanner } from '../../components/CianDobroBanner';

type TCianDobroBannerInfo = {
  header: string;
  text: string;
  buttonText: string;
  buttonUrl: string;
  logoUrl: string;
};

export function CianDobroBannerContainer() {
  const { config } = useApplicationContext();
  const bannerInfo = config.get<TCianDobroBannerInfo>('cian-dobro.desktopSerpBannerInfo');

  if (
    !bannerInfo ||
    !bannerInfo.header ||
    !bannerInfo.text ||
    !bannerInfo.buttonText ||
    !bannerInfo.buttonUrl ||
    !bannerInfo.logoUrl
  ) {
    return null;
  }

  return <CianDobroBanner {...bannerInfo} onShow={trackCianDobroBannerShow} onClick={trackCianDobroBannerClick} />;
}
