import { connect } from 'react-redux';

import { IPrintPopupProps, PrintPopup } from './index';
import { IAppState, Dispatch } from '../../../../common/state';
import { changePrintOptions, IChangePrintOptions } from '../../../state/print';

export const PrintPopupContainer = connect<
  Pick<IPrintPopupProps, 'showComments' | 'showPhones'>,
  Pick<IPrintPopupProps, 'changePrintOptions'>,
  Pick<IPrintPopupProps, 'close' | 'open'>
>(
  (state: IAppState) => {
    const { showComments, showPhones } = state.print;

    return {
      showComments,
      showPhones,
    };
  },
  (dispatch: Dispatch) => ({
    changePrintOptions: (options: IChangePrintOptions) => {
      dispatch(changePrintOptions(options));
    },
  }),
)(PrintPopup);
