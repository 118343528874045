import * as React from 'react';
import { useSelector } from 'react-redux';

import { SaveSearchProvider as Provider } from '../../../packages/SaveSearchModal';
import { IAppState } from '../../common/state';
import { IJsonQuery } from '../../json_query';
import { getSaveSearchMicrofrontend } from '../../services/getSaveSearchMicrofrontend';
import { TUser } from '../../types/user';
import { useApplicationContext } from '../../utils/applicationContext';

enum ESource {
  Card = 'card',
  Listing = 'listing',
  Map = 'map',
}

export const SaveSearchModalProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const context = useApplicationContext();
  const fetchManifest = React.useCallback(async () => getSaveSearchMicrofrontend(context), [context]);

  const jsonQuery = useSelector<IAppState, IJsonQuery>(state => state.results.jsonQuery);
  const queryString = useSelector<IAppState, string>(state => state.results.queryString);
  const title = useSelector<IAppState, string | null>(state => state.results.seo.h1);
  const user = useSelector<IAppState, TUser>(state => state.user);
  const email = React.useMemo(() => (user.isAuthenticated ? user.email : null), [user]);
  const isAuthenticated = user.isAuthenticated;

  const params = {
    jsonQuery,
    email,
    title,
    isAuthenticated,
    source: ESource.Listing,
    analyticsParams: {
      pageType: 'Listing',
      listingType: 'list',
      queryString,
      isFromBanner: false,
    },
  };

  return (
    <Provider fetchManifest={fetchManifest} params={params}>
      {children}
    </Provider>
  );
};
