import * as React from 'react';

import { processMultiple } from './processMultiple';
import { processSingle } from './processSingle';
import { ISelectKeyboardControllerOptions } from './types';

export const processKeyboardEvent = (e: React.KeyboardEvent, options: ISelectKeyboardControllerOptions) => {
  if (options.multiple) {
    return processMultiple(e, options);
  }

  return processSingle(e, options);
};
