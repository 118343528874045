import { getAgentExperience } from './agent/getAgentExperience';
import { TComputedPropMapper } from '../../types/computed-props-mapper';

export const agentInfo: TComputedPropMapper<string | undefined> = (props, ctx) => {
  const { isByHomeowner, isTop3, user } = props.offer;
  if (isByHomeowner) {
    return undefined;
  }

  const experience = getAgentExperience(props, ctx);

  if (isTop3 && experience) {
    return experience;
  }

  if (user?.subAgentCompanyName && !user.masterAgent) {
    return user.subAgentCompanyName;
  }

  return undefined;
};
