import { ActionAfterViewed } from '@cian/action-after-viewed-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingPromoCarouselContainerLoadable } from './NewbuildingPromoCarouselLoadable';
import { NewbuildingPromoCarouselLayout } from '../../components/NewbuildingPromoCarouselLayout';
import { getIsNewbuildingPromoCarouselEnabled } from '../../selectors';
import { getDealType } from '../../selectors/getDealType';
import { getOfferType } from '../../selectors/getOfferType';
import { isNewbuildingSelector } from '../../selectors/newbuildingConsultant';
import { getNewbuildingIdsForBuildersPromoSlider } from '../../selectors/newbuildingPromoSlider';
import { EDealType } from '../../types/dealType';
import { FOfferType } from '../../utils/category';

export function LazyNewbuildingPromoCarouselContainer() {
  const [isVisible, setIsVisible] = React.useState(false);
  const newbuildingIds = useSelector(getNewbuildingIdsForBuildersPromoSlider);
  const isNewbuildingsOnly = useSelector(isNewbuildingSelector);
  const isNewbuildingPromoCarouselEnabled = useSelector(getIsNewbuildingPromoCarouselEnabled);
  const offerType = useSelector(getOfferType);
  const dealType = useSelector(getDealType);

  // Определяем, нужно ли показывать промо карусель с ЖК в смешенной или первичной выдаче квартир
  const isVisiblePromoCarousel = (offerType === FOfferType.Flat && dealType === EDealType.Sale) || isNewbuildingsOnly;

  if (!isNewbuildingPromoCarouselEnabled) {
    return null;
  }

  if (!isVisiblePromoCarousel) {
    return null;
  }

  if (!newbuildingIds.length) {
    return null;
  }

  return (
    <ActionAfterViewed callback={() => setIsVisible(true)} percentVisible={0.8}>
      <div>
        {isVisible && (
          <NewbuildingPromoCarouselLayout isShown={isVisible}>
            <NewbuildingPromoCarouselContainerLoadable newbuildingIds={newbuildingIds} />
          </NewbuildingPromoCarouselLayout>
        )}
      </div>
    </ActionAfterViewed>
  );
}
