import { getDaysData } from './getDaysData';
import { getMonthsArray } from './getMonthsArray';
import { getMonthsCount } from './getMonthsCount';
import { TCalendarMonths } from '../types';

interface IParams {
  start: Date;
  end: Date;
}

export const getMonthsData = (params: IParams): TCalendarMonths => {
  const { start, end } = params;

  const startMonth = start.getMonth();
  const endMonth = end.getMonth();
  const startYear = start.getFullYear();
  const endYear = end.getFullYear();

  const monthsCount = getMonthsCount({ startMonth, endMonth, startYear, endYear });
  const monthsArray = getMonthsArray(startMonth, monthsCount);

  let year = startYear;

  return monthsArray.map((monthNumber, index) => {
    if (monthNumber === 0 && index !== 0) {
      year++;
    }

    return {
      number: monthNumber,
      year,
      days: getDaysData({ month: monthNumber, year, start, end }),
    };
  });
};
