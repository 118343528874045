import { EVas } from '../../../types/vas';

// @deprecated
const VAS_DESCRIPTIONS: { [key in EVas]: string } = {
  [EVas.Auction]: 'Объявления пользователей, которые заплатили больше, показываются на более высоких позициях.',
  [EVas.Standard]: 'Стандарт ниже объявлений с продвижением Топ и Премиум, но выше Публикаций в результатах поиска.',
  [EVas.Premium]: 'Объявления показываются сразу под Топ-объявлениями, но перед Платными.',
  [EVas.Top]:
    'Объявления занимают первые три позиции на странице результатов поиска. Можно добавить к объявлению свой логотип.',
};

const ML_VAS_DESCRIPTIONS: { [key in EVas]: string } = {
  [EVas.Auction]:
    'Аукцион – это способ увеличить частоту показов вашего объявления. Чем выше ставка, тем чаще ваше объявление будет показано в результатах поиска.',
  [EVas.Standard]: 'Стандарт ниже объявлений с продвижением Топ и Премиум, но выше Публикаций в результатах поиска.',
  [EVas.Premium]:
    'Премиум-объявления выделены в результатах поиска за счёт брендирования. Такие объявления показываются в 1.5 раза чаще обычных.',
  [EVas.Top]:
    'Топ-объявления самые заметные в результатах поиска за счёт больших фотографий и брендирования. Такие объявления показываются в 3 раза чаще обычных.',
};

/**
 * Возвращает описание VAS и его преимущества (с учетом ранжированной выдачи)
 * @param vas мнемоника VAS
 */
export function getMlVasDescription(vas: EVas) {
  return ML_VAS_DESCRIPTIONS[vas];
}

/**
 * Возвращает описание VAS и его преимущества
 * @param vas мнемоника VAS
 * @deprecated повсеместно используется новый нейминг ML ранжированной выдачи
 */
export function getVasDescription(vas: EVas) {
  return VAS_DESCRIPTIONS[vas];
}

export function getDescription(vas: EVas, isML: boolean) {
  return isML ? getMlVasDescription(vas) : getVasDescription(vas);
}
