import { IApplicationContext } from '../../../types/application-context';
import { EGalleryLabelColor, IGalleryLabel } from '../../../types/gallery-label';

export function getAgentListsLabels(agentLists: string[], ctx: Pick<IApplicationContext, 'config'>): IGalleryLabel[] {
  const agentListsMap = ctx.config.get<Record<string, string>>('sellingTypes.agentLists');

  /**
   * @todo Удалить фича-тогл
   * @description Данный функционал появился в задаче CD-187921, будет удалён в задаче CD-189395
   */
  const isElectronicTradingEnabled = ctx.config.getStrict<boolean>('Audience.ElectronicTrading.Enabled');

  if (!agentListsMap) {
    return [];
  }

  const result = [];

  for (const agentList of agentLists) {
    /**
     * Для эл. торгов необходимо убрать 2 лейбла ("Залоговая недвижимость" и "Аукцион") из галереи.
     */
    if (isElectronicTradingEnabled && checkIfPledgeOrTradeRealty(agentList)) {
      continue;
    }

    const labelText = agentListsMap[agentList];

    if (labelText) {
      result.push({
        color: EGalleryLabelColor.LightGrey,
        label: labelText,
      });
    }
  }

  return result;
}

export function checkIfPledgeOrTradeRealty(agent: string) {
  return agent === 'pledgeRealty' || agent === 'tradeRealty';
}
