import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IFlatShareTagType = 'advanced-flat_share';

export interface IFlatShareTag {
  type: 'advanced-flat_share';
  value: number | null;
}

export function isFlatShareTag(tag: TTag): tag is IFlatShareTag {
  return tag.type === IFlatShareTagType;
}

export const flatShareTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.flat_share ? jsonQuery.flat_share.value : undefined;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isFlatShareTag, () => ({ type: IFlatShareTagType, value }));
    } else {
      return ensureTagNotExists(tags, isFlatShareTag);
    }
  },
};
