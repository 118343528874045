import { CheckboxButton } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './VillageMortgageAllowed.css';

interface IVillageMortgageAllowedProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: boolean | null) => void;
  checked: boolean;
}

export const VillageMortgageAllowed = React.forwardRef<HTMLDivElement, IVillageMortgageAllowedProps>(
  ({ onChange, checked }, ref) => {
    return (
      <div className={styles['village-mortgage-filter']} ref={ref}>
        <CheckboxButton label="Сельская ипотека 3%" onChange={onChange} checked={checked} />
      </div>
    );
  },
);

VillageMortgageAllowed.displayName = 'VillageMortgageAllowed';
