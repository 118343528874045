export enum ERoomType {
  Room = 0,
  FlatOneRoom = 1,
  FlatTwoRooms = 2,
  FlatThreeRooms = 3,
  FlatFourRooms = 4,
  FlatFiveRooms = 5,
  FlatManyRooms = 6,
  FlatFreeLayout = 7,
  FlatShared = 8,
  Studio = 9,
  Bed = 10,
}
