import { ca } from '@cian/analytics';

export function trackView() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'SeoBlock',
    action: 'show_main_link_sopr',
  });
}

export function trackClick(url: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'SeoBlock',
    action: 'click_main_link_sopr',
    label: url,
  });
}
