import * as React from 'react';

import { DoublesRankingPopup } from '../../../components/DoublesRankingPopup';
import { useOfferContext } from '../../../context';

export const DoublesRankingPopupContainer: React.FunctionComponent<{}> = () => {
  const {
    offer: { searchDuplicatesRotationPromo },
  } = useOfferContext();

  if (!searchDuplicatesRotationPromo) {
    return null;
  }

  return <DoublesRankingPopup popupText={searchDuplicatesRotationPromo} />;
};
