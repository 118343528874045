import { IAppState, TActions } from '../../../common/state';
import { EParkingType, IJsonQueryTerms } from '../../../json_query';

export const CHANGED_ACTION_TYPE = 'filters/parking/CHANGED';
export const SET_ACTION_TYPE = 'filters/parking/SET';

export interface IParkingChangedAction {
  type: 'filters/parking/CHANGED';
  parkingType: EParkingType;
  parkingAdded: boolean;
}

export interface IParkingSetAction {
  type: 'filters/parking/SET';
  value: EParkingType[];
}

export function changeParking(parkingType: EParkingType, parkingAdded: boolean): IParkingChangedAction {
  return {
    type: CHANGED_ACTION_TYPE,
    parkingType,
    parkingAdded,
  };
}

export function setParking(value: EParkingType[]): IParkingSetAction {
  return {
    type: SET_ACTION_TYPE,
    value,
  };
}

const changeParkingType = (
  parkingTypes: EParkingType[] | undefined,
  parkingType: EParkingType,
  parkingAdded: boolean,
): IJsonQueryTerms<EParkingType> | undefined => {
  let newParkingTypes = (parkingTypes && parkingTypes.slice()) || [];

  if (parkingAdded) {
    if (newParkingTypes.indexOf(parkingType) === -1) {
      newParkingTypes.push(parkingType);
    }
  } else {
    newParkingTypes = newParkingTypes.filter(e => e !== parkingType);
  }

  return newParkingTypes.length
    ? {
        type: 'terms',
        value: newParkingTypes,
      }
    : undefined;
};

export function parkingReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case CHANGED_ACTION_TYPE: {
      const parking_type = state.filters.jsonQuery.parking_type;

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            parking_type: changeParkingType(
              parking_type && parking_type.value,
              action.parkingType,
              action.parkingAdded,
            ),
          },
        },
      };
    }

    case SET_ACTION_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            parking_type: action.value.length
              ? {
                  type: 'terms',
                  value: action.value,
                }
              : undefined,
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-parking') {
        const parkingType = state.filters.jsonQuery.parking_type;

        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              parking_type: changeParkingType(parkingType && parkingType.value, action.tag.value, false),
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
}
