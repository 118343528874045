import { IAppState, TActions } from '../../../common/state';

export type TFloorsTotalRangeType = 'gte' | 'lte';

export const IMinFloorsTotalChangedActionType = 'filters/total_floors/MIN_TOTAL_FLOORS_CHANGED';
export const IMaxFloorsTotalChangedActionType = 'filters/total_floors/MAX_TOTAL_FLOORS_CHANGED';

export interface IMinFloorsTotalChangedAction {
  type: 'filters/total_floors/MIN_TOTAL_FLOORS_CHANGED';
  value: number | undefined;
}

export interface IMaxFloorsTotalChangedAction {
  type: 'filters/total_floors/MAX_TOTAL_FLOORS_CHANGED';
  value: number | undefined;
}

export function changeMinFloorsTotal(value: number | undefined): IMinFloorsTotalChangedAction {
  return {
    type: IMinFloorsTotalChangedActionType,
    value,
  };
}

export function changeMaxFloorsTotal(value: number | undefined): IMaxFloorsTotalChangedAction {
  return {
    type: IMaxFloorsTotalChangedActionType,
    value,
  };
}

export function getNewValue(
  state: IAppState,
  value: number | undefined,
  currentField: TFloorsTotalRangeType,
  otherField: TFloorsTotalRangeType,
) {
  if (!value && !(state.filters.jsonQuery.floorn && state.filters.jsonQuery.floorn.value[otherField])) {
    return undefined;
  }

  return {
    type: 'range' as const,
    value: {
      [currentField]: value,
      [otherField]: state.filters.jsonQuery.floorn && state.filters.jsonQuery.floorn.value[otherField],
    },
  };
}

export function floorsTotalReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IMinFloorsTotalChangedActionType: {
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            floorn: getNewValue(state, action.value, 'gte', 'lte'),
          },
        },
      };
    }

    case IMaxFloorsTotalChangedActionType: {
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            floorn: getNewValue(state, action.value, 'lte', 'gte'),
          },
        },
      };
    }

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'totalFloors') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              floorn: undefined,
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
}
