import { useBooleanState } from '@cian/react-utils';
import * as React from 'react';

import { AdFoxBanner } from '../../components/AdfoxBanner';
import { SpecialProjectBannerSkeleton } from '../../components/SpecialProjectBannerSkeleton';
import { AdfoxContextData } from '../../types/adfox/AdfoxContextData';
import { AdfoxContextProvider, PParams } from '../../utils/AdfoxContext';

const POST_VIEW_ANALYTICS_DATA: AdfoxContextData['postViewAnalyticsData'] = {
  banner: {
    place: 'offersSerp',
    name: 'Special_project_adfox',
    extra: {
      offerType: 'banner',
    },
  },
};
const SIZE: AdfoxContextData['size'] = { width: '100%', minHeight: '148px' };
const P_PARAMS: PParams = { p1: 'cwlpf', p2: 'hyhj' };

export function SpecialProjectBannerContainer() {
  const { state: isVisibleBanner, setFalse: setBannerInvisible } = useBooleanState(true);

  if (!isVisibleBanner) {
    return null;
  }

  return (
    <SpecialProjectBannerSkeleton>
      <AdfoxContextProvider size={SIZE} pParams={P_PARAMS} postViewAnalyticsData={POST_VIEW_ANALYTICS_DATA}>
        <AdFoxBanner position={null} onStub={setBannerInvisible} onError={setBannerInvisible} />
      </AdfoxContextProvider>
    </SpecialProjectBannerSkeleton>
  );
}
