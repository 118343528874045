import { IModel, IModelResponse } from '../types/model';

export type TDelayedMarkSavedSearchAsReadDesktopModel = IModel<
  IDelayedMarkSavedSearchAsReadDesktopRequestSchema,
  TIDelayedMarkSavedSearchAsReadDesktopResponseSchema
>;

const defaultConfig: TDelayedMarkSavedSearchAsReadDesktopModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'savedSearches',
  pathApi: '/v1/delayed-mark-saved-search-as-read-desktop/',
};

export function createDelayedMarkSavedSearchAsReadestopModel(
  config: Partial<TDelayedMarkSavedSearchAsReadDesktopModel>,
): TDelayedMarkSavedSearchAsReadDesktopModel {
  return {
    ...defaultConfig,
    ...config,
  };
}

export interface IDelayedMarkSavedSearchAsReadDesktopRequestSchema {
  savedSearchId: number;
}

export interface IDelayedMarkSavedSearchAsReadDesktopResponseSchema {
  status: string;
}

export interface IError {
  key: string;
  code: string;
  message: string;
}

export interface IDelayedMarkSavedSearchAsReadDesktopErrorSchema {
  errors: IError[];
  message: string;
}

export interface IDelayedMarkSavedSearchAsReadDesktopResponse200
  extends IModelResponse<IDelayedMarkSavedSearchAsReadDesktopResponseSchema> {
  statusCode: 200;
}

export interface IDelayedMarkSavedSearchAsReadDesktopResponse400
  extends IModelResponse<IDelayedMarkSavedSearchAsReadDesktopErrorSchema> {
  statusCode: 400;
}

export type TIDelayedMarkSavedSearchAsReadDesktopResponseSchema =
  | IDelayedMarkSavedSearchAsReadDesktopResponse200
  | IDelayedMarkSavedSearchAsReadDesktopResponse400;
