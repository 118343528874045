import * as React from 'react';

import { IIsFirstFloorTag } from '../../../state/tags/definitions/is_first_floor';
import { TagNew } from '../TagNew';

export interface IIsFirstFloorTagItemProps {
  tag: IIsFirstFloorTag;
  onDeleteClick(): void;
}

export const IsFirstFloorTagItem = ({ tag, onDeleteClick }: IIsFirstFloorTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>{tag.value ? 'Первый этаж' : 'Не первый этаж'}</TagNew>;
};
