import * as React from 'react';

import { EGarageStatus, NonEmptyArray } from '../../../../../JsonQuery';
import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface IGarageStatusOption {
  label: string;
  value: EGarageStatus | null;
}

const GARAGE_STATUS_OPTIONS: IGarageStatusOption[] = [
  { label: 'Неважно', value: null },
  { label: 'Собственность', value: EGarageStatus.Ownership },
  { label: 'Кооператив', value: EGarageStatus.Cooperative },
  { label: 'По договорённости', value: EGarageStatus.Agreement },
];

interface IGarageStatusProps {
  value: NonEmptyArray<EGarageStatus> | null;
  onChange(value: NonEmptyArray<EGarageStatus> | null): void;
}

export const GarageStatus: React.FC<IGarageStatusProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_garageStatus" label="Статус">
      <CheckboxButtonGroupUnselectable options={GARAGE_STATUS_OPTIONS} value={value} onChange={onChange} />
    </Filter>
  );
};
