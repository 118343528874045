import { EGalleryLabelColor, IGalleryLabel } from '../../../../types/gallery-label';

export const getNewbuildingMediaLabels = (isFromBuilder: boolean): IGalleryLabel[] => {
  return [
    {
      color: EGalleryLabelColor.Black,
      label: isFromBuilder ? 'Новостройка от застройщика' : 'Новостройка от представителя',
    },
  ];
};
