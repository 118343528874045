import { ca } from '@cian/analytics';

import { IOffer } from '../../offer';

export function trackWriteMessageClick(offer: IOffer, position: number) {
  ca('eventSite', {
    action: 'send_message',
    category: 'Listing',
    label: offer.gaLabel,
    name: 'oldevent',
    page: {
      pageType: 'Listing',
      siteType: 'desktop',
      extra: {
        reason: 'WorkHoursPopup',
        /* istanbul ignore next */
        opponent_id: offer.user?.cianUserId,
      },
    },
    products: [
      {
        id: offer.id,
        offerType: 'offer',
        position,
      },
    ],
  });
}

export function trackPopupShown(offer: IOffer, position: number) {
  ca('eventSite', {
    action: 'show_sopr',
    category: 'work_hours',
    label: 'popup',
    name: 'oldevent',
    products: [
      {
        id: offer.id,
        offerType: 'offer',
        position,
      },
    ],
  });
}

export function trackCallAnyway(offer: IOffer, position: number) {
  ca('eventSite', {
    action: 'click_callbutton',
    category: 'work_hours',
    label: 'popup',
    name: 'oldevent',
    products: [
      {
        id: offer.id,
        offerType: 'offer',
        position,
      },
    ],
  });
}
