import { pathOr } from 'ramda';
import * as React from 'react';

import { Floors } from '../../../components/advancedFilters/Floors';
import { IJsonQueryRangeValue } from '../../../types/jsonQuery';
import { useContext } from '../../../utils/useContext';
import { useOfferType } from '../../../utils/useOfferType';

export const FloorsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const offerType = useOfferType();
  const floors = pathOr<IJsonQueryRangeValue>({ gte: undefined, lte: undefined }, ['floorn', 'value'], jsonQuery);
  const handleFloorsMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setFloorsMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleFloorsMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setFloorsMax', arguments: [lte] });
    },
    [onChange],
  );

  return (
    <Floors
      offerType={offerType}
      floors={floors}
      onFloorsMinChange={handleFloorsMinChange}
      onFloorsMaxChange={handleFloorsMaxChange}
    />
  );
};
