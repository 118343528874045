import { ca } from '@cian/analytics';

interface ITrackAddOfferToComparison {
  offerId: number;
  label: string;
}

export const trackAddOfferToComparison = ({ offerId, label }: ITrackAddOfferToComparison) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Compare',
    action: 'Add',
    label,
    products: [
      {
        id: offerId,
        offerType: 'offer',
      },
    ],
  });
};

interface ITrackDeleteOfferFromComparison {
  offerId: number;
  label: string;
}

export const trackDeleteOfferFromComparison = ({ offerId, label }: ITrackDeleteOfferFromComparison) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Compare',
    action: 'Remove',
    label,
    products: [
      {
        id: offerId,
        offerType: 'offer',
      },
    ],
  });
};
