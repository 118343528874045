import * as React from 'react';

import { PessimizationFlexible } from '../../../components/PessimizationFlexible';
import { useOfferContext } from '../../../context';

export const PessimizationFlexibleContainer = () => {
  const {
    offer: { moderationInfo },
  } = useOfferContext();

  if (!moderationInfo?.warningMessage) {
    return null;
  }

  return <PessimizationFlexible warningMessage={moderationInfo.warningMessage} />;
};

PessimizationFlexibleContainer.displayName = 'PessimizationFlexibleContainer';
