import { IAppState } from '../../common/state';
import { IResidentialComplexLayoutsCount } from '../../types/residentialComplexLayouts';

const defaultState: IResidentialComplexLayoutsCount = {
  offersCount: 0,
};

export function newbuildingLayoutsCountReducer(state: IAppState): IAppState {
  return {
    ...state,
    newbuildingLayoutsCount: state.newbuildingLayoutsCount || defaultState,
  };
}
