import { ca } from '@cian/analytics';

export type TGeoSpecialTag =
  | 'geo-location'
  | 'geo-district'
  | 'geo-street'
  | 'geo-underground'
  | 'geo-railway'
  | 'geo-newobject';

export function trackGeoTagDeleted(type: TGeoSpecialTag) {
  const getLabel = (type: TGeoSpecialTag) => {
    switch (type) {
      case 'geo-location':
        return 'region';
      case 'geo-district':
        return 'district';
      case 'geo-street':
        return 'street';
      case 'geo-underground':
        return 'metro';
      case 'geo-railway':
        return 'railways';
      case 'geo-newobject':
        return 'jk';
      default:
        return 'none';
    }
  };

  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'delete_tag',
    label: `geo_${getLabel(type)}`,
  });
}

export function trackClearButtonClick(isAdvancedFiltersOpened: boolean | undefined) {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'clear_filters',
    label: `From_cat${isAdvancedFiltersOpened ? '_fastfilters' : ''}`,
  });
}
