import { ArticleHeading4 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './QuickLinksList.css';
import { QuickLinkItem } from './components/QuickLinkItem';
import { IQuickLink } from '../../../../../types/quickLinks';

export interface IQuickLinksListProps {
  linkGroups: IQuickLink[][];
  level: number;
}

export function QuickLinksList(props: IQuickLinksListProps) {
  const { linkGroups, level } = props;

  return (
    <div className={styles['section']}>
      <ArticleHeading4 as="h4" whiteSpace="nowrap">
        Рекомендуем посмотреть
      </ArticleHeading4>
      <div className={styles['list']}>
        {linkGroups.map((links, index) => {
          return (
            <div key={`quicks_link_${index}`} className={styles['column']}>
              {links.map((link, i) => {
                return (
                  <QuickLinkItem
                    key={`quick_link_${i}`}
                    level={level}
                    url={link.url}
                    offersCount={link.offersCount}
                    title={link.title}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
