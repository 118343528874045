import { IAppState, TActions } from '../../../common/state';
import { jq } from '../../../json_query';
import { EFacility } from '../../../json_query/types';

const IFacilitiesChangedActionType = 'filters/facilities/FACILITIES_CHANGED';
const IFurnitureChangedActionType = 'filters/facilities/FURNITURE_CHANGED';

export interface IFacilitiesChangedAction {
  type: 'filters/facilities/FACILITIES_CHANGED';
  param: EFacility;
  value: boolean;
}

export interface IFurnitureChangedAction {
  type: 'filters/facilities/FURNITURE_CHANGED';
  value: boolean | undefined;
}

export function changeFacility(param: EFacility, value: boolean): IFacilitiesChangedAction {
  return {
    type: IFacilitiesChangedActionType,
    param,
    value,
  };
}

export function changeFurniture(value: boolean | undefined): IFurnitureChangedAction {
  return {
    type: IFurnitureChangedActionType,
    value,
  };
}

export function facilitiesReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IFacilitiesChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setFacility(action.param, action.value),
        },
      };

    case IFurnitureChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            has_furniture:
              (action.value !== undefined && {
                type: 'term',
                value: action.value,
              }) ||
              undefined,
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-facilities-facility') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).setFacility(action.tag.value, false),
          },
        };
      } else if (action.tag.type === 'advanced-facilities-furniture') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              has_furniture: undefined,
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
}
