import { ActionAfterViewed } from '@cian/action-after-viewed-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackVillagesInView, trackVillageClick, trackShowAllVillagesClick } from './tracking';
import { VillagesRecommendations } from '../../components/VillagesRecommendations';
import { selectPageNumber } from '../../selectors/filters/selectPageNumber';
import { selectRecommendedVillages } from '../../selectors/selectRecommendedVillages';

export const VillagesRecommendationsContainer = () => {
  const recommendedVillages = useSelector(selectRecommendedVillages);
  const pageNumber = useSelector(selectPageNumber);
  const villageIds = React.useMemo(
    () => (recommendedVillages ? recommendedVillages.villageList.map(({ id }) => id) : []),
    [recommendedVillages],
  );

  const handleVillagesInView = React.useCallback(() => {
    trackVillagesInView(pageNumber, villageIds);
  }, [pageNumber, villageIds]);

  if (!recommendedVillages || !recommendedVillages.villageList.length) {
    return null;
  }

  return (
    <ActionAfterViewed callback={handleVillagesInView}>
      <VillagesRecommendations
        {...recommendedVillages}
        onVillageClick={trackVillageClick}
        onShowAllClick={trackShowAllVillagesClick}
      />
    </ActionAfterViewed>
  );
};
