import { IAppState, TActions } from '../../../common/state';
import { jq, jqs } from '../../../json_query';

const IMinFloorChangedActionType = 'filters/floor/MIN_FLOOR_CHANGED';
const IMaxFloorChangedActionType = 'filters/floor/MAX_FLOOR_CHANGED';
const IIsFirstChangedActionType = 'filters/floor/IS_FIRST_CHANGED';
const INotLastChangedActionType = 'filters/floor/NOT_LAST_CHANGED';

export interface IMinFloorChangedAction {
  type: 'filters/floor/MIN_FLOOR_CHANGED';
  value: number | undefined;
}

export interface IMaxFloorChangedAction {
  type: 'filters/floor/MAX_FLOOR_CHANGED';
  value: number | undefined;
}

export interface IIsFirstChangedAction {
  type: 'filters/floor/IS_FIRST_CHANGED';
  value: boolean | undefined;
}

export interface INotLastChangedAction {
  type: 'filters/floor/NOT_LAST_CHANGED';
  value: boolean | undefined;
}

export interface IIsBasementChangedAction {
  type: 'filters/floor/IS_BASEMENT_CHANGED';
  value: boolean | undefined;
}

export interface IIsSemibasementChangedAction {
  type: 'filters/floor/IS_SEMIBASEMENT_CHANGED';
  value: boolean | undefined;
}

export function changeMinFloor(value: number | undefined): IMinFloorChangedAction {
  return {
    type: IMinFloorChangedActionType,
    value,
  };
}

export function changeMaxFloor(value: number | undefined): IMaxFloorChangedAction {
  return {
    type: IMaxFloorChangedActionType,
    value,
  };
}

export function changeIsFirst(value: boolean | undefined): IIsFirstChangedAction {
  return {
    type: IIsFirstChangedActionType,
    value,
  };
}

export function changeNotLast(value: boolean | undefined): INotLastChangedAction {
  return {
    type: INotLastChangedActionType,
    value,
  };
}

export function floorReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IMinFloorChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setMinFloor(action.value),
        },
      };

    case IMaxFloorChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setMaxFloor(action.value),
        },
      };

    case IIsFirstChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setIsFirstFloor(action.value),
        },
      };

    case INotLastChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setNotLastFloor(action.value),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-floor-floor') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jqs(state.filters.jsonQuery, [
              jsonQuery => jq(jsonQuery).setMinFloor(undefined),
              jsonQuery => jq(jsonQuery).setMaxFloor(undefined),
            ]),
          },
        };
      } else if (action.tag.type === 'advanced-floor-isFirstFloor') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).setIsFirstFloor(undefined),
          },
        };
      } else if (action.tag.type === 'advanced-floor-notLastFloor') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).setNotLastFloor(undefined),
          },
        };
      }

      return state;

    default:
      return state;
  }
}
