import * as React from 'react';

import { IGeoBboxTag } from '../../../../../state/tags/definitions/geo';
import { TagNew } from '../../../TagNew';

export interface IGeoBboxTagItemProps {
  tag: IGeoBboxTag;
  onDeleteClick(): void;
}

export const GeoBboxTagItem = (props: IGeoBboxTagItemProps) => {
  return <TagNew onDeleteClick={props.onDeleteClick}>{props.tag.text}</TagNew>;
};
