import { ICalendarMonth, IDateIndex, TCalendarDayType } from '../types';

interface IParams {
  calendar: Array<ICalendarMonth>;
  index: IDateIndex;
  type: TCalendarDayType;
}

export const updateCalendarDay = (params: IParams): void => {
  const { calendar, index, type } = params;

  const day = calendar[index.month].days[index.day];

  if (day) {
    day.type = type;
  }
};
