import * as React from 'react';

import { ESuburbanFloors } from '../../../types/suburbanFloors';
import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';

interface ISuburbanFloors {
  label: string;
  value: ESuburbanFloors | null;
}

const FLOORS_OPTIONS: ISuburbanFloors[] = [
  { label: 'Неважно', value: null },
  { label: '1', value: ESuburbanFloors.One },
  { label: '2 и более', value: ESuburbanFloors.TwoAndMore },
];

interface ISuburbanFloorsProps {
  value: ESuburbanFloors | null;
  onChange(value: ESuburbanFloors | null): void;
}

export const SuburbanFloors: React.FC<ISuburbanFloorsProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_suburbanFloors" label="Этажей в доме">
      <RadioButtonGroup options={FLOORS_OPTIONS} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
