import * as React from 'react';
import useResizeObserver from 'use-resize-observer';

import { GeneralInfoSectionRow } from '../../../components/GeneralInfoSectionRow';
import { ResizableWrapper } from '../../../components/ResizableWrapper';
import { DescriptionContainer } from '../DescriptionContainer';
import { KPAdvantagesContainer } from '../KPAdvantagesContainer';
import { KPNameContainer } from '../KPNameContainer';

type ResizableContainerProps = {
  setCallback?(fn: () => void): void;
};

export const ResizableContainer: React.FC<ResizableContainerProps> = ({ setCallback }) => {
  const [blockHeight, setBlockHeight] = React.useState(0);
  const { ref, height } = useResizeObserver();
  const kpContainerRef = React.useRef<HTMLDivElement>(null);

  const saveHeight = React.useCallback(() => {
    if (height && kpContainerRef.current) {
      setBlockHeight(height - kpContainerRef.current.clientHeight);
    }
  }, [height]);

  React.useEffect(() => {
    saveHeight();
  }, [saveHeight]);

  return (
    <ResizableWrapper ref={ref}>
      <GeneralInfoSectionRow ref={kpContainerRef}>
        <KPNameContainer />
        <KPAdvantagesContainer />
      </GeneralInfoSectionRow>
      <DescriptionContainer setCallback={setCallback} parentHeight={blockHeight} />
    </ResizableWrapper>
  );
};
