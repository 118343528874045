import { UIHeading4, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './QuickLinksSuburbanBlackFriday.css';

interface IQuickLinksSuburbanBlackFridayProps {
  link: string;
}

export const QuickLinksSuburbanBlackFriday: React.VFC<IQuickLinksSuburbanBlackFridayProps> = ({ link }) => {
  return (
    <a href={link} target="_blank" className={styles['container']} rel="noopener noreferrer">
      <UIHeading4 color="white_100">
        Чёрная пятница
        <br />в коттеджных посёлках
      </UIHeading4>
      <div className={styles['subtitle']}>
        <UIText2 color="white_100" display="inline">
          Смотреть скидки
        </UIText2>
      </div>
    </a>
  );
};
