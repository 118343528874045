import React, { forwardRef, ReactNode } from 'react';

import styles from './ResizableWrapper.css';
import { GeneralInfoSectionRow } from '../GeneralInfoSectionRow';

export const ResizableWrapper = forwardRef<HTMLDivElement, { children: ReactNode }>(({ children }, ref) => {
  return (
    <GeneralInfoSectionRow ref={ref} className={styles['wrapper']}>
      {children}
    </GeneralInfoSectionRow>
  );
});

ResizableWrapper.displayName = 'ResizableWrapper';
