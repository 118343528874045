import { ErrorLogComponent } from '@cian/error-log-component';
import { DynamicCalltrackingContext } from '@cian/frontend-dynamic-calltracking-component';
import { Provider as MicrofrontendProvider } from '@cian/mf-react';
import { UiKitContextProvider } from '@cian/ui-kit';
import * as React from 'react';
import { Provider } from 'react-redux';

import { ActionsCentersLoadable } from './internal/components/ActionsCenters/ActionsCentersLoadable';
import { SearchEngineResultsPageContainer } from '../../common/components/serp/container';
import { IAppStore } from '../../common/state';
import { IApplicationContext } from '../../types/applicationContext';
import { ApplicationContext } from '../../utils/applicationContext';
import { NewbuildingBrokerWidgetProvider } from '../NewbuildingBrokerWidgetProvider';
import { SaveSearchModalProvider } from '../SaveSearchModalProvider';
import { SubscriptionsContainer } from '../Subscriptions';

export interface IApplicationProps {
  context: IApplicationContext;
  reduxStore: IAppStore;
}

export const Application: React.FC<IApplicationProps> = ({ reduxStore, context }) => {
  const { logger, microfrontendsRegistry } = context;

  return (
    <ErrorLogComponent logger={logger}>
      <ApplicationContext.Provider value={context}>
        <DynamicCalltrackingContext.Provider value={context}>
          <Provider store={reduxStore}>
            <UiKitContextProvider deviceType="desktop" useStaticDeviceType>
              <MicrofrontendProvider registry={microfrontendsRegistry}>
                <ActionsCentersLoadable />
                <SaveSearchModalProvider>
                  <NewbuildingBrokerWidgetProvider>
                    <SearchEngineResultsPageContainer />
                    <SubscriptionsContainer reduxStore={reduxStore} />
                  </NewbuildingBrokerWidgetProvider>
                </SaveSearchModalProvider>
              </MicrofrontendProvider>
            </UiKitContextProvider>
          </Provider>
        </DynamicCalltrackingContext.Provider>
      </ApplicationContext.Provider>
    </ErrorLogComponent>
  );
};
