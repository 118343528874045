import { ca } from '@cian/analytics';

export function trackOpen() {
  ca('event', {
    name: 'oldevent',
    category: 'subscription',
    action: 'Open',
    label: 'From_cat_save',
  });
}
