import { ModalWindow } from '@cian/ui-kit/modal';
import * as React from 'react';

import * as styles from './AdvancedFilters.css';
import { Header } from './components/Header';
import { AdvancedFiltersFooterContainer } from '../../containers/filters/AdvancedFiltersFooterContainer';

interface IAdvancedFiltersProps {
  open: boolean;
  onClearClick(): void;
  onShowClick(): void;
  onClose(): void;
}

export const AdvancedFilters: React.FC<React.PropsWithChildren<IAdvancedFiltersProps>> = ({
  children,
  open,
  onClearClick,
  onShowClick,
  onClose,
}) => {
  return (
    <ModalWindow
      open={open}
      onClose={onClose}
      size="M"
      fixed
      width={952}
      header={<Header />}
      footer={<AdvancedFiltersFooterContainer onClearClick={onClearClick} onShowClick={onShowClick} />}
    >
      <div className={styles['container']}>
        <div className={styles['wrapper']}>{children}</div>
      </div>
    </ModalWindow>
  );
};
