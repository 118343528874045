import educationIcon from './assets/education.svg';
import entranceIcon from './assets/entrance.svg';
import forestIcon from './assets/forest.svg';
import gasIcon from './assets/gas.svg';
import playgroundIcon from './assets/playground.svg';
import plumbingIcon from './assets/plumbing.svg';
import seweryIcon from './assets/sewery.svg';
import waterIcon from './assets/water.svg';
import { EKPAdvantage } from '../../types/kp';

export const advantageIcons: Record<EKPAdvantage, string> = {
  plumbing: plumbingIcon,
  sewery: seweryIcon,
  forest: forestIcon,
  gas: gasIcon,
  education: educationIcon,
  playground: playgroundIcon,
  water: waterIcon,
  entrance: entranceIcon,
};
