import React, { useMemo, MouseEvent } from 'react';

import { KPName } from '../../../components/KPName';
import { useOfferContext } from '../../../context';

export function KPNameContainer() {
  const { kp, offer, tracking } = useOfferContext();

  const handleClick = useMemo(() => {
    if (!kp?.url) {
      return undefined;
    }

    return (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      tracking.trackJKLinkClicked(offer);
    };
  }, [tracking.trackJKLinkClicked, offer, kp?.url]);

  if (!kp) {
    return null;
  }

  return <KPName {...kp} onClick={handleClick} />;
}
