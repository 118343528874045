import { Button } from '@cian/ui-kit/button';
import { Outside } from '@cian/ui-kit/services';
import * as React from 'react';

import * as styles from './Dates.css';
import { Calendar } from '../../../../../Calendar';
import { SearchResultsTooltip } from '../../SearchResultsTooltip';

interface IDatesProps {
  buttonLabel: string;
  isOpen?: boolean;
  minDate: Date;
  maxDate: Date;
  fromValue: Date | null;
  toValue: Date | null;
  hideTooltipCount?: boolean;
  offersCount?: number;
  offersCountLoading?: boolean;
  openTooltip?: boolean;
  onButtonClick?(): void;
  onDatesChange(from: Date | undefined, to: Date | undefined): void;
  onApply?(): void;
  onClose?(): void;
}

export const Dates = (props: IDatesProps) => {
  const {
    buttonLabel,
    isOpen,
    minDate,
    maxDate,
    fromValue,
    toValue,
    hideTooltipCount,
    offersCount,
    offersCountLoading,
    openTooltip,
    onButtonClick,
    onDatesChange,
    onApply,
    onClose,
  } = props;

  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const handleClose = React.useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <Outside insideRefs={[dropdownRef]} onOutside={handleClose} active={isOpen}>
      <div className={styles['container']} data-name="FilterDates">
        <SearchResultsTooltip
          open={!isOpen && openTooltip}
          hideCount={hideTooltipCount}
          offersCount={offersCount || 0}
          loading={offersCountLoading}
          placement="bottom-start"
          onApply={onApply}
        >
          <Button theme="stroke_secondary" size="XS" onClick={onButtonClick}>
            {buttonLabel}
          </Button>
        </SearchResultsTooltip>

        {isOpen && (
          <SearchResultsTooltip
            open={openTooltip}
            hideCount={hideTooltipCount}
            offersCount={offersCount || 0}
            loading={offersCountLoading}
            placement="right"
            onApply={onApply}
          >
            <div className={styles['dropdown']} ref={dropdownRef}>
              <Calendar
                minDate={minDate}
                maxDate={maxDate}
                fromValue={fromValue}
                toValue={toValue}
                onChange={onDatesChange}
              />
            </div>
          </SearchResultsTooltip>
        )}
      </div>
    </Outside>
  );
};
