import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackBannerClick, trackBannerShow } from './tracking';
import { DiscountMortgageBanner } from '../../components/NewbuildingDiscountMortgageBanner';
import { getJsonQuery } from '../../selectors/getJsonQuery';
import { getMortgageBannerUrl } from '../../selectors/newbuildingDiscountMortgage';

// TODO будет удален после 1-го июля в https://jira.cian.tech/browse/CD-206149
/* istanbul ignore next */
export const DiscountMortgageBannerContainer = () => {
  const url = useSelector(getMortgageBannerUrl);
  const jsonQuery = useSelector(getJsonQuery);

  const onBannerShown = React.useCallback(() => trackBannerShow(jsonQuery), [jsonQuery]);
  const onBannerClick = React.useCallback(() => trackBannerClick(jsonQuery), [jsonQuery]);

  return (
    <ActionAfterViewObserver callback={onBannerShown} triggerOnce>
      <DiscountMortgageBanner url={url} onClick={onBannerClick} />
    </ActionAfterViewObserver>
  );
};
