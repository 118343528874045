import * as React from 'react';

import { getTermsValue, NonEmptyArray } from '../../../../../JsonQuery';
import { Repair } from '../../../components/advancedFilters/Repair';
import { ERepair } from '../../../types/jsonQuery';
import { useContext } from '../../../utils/useContext';

export const RepairContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  let repair = getTermsValue('repair')(jsonQuery);
  if (repair && repair.length === 0) {
    repair = null;
  }

  const handleChange = React.useCallback(
    (repair: NonEmptyArray<ERepair> | null) => {
      onChange({ action: 'setRepair', arguments: [repair] });
    },
    [onChange],
  );

  return <Repair value={repair as NonEmptyArray<ERepair> | null} onChange={handleChange} />;
};
