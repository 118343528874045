import {
  IDeleteOfferFromComparisonResponse,
  IDeleteOfferFromComparisonResponse200,
} from '../../../../repositories/offers-comparison/v1/delete-offer-from-comparison';

export const mapDeleteOfferFromComparisonResponse = (
  response: IDeleteOfferFromComparisonResponse,
): IDeleteOfferFromComparisonResponse200 => {
  return {
    statusCode: 200,
    headers: [],
    response,
  };
};
