import * as React from 'react';

import { ITotalBedroomsTag } from '../../../state/tags/definitions/total_bedrooms';
import { TagNew } from '../TagNew';

export interface ITotalBedroomsTagItemProps {
  tag: ITotalBedroomsTag;
  onDeleteClick(): void;
}

export const TotalBedroomsTagItem = (props: ITotalBedroomsTagItemProps) => {
  const text = [];

  if (props.tag.min !== undefined) {
    text.push(`от ${props.tag.min}`);
  }

  if (props.tag.max !== undefined) {
    text.push(`до ${props.tag.max}`);
  }

  return <TagNew onDeleteClick={props.onDeleteClick}>Спален в доме {text.join(' ')}</TagNew>;
};
