import { getValueToTagLabelMap } from '../../../../utils/newbuildingPromoSearchTypes';
import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const INewbuildingPromoSearchTypesTagType = 'promo_search_types';

export interface INewbuildingPromoSearchTypesTag {
  type: 'promo_search_types';
  value: string;
  label?: string;
}

export function isNewbuildingPromoSearchTypesTag(tag: TTag): tag is INewbuildingPromoSearchTypesTag {
  return tag.type === INewbuildingPromoSearchTypesTagType;
}

export const newbuildingPromoSearchTypesTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery, tagsData) => {
    const { newbuildingPromoSearchTypes } = tagsData;

    const values = jsonQuery.promo_search_types && jsonQuery.promo_search_types.value;

    let nextTags = tags.filter(
      tag => tag.type !== INewbuildingPromoSearchTypesTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined && newbuildingPromoSearchTypes) {
      nextTags = values.reduce(
        (result, value: string) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is INewbuildingPromoSearchTypesTag =>
              isNewbuildingPromoSearchTypesTag(tag) && tag.value === value,
            () => ({
              type: INewbuildingPromoSearchTypesTagType,
              value,
              label: getValueToTagLabelMap(newbuildingPromoSearchTypes).get(value),
            }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
