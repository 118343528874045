import * as React from 'react';

import { IOfferIdTag } from '../../../state/tags/definitions/offer_id';
import { TagNew } from '../TagNew';

export interface IOfferIdTagItemProps {
  tag: IOfferIdTag;
  onDeleteClick(): void;
}

export const OfferIdTagItem = ({ onDeleteClick, tag }: IOfferIdTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>Объявление №{tag.value}</TagNew>;
};
