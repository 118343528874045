import { pathOr } from 'ramda';
import * as React from 'react';

import { offerTypeFromJsonQuery } from '../../../../../JsonQuery';
import { UndergroundTime } from '../../../components/advancedFilters/UndergroundTime';
import { EMetroTravelType } from '../../../types/jsonQuery';
import { useContext } from '../../../utils/useContext';

interface ITravelDataChange {
  travelTime: number | null;
  travelType: EMetroTravelType | null;
}

export const UndergroundTimeContainer: React.FC = () => {
  const { onChange, jsonQuery } = useContext();

  const offerType = offerTypeFromJsonQuery(jsonQuery);
  const travelType = pathOr<EMetroTravelType | null>(null, ['only_foot', 'value'], jsonQuery);
  const travelTime = pathOr<number | null>(null, ['foot_min', 'value', 'lte'], jsonQuery);

  const handleChangeTravelTime = React.useCallback(
    (time: number | null) => {
      onChange({ action: 'setUndergroundTravelTime', arguments: [time] });
    },
    [onChange],
  );

  const handleChangeTravelType = React.useCallback(
    (type: EMetroTravelType | null) => {
      onChange({ action: 'setUndergroundTravelType', arguments: [type] });
    },
    [onChange],
  );

  const handleChangeTravelData = React.useCallback(
    ({ travelTime, travelType }: ITravelDataChange) => {
      onChange(
        { action: 'setUndergroundTravelType', arguments: [travelType] },
        { action: 'setUndergroundTravelTime', arguments: [travelTime] },
      );
    },
    [onChange],
  );

  return (
    <UndergroundTime
      offerType={offerType}
      travelType={travelType}
      travelTime={travelTime}
      onTravelTimeChange={handleChangeTravelTime}
      onTravelTypeChange={handleChangeTravelType}
      onTravelDataChange={handleChangeTravelData}
    />
  );
};
