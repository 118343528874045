import { isFromSuburbanBuilder } from './isFromSuburbanBuilder';
import { TComputedPropMapper } from '../../../types/computed-props-mapper';

export const isFromBuilder: TComputedPropMapper<boolean> = (props, ctx) => {
  const { newbuilding } = props.offer;

  return (
    Boolean(newbuilding && newbuilding.isFromBuilder) ||
    Boolean(ctx.config.get<boolean>('Countryside.OffersBuilderBadge.Enabled') && isFromSuburbanBuilder(props, ctx))
  );
};
