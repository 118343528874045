import { LinkButton } from '@cian/ui-kit';
import React, { MouseEvent, useCallback } from 'react';

import { EActionType } from '../../../../../../../shared/repositories/search-offers.legacy/v2/types/dailyrentData';
import { useOfferContext } from '../../../../context';

export const getTitleByActionType = (actionType?: EActionType) => {
  switch (actionType) {
    case EActionType.PartnerBooking:
      return 'Забронировать';
    case EActionType.PartnerCheckDates:
      return 'Проверить даты';
    default:
      return undefined;
  }
};

export function DailyrentPartnerButtonContainer() {
  const {
    offer: { externalOfferUrl, dailyrent },
    tracking,
  } = useOfferContext();

  const title = getTitleByActionType(dailyrent?.actionType);

  const handleClick = useCallback((e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    tracking.trackPhoneOpened(false);
  }, []);

  return (
    <LinkButton
      onClick={handleClick}
      href={externalOfferUrl}
      fullWidth
      size="XS"
      role="button"
      theme="fill_primary"
      target="_blank"
      rel="noopener noreferrer"
    >
      {title}
    </LinkButton>
  );
}
