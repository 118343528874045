export enum EHeatingType {
  None = 1,
  Gas = 2,
  Coal = 3,
  Furnace = 4,
  Fireplace = 5,
  Electric = 6,
  AutonomousGas = 6300,
  Diesel = 6301,
  SolidFuelBoiler = 6302,
}
