import { getTermValue } from './helpers';
import { EFacilityType, TJsonQuery } from './types';
import { NonEmptyArray } from './types/common';

export function getFacilityTypes(jsonQuery: TJsonQuery): NonEmptyArray<EFacilityType> | null {
  const hasConditioner = getTermValue('conditioner')(jsonQuery);
  const hasFurniture = getTermValue('has_furniture')(jsonQuery);
  const hasInternet = getTermValue('internet')(jsonQuery);
  const hasFridge = getTermValue('has_fridge')(jsonQuery);
  const hasPhone = getTermValue('phone')(jsonQuery);
  const hasDishwasher = getTermValue('dish_washer')(jsonQuery);
  const hasKitchenFurniture = getTermValue('has_kitchen_furniture')(jsonQuery);
  const hasTV = getTermValue('tv')(jsonQuery);
  const hasWasher = getTermValue('has_washer')(jsonQuery);

  const facilityTypes: EFacilityType[] = [];

  if (hasConditioner) {
    facilityTypes.push(EFacilityType.Conditioner);
  }

  if (hasFurniture) {
    facilityTypes.push(EFacilityType.RoomFurniture);
  }

  if (hasKitchenFurniture && hasFurniture !== false) {
    facilityTypes.push(EFacilityType.KitchenFurniture);
  }

  if (hasFurniture === false) {
    facilityTypes.push(EFacilityType.WithoutFurniture);
  }

  if (hasInternet) {
    facilityTypes.push(EFacilityType.Internet);
  }

  if (hasFridge) {
    facilityTypes.push(EFacilityType.Fridge);
  }

  if (hasPhone) {
    facilityTypes.push(EFacilityType.Phone);
  }

  if (hasDishwasher) {
    facilityTypes.push(EFacilityType.Dishwasher);
  }

  if (hasTV) {
    facilityTypes.push(EFacilityType.TV);
  }

  if (hasWasher) {
    facilityTypes.push(EFacilityType.Washer);
  }

  return facilityTypes.length > 0 ? (facilityTypes as NonEmptyArray<EFacilityType>) : null;
}
