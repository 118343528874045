import * as R from 'ramda';

import { setGeo } from './helpers';
import { NonEmptyArray, TJsonQuery } from './types';
import { IJsonQueryHighway } from '../api-models/common/json_query';

export function setHighways(jsonQuery: TJsonQuery): (...geoHighways: NonEmptyArray<IJsonQueryHighway>) => TJsonQuery {
  return (...geoHighways) => {
    const geo = (jsonQuery.geo && R.clone(jsonQuery.geo.value)) || [];

    const nextGeo = geo.filter(g => g.type !== 'highway' || geoHighways.some(h => h.id === g.id));
    const addedGeoHighways = geoHighways.filter(h => !geo.some(g => R.equals(h, g)));

    return setGeo()(jsonQuery)([...nextGeo, ...addedGeoHighways]);
  };
}
