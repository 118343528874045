import * as React from 'react';

import { TFilter, TOpenedFiltersState } from '../../../../packages/Filters/shared/types/filters';

const defaultState: TOpenedFiltersState = {};

export function useFiltersState() {
  const [openedFilters, setOpenedFilters] = React.useState<TOpenedFiltersState>(defaultState);
  const openFilter = (filter: TFilter) => setOpenedFilters(state => ({ ...state, [filter]: true }));
  const closeFilter = (filter: TFilter) => setOpenedFilters(state => ({ ...state, [filter]: false }));

  return {
    openedFilters,
    openFilter,
    closeFilter,
  };
}
