import { BuildingQuarter, IOffer } from '../../../../../../shared/offer';

export function getBuildingDeadline(offer: IOffer): string | undefined {
  const { deadline, buildYear } = offer.building || {};

  if (deadline) {
    if (deadline.isComplete) {
      return 'дом сдан';
    }

    if (deadline.quarter) {
      let text = `сдача ГК: ${BuildingQuarter[deadline.quarter]} кв.`;

      if (deadline.year) {
        text += ` ${deadline.year} года`;
      } else if (buildYear) {
        text += ` ${buildYear} года`;
      }

      return text;
    }
  }

  return undefined;
}
