import * as React from 'react';

import { AREA_TYPE_SETTINGS } from './constants';
import { ISquareMeterAreaValue, TSquareMeterAreaType } from './types';
import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';
import { RangeInput } from '../common/RangeInput';

interface ISquareMeterAreaProps {
  values: ISquareMeterAreaValue[];
  onMinChange(type: TSquareMeterAreaType, value: number | null): void;
  onMaxChange(type: TSquareMeterAreaType, value: number | null): void;
}

export const SquareMeterArea: React.FC<ISquareMeterAreaProps> = ({ values, onMinChange, onMaxChange }) => {
  const handleMinChange = useDebouncedCallback(
    (type: TSquareMeterAreaType, gte: number | null) => {
      onMinChange(type, gte);
    },
    300,
    [onMinChange],
  );

  const handleMaxChange = useDebouncedCallback(
    (type: TSquareMeterAreaType, gte: number | null) => {
      onMaxChange(type, gte);
    },
    300,
    [onMaxChange],
  );

  return (
    <Filter
      label={
        <>
          Площадь, м<sup>2</sup>
        </>
      }
    >
      <InlineFilterGroup>
        {values.map(({ type, value }) => {
          const { gte: valueMin = null, lte: valueMax = null } = value;
          const { title } = AREA_TYPE_SETTINGS[type];
          const withPrefix = values.length > 1;

          return (
            <InlineFilter key={`area_${type}`} prefix={withPrefix ? title : undefined}>
              <RangeInput
                min={1}
                max={9999}
                valueMin={valueMin}
                valueMax={valueMax}
                onChangeMin={value => handleMinChange(type, value)}
                onChangeMax={value => handleMaxChange(type, value)}
              />
            </InlineFilter>
          );
        })}
      </InlineFilterGroup>
    </Filter>
  );
};
