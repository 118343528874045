import * as React from 'react';

import { IDecorationTag } from '../../../state/tags/definitions/decoration';
import { TagNew } from '../TagNew';

export interface IDecorationTagItemProps {
  tag: IDecorationTag;
  onDeleteClick(): void;
}

export const DecorationTagItem = ({ onDeleteClick, tag }: IDecorationTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>{tag.value ? 'С отделкой' : 'Без отделки'}</TagNew>;
};
