import { EVas } from '../../../types/vas';

export type TPriorities = {
  [key in EVas]: number;
};

const PRIORITIES: TPriorities = {
  [EVas.Auction]: 4,
  [EVas.Standard]: 2,
  [EVas.Premium]: 3,
  [EVas.Top]: 5,
};

export function sortVas(vasList: EVas[], priorities: TPriorities = PRIORITIES): EVas[] {
  return vasList.slice(0).sort((a, b) => {
    const aPriority = priorities[a];
    const bPriority = priorities[b];

    // Не переписывать на тернарник, иначе падают тесты
    if (aPriority <= bPriority) {
      return 1;
    }

    return -1;
  });
}
