import { Checkbox } from '@cian/ui-kit';
import * as React from 'react';

import { FDealType, FOfferType } from '../../../../../JsonQuery';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';

interface IDealTypeProps {
  dealType: FDealType;
  offerType: FOfferType;
  isByHomeowner: boolean | null;
  onIsByHomeownerChange(value: boolean | null): void;
}

export const DealType: React.FC<IDealTypeProps> = ({ offerType, isByHomeowner, onIsByHomeownerChange }) => {
  const isByHomeownerAvailable = (offerType & (FOfferType.Residential & ~FOfferType.FlatNew)) !== 0;

  return (
    <Filter id="advancedFilter_dealType" label="Тип сделки">
      <InlineFilterGroup>
        <InlineFilter>
          {isByHomeownerAvailable && (
            <Checkbox
              label="От собственника"
              checked={!!isByHomeowner}
              onChange={event => onIsByHomeownerChange(event.target.checked || null)}
            />
          )}
        </InlineFilter>
      </InlineFilterGroup>
    </Filter>
  );
};
