import { IApplicationContext } from '../../types/applicationContext';
import { IUndergroundJSONDataPath } from '../../types/undergroundStationData';

import type { IUndergroundMapDataV1, IUndergroundMapDataV2 } from '@cian/underground-map-widget';

export async function fetchUndergroundMapData(
  locationId: number,
  ctx: IApplicationContext,
): Promise<IUndergroundMapDataV1 | IUndergroundMapDataV2> {
  const newMapJsonPath = ctx.config.get<IUndergroundJSONDataPath>('undergroundMapDataV2') ?? null;

  if (
    newMapJsonPath &&
    Object.keys(newMapJsonPath)
      .map(location => Number(location))
      .includes(locationId)
  ) {
    const data = await fetch(newMapJsonPath[locationId]).then(response => response.json());

    return data as IUndergroundMapDataV2;
  } else {
    const data = await import(`@cian/metro/json/${locationId}.json`);

    return data.default as IUndergroundMapDataV1;
  }
}
