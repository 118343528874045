import { Spinner } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './LoadingContent.css';

export const LoadingContent: React.FC = () => {
  return (
    <div data-testid="loading-content" className={styles['container']}>
      <Spinner color="gray_icons_100" />
    </div>
  );
};
