import { plural } from '@cian/utils';

const SHRUG = '¯\\_(ツ)_/¯';

export function getBedroomsLabel(min: number | null, max: number | null): string | null {
  if (min === 0 && max === 0) {
    return SHRUG;
  }

  if (min !== null && max === null) {
    return `от ${min} ${plural(min, ['спальни', 'спален', 'спален'])}`;
  } else if (min === null && max !== null) {
    return `до ${max} ${plural(max, ['спальни', 'спален', 'спален'])}`;
  } else if (min !== null && max !== null) {
    return `${min} - ${max} спален`;
  }

  return null;
}
