import { withObservation } from '@cian/component-observer';
import { connect } from 'react-redux';

import { BannerServicePlaceInternal, IBannerServicePlaceInternalProps } from './index';
import { sendTeaserEvent, ITeaserEventProps } from '../../../../../browser/utils/TeaserEvent';
import { IAppState } from '../../../../common/state';
import { TUser } from '../../../../types/user';

type StateProps = {
  currentResultPageNumber?: number;
  deviceType?: string;
  observerKey?: string | number;
  queryString?: string;
  soprApi?: string;
  user: TUser;
};

export const mapStateToProps = (state: IAppState): StateProps => {
  return {
    soprApi: state.soprApi,
    deviceType: state.deviceType,
    user: state.user,
    observerKey: state.results.queryString,
    queryString: state.results.queryString,
  };
};

export const getServicePlaceWidgetName = (position: number) => {
  switch (position) {
    case 2:
      return 'servisnoe_mesto_oldstyle_3_show';
    case 32:
      return 'servisnoe_mesto_oldstyle_33_show';
    case 62:
      return 'servisnoe_mesto_oldstyle_63_show';
    default:
      return '';
  }
};

export const transformProps = (props: IBannerServicePlaceInternalProps & StateProps) => ({
  soprApi: props.soprApi || '',
  user_id: (props.user && props.user.isAuthenticated && props.user.userId) || null,
  pagenumber: props.currentResultPageNumber || 1,
  position: props.position || 1,
  pageType: 'Listing',
  device_type: props.deviceType || '',
  block_type: 'mortgage_widget',
  sc: props.queryString || '',
  name: getServicePlaceWidgetName(props.position),
});

export const getTeaserProps = ({
  props,
  isViewed,
}: {
  props: IBannerServicePlaceInternalProps & StateProps;
  isViewed: boolean;
}): ITeaserEventProps => ({
  ...transformProps(props),
  isTeaserShow: isViewed ? 1 : 0,
});

export const observerOptions = {
  onChange: (events: Array<{ props: IBannerServicePlaceInternalProps; isViewed: boolean }>) =>
    sendTeaserEvent(events.map(getTeaserProps)),
};

export const ObservableBannerServicePlace = connect(mapStateToProps)(
  withObservation<IBannerServicePlaceInternalProps>(BannerServicePlaceInternal, observerOptions),
);
