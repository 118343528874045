import { Button } from '@cian/ui-kit/button';
import { Outside } from '@cian/ui-kit/services';
import * as React from 'react';

import * as styles from './Beds.css';
import { getBedsLabel } from './helpers';
import { NumberInputWithButtons } from '../../../../../NumberInputWithButtons';
import { SearchResultsTooltip } from '../../SearchResultsTooltip';

interface IAreaProps {
  open?: boolean;
  value: number;
  min: number;
  max: number;
  hideTooltipCount?: boolean;
  offersCount?: number;
  offersCountLoading?: boolean;
  openTooltip?: boolean;

  onOpen?(): void;
  onChange(value: number): void;
  onApply?(): void;
  onClose?(): void;
}

export const Beds: React.FC<IAreaProps> = ({
  open,
  value,
  min,
  max,
  hideTooltipCount,
  offersCount,
  offersCountLoading,
  openTooltip,
  onOpen,
  onChange,
  onApply,
  onClose,
}) => {
  const insideRef = React.useRef<HTMLDivElement>(null);

  const areaLabel = getBedsLabel(value);

  const handleClose = React.useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <Outside insideRefs={[insideRef]} onOutside={handleClose} active={open}>
      <div ref={insideRef} className={styles['container']} data-name="FilterBeds">
        <SearchResultsTooltip
          open={!open && openTooltip}
          hideCount={hideTooltipCount}
          offersCount={offersCount || 0}
          loading={offersCountLoading}
          placement="bottom-start"
          onApply={onApply}
        >
          <Button theme="stroke_secondary" size="XS" onClick={onOpen}>
            {areaLabel}
          </Button>
        </SearchResultsTooltip>
        {open && (
          <SearchResultsTooltip
            open={openTooltip}
            hideCount={hideTooltipCount}
            offersCount={offersCount || 0}
            loading={offersCountLoading}
            placement="right"
            onApply={onApply}
          >
            <div className={styles['dropdown']}>
              <div className={styles['heading']}>Количество гостей</div>
              <NumberInputWithButtons size="XS" value={value} onChange={onChange} min={min} max={max} />
            </div>
          </SearchResultsTooltip>
        )}
      </div>
    </Outside>
  );
};
