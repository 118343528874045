import { flatten, without } from 'ramda';
import * as React from 'react';

import { getOfferTypeList } from './helpers';
import { useApplicationContext } from '../../../../../../shared/utils/applicationContext';
import { FDealType, FOfferType, isAvailableOfferTypeCombination } from '../../../../../JsonQuery';
import { Select, Option } from '../../Select';

interface IOfferTypeProps {
  open?: boolean;
  dealType: FDealType;
  label: string | null;
  value: FOfferType;
  hideTooltipCount?: boolean;
  offersCount: number | undefined;
  offersCountLoading?: boolean;
  popupPostfix?: React.ReactNode;
  openTooltip?: boolean;
  onChange(value: FOfferType): void;
  onApply?(): void;
  onOpen?(): void;
  onClose?(): void;
}

export const OfferType: React.FC<IOfferTypeProps> = ({
  open,
  dealType,
  label,
  value,
  hideTooltipCount,
  offersCount,
  offersCountLoading,
  popupPostfix,
  openTooltip,
  onChange,
  onApply,
  onOpen,
  onClose,
}) => {
  const { config } = useApplicationContext();
  const isSearchFlatShareEnabled = config.getStrict<boolean>('frontend_search_flat_share_enabled.Enabled');
  const isHotelsEnabled = config.getStrict<boolean>('Dailyrent.HotelsSearchFrontend.Enabled');
  const offerTypes = getOfferTypeList(dealType, isSearchFlatShareEnabled, isHotelsEnabled);

  const selectedOptions = flatten(offerTypes)
    .filter(offerType => (value & offerType.value) !== 0)
    .map(offerType => offerType.value);

  return (
    <Select
      open={open}
      multiple
      withoutArrow
      label={label || undefined}
      placeholder="Тип предложения"
      value={selectedOptions}
      hideTooltipCount={hideTooltipCount}
      offersCount={offersCount}
      offersCountLoading={offersCountLoading}
      openTooltip={openTooltip}
      onChange={(_, selectedValues: FOfferType[]) => {
        const selectedOfferType = without(
          selectedOptions.length < selectedValues.length ? selectedOptions : selectedValues,
          selectedOptions.length < selectedValues.length ? selectedValues : selectedOptions,
        )[0];

        let nextOfferTypes: FOfferType[] = [selectedOfferType];
        if (selectedOptions.includes(selectedOfferType)) {
          if (selectedOptions.length > 1) {
            nextOfferTypes = selectedOptions.filter(o => o !== selectedOfferType);
          }
        } else {
          if (
            isAvailableOfferTypeCombination(
              [...selectedOptions, selectedOfferType].reduce(
                (previousValue, currentValue) => previousValue | currentValue,
                FOfferType.Unexpected,
              ),
            )
          ) {
            nextOfferTypes = [...selectedOptions, selectedOfferType];
          }
        }

        const nextOfferType: FOfferType = nextOfferTypes.reduce(
          (previousValue, currentValue) => previousValue | currentValue,
          FOfferType.Unexpected,
        );

        onChange(nextOfferType);
      }}
      onApply={onApply}
      onOpen={onOpen}
      onClose={onClose}
      popupPostfix={popupPostfix}
    >
      {offerTypes.map(parts =>
        parts.map(offerType => (
          <Option key={`${offerType.label}_${offerType.value}`} value={offerType.value} marker={offerType.marker}>
            {offerType.label}
          </Option>
        )),
      )}
    </Select>
  );
};
