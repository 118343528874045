import { Image } from '@cian/ui-kit/image';
import classNames from 'classnames';
import * as React from 'react';

import styles from './Gallery.css';
import { Carousel, IMedia } from '../../../../../shared/components/Carousel';

interface IGalleryProps {
  media: IMedia[];
  deoptimised?: boolean;
  bookedFromCian?: boolean;
  bookedFromDeveloper?: boolean;
  onChangeSlide(): void;
}

export function Gallery({ media, deoptimised, bookedFromCian, bookedFromDeveloper, onChangeSlide }: IGalleryProps) {
  if (media.length === 0) {
    return (
      <div className={classNames(styles['cont'], styles['cont--empty'])}>
        <Image src="" />
      </div>
    );
  }

  return (
    <div data-name="Gallery" className={styles['cont']}>
      <Carousel
        media={media}
        deoptimised={deoptimised}
        bookedFromCian={bookedFromCian}
        bookedFromDeveloper={bookedFromDeveloper}
        onSlideChange={onChangeSlide}
      />
    </div>
  );
}
