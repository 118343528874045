import { Outside } from '@cian/ui-kit';
import { Button } from '@cian/ui-kit/button';
import * as React from 'react';

import * as styles from './Price.css';
import {
  PRICE_TYPES,
  getIsSquareMeterPrice,
  getPriceLabel,
  getPriceTitle,
  getPriceType,
  getPriceTypeLabel,
} from './helpers';
import { EPriceType, FDealType, FOfferType } from '../../../../../JsonQuery';
import { IJsonQueryRangeValue } from '../../../types/jsonQuery';
import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { RangeInput } from '../../RangeInput';
import { SearchResultsTooltip } from '../../SearchResultsTooltip';
import { Option, Select } from '../../Select';

interface IPriceProps {
  open?: boolean;
  dealType: FDealType;
  offerType: FOfferType;
  price: IJsonQueryRangeValue | null;
  defaultPriceLabel?: string;
  isSquareMeterPrice: boolean | null;
  hideTooltipCount?: boolean;
  offersCount?: number;
  offersCountLoading?: boolean;
  openTooltip?: boolean;
  onPriceMinChange(gte: number | null): void;
  onPriceMaxChange(lte: number | null): void;
  onPriceTypeChange(isSquareMeterPrice: boolean | null): void;
  onApply?(): void;
  onOpen?(): void;
  onClose?(): void;
}

export const Price: React.FC<IPriceProps> = ({
  open,
  dealType,
  offerType,
  price,
  defaultPriceLabel = 'Цена',
  hideTooltipCount,
  offersCount,
  offersCountLoading,
  openTooltip,
  isSquareMeterPrice,
  onPriceMinChange,
  onPriceMaxChange,
  onPriceTypeChange,
  onApply,
  onOpen,
  onClose,
}) => {
  const { gte: priceMin = null, lte: priceMax = null } = price || {};
  const isPriceTypeAvailable = dealType === FDealType.Sale && (offerType & FOfferType.Urban) !== 0;

  const [isPriceTypeOpen, setPriceTypeOpen] = React.useState(false);

  const priceTitle = getPriceTitle(priceMin, priceMax) || undefined;
  const priceLabel = getPriceLabel(priceMin, priceMax);
  const priceType = getPriceType(isSquareMeterPrice);
  const priceTypeLabel = getPriceTypeLabel(priceType);

  const handleClick = React.useCallback(() => {
    if (!open && onOpen) {
      onOpen();
    } else if (open && onClose) {
      onClose();
    }
  }, [open, onOpen, onClose]);

  const handleClose = React.useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handlePriceMinChange = useDebouncedCallback(
    (gte: number | null) => {
      onPriceMinChange(gte);
    },
    300,
    [onPriceMinChange],
  );

  const handlePriceMaxChange = useDebouncedCallback(
    (lte: number | null) => {
      onPriceMaxChange(lte);
    },
    300,
    [onPriceMaxChange],
  );

  return (
    <Outside onOutside={handleClose} active={open}>
      <div className={styles['container']}>
        <SearchResultsTooltip
          open={!open && openTooltip}
          hideCount={hideTooltipCount}
          offersCount={offersCount || 0}
          loading={offersCountLoading}
          placement="bottom-start"
          onApply={onApply}
        >
          <Button theme="stroke_secondary" size="XS" title={priceTitle} onClick={handleClick}>
            {priceLabel || defaultPriceLabel}
          </Button>
        </SearchResultsTooltip>
        {open && (
          <SearchResultsTooltip
            open={openTooltip}
            hideCount={hideTooltipCount}
            offersCount={offersCount || 0}
            loading={offersCountLoading}
            placement="right"
            onApply={onApply}
          >
            <div className={styles['dropdown']}>
              <div className={styles['dropdown-filter']}>
                <RangeInput
                  min={0}
                  max={999999999999}
                  joined={false}
                  postfix={'\u20bd'}
                  valueMin={priceMin}
                  valueMax={priceMax}
                  onChangeMin={handlePriceMinChange}
                  onChangeMax={handlePriceMaxChange}
                />
              </div>
              {isPriceTypeAvailable && (
                <div className={styles['dropdown-filter']}>
                  <Select
                    open={isPriceTypeOpen}
                    withoutArrow
                    buttonAppearance={'underlined'}
                    value={priceType || EPriceType.Total}
                    label={priceTypeLabel}
                    onChange={(_, value: EPriceType) => onPriceTypeChange(getIsSquareMeterPrice(value))}
                    onOpen={() => setPriceTypeOpen(true)}
                    onClose={() => setPriceTypeOpen(false)}
                  >
                    {PRICE_TYPES.map(priceType => (
                      <Option key={`${priceType.label}_${priceType.value}`} value={priceType.value}>
                        {priceType.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
            </div>
          </SearchResultsTooltip>
        )}
      </div>
    </Outside>
  );
};
