/* eslint-disable max-lines */
import {
  EBuildingStatus,
  EObjectType,
  EOfficeType,
  ERentTime,
  ERoomType,
  IJsonQuery,
} from '../repositories/common/json_query';

/** Тип сделки */
export enum FDealType {
  /** Do not use */
  Unexpected = 0,
  /** Продажа */
  Sale = 1 << 0,
  /** Аренда длительная */
  RentLongterm = 1 << 1,
  /** Аренда посуточная */
  RentDaily = 1 << 2,
  /** Аренда */
  Rent = RentLongterm | RentDaily,
  /** Любой тип сделки */
  Any = Sale | Rent,
}

/** Тип предложения */
export enum FOfferType {
  /** Do not use */
  Unexpected = 0,
  /** Квартира во вторичке */
  FlatOld = 1 << 1,
  /** Квартира в новостройке */
  FlatNew = 1 << 2,
  /** Квартира */
  Flat = FlatOld | FlatNew,
  /** Доля в квартире */
  FlatShared = 1 << 3,
  /** Комната */
  Room = 1 << 4,
  /** Койко-место */
  Bed = 1 << 5,
  /** Городская недвижимость */
  Urban = Flat | FlatShared | Room | Bed,
  /** Дом */
  House = 1 << 6,
  /** Часть дома */
  HousePart = 1 << 7,
  /** Таунхаус */
  Townhouse = 1 << 8,
  /** Участок */
  Land = 1 << 9,
  /** Загородная недвижимость */
  Suburban = House | HousePart | Townhouse | Land,
  /** Гараж */
  Garage = 1 << 10,
  /** Отель */
  Hotel = 1 << 23,
  /** Другая недвижимость */
  Other = Garage,
  /** Жилая недвижимость */
  Residential = Urban | Suburban | Other,
  /** Любая недвижимость */
  Any = Residential,
}

/**
 * Определение типа сделки по jsonQuery
 */
export const dealTypeFromJsonQuery = (jsonQuery: IJsonQuery): FDealType => {
  const type = jsonQuery._type;
  const rentTime = jsonQuery.for_day && jsonQuery.for_day.value;

  if (type.endsWith('sale')) {
    return FDealType.Sale;
  }

  if (type.endsWith('rent')) {
    if (rentTime === ERentTime.NotDaily) {
      return FDealType.RentLongterm;
    } else if (rentTime === ERentTime.Daily) {
      return FDealType.RentDaily;
    }

    return FDealType.Rent;
  }

  // istanbul ignore next
  return FDealType.Unexpected;
};

/**
 * Определение типа предложения по jsonQuery
 */
export const offerTypeFromJsonQuery = (jsonQuery: IJsonQuery): FOfferType => {
  const type = jsonQuery._type;
  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const room = jsonQuery.room && jsonQuery.room.value;
  const buildingStatus = jsonQuery.building_status && jsonQuery.building_status.value;
  const fromDeveloper = jsonQuery.from_developer && jsonQuery.from_developer.value;
  const withNewbuildings = jsonQuery.with_newobject && jsonQuery.with_newobject.value;
  const showHotels = jsonQuery.show_hotels && jsonQuery.show_hotels.value;

  if (type.startsWith('flat')) {
    if (showHotels) {
      return FOfferType.Hotel;
    }

    if (room && room.some(r => [ERoomType.FlatShared, ERoomType.Room, ERoomType.Bed].includes(r))) {
      let offerType = FOfferType.Unexpected;

      if (room.includes(ERoomType.FlatShared)) {
        offerType |= FOfferType.FlatShared;
      }
      if (room.includes(ERoomType.Room)) {
        offerType |= FOfferType.Room;
      }
      if (room.includes(ERoomType.Bed)) {
        offerType |= FOfferType.Bed;
      }

      return offerType;
    }

    if (buildingStatus && buildingStatus === EBuildingStatus.New) {
      return FOfferType.FlatNew;
    }

    if (fromDeveloper !== undefined) {
      return FOfferType.FlatNew;
    }

    if (withNewbuildings === true) {
      return FOfferType.FlatNew;
    }

    if (buildingStatus && buildingStatus === EBuildingStatus.Old) {
      return FOfferType.FlatOld;
    }

    if (withNewbuildings === false) {
      return FOfferType.FlatOld;
    }

    if (dealType & FDealType.Rent) {
      return FOfferType.FlatOld;
    }

    if (type === 'flatsale') {
      return FOfferType.Flat;
    }

    // istanbul ignore next
    return FOfferType.Urban;
  }

  if (type.startsWith('suburban')) {
    const objectType = jsonQuery.object_type && jsonQuery.object_type.value;

    if (objectType) {
      let offerType = FOfferType.Unexpected;

      if (objectType.includes(EObjectType.House)) {
        offerType |= FOfferType.House;
      }
      if (objectType.includes(EObjectType.Housepart)) {
        offerType |= FOfferType.HousePart;
      }
      if (objectType.includes(EObjectType.Townhouse)) {
        offerType |= FOfferType.Townhouse;
      }
      if (objectType.includes(EObjectType.Land)) {
        if ((dealType & FDealType.Rent) === 0) {
          offerType |= FOfferType.Land;
        }
      }

      return offerType;
    }

    return FOfferType.Suburban;
  }

  if (type.startsWith('commercial')) {
    const officeType = jsonQuery.office_type && jsonQuery.office_type.value;

    if (officeType) {
      let offerType = FOfferType.Unexpected;

      if (officeType.includes(EOfficeType.Garage)) {
        offerType |= FOfferType.Garage;
      }

      return offerType;
    }
  }

  // istanbul ignore next
  return FOfferType.Unexpected;
};

/**
 * Check is urban category
 * @param {FOfferType} offerType
 */
export function isUrban(offerType: FOfferType) {
  return (offerType & FOfferType.Urban) !== 0;
}

/**
 * Check is suburban category
 * @param {FOfferType} offerType
 */
export function isSuburban(offerType: FOfferType) {
  return (offerType & FOfferType.Suburban) !== 0;
}

/**
 * Check is hotel category
 * @param {FOfferType} offerType
 */
export function isHotel(offerType: FOfferType) {
  return (offerType & FOfferType.Hotel) !== 0;
}

/**
 * Check is residential category
 * @param {FOfferType} offerType
 */
export function isResidential(offerType: FOfferType) {
  return (offerType & FOfferType.Residential) !== 0;
}

/**
 * Check is flat category
 * @param {FOfferType} offerType
 */
export function isFlat(offerType: FOfferType) {
  return (offerType & FOfferType.Flat) !== 0;
}

export const isParamAvailable = (currentDealType: FDealType, currentOfferType: FOfferType) => {
  return (allowedDealTypes: FDealType, allowedOfferTypes: FOfferType, custom?: boolean) => {
    if ((currentDealType & allowedDealTypes) === 0) {
      return false;
    }
    if ((currentOfferType & allowedOfferTypes) === 0) {
      return false;
    }
    if (custom !== undefined && !custom) {
      return false;
    }

    return true;
  };
};

export function checkParamType(paramType: FOfferType | FDealType) {
  return (allowedParamType: FOfferType | FDealType) => (paramType & allowedParamType) !== 0;
}

export function isOfferTypeIncludes(currentOfferType: FOfferType, permissibleValue: FOfferType) {
  return (currentOfferType & permissibleValue) !== 0;
}
