import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingBlackFridayBanner } from './NewbuildingBlackFridayBanner';
import { trackBannerShow, trackBannerClick } from './tracking';
import { getJsonQuery } from '../../../selectors/getJsonQuery';
import {
  selectNewbuildingBlackFridayListingBannerImage,
  selectNewbuildingBlackFridayUrl,
} from '../../../selectors/newbuildingBlackFriday';

export function NewbuildingBlackFridayBannerContainer() {
  const jsonQuery = useSelector(getJsonQuery);
  const url = useSelector(selectNewbuildingBlackFridayUrl);
  const image = useSelector(selectNewbuildingBlackFridayListingBannerImage);

  const handleBannerShown = React.useCallback(() => trackBannerShow(jsonQuery), [jsonQuery]);
  const handleBannerClick = React.useCallback(() => trackBannerClick(jsonQuery), [jsonQuery]);

  return (
    <ActionAfterViewObserver callback={handleBannerShown} triggerOnce>
      <NewbuildingBlackFridayBanner url={url} image={image} onBannerClick={handleBannerClick} />
    </ActionAfterViewObserver>
  );
}
