import * as React from 'react';

import { OfferTypePopupPostfix } from './parts/OfferTypePopupPostfix';
import { checkIsHotelsSearch } from '../../../../../../shared/utils/checkIsHotelsSearch';
import { FOfferType } from '../../../../../JsonQuery';
import { OfferType } from '../../../components/filters/OfferType';
import { IFilterComponentProps } from '../../../types/filters';
import { TAvailableModifierActions } from '../../../types/modifier';
import { getOfferTypeLabel } from '../../../utils/offerType';
import { useSearchResultsTooltipOpen } from '../../../utils/searchResultsTooltipOpen';
import { useContext } from '../../../utils/useContext';
import { useDealType } from '../../../utils/useDealType';
import { useOfferType } from '../../../utils/useOfferType';

export const OfferTypeContainer: React.FC<IFilterComponentProps> = ({ filterKey, open, onOpen, onClose }) => {
  const { jsonQuery, jsonQueryCount, jsonQueryCountRefreshing, onChange, onApply, onApplyClick } = useContext();
  const dealType = useDealType();
  const offerType = useOfferType();
  const action: TAvailableModifierActions = 'setOfferType';
  const openTooltip = useSearchResultsTooltipOpen({ filterKey, actions: [action] });
  const label = getOfferTypeLabel(dealType, offerType);
  const isHotelSearch = checkIsHotelsSearch(jsonQuery);

  const handleApply = React.useCallback(() => {
    if (onApplyClick) {
      onApplyClick({ source: 'tooltip' });
    }

    onApply();

    if (onClose) {
      onClose();
    }
  }, [onApply, onApplyClick, onClose]);

  const handleChange = React.useCallback(
    (offerType: FOfferType) => {
      onChange({ action, arguments: [offerType] });
    },
    [onChange],
  );

  return (
    <OfferType
      open={open}
      dealType={dealType}
      label={label}
      value={offerType}
      hideTooltipCount={isHotelSearch}
      offersCount={jsonQueryCount}
      offersCountLoading={jsonQueryCountRefreshing}
      popupPostfix={<OfferTypePopupPostfix />}
      onChange={handleChange}
      openTooltip={openTooltip}
      onApply={handleApply}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
};
