import { ArticleHeading4, Image } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './JournalHighlightsItem.css';
import { IJournalHighlightsItem } from '../../../../types/journal';

interface IJournalHighlightsItemProps extends IJournalHighlightsItem {
  className: string;
  onClick(e: React.SyntheticEvent<HTMLAnchorElement>): void;
}

export function JournalHighlightsItem({ title, img, url, className, onClick }: IJournalHighlightsItemProps) {
  return (
    <article className={className}>
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a className={styles['link']} href={url} target="_blank" onClick={onClick}>
        <span className={styles['image']}>
          <Image src={img} borderRadius={0} />
        </span>
        <span className={styles['title-wrapper']}>
          <span className={styles['title']}>
            <ArticleHeading4 as="span">{title}</ArticleHeading4>
          </span>
        </span>
      </a>
    </article>
  );
}
