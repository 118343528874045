import classNames from 'classnames';
import * as React from 'react';

import styles from './OfferGalleryPessimizationFlexible.css';
import {
  IPhotoStubSchema,
  EBackground,
} from '../../../../../shared/repositories/search-offers.legacy/v2/types/PhotoStubSchema';

interface IOfferGalleryPessimizationFlexibleProps {
  photoStub: IPhotoStubSchema;
}

export const OfferGalleryPessimizationFlexible = ({
  photoStub: { text, background },
}: IOfferGalleryPessimizationFlexibleProps) => {
  return (
    <div className={classNames(styles['pessimization'], background === EBackground.Red && styles['strong'])}>
      {text}
    </div>
  );
};

OfferGalleryPessimizationFlexible.displayName = 'OfferGalleryPessimizationFlexible';
