import * as React from 'react';

import { EWindowsType } from '../../../../json_query';
import { IWindowsTypeTag } from '../../../state/tags/definitions/windows';
import { TagNew } from '../TagNew';

export interface IWindowsTypeTagItemProps {
  tag: IWindowsTypeTag;
  onDeleteClick(): void;
}

const windowsTypeMap = {
  [EWindowsType.Courtyard]: 'во двор',
  [EWindowsType.Street]: 'на улицу',
};

export const WindowsTypeTagItem = ({ tag, onDeleteClick }: IWindowsTypeTagItemProps) => {
  if (tag.value === EWindowsType.Any) {
    return null;
  }

  return <TagNew onDeleteClick={onDeleteClick}>Окна {windowsTypeMap[tag.value]}</TagNew>;
};
