import { connect } from 'react-redux';

import { FastLinks } from './index';
import { IAppState } from '../../../common/state';
import { selectOffersPerPage } from '../../../selectors/experiments/selectOffersPerPage';

export const mapStateToProps = (state: IAppState) => {
  const {
    results: { fastLinks, aggregatedOffers, jsonQuery },
  } = state;

  const offersPerPage = selectOffersPerPage(state);

  return {
    fastLinks,
    aggregatedOffers,
    jsonQuery,
    offersPerPage,
  };
};

export const FastLinksContainer = connect(mapStateToProps)(FastLinks);
