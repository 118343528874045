import React, { useMemo } from 'react';

import { getNewbuildingMediaLabels } from './utils';
import { GalleryLabels } from '../../../../components/GalleryLabels';
import { useOfferContext } from '../../../../context';

export function OfferLabelsContainer() {
  const { hasVideo, hasTour, mediaLabels, isNewbuilding, isNewbuildingFromBuilder } = useOfferContext();

  const displayLabels = useMemo(
    () => (isNewbuilding ? getNewbuildingMediaLabels(isNewbuildingFromBuilder) : mediaLabels),
    [isNewbuildingFromBuilder, isNewbuilding, mediaLabels],
  );

  if (!displayLabels.length) {
    return null;
  }

  return <GalleryLabels hasFeatures={hasVideo || hasTour} labels={displayLabels} />;
}
