export enum EDropdownDirection {
  Up,
  Down,
}

export const getDropdownDirection = (container: HTMLElement, popup: HTMLElement) => {
  const containerRect = container.getBoundingClientRect();

  const popupHeight = popup.clientHeight;
  const spaceAtTheTop = containerRect.top - popupHeight;
  const spaceAtTheBottom =
    document.documentElement.clientHeight - containerRect.top - containerRect.height - popupHeight;

  if (spaceAtTheBottom < 0 && spaceAtTheTop > spaceAtTheBottom) {
    return EDropdownDirection.Up;
  }

  return EDropdownDirection.Down;
};
