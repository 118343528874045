import { clone } from 'ramda';

import { setTerm, resetTerms } from './helpers';
import { TJsonQuery } from './types';

export function setApartments(jsonQuery: TJsonQuery) {
  return (apartments: boolean | null): TJsonQuery => {
    let nextJsonQuery = clone(jsonQuery);

    nextJsonQuery = resetTerms(['only_flat', 'apartment'])(nextJsonQuery);

    if (apartments) {
      nextJsonQuery = setTerm('apartment')(nextJsonQuery)(true);
    } else if (apartments !== null) {
      nextJsonQuery = setTerm('only_flat')(nextJsonQuery)(true);
    }

    return nextJsonQuery;
  };
}
