import { TTgbNativedescGeneralContext } from '@cian/adfox-templates';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TgbNativedescGeneral, TgbNativedescGeneralProps } from './TgbNativedescGeneral';
import { INewbuildingInfoForTgbSchema } from '../../../../../../repositories/newbuilding-search/entities/get_newbuildings_info_for_tgb/NewbuildingInfoForTgbSchema';
import { selectJsonQuery } from '../../../../../../selectors/results';
import { fetchGetNewbuildingsForSerpService } from '../../../../../../services/fetchGetNewbuildingsForSerpService';
import { jsonRegionIdToTransportType } from '../../../../../../utils/adfox/jsonRegionIdToTransportType';
import { useApplicationContext } from '../../../../../../utils/applicationContext';

type TgbNativedescGeneralContainerProps = {
  context: TTgbNativedescGeneralContext;
};

export const TgbNativedescGeneralContainer: FC<TgbNativedescGeneralContainerProps> = props => {
  const { context } = props;

  const {
    annotation,
    image,
    url,
    metroDistance,
    metroColor,
    metro,
    description,
    title,
    location,
    button,
    label,
    logoImageUrl,
    builderName,
    newBuildingId,
    newBuildingIdIsUsed,
  } = context;

  const applicationContext = useApplicationContext();

  const [newbuidingInfoForTgb, setNewbuidingInfoForTgb] = useState<INewbuildingInfoForTgbSchema | null>(null);

  const jsonQuery = useSelector(selectJsonQuery);

  /**
   * @description Только для значения newBuildingIdIsUsed === '1' делаем запрос на бекенд для обогащения данных
   */
  const isEnrichmentFromBackendEnabled = newBuildingIdIsUsed === '1';

  useEffect(() => {
    if (isEnrichmentFromBackendEnabled && newBuildingId && !newbuidingInfoForTgb) {
      fetchGetNewbuildingsForSerpService(applicationContext, { newbuildingIds: [parseInt(newBuildingId, 10)] })
        .then(data => {
          setNewbuidingInfoForTgb(data.newbuildings[0]);
        })
        .catch(() => {});
    }
    /**
     * @description Тут смотрим на изменение newBuildingId, изменение остальных данных не должно триггерить запрос
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBuildingId]);

  const tgbNativedescGeneralProps = ((): TgbNativedescGeneralProps | null => {
    if (isEnrichmentFromBackendEnabled) {
      if (newbuidingInfoForTgb) {
        return {
          address: newbuidingInfoForTgb.address,
          annotation,
          builderName: newbuidingInfoForTgb.publisher.name,
          button,
          description,
          image,
          label,
          logoImageUrl: newbuidingInfoForTgb.publisher.logo.url,
          title,
          transports: newbuidingInfoForTgb.transports,
          url,
        };
      }

      return null;
    }

    const transports: TgbNativedescGeneralProps['transports'] = [];

    if (metro) {
      const iconType = jsonRegionIdToTransportType(jsonQuery);

      transports.push({
        iconColor: metroColor,
        iconType,
        id: 1,
        name: metro,
        remoteness: metroDistance,
      });
    }

    return {
      logoImageUrl,
      url,
      transports,
      label,
      button,
      image,
      annotation,
      builderName,
      title,
      address: location,
      description,
    };
  })();

  if (tgbNativedescGeneralProps) {
    return <TgbNativedescGeneral {...tgbNativedescGeneralProps} />;
  }

  return null;
};
