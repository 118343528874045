import { IOfferHouse } from '../../../types/offer';

interface IGetOfferSubtitle {
  isNewbuilding: boolean;
  originalSubtitle?: string;
  house?: IOfferHouse | null;
  additionalTextBegin?: string;
  hideCompletionDate: boolean;
}

export function getOfferSubtitle({
  isNewbuilding,
  originalSubtitle,
  house,
  additionalTextBegin,
  hideCompletionDate,
}: IGetOfferSubtitle) {
  const { section, finishDate, isFinished } = house || {};

  if (!isNewbuilding || !house) {
    return originalSubtitle;
  }

  const parts = [];

  if (section) {
    parts.push(section);
  }

  if (isFinished && !hideCompletionDate) {
    parts.push('Сдан');
  } else if (!isFinished && finishDate && !hideCompletionDate) {
    parts.push(`Сдача корпуса ${finishDate.quarter} кв. ${finishDate.year}`);
  }

  if (additionalTextBegin) {
    parts.unshift(additionalTextBegin);
  }

  return parts.join(' • ');
}
