import { getConfig } from '@cian/config/browser';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';
import { registry } from '@cian/mf-registry/browser';
import { getTelemetry } from '@cian/telemetry/browser';

import { IApplicationContext, IApplicationContextCustom } from '../../../shared/types/applicationContext';

export const createContext = (): IApplicationContext => {
  const config = getConfig();

  return {
    config,
    logger: getLogger(),
    httpApi: getHttpApi(),
    telemetry: getTelemetry(),
    // getStrict
    custom: config.get<IApplicationContextCustom>('applicationContext.custom') || {},
    microfrontendsRegistry: registry(),
  };
};
