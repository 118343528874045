/** Тип предложения */
export enum FOfferType {
  /** Do not use */
  Unexpected = 0,
  /** Квартира во вторичке */
  FlatOld = 1 << 1,
  /** Квартира в новостройке */
  FlatNew = 1 << 2,
  /** Квартира */
  Flat = FlatOld | FlatNew,
  /** Доля в квартире */
  FlatShared = 1 << 3,
  /** Комната */
  Room = 1 << 4,
  /** Койко-место */
  Bed = 1 << 5,
  /** Городская недвижимость */
  Urban = Flat | FlatShared | Room | Bed,
  /** Дом */
  House = 1 << 6,
  /** Часть дома */
  HousePart = 1 << 7,
  /** Таунхаус */
  Townhouse = 1 << 8,
  /** Участок */
  Land = 1 << 9,
  /** Загородная недвижимость */
  Suburban = House | HousePart | Townhouse | Land,
  /** Гараж */
  Garage = 1 << 10,
  /** Другая недвижимость */
  Other = Garage,
  /** Отель, они хранятся в RentDaily и отображаются только с параметром show_hotels=true */
  Hotel = 1 << 23,
  /** Жилая и другая недвижимость */
  Residential = Urban | Suburban | Other | Hotel,
  /** Офис */
  Office = 1 << 11,
  /** Торговая площадь */
  TradeArea = 1 << 12,
  /** Склад */
  Warehouse = 1 << 13,
  /** ПСН */
  FreeAppointmentObject = 1 << 14,
  /** Общепит */
  PublicCatering = 1 << 15,
  /** Производство */
  Manufacture = 1 << 16,
  /** Автосервис */
  AutoService = 1 << 17,
  /** Готовый бизнес */
  Business = 1 << 18,
  /** Здание */
  Building = 1 << 19,
  /** Бытовые услуги */
  DomesticServices = 1 << 20,
  /** Коммерческая земля */
  CommercialLand = 1 << 21,
  /** Коворкинг */
  Coworking = 1 << 22,
  /** Коммерческая недвижимость */
  Commercial = Office |
    TradeArea |
    Warehouse |
    FreeAppointmentObject |
    PublicCatering |
    Manufacture |
    AutoService |
    Business |
    Building |
    DomesticServices |
    CommercialLand |
    Coworking,
  /** Любая недвижимость */
  Any = Residential | Commercial,
}
