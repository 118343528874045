import { RENTER_FORM_EXPERIMENT } from '../../constants/experiments';
import { IAbUseExperiment } from '../../types/abUseExperiments';

export function isDealRentDisablePhonesExperimentActive(experiments: IAbUseExperiment[] = []) {
  const experiment = experiments.find(exp => exp.experimentName === RENTER_FORM_EXPERIMENT);

  if (!experiment) {
    return false;
  }

  return experiment.groupName === 'B';
}
