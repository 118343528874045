import { getTermValue } from './helpers';
import { EGarageUtility, NonEmptyArray, TJsonQuery } from './types';

export function getGarageUtilities(jsonQuery: TJsonQuery): NonEmptyArray<EGarageUtility> | null {
  const hasElectricity = getTermValue('has_electricity')(jsonQuery);
  const hasHeating = getTermValue('has_heating')(jsonQuery);
  const hasWater = getTermValue('has_water')(jsonQuery);
  const hasExtinguishingSystem = getTermValue('has_extinguishing_system')(jsonQuery);

  const garageUtilities: EGarageUtility[] = [];

  if (hasElectricity) {
    garageUtilities.push(EGarageUtility.Electricity);
  }

  if (hasHeating) {
    garageUtilities.push(EGarageUtility.Heating);
  }

  if (hasWater) {
    garageUtilities.push(EGarageUtility.Water);
  }

  if (hasExtinguishingSystem) {
    garageUtilities.push(EGarageUtility.ExtinguishingSystem);
  }

  return garageUtilities.length > 0 ? (garageUtilities as NonEmptyArray<EGarageUtility>) : null;
}
