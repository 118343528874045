import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { UIHeading1, ArticleParagraph1 } from '@cian/ui-kit';
import { LinkButton } from '@cian/ui-kit/button';
import { TButtonTheme } from '@cian/ui-kit/button/types';
import * as React from 'react';

import * as styles from './SubsidisedMortgageBanner.css';

interface ISubsidisedMortgageBannerProps {
  title: string;
  subtitle: string;
  backgroundColor: string;
  backgroundImage: string;
  textColor: string;
  buttonTheme: string;
  buttonText: string;
  url: string;
  onClick(): void;
  onShow(): void;
}

export const SubsidisedMortgageBanner: React.FC<ISubsidisedMortgageBannerProps> = ({
  title,
  subtitle,
  backgroundColor,
  backgroundImage,
  textColor,
  buttonTheme,
  buttonText,
  url,
  onClick,
  onShow,
}) => {
  const style = React.useMemo(() => ({ background: backgroundColor, color: textColor }), [backgroundColor, textColor]);

  return (
    <ActionAfterViewObserver callback={onShow} percentVisible={0.8} triggerOnce>
      <div data-name="SubsidisedMortgageBanner" className={styles['container']} style={style}>
        <div className={styles['content']}>
          <UIHeading1 whiteSpace="pre-line">{title}</UIHeading1>
          <ArticleParagraph1>{subtitle}</ArticleParagraph1>
          <LinkButton href={url} size="L" theme={buttonTheme as TButtonTheme} onClick={onClick}>
            {buttonText}
          </LinkButton>
        </div>
        <img className={styles['image']} src={backgroundImage} alt="" />
      </div>
    </ActionAfterViewObserver>
  );
};
