import React, { useMemo, MouseEvent } from 'react';

import { JKName } from '../../../components/JKName';
import { useOfferContext } from '../../../context';

export function JKNameContainer() {
  const { jk, offer, tracking } = useOfferContext();

  const handleClick = useMemo(() => {
    if (!jk?.url) {
      return undefined;
    }

    return (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      tracking.trackJKLinkClicked(offer);
    };
  }, [tracking.trackJKLinkClicked, offer, jk?.url]);

  if (!jk) {
    return null;
  }

  return <JKName {...jk} onClick={handleClick} />;
}
