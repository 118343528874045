import { connect } from 'react-redux';

import { AdFoxBannerTracker } from './AdFoxBannerTracker';
import { IAppState } from '../../../../../common/state';
import { IWithTrackingProps } from '../../../../../serp/hoc/withTracking';
import { prepareTrackingData } from '../../../../../utils/prepareTrackingData';

export function mapStateToProps(state: IAppState): IWithTrackingProps {
  const { filters, results, user, breadcrumbs } = state;

  return {
    tracking: prepareTrackingData(filters, results, breadcrumbs, user),
  };
}

export const AdFoxBannerTrackerContainer = connect<IWithTrackingProps>(mapStateToProps)(AdFoxBannerTracker);
