import { selectProfessionalSearchUrl as selectProfessionalSearchUrlWithoutQuery } from '../../packages/Filters/shared/selectors';
import { IAppState } from '../common/state';

export const selectProfessionalSearchUrl = (state: IAppState) => {
  const hostPart = selectProfessionalSearchUrlWithoutQuery(state);

  const {
    results: { queryString },
  } = state;

  const encodeQueryString = queryString ? encodeURIComponent(queryString) : '';

  return queryString ? `${hostPart}?from_full_url=${encodeQueryString}` : hostPart;
};
