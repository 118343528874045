import { IAppStore } from '../../../shared/common/state';
import { mlSearchSession } from '../../../shared/mlSearchSession';
import { IApplicationContext } from '../../../shared/types/applicationContext';
import { displayConsultantPhone } from '../../../shared/utils/displayNewbuildingConsultantPhone';
import { triggerHeaderCategoryChanged } from '../../../shared/utils/events';
import { modifyPageViewAnalyticsEvent } from '../../utils/modifyPageViewAnalyticsEvent';
import { subscribeToInteractiveTime } from '../subscribeToInteractiveTime';

/**
 * Legacy
 * @deprecated
 */
export function legacyInitBeforeHydration(context: IApplicationContext, store: IAppStore) {
  const state = store.getState();

  triggerHeaderCategoryChanged(state.filters.jsonQuery);
  mlSearchSession.init(state.results.jsonQuery);
  subscribeToInteractiveTime({ store });

  /**
   * Обновляем сессию поиска пользователя, когда пользователь попал на ранжированную выдачу И
   * выдача не была закэширована ml-сервисом.
   */
  if (state.mlRankingGuid && !state.isMlRankingResponseCached) {
    mlSearchSession.updateSession(state.results.jsonQuery);
  }

  const pageViewParams = {
    page: { extra: { mlSearchSessionGuid: mlSearchSession.getSessionGuid(state.results.jsonQuery) } },
  };

  /**
   * Добавляем параметр mlSearchSessionGuid в объект события pageview при первом ренедере страницы.
   * Данный хак требуется, поскольку признак mlSearchSessionGuid существует только на стороне браузера
   * (хранится в sessionStorage).
   */
  modifyPageViewAnalyticsEvent(context, { pageViewParams });
}

/**
 * Legacy
 * @deprecated
 */
export function legacyInitAfterHydration(context: IApplicationContext, store: IAppStore) {
  const { config } = context;
  const state = store.getState();

  const consultantPhone = config.get<string>('newbuildingConsultant.phone.moscowAndRegion');
  const consultantPhoneEnabledRegions = config.get<number[]>('newbuildingConsultant.phone.enabledRegions') || [];
  displayConsultantPhone(state, consultantPhoneEnabledRegions, consultantPhone);
}
