import { isUtilitiesMustIncludedInPrice } from './price/isUtilitiesMustIncludedInPrice';
import { getIsDailyRent } from './price/rent/getIsDailyRent';
import { getRentPrice } from './price/rent/getRentPrice';
import { getRentPriceWithUtilities } from './price/rent/getRentPriceWithUtilities';
import { getSalePrice } from './price/sale/getSalePrice';
import { getRegionId } from './utils/getRegionId';
import { TComputedPropMapper } from '../../types/computed-props-mapper';

export const price: TComputedPropMapper<string> = ({ offer, jsonQuery, featureToggle: { utilitiesTermsSettings } }) => {
  const isDailyrent = getIsDailyRent(offer.category);
  // если посуточная, но объявка не партнерская и даты не выбраны
  const shouldAddPretext = isDailyrent && !(offer.isCianPartner && !!jsonQuery.dates);

  if (offer.dealType === 'rent') {
    const { bargainTerms, category, geo } = offer;
    const { utilitiesTerms, priceRur } = bargainTerms;

    const regionId = geo ? getRegionId(geo) : null;

    if (
      utilitiesTerms &&
      regionId &&
      isUtilitiesMustIncludedInPrice(
        { regionId, includedInPrice: utilitiesTerms.includedInPrice },
        utilitiesTermsSettings,
      )
    ) {
      return getRentPriceWithUtilities({
        priceRur,
        utilitiesPriceRur: utilitiesTerms.price,
        category,
        shouldAddPretext,
      });
    } else {
      return getRentPrice({ priceRur, category, shouldAddPretext });
    }
  } else {
    return getSalePrice(offer);
  }
};
