import * as React from 'react';

import { getRangeValue } from '../../../../../JsonQuery';
import { SuburbanAreaDailyrent } from '../../../components/advancedFilters/SuburbanAreaDailyrent';
import { useContext } from '../../../utils/useContext';
import { useOfferType } from '../../../utils/useOfferType';

export const SuburbanAreaDailyrentContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const offerType = useOfferType();
  const houseArea = getRangeValue('total_area')(jsonQuery);

  const handleHouseAreaMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setAreaMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleHouseAreaMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setAreaMax', arguments: [lte] });
    },
    [onChange],
  );

  return (
    <SuburbanAreaDailyrent
      offerType={offerType}
      houseArea={houseArea}
      onHouseAreaMinChange={handleHouseAreaMinChange}
      onHouseAreaMaxChange={handleHouseAreaMaxChange}
    />
  );
};
