import { INewbuildingPromoBuilderOffer } from '../../types/newbuildingPromoBuilderOffers';

interface IGetNewbuildingOffersPositionsParams {
  step: number;
  activeSlideIndex: number;
  offers: INewbuildingPromoBuilderOffer[];
}

export function getNewbuildingOffersPositions({
  step,
  activeSlideIndex,
  offers,
}: IGetNewbuildingOffersPositionsParams) {
  return offers
    .map(offer => offer.id)
    .slice(activeSlideIndex, activeSlideIndex + step)
    .map((item, index) => ({ id: item, position: index + activeSlideIndex + 1 }));
}
