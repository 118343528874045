import { ENewbuildingLayoutOffersActionTypes } from '../../actions/newbuildingLayoutOffers/types';
import { IAppState, TActions } from '../../common/state';
import { ENewbuildingLayoutFetchStatus } from '../../types/residentialComplexLayouts/layoutWithOffers/layoutWithOffers';

export function newbuildingLayoutOffersReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case ENewbuildingLayoutOffersActionTypes.StartRequest: {
      return {
        ...state,
        newbuildingLayoutOffers: {
          fetchStatus: ENewbuildingLayoutFetchStatus.Loading,
          data: null,
        },
      };
    }
    case ENewbuildingLayoutOffersActionTypes.Error: {
      return {
        ...state,
        newbuildingLayoutOffers: {
          fetchStatus: ENewbuildingLayoutFetchStatus.Error,
          data: null,
        },
      };
    }
    case ENewbuildingLayoutOffersActionTypes.Finished: {
      return {
        ...state,
        newbuildingLayoutOffers: {
          fetchStatus: ENewbuildingLayoutFetchStatus.Done,
          data: action.payload,
        },
      };
    }
  }

  return state;
}
