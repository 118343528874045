import { getGeoValueByType } from '../../../packages/JsonQuery';
import { IAppState } from '../../common/state';
import { IJsonQueryNewbuilding } from '../../repositories/common/json_query';
import { selectJsonQuery } from '../results';

export const isListingForResidentialComplexSelector = (state: IAppState) => {
  const jsonQuery = selectJsonQuery(state);
  const newobject = getGeoValueByType<IJsonQueryNewbuilding>('newobject')(jsonQuery);

  return Boolean(newobject && newobject[0].id);
};
