import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { Button } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingBrokerBanner } from '../../components/NewbuildingBrokerBanner';
import { useBrokerLinks } from '../../hooks/useBrokerLinks';
import { useNewbuildingBrokerAnalyticsUser } from '../../hooks/useNewbuildingBrokerAnalyticsUser';
import { getJsonQuery } from '../../selectors/getJsonQuery';
import { trackBannerClick, trackBannerShown } from '../../tracking/newbuilding/broker';

export const NewbuildingBrokerBannerContainer = () => {
  const { landingPageLink } = useBrokerLinks();
  const jsonQuery = useSelector(getJsonQuery);
  const user = useNewbuildingBrokerAnalyticsUser();

  const handleButtonClick = React.useCallback(() => {
    trackBannerClick(jsonQuery, user);
    window.open(landingPageLink, '_blank', 'noopener');
  }, [landingPageLink, jsonQuery, user]);

  const handleBannerShown = React.useCallback(() => {
    trackBannerShown(jsonQuery, user);
  }, [jsonQuery, user]);

  return (
    <ActionAfterViewObserver callback={handleBannerShown} triggerOnce>
      <NewbuildingBrokerBanner
        button={
          <Button theme="fill_white_primary" onClick={handleButtonClick}>
            Хочу продать
          </Button>
        }
      />
    </ActionAfterViewObserver>
  );
};
