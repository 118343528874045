import * as React from 'react';

import { EPublishPeriod } from '../../../../json_query';
import { IPublishPeriodTag } from '../../../state/tags/definitions/publish_period';
import { TagNew } from '../TagNew';

export interface IPublishPeriodTagItemProps {
  tag: IPublishPeriodTag;
  onDeleteClick(): void;
}

const periodsMap = {
  [EPublishPeriod.Today]: 'сегодня',
  [EPublishPeriod.Hour]: 'за час',
  [EPublishPeriod.Day]: 'за сутки',
  [EPublishPeriod.Week]: 'за неделю',
  [EPublishPeriod.TenDays]: 'за 10 дней',
  [EPublishPeriod.Month]: 'за месяц',
};

export const PublishPeriodTagItem = ({ tag, onDeleteClick }: IPublishPeriodTagItemProps) => {
  if (tag.value === EPublishPeriod.Any) {
    return null;
  }

  return <TagNew onDeleteClick={onDeleteClick}>Опубликовано: {periodsMap[tag.value]}</TagNew>;
};
