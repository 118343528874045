import * as React from 'react';

import { ICeilingHeightTag } from '../../../state/tags/definitions/ceiling_height';
import { TagNew } from '../TagNew';

export interface ICeilingHeightTagItemProps {
  tag: ICeilingHeightTag;
  onDeleteClick(): void;
}

export const CeilingHeightTagItem = ({ tag, onDeleteClick }: ICeilingHeightTagItemProps) => {
  const text = [];

  if (tag.min !== undefined) {
    text.push(`от ${tag.min}`);
  }

  if (tag.max !== undefined) {
    text.push(`до ${tag.max}`);
  }

  return <TagNew onDeleteClick={onDeleteClick}>Высота потолков {text.join(' ')} м</TagNew>;
};
