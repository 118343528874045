import { connect } from 'react-redux';

import { TopPopup, ITopPopupDispatchProps } from './';
import { Dispatch } from '../../../common/state';
import { toggleTopPopup } from '../../state/hide_offer';

export const TopPopupContainer = connect(
  undefined,
  (dispatch: Dispatch): ITopPopupDispatchProps => ({
    toggleTopPopup: (isOpened: boolean) => () => dispatch(toggleTopPopup(isOpened)),
  }),
)(TopPopup);
