import { TColors } from '@cian/ui-kit';

import { TLabelColor, ELabelColor } from '../../types/label';

export const labelBgColorMap: Record<ELabelColor, string> = {
  [ELabelColor.Red]: 'error_10',
  [ELabelColor.Green]: 'success_10',
  [ELabelColor.DarkGreen]: 'success_100',
  [ELabelColor.Blue]: 'primary_10',
  [ELabelColor.Gray]: 'gray10_100',
  [ELabelColor.DarkGray]: 'gray6_100',
  [ELabelColor.Yellow]: 'warning15_100',
  [ELabelColor.OrangePercent]: '#FFE1FF',
  [ELabelColor.SimpleBlue]: 'primary_10',
  [ELabelColor.SurfaceBlueIconed]: 'var(--surface-basic)',
  [ELabelColor.SurfaceBlue]: 'var(--surface-basic)',
  // TODO: Применить новые переменные после обновления на последний кит.
  [ELabelColor.BlackBlueGradient]: 'linear-gradient(90deg, black 0%, #0766fc 100%), rgba(4, 104, 255, 0.1)',
  [ELabelColor.BlueVerifiedByCian]: 'primary_100',
  [ELabelColor.Purple]: 'rgba(135, 41, 255, 0.10)',
};

export const labelTextColorMap: Record<ELabelColor, string> = {
  [ELabelColor.Red]: 'error_100',
  [ELabelColor.Green]: 'success_100',
  [ELabelColor.DarkGreen]: 'white_100',
  [ELabelColor.Blue]: 'primary_100',
  [ELabelColor.Gray]: 'black_100',
  [ELabelColor.DarkGray]: 'black_100',
  [ELabelColor.Yellow]: 'warning_100',
  [ELabelColor.OrangePercent]: 'black_100',
  [ELabelColor.SimpleBlue]: 'primary_100',
  [ELabelColor.SurfaceBlueIconed]: 'var(--accent-primary-basic)',
  [ELabelColor.SurfaceBlue]: 'var(--accent-primary-basic)',
  [ELabelColor.BlackBlueGradient]: 'white_100',
  [ELabelColor.BlueVerifiedByCian]: 'white_100',
  [ELabelColor.Purple]: '#8729FF',
};

export function mapLabelBgColor(color: TLabelColor): string {
  return labelBgColorMap[color];
}

export function mapLabelColor(color: TLabelColor) {
  return labelTextColorMap[color] as TColors;
}
