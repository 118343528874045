import * as React from 'react';

import { EMetroTravelType } from '../../../../json_query';
import { IUndergroundTimeTag } from '../../../state/tags/definitions/underground_time';
import { TagNew } from '../TagNew';

export interface IUndergroundTimeTagItemProps {
  tag: IUndergroundTimeTag;
  onDeleteClick(): void;
}

export const UndergroundTimeTagItem = ({ tag, onDeleteClick }: IUndergroundTimeTagItemProps) => {
  const { travelTime, travelType } = tag;

  const text = React.useMemo(
    () => `${travelTime} мин. ${travelType === EMetroTravelType.MapFoot ? 'пешком' : 'транспортом'}`,
    [travelTime, travelType],
  );

  return <TagNew onDeleteClick={onDeleteClick}>До метро {text}</TagNew>;
};
