import { TSquareMeterAreaType, ISquareMeterAreaSettings } from './types';

export const AREA_TYPE_SETTINGS: { [key in TSquareMeterAreaType]: ISquareMeterAreaSettings } = {
  general: {
    title: 'Общая',
  },
  room: {
    title: 'Комната',
  },
  kitchen: {
    title: 'Кухня',
  },
  living: {
    title: 'Жилая',
  },
};
