import { numberToPrettyString } from '@cian/utils';

import { IOffer } from '../../../../types/offer';
import { isSuburban } from '../../category/isSuburban';

export function getSalePricePerMeter(offer: IOffer): string | null {
  if (!offer.bargainTerms || isSuburban(offer)) {
    return null;
  }

  if (offer.category && offer.category.includes('room') && offer.roomArea) {
    const pricePerMeter = Math.floor(offer.bargainTerms.priceRur / Number(offer.roomArea));

    return `${numberToPrettyString(pricePerMeter)}\u00A0₽/м²`;
  }

  if (offer.bargainTerms.priceType !== 'all') {
    return `${numberToPrettyString(offer.bargainTerms.priceRur)}\u00A0₽`;
  } else if (offer.totalArea) {
    const totalArea = parseFloat(offer.totalArea);

    if (totalArea > 0) {
      const pricePerMeter = Math.round(offer.bargainTerms.priceRur / totalArea);
      const pricePerMeterFormatted = numberToPrettyString(pricePerMeter);

      return `${pricePerMeterFormatted}\u00A0₽/м²`;
    }
  }

  return null;
}
