import { COMMON_PROPERTIES, COMMON_PROPERTIES_SUBURBAN, RENTAL_PERIOD_SELECTED_OPTIONS } from './constants';
import { getTermValue, TJsonQueryKeys } from '../../../../JsonQuery';
import { IJsonQuery } from '../../types/jsonQuery';

function isCommonPropertiesSelected(jsonQuery: IJsonQuery) {
  const commonProperties = jsonQuery._type === 'suburbansale' ? COMMON_PROPERTIES_SUBURBAN : COMMON_PROPERTIES;

  return (Object.keys(jsonQuery) as TJsonQueryKeys[]).some(
    property => !commonProperties.includes(property) && jsonQuery[property] !== undefined,
  );
}

function isRentalPeriodSelected(jsonQuery: IJsonQuery) {
  const rentalPeriod = getTermValue('for_day')(jsonQuery);

  return rentalPeriod !== null && RENTAL_PERIOD_SELECTED_OPTIONS.includes(rentalPeriod);
}

export function isAdvancedFiltersSelected(jsonQuery: IJsonQuery): boolean {
  return isCommonPropertiesSelected(jsonQuery) || isRentalPeriodSelected(jsonQuery);
}
