import * as React from 'react';

import * as styles from './Month.css';

interface IMonthProps {
  header: React.ReactNode;
}

export const Month: React.FC<React.PropsWithChildren<IMonthProps>> = ({ children, header }) => {
  return (
    <div className={styles['month']}>
      <div className={styles['header']}>{header}</div>
      <div className={styles['dates']}>{children}</div>
    </div>
  );
};
