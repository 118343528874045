import { isAgent } from './isAgent';
import { getRentPriceInfo } from './price/rent/getRentPriceInfo';
import { getSalePriceInfo } from './price/sale/getSalePriceInfo';
import { TComputedPropMapper } from '../../types/computed-props-mapper';

export const priceInfo: TComputedPropMapper<string | undefined> = (props, ctx) => {
  const isUserAgent = isAgent(props, ctx);

  return props.offer.dealType === 'rent'
    ? getRentPriceInfo(props.offer, isUserAgent)
    : getSalePriceInfo(props.offer, isUserAgent);
};
