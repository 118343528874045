import { dealTypeFromJsonQuery, FDealType } from '../../../../utils/category';
import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IRoomsInDealTagType = 'advanced-roomsInDeal';

export interface IRoomsInDealTag {
  type: 'advanced-roomsInDeal';
  dealType: FDealType;
  value: number;
}

function isRoomsInDealTag(tag: TTag): tag is IRoomsInDealTag {
  return tag.type === IRoomsInDealTagType;
}

export const roomsInDealTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.rooms_for_sale && jsonQuery.rooms_for_sale.value.gte;

    if (value !== undefined) {
      const dealType = dealTypeFromJsonQuery(jsonQuery);

      return ensureSingleTagExists(tags, isRoomsInDealTag, () => ({
        type: IRoomsInDealTagType,
        dealType,
        value,
      }));
    } else {
      return ensureTagNotExists(tags, isRoomsInDealTag);
    }
  },
};
