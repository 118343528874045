export const BACKSPACE_CODE = 'Backspace';
export const DELETE_CODE = 'Delete';
export const ARROW_LEFT_CODE = 'ArrowLeft';
export const ARROW_RIGHT_CODE = 'ArrowRight';
export const TAB_CODE = 'Tab';
export const ESC_CODE = 'Esc';

export const NAVIGATION_KEYS_CODES = [
  BACKSPACE_CODE,
  DELETE_CODE,
  ARROW_LEFT_CODE,
  ARROW_RIGHT_CODE,
  TAB_CODE,
  ESC_CODE,
];
