import { getFirstNextUserOffer } from './getFirstNextUserOffer';
import { getOfferServices } from './getOfferServices';
import { IOffer } from '../repositories/search-offers.legacy/v2/types/offer';

export const offersDecorator = (offers: IOffer[], isAdditionalTop3Disabled: boolean) => {
  offers.forEach((offer, index) => {
    if (offer.isAuction && offer.auction && !offer.auction.isOwn) {
      const userOffer = getFirstNextUserOffer(offers, index + 1);

      if (userOffer) {
        offer.auction.userOfferId = userOffer.id;
        offer.auction.userOfferServices = getOfferServices(userOffer);
      }
    }

    if (isAdditionalTop3Disabled) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (offer as any).top3Place;
    }
  });
};
