import { isFromSuburbanBuilder } from './agent/isFromSuburbanBuilder';
import { isSuburban } from './category/isSuburban';
import { TComputedPropMapper } from '../../types/computed-props-mapper';
import { IKpInfo } from '../../types/kp';

export const kp: TComputedPropMapper<IKpInfo | undefined> = (props, ctx) => {
  const { offer } = props;
  const { name, url, villageAdvantages } = offer?.kp || {};

  if (!isSuburban(offer) || name === undefined || name === null) {
    return undefined;
  }

  return {
    name,
    url: url || undefined,
    villageAdvantages: isFromSuburbanBuilder(props, ctx) && villageAdvantages ? villageAdvantages : undefined,
  };
};
