import * as React from 'react';

import { EGarageStatus, getTermsValue, NonEmptyArray } from '../../../../../JsonQuery';
import { GarageStatus } from '../../../components/advancedFilters/GarageStatus';
import { useContext } from '../../../utils/useContext';

export const GarageStatusContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  let garageStatus = getTermsValue('garage_status')(jsonQuery);
  if (garageStatus && garageStatus.length === 0) {
    garageStatus = null;
  }

  const handleChange = React.useCallback(
    (garageStatus: NonEmptyArray<EGarageStatus> | null) => {
      onChange({ action: 'setGarageStatus', arguments: [garageStatus] });
    },
    [onChange],
  );

  return <GarageStatus value={garageStatus as NonEmptyArray<EGarageStatus> | null} onChange={handleChange} />;
};
