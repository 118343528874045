/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from 'react';

import { ISelectOption, TSelectValue } from '../types';

type HTMLSelectProps = React.SelectHTMLAttributes<HTMLSelectElement>;

type HTMLSelectPassedProps = Pick<HTMLSelectProps, Exclude<keyof HTMLSelectProps, 'value' | 'onChange'>>;

interface INativeSelectProps extends HTMLSelectPassedProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?(e: React.ChangeEvent<HTMLSelectElement>, value: TSelectValue): any;
  options: ISelectOption[];
  value: TSelectValue;
}

export class NativeSelect extends React.Component<INativeSelectProps> {
  private htmlSelectRef: React.RefObject<HTMLSelectElement> = React.createRef();

  public setOptionState = (index: number, selected: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const htmlSelect = this.htmlSelectRef.current!;

    htmlSelect.options[index].selected = selected;

    htmlSelect.dispatchEvent(new Event('change', { bubbles: true }));
  };

  public toggleOptionState = (index: number) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const htmlSelect = this.htmlSelectRef.current!;

    this.setOptionState(index, !htmlSelect.options[index].selected);
  };

  public click = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.htmlSelectRef.current!.click();
  };

  public render() {
    const { options, value, ...passedProps } = this.props;

    return (
      <select
        {...passedProps}
        ref={this.htmlSelectRef}
        onChange={this.handleChange}
        tabIndex={-1}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value={(value as any) || undefined}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    );
  }

  private handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(e, this.getValue(e.currentTarget));
    }
  };

  private getValue = (select: HTMLSelectElement) => {
    if (!this.props.multiple) {
      return this.props.options[select.selectedIndex].value;
    }

    const value = [];

    for (let i = 0; i < select.options.length; i++) {
      const isSelected = select.options[i].selected;

      if (isSelected) {
        value.push(this.props.options[i].value);
      }
    }

    return value;
  };
}
