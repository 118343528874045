import * as React from 'react';
import { useSelector } from 'react-redux';
import sanitize from 'sanitize-html';

import { SeoText } from '../../components/SeoText';
import { selectSeo } from '../../selectors/seo';

export const SeoTextContainer: React.FC = () => {
  const seo = useSelector(selectSeo);

  const sanitizedSeoText: string = React.useMemo(() => {
    if (!seo.text) {
      return '';
    }

    return sanitize(seo.text);
  }, [seo]);

  if (!seo.text) {
    return null;
  }

  return <SeoText text={sanitizedSeoText} />;
};
