import { EGalleryLabelColor } from '../../../types/gallery-label';

export function mapSavedSearchLabelColor(colorValue: string): EGalleryLabelColor {
  const color = colorValue.toLocaleLowerCase();

  switch (color) {
    case '#ff9d00':
      return EGalleryLabelColor.Orange;
    default:
      return EGalleryLabelColor.Grey;
  }
}
