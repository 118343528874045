import { ca } from '@cian/analytics';

export function trackSaveMapModalEvent(label: number) {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'popup_map_save',
    label,
  });
}
