import { getTermsValue, getTermValue } from './helpers';
import { ECompletionYearType, NonEmptyArray, TJsonQuery } from './types';

export interface IGetCompletionYearTypesParams {
  yearOffset: number;
  availableYearTypes: ECompletionYearType[];
}

export function getCompletionYearTypes(
  jsonQuery: TJsonQuery,
  { yearOffset, availableYearTypes }: IGetCompletionYearTypesParams,
): NonEmptyArray<ECompletionYearType> | null {
  const handOver = getTermValue('hand_over')(jsonQuery);
  const year = getTermsValue('year')(jsonQuery);
  const yeargte = getTermValue('yeargte')(jsonQuery);

  const completionYearTypes: ECompletionYearType[] = [];

  if (handOver) {
    completionYearTypes.push(ECompletionYearType.HandedOver);
  }

  if (year && year.length > 0) {
    const filteredYear = year
      .map(value => value - yearOffset)
      .filter(value => availableYearTypes.includes(value)) as ECompletionYearType[];

    completionYearTypes.push(...filteredYear);
  }

  if (yeargte) {
    completionYearTypes.push(ECompletionYearType.Later);
  }

  return completionYearTypes.length > 0 ? (completionYearTypes as NonEmptyArray<ECompletionYearType>) : null;
}
