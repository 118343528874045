import { getOfferCategoryTitle } from './getOfferCategoryTitle';
import { IOffer } from '../../../types/offer';
import { getOfferArea } from '../area/getOfferArea';
import { getBedroomCount } from '../bedroom/getBedroomCount';
import { getFloor } from '../floor/getFloor';
import { getFloorsForSuburban } from '../floor/getFloorsForSuburban';
import { getLandAreaTitle } from '../suburban/getLandAreaTitle';
import { getLandStatus } from '../suburban/getLandStatus';

export function getOfferName(offer: IOffer) {
  return [
    getOfferCategoryTitle,
    getOfferArea,
    getLandAreaTitle,
    getLandStatus,
    getFloor,
    getFloorsForSuburban,
    getBedroomCount,
  ]
    .map(fn => fn(offer))
    .filter(Boolean)
    .join(', ');
}
