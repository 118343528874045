import { Button } from '@cian/ui-kit';
import * as React from 'react';

interface IGoToButtonProps {
  buttonText?: string | null;
}

export function GoToButton({ buttonText }: IGoToButtonProps) {
  const buttonTitle = buttonText || 'Узнать подробнее';

  return (
    <Button theme="fill_secondary" size="XS">
      {buttonTitle}
    </Button>
  );
}
