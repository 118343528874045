import { UIText1 } from '@cian/ui-kit';
import { IconFunctionalSuccessCircle16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './styles.css';

export interface IDoneListProps {
  className?: string;
  items: string[];
}

export const DoneList: React.VFC<IDoneListProps> = ({ className, items }) => {
  return (
    <div className={className}>
      {items.map((item, i) => (
        <div key={i} className={styles['item']}>
          <IconFunctionalSuccessCircle16 display="block" color="success_100" />
          <UIText1>{item}</UIText1>
        </div>
      ))}
    </div>
  );
};
