import { connect } from 'react-redux';

import { Dispatch, IAppState } from '../../../../common/state';
import { changeSort } from '../../../../filters/state/sort';
import { ESortValue } from '../../../../repositories/common/json_query';
import { SummarySortDropdown, ISortDropdownProps } from '../../../components/Summary';

export function mapStateToProps(state: IAppState): Pick<ISortDropdownProps, 'currentLocation' | 'jsonQuery'> {
  return {
    currentLocation: state.filters.currentLocation,
    jsonQuery: state.results.jsonQuery,
  };
}

export function mapDispatchToProps(dispatch: Dispatch): Pick<ISortDropdownProps, 'onSortChanged'> {
  return {
    onSortChanged: (sort: ESortValue) => {
      dispatch(changeSort(sort));
    },
  };
}

export const SummarySortDropdownContainer = connect(mapStateToProps, mapDispatchToProps)(SummarySortDropdown);
