import { TComputedPropMapper } from '../../../types/computed-props-mapper';
import { isFlat } from '../category/isFlat';

export const getAgentExperience: TComputedPropMapper<string | undefined> = ({ offer }) => {
  const { user } = offer;

  if (!user) {
    return undefined;
  }

  if (!isFlat(offer)) {
    return undefined;
  }

  if (user.masterAgent) {
    return undefined;
  }

  if (offer.isByHomeowner) {
    return undefined;
  }

  const experience = user.experience;

  if (!experience) {
    return undefined;
  }

  const experienceNumber = Number(experience);

  switch (true) {
    case offer.newbuilding?.isFromSeller:
      return isNaN(experienceNumber) ? `На рынке ${experience}` : `Год основания: ${experience}`;

    case offer.user?.accountType === 'agency':
      return isNaN(experienceNumber) ? `На рынке ${experience}` : `На рынке с ${experience} г.`;

    case offer.user?.isAgent:
      return isNaN(experienceNumber) ? `Опыт: ${experience}` : `Опыт: с ${experience} г.`;

    default:
      break;
  }

  return undefined;
};
