import { useUpdateEffect, useValueRef } from '@cian/react-utils';
import * as React from 'react';

import { TOptionValue, IMultiSelectProps, ISelectActions } from '../types';

interface IUseMultiSelectDesktopControllerProps extends IMultiSelectProps {
  value: TOptionValue[];
  onChange(value: TOptionValue[]): void;
}

export function useMultiSelectDesktopController(props: IUseMultiSelectDesktopControllerProps) {
  const [open, setOpen] = React.useState(false);
  const [focusedIndex, setFocusedIndex] = React.useState(0);
  const stateRef = useValueRef({
    props,
    open,
    focusedIndex,
  });

  React.useEffect(() => {
    const { options, value } = stateRef.current.props;

    if (!open) {
      const firstSelectedIndex = options.findIndex(option => value.includes(option.value));

      setFocusedIndex(Math.max(firstSelectedIndex, 0));
    }
  }, [open, stateRef]);

  useUpdateEffect(() => {
    if (props.disabled && stateRef.current.open) {
      setOpen(false);
    }
  }, [props.disabled]);

  const actions: ISelectActions = React.useMemo(() => {
    const openDropdown = () => {
      if (!stateRef.current.props.disabled) {
        setOpen(true);
      }
    };
    const closeDropdown = () => setOpen(false);

    const moveFocus = (delta: -1 | 1) => {
      const current = stateRef.current.focusedIndex;
      let next = current + delta;

      if (current === -1) {
        next = 0;
      }

      if (next < 0 || next >= stateRef.current.props.options.length) {
        return;
      }

      setFocusedIndex(next);
    };

    const setFocusedOptionState = (nextState: boolean) => {
      const { props, focusedIndex } = stateRef.current;
      const option = props.options[focusedIndex];

      if (!option) {
        return;
      }

      const optionValue = option.value;
      const prevValue = props.value;
      const isChecked = prevValue.includes(optionValue);

      if (isChecked === nextState) {
        return;
      }

      if (nextState) {
        props.onChange([...prevValue, optionValue]);
      } else {
        props.onChange(prevValue.filter(v => v !== optionValue));
      }
    };

    const toggleOptionState = (index: number) => {
      const { props } = stateRef.current;
      const option = props.options[index];

      if (!option) {
        return;
      }

      const optionValue = option.value;
      const prevValue = props.value;
      const isChecked = prevValue.includes(optionValue);

      if (isChecked) {
        props.onChange(prevValue.filter(v => v !== optionValue));
      } else {
        props.onChange([...prevValue, optionValue]);
      }
    };

    const toggleFocusedOptionState = () => {
      toggleOptionState(stateRef.current.focusedIndex);
    };

    return {
      openDropdown,
      closeDropdown,
      moveFocus,
      setFocusedIndex,
      setFocusedOptionState,
      toggleFocusedOptionState,
      toggleOptionState,
    };
  }, [stateRef]);

  return { open, focusedIndex, actions };
}
