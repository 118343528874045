import { ca } from '@cian/analytics';

export const trackServicePlaceClick = (
  label: 'servisnoe_mesto_oldstyle_3_show' | 'servisnoe_mesto_oldstyle_33_show' | 'servisnoe_mesto_oldstyle_63_show',
) => {
  return ca('eventSite', {
    name: 'oldevent',
    category: 'mortgage_widget',
    action: 'click',
    label,
  });
};
