import { motivationPopupAvailableSelector } from './motivationPopup';
import { isCommonFilterSelector } from './newbuildingConsultant';
import { MOSCOW_AND_REGION } from '../../../packages/Filters/shared/constants/regions';
import { IAppState } from '../../common/state';
import { isChatOnCommonFilterAvailable } from '../../utils/experiments/isChatOnCommonFilterAvailable';
import { isInRegionSelector } from '../isInRegion';

export function chatAvailableSelector(state: IAppState): boolean {
  const { config, isNewbuildingsOnly } = state;
  const accountName = config.get<string>('webim.accountName');

  return !!accountName && (isNewbuildingsOnly || chatAvailableOnCommonFilterSelector(state));
}

export function chatAvailableOnCommonFilterSelector(state: IAppState): boolean {
  const { config, abUseExperiments } = state;
  const accountName = config.get<string>('webim.accountName');

  return (
    !!accountName &&
    isCommonFilterSelector(state) &&
    isChatOnCommonFilterAvailable(abUseExperiments) &&
    isInRegionSelector(MOSCOW_AND_REGION)(state)
  );
}

export function chatOpenSelector(state: IAppState) {
  return state.newbuildingConsultant.isOpen;
}

export function isForceNeedOpenSelector(state: IAppState) {
  return (
    state.newbuildingConsultant.needForceOpen &&
    !motivationPopupAvailableSelector(state) &&
    !chatAvailableOnCommonFilterSelector(state)
  );
}
