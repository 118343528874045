import { ca } from '@cian/analytics';

const baseEvent = {
  name: 'oldevent',
  category: 'Listing',
};

export function trackOpenDistrictsModalEvent() {
  ca('event', {
    ...baseEvent,
    action: 'popup_district_open',
  });
}
