import { Paragraph3 } from '@cian/ui-kit';
import * as React from 'react';

import { getIsUserIdentifiedByDocuments } from './utils';
import { AgentWorkHonestlyProofs } from '../../../../components/AgentWorkHonestlyProofs';
import { useOfferContext } from '../../../../context';

export const AgentWorkHonestlyProofsContainer = () => {
  const offerData = useOfferContext();
  const isUserIdentifiedByDocuments = getIsUserIdentifiedByDocuments(offerData);
  const { agentInfo } = offerData;

  if (!isUserIdentifiedByDocuments) {
    return null;
  }

  return (
    <>
      {isUserIdentifiedByDocuments && <AgentWorkHonestlyProofs message="Документы проверены" />}
      {agentInfo && <Paragraph3 color="gray60_100">{agentInfo}</Paragraph3>}
    </>
  );
};
