import { ERentTime, TJsonQueryOptionalKeys, TJsonQueryRequiredKeys } from '../../../../JsonQuery';

export const COMMON_PROPERTIES: (TJsonQueryRequiredKeys | TJsonQueryOptionalKeys)[] = [
  '_type',
  'beds_count',
  'building_status',
  'currency',
  'dates',
  'engine_version',
  'for_day',
  'geo',
  'kp_id',
  'object_type',
  'office_type',
  'price',
  'price_sm',
  'region',
  'room',
  'with_newobject',
];

export const COMMON_PROPERTIES_SUBURBAN: (TJsonQueryRequiredKeys | TJsonQueryOptionalKeys)[] = [
  '_type',
  'beds_count',
  'building_status',
  'currency',
  'dates',
  'engine_version',
  'for_day',
  'from_developer',
  'geo',
  'kp_id',
  'object_type',
  'price',
  'price_sm',
  'region',
  'room',
  'site',
  'total_area',
  'village_mortgage_allowed',
  'with_newobject',
];

export const RENTAL_PERIOD_SELECTED_OPTIONS = [ERentTime.FromYear, ERentTime.FewMonths];
