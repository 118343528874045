import { getDailyHouseTitle } from './getDailyHouseTitle';
import { getFlatShareTitle } from './getFlatShareTitle';
import { getFlatTitle } from './getFlatTitle';
import { getGarageTitle } from './getGarageTitle';
import { getHouseShareTitle } from './getHouseShareTitle';
import { getRoomTitle } from './getRoomTitle';
import { IOffer } from '../../../types/offer';
import { getShortCategory } from '../category/getShortCategory';

export function getOfferCategoryTitle(offer: IOffer): string | undefined {
  switch (getShortCategory(offer.category || '')) {
    case 'dailyRoom':
      return 'Комната';
    case 'bed':
    case 'dailyBed':
      return 'Койка-место';
    case 'shoppingArea':
      return 'Торговая площадь';
    case 'warehouse':
      return 'Склад';
    case 'freeAppointmentObject':
      return 'Своб. назнач.';
    case 'industry':
      return 'Помещение под производство';
    case 'house':
      return 'Дом';
    case 'cottage':
      return 'Коттедж';
    case 'townhouse':
      return 'Таунхаус';
    case 'land':
      return 'Участок';
    case 'commercialLand':
      return 'Участок';
    case 'flat':
    case 'newBuildingFlat':
    case 'dailyFlat':
      return getFlatTitle(offer);
    case 'room':
      return getRoomTitle(offer);
    case 'garage':
      return getGarageTitle(offer);
    case 'flatShare':
      return getFlatShareTitle(offer);
    case 'houseShare':
      return getHouseShareTitle(offer);
    case 'dailyHouse':
      return getDailyHouseTitle(offer);
    default:
      return undefined;
  }
}
