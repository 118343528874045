import { IAppState } from '../common/state';

export function selectLocationId(state: IAppState): number {
  let locationId;
  const location = state.filters.currentLocation;

  if (location === 'moscow_mo') {
    locationId = 1;
  } else if (location === 'spb_lo') {
    locationId = 2;
  } else {
    locationId = location.id;
  }

  return locationId;
}
