import { Microfrontend } from '@cian/mf-react';
import * as React from 'react';

import { ComplainsModalLoader } from '../ComplainsModalLoader';
import { ModalComplainsLoadError } from '../ModalComplainsLoadError';
import { useModerationComplaint } from '../hook';

type ComplaintModalContentProps = {
  onCloseModal(): void;
};

export const ComplaintModalContent: React.FC<ComplaintModalContentProps> = ({ onCloseModal }) => {
  const { isLoading, refetch, manifest, offerId, isError } = useModerationComplaint({ onCloseModal });

  if (isError) {
    return <ModalComplainsLoadError isLoading={isLoading} refetch={refetch} />;
  }

  if (!manifest) {
    return <ComplainsModalLoader />;
  }

  return (
    <Microfrontend
      image={manifest.imageVersion}
      name={manifest.microfrontendName}
      parameters={{ offerId }}
      runtime={manifest.runtimeName}
    />
  );
};
