import classNames from 'classnames';
import React, { memo } from 'react';

import * as styles from './Card.css';
import * as hoverStyles from './CardHover.css';
import { LinkArea } from '../LinkArea';

export interface ICardProps {
  agent: React.ReactNode;
  colored: boolean;
  comment: React.ReactNode;
  content: React.ReactNode;
  controls: React.ReactNode;
  gallery: React.ReactNode;
  href: string;
  isWide: boolean;
  pessimization: React.ReactNode;
  thumbnails: React.ReactNode;
  showVas?: boolean;
  vas: React.ReactNode;

  onClick(e: React.MouseEvent<HTMLElement>): void;
}

export function CardComponent({
  agent,
  colored,
  comment,
  content,
  controls,
  gallery,
  href,
  isWide,
  pessimization,
  thumbnails,
  showVas = true,
  vas,
  onClick,
}: ICardProps) {
  return (
    <article className={classNames(styles['container'], hoverStyles['cont'], colored && styles['colored'])}>
      <div
        data-testid="offer-card"
        className={classNames(styles['card'], hoverStyles['card'], isWide && styles['wide'])}
      >
        <a className={styles['media']} href={href} onClick={onClick} target="_blank" rel="noreferrer">
          {gallery}
          {thumbnails}
        </a>
        <div className={styles['content']}>
          <div className={styles['general']}>
            <LinkArea className={styles['link']} href={href} onClick={onClick} target="_blank">
              {content}
            </LinkArea>
          </div>
          <div className={styles['aside']}>
            <LinkArea className={styles['agent-cont']} href={href} onClick={onClick} target="_blank">
              <div className={styles['agent']}>{agent}</div>
              <div className={styles['buttons']} onClick={/* istanbul ignore next */ e => e.stopPropagation()}>
                {controls}
              </div>
            </LinkArea>
            {showVas && <div className={styles['vas']}>{vas}</div>}
          </div>
        </div>
      </div>
      <div className={styles['comments']}>
        {pessimization}
        {comment}
      </div>
    </article>
  );
}

export const Card = memo(CardComponent);
