export type TAuctionBetValidatorResult = IAuctionBetValidatorResult | null | undefined;

export interface IAuctionBetValidatorResult {
  type: string;
  message: string;
}

export interface IAuctionBetValidatorOptions {
  /** Считать ли нуль невалидным случаем */
  isZeroValid: boolean;
  /** Использовать ли кратность при проверке валидности */
  validateStep?: boolean;
}

/**
 * Валидация ставки аукциона.
 *
 * @returns {IAuctionBetValidatorResult} тип ошибки
 * @returns {null} неизвестная ошибка
 * @returns {undefined} ошибки нет
 */
export const auctionBetValidator = (
  bet: number,
  min: number,
  max: number,
  betStep: number,
  options: IAuctionBetValidatorOptions = { isZeroValid: true },
): TAuctionBetValidatorResult => {
  if (options.isZeroValid && bet === 0) {
    return undefined;
  }

  switch (true) {
    case bet < min:
      return {
        type: 'min',
        message: `Минимальная ставка — ${min}\u00A0₽`,
      };

    case bet > max:
      return {
        type: 'max',
        message: `Максимальная ставка — ${max}\u00A0₽`,
      };

    case options && options.validateStep && bet % betStep !== 0:
      return {
        type: 'multiple',
        message: `Ставка должна быть кратна ${betStep} рублям.`,
      };

    default:
      return null;
  }
};
