import { IJsonQueryPolygon, TCoordinate } from '../../../../../json_query';
import { coordsIsEqual } from '../../../../../utils/geo';
import { ensureSingleTagExists } from '../../helpers';
import { ITagDefinition, TTag } from '../../index';

const IGeoPolygonTagType = 'geo-polygon';

export interface IGeoPolygonTag {
  type: 'geo-polygon';
  value: TCoordinate[];
  text: string;
}

export const isGeoPolygonTag = (tag: TTag): tag is IGeoPolygonTag => tag.type === IGeoPolygonTagType;

export const geoPolygonTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.geo && (jsonQuery.geo.value.filter(geo => geo.type === 'polygon') as IJsonQueryPolygon[]);

    let nextTags = tags.filter(
      tag =>
        tag.type !== IGeoPolygonTagType ||
        (values && values.some(value => coordsIsEqual(value.coordinates, tag.value))),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IGeoPolygonTag => isGeoPolygonTag(tag) && coordsIsEqual(value.coordinates, tag.value),
            () => ({ type: IGeoPolygonTagType, value: value.coordinates, text: value.name }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
