import { useForkRef } from '@cian/react-utils';
import { InputAdornment } from '@cian/ui-kit';
import { HiddenInput } from '@cian/ui-kit/internal/components/HiddenInput';
import { InputWrapper } from '@cian/ui-kit/internal/components/InputWrapper';
import { IconActionChevronDownSmall16, IconActionChevronUpSmall16 } from '@cian/ui-kit-design-tokens/icons';
import cx from 'classnames';
import * as React from 'react';

import * as styles from './styles.css';
import { ISelectProps, TOptionValue } from '../../../types';

interface ISelectInputProps extends ISelectProps<TOptionValue | TOptionValue[]> {
  open: boolean;
}

export const SelectInput = React.forwardRef<HTMLDivElement, ISelectInputProps>(function SelectInput(props, ref) {
  const {
    value,
    options,
    open,
    placeholder,
    autoFocus,
    size,
    width,
    minWidth,
    maxWidth,
    name,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange,
    disabled,
    placeholderActive,
    ...divProps
  } = props;
  const [displayNode, setDisplayNode] = React.useState<HTMLDivElement | null>(null);
  const inputRef = useForkRef(setDisplayNode as React.RefCallback<HTMLDivElement>, ref);
  const label = React.useMemo(() => {
    if (!hasValue(value)) {
      return null;
    }

    const valueArray = Array.isArray(value) ? value : [value];

    return options
      .reduce((result, option) => {
        if (valueArray.includes(option.value)) {
          result.push(option.label || option.value);
        }

        return result;
      }, [] as React.ReactText[])
      .join(', ');
  }, [options, value]);

  React.useEffect(() => {
    if (displayNode && autoFocus && !disabled) {
      displayNode.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayNode, autoFocus]);

  return (
    <InputWrapper
      {...divProps}
      ref={inputRef}
      size={size}
      width={width}
      minWidth={minWidth}
      maxWidth={maxWidth}
      cursor="pointer"
      tabIndex={divProps.tabIndex || 0}
      disabled={disabled}
      rightAdornment={
        <InputAdornment>
          <div className={cx(styles['arrow'], disabled && styles['disabled'])}>
            {open ? (
              <IconActionChevronUpSmall16 color="current_color" />
            ) : (
              <IconActionChevronDownSmall16 color="current_color" />
            )}
          </div>
        </InputAdornment>
      }
    >
      <HiddenInput defaultValue={valueToHtml(value)} name={name} disabled={disabled} tabIndex={-1} />
      {label ? (
        <div className={styles['label-wrapper']}>
          <div className={styles['label']}>{label}</div>
          {Array.isArray(value) && !!value.length && <span className={styles['count']}>{value.length}</span>}
        </div>
      ) : (
        <div className={cx(styles['placeholder'], placeholderActive && styles['placeholder-active'])}>
          {placeholder}
        </div>
      )}
    </InputWrapper>
  );
});

function hasValue(value: TOptionValue | TOptionValue[] | null | undefined) {
  if (Array.isArray(value)) {
    return !!value.length;
  }

  return value !== null && value !== undefined;
}

function valueToHtml(value: TOptionValue | TOptionValue[] | null | undefined): string | undefined {
  if (Array.isArray(value)) {
    return value.join(',');
  }

  if (value !== null && value !== undefined) {
    return String(value);
  }

  return;
}
