import * as React from 'react';

import { getSuburbanFacilitiesList } from './helpers';
import { EFacilityType, FDealType, NonEmptyArray } from '../../../../../JsonQuery';
import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface ISuburbanFacilitiesProps {
  dealType: FDealType;
  value: NonEmptyArray<EFacilityType> | null;
  onChange(value: NonEmptyArray<EFacilityType> | null): void;
}

export const SuburbanFacilities: React.FC<ISuburbanFacilitiesProps> = ({ dealType, value, onChange }) => {
  const suburbanFacilities = getSuburbanFacilitiesList(dealType);

  return (
    <Filter id="advancedFilter_suburbanFacilities" label="Дополнительно">
      <CheckboxButtonGroupUnselectable options={suburbanFacilities} value={value} onChange={onChange} />
    </Filter>
  );
};
