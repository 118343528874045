import { selectOffersPerPage } from './experiments/selectOffersPerPage';
import { IAppState } from '../common/state';

export function selectServicesBannerPositions(state: IAppState): number[] {
  const perPage = selectOffersPerPage(state);
  /**
   * В рамках экспа делаем консистентную логику показа сервисных баннеров
   * Сервисные баннеры показываются на страницах 1-3 после 3-го объявления
   */
  if (perPage === state.featureToggle.offersPerPageSettings.extendedOffersPerPage) {
    return Array(3)
      .fill(1)
      .map((_, index) => index * perPage + 2);
  }

  /**
   * по умолчанию оставляем старые "нелогичные" позиции, чтобы ничего не сломать
   */
  return [2, 32, 62];
}
