/* eslint-disable @typescript-eslint/no-unused-vars */
import { useForkRef, useIsomorphicLayoutEffect, useUpdateEffect } from '@cian/react-utils';
import { DropdownPopover } from '@cian/ui-kit';
import * as React from 'react';

import { MultiSelectDesktopDropdown } from './MultiSelectDesktopDropdown';
import { useControlledValue, SelectInput } from './internal';
import { useDeferValue } from './internal/hooks/useDeferValue/useDeferValue';
import { processMultiSelectKeyboardEvent } from './processMultiSelectKeyboardEvent';
import { IMultiSelectProps } from './types';
import { useMultiSelectDesktopController } from './useMultiSelectDesktopController';

/**
 * select-компонент для выбора нескольких значений из списка
 *
 * Только десктопный вариант, открывающий выпадающий список
 */
export const MultiSelectDesktop = React.forwardRef<HTMLDivElement, IMultiSelectProps>(
  function MultiSelectDesktop(props, forwardedRef) {
    const { onClick, ...selectInputProps } = props;
    const inputRef = React.useRef<HTMLDivElement>(null);
    const ref = useForkRef(forwardedRef, inputRef);

    const { value: controlledValue, onChange: controlledOnChange } = useControlledValue(props, []);
    const { value, onChange, onComplete } = useDeferValue({
      value: controlledValue,
      onChange: controlledOnChange,
      defer: props.defer,
    });

    const { open, focusedIndex, actions } = useMultiSelectDesktopController({
      ...props,
      value: value || [],
      onChange,
    });

    useUpdateEffect(
      () => {
        const inputNode = inputRef.current;

        if (!open && inputNode) {
          inputNode.focus();
        }
      },
      [open],
      useIsomorphicLayoutEffect,
    );

    const handleInputClick = React.useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        if (onClick) {
          onClick(e);
        }

        if (!e.defaultPrevented) {
          actions.openDropdown();
        }
      },
      [actions, onClick],
    );
    const handleKeyDown = React.useCallback(
      (e: React.KeyboardEvent) => {
        processMultiSelectKeyboardEvent(e, { open, actions });
      },
      [open, actions],
    );

    return (
      <DropdownPopover
        open={open}
        content={
          <MultiSelectDesktopDropdown
            {...props}
            value={value}
            open={open}
            onKeyDown={handleKeyDown}
            focusedIndex={focusedIndex}
            onComplete={onComplete}
            actions={actions}
          />
        }
      >
        <SelectInput
          {...selectInputProps}
          ref={ref}
          open={open}
          onClick={handleInputClick}
          onKeyDown={handleKeyDown}
          role="button"
          aria-haspopup="listbox"
          value={value}
        />
      </DropdownPopover>
    );
  },
);
