import { fetchSimilarNewobjects } from './fetchSimilarNewobjects';
import { IJsonQueryNewbuilding } from '../../repositories/common/json_query';
import { IApplicationContext } from '../../types/applicationContext';
import { IOffersData } from '../../types/offersData';
import { ISimilarNewobject } from '../../types/similarNewobjects';

export async function fetchSimilarNewobjectsIfNeeded(
  context: IApplicationContext,
  offersData: IOffersData,
): Promise<ISimilarNewobject[] | null> {
  const newbuildingTags =
    offersData.jsonQuery.geo &&
    (offersData.jsonQuery.geo.value.filter(geo => geo.type === 'newobject') as IJsonQueryNewbuilding[]);

  if (offersData.offerCount > 0 && offersData.offerCount < 11 && newbuildingTags && newbuildingTags.length > 0) {
    return fetchSimilarNewobjects(context, { newobjectId: newbuildingTags[0].id });
  }

  return null;
}
