import { getRoomArea } from './getRoomArea';
import { getTotalArea } from './getTotalArea';
import { IOffer } from '../../../types/offer';
import { isFlat } from '../category/isFlat';

export function getOfferArea(offer: IOffer): string | undefined {
  const roomArea = getRoomArea(offer);
  const totalArea = getTotalArea(offer);
  const isFlatCategory = isFlat(offer);

  if (isFlatCategory && roomArea) {
    return `${roomArea} м²`;
  }

  if (totalArea) {
    return `${totalArea} м²`;
  }

  return undefined;
}
