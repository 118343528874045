import { EOfferType } from '../../../../packages/api-models/hidden-objects/v1/types';
import { ECategory } from '../../../../shared/repositories/offers-comparison/v2/add-offer-to-comparison';
import { ELabelColor } from '../types/label';
import { IOffer } from '../types/offer';

export const prepareNotApprovedLayoutLabel = (offer: IOffer) => {
  // в контексте текущей фичи null/undefined === true
  if (
    offer.isLayoutApproved !== false ||
    offer.category !== ECategory.FlatShareSale ||
    offer.offerType !== EOfferType.Flat
  ) {
    return;
  }

  return {
    color: ELabelColor.Yellow,
    text: 'Перепланировка',
  };
};
