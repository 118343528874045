import { Button, ModalWindow, Spinner } from '@cian/ui-kit';
import * as React from 'react';

import { Areas } from './areas';
import { Breadcrumbs } from './breadcrumb';
import { Capitals } from './capitals/';
import { trackApplySelectedLocation } from './tracking';
import { ILocation, TLocation } from '../../../types/location';
import { SearchButtonContainer } from '../search_button/container';

const style = require('./index.css');

export interface ILocationSwitherProps {
  autocomplete: React.ReactNode;
  cities: ILocation[] | undefined;
  isDataLoading: boolean;
  regions: ILocation[];
  selectedCity: ILocation | undefined;
  selectedRegion: TLocation | undefined;
  queryString: string | undefined;

  applySelectedLocation(): void;
  onCitySelected(city: ILocation): void;
  onClose(): void;
  onCountrySelected(): void;
  onRegionSelected(region: TLocation): void;
}

export class LocationSwither extends React.Component<ILocationSwitherProps> {
  public render() {
    const { selectedRegion, selectedCity, isDataLoading } = this.props;

    return (
      <ModalWindow
        escape
        fixed
        size="M"
        width="100%"
        maxWidth={1300}
        open
        onClose={this.props.onClose}
        header={
          <>
            {this.props.autocomplete}
            {this.renderBreadcrumbs()}
          </>
        }
        footer={
          <div className={style['footer']}>
            {selectedCity || selectedRegion ? (
              <SearchButtonContainer onClick={this.handleClickOnSearchButton} />
            ) : (
              this.renderCancelButton()
            )}
          </div>
        }
      >
        <div className={style['location-switcher']}>
          {isDataLoading && (
            <div className={style['preloader-wrapper']}>
              <Spinner size={50} />
            </div>
          )}
          <Capitals
            selectedCity={selectedCity}
            selectedRegion={selectedRegion}
            regions={this.props.regions}
            onRegionSelected={this.props.onRegionSelected}
          />
          {selectedCity ? this.renderCities() : selectedRegion ? this.renderCities() : this.renderRegions()}
        </div>
      </ModalWindow>
    );
  }

  private renderBreadcrumbs() {
    const { selectedRegion, selectedCity } = this.props;

    return (
      <Breadcrumbs
        selectedCity={selectedCity}
        selectedRegion={selectedRegion}
        regions={this.props.regions}
        onCitySelected={this.props.onCitySelected}
        onCountrySelected={this.props.onCountrySelected}
        onRegionSelected={this.props.onRegionSelected}
      />
    );
  }

  private renderRegions() {
    return (
      <Areas
        areas={this.props.regions}
        onSelected={this.props.onRegionSelected}
        onRegionSelected={this.props.onRegionSelected}
      />
    );
  }

  private renderCities() {
    return (
      <Areas
        areas={this.props.cities}
        onSelected={this.props.onCitySelected}
        selectedCity={this.props.selectedCity}
        selectedRegion={this.props.selectedRegion}
        onRegionSelected={this.props.onRegionSelected}
      />
    );
  }

  private renderCancelButton() {
    return (
      <Button theme="fill_primary" size="XS" disabled={true} onClick={this.props.onClose}>
        Выберите регион
      </Button>
    );
  }

  private handleClickOnSearchButton = () => {
    trackApplySelectedLocation(this.props.queryString);
    this.props.applySelectedLocation();
  };
}
