import { IAppState, TActions } from '../../../common/state';
import { ELandStatusType, jq } from '../../../json_query';

export const ILandStatusChangedActionType = 'filters/land_status/LAND_STATUS_CHANGED';

export interface ILandStatusChangedAction {
  type: 'filters/land_status/LAND_STATUS_CHANGED';
  landStatusType: ELandStatusType;
  value: boolean;
}

export function changeLandStatus(landStatusType: ELandStatusType, value: boolean): ILandStatusChangedAction {
  return {
    type: ILandStatusChangedActionType,
    landStatusType,
    value,
  };
}

export function landStatusReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case ILandStatusChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: action.value
            ? jq(state.filters.jsonQuery).setLandStatusType(action.landStatusType)
            : jq(state.filters.jsonQuery).unsetLandStatusType(action.landStatusType),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-landStatus') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).unsetLandStatusType(action.tag.value),
          },
        };
      }

      return state;

    default:
      return state;
  }
}
