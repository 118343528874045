import { IAppState, TActions } from '../../../common/state';

const cloneState = (state: IAppState): IAppState => {
  return {
    ...state,
    filters: {
      ...state.filters,
      jsonQuery: {
        ...state.filters.jsonQuery,
      },
    },
  };
};

export function wcReducer(state: IAppState, action: TActions): IAppState {
  const newState = cloneState(state);
  const jq = newState.filters.jsonQuery;

  switch (action.type) {
    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-wc-wcType') {
        jq.wc_type = undefined;
      } else if (action.tag.type === 'advanced-wc-wcCount') {
        jq.wc = undefined;
      }

      return newState;

    default:
      return state;
  }
}
