import { FDealType, FOfferType, isAvailable } from '../../../../JsonQuery';
import { SubsidisedMortgageContainer } from '../../containers/advancedFilters/SubsidisedMortgageContainer';
import { IFilter } from '../../types/advancedFilters';

export const subsidisedMortgage: IFilter = {
  component: SubsidisedMortgageContainer,
  availability: ({ config, jsonQuery }) =>
    Boolean(config.get<boolean>('frontend-serp.subsidizedMortgage.Enabled')) &&
    isAvailable(FDealType.Sale, FOfferType.FlatOld)(jsonQuery),
};
