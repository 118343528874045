import { IAppState, TActions } from '../../../common/state';
import { IJsonQuery } from '../../../json_query';
import { setTerm } from '../../../json_query/reducers/helpers';

export interface IAdvancedTermChanged {
  type: 'filters/advanced/TERM_CHANGED';
  key: keyof IJsonQuery;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  termValue: any;
}

export function setTermValue<T>(key: keyof IJsonQuery, value: T | undefined): IAdvancedTermChanged {
  return {
    type: 'filters/advanced/TERM_CHANGED',
    key,
    termValue: value,
  };
}

export function advancedTermReducer(state: IAppState, action: TActions): IAppState {
  let jsonQuery = Object.assign({}, state.filters.jsonQuery);

  switch (action.type) {
    case 'filters/advanced/TERM_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: setTerm(action.key)(jsonQuery)(action.termValue),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      switch (action.tag.type) {
        case 'advanced-demolished-in-moscow-programm':
          jsonQuery = setTerm('demolished_in_moscow_programm')(jsonQuery)(undefined);
          break;
        case 'advanced-has-electricity':
          jsonQuery = setTerm('has_electricity')(jsonQuery)(undefined);
          break;
        case 'advanced-has-extinguishing-system':
          jsonQuery = setTerm('has_extinguishing_system')(jsonQuery)(undefined);
          break;
        case 'advanced-has-heating':
          jsonQuery = setTerm('has_heating')(jsonQuery)(undefined);
          break;
        case 'advanced-has-light':
          jsonQuery = setTerm('has_light')(jsonQuery)(undefined);
          break;
        case 'advanced-has-water':
          jsonQuery = setTerm('has_water')(jsonQuery)(undefined);
          break;
        case 'advanced-has-video':
          jsonQuery = setTerm('has_video')(jsonQuery)(undefined);
          break;
        case 'advanced-by-homeowner':
          jsonQuery = setTerm('is_by_homeowner')(jsonQuery)(undefined);
          break;
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery,
        },
      };

    default:
      return state;
  }
}
