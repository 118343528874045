import { IAppState } from '../common/state';
import { getRegionId } from '../utils/geo';

export function isInRegionSelector(regions: number[]) {
  return (state: IAppState) => {
    const regionId = getRegionId(state.filters.currentLocation);

    return regions.includes(regionId);
  };
}
