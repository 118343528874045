import { getLocationIdForMetro } from '../../../../../shared/utils/geo';
import { UndergroundTimeContainer } from '../../containers/advancedFilters/UndergroundTimeContainer';
import { IFilter } from '../../types/advancedFilters';
import { REGIONS_WITH_UNDERGROUND } from '../regions';

export const undergroundTime: IFilter = {
  availability: ({ currentLocation }) => {
    const locationId = getLocationIdForMetro(currentLocation);

    return REGIONS_WITH_UNDERGROUND.includes(locationId);
  },
  component: UndergroundTimeContainer,
};
