import * as React from 'react';

interface IUseDeferValueProps<T> {
  value?: T;
  onChange?(value: T): void;
  defer?: boolean;
}

export function useDeferValue<T>(props: IUseDeferValueProps<T>) {
  const { onChange } = props;
  const { current: defer } = React.useRef(props.defer);
  const [valueState, setValue] = React.useState(props.value);
  const value = defer ? valueState : props.value;

  const handleChange = React.useCallback(
    (newValue: T) => {
      if (defer) {
        setValue(newValue);
      } else {
        if (onChange) {
          onChange(newValue);
        }
      }
    },
    [defer, onChange],
  );

  const onComplete = () => {
    if (onChange) {
      onChange(value as T);
    }
  };

  return { value, onChange: handleChange, onComplete };
}
