import * as React from 'react';

import { FOfferType } from '../../../../../JsonQuery';
import { IJsonQueryRangeValue } from '../../../types/jsonQuery';
import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';
import { RangeInput } from '../common/RangeInput';

interface ISuburbanAreaProps {
  offerType: FOfferType;
  houseArea: IJsonQueryRangeValue | null;

  onHouseAreaMinChange(value: number | null): void;

  onHouseAreaMaxChange(value: number | null): void;
}

export const SuburbanAreaDailyrent: React.FC<ISuburbanAreaProps> = ({
  offerType,
  houseArea,
  onHouseAreaMinChange,
  onHouseAreaMaxChange,
}) => {
  const { gte: houseAreaMin = null, lte: houseAreaMax = null } = houseArea || {};

  const isHouseAreaAvailable = (offerType & ~FOfferType.Land) !== 0;

  const handleHouseAreaMinChange = useDebouncedCallback(
    (gte: number | null) => {
      onHouseAreaMinChange(gte);
    },
    300,
    [onHouseAreaMinChange],
  );

  const handleHouseAreaMaxChange = useDebouncedCallback(
    (lte: number | null) => {
      onHouseAreaMaxChange(lte);
    },
    300,
    [onHouseAreaMaxChange],
  );

  return (
    <Filter id="advancedFilter_suburbanArea" label="Площадь дома">
      <InlineFilterGroup>
        {isHouseAreaAvailable && (
          <InlineFilter postfix={'м\u00b2'}>
            <RangeInput
              min={1}
              max={99999}
              valueMin={houseAreaMin}
              valueMax={houseAreaMax}
              onChangeMin={handleHouseAreaMinChange}
              onChangeMax={handleHouseAreaMaxChange}
            />
          </InlineFilter>
        )}
      </InlineFilterGroup>
    </Filter>
  );
};
