import cx from 'classnames';
import * as React from 'react';

import * as styles from './styles.css';

interface INewbuildingPromoBuilderOffersLayoutProps {
  carousel: React.ReactNode;
  builder: React.ReactNode;
  offersFewed: boolean;
}

export function NewbuildingPromoBuilderOffersLayout({
  carousel,
  builder,
  offersFewed,
}: INewbuildingPromoBuilderOffersLayoutProps) {
  return (
    <div
      className={cx(styles['container'], offersFewed && `carousel-offers-fewed`)}
      data-testid="NewbuildingPromoBuilderOffersLayout"
    >
      <div className={styles['builder']}>{builder}</div>
      <div className={styles['carousel']}>{carousel}</div>
    </div>
  );
}
