import React from 'react';

import { Title } from '../../../components/Title';
import { useOfferContext } from '../../../context';
import { ENewbuildingSnippetType } from '../../../types/offer';
import { getOfferName } from '../../../utils/computedProps/title/getOfferName';
import { getOfferSubtitle } from '../../../utils/computedProps/title/getOfferSubtitle';

export function TitleContainer() {
  const { title, originalTitle, subtitle: offerSubtitle, isTop, isNewbuilding, offer } = useOfferContext();

  const { fullUrl, isCianPartner, externalOfferUrl, newbuilding, dailyrent } = offer;

  const subtitle = dailyrent?.hotelData?.isHotel
    ? null
    : getOfferSubtitle({
        isNewbuilding,
        originalSubtitle: offerSubtitle,
        house: newbuilding?.house,
        hideCompletionDate:
          Boolean(newbuilding?.snippetType === ENewbuildingSnippetType.Maximum) && Boolean(originalTitle),
        ...(newbuilding?.snippetType === ENewbuildingSnippetType.Maximum &&
          originalTitle && { additionalTextBegin: getOfferName(offer) }),
      });

  const starRating = dailyrent?.hotelData?.starRating;

  const href = isCianPartner ? externalOfferUrl : fullUrl;

  return <Title isTop={isTop} title={title} subtitle={subtitle} starRating={starRating} href={href} />;
}
