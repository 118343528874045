import * as React from 'react';

import { RangeInput } from '../../../../../../packages/Filters/shared/components/RangeInput';
import { useDebouncedCallback } from '../../../../../../packages/Filters/shared/utils/useDebouncedCallback';
import { IJsonQueryRangeValue } from '../../../../../json_query';

const styles = require('./index.css');

export interface IDirectionsModalHeaderProps {
  fromMKAD: IJsonQueryRangeValue;
  label: string;
  onChangeMin(value: number | undefined): void;
  onChangeMax(value: number | undefined): void;
}

export const DirectionsModalHeader: React.FC<IDirectionsModalHeaderProps> = ({
  fromMKAD,
  label,
  onChangeMin,
  onChangeMax,
}) => {
  const handleChangeMin = useDebouncedCallback(
    (gte: number | null) => {
      onChangeMin(gte !== null ? gte : undefined);
    },
    300,
    [onChangeMin],
  );

  const handleChangeMax = useDebouncedCallback(
    (lte: number | null) => {
      onChangeMax(lte !== null ? lte : undefined);
    },
    300,
    [onChangeMax],
  );

  return (
    <>
      <span className={styles['before']}>{label}</span>
      <div className={styles['inputs']}>
        <RangeInput
          min={1}
          max={9999}
          valueMin={fromMKAD.gte}
          valueMax={fromMKAD.lte}
          onChangeMin={handleChangeMin}
          onChangeMax={handleChangeMax}
          postfix="км"
        />
      </div>
    </>
  );
};
