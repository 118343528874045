import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TERMS_TO_RESET, EXTENDED_TERMS_TO_RESET } from './constants';
import { Tags } from './index';
import { useContext } from '../../../../packages/Filters';
import { TJsonQueryOptionalKeysToReset, isSuburban, offerTypeFromJsonQuery } from '../../../../packages/JsonQuery';
import { IAppState } from '../../../common/state';
import { EMetroTravelType } from '../../../json_query';
import { tagsSelector } from '../../../selectors/filters/tagsSelector';
import { TTag } from '../../state/tags';
import { IKpIdTag } from '../../state/tags/definitions/kp_id';

export const TagsContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { jsonQuery, onChange } = useContext();
  const offerType = offerTypeFromJsonQuery(jsonQuery);
  const isFitInOneLineEnabled = useSelector((state: IAppState) => state.filters.tagsOptions.isFitInOneLineEnabled);
  const isAuthenticated = useSelector((state: IAppState) => state.user.isAuthenticated);
  const villageName = useSelector((state: IAppState) => state.villageName);
  const tags = useSelector(tagsSelector);
  const tagsToReset: TJsonQueryOptionalKeysToReset[] = React.useMemo(
    () => (isSuburban(offerType) ? TERMS_TO_RESET : EXTENDED_TERMS_TO_RESET),
    [offerType],
  );

  // TODO убрать этот костыль, непонятно зачем вообще так сделали
  const kpIdTag = tags.find(({ type }) => type === 'kp-id') as IKpIdTag;
  if (kpIdTag && villageName) {
    kpIdTag.text = villageName;
  }

  const onTagDeleted = React.useCallback(
    (tag: TTag, index: number) => {
      /*
       * TODO clearTag на данный момент пустой, нужно перенести все старые теги на новые рельсы.
       *  В итоге нужно чтобы теги работали через модификаторы и не были привязаны напрямую к стору.
       *  Текущий clearTag-пустышка, нужен чтобы модификатор попадал в список lastAppliedModifiers
       *  и провоцировал отображение плашки об изменении фильтров с кнопкой "Применить".
       *
       *  Нельзя менять местами onChange и dispatch, иначе будет рассинхрон jsonQuery,
       *  потому что они одновременно попытаются модифицировать стор и jsonQuery останется старый
       */
      onChange({ action: 'clearTag', arguments: [] });

      dispatch({
        type: 'filters/tags/TAG_REMOVED',
        tag,
        index,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange],
  );

  const onAllTagsDeleted = React.useCallback(() => {
    dispatch({
      type: 'filters/tags/ALL_TAGS_REMOVED',
    });

    onChange({ action: 'clearTags', arguments: [tagsToReset] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, tagsToReset]);

  return (
    <Tags
      userIsAuthenticated={isAuthenticated}
      isFitInOneLineEnabled={isFitInOneLineEnabled}
      tags={tags}
      travelType={(jsonQuery.only_foot && jsonQuery.only_foot.value) || EMetroTravelType.Any}
      travelTime={jsonQuery.foot_min && jsonQuery.foot_min.value.lte}
      jsonQuery={jsonQuery}
      onTagDeleted={onTagDeleted}
      onAllTagsDeleted={onAllTagsDeleted}
    />
  );
};
