import { IAppState, TActions } from '../../../common/state';
import { EHeatingType, jq } from '../../../json_query';

export const IHeatingChangedActionType = 'filters/heating/HEATING_CHANGED';

export interface IHeatingChangedAction {
  type: 'filters/heating/HEATING_CHANGED';
  heatingType: EHeatingType;
  value: boolean;
}

export function changeHeating(heatingType: EHeatingType, value: boolean): IHeatingChangedAction {
  return {
    type: IHeatingChangedActionType,
    heatingType,
    value,
  };
}

export function heatingReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IHeatingChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: action.value
            ? jq(state.filters.jsonQuery).setHeatingType(action.heatingType)
            : jq(state.filters.jsonQuery).unsetHeatingType(action.heatingType),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-heating') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).unsetHeatingType(action.tag.value),
          },
        };
      }

      return state;

    default:
      return state;
  }
}
