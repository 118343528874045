import { IOffer, TOfferCategory } from '../../../types/offer';

export function isSuburban(offer: IOffer): boolean {
  return offer.category
    ? Array<TOfferCategory>(
        'cottageRent',
        'cottageSale',
        'dailyHouseRent',
        'houseRent',
        'houseSale',
        'houseShareRent',
        'houseShareSale',
        'landRent',
        'landSale',
        'townhouseRent',
        'townhouseSale',
      ).includes(offer.category)
    : false;
}
