import { getName } from './getName';
import { getNewbuildingRoomTypeSeoTitle } from './getNewbuildingRoomTypeSeoTitle';
import { getNewbuildingSeoDescription } from './getNewbuildingSeoDescription';
import { getNewbuildingSeoTitle } from './getNewbuildingSeoTitle';
import { getNewbuildingUrl } from './getNewbuildingUrl';
import { IAppState } from '../../common/state';
import { INewbuildingHeaderSeo } from '../../types/newbuildingHeader';

export function getNewbuildingHeaderSeo(state: IAppState): INewbuildingHeaderSeo {
  const newbuildingName = getName(state);
  const seoTitle = getNewbuildingSeoTitle(state) || getNewbuildingRoomTypeSeoTitle(state);
  const newbuildingUrl = getNewbuildingUrl(state);
  const seoDescription = getNewbuildingSeoDescription(state);

  const url =
    (newbuildingName &&
      newbuildingUrl && {
        text: newbuildingName,
        href: newbuildingUrl,
      }) ||
    undefined;

  return {
    title: seoTitle,
    url,
    description: seoDescription,
  };
}
