/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import { TGetNewbuildingBrokerWidgetModel, TGetNewbuildingBrokerWidgetResponse } from './types';

const defaultConfig = {
  apiType: 'public',
  assertStatusCodes: [200],
  method: 'GET',
  microserviceName: 'newbuilding-broker-widget-frontend',
  pathApi: '/v1/get-widget/',
  hostType: 'api',
  responseType: 'json',
} as TGetNewbuildingBrokerWidgetModel;

function createGetNewbuildingBrokerWidgetModel() {
  return {
    ...defaultConfig,
  };
}

export interface IGetNewbuildingBrokerWidgetOptions {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
}

async function fetchGetNewbuildingBrokerWidget({
  httpApi,
  config,
}: IGetNewbuildingBrokerWidgetOptions): Promise<TGetNewbuildingBrokerWidgetResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetNewbuildingBrokerWidgetModel(), config);

  return { response, statusCode } as TGetNewbuildingBrokerWidgetResponse;
}
export { defaultConfig, createGetNewbuildingBrokerWidgetModel, fetchGetNewbuildingBrokerWidget };
