import { Button } from '@cian/ui-kit/button';
import { ModalWindow } from '@cian/ui-kit/modal';
import { Header2, InlineText1 } from '@cian/ui-kit/typography';
import * as React from 'react';

import * as styles from './styles.css';

interface ISuccessModalProps {
  open: boolean;
  phone: string;
  time: string;
  dayOfCallText: string;
  onClose(): void;
}

export function SuccessModal({ open, phone, dayOfCallText, time, onClose }: ISuccessModalProps) {
  return (
    <ModalWindow open={open} onClose={onClose} width={518}>
      <div className={styles['container']}>
        <Header2 as="span">
          Заявка на обратный звонок
          <br />
          отправлена 👌
        </Header2>
        <div className={styles['text']}>
          <InlineText1>
            Вам перезвонят {dayOfCallText} в {time}
            <br />
            на номер {phone}
          </InlineText1>
        </div>
        <Button size="XS" theme="fill_primary" onClick={onClose}>
          Хорошо
        </Button>
      </div>
    </ModalWindow>
  );
}
