import { ca } from '@cian/analytics';

import { setAuthenticationCancelled, setAuthenticationSucceed } from '../../actions/authentication/authentication';
import { getUser } from '../../actions/user';
import { IAppStore } from '../../common/state';
import { getTrackingUser } from '../../selectors/getTrackingUser';
import { EUserEventType, TUserEvent } from '../types/user';

interface IEventHandlerParams {
  reduxStore: IAppStore;
}

export function createAuthenticationEventHandler({ reduxStore }: IEventHandlerParams) {
  return async function authenticationEventHandler(event: TUserEvent) {
    if (typeof window === 'undefined') {
      return;
    }

    switch (event.type) {
      case EUserEventType.Authenticated: {
        await reduxStore.dispatch(getUser());

        ca('user', getTrackingUser(reduxStore.getState()));

        if (window.__reloadHeader__) {
          window.__reloadHeader__();
        }

        reduxStore.dispatch(setAuthenticationSucceed());

        break;
      }
      case EUserEventType.AuthenticationModalClosed: {
        reduxStore.dispatch(setAuthenticationCancelled());

        break;
      }
    }
  };
}
