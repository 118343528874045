import { NEWBUILDING_MARTECH_NEW_DESIGN_TGB_LISTING_EXPERIMENT } from '../selectors/experiments/useSelectIsNewbuildingMartechNewDesignTgbListingExperimentEnabled';

export const NB_CHAT_COMMON_FILTER = 'newbuilding_chat_common_filter';
export const NB_CONSULTANT_TRIGGERING_MESSAGE = 'newbuilding-triggering-message';
export const NB_HELP_IN_SEARCH = 'newbuilding_consultant_help_in_search';
export const RENTER_FORM_EXPERIMENT = 'renter_form_experiment';
export const AUD_DESKTOP_AUD2776_CIAN_ASSISTANT_ENTRY_POINT = 'aud_desktop_aud2776_cian_assistant_entry_point';

export const EXPERIMENTS: string[] = [
  'newbuilding_consultant',
  NB_CHAT_COMMON_FILTER,
  NB_CONSULTANT_TRIGGERING_MESSAGE,
  /**
   * @todo Удалить строку с экспериментом
   * @description Данный функционал появился в задаче CD-206850, будет удалён в задаче CD-206973
   */
  NEWBUILDING_MARTECH_NEW_DESIGN_TGB_LISTING_EXPERIMENT,
  NB_HELP_IN_SEARCH,
  RENTER_FORM_EXPERIMENT,
  AUD_DESKTOP_AUD2776_CIAN_ASSISTANT_ENTRY_POINT,
];
