import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackNotnowClick, trackTrynowClick } from './tracking';
import { ProfessionalSearchBanner } from '../../../../../shared/components/ProfessionalSearchBanner';
import { useLocalStorage } from '../../../../../shared/hooks';
import { selectIsMultiplatformProfessionalSearchBannerVisible } from '../../../../../shared/selectors/selectIsMultiplatformProfessionalSearchBannerVisible';
import { selectProfessionalSearchUrl } from '../../selectors';

export const ProfessionalSearchBannerContainer = () => {
  const isVisible = useSelector(selectIsMultiplatformProfessionalSearchBannerVisible);
  const [hideProfessionalSearchPromoBanner, setHideProfessionalSearchPromoBanner] = useLocalStorage(
    'hideProfessionalSearchPromoBanner',
    false,
  );
  const link = useSelector(selectProfessionalSearchUrl);

  const handleTryNowClick = React.useCallback(() => {
    trackTrynowClick();
  }, []);
  const handleNotnowClick = React.useCallback(() => {
    trackNotnowClick();
    setHideProfessionalSearchPromoBanner(true);
  }, [setHideProfessionalSearchPromoBanner]);

  if (!isVisible || hideProfessionalSearchPromoBanner) {
    return null;
  }

  return <ProfessionalSearchBanner onTrynowClick={handleTryNowClick} onNotnowClick={handleNotnowClick} link={link} />;
};
