import React from 'react';
import { useSelector } from 'react-redux';

import { botCathcerLinksSelector } from './utils/selectors';
import { SeoBotCatcherLinks } from '../../components/SeoBotCatcherLinks';

export function SeoBotCatcherLinksContainer() {
  const links = useSelector(botCathcerLinksSelector);

  if (!links || links.length === 0) {
    return null;
  }

  return <SeoBotCatcherLinks links={links} title="Дополнительные предложения" />;
}
