import * as React from 'react';

import { NonEmptyArray } from '../../../../../JsonQuery';
import { ERoomsTotal } from '../../../types/jsonQuery';
import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface IRoom {
  label: string;
  value: ERoomsTotal | null;
}

const ROOMS: IRoom[] = [
  { label: 'Неважно', value: null },
  { label: '1', value: ERoomsTotal.One },
  { label: '2', value: ERoomsTotal.Two },
  { label: '3', value: ERoomsTotal.Three },
  { label: '4', value: ERoomsTotal.Four },
  { label: '5', value: ERoomsTotal.Five },
];

interface IFlatRoomsProps {
  value: NonEmptyArray<ERoomsTotal> | null;
  onChange(value: NonEmptyArray<ERoomsTotal> | null): void;
}

export const FlatRooms: React.FC<IFlatRoomsProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_flatRooms" label="Комнат в квартире">
      <CheckboxButtonGroupUnselectable options={ROOMS} value={value} onChange={onChange} />
    </Filter>
  );
};
