import { IAppState, TActions } from '../../../common/state';
import { IElectronicTradingTypeTagType } from '../tags/definitions/electronic_trading_type';

export function electronicTradingTypeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === IElectronicTradingTypeTagType) {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              electronic_trading: undefined,
            },
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            electronic_trading: undefined,
          },
        },
      };

    default:
      return state;
  }
}
