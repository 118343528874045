import { LinkButton, TooltipDesktop } from '@cian/ui-kit';
import { IconFileDocument16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { ControlWrapper } from '../../../../components/ControlWrapper';
import { useOfferContext } from '../../../../context';

export function DownloadPDFControlContainer() {
  const {
    offer: { fullUrl },
    tracking: { trackPDFLinkClicked },
  } = useOfferContext();

  const url = React.useMemo(() => fullUrl.replace('.ru/', '.ru/export/pdf/'), [fullUrl]);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();

    trackPDFLinkClicked();
  };

  return (
    <ControlWrapper>
      <TooltipDesktop theme="black" placement="left" title="Скачать PDF">
        <LinkButton
          data-mark="DownloadPDFControl"
          target="_blank"
          size="XS"
          theme="stroke_secondary"
          onClick={handleClick}
          href={url}
          beforeIcon={<IconFileDocument16 />}
        />
      </TooltipDesktop>
    </ControlWrapper>
  );
}
