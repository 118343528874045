import * as R from 'ramda';

import { setGeo } from './helpers';
import { TGeoValue, TJsonQuery } from './types';

export function removeGeo(jsonQuery: TJsonQuery): (geoValue: TGeoValue) => TJsonQuery {
  return geoValue => {
    if (!jsonQuery.geo || !jsonQuery.geo.value || !R.find(R.equals(geoValue), jsonQuery.geo.value)) {
      return jsonQuery;
    }

    return setGeo()(jsonQuery)(jsonQuery.geo.value.filter(value => !R.equals(value, geoValue)));
  };
}
