import * as React from 'react';

import { EGarageUtility, NonEmptyArray } from '../../../../../JsonQuery';
import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface IGarageUtility {
  label: string;
  value: EGarageUtility | null;
}

const GARAGE_UTILITIES: IGarageUtility[] = [
  { label: 'Неважно', value: null },
  { label: 'Электричество', value: EGarageUtility.Electricity },
  { label: 'Вода', value: EGarageUtility.Water },
  { label: 'Отопление', value: EGarageUtility.Heating },
  { label: 'Система пожаротушения', value: EGarageUtility.ExtinguishingSystem },
];

interface IGarageUtilitiesProps {
  value: NonEmptyArray<EGarageUtility> | null;
  onChange(value: NonEmptyArray<EGarageUtility> | null): void;
}

export const GarageUtilities: React.FC<IGarageUtilitiesProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_garageUtilities" label="Коммуникации">
      <CheckboxButtonGroupUnselectable options={GARAGE_UTILITIES} value={value} onChange={onChange} />
    </Filter>
  );
};
