import { IOffer } from '../../../types/offer';

export function getGarageTitle(offer: IOffer) {
  if (!offer.garage) {
    return 'Гараж';
  }

  switch (offer.garage.type) {
    case 'box':
      return 'Бокс';
    case 'garage':
      return 'Гараж';
    case 'parkingPlace':
      return 'Машиноместо';
    default:
      return '';
  }
}
