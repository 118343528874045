import * as React from 'react';

import { IGeoPolygonTag } from '../../../../../state/tags/definitions/geo';
import { TagNew } from '../../../TagNew';

export interface IGeoPolygonTagItemProps {
  tag: IGeoPolygonTag;
  onDeleteClick(): void;
}

export const GeoPolygonTagItem = (props: IGeoPolygonTagItemProps) => {
  return <TagNew onDeleteClick={props.onDeleteClick}>{props.tag.text}</TagNew>;
};
