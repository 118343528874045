import * as React from 'react';

import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';

interface IDemolitionOption {
  label: string;
  value: boolean | null;
}

const DEMOLITION_OPTIONS: IDemolitionOption[] = [
  { label: 'Неважно', value: null },
  { label: 'Не показывать', value: false },
  { label: 'Только под снос', value: true },
];

interface IDemolitionProps {
  value: boolean | null;
  onChange(value: boolean | null): void;
}

export const Demolition: React.FC<IDemolitionProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_demolition" label="Дома под снос">
      <RadioButtonGroup options={DEMOLITION_OPTIONS} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
