import { IAppState, TActions } from '../../../common/state';
import { terms } from '../../../json_query';

export function offerSellerReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-offerSeller') {
        const offerSellers = state.filters.jsonQuery.offer_seller_type?.value;
        const removedElement = action.tag.value;
        const newOfferSellers = offerSellers?.filter(seller => seller !== removedElement);

        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              offer_seller_type: newOfferSellers ? terms(newOfferSellers) : undefined,
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
}
