import { v4 as uuid } from 'uuid';

import { ISession, IJsonQuery } from '../types';

const SESSION_KEY = '__serp_ml_search_session__';

export function generateSession(jsonQuery: IJsonQuery): ISession {
  const guid = uuid();
  const session = { jsonQuery, guid };

  sessionStorage.setItem(SESSION_KEY, JSON.stringify(session));

  return session;
}

export function restoreSession(): ISession | null {
  const sessionString = sessionStorage.getItem(SESSION_KEY);
  const session = sessionString ? JSON.parse(sessionString) : null;

  return session;
}
