import { Button, TooltipDesktop } from '@cian/ui-kit';
import { IconActionCompareAdd16, IconActionCompareAdded16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { ControlWrapper } from '../../../../components/ControlWrapper';
import { FakeControlWrapper } from '../../../../components/FakeControlWrapper';
import { useOfferContext } from '../../../../context';
import { EStatus } from '../../../../types/offer-comparison';
import { ComparisonControlTooltip } from '../../ComparisonControlTooltip';

export const ComparisonControlContainer: React.FC = () => {
  const {
    offer,
    api: { addToComparison, deleteFromComparison },
    tracking: { trackAddOfferToComparison, trackDeleteOfferFromComparison },
  } = useOfferContext();
  const { comparisonStatus } = offer;

  const { description, isAdded, isUnavailable } = React.useMemo(
    () => ({
      description: (comparisonStatus && comparisonStatus.description) || null,
      isAdded: comparisonStatus ? comparisonStatus.status === EStatus.Added : false,
      isUnavailable: !comparisonStatus || comparisonStatus.status === EStatus.Unavailable,
    }),
    [comparisonStatus],
  );

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      const offerId = offer.id;
      const category = offer.category;
      const label = offer.gaLabel || '';

      if (isAdded) {
        deleteFromComparison({ offerId });
        trackDeleteOfferFromComparison({ offerId, label });
      } else {
        addToComparison({ offerId, category });
        trackAddOfferToComparison({ offerId, label });
      }
    },
    [isAdded, offer],
  );

  if (comparisonStatus == null) {
    return null;
  }

  const button = isUnavailable ? (
    <FakeControlWrapper>
      <Button
        theme="fill_primary"
        data-mark="ComparisonControl"
        size="XS"
        beforeIcon={<IconActionCompareAdd16 color="gray60_100" />}
        disabled
        fullWidth
      />
    </FakeControlWrapper>
  ) : (
    <Button
      onClick={handleClick}
      theme="stroke_secondary"
      data-mark="ComparisonControl"
      size="XS"
      beforeIcon={
        isAdded ? <IconActionCompareAdded16 color="primary_100" /> : <IconActionCompareAdd16 color="gray60_100" />
      }
    />
  );

  return (
    <ControlWrapper visible={true}>
      {description ? (
        <TooltipDesktop theme="black" placement="left" title={<ComparisonControlTooltip text={description} />}>
          {button}
        </TooltipDesktop>
      ) : (
        button
      )}
    </ControlWrapper>
  );
};
