export function ensureChildrenVisibility(container: HTMLElement, targetChildren: HTMLElement) {
  const isOutOfUpperView = targetChildren.offsetTop < container.scrollTop;
  const isOutOfLowerView =
    targetChildren.offsetTop + targetChildren.clientHeight > container.scrollTop + container.clientHeight;

  if (isOutOfUpperView) {
    container.scrollTop = targetChildren.offsetTop;
  } else if (isOutOfLowerView) {
    container.scrollTop = targetChildren.offsetTop + targetChildren.clientHeight - container.clientHeight;
  }
}
