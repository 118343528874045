import * as React from 'react';

import * as styles from './Filters.css';
import { Filter } from './components/Filter';
import { Group } from './components/Group';
import { TFilter } from '../../types/filters';

interface IFiltersProps {
  advancedFilters?: React.ReactNode;
  applyButton?: React.ReactNode;
  bedrooms?: React.ReactNode;
  beds?: React.ReactNode;
  cottageVillage?: React.ReactNode;
  dates?: React.ReactNode;
  dealType?: React.ReactNode;
  geo?: React.ReactNode;
  offerType?: React.ReactNode;
  posessor?: React.ReactNode;
  price?: React.ReactNode;
  roomType?: React.ReactNode;
  savedSearch?: React.ReactNode;
  tags?: React.ReactNode;
  villageMortgageAllowed?: React.ReactNode;
  starRating?: React.ReactNode;
  suburbanArea?: React.ReactNode;
}

type TMainFilter = Exclude<TFilter, 'geo'>;

const MAIN_FILTERS_GROUP: TMainFilter[] = [
  'dealType',
  'dates',
  'beds',
  'offerType',
  'roomType',
  'bedrooms',
  'price',
  'suburbanArea',
  'villageMortgageAllowed',
  'advancedFilters',
  'cottageVillage',
  'starRating',
];

export const Filters: React.FC<IFiltersProps> = ({ geo, savedSearch, applyButton, tags, ...restFilters }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['layout']}>
        <div className={styles['filters-layout']}>
          <Group>
            {MAIN_FILTERS_GROUP.filter(filterKey => Boolean(restFilters[filterKey])).map(filterKey => (
              <Filter key={`filter_${filterKey}`} id={`mainFilter_${filterKey}`}>
                {restFilters[filterKey]}
              </Filter>
            ))}
          </Group>
          <Group className={styles['secondary-group']}>
            {geo && <Filter className={styles['geo']}>{geo}</Filter>}
            {applyButton && <div className={styles['button']}>{applyButton}</div>}
            {savedSearch && <div className={styles['button']}>{savedSearch}</div>}
          </Group>
        </div>
        {tags && <div className={styles['tags-layout']}>{tags}</div>}
      </div>
    </div>
  );
};
