import * as R from 'ramda';

import { setTerm, setRangeMin, resetTerms } from './helpers';
import { EElevatorType, TJsonQuery } from './types';
import { EElevators } from '../api-models/common/json_query';

export function setElevatorType(jsonQuery: TJsonQuery) {
  return (elevatorType: EElevatorType | null): TJsonQuery => {
    let nextJsonQuery = R.clone(jsonQuery);

    nextJsonQuery = resetTerms(['lift_service', 'lifts'])(nextJsonQuery);

    if (elevatorType) {
      switch (elevatorType) {
        case EElevatorType.Any:
          nextJsonQuery = setTerm('lifts')(nextJsonQuery)(EElevators.One);
          break;
        case EElevatorType.Service:
          nextJsonQuery = setRangeMin('lift_service')(nextJsonQuery)(1);
          break;
      }
    }

    return nextJsonQuery;
  };
}
