import * as React from 'react';

import { ContentRow } from '../ContentRow';
import { Proof } from '../Proof';

export interface IDocumentsApproveMessageProps {
  message: string;
}

export const AgentWorkHonestlyProofs = ({ message }: IDocumentsApproveMessageProps) => {
  return (
    <ContentRow>
      <Proof text={message} />
    </ContentRow>
  );
};
