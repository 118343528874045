import { ESuburbanUtility } from '../../../../json_query';
import { isSuburban, offerTypeFromJsonQuery } from '../../../../utils/category';
import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const ISuburbanUtilityTagType = 'advanced-suburbanUtility';

export interface ISuburbanUtilityTag {
  type: 'advanced-suburbanUtility';
  value: ESuburbanUtility;
}

function isSuburbanUtilityTag(tag: TTag): tag is ISuburbanUtilityTag {
  return tag.type === ISuburbanUtilityTagType;
}

export const suburbanUtilityTagDefinition = [
  createSuburbanUtilityTag(ESuburbanUtility.Drainage),
  createSuburbanUtilityTag(ESuburbanUtility.Electricity),
  createSuburbanUtilityTag(ESuburbanUtility.Gas),
  createSuburbanUtilityTag(ESuburbanUtility.Water),
];

function createSuburbanUtilityTag(suburbanUtility: ESuburbanUtility): ITagDefinition {
  return {
    reduce: (tags, jsonQuery) => {
      const offerType = offerTypeFromJsonQuery(jsonQuery);
      const value: boolean | undefined =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (jsonQuery as any)[suburbanUtility] &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (jsonQuery as any)[suburbanUtility].value;

      if (value && isSuburban(offerType)) {
        return ensureSingleTagExists(
          tags,
          (tag: TTag): tag is ISuburbanUtilityTag => isSuburbanUtilityTag(tag) && tag.value === suburbanUtility,
          () => ({ type: ISuburbanUtilityTagType, value: suburbanUtility }),
        );
      } else {
        return ensureTagNotExists(
          tags,
          (tag: TTag): tag is ISuburbanUtilityTag => isSuburbanUtilityTag(tag) && tag.value === suburbanUtility,
        );
      }
    },
  };
}
