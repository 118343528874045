import classNames from 'classnames';
import * as React from 'react';

import * as styles from './SuburbanCatalogs.css';
import { SuburbanCatalog } from './components/SuburbanCatalog';
import { ISuburbanCatalog } from '../../../types/suburbanCatalog';

interface ISuburbanCatalogsProps {
  catalogs: ISuburbanCatalog[];
  fullWidth?: boolean;
}

export const SuburbanCatalogs: React.VFC<ISuburbanCatalogsProps> = ({ catalogs, fullWidth }) => {
  return (
    <div className={classNames(styles['container'], { [styles['full-width']]: fullWidth })}>
      <div className={styles['list']}>
        {catalogs.map((catalog, index) => (
          <SuburbanCatalog key={index} {...catalog} />
        ))}
      </div>
    </div>
  );
};
