import * as React from 'react';

import { ESuburbanUtility, NonEmptyArray } from '../../../../../JsonQuery';
import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface ISuburbanUtility {
  label: string;
  value: ESuburbanUtility | null;
}

const UTILITIES: ISuburbanUtility[] = [
  { label: 'Неважно', value: null },
  { label: 'Электричество', value: ESuburbanUtility.Electricity },
  { label: 'Газ', value: ESuburbanUtility.Gas },
  { label: 'Водоснабжение', value: ESuburbanUtility.Water },
  { label: 'Канализация', value: ESuburbanUtility.Drainage },
];

interface ISuburbanUtilitiesProps {
  value: NonEmptyArray<ESuburbanUtility> | null;
  onChange(value: NonEmptyArray<ESuburbanUtility> | null): void;
}

export const SuburbanUtilities: React.FC<ISuburbanUtilitiesProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_suburbanUtilities" label="Коммуникации">
      <CheckboxButtonGroupUnselectable options={UTILITIES} value={value} onChange={onChange} />
    </Filter>
  );
};
