/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';

export type TAddOfferToComparisonModel = IModel<IAddOfferToComparisonRequest, TAddOfferToComparisonResponse>;

export interface IAddOfferToComparisonRequest {
  /** Категория объявления **/
  category?: ECategory | null;
  /** ID объявления **/
  offerId: number;
}

export type TAddOfferToComparisonResponse = IAddOfferToComparisonResponse200 | IAddOfferToComparisonResponse400;

export interface IAddOfferToComparisonResponse200 extends IModelResponse<IAddOfferToComparisonResponse> {
  statusCode: 200;
}

export interface IAddOfferToComparisonResponse400 extends IModelResponse<IAddOfferToComparisonResponseError> {
  statusCode: 400;
}

export interface IAddOfferToComparisonResponse {
  /** ссылка на страницу сравнения **/
  comparisonUrl: string;
  /** id объявления **/
  offerId: number;
  /** объявление было добавлено или нет **/
  success: boolean;
  /** описание результата **/
  text: string;
  /** Кол-во объявлений в подборке **/
  totalOffers: number;
}

export interface IAddOfferToComparisonResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IAddOfferToComparisonResponse | IAddOfferToComparisonResponseError;

export enum ECategory {
  /** Аренда койко-места **/
  BedRent = 'bedRent',
  /** Аренда здания **/
  BuildingRent = 'buildingRent',
  /** Продажа здания **/
  BuildingSale = 'buildingSale',
  /** Аренда готового бизнеса **/
  BusinessRent = 'businessRent',
  /** Продажа готового бизнеса **/
  BusinessSale = 'businessSale',
  /** Аренда автосервис **/
  CarServiceRent = 'carServiceRent',
  /** Продажа автосервиса **/
  CarServiceSale = 'carServiceSale',
  /** Аренда коммерческой земли **/
  CommercialLandRent = 'commercialLandRent',
  /** Продажа коммерческой земли **/
  CommercialLandSale = 'commercialLandSale',
  /** Аренда коттеджа **/
  CottageRent = 'cottageRent',
  /** Продажа коттеджа **/
  CottageSale = 'cottageSale',
  /** Посуточная аренда койко-места **/
  DailyBedRent = 'dailyBedRent',
  /** Посуточная аренда квартиры **/
  DailyFlatRent = 'dailyFlatRent',
  /** Посуточная аренда дома, дачи, коттеджа **/
  DailyHouseRent = 'dailyHouseRent',
  /** Посуточная аренда комнаты **/
  DailyRoomRent = 'dailyRoomRent',
  /** Аренда помещения под бытовые услуги **/
  DomesticServicesRent = 'domesticServicesRent',
  /** Продажа помещения под бытовые услуги **/
  DomesticServicesSale = 'domesticServicesSale',
  /** Аренда квартиры **/
  FlatRent = 'flatRent',
  /** Продажа квартиры **/
  FlatSale = 'flatSale',
  /** Продажа доли в квартире **/
  FlatShareSale = 'flatShareSale',
  /** Аренда помещения свободного назначения **/
  FreeAppointmentObjectRent = 'freeAppointmentObjectRent',
  /** Продажа помещения свободного назначения **/
  FreeAppointmentObjectSale = 'freeAppointmentObjectSale',
  /** Аренда гаража **/
  GarageRent = 'garageRent',
  /** Продажа гаража **/
  GarageSale = 'garageSale',
  /** Аренда дома/дачи **/
  HouseRent = 'houseRent',
  /** Продажа дома/дачи **/
  HouseSale = 'houseSale',
  /** Аренда части дома **/
  HouseShareRent = 'houseShareRent',
  /** Продажа части дома **/
  HouseShareSale = 'houseShareSale',
  /** Аренда производства **/
  IndustryRent = 'industryRent',
  /** Продажа производство **/
  IndustrySale = 'industrySale',
  /** Продажа участка **/
  LandSale = 'landSale',
  /** Продажа квартиры в новостройке **/
  NewBuildingFlatSale = 'newBuildingFlatSale',
  /** Аренда офиса **/
  OfficeRent = 'officeRent',
  /** Продажа офиса **/
  OfficeSale = 'officeSale',
  /** Аренда общепита **/
  PublicCateringRent = 'publicCateringRent',
  /** Продажа общепита **/
  PublicCateringSale = 'publicCateringSale',
  /** Аренда комнаты **/
  RoomRent = 'roomRent',
  /** Продажа комнаты **/
  RoomSale = 'roomSale',
  /** Аренда торговой площади **/
  ShoppingAreaRent = 'shoppingAreaRent',
  /** Продажа торговой площади **/
  ShoppingAreaSale = 'shoppingAreaSale',
  /** Аренда таунхауса **/
  TownhouseRent = 'townhouseRent',
  /** Продажа таунхауса **/
  TownhouseSale = 'townhouseSale',
  /** Аренда склада **/
  WarehouseRent = 'warehouseRent',
  /** Продажа склада **/
  WarehouseSale = 'warehouseSale',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IAddOfferToComparisonResponse): TResponse200;
  400(response: IAddOfferToComparisonResponseError): TResponse400;
}
