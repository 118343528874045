import { IOffer } from '../../offer';
import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum ENotAvailablePopupActionTypes {
  Open = 'NotAvailablePopup/Open',
  Close = 'NotAvailablePopup/Close',
}

export type TOpenNotAvailablePopupPayload = { offer: IOffer; position: number };

export type TOpenNotAvailablePopup = ITypedReduxAction<
  ENotAvailablePopupActionTypes.Open,
  TOpenNotAvailablePopupPayload
>;
export type TCloseNotAvailablePopup = ITypedReduxAction<ENotAvailablePopupActionTypes.Close>;

export const openNotAvailablePopup: (payload: TOpenNotAvailablePopupPayload) => TOpenNotAvailablePopup =
  actionGenerator<ENotAvailablePopupActionTypes.Open, TOpenNotAvailablePopupPayload>(
    ENotAvailablePopupActionTypes.Open,
  );

export const closeNotAvailablePopup: () => TCloseNotAvailablePopup =
  actionGenerator<ENotAvailablePopupActionTypes.Close>(ENotAvailablePopupActionTypes.Close);

export type TNotAvailablePopupActions = TOpenNotAvailablePopup | TCloseNotAvailablePopup;
