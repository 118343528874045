import { ICalendarContainerProps, ICalendarState } from './types';
import { MONTHS_TO_DISPLAY } from '../../constants/widget';
import { getMonthsData, updateCalendarData } from '../../utils';

export type TPrepareInitialParams = Pick<ICalendarContainerProps, 'maxDate' | 'minDate' | 'fromValue' | 'toValue'>;

export function prepareInitialState({ minDate, maxDate, fromValue, toValue }: TPrepareInitialParams): ICalendarState {
  const initialCalendar = getMonthsData({ start: minDate, end: maxDate });
  const calendar = updateCalendarData({ calendar: initialCalendar, from: fromValue, to: toValue });
  let currentPage = Math.max(
    calendar.findIndex(month => month.days.some(day => day?.type === 'range_start')),
    0,
  );

  if (currentPage % 2 === 1) {
    currentPage -= 1;
  }

  return {
    initialCalendar,
    calendar,
    isPrevPageAvailable: currentPage > 1,
    isNextPageAvailable: currentPage < initialCalendar.length - MONTHS_TO_DISPLAY - 1,
    currentPage,
    selecting: false,
    updated: false,
    to: toValue,
    from: fromValue,
  };
}
