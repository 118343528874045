import * as React from 'react';

import { EWCType } from '../../../../json_query';
import { IWCTypeTag } from '../../../state/tags/definitions/wc_type';
import { TagNew } from '../TagNew';

export interface IWCTypeTagItemProps {
  tag: IWCTypeTag;
  onDeleteClick(): void;
}

const wcTypeMap = {
  [EWCType.Separated]: 'Раздельный',
  [EWCType.United]: 'Совмещенный',
};

export const WCTypeTagItem = ({ tag, onDeleteClick }: IWCTypeTagItemProps) => {
  if (tag.value === EWCType.Any) {
    return null;
  }

  return <TagNew onDeleteClick={onDeleteClick}>{wcTypeMap[tag.value]} санузел</TagNew>;
};
