import * as React from 'react';

import { getRoomTypeLabel } from './helpers';
import { NonEmptyArray } from '../../../../../JsonQuery';
import { ERoomType } from '../../../types/jsonQuery';
import { Select, Option } from '../../Select';

interface IRoomType {
  label: string;
  value: ERoomType;
}

const ROOM_TYPES: IRoomType[] = [
  { label: '1-комнатная', value: ERoomType.FlatOneRoom },
  { label: '2-комнатная', value: ERoomType.FlatTwoRooms },
  { label: '3-комнатная', value: ERoomType.FlatThreeRooms },
  { label: '4-комнатная', value: ERoomType.FlatFourRooms },
  { label: '5-комнатная', value: ERoomType.FlatFiveRooms },
  { label: '6-комнатная', value: ERoomType.FlatManyRooms },
  { label: 'Студия', value: ERoomType.Studio },
  { label: 'Свободная планировка', value: ERoomType.FlatFreeLayout },
];

interface IRoomTypeProps {
  open?: boolean;
  value: NonEmptyArray<ERoomType> | null;
  offersCount: number | undefined;
  offersCountLoading?: boolean;
  openTooltip?: boolean;
  onChange(value: NonEmptyArray<ERoomType> | null): void;
  onApply?(): void;
  onOpen?(): void;
  onClose?(): void;
}

export const RoomType: React.FC<IRoomTypeProps> = ({
  open,
  value,
  offersCount,
  offersCountLoading,
  openTooltip,
  onChange,
  onApply,
  onOpen,
  onClose,
}) => {
  const roomTypeLabel = value ? getRoomTypeLabel(value) : null;

  return (
    <Select
      open={open}
      multiple
      withoutArrow
      label={roomTypeLabel || undefined}
      placeholder="Комнатность"
      value={value}
      offersCount={offersCount}
      offersCountLoading={offersCountLoading}
      onChange={(_, value: NonEmptyArray<ERoomType>) => onChange(value)}
      onApply={onApply}
      onOpen={onOpen}
      onClose={onClose}
      openTooltip={openTooltip}
    >
      {ROOM_TYPES.map(roomType => (
        <Option key={`${roomType.label}_${roomType.value}`} value={roomType.value}>
          {roomType.label}
        </Option>
      ))}
    </Select>
  );
};
