import { plural } from '@cian/utils';

import { ELabelColor, ILabel } from '../../../types/label';
import { ENewbuildingSnippetType } from '../../../types/offer';

export function getNewbuildingSnippetLabels({
  labelsV1,
  labelsV2,
  promoLabels,
  snippetType,
  countPromos,
  isBlackFriday,
  isExclusive,
  isUnique,
}: {
  labelsV1: ILabel[];
  labelsV2: ILabel[];
  promoLabels: ILabel[] | null;
  snippetType?: ENewbuildingSnippetType;
  countPromos?: number;
  isBlackFriday?: boolean;
  isExclusive?: boolean;
  isUnique?: boolean;
}): ILabel[] {
  const cplLabels: ILabel[] = [];

  if (labelsV2) {
    cplLabels.push(...labelsV2);
  }

  if (promoLabels && (snippetType !== ENewbuildingSnippetType.Basic || isBlackFriday)) {
    cplLabels.push(
      ...promoLabels.map(label => ({
        ...label,
        color: snippetType === ENewbuildingSnippetType.Maximum ? ELabelColor.DarkGreen : ELabelColor.Green,
        ...(isBlackFriday && {
          color: ELabelColor.SurfaceBlueIconed,
        }),
        ...(isBlackFriday &&
          label.text === 'Эксклюзив' && {
            color: ELabelColor.BlackBlueGradient,
          }),
      })),
    );
  }

  if (countPromos && snippetType !== ENewbuildingSnippetType.Basic) {
    const moreText = promoLabels && (snippetType === ENewbuildingSnippetType.Maximum || isBlackFriday) ? 'ещё ' : '';

    cplLabels.push({
      color: snippetType === ENewbuildingSnippetType.Maximum ? ELabelColor.DarkGreen : ELabelColor.Green,
      text: `${moreText}${countPromos} ${plural(countPromos, ['акция', 'акции', 'акций'])}`,
      ...(isBlackFriday && {
        color: ELabelColor.SurfaceBlue,
      }),
    });
  }

  const decorLabel = labelsV1.find(label => label.text === 'С отделкой');

  if (decorLabel) {
    cplLabels.push(decorLabel);

    return cplLabels;
  }

  if (isUnique && !isExclusive) {
    cplLabels.push({ color: ELabelColor.Blue, text: 'Только на Циан' });

    return cplLabels;
  }

  const salesLeaderLabel = labelsV1.find(label => label.text === 'ЖК лидер продаж');

  if (salesLeaderLabel) {
    cplLabels.push(salesLeaderLabel);

    return cplLabels;
  }

  return cplLabels;
}
