import { FormikProps } from 'formik';

import { TSetAuctionBetStatus } from '../api/setOfferAuctionBet';

export interface IAuctionStatusNotificationProps {
  status: TSetAuctionBetStatus;
  onRetryClick?(): void;
  onPayClick?(): void;
}

export enum EAuctionTooltipView {
  /** Объявление принадлежит пользователю и ставку можно изменить  */
  MANAGE,
  /** Объявление не принадлежит пользователю, но есть на других страницах выдачи */
  AUCTION,
  /** Объявление не принадлежит пользователю, но его объявление есть на этой странице выдачи  */
  AUCTION_ON_THIS_PAGE,
  /** Объявление принадлежит пользователю и ставку нельзя изменить */
  NOT_PREMIUM,
  /**
   * Объявление не принадлежит пользователю, но его объявление есть на этой странице выдачи
   * и имеет отличный от текущего отбъявления пакет услуг
   */
  AUCTION_DIFFERENT_SERVICE,
}

export interface IBetFormValues {
  bet: number;
}

interface IAuctionContextCommon {
  /* объявление с аукционом принадлежит агенту */
  isPersonalBet: boolean;
  /* объявление с аукционом принадлежит конкуренту */
  isConcurrentBet: boolean;
  /* показ подсказки об аукционе */
  isAuctionEnabled: boolean;
  /* минимальная ставка на аукционе */
  auctionMinBet: number;
  /* максимальная ставка на аукционе */
  auctionMaxBet: number;
  /* контент тултипа в зависимости от контекста */
  tooltipView: EAuctionTooltipView | null;
  /* текущая ставка */
  currentBet: number;
  /* следующая ставка */
  nextBet?: number;
  /* баллы ликвидности */
  liquidityPoints: number | null;
  /* дата применения следующей ставки */
  applyNewBetDate?: string;
  /* снижается ли ставка */
  isReducingBet: boolean;
  /* ручка смены контента тултипа */
  setTooltipView(view?: EAuctionTooltipView): void;
  /* увеличение ставки на шаг */
  incrementBet(): void;
  /* контроллер формы */
  formik: FormikProps<IBetFormValues>;
  /* сброс формы */
  resetForm(): void;
}

interface IAuctionContextEnabled extends IAuctionContextCommon {
  isAuctionEnabled: true;
  tooltipView: EAuctionTooltipView;
}

interface IAuctionContextDisabled extends IAuctionContextCommon {
  isAuctionEnabled: false;
  tooltipView: null;
}

export type TAuctionContext = IAuctionContextEnabled | IAuctionContextDisabled;
