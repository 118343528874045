import { Button } from '@cian/ui-kit/button';
import * as React from 'react';

import { SearchResultsTooltip, useContext, useSearchResultsTooltipOpen } from '../../../../packages/Filters';
import { checkIsHotelsSearch } from '../../../utils/checkIsHotelsSearch';

export function ApplyFiltersButtonContainer() {
  const { jsonQuery, onApply, onApplyClick, jsonQueryCount, jsonQueryCountRefreshing } = useContext();
  const isHotelSearch = checkIsHotelsSearch(jsonQuery);
  const openTooltip = useSearchResultsTooltipOpen({
    filterKey: 'geo',
    actions: ['selectGeoFake', 'setKPIdFake'],
  });

  const handleTooltipApply = () => {
    if (onApplyClick) {
      onApplyClick({ source: 'tooltip' });
    }

    onApply();
  };

  const handleSearch = () => {
    if (onApplyClick) {
      onApplyClick({ source: 'findButton' });
    }

    onApply();
  };

  return (
    <SearchResultsTooltip
      open={openTooltip}
      hideCount={isHotelSearch}
      offersCount={jsonQueryCount || 0}
      loading={jsonQueryCountRefreshing}
      placement="bottom-start"
      onApply={handleTooltipApply}
    >
      <Button
        data-name="ApplyFiltersButton"
        theme="fill_primary"
        loading={jsonQueryCountRefreshing}
        size="XS"
        onClick={handleSearch}
      >
        Найти
      </Button>
    </SearchResultsTooltip>
  );
}
