import { Spinner } from '@cian/ui-kit';
import * as React from 'react';

import styles from './ElectronicTradingWidgetLoadableFallback.css';

const SPINNER_SIZE = 40;

export const ElectronicTradingWidgetLoadableFallback: React.FC = () => {
  return (
    <div className={styles['spinner-container']} data-testid="electronic-widget-loadable-fallback">
      <Spinner size={SPINNER_SIZE} />
    </div>
  );
};
