import { IAppState } from '../../common/state';
import { FOfferType, FDealType } from '../../utils/category';
import { getDealType } from '../getDealType';
import { getOfferType } from '../getOfferType';
import { selectIsAgent } from '../user';

export const getIsBrokerBannerVisible = (state: IAppState) => {
  const isAgent = selectIsAgent(state);
  const dealType = getDealType(state);

  const offerType = getOfferType(state);
  const isFlatSale = (offerType === FOfferType.FlatNew || offerType === FOfferType.Flat) && dealType === FDealType.Sale;
  const isNewbuildingSnippetUnAvailable = state.newbuildingInformation === null;

  return isAgent && isNewbuildingSnippetUnAvailable && isFlatSale;
};
