import * as React from 'react';

import { EElectronicTradingType } from '../../../../../JsonQuery/types/electronicTradingType';
import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';

interface IElectronicTrading {
  label: string;
  value: EElectronicTradingType | null;
}

const ELECTRONIC_TRADING: IElectronicTrading[] = [
  { label: 'Неважно', value: null },
  { label: 'Не показывать', value: EElectronicTradingType.DoNotDisplay },
  { label: 'Только торги и залоговая недвижимость', value: EElectronicTradingType.ElectronicTradingOnly },
];

export interface IElectronicTradingProps {
  value: EElectronicTradingType | null;
  onChange(value: EElectronicTradingType | null): void;
}

export const ElectronicTrading: React.FC<IElectronicTradingProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_electronicTrading" label="Торги и залоговая недвижимость">
      <RadioButtonGroup spliced={false} options={ELECTRONIC_TRADING} value={value} onChange={onChange} />
    </Filter>
  );
};
