import { NB_CHAT_COMMON_FILTER } from '../../constants/experiments';
import { IAbUseExperiment } from '../../types/abUseExperiments';

export function isChatOnCommonFilterAvailable(experiments: IAbUseExperiment[]) {
  const experiment = experiments.find(e => e.experimentName === NB_CHAT_COMMON_FILTER);

  if (!experiment) {
    return false;
  }

  return experiment.groupName === 'enabled';
}
