import { Button, TooltipDesktop } from '@cian/ui-kit';
import * as React from 'react';

import { IconHide16 } from './components/IconHide16';
import { ControlWrapper } from '../../../../components/ControlWrapper';
import { useOfferContext } from '../../../../context';

export function HideOfferControlContainer() {
  const {
    api: { onHideOffer },
    isHideOfferInProgress,
  } = useOfferContext();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!isHideOfferInProgress) {
      onHideOffer();
    }
  };

  return (
    <ControlWrapper>
      <TooltipDesktop theme="black" placement="left" title="Скрыть">
        <Button
          data-mark="HideOfferControl"
          theme="stroke_secondary"
          onClick={handleClick}
          size="XS"
          loading={isHideOfferInProgress}
          /* TODO заменить иконку на IconHide16 из ui-kit, кода пофиксят багу.
              Сейчас она не работает из-за того, что в ней используется clipPath, в него попадает неуникальный id.
              Поскольку изначально у элемента visibility: hidden, то будет отрисована только одна иконка,
              а все остальные иконки навсегда будут скрыты. Visibility мы не можем убрать из-за анимации.
              Поэтому ждём когда затащат в ui-kit React.useId() для генерации уникальных id иконок.
           */
          beforeIcon={<IconHide16 />}
        />
      </TooltipDesktop>
    </ControlWrapper>
  );
}
