import { pathOr } from 'ramda';

import { IJsonQuery } from '../../json_query';
import { ISearchOffersDesktopResponse } from '../../repositories/search-offers/v2/search-offers-desktop';
import { IOffersData } from '../../types/offersData';

export function prepareOffers(response: ISearchOffersDesktopResponse): IOffersData {
  const offersSerialized = response?.data?.offersSerialized ?? [];

  return {
    ...response.data,
    aggregatedCount: pathOr(0, ['data', 'aggregatedCount'], response),
    avgPriceInformer: pathOr(null, ['data', 'avgPriceInformer'], response),
    breadcrumbs: pathOr([], ['data', 'breadcrumbs'], response),
    collections: pathOr(null, ['data', 'collections', 'items'], response),
    extendedOffersCount: pathOr(0, ['data', 'extendedOffersCount'], response),
    extensionTypes: pathOr([], ['data', 'extensionTypes'], response),
    fullUrl: pathOr('/', ['data', 'fullUrl'], response),
    jsonQuery: response.data.jsonQuery as IJsonQuery,
    mlRankingGuid: pathOr(null, ['data', 'mlRankingGuid'], response),
    mlRankingModelVersion: pathOr(null, ['data', 'mlRankingModelVersion'], response),
    newbuilding: pathOr(null, ['data', 'newbuilding'], response),
    newbuildingIdsForBuildersPromoSlider: pathOr([], ['data', 'newbuildingIdsForBuildersPromoSlider'], response),
    newbuildingPromoBuilderOffers: pathOr(null, ['data', 'specialPromoBuilderOffers'], response),
    newbuildingSimilarOffersBlock: pathOr(null, ['data', 'newbuildingSimilarOffersBlock'], response),
    newbuildingBrokerCommon: pathOr(null, ['data', 'newbuildingBrokerCommon'], response),
    offerCount: pathOr(0, ['data', 'offerCount'], response),
    offersSerialized,
    queryString: pathOr('', ['data', 'queryString'], response),
    searchRequestId: pathOr('', ['data', 'searchRequestId'], response),
    seoData: response.data.seoData as IOffersData['seoData'],
    seoLinks: pathOr(null, ['data', 'seoLinks'], response),
    seoSchemas: pathOr(null, ['data', 'seoSchemas'], response),
    markedList: pathOr(null, ['data', 'markedList'], response),
    suggestOffersSerializedList: pathOr(null, ['data', 'suggestOffersSerializedList'], response),
    suggestionsQuery: pathOr(null, ['data', 'suggestionsQuery'], response),
    topHitsLinks: pathOr(null, ['data', 'topHitsLinks'], response),
    puids: pathOr(null, ['data', 'puids'], response),
  };
}
