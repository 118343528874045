import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackCallAnyway, trackPopupShown, trackWriteMessageClick } from './tracking';
import { openChat, openChatAfterAuth } from '../../actions/chat';
import { closeNotAvailablePopup } from '../../actions/notAvailablePopup';
import { NotAvailablePopup } from '../../components/NotAvailablePopup';
import { isAuthenticatedUserSelector } from '../../selectors/isAuthenticatedUserSelector';
import { selectNotAvailablePopup } from '../../selectors/selectNotAvailablePopup';
import { callAuthenticationPopup } from '../../utils/authentication';

export const NotAvailablePopupContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { offer, isOpened, position } = useSelector(selectNotAvailablePopup);
  const isAuthenticated = useSelector(isAuthenticatedUserSelector);

  const onWriteMessage = React.useCallback(() => {
    if (!offer || !position) {
      return;
    }

    trackWriteMessageClick(offer, position);
    dispatch(closeNotAvailablePopup());

    if (!isAuthenticated) {
      dispatch(openChatAfterAuth({ offer, position }));
      callAuthenticationPopup();

      return;
    }

    dispatch(openChat({ offer, position }));
  }, [offer, dispatch, isAuthenticated, position]);

  const onCallAnyway = React.useCallback(() => {
    if (!offer || !position) {
      return;
    }

    trackCallAnyway(offer, position);
    dispatch(closeNotAvailablePopup());
  }, [offer, dispatch, position]);

  const onClose = React.useCallback(() => {
    dispatch(closeNotAvailablePopup());
  }, [dispatch]);

  const onShow = React.useCallback(() => {
    if (!offer || !position) {
      return;
    }

    trackPopupShown(offer, position);
  }, [offer, position]);

  if (!offer || !isOpened || !offer.user?.agentAvailability) {
    return null;
  }

  const { title, message, available } = offer.user.agentAvailability;

  if (available || !title || !message) {
    return null;
  }

  return (
    <NotAvailablePopup
      onClose={onClose}
      onCallAnyway={onCallAnyway}
      onWriteMessage={onWriteMessage}
      onShow={onShow}
      title={title}
      message={message}
      chatsEnabled={offer.user.isChatsEnabled}
    />
  );
};
