export interface IPhotoLabel {
  color: EPhotoLabelColor;
  text: string;
}

export enum EPhotoLabelColor {
  Grey = 'grey',
  Orange = 'orange',
  Green = 'green',
  Blue = 'blue',
}
