import { ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import { MapFooterContainer } from './footer/container';
import { Map, IMapAreaModels } from '../../../common/components/map/';
import { IJsonQueryGeo } from '../../../json_query';
import { TMapObjectData } from '../../state/map/map_changed';

const style = require('./index.css');

export interface IMapModalProps {
  center: [number, number];
  mapRegions: IJsonQueryGeo;

  close(): void;
  onChanged(data: TMapObjectData[]): void;
  onAreaDeleted(area: TMapObjectData): void;
}

export class MapModal extends React.Component<IMapModalProps> {
  public render() {
    return (
      <ModalWindow
        fixed
        size="M"
        escape
        width="100%"
        maxWidth={1300}
        open
        onClose={this.props.close}
        footer={<MapFooterContainer />}
      >
        <div className={style.mapModal}>
          <Map
            onChange={this.props.onChanged}
            onDelete={this.props.onAreaDeleted}
            areaModels={this.getAreaObjects()}
            center={this.props.center}
          />
        </div>
      </ModalWindow>
    );
  }

  private getAreaObjects = (): IMapAreaModels | null => {
    if (!this.props.mapRegions) {
      return null;
    }

    const areaObjects = this.props.mapRegions.value.reduce(
      (acc: IMapAreaModels, value) => {
        switch (value.type) {
          case 'polygon':
            acc.polygons.push(value);
            break;
          case 'distance':
            acc.circles.push(value);
            break;
          default:
            break;
        }

        return acc;
      },
      {
        polygons: [],
        circles: [],
      },
    );

    return areaObjects;
  };
}
