import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export interface IByHomeOwnerTag {
  type: 'advanced-by-homeowner';
}

function isByHomeownerTag(tag: TTag): tag is IByHomeOwnerTag {
  return tag.type === 'advanced-by-homeowner';
}

export const isByHomeOwnerTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.is_by_homeowner?.value;

    if (value) {
      return ensureSingleTagExists(tags, isByHomeownerTag, () => ({ type: 'advanced-by-homeowner', value }));
    } else {
      return ensureTagNotExists(tags, isByHomeownerTag);
    }
  },
};
