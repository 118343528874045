import { EObjectType } from '../../api-models/common/json_query';
import { LONGTERM_RENT_OPTIONS } from '../constants';
import { getTermValue } from '../helpers';
import { ERentTime, FDealType, TJsonQuery } from '../types';

export const dealTypeFromJsonQuery = (jsonQuery: TJsonQuery): FDealType => {
  const { _type: type } = jsonQuery;

  if (type.endsWith('sale')) {
    return FDealType.Sale;
  }

  if (type.endsWith('rent')) {
    const rentTime = getTermValue('for_day')(jsonQuery);

    if (rentTime !== null && LONGTERM_RENT_OPTIONS.includes(rentTime)) {
      return FDealType.RentLongterm;
    } else if (rentTime === ERentTime.Daily) {
      return FDealType.RentDaily;
    }

    if (isCommercial(jsonQuery)) {
      return FDealType.RentLongterm;
    }

    return FDealType.Rent;
  }

  return FDealType.Unexpected;
};

function isCommercial(jsonQuery: TJsonQuery): boolean {
  return (
    jsonQuery._type.startsWith('commercial') ||
    (jsonQuery._type === 'suburbanrent' &&
      !!jsonQuery.object_type &&
      jsonQuery.object_type.value.includes(EObjectType.Land))
  );
}
