import * as React from 'react';

import { usePageLoaded } from '../../../../hooks/usePageLoaded';

/**
 * Вызывает callback если реклама на сайте отключена/заблокирована.
 * Выполняется после загрузки страницы.
 *
 * @param {Function} callback - Обработчик, вызывается после загрузки страницы, если запуск рекламы на сайте отключен
 * @param {Array>} deps - Список зависимостей, при их обновлении пересоздается обработчик
 * @returns
 */
export function useAdsDisabled(callback: () => void, deps: React.DependencyList) {
  return usePageLoaded(() => {
    if (!window._cianCanRunAds) {
      callback();
    }
  }, deps);
}
