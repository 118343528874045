import * as React from 'react';

import { ERentTime } from '../../../../../JsonQuery';
import { DEFAULT_LONGTERM_RENT_OPTION } from '../../../../../JsonQuery/constants';
import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';

interface IRentalPeriodOption {
  label: string;
  value: ERentTime;
}

const RENTAL_PERIOD_OPTIONS: IRentalPeriodOption[] = [
  { label: 'Неважно', value: DEFAULT_LONGTERM_RENT_OPTION },
  { label: 'От года', value: ERentTime.FromYear },
  { label: 'На несколько месяцев', value: ERentTime.FewMonths },
];

interface IRentalPeriodProps {
  value: ERentTime;
  onChange(value: ERentTime): void;
}

export const RentalPeriod: React.FC<IRentalPeriodProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_rentalPeriod" label="Срок аренды">
      <RadioButtonGroup options={RENTAL_PERIOD_OPTIONS} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
