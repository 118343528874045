import { clone } from 'ramda';

import { setTerm } from './helpers';
import { TJsonQuery } from './types';

export function setIsSubsidisedMortgage(jsonQuery: TJsonQuery) {
  return (isSubsidisedMortgage: boolean | null): TJsonQuery => {
    const nextJsonQuery = clone(jsonQuery);

    return setTerm('is_subsidised_mortgage')(nextJsonQuery)(isSubsidisedMortgage);
  };
}
