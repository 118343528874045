import { EDealType, EOfferType } from './types';
import { IError } from '../../monolith-csharp/api/users/common';
import { IModel, IModelResponse } from '../../types/model';

export const defaultConfig: THideOfferModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'hiddenObjects',
  pathApi: '/v1/hide-offer-website/',
};

export function createHideOfferModel(config: Partial<THideOfferModel>): THideOfferModel {
  return { ...defaultConfig, ...config };
}

export type THideOfferModel = IModel<IHideOfferRequestScheme, THideOfferResponseScheme>;

export interface IHideOfferRequestScheme {
  /** realtyId объявления */
  offerId: number;
  dealType: EDealType;
  offerType: EOfferType;
  publishedUserId: number;
  houseId?: number;
}

export type THideOfferResponseScheme = IHideOfferResponse200 | IHideOfferResponse400;

export interface IHideOfferResponse200 extends IModelResponse<IHideOfferResponseScheme> {
  statusCode: 200;
}

export interface IHideOfferResponseScheme {
  status: string;
}

export interface IHideOfferResponse400 extends IModelResponse<IHideOfferErrorResponseScheme> {
  statusCode: 400;
}

export interface IHideOfferErrorResponseScheme {
  errors: IError[];
  message: string;
}
