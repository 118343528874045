import { ArticleHeading1, ArticleParagraph1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './NewbuildingBlackFridayBanner.css';

interface INewbuildingBlackFridayBannerProps {
  url: string;
  image: string;
  onBannerClick: () => void;
}

export const NewbuildingBlackFridayBanner: React.FC<INewbuildingBlackFridayBannerProps> = ({
  url,
  image,
  onBannerClick,
}) => {
  const backgroundStyle = {
    background: `url(${image}) 100% 0 no-repeat, var(--primary_100)`,
  };

  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className={styles['container']}
      style={backgroundStyle}
      data-testid="NewbuildingBlackFridayBanner"
      onClick={onBannerClick}
    >
      <ArticleHeading1 color="white_100">Скидки на новостройки переехали к нам</ArticleHeading1>
      <div className={styles['description-wrapper']}>
        <ArticleParagraph1 color="white_100">
          Эксклюзивные предложения от застройщиков только до 29 сентября
        </ArticleParagraph1>
      </div>
      <div>
        <button className={styles['button']}>
          <ArticleParagraph1 fontWeight="bold">Подробнее</ArticleParagraph1>
        </button>
      </div>
    </a>
  );
};
