import { IOffer, IOfferAnalyticsInfo } from '../types/offer';

const parseCategory = (offer: IOffer): string => {
  const { dealType, offerType, category } = offer;
  const isNewBuilding = category && category.toLowerCase().includes('newbuilding');

  const getDealType = (): string => {
    if (offerType !== 'flat' || dealType !== 'rent' || !offer.category) {
      return dealType as string;
    }

    return offer.category.toLowerCase().includes('daily') ? 'rent_day' : 'rent_long';
  };

  const getOfferType = (): string => {
    if (offerType === 'flat') {
      return isNewBuilding ? 'flat_new' : 'flat_old';
    }

    return offerType as string;
  };

  return `${getDealType()}/${getOfferType()}`;
};

const parseBrand = (offer: IOffer): string | undefined => {
  const { user, cianUserId, fromDeveloper, isPro } = offer;

  const getAutorType = (): string | undefined => {
    if (fromDeveloper) {
      return 'developer';
    }
    if (isPro) {
      return 'realtor_prof';
    }
    if (user && !user.isAgent) {
      return 'physical';
    }

    return undefined;
  };

  return [cianUserId, getAutorType()].filter(Boolean).join('/') || undefined;
};

const parseVariant = (offer: IOffer): string | undefined => {
  /**
   * Не добавлен в api models, т.к. эксперимент и потом сложно будет выпиливать
   */
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { isAuction, isPremium, isTop3, isStandard } = offer as any;

  const params = [isAuction && 'auction', isPremium && 'premium', isTop3 && 'top3', isStandard && 'standard']
    .filter(Boolean)
    .join('/');

  return params || undefined;
};

const parsePrice = (offer: IOffer): number | undefined => {
  if (offer.bargainTerms && offer.bargainTerms.priceRur) {
    return offer.bargainTerms.priceRur;
  }

  return undefined;
};

export const getAnalyticsInfo = (offer: IOffer): IOfferAnalyticsInfo => {
  return {
    brand: parseBrand(offer),
    category: parseCategory(offer),
    id: offer.id,
    price: parsePrice(offer),
    quantity: 1,
    variant: parseVariant(offer),
  };
};
