import { authenticate } from '@cian/auth';
import { ValidationError } from '@cian/peperrors/shared';
import React from 'react';

import { EAction, IState, TActions } from './types';
import { IJsonQuery } from '../../../json_query';
import {
  EFrequency,
  ESource,
  EStatus,
  fetchWebCreateSavedSearch,
  TWebCreateSavedSearchResponse,
} from '../../../repositories/saved-searches/v1/web-create-saved-search';
import { IApplicationContext } from '../../../types/applicationContext';
import { actionGenerator } from '../../../utils/redux';

export const setEmail = actionGenerator<EAction.SetEmail, string>(EAction.SetEmail);
export const setSubscribeToNews = actionGenerator<EAction.SetSubscribeToNews, boolean>(EAction.SetSubscribeToNews);
export const setStatusLoading = actionGenerator<EAction.SetStatusLoading>(EAction.SetStatusLoading);
export const setStatusSucceed = actionGenerator<EAction.SetStatusSucceed, boolean>(EAction.SetStatusSucceed);
export const setStatusFailed = actionGenerator<EAction.SetStatusFailed, string[]>(EAction.SetStatusFailed);

interface ISubscribeParameters {
  context: IApplicationContext;
  title: string;
  jsonQuery: IJsonQuery;
  state: IState;
  dispatch: React.Dispatch<TActions>;
}

export async function subscribe({
  context: { httpApi, logger },
  title,
  jsonQuery,
  state,
  dispatch,
}: ISubscribeParameters): Promise<void> {
  dispatch(setStatusLoading());

  try {
    const saveSearchResponse: TWebCreateSavedSearchResponse = await fetchWebCreateSavedSearch({
      httpApi,
      parameters: {
        jsonQuery,
        source: ESource.Listing,
        subscribeNews: state.subscribeToNews,
        email: state.email,
        frequency: EFrequency.Daily,
        title,
      },
    });

    if (saveSearchResponse.statusCode !== 200) {
      dispatch(setStatusFailed(saveSearchResponse.response.errors.map(error => error.message)));

      throw new ValidationError({
        message: saveSearchResponse.response.message,
        domain: '',
      });
    }

    const {
      response: { status, logonInfo },
    } = saveSearchResponse;

    if (logonInfo) {
      try {
        await authenticate(logonInfo.logOnInfo, logonInfo.login, true);

        if (window.__reloadHeader__) {
          window.__reloadHeader__();
        }
      } catch (ex) {
        logger.error(ex);
      }
    }

    dispatch(setStatusSucceed(status === EStatus.EmailConfirmationRequired));
  } catch (ex) {
    if (ex instanceof ValidationError) {
      throw ex;
    }

    logger.error(ex);

    dispatch(setStatusFailed(['Извините, произошла неизвестная ошибка. Попробуйте ещё раз']));

    throw ex;
  }
}
