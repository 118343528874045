import { IconActionChevronLeft16, IconActionChevronRight16 } from '@cian/ui-kit-design-tokens/icons';
import classNames from 'classnames';
import * as React from 'react';

import * as styles from './styles.css';

type TDirection = -1 | 1;

interface IArrowProps {
  direction: TDirection;
}

export const Arrow = React.forwardRef(({ direction }: IArrowProps, ref: React.Ref<HTMLDivElement>) => {
  return (
    <div
      data-testid="newbuilding_promo_carousel_arrow"
      ref={ref}
      className={classNames(styles['click_area'], direction < 0 && styles['click_area--left'])}
    >
      <div className={styles['background']}>
        <div className={styles['icon_container']}>
          <div className={styles['icon']}>
            {direction < 0 ? (
              <IconActionChevronLeft16 data-testid="icon_arrow_left" color={'gray_icons_100'} />
            ) : (
              <IconActionChevronRight16 data-testid="icon_arrow_right" color={'gray_icons_100'} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

Arrow.displayName = 'Arrow';
