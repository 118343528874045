/**
 * https://github.com/josdejong/mathjs/blob/82074e85e02e14472464991b6f2b0ad78747ba95/src/utils/number.js
 */
export function round(value: number, decimals: number) {
  return parseFloat(toFixed(value, decimals));
}

function toFixed(value: number, precision: number): string {
  if (isNaN(value) || !isFinite(value)) {
    return String(value);
  }

  const splitValue = splitNumber(value);
  const rounded =
    typeof precision === 'number' ? roundDigits(splitValue, splitValue.exponent + 1 + precision) : splitValue;
  let c: Array<number | string> = rounded.coefficients;
  let p = rounded.exponent + 1;

  const pp = p + (precision || 0);
  if (c.length < pp) {
    c = c.concat(zeros(pp - c.length));
  }

  if (p < 0) {
    c = (zeros(-p + 1) as Array<number | string>).concat(c);
    p = 1;
  }

  if (p < c.length) {
    c.splice(p, 0, p === 0 ? '0.' : '.');
  }

  return rounded.sign + c.join('');
}

interface ISplitValue {
  sign: '+' | '-' | '';
  coefficients: number[];
  exponent: number;
}

function splitNumber(value: number): ISplitValue {
  const match = String(value)
    .toLowerCase()
    .match(/^(-?)(\d+\.?\d*)(e([+-]?\d+))?$/);
  if (!match) {
    throw new SyntaxError('Invalid number ' + value);
  }

  const sign = match[1] as ISplitValue['sign'];
  const digits = match[2];
  let exponent = parseFloat(match[4] || '0');

  const dot = digits.indexOf('.');
  exponent += dot !== -1 ? dot - 1 : digits.length - 1;

  const coefficients = digits
    .replace('.', '')
    .replace(/^0*/, function (zeros) {
      exponent -= zeros.length;

      return '';
    })
    .replace(/0*$/, '')
    .split('')
    .map(d => {
      return parseInt(d, 10);
    });

  if (coefficients.length === 0) {
    coefficients.push(0);
    exponent++;
  }

  return {
    sign,
    coefficients,
    exponent,
  };
}

function roundDigits(split: ISplitValue, precision: number): ISplitValue {
  const rounded = {
    sign: split.sign,
    coefficients: split.coefficients,
    exponent: split.exponent,
  };
  const c = rounded.coefficients;

  while (precision <= 0) {
    c.unshift(0);
    rounded.exponent++;
    // eslint-disable-next-line no-param-reassign
    precision++;
  }

  if (c.length > precision) {
    const removed = c.splice(precision, c.length - precision);

    if (removed[0] >= 5) {
      let i = precision - 1;
      c[i]++;
      while (c[i] === 10) {
        c.pop();
        if (i === 0) {
          c.unshift(0);
          rounded.exponent++;
          i++;
        }
        i--;
        c[i]++;
      }
    }
  }

  return rounded;
}

function zeros(length: number): number[] {
  const arr = [];
  for (let i = 0; i < length; i++) {
    arr.push(0);
  }

  return arr;
}
