import { ca } from '@cian/analytics';

export const trackTooltipHover = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'RentContract',
    action: 'Click_sopr',
    label: `LabelOnlineRent`,
    useLastProducts: true,
  });
};

export const trackModalShow = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'RentContract',
    action: 'ShowPopup',
    useLastProducts: true,
  });
};

export const trackLinkClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'RentContract',
    action: 'Click_sopr',
    label: `GoToDetails`,
    useLastProducts: true,
  });
};
