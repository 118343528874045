import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const ISuburbanHouseTypeTagType = 'advanced-suburbanHouseType';

export interface ISuburbanHouseTypeTag {
  type: 'advanced-suburbanHouseType';
  value: boolean;
}

export const isSuburbanHouseTypeTag = (tag: TTag): tag is ISuburbanHouseTypeTag =>
  tag.type === ISuburbanHouseTypeTagType;

export const suburbanHouseTypeTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.is_dacha && jsonQuery.is_dacha.value;

    if (typeof value === 'boolean') {
      return ensureSingleTagExists(tags, isSuburbanHouseTypeTag, () => ({ type: ISuburbanHouseTypeTagType, value }));
    } else {
      return ensureTagNotExists(tags, isSuburbanHouseTypeTag);
    }
  },
};
