import classNames from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';

import * as styles from './BannerServicePlaceFallback.css';
import mortgageIcon from './images/mortgage.svg';
import { mortgageDataFlatRatesSelector } from '../../../../selectors/mortgageData';

export type BannerServicePlaceFallbackProps = {
  position: number;
  className?: string;
};

export const BannerServicePlaceFallback: React.VFC<BannerServicePlaceFallbackProps> = ({ position, className }) => {
  const rates = useSelector(mortgageDataFlatRatesSelector);

  if (!rates) {
    return null;
  }

  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={`https://cian.ru/ipoteka-main?banner=servisnoe_mesto_oldstyle_${position}`}
      className={classNames(styles['wrapper'], className)}
    >
      <div className={styles['container']}>
        <img className={styles['logo']} src={mortgageIcon} alt="Циан.Ипотека" />
        <div className={styles['content']}>
          <h3 className={styles['title']}>Выгодные ставки по ипотеке</h3>
          <p className={styles['text']}>
            {rates.newBuilding}% на новостройки и {rates.used}% на вторичку
          </p>
          <button className={styles['button']}>Узнать свои ставки</button>
        </div>
      </div>
    </a>
  );
};
