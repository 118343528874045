import { getDepositPrice } from './getDepositPrice';
import { getFeePrice } from './getFeePrice';
import { getLeasePeriod } from './getLeasePeriod';
import { getUtilitiesTermsPrice } from './getUtilitiesTermsPrice';
import { IOffer } from '../../../../types/offer';
import { getAgentBonusPrice } from '../getAgentBonusPrice';

export function getRentPriceInfo(offer: IOffer, isAgent: boolean): string | undefined {
  const infoParts = [
    getLeasePeriod(offer),
    getUtilitiesTermsPrice(offer),
    getFeePrice(offer, isAgent),
    getDepositPrice(offer),
    getAgentBonusPrice(offer, isAgent),
  ].filter(Boolean);

  if (offer.dailyrent?.hotelData?.isHotel) {
    return offer.dailyrent.hotelData.priceSubtitle;
  }

  if (infoParts.length > 0) {
    const res = infoParts.join(', ');

    return `${res[0].toUpperCase()}${res.slice(1)}`;
  }

  return undefined;
}
