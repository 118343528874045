import React from 'react';

import { KPAdvantages } from '../../../components/KPAdvantages';
import { useOfferContext } from '../../../context';

export function KPAdvantagesContainer() {
  const { kp } = useOfferContext();

  if (!kp?.villageAdvantages) {
    return null;
  }

  return <KPAdvantages items={kp.villageAdvantages} />;
}
