import { ISelectStyles } from '../../types';

export const getInlineStyles = (style?: ISelectStyles) => {
  let styles = {};

  if (style === undefined) {
    return undefined;
  }

  if (style.width !== undefined) {
    styles = {
      ...styles,
      width: style.width,
      maxWidth: style.width,
    };
  }

  return styles;
};
