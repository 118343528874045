import { ca } from '@cian/analytics';

export function trackQuickLinksView(level: number) {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: `view_alsoLooking_${level}`,
    label: '',
  });
}

export function trackQuickLinkClick(level: number, url: string) {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: `click_alsoLooking_${level}`,
    label: url,
  });
}
