import * as React from 'react';

import { EHeatingType } from '../../../../json_query';
import { IHeatingTypesTag } from '../../../state/tags/definitions/heating';
import { TagNew } from '../TagNew';

export interface IHeatingTypesTagItemProps {
  tag: IHeatingTypesTag;
  onDeleteClick(): void;
}

const heatingTypeMap = {
  [EHeatingType.Furnace]: 'Печь',
  [EHeatingType.Fireplace]: 'Камин',
  [EHeatingType.None]: 'Без отопления',
  [EHeatingType.Gas]: 'Центральное газовое',
  [EHeatingType.Coal]: 'Угольное отопление',
  [EHeatingType.Electric]: 'Электрическое',
  [EHeatingType.AutonomousGas]: 'Автономное газовое',
  [EHeatingType.Diesel]: 'Дизельное',
  [EHeatingType.SolidFuelBoiler]: 'Твердотопливный котел',
};

export const HeatingTypesTagItem = ({ onDeleteClick, tag }: IHeatingTypesTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>{heatingTypeMap[tag.value]}</TagNew>;
};
