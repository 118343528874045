import * as React from 'react';

import { ISelectActions } from '../types';

interface IMultiSelectProcessKeyboardOptions {
  open: boolean;
  actions: ISelectActions;
}

function handleSpace(e: React.KeyboardEvent, options: IMultiSelectProcessKeyboardOptions) {
  e.preventDefault();

  if (options.open) {
    options.actions.toggleFocusedOptionState();
  } else {
    options.actions.openDropdown();
  }
}

function handleEnter(e: React.KeyboardEvent, options: IMultiSelectProcessKeyboardOptions) {
  if (options.open) {
    e.preventDefault();

    options.actions.setFocusedOptionState(true);
    options.actions.closeDropdown();
  }
}

function handleEscape(e: React.KeyboardEvent, options: IMultiSelectProcessKeyboardOptions) {
  if (options.open) {
    options.actions.closeDropdown();
  }
}

function handleVerticalArrow(e: React.KeyboardEvent, options: IMultiSelectProcessKeyboardOptions) {
  e.preventDefault();

  if (options.open) {
    const delta = e.nativeEvent.key === 'ArrowUp' ? -1 : 1;

    options.actions.moveFocus(delta);
  } else {
    options.actions.openDropdown();
  }
}

function handleTab(e: React.KeyboardEvent, options: IMultiSelectProcessKeyboardOptions) {
  if (options.open) {
    e.preventDefault();

    options.actions.closeDropdown();
  }
}

export function processMultiSelectKeyboardEvent(e: React.KeyboardEvent, options: IMultiSelectProcessKeyboardOptions) {
  switch (e.nativeEvent.key) {
    case ' ':
      return handleSpace(e, options);

    case 'Tab':
      return handleTab(e, options);

    case 'Enter':
      return handleEnter(e, options);

    case 'Escape':
      return handleEscape(e, options);

    case 'ArrowUp':
    case 'ArrowDown':
      return handleVerticalArrow(e, options);
  }
}
