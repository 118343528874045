import { IJsonQuery, jq } from '../../json_query';
import { IApplicationContext } from '../../types/applicationContext';
import { fetchSeoUrlsFromJsonQueriesSafe } from '../fetchSeoUrlsFromJsonQueries';

interface IFetchSeoUrlsForFirstTwoPagesParameters
  extends Omit<Parameters<typeof fetchSeoUrlsFromJsonQueriesSafe>[1], 'jsonQueries'> {
  jsonQuery: IJsonQuery;
}

export async function fetchSeoUrlsForFirstTwoPages(
  context: IApplicationContext,
  { subdomain, jsonQuery }: IFetchSeoUrlsForFirstTwoPagesParameters,
) {
  const jsonQueries = [1, 2].map(pageNumber => jq(jsonQuery).setPageNumber(pageNumber));

  return (await fetchSeoUrlsFromJsonQueriesSafe(context, { subdomain, jsonQueries })) || [];
}
