import { underground } from './underground';
import { TComputedPropMapper } from '../../types/computed-props-mapper';
import { IGeoLabel } from '../../types/geo';
import { ELocationTypeId } from '../../types/offer';

export const geoLabels: TComputedPropMapper<IGeoLabel[]> = (props, ctx) => {
  const undergroundStation = underground(props, ctx);
  const { offer, qsToUris } = props;

  if (!offer.geo) {
    return [];
  }

  const isGeoAddressWithMetro = offer.geo.address.find(({ type }) => type === 'metro');

  const cityIndex = offer.geo.address.reduce(
    (cIndex, { locationTypeId }, index) => (locationTypeId === ELocationTypeId.City ? index + 1 : cIndex),
    1,
  );

  const geoLabels = offer.geo.address.map(
    ({ fullName, qs, type }) =>
      ({
        label: fullName,
        type: type === 'metro' ? 'metro' : 'address',
        url: (qsToUris && qsToUris[qs]) || (qs && `/cat.php?${qs}`) || undefined,
      }) as IGeoLabel,
  );

  /**
   * Если есть underground, но нет метро внутри geo.address,
   * то добавляем метро вручную тут
   */
  if (undergroundStation && !isGeoAddressWithMetro) {
    geoLabels.splice(cityIndex, 0, {
      label: `м.\u00A0${undergroundStation.name}`,
      type: 'metro',
      url:
        (undergroundStation.qs &&
          ((qsToUris && qsToUris[undergroundStation.qs]) || `/cat.php?${undergroundStation.qs}`)) ||
        undefined,
    });
  }

  return geoLabels;
};
