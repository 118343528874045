import * as React from 'react';

import { usePagination } from './usePagination';
import { Pagination } from '../../components/Pagination';

export function PaginationContainer() {
  const { onPageSelect, pages, nextPage, prevPage } = usePagination();

  const handleClickPagination = React.useCallback(
    (e: React.SyntheticEvent<HTMLAnchorElement>, page: number) => {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: 'smooth' });
      onPageSelect(page);
    },
    [onPageSelect],
  );

  if (pages.length === 0) {
    return null;
  }

  return <Pagination nextButton={nextPage} prevButton={prevPage} pages={pages} onClick={handleClickPagination} />;
}
