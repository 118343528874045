import { TooltipDesktop, UIText2 } from '@cian/ui-kit';
import classNames from 'classnames';
import * as React from 'react';

import disclaimerIcon from './assets/disclaimerIcon.svg';
import * as style from './mortgage.css';
import { IWithTrackingProps } from '../../../../../../serp/hoc/withTracking';
import { trackAdFoxMortgage } from '../../../../../../tracking/adFoxMortgage';

export interface IAdFoxBannerContext {
  [key: string]: string;
}

export interface IMortgageSimpleBannerOwnProps {
  context: IAdFoxBannerContext;
}

export type IMortgageSimpleBannerStateProps = IWithTrackingProps;

export type TMortgageSimpleBannerProps = IMortgageSimpleBannerStateProps & IMortgageSimpleBannerOwnProps;

export class MortgageSimpleBanner extends React.PureComponent<TMortgageSimpleBannerProps> {
  public static shownBannerIds: string[] = [];

  public componentDidMount(): void {
    this.track(true);
  }

  public render() {
    const {
      context: { reference, text, label, annotation, withReference },
    } = this.props;

    return (
      <div className={style['root']}>
        {withReference === '1' ? (
          <span className={classNames(style['link'])}>{text}</span>
        ) : (
          <a
            data-mark="MortgageSimpleBannerLink"
            href={reference}
            target={'_blank'}
            className={classNames(style['link'])}
            onClick={this.onClick}
            rel="noreferrer"
          >
            {text}
          </a>
        )}
        {label && !annotation && <div className={style['label']}>{label}</div>}
        {annotation && (
          <TooltipDesktop
            title={<UIText2 color="gray40_100">{annotation}</UIText2>}
            placement={'bottom'}
            theme={'white'}
          >
            {label ? (
              <div className={style['label']}>{label}</div>
            ) : (
              <img className={style['info']} src={disclaimerIcon} alt={'info_icon'} />
            )}
          </TooltipDesktop>
        )}
      </div>
    );
  }

  private onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    this.track(false);
  };

  private track = (isShow: boolean) => {
    const {
      tracking: { user, page },
      context: { bannerId },
    } = this.props;

    // Костыль, на время, пока не исправится ПОЛНАЯ перерисовка всего листинга
    // при каждом чихе (даже открытии какого-то попапа в карточке). Каждый
    // раз вызывается componentDidMount, потому что по какой-то причине весь
    // листинг реинициализируется. Таким образом мы избавляемся от логической
    // ошибки, когда в реалии трекинг ссылки должен срабатывать один раз
    // при ее показе, но срабатывает КАЖДЫЙ, когда перерисовывается вся
    // страница.
    if (isShow) {
      if (MortgageSimpleBanner.shownBannerIds.includes(bannerId)) {
        return;
      }
      MortgageSimpleBanner.shownBannerIds.push(bannerId);
    }

    trackAdFoxMortgage(isShow ? 'show' : 'click', 'LinkIpoteka', bannerId, user, page);
  };
}
