import { IModel, IModelResponse } from '../../../types/model';

export type TAddFeedbackModel = IModel<TAddFeedbackModelRequestSchema, TAddFeedbackModelResponseSchema>;

const defaultConfig: TAddFeedbackModel = {
  apiType: 'public',
  assertStatusCodes: [204, 400],
  method: 'POST',
  microserviceName: 'moderationComplaints',
  pathApi: '/v1/add-feedback-request/',
};

export function createModerationComplaintsModel(config: Partial<TAddFeedbackModel>): TAddFeedbackModel {
  return {
    ...defaultConfig,
    ...config,
  };
}

export interface IAddFeedbackModelRequest {
  email: string;
  complaintId: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAddFeedbackModelResponse {}

export interface IAddFeedbackModelErrorResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any[];
  message: string;
}

export interface IAddFeedbackModelResponse204 extends IModelResponse<IAddFeedbackModelResponse> {
  statusCode: 204;
}

export interface IAddFeedbackModelResponse400 extends IModelResponse<IAddFeedbackModelErrorResponse> {
  statusCode: 400;
}

export type TAddFeedbackModelRequestSchema = IAddFeedbackModelRequest;
export type TAddFeedbackModelResponseSchema = IAddFeedbackModelResponse204 | IAddFeedbackModelResponse400;
