import { IJsonQueryNewbuildingHouse, TGeoValue } from '../../../packages/JsonQuery';
import { IAppState } from '../../common/state';

export const getActiveHouses = (state: IAppState) => {
  const geo = state.filters.jsonQuery.geo?.value || [];

  const houses = geo.filter<IJsonQueryNewbuildingHouse>(filterNewbuildingHouse);

  return houses.map(item => item.id);
};

function filterNewbuildingHouse(item: TGeoValue): item is IJsonQueryNewbuildingHouse {
  return item.type === 'nb_house_key';
}
