import { IJsonQueryTerms } from '../../../api-models/common/json_query';

export enum EDecorationType {
  /**
   * Чистовая с мебелью
   */
  FineWithFurniture = 'fineWithFurniture',

  /**
   * Чистовая
   */
  Fine = 'fine',

  /**
   * Предчистовая
   */
  PreFine = 'preFine',

  /**
   * Без отделки
   */
  Without = 'without',
}

export type TDecorationsListTerms = { decorations_list?: IJsonQueryTerms<EDecorationType> };
