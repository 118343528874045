import * as React from 'react';

import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';

interface ISuburbanHouseType {
  label: string;
  value: boolean | null;
}

const SUBURBAN_HOUSE_TYPES: ISuburbanHouseType[] = [
  { label: 'Неважно', value: null },
  { label: 'Для постоянного проживания', value: false },
  { label: 'Дача', value: true },
];

interface ISuburbanHouseTypeProps {
  value: boolean | null;
  onChange(value: boolean | null): void;
}

export const SuburbanHouseType: React.VFC<ISuburbanHouseTypeProps> = ({ value, onChange }) => {
  return (
    <Filter id="filter_suburbanHouseType" label="Тип дома">
      <RadioButtonGroup options={SUBURBAN_HOUSE_TYPES} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
