import * as React from 'react';

import { FDealType } from '../../../../../../JsonQuery';
import { PostfixPopupLink } from '../../../../components/Select';
import { useContext } from '../../../../utils/useContext';
import { useDealType } from '../../../../utils/useDealType';
import { getCommercialLink } from '../utils/getCommercialLink';

export function OfferTypePopupPostfix() {
  const { jsonQuery } = useContext();
  const dealType = useDealType();
  const [commercialLink, setCommercialLink] = React.useState('#');

  React.useEffect(() => {
    setCommercialLink(getCommercialLink(jsonQuery.region?.value[0]));
  }, [jsonQuery]);

  if (dealType === FDealType.RentDaily) {
    return null;
  }

  return <PostfixPopupLink href={commercialLink} text="Коммерческая" />;
}
