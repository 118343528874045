import React from 'react';

import { HeaderContainer } from '../../serp/components/header/container';
import { SummaryContainer } from '../../serp/containers/SummaryContainer';

export function HeaderSectionContainer() {
  return (
    <>
      <HeaderContainer />
      <SummaryContainer />
    </>
  );
}
