import * as React from 'react';

import { TradeRealtyTooltipContent } from '../../../components/TradeRealtyTooltipContent';
import { ELabelColor, ILabel } from '../../../types/label';

export function getTradeRealtyLabel(): ILabel {
  return {
    tooltip: {
      title: '',
      description: <TradeRealtyTooltipContent />,
    },
    text: 'Аукцион',
    color: ELabelColor.Gray,
  };
}
