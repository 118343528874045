import { TAdFoxBannerTemplate } from '@cian/adfox-component';
import { TTgbNativedescGeneralContext } from '@cian/adfox-templates';
import { Base64 } from 'js-base64';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { isSuburban as getIsSuburban, offerTypeFromJsonQuery } from '../../../../../../packages/JsonQuery';
import { useSelectIsNewbuildingMartechNewDesignTgbListingExperimentEnabled } from '../../../../../selectors/experiments/useSelectIsNewbuildingMartechNewDesignTgbListingExperimentEnabled';
import { selectJsonQuery } from '../../../../../selectors/results';
import { IMortgageBannerContext, INewbuildingSpecialProjectBannerContext } from '../../../../../types/adfox';
import { useApplicationContext } from '../../../../../utils/applicationContext';
import { getIsNewbuildingFromJsonQuery } from '../../../../../utils/getIsNewbuildingFromJsonQuery';
import { AdFoxBannerTrackerContainer } from '../AdFoxBannerTracker';
import { TAdFoxBannerTrackerProps } from '../AdFoxBannerTracker/AdFoxBannerTracker';
import { NativeTGBBannerContainer } from '../templates/NativeTGBBanner';
import { NewbuildingSpecialProjectContainer } from '../templates/NewbuildingSpecialProject';
import { TgbNativedescGeneralContainer } from '../templates/TgbNativedescGeneral';
import { MortgageSimpleBannerContainer } from '../templates/mortgage';

const OWN_BUNDLE_NAMES = ['banner.transfer'];
const OWN_TYPES: Array<BannerContext['type']> = [
  'mortgage_banner_new',
  'special_project_banner',
  'tgb_nativedesc_general',
];

export interface IAdFoxTemplateParams {
  type: string;
}

type BannerContext = IMortgageBannerContext | INewbuildingSpecialProjectBannerContext | TTgbNativedescGeneralContext;

export type TAdFoxTemplatesParams = TAdFoxBannerTrackerProps & IAdFoxTemplateParams & BannerContext;

export const OwnTemplates: React.FC<TAdFoxBannerTemplate> = props => {
  const ctx = useApplicationContext();
  const { bundleName, bundleParams } = props;

  const jsonQuery = useSelector(selectJsonQuery);

  /**
   * @todo Удалить переменную с экспериментом
   * @description Данный функционал появился в задаче CD-206850, будет удалён в задаче CD-206973
   */
  const isNewbuildingMartechNewDesignTgbListingExperimentEnabled =
    useSelectIsNewbuildingMartechNewDesignTgbListingExperimentEnabled();

  if (bundleName && OWN_BUNDLE_NAMES.includes(bundleName) && bundleParams) {
    if (bundleName === 'banner.transfer' && bundleParams.htmlBase64) {
      try {
        const decodedParams = Base64.decode(bundleParams.htmlBase64);
        const parsedDecodedParams = JSON.parse(decodedParams);

        if (isParsedParamsValid(parsedDecodedParams)) {
          if (OWN_TYPES.includes(parsedDecodedParams.type)) {
            switch (parsedDecodedParams.type) {
              case 'special_project_banner':
                return (
                  <NewbuildingSpecialProjectContainer
                    context={{
                      ...parsedDecodedParams,
                      campaignId: bundleParams.campaignId,
                      bannerId: bundleParams.bannerId,
                    }}
                  />
                );
              case 'mortgage_banner_new': {
                const context = {
                  text: parsedDecodedParams.text,
                  reference: parsedDecodedParams.url,
                  label: parsedDecodedParams.label,
                  annotation: parsedDecodedParams.annotation,
                  withReference: parsedDecodedParams.withReference,
                };

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return <MortgageSimpleBannerContainer context={context as any} />;
              }

              case 'tgb_nativedesc_general': {
                const isNewbuilding = getIsNewbuildingFromJsonQuery(jsonQuery);
                const isSuburban = getIsSuburban(offerTypeFromJsonQuery(jsonQuery));

                /**
                 * @todo Удалить логику с экспериментом
                 * @description Данный функционал появился в задаче CD-206850, будет удалён в задаче CD-206973
                 * Если эксперимент будет удачным, то можно удалять весь компонент NativeTGBBannerContainer
                 * и сопутствующие артефакты, та же само ветвление будет не нужным. Но тут есть один момент,
                 * нужно у Сергея Юрикова уточнить, раскатываем ли мы новый дизайн на загородку или нет.
                 * Если раскатываем, то смело удаляем NativeTGBBannerContainer и всё с ним связанное. Если не
                 * раскатываем новый дизайн на загородку, то нужно будет сохранить NativeTGBBannerContainer и тогда
                 * условие будет таким, если isSuburban, то тогда будет старый дизайн, то есть возвращаем
                 * NativeTGBBannerContainer, если другое, то уже возвращаем TgbNativedescGeneralContainer.
                 * В любом случае нужно будет уточнить, что делаем у Сергея Юрикова, без этого вообще не раскатывать
                 *
                 * Если эксперимент будет не удачным, то из условия нужно будет удалить
                 * isNewbuildingMartechNewDesignTgbListingExperimentEnabled
                 */
                if (!isSuburban && (isNewbuilding || isNewbuildingMartechNewDesignTgbListingExperimentEnabled)) {
                  return <TgbNativedescGeneralContainer context={parsedDecodedParams} />;
                }

                return (
                  <NativeTGBBannerContainer
                    context={parsedDecodedParams}
                    campaignId={bundleParams.campaignId}
                    bannerId={bundleParams.bannerId}
                  />
                );
              }
            }
          }
        }
      } catch (e) {
        ctx.logger.error(e, {
          message: '[AdFox] Failed to decode/parse bundleParams',
          bannerId: props.bundleParams?.bannerId,
        });
      }
    }
  }

  return <AdFoxBannerTrackerContainer {...props} />;
};

function isParsedParamsValid(params: object): params is TAdFoxTemplatesParams {
  return 'type' in params;
}
