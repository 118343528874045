import * as React from 'react';

import { EMaxPrepay } from '../../../../json_query';
import { IMaxPrepayTag } from '../../../state/tags/definitions/max_prepay';
import { TagNew } from '../TagNew';

export interface IMaxPrepayTagItemProps {
  tag: IMaxPrepayTag;
  onDeleteClick(): void;
}

const maxPrepayMap = {
  [EMaxPrepay.Month]: 'не более месяца',
  [EMaxPrepay.TwoMonths]: 'не более 2 месяцев',
  [EMaxPrepay.ThreeMonths]: 'не более 3 месяцев',
};

export const MaxPrepayTagItem = ({ tag, onDeleteClick }: IMaxPrepayTagItemProps) => {
  if (tag.value === EMaxPrepay.Any) {
    return null;
  }

  return <TagNew onDeleteClick={onDeleteClick}>Предоплата: {maxPrepayMap[tag.value]}</TagNew>;
};
