import { FavoritesAssignWidgetContainer } from '@cian/favorites-assign-widget';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { FavoritesFolders } from '../../components/FavoritesFolders';
import { getIsFavoritesFoldersEnabled } from '../../selectors';
import { getOffers } from '../../selectors/offers';
import { getUser } from '../../selectors/user';
import {
  trackCreateFolder,
  trackFolderNameClick,
  trackNotificationChangeFolder,
  trackNotificationSaveToFolder,
  trackSaveToFolder,
} from '../../serp/components/offers/tracking';
import { getPosition } from '../../utils/offer/getPosition';

export function FavoritesFoldersContainer() {
  const offers = useSelector(getOffers);
  const isFeatureEnabled = useSelector(getIsFavoritesFoldersEnabled);
  const { isAuthenticated } = useSelector(getUser);

  const handleSaveToFolder = React.useCallback(
    (offerId: number) => {
      const position = getPosition(offerId, offers);
      trackNotificationSaveToFolder({ offerId, position });
    },
    [offers],
  );

  const handleChangeFolder = React.useCallback(
    (offerId: number) => {
      const position = getPosition(offerId, offers);
      trackNotificationChangeFolder({ offerId, position });
    },
    [offers],
  );

  if (!isFeatureEnabled) {
    return null;
  }

  const handleCreate = (name: string, offerId: number) => {
    const position = getPosition(offerId, offers);
    trackCreateFolder({ offerId, position });
  };

  const handleSave = (offerId: number) => {
    const position = getPosition(offerId, offers);
    trackSaveToFolder({ offerId, position });
  };

  const handleClickFolderName = (offerId: number, folderId: number) => {
    const position = getPosition(offerId, offers);
    trackFolderNameClick({ offerId, folderId, position });
  };

  const handleLogin = () => {
    if (window.waitForLoginPopup) {
      window.waitForLoginPopup('favorites');
    }
  };

  return (
    <FavoritesFolders>
      <FavoritesAssignWidgetContainer
        isAuthenticated={isAuthenticated}
        disableNotification={true}
        onCreateFolder={handleCreate}
        onSaveOffer={handleSave}
        onWaitForLogin={handleLogin}
        onClickFolderName={handleClickFolderName}
        onNotificationSaveToFolder={handleSaveToFolder}
        onNotificationChangeFolder={handleChangeFolder}
      />
    </FavoritesFolders>
  );
}
