import * as React from 'react';

import { IApartmentTag } from '../../../state/tags/definitions/apartment';
import { TagNew } from '../TagNew';

export interface IApartmentTagItemProps {
  tag: IApartmentTag;
  onDeleteClick(): void;
}

export const ApartmentTagItem = ({ tag, onDeleteClick }: IApartmentTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>{tag.value ? 'Только апартаменты' : 'Без апартаментов'}</TagNew>;
};
