import * as React from 'react';

import * as style from './index.css';
import { AdfoxContextData } from '../../types/adfox/AdfoxContextData';
import { AdfoxContextProvider, PParams } from '../../utils/AdfoxContext';
import { AdFoxBanner } from '../AdfoxBanner';

const POST_VIEW_ANALYTICS_DATA: AdfoxContextData['postViewAnalyticsData'] = {
  banner: {
    place: 'footerSerp',
    name: 'Listing_parallax',
  },
};
const P_PARAMS: PParams = { p1: 'dbtyp', p2: 'y' };
const SIZE: AdfoxContextData['size'] = { width: '100%', minHeight: '500px' };

export const SeoTopBanners: React.FC = () => {
  return (
    <div className={style['container']} data-name="SeoTopBanners">
      <AdfoxContextProvider size={SIZE} pParams={P_PARAMS} postViewAnalyticsData={POST_VIEW_ANALYTICS_DATA}>
        <AdFoxBanner position={null} />
      </AdfoxContextProvider>
    </div>
  );
};
