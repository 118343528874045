import { ca } from '@cian/analytics';

export function trackOpenChatbot() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'chat_bot',
    label: 'open',
    page: {
      pageType: 'Listing',
      siteType: 'desktop',
      extra: {
        chat_id: 'chatbot',
      },
    },
  });
}
