import * as React from 'react';

import { IFlatShareTag } from '../../../state/tags/definitions/flat_share';
import { TagNew } from '../TagNew';

export interface IFlatShareTagItemProps {
  tag: IFlatShareTag;
  onDeleteClick(): void;
}

export const FlatShareTagItem = ({ tag, onDeleteClick }: IFlatShareTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>{tag.value === 1 ? 'Только доли' : 'Без долей'}</TagNew>;
};
