import { EElectronicTradingType } from '../../../../../packages/JsonQuery/types/electronicTradingType';
import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const IElectronicTradingTypeTagType = 'advanced-electronicTradingType';

export interface IElectronicTradingTag {
  type: 'advanced-electronicTradingType';
  value: EElectronicTradingType;
}

export const isElectronicTradingTypeTag = (tag: TTag): tag is IElectronicTradingTag =>
  tag.type === IElectronicTradingTypeTagType;

export const electronicTradingTypeTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.electronic_trading?.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isElectronicTradingTypeTag, () => ({
        type: IElectronicTradingTypeTagType,
        value,
      }));
    } else {
      return ensureTagNotExists(tags, isElectronicTradingTypeTag);
    }
  },
};
