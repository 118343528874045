import { IAppState, TActions, Dispatch } from '../../../common/state';
import { ISearchRequestStartRequestedAction } from '../search';

export interface IAdvancedApplyAction {
  type: 'filters/advanced/APPLY';
}

export function applyAdvanced() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: 'filters/advanced/APPLY',
    } as IAdvancedApplyAction);

    dispatch({
      type: 'filters/search/SEARCH_REQUEST_START_REQUESTED',
    } as ISearchRequestStartRequestedAction);
  };
}

export function advancedApplyReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/advanced/APPLY':
      return {
        ...state,
        filters: {
          ...state.filters,
          backup: undefined,
        },
        isAdvancedModalVisible: false,
      };
    default:
      return state;
  }
}
