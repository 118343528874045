import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';

import * as styles from './styles.css';
import { trackLinkClick, trackModalShow } from './tracking';
import { Link } from '../Link';

const ONLINE_RENTAL_AGREEMENT_LINK = '/rental-online-agreement';

export const OnlineRentalAgreementTooltipContent: React.FC = () => {
  return (
    <ActionAfterViewObserver callback={trackModalShow} callbackDelay={1500}>
      <>
        Собственник готов подписать договор <br />
        аренды онлайн через Госуслуги
        <div className={styles['link']}>
          <Link
            text="Подробнее о сервисе"
            target="_blank"
            href={ONLINE_RENTAL_AGREEMENT_LINK}
            onClick={trackLinkClick}
          />
        </div>
      </>
    </ActionAfterViewObserver>
  );
};
