import { useEventCallback } from '@cian/react-utils';
import * as React from 'react';

import { getOptionsByOfferType } from './utils/getOptionsByOfferType';
import { FOfferType, NonEmptyArray } from '../../../../../JsonQuery';
import { EOfferSeller } from '../../../types/jsonQuery';
import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';

interface ILandStatusProps {
  value: NonEmptyArray<EOfferSeller> | null;
  offerType: FOfferType;
  onChange(value: NonEmptyArray<EOfferSeller> | null): void;
}

export const OfferSeller: React.FC<ILandStatusProps> = ({ value, onChange, offerType }) => {
  const options = getOptionsByOfferType(offerType);
  const optionsCountWithoutNone = React.useMemo(() => options.length - 1, [options.length]);

  const handleRadioButtonChange = useEventCallback((newValue: EOfferSeller | null) => {
    onChange(newValue ? [newValue] : null);
  });

  return (
    <Filter id="advancedFilter_offerSeller" label="Продавец">
      {optionsCountWithoutNone > 2 ? (
        <CheckboxButtonGroupUnselectable options={options} value={value} onChange={onChange} />
      ) : (
        <RadioButtonGroup
          options={options}
          value={value ? value[0] : null}
          onChange={handleRadioButtonChange}
          spliced={false}
        />
      )}
    </Filter>
  );
};
