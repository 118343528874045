import { connect, IAdFoxBannerOnErrorError, TAdFoxBannerProps } from '@cian/adfox-component';
import { withAdfoxPostViewAnalytics } from '@cian/postview-analytics-utils';
import { NoSsr } from '@cian/react-utils';
import { clone } from 'ramda';
import * as React from 'react';

import { OwnTemplates } from './internal/components/OwnTemplates';
import { useAdsDisabled } from './internal/hooks/useAdsDisabled';
import { useAdfoxContext } from '../../utils/AdfoxContext';

const AdFoxBannerComponent = connect(OwnTemplates);

type AdFoxBannerProps = {
  position: number | null;
  onStub?(): void;
  onError?(error: IAdFoxBannerOnErrorError): void;
};

export const AdFoxBanner = React.memo<AdFoxBannerProps>(function AdFoxBanner(props) {
  const { onStub, onError, position } = props;

  const { params, size, postViewAnalyticsData } = useAdfoxContext();

  const [isHidden, setHidden] = React.useState(false);

  const handleStub = React.useCallback(() => {
    setHidden(true);

    onStub?.();
  }, []);

  const handleError = React.useCallback((error: IAdFoxBannerOnErrorError) => {
    setHidden(true);

    onError?.(error);
  }, []);

  useAdsDisabled(() => {
    handleStub();
  }, []);

  const banner = React.useMemo(() => {
    const bannerProps: TAdFoxBannerProps = {
      onStub: handleStub,
      onError: handleError,
      params,
      lazyLoad: true,
      ...size,
    };

    if (postViewAnalyticsData) {
      let postViewAnalytics = postViewAnalyticsData;

      if (position != null) {
        postViewAnalytics = clone(postViewAnalytics);

        postViewAnalytics.banner.position = position;
      }

      const AdfoxWithPostView = withAdfoxPostViewAnalytics(AdFoxBannerComponent, bannerProps, postViewAnalytics);

      return <AdfoxWithPostView />;
    }

    return <AdFoxBannerComponent {...bannerProps} />;
  }, [handleStub, handleError, params, size, postViewAnalyticsData, position]);

  if (isHidden) {
    return null;
  }

  return <NoSsr>{banner}</NoSsr>;
});
