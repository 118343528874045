import * as React from 'react';

import { IWordsExcludeTag } from '../../../state/tags/definitions/words_exclude';
import { TagNew } from '../TagNew';

export interface IWordsExcludeTagItemProps {
  tag: IWordsExcludeTag;
  onDeleteClick(): void;
}

export const WordsExcludeTagItem = ({ tag, onDeleteClick }: IWordsExcludeTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>Не содержит &quot;{tag.value}&quot;</TagNew>;
};
