import * as React from 'react';

import { trackBannerShow } from '../tracking';

export const useHandleTrackShow = () => {
  const [isShown, setIsShown] = React.useState(false);

  return React.useCallback(
    (visible: boolean) => {
      if (visible && !isShown) {
        trackBannerShow();
        setIsShown(true);
      }
    },
    [isShown],
  );
};
