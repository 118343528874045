import { IAppState, TActions } from '../../../common/state';

export function undergroundTimeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-undergroundTime') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              foot_min: undefined,
              only_foot: undefined,
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
}
