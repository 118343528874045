import * as React from 'react';

export const ZoomOut: React.FC = () => {
  return (
    // FIXME
    // eslint-disable-next-line react/forbid-elements
    <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="Уменьшить">
      <line x1="16" y1="1" y2="1" stroke="#152242" strokeWidth="2" />
    </svg>
  );
};

ZoomOut.displayName = 'ZoomOut';
