import { LeaseTerm } from '../../../../../../../shared/offer';
import { IOffer } from '../../../../types/offer';

export function getLeasePeriod({ category, bargainTerms: { leaseTermType } }: IOffer) {
  if (category && category.startsWith('daily')) {
    return 'посуточно';
  }

  if (leaseTermType === LeaseTerm.Long) {
    return 'от года';
  }

  if (leaseTermType === LeaseTerm.FewMonths) {
    return 'на несколько месяцев';
  }

  return undefined;
}
