import { getTermValue } from './helpers';
import { EFacilityType, NonEmptyArray, TJsonQuery } from './types';

export function getLivingConditions(jsonQuery: TJsonQuery): NonEmptyArray<EFacilityType> | null {
  const kidsAllowed = getTermValue('kids')(jsonQuery);
  const petsAllowed = getTermValue('pets')(jsonQuery);

  const livingConditions: EFacilityType[] = [];

  if (kidsAllowed) {
    livingConditions.push(EFacilityType.KidsAllowed);
  }

  if (petsAllowed) {
    livingConditions.push(EFacilityType.PetsAllowed);
  }

  return livingConditions.length > 0 ? (livingConditions as NonEmptyArray<EFacilityType>) : null;
}
