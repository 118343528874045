export enum EMediaType {
  Layout = 'layout',
  CianLayout = 'cianLayout',
  Photo = 'photo',
  Video = 'video',
}

export interface IMedia {
  type: EMediaType;
  src: string;
}
