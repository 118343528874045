import { getParamChangedAction } from './fabrics/multiselect_param_reducer';
import { IAppState, TActions } from '../../../common/state';
import { EGarageType, jq, jqs } from '../../../json_query';
import { isGarageTypesTag } from '../tags/definitions/garage_type';

type TGarageTypeChangedActionType = 'filters/garage/GARAGE_TYPE_CHANGED';
export const IGarageTypeChangedActionType = 'filters/garage/GARAGE_TYPE_CHANGED' as TGarageTypeChangedActionType;

export interface IGarageTypeChangedAction {
  type: 'filters/garage/GARAGE_TYPE_CHANGED';
  paramType: EGarageType;
  value: boolean;
}

export const changeGarage = getParamChangedAction<EGarageType, TGarageTypeChangedActionType>(
  IGarageTypeChangedActionType,
);

export function garageTypeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IGarageTypeChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jqs(state.filters.jsonQuery, [
            jsonQuery =>
              action.value
                ? jq(jsonQuery).setGarageType(action.paramType)
                : jq(jsonQuery).unsetGarageType(action.paramType),
            jsonQuery => jq(jsonQuery).unsetGarageKinds(),
            jsonQuery => jq(jsonQuery).unsetGarageMaterials(),
          ]),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (isGarageTypesTag(action.tag)) {
        const tag = action.tag;

        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jqs(state.filters.jsonQuery, [
              jsonQuery => jq(jsonQuery).unsetGarageType(tag.value),
              jsonQuery => jq(jsonQuery).unsetGarageKinds(),
              jsonQuery => jq(jsonQuery).unsetGarageMaterials(),
            ]),
          },
        };
      }

      return state;

    default:
      return state;
  }
}
