import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { LinkButton } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './CianDobroBanner.css';

export interface ICianDobroBannerProps {
  header: string;
  text: string;
  buttonText: string;
  buttonUrl: string;
  logoUrl: string;
  onShow(): void;
  onClick(): void;
}

export function CianDobroBanner(props: ICianDobroBannerProps) {
  return (
    <ActionAfterViewObserver callback={props.onShow} percentVisible={0.8} triggerOnce>
      <div className={styles.banner} data-name="CianDobroBanner">
        <div className={styles.info}>
          <div className={styles.header}>{props.header}</div>
          <div className={styles.text}>{props.text}</div>
          <div className={styles.button}>
            <LinkButton size="M" href={props.buttonUrl} onClick={props.onClick}>
              {props.buttonText}
            </LinkButton>
          </div>
        </div>
        <img src={props.logoUrl} draggable={false} />
      </div>
    </ActionAfterViewObserver>
  );
}
