/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TSuggestVillagesTariffProModel,
  ISuggestVillagesTariffProRequest,
  IMappers,
  ISuggestVillagesTariffProResponse,
  ISuggestVillagesTariffProResponseError,
  TSuggestVillagesTariffProResponse,
} from './types';

const defaultConfig: TSuggestVillagesTariffProModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'countryside-search',
  pathApi: '/v1/suggest-villages-tariff-pro/',
  hostType: 'api',
} as TSuggestVillagesTariffProModel;

function createSuggestVillagesTariffProModel(
  parameters: ISuggestVillagesTariffProRequest,
): TSuggestVillagesTariffProModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ISuggestVillagesTariffProOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: ISuggestVillagesTariffProRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchSuggestVillagesTariffPro<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: ISuggestVillagesTariffProOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TSuggestVillagesTariffProResponse
> {
  const { response, statusCode } = await httpApi.fetch(createSuggestVillagesTariffProModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ISuggestVillagesTariffProResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as ISuggestVillagesTariffProResponseError);
    }
  }

  return { response, statusCode } as TSuggestVillagesTariffProResponse;
}

export { defaultConfig, createSuggestVillagesTariffProModel, fetchSuggestVillagesTariffPro };
