import * as React from 'react';

import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';

interface IOptions {
  label: string;
  value: boolean | null;
}

const options: IOptions[] = [
  { label: 'Неважно', value: null },
  { label: 'Не показывать', value: false },
  { label: 'Показывать только их', value: true },
];

interface IIsEncumbranceRealtyProps {
  value: boolean | null;
  onChange(value: boolean | null): void;
}

export const IsEncumbranceRealty: React.FC<IIsEncumbranceRealtyProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_is_encumbrance_realty" label="Объекты с обременением">
      <RadioButtonGroup options={options} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
