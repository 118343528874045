import { Button } from '@cian/ui-kit/button';
import { ModalWindow } from '@cian/ui-kit/modal';
import { Header2, InlineText2, InlineText3 } from '@cian/ui-kit/typography';
import * as React from 'react';

import { PhoneInput } from './components/PhoneInput';
import { TimeInput } from './components/TimeInput';
import * as styles from './styles.css';
import { formatPhoneNumber } from './utils/formatters';
import { validatePhone } from './utils/phone';
import { validateTime, inTimeRange } from './utils/time';

interface INewbuildingConsultantPopupProps {
  open: boolean;
  callFrom: string;
  callTo: string;
  onClose(): void;
  onRequestCallback(customerPhone: string, time: string): Promise<void>;
  openChat(): void;
}

export function NewbuildingConsultantPopup({
  open,
  callFrom,
  callTo,
  onClose,
  openChat,
  onRequestCallback,
}: INewbuildingConsultantPopupProps) {
  const [phone, changeStatePhone] = React.useState('');
  const [phoneError, setPhoneError] = React.useState('');
  const [timeValue, changeStateTimeValue] = React.useState('');
  const [timeValueError, setTimeValueError] = React.useState('');

  const changePhone = (value: string) => {
    changeStatePhone(value);
    setPhoneError('');
  };
  const changeTimeValue = (value: string) => {
    changeStateTimeValue(value);
    setTimeValueError('');
  };

  const handleSubmit = async () => {
    const customerPhone = `+7${phone}`;
    const isValidTime = validateTime(timeValue) && inTimeRange(timeValue, callFrom, callTo);
    const errorText = `Введите время в формате ЧЧ:ММ в интервале с ${callFrom} до ${callTo}`;
    const isValidPhone = validatePhone(customerPhone);

    setTimeValueError(isValidTime ? '' : errorText);
    setPhoneError(isValidPhone ? '' : 'Введите номер');

    if (isValidTime && isValidPhone) {
      await onRequestCallback(customerPhone, timeValue);

      changeStateTimeValue('');
      changeStatePhone('');
    }
  };

  return (
    <ModalWindow open={open} onClose={onClose} width={518}>
      <div className={styles['container']}>
        <div className={styles['consultant-img']} />
        <Header2>Быстрый поиск квартиры мечты</Header2>
        <div className={styles['description']}>
          <InlineText2>
            Оставьте ваши контакты или напишите в чат.
            <br />
            Консультанты Циан готовы помочь с поиском лучшего предложения на квартиры в новостройках
          </InlineText2>
        </div>

        <div className={styles['form']}>
          <InlineText2>Номер телефона</InlineText2>
          <div className={styles['phone-input']}>
            <PhoneInput value={formatPhoneNumber(phone)} error={phoneError} changeValue={changePhone} />
          </div>

          <InlineText2>Время для звонка (по Москве)</InlineText2>
          <TimeInput value={timeValue} error={timeValueError} changeValue={changeTimeValue} />

          <div className={styles['btn-group']}>
            <Button size="XS" onClick={handleSubmit}>
              Перезвоните мне
            </Button>
            <InlineText2>или</InlineText2>
            <Button size="XS" theme="fill_secondary" onClick={openChat}>
              Написать в чат
            </Button>
          </div>
        </div>

        <div className={styles['private-notes']}>
          <InlineText3 color="gray60_100">
            Мы не будем передавать ваши личные данные третьим лицам и присылать вам спам.
          </InlineText3>
        </div>
      </div>
    </ModalWindow>
  );
}
