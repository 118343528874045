import { IJsonQuery } from '../../../types/jsonQuery';
import { IOffer } from '../../../types/offer';

export function isSeoSuggestAvailable(offer: IOffer, jsonQuery: IJsonQuery): boolean {
  const { geo } = offer;
  const { geo: jsonQueryGeo } = jsonQuery;

  if (!geo) {
    return false;
  }

  const jsonQueryGeoId =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    jsonQueryGeo && jsonQueryGeo.value.length === 1 ? (jsonQueryGeo.value[0] as any).id : undefined;

  /**
   * Если в гео карточки уже есть гео запроса из ЧПУ - дополнительно расстояние
   * от текущего гео ЧПУ выводить не нужно
   */
  const geoIdConcurrences = jsonQueryGeoId ? geo.address.find(({ id }) => id === jsonQueryGeoId) : false;

  return geoIdConcurrences === undefined;
}
