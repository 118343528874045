import * as React from 'react';

import { IGeoDistrictTag } from '../../../../state/tags/definitions/geo';
import { TagNew } from '../../TagNew';

export interface IGeoDistrictTagItemProps {
  tag: IGeoDistrictTag;
  onDeleteClick(): void;
}

export const GeoDistrictTagItem = (props: IGeoDistrictTagItemProps) => {
  return <TagNew onDeleteClick={props.onDeleteClick}>{props.tag.text}</TagNew>;
};
