import { Dispatch, IAppState } from '../../../../common/state';
import { IDeleteOfferFromComparisonRequest } from '../../../../repositories/offers-comparison/v1/delete-offer-from-comparison';
import { deleteOfferFromComparisonRequest } from '../../../../services/offersComparison';
import {
  DeleteFromComparisonActionType,
  EComparisonNotificationCategory,
  SetComparisonNotificationType,
} from '../index';

export const deleteOfferFromComparison =
  (parameters: IDeleteOfferFromComparisonRequest) => async (dispatch: Dispatch, getState: () => IAppState) => {
    const { httpApi } = getState();
    const { offerId } = parameters;
    const response = await deleteOfferFromComparisonRequest({ httpApi, parameters: { offerId } });

    if (response) {
      const { text, comparisonUrl, offerId } = response;

      dispatch({
        type: DeleteFromComparisonActionType,
        offerId,
      });

      dispatch({
        type: SetComparisonNotificationType,
        text,
        url: comparisonUrl,
        category: EComparisonNotificationCategory.Remove,
        offerId,
      });
    }
  };
