import { LinkButton, UIHeading1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './SuburbanBuildersProjects.css';
import { ProjectItem } from './components/ProjectItem';
import { ISuburbanProjectSuggest } from '../../types/suburbanBuildersProjects';

export interface ISuburbanBuildersProjectsProps {
  items: ISuburbanProjectSuggest[];
  link: string;
  onProjectClick(id: number, position: number): void;
  onAllProjectsButtonClick(): void;
}

export const SuburbanBuildersProjects: React.VFC<ISuburbanBuildersProjectsProps> = ({
  items,
  link,
  onProjectClick,
  onAllProjectsButtonClick,
}) => {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['title-container']}>
        <UIHeading1>Построить дом</UIHeading1>
        <LinkButton size="XS" href={link} theme="fill_secondary" onClick={onAllProjectsButtonClick} target="_blank">
          Смотреть все проекты
        </LinkButton>
      </div>
      <div className={styles['projects-list']}>
        {items.map((project, index) => (
          <ProjectItem
            {...project}
            key={project.projectId}
            onClick={() => onProjectClick(project.projectId, index + 1)}
          />
        ))}
      </div>
    </div>
  );
};
