import { IQuickLink } from '../../../../types/quickLinks';

/**
 * Преобразуем массив объектов в массив содержащий 3 или 4 массива
 * чтобы вывести быстрые ссылки в несколько столбцов с сохранением порядка ссылок,
 * так как они пришли с бекенда.
 */
export function getGroupedLinks(links: IQuickLink[]): IQuickLink[][] {
  if (links.length === 0) {
    return [];
  }

  const result: IQuickLink[][] = [];

  let groupCount = 4;
  let minCountInGroup = Math.floor(links.length / groupCount);
  let remainder = links.length % groupCount;

  if (minCountInGroup < 2 && remainder !== 3) {
    groupCount = 3;
    minCountInGroup = Math.floor(links.length / groupCount);
    remainder = links.length % groupCount;
  }

  let offset = 0;

  while (offset !== links.length) {
    let countInGroup = minCountInGroup;

    if (remainder > 0) {
      remainder -= 1;
      countInGroup += 1;
    }

    const nextOffset = offset + countInGroup;

    result.push(links.slice(offset, nextOffset));
    offset = nextOffset;
  }

  return result;
}
