import { Header1 } from '@cian/ui-kit/typography';
import * as React from 'react';

import * as style from './Breadcrumbs.css';
import { IBreadcrumb } from '../../../offer';

interface IBreadcrumbsProps {
  breadcrumbs: IBreadcrumb[];
  h1?: React.ReactNode;
}

export function Breadcrumbs({ breadcrumbs, h1 }: IBreadcrumbsProps) {
  return (
    <div>
      {breadcrumbs.length > 0 && (
        <div className={style['breadcrumbs']}>
          {breadcrumbs.map((breadcrumb, index, arr) => {
            return (
              <span key={breadcrumb.title || index}>
                <a
                  key={index}
                  className={style['breadcrumbs-link']}
                  href={breadcrumb.url || undefined}
                  title={breadcrumb.title || ''}
                >
                  <span>{breadcrumb.title}</span>
                  {index < arr.length - 1 && <div className={style['breadcrumbs-delimiter']} />}
                </a>
              </span>
            );
          })}
        </div>
      )}
      {h1 && <Header1 as="h1">{h1}</Header1>}
    </div>
  );
}
