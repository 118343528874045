import { EGarageType } from '../../../../json_query';
import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IGarageTypesTagType = 'advanced-garage';

export interface IGarageTypesTag {
  type: 'advanced-garage';
  value: EGarageType;
}

export const isGarageTypesTag = (tag: TTag): tag is IGarageTypesTag => tag.type === IGarageTypesTagType;

export const garageTypesTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.garage_type && jsonQuery.garage_type.value;

    let nextTags = tags.filter(
      tag => tag.type !== IGarageTypesTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: EGarageType) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IGarageTypesTag => isGarageTypesTag(tag) && tag.value === value,
            () => ({ type: IGarageTypesTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
