import * as React from 'react';

import { EOfferSeller } from '../../../../json_query';
import { IOfferSellerTypesTag } from '../../../state/tags/definitions/offer_seller';
import { TagNew } from '../TagNew';

export interface IOfferSellerTypesTagItemProps {
  tag: IOfferSellerTypesTag;
  onDeleteClick(): void;
}

const OFFER_SELLERS_MAP = {
  [EOfferSeller.Agent]: 'От агентов',
  [EOfferSeller.Homeowner]: 'От собственников',
  [EOfferSeller.Builder]: 'От застройщиков',
};

export const OfferSellerTagItem = ({ onDeleteClick, tag }: IOfferSellerTypesTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>{OFFER_SELLERS_MAP[tag.value]}</TagNew>;
};
