import { COMPLAIN_COMPLAINTS_FETCHED, COMPLAIN_FAIL_REQUEST } from '../../actions/complain/complain';
import { IAppState, TActions } from '../../common/state';

export function complainReducer(state: IAppState, action: TActions) {
  switch (action.type) {
    case COMPLAIN_FAIL_REQUEST:
      return {
        ...state,
        complain: {
          ...state.complain,
          isError: action.isError,
        },
      };

    case COMPLAIN_COMPLAINTS_FETCHED: {
      const { manifest } = action;

      return {
        ...state,
        complain: { ...state.complain, manifest },
      };
    }

    default:
      return state;
  }
}
