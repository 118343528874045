import React from 'react';

import { SeoSuggestion } from '../../../components/SeoSuggestion';
import { useOfferContext } from '../../../context';

export const SeoSuggestionContainer: React.FC = () => {
  const { currentPath, seoSuggestionLabel, seoSuggestionDistance } = useOfferContext();

  if (!seoSuggestionLabel) {
    return null;
  }

  return <SeoSuggestion text={seoSuggestionLabel} distance={seoSuggestionDistance} url={currentPath} />;
};
