import { TooltipDesktop } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';
import { ILabelTooltip } from '../../types/label';

interface ILabelTooltipProps {
  children: React.ReactElement;
  tooltip?: ILabelTooltip;
}

export function LabelTooltip({ tooltip, children }: ILabelTooltipProps) {
  if (!tooltip) {
    return children;
  }

  return (
    <TooltipDesktop
      title={
        <div className={styles['content']}>
          <span className={styles['title']}>{tooltip.title}</span>
          <span className={styles['description']}>{tooltip.description}</span>
        </div>
      }
      theme={'white'}
      placement={'bottom-start'}
      onOpen={tooltip.onOpen}
    >
      {children}
    </TooltipDesktop>
  );
}
