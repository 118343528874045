/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetNewbuildingLayoutWithOffersModel,
  IGetNewbuildingLayoutWithOffersRequest,
  IMappers,
  IGetNewbuildingLayoutWithOffersResponse,
  IGetNewbuildingLayoutWithOffersResponseError,
  TGetNewbuildingLayoutWithOffersResponse,
} from './types';

const defaultConfig: TGetNewbuildingLayoutWithOffersModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'search-offers',
  pathApi: '/v1/get-newbuilding-layout-with-offers/',
  hostType: 'api',
} as TGetNewbuildingLayoutWithOffersModel;

function createGetNewbuildingLayoutWithOffersModel(
  parameters: IGetNewbuildingLayoutWithOffersRequest,
): TGetNewbuildingLayoutWithOffersModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetNewbuildingLayoutWithOffersOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetNewbuildingLayoutWithOffersRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetNewbuildingLayoutWithOffers<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetNewbuildingLayoutWithOffersOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetNewbuildingLayoutWithOffersResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetNewbuildingLayoutWithOffersModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetNewbuildingLayoutWithOffersResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetNewbuildingLayoutWithOffersResponseError);
    }
  }

  return { response, statusCode } as TGetNewbuildingLayoutWithOffersResponse;
}

export { defaultConfig, createGetNewbuildingLayoutWithOffersModel, fetchGetNewbuildingLayoutWithOffers };
