import * as React from 'react';

import { getTermValue } from '../../../../../JsonQuery';
import { SuburbanBathroom } from '../../../components/advancedFilters/SuburbanBathroom';
import { ESuburbanWCType } from '../../../types/jsonQuery';
import { useContext } from '../../../utils/useContext';
import { useDealType } from '../../../utils/useDealType';

export const SuburbanBathroomContainer: React.FC = () => {
  const dealType = useDealType();
  const { jsonQuery, onChange } = useContext();
  const suburbanBathroomType = getTermValue('wc_site')(jsonQuery);

  const handleChange = React.useCallback(
    (suburbanBathroomType: ESuburbanWCType | null) => {
      onChange({ action: 'setSuburbanBathroomType', arguments: [suburbanBathroomType] });
    },
    [onChange],
  );

  return <SuburbanBathroom value={suburbanBathroomType} onChange={handleChange} dealType={dealType} />;
};
