/* eslint-disable max-lines */
import { Input, Outside, DropdownPopover } from '@cian/ui-kit';
import classNames from 'classnames';
import * as React from 'react';

import { getKeyboardEventCode } from './utils/events';
import { scrollTo } from './utils/scrollTo';
import * as textSearch from '../../../utils/text_search';

const style = require('./index.css');

export interface IGeoSuggestItem<T> {
  title: string;
  value: T;
  subTitle?: string;
  externalLink?: string;
}

export interface IGeoSuggestGroup<T> {
  title: string;
  items: IGeoSuggestItem<T>[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AbstractGeoSuggestItem = IGeoSuggestItem<any>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AbstractGeoSuggestGroup = IGeoSuggestGroup<any>;

type AbstractSuggestions = AbstractGeoSuggestItem[] | AbstractGeoSuggestGroup[];

const isIGeoSuggestGroup = (suggestions: AbstractSuggestions): suggestions is AbstractGeoSuggestGroup[] =>
  'items' in suggestions[0];

export interface IGeoSuggestProps {
  placeholder?: string;
  suggestions?: AbstractSuggestions;
  loading?: boolean;
  disabled?: boolean;
  leftAdornment?: React.ReactNode;

  onFocus?(): void;
  onValueChange(value: string): void;
  onSelect(suggestion: AbstractGeoSuggestItem): void;
  onLinkClick?(suggestion: AbstractGeoSuggestItem): void;
}

export interface IGeoSuggestState {
  value: string;
  isOpened: boolean;
  selectedItemIndex: number;
}

// eslint-disable-next-line import/no-default-export
export default class GeoSuggest extends React.Component<IGeoSuggestProps, IGeoSuggestState> {
  public static defaultProps = {
    loading: false,
    disabled: false,
  };

  public state = {
    value: '',
    isOpened: false,
    selectedItemIndex: 0,
  };

  private rootRef = React.createRef<HTMLDivElement>();
  private inputRef = React.createRef<HTMLInputElement>();
  private popupElement: HTMLDivElement | null = null;
  private suggestionElements: HTMLLIElement[] | null[] = [];
  private suggestionLastIndex = 0;
  private mouseOverDisabled = false;
  private mouseOverDisableTimeout: number;

  public reset() {
    this.setState({
      value: '',
    });
  }

  public render() {
    const dropdownOpened = this.state.isOpened && !!this.state.value && !!this.props.suggestions;

    return (
      <DropdownPopover
        open={dropdownOpened}
        flip={false}
        sameWidth="width"
        content={
          <Outside insideRefs={[this.rootRef]} onOutside={this.handleBlur} active={dropdownOpened}>
            <div>{this.renderList()}</div>
          </Outside>
        }
      >
        <div ref={this.rootRef} className={style['container']}>
          <Input
            inputRef={this.inputRef}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
            loading={this.props.loading}
            value={this.state.value}
            onChange={this.handleValueChange}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            leftAdornment={this.props.leftAdornment}
          />
        </div>
      </DropdownPopover>
    );
  }

  private renderList() {
    if (!this.props.suggestions) {
      return null;
    }

    if (this.props.suggestions.length === 0) {
      return <div className={classNames(style['dropdown'], style['nothingFound'])}>Ничего не найдено</div>;
    }

    this.suggestionLastIndex = 0;
    this.suggestionElements = [];

    let list: JSX.Element | (JSX.Element | null)[];
    if (isIGeoSuggestGroup(this.props.suggestions)) {
      list = this.props.suggestions.map(group => {
        if (group.items.length === 0) {
          return null;
        }

        return (
          <figure key={`suggestion_group-${group.title}`} className={style['list-group']}>
            <figcaption className={style['list-groupTitle']}>{group.title}</figcaption>
            <ul className={style.list}>{this.renderItems(group.items, true)}</ul>
          </figure>
        );
      });
    } else {
      list = <ul className={style.list}>{this.renderItems(this.props.suggestions)}</ul>;
    }

    return <div className={classNames(style['dropdown'], style['list-container'])}>{list}</div>;
  }

  private renderItems(items: AbstractGeoSuggestItem[], nested: boolean = false) {
    return items.map(item => {
      const index = this.suggestionLastIndex++;

      return (
        <li
          key={`suggestion-${item.title}`}
          ref={suggestionElement => (this.suggestionElements[index] = suggestionElement)}
          className={classNames(
            style['list-item'],
            nested && style['list-item--nested'],
            item.externalLink && style['list-item--linked'],
            this.state.selectedItemIndex === index && style['list-item--selected'],
          )}
        >
          <a
            className={style['list-itemContainer']}
            onClick={() => this.handleItemClick(item)}
            onMouseOver={() => this.handleItemOver(index)}
            title={item.title}
          >
            {this.renderTitle(item.title)}
            {this.renderSubtitle(item.subTitle)}
          </a>
          {this.renderLink(item)}
        </li>
      );
    });
  }

  private renderTitle(title: string) {
    const value = this.state.value.trim();

    if (!value) {
      return <span>{title}</span>;
    }

    const startIndex = textSearch.indexOf(title, value);
    if (startIndex === -1) {
      return <span>{title}</span>;
    }

    const endIndex = startIndex + value.length;

    return (
      <span>
        {title.substring(0, startIndex)}
        <strong>{title.substring(startIndex, endIndex)}</strong>
        {title.substring(endIndex)}
      </span>
    );
  }

  private renderSubtitle = (subtitle: string | undefined) => {
    if (!subtitle) {
      return undefined;
    }

    return <p className={style['list-itemDesc']}>{subtitle}</p>;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private renderLink(item: IGeoSuggestItem<any>) {
    if (!item.externalLink) {
      return undefined;
    }

    const { onLinkClick } = this.props;

    return (
      <a
        className={style['list-itemLink']}
        href={item.externalLink}
        target="_blank"
        onClick={() => onLinkClick && onLinkClick(item)}
        rel="noreferrer"
      />
    );
  }

  private handleFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus();
    }

    this.open();
  };

  private handleBlur = () => {
    this.close();
  };

  private handleValueChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    this.setState({
      value,
      selectedItemIndex: 0,
    });

    //this.scrollTo(0);

    this.props.onValueChange(value);
  };

  private handleKeyDown = (event: React.KeyboardEvent<EventTarget>) => {
    if (!this.props.suggestions || this.props.suggestions.length === 0) {
      return;
    }

    const nativeEvent = event.nativeEvent as KeyboardEvent;
    const code = getKeyboardEventCode(nativeEvent);

    switch (code) {
      case 'ArrowDown':
      case 'Down':
        event.preventDefault();
        this.selectItem(this.state.selectedItemIndex + 1);
        break;

      case 'ArrowUp':
      case 'Up':
        event.preventDefault();
        this.selectItem(this.state.selectedItemIndex - 1);
        break;

      case 'Tab':
      case 'NumpadEnter':
      case 'Enter':
        event.preventDefault();
        this.handleEnterPress();
        break;
      case 'Escape':
        this.close();
        break;
      default:
        break;
    }
  };

  private handleEnterPress() {
    if (!this.props.suggestions) {
      return;
    }

    let index = this.state.selectedItemIndex;
    if (isIGeoSuggestGroup(this.props.suggestions)) {
      for (let i = 0; i <= this.props.suggestions.length - 1; i++) {
        const suggestionsGroup = this.props.suggestions[i];
        if (suggestionsGroup.items.length - 1 < index) {
          index -= suggestionsGroup.items.length;
          continue;
        }

        this.handleItemClick(suggestionsGroup.items[index]);
        break;
      }
    } else {
      this.handleItemClick(this.props.suggestions[index]);
    }
  }

  private handleItemOver = (index: number) => {
    if (this.mouseOverDisabled) {
      return;
    }

    this.setState({
      selectedItemIndex: index,
    });
  };

  private handleItemClick = (item: AbstractGeoSuggestItem) => {
    this.setState({ value: item.title });
    this.props.onSelect(item);
    this.close();
  };

  private selectItem = (index: number) => {
    if (this.suggestionLastIndex === 0) {
      return;
    }

    if (index < 0) {
      // eslint-disable-next-line no-param-reassign
      index = this.suggestionLastIndex - 1;
    } else if (index > this.suggestionLastIndex - 1) {
      // eslint-disable-next-line no-param-reassign
      index = 0;
    }

    if (this.state.selectedItemIndex === index) {
      return;
    }

    this.setState({
      selectedItemIndex: index,
    });

    this.scrollTo(index);
  };

  private open() {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
      this.setState({ isOpened: true });
    }
  }

  private close() {
    if (this.inputRef.current) {
      this.inputRef.current.blur();

      this.setState({
        isOpened: false,
        selectedItemIndex: 0,
      });
    }
  }

  private scrollTo(index: number, center: boolean = false) {
    if (this.popupElement && this.suggestionElements) {
      const containerElement = this.popupElement;
      const valueElement = this.suggestionElements[index];

      if (!valueElement) {
        return;
      }

      window.clearTimeout(this.mouseOverDisableTimeout);
      this.mouseOverDisabled = true;

      scrollTo({ center, containerElement, targetElement: valueElement });

      this.mouseOverDisableTimeout = window.setTimeout(() => {
        this.mouseOverDisabled = false;
      }, 100);
    }
  }
}
