import { ca } from '@cian/analytics';

import { TJsonQuery } from '../../../../packages/JsonQuery';

export function trackSuburbanCloseSaveSearchClick(sc: TJsonQuery) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'saved_search',
    action: 'close',
    label: 'window',
    sc,
    useLastProducts: true,
  });
}
