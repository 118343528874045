import * as React from 'react';

import { EDecorationType, getTermsValue, NonEmptyArray } from '../../../../../JsonQuery';
import { DecorationsList } from '../../../components/advancedFilters/DecorationsList';
import { useContext } from '../../../utils/useContext';

export const DecorationsListContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const decorationsList = getTermsValue('decorations_list')(jsonQuery);

  const handleChange = React.useCallback(
    (decorationsList: NonEmptyArray<EDecorationType> | null) => {
      onChange({ action: 'setDecorationsList', arguments: [decorationsList] });
    },
    [onChange],
  );

  return <DecorationsList value={decorationsList} onChange={handleChange} />;
};
