export enum EVas {
  Auction = 'auction',
  Top = 'top3',
  Premium = 'premium',
  Standard = 'standard',
}

export interface IVas {
  type: EVas;
  name: string;
  description: string;
}
