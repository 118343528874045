import { TComputedPropMapper } from '../../types/computed-props-mapper';

export const agentName: TComputedPropMapper<string | undefined> = props => {
  const { user, isByHomeowner } = props.offer;
  const { masterAgent, cianUserId, agencyName } = user || {};

  if (isByHomeowner) {
    return cianUserId ? `ID ${cianUserId}` : undefined;
  }

  if (masterAgent && masterAgent.name) {
    return masterAgent.name;
  }

  if (agencyName) {
    return agencyName;
  }

  if (cianUserId) {
    return `ID ${cianUserId}`;
  }

  return undefined;
};
