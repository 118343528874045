import * as R from 'ramda';

import { resetTerms, setTerm } from './helpers';
import { EFacilityType, TJsonQuery } from './types';
import { NonEmptyArray } from './types/common';

export function setBathTypes(jsonQuery: TJsonQuery) {
  return (facilityTypes: NonEmptyArray<EFacilityType> | null): TJsonQuery => {
    let nextJsonQuery = R.clone(jsonQuery);

    nextJsonQuery = resetTerms(['bath', 'shower'])(nextJsonQuery);

    if (facilityTypes) {
      if (facilityTypes.includes(EFacilityType.Bath)) {
        nextJsonQuery = setTerm('bath')(nextJsonQuery)(true);
      }

      if (facilityTypes.includes(EFacilityType.Shower)) {
        nextJsonQuery = setTerm('shower')(nextJsonQuery)(true);
      }
    }

    return nextJsonQuery;
  };
}
