import { fetchAddNewbuildingToFavorites, fetchRemoveNewbuildingFromFavorites } from '../../api/newbuildings';
import { IAppState, TActions, Dispatch } from '../../common/state';
import { INewbuilding } from '../../types/newbuildingHeader';
import { IResidentialComplexInfo } from '../../types/residentialComplexInfo';

export interface ISetNewbuildingFavoriteAction {
  type: 'serp/newbuilding_info/SET_NEWBUILDING_FAVORITE';
  isFavorite: boolean;
}

function setNewbuildingFavoriteAction(isFavorite: boolean): ISetNewbuildingFavoriteAction {
  return {
    type: 'serp/newbuilding_info/SET_NEWBUILDING_FAVORITE',
    isFavorite,
  };
}

export function newbuildingInfoReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'serp/newbuilding_info/SET_NEWBUILDING_FAVORITE': {
      const newbuildingInfo: IResidentialComplexInfo | null = state.results.newbuildingInfo && {
        ...state.results.newbuildingInfo,
        isFavorite: action.isFavorite,
      };

      const newbuildingInformation: INewbuilding | null = state.newbuildingInformation && {
        ...state.newbuildingInformation,
        isFavorite: action.isFavorite,
      };

      return {
        ...state,
        results: {
          ...state.results,
          newbuildingInfo,
        },
        newbuildingInformation,
      };
    }
    default:
      return state;
  }
}

export function setNewbuildingFavorite(isFavorite: boolean, newbuildingId: number) {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const { makeRequest, user } = getState();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { waitForLoginPopup, __header_favorites_count__ = 0, __header_updateFavorites__ } = window as any;

    const toggle = (state: boolean) => {
      dispatch(setNewbuildingFavoriteAction(state));
      if (__header_updateFavorites__) {
        __header_updateFavorites__(state);
      }
    };

    if (isFavorite && !user.isAuthenticated && __header_favorites_count__ >= 5 && waitForLoginPopup) {
      waitForLoginPopup('favorites');
    } else {
      toggle(isFavorite);

      if (isFavorite) {
        fetchAddNewbuildingToFavorites(makeRequest, newbuildingId).catch(() => toggle(false));
      } else {
        fetchRemoveNewbuildingFromFavorites(makeRequest, newbuildingId).catch(() => toggle(true));
      }
    }
  };
}
