import * as React from 'react';

import * as styles from './RangeInput.css';
import { IRangeInputProps, RangeInput as RangeInputComponent } from '../../../RangeInput';

export const RangeInput: React.FC<IRangeInputProps> = props => {
  return (
    <div className={styles['container']}>
      <RangeInputComponent {...props} />
    </div>
  );
};
