import React, { useState, useCallback } from 'react';

import { FeatureLabelsContainer } from './FeatureLabelsContainer';
import { OfferLabelsContainer } from './OfferLabelsContainer';
import { useApplicationContext } from '../../../../../../shared/utils/applicationContext';
import { Gallery } from '../../../components/Gallery';
import { OfferGalleryPessimizationFlexible } from '../../../components/OfferGalleryPessimizationFlexible';
import { useOfferContext } from '../../../context';
import { isNewbuildingBooked, isNewbuildingBookedFromDeveloper } from '../../../utils/newbuildingBooking';

export function GalleryContainer() {
  const { index, media, tracking, offer } = useOfferContext();
  const { config } = useApplicationContext();

  const isBookedFromCian = isNewbuildingBooked(offer, config);
  const isBookedFromDeveloper = isNewbuildingBookedFromDeveloper(offer);

  const [clicked, setClicked] = useState(false);
  const isFlexibleSanctions = config.get<boolean>('Moderation.CustomSanction.NewModerationInfo.Enabled');

  const handleChangeSlide = useCallback(() => {
    if (!clicked) {
      tracking.trackPhotoSlideChanged();
      setClicked(true);
    }
  }, [clicked, tracking.trackPhotoSlideChanged]);

  if (isFlexibleSanctions && offer.moderationInfo?.photoStub) {
    return <OfferGalleryPessimizationFlexible photoStub={offer.moderationInfo.photoStub} />;
  }

  return (
    <>
      <Gallery
        media={media}
        deoptimised={index === 0}
        bookedFromCian={isBookedFromCian}
        bookedFromDeveloper={isBookedFromDeveloper}
        onChangeSlide={handleChangeSlide}
      />
      <OfferLabelsContainer />
      <FeatureLabelsContainer />
    </>
  );
}
