import { IJsonQueryLocation } from '../../../../../json_query';
import { ensureSingleTagExists } from '../../helpers';
import { ITagDefinition, TTag } from '../../index';

export const IGeoLocationTagType = 'geo-location';

export interface IGeoLocationTag {
  type: 'geo-location';
  value: number;
  text: string;
}

const isGeoLocationTag = (tag: TTag): tag is IGeoLocationTag => tag.type === IGeoLocationTagType;

export const geoLocationTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery, tagsData) => {
    const values =
      jsonQuery.geo &&
      (jsonQuery.geo.value.filter(geo => geo.type === 'location') as IJsonQueryLocation[]).map(geo => geo.id);

    let nextTags = tags.filter(
      tag => tag.type !== IGeoLocationTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce((result, value) => {
        const tagData = tagsData.locations[value];

        return ensureSingleTagExists(
          result,
          (tag: TTag): tag is IGeoLocationTag => isGeoLocationTag(tag) && tag.value === value,
          () => ({
            type: IGeoLocationTagType,
            value,
            text: tagData || 'Неизвестный адрес',
          }),
        );
      }, nextTags);
    }

    return nextTags;
  },
};
