import { call, select } from 'redux-saga/effects';

import { JsonQuery } from '../../../packages/JsonQuery';
import { IAppState, TActions } from '../../common/state';
import { IRequestMetaResponse, requestMeta } from '../api';

export enum EVillageMortgageActionType {
  Enable = 'filters/village_mortgage_filter/ENABLE',
  Disable = 'filters/village_mortgage_filter/DISABLE',
  Update = 'filters/village_mortgage_filter/UPDATE',
}

export interface IEnableVillageMortgageFilter {
  type: EVillageMortgageActionType.Enable;
}

export interface IDisableVillageMortgageFilter {
  type: EVillageMortgageActionType.Disable;
}

export interface IUpdateVillageMortgageFilter {
  type: EVillageMortgageActionType.Update;
}

export function enableVillageMortgageFilter(): IEnableVillageMortgageFilter {
  return {
    type: EVillageMortgageActionType.Enable,
  };
}

export function disableVillageMortgageFilter(): IDisableVillageMortgageFilter {
  return {
    type: EVillageMortgageActionType.Disable,
  };
}

export function* getVillageMortgageMeta() {
  const {
    logger,
    makeRequest,
    filters: { jsonQuery },
  } = yield select();

  const jsonQueryWithVillageMortgage = new JsonQuery(jsonQuery).setVillageMortgageAllowed(true).toJSON();

  try {
    const meta: IRequestMetaResponse = yield call(requestMeta, makeRequest, jsonQueryWithVillageMortgage);

    return meta;
  } catch (ex) {
    logger.warning(ex, { degradation: 'Запрос меты для сельской ипотеки' });

    return null;
  }
}

export function villageMortgageFilterReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case EVillageMortgageActionType.Enable:
      return {
        ...state,
        filters: {
          ...state.filters,
          isVillageMortgageFilterEnabled: true,
        },
      };
    case EVillageMortgageActionType.Disable:
      return {
        ...state,
        filters: {
          ...state.filters,
          isVillageMortgageFilterEnabled: false,
        },
      };
    default:
      return state;
  }
}
