import { connect } from 'react-redux';

import { MapModal } from './';
import { IAppState, Dispatch } from '../../../common/state';
import { deleteArea } from '../../state/map/area_delete';
import { changeMap, TMapObjectData } from '../../state/map/map_changed';
import { closeMapModal } from '../../state/map/toggle_modal';

const moscowAreaCenter: [number, number] = [55.76, 37.64];
const leningradAreaCenter: [number, number] = [59.9385, 30.3058];

export const MapContainer = connect(
  (state: IAppState) => {
    let center: [number, number];
    if (state.filters.currentLocation !== 'moscow_mo' && state.filters.currentLocation !== 'spb_lo') {
      center = [state.filters.currentLocation.lat, state.filters.currentLocation.lng];
    } else {
      center = state.filters.currentLocation === 'moscow_mo' ? moscowAreaCenter : leningradAreaCenter;
    }

    return {
      mapRegions: state.filters.jsonQuery.geo,
      center,
    };
  },
  (dispatch: Dispatch) => ({
    onChanged: (data: TMapObjectData[]) => {
      dispatch(changeMap(data));
    },
    onAreaDeleted: (area: TMapObjectData) => {
      dispatch(deleteArea(area));
    },
    close: () => {
      dispatch(closeMapModal());
    },
  }),
)(MapModal);
