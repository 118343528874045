import { IAppState, TActions } from '../../../../common/state';
import { EFlatTourBookingActionTypes } from '../flatTourBooking';

export function flatTourBookingReducer(state: IAppState, action: TActions) {
  switch (action.type) {
    case EFlatTourBookingActionTypes.SetScheduleData:
      return {
        ...state,
        newbuildingFlatTourBookingSchedule: action.payload,
      };

    case EFlatTourBookingActionTypes.SetFlatViewOrder:
      return {
        ...state,
        newbuildingInformation: {
          ...state.newbuildingInformation,
          flatTourBookingInfo: {
            isEnabled: state.newbuildingInformation?.flatTourBookingInfo?.isEnabled || true,
            orderStatus: action.payload,
          },
        },
      };

    case EFlatTourBookingActionTypes.ToggleNotification:
      return {
        ...state,
        newbuildingFlatTourBookingTooltipVisible: action.payload,
      };

    default:
      return state;
  }
}
