import { ca } from '@cian/analytics';

function getLabel(searchString: string): string {
  const searchStringRaw = searchString.split('?');

  return searchStringRaw.length > 1 ? searchStringRaw[1] : searchStringRaw[0];
}

/**
 * Клик на кнопку "Показать объекты" внутри нового блока расширенных фильтров
 */
export function trackSearchButtonInAdvancedFilterClick(searchString: string) {
  const label = getLabel(searchString);

  ca('event', {
    name: 'oldevent',
    category: 'FastFilters',
    action: 'click_filters_block',
    label,
  });
}
