import { ResponseError } from '../../errors';
import { prepareNewbuildingLayoutWithOffers } from '../../mappers/prepareNewbuildingLayoutWithOffers';
import {
  fetchGetNewbuildingLayoutWithOffers,
  TGetNewbuildingLayoutWithOffersResponse,
} from '../../repositories/search-offers/v1/get-newbuilding-layout-with-offers';
import { IApplicationContext } from '../../types/applicationContext';
import { INewbuildingLayoutWithOffers } from '../../types/residentialComplexLayouts/layoutWithOffers/layoutWithOffers';

type ISearchNewbuildingLayoutsDesktopParameters = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jsonQuery: any;
  subdomain: string;
  path: string;
};

export async function fetchResidentialComplexLayoutWithOffers(
  { httpApi }: IApplicationContext,
  _parameters: ISearchNewbuildingLayoutsDesktopParameters,
): Promise<INewbuildingLayoutWithOffers | null> {
  const parameters = { jsonQuery: _parameters.jsonQuery, subdomain: _parameters.subdomain, path: _parameters.path };

  const offersData: TGetNewbuildingLayoutWithOffersResponse = await fetchGetNewbuildingLayoutWithOffers({
    httpApi,
    parameters,
  });

  if (offersData.statusCode !== 200) {
    throw new ResponseError({
      domain: 'fetchGetNewbuildingLayoutWithOffers',
      message: offersData.response.message,
      details: {
        error: offersData.response.errors,
      },
    });
  }

  return prepareNewbuildingLayoutWithOffers(offersData.response);
}
