import { InformationTooltipDesktop, InlineText3, Paragraph3 } from '@cian/ui-kit';
import React, { useState } from 'react';

import { EMaxAuctionService } from '../../../../../../../shared/repositories/search-offers.legacy/v2/search-offers-desktop.data';
import { useApplicationContext } from '../../../../../../../shared/utils/applicationContext';
import { AuctionTooltipContent } from '../../../../components/AuctionTooltipContent/AuctionTooltipContent';
import { useOfferContext } from '../../../../context';
import { useAuctionContext } from '../../../../context/AuctionContext';
import { THintType, trackAuctionHint } from '../../../../context/AuctionContext/utils/tracking';
import { EAuctionTooltipView } from '../../../../types/auction';
import { AuctionBetManagerContainer } from '../AuctionBetManagerContainer';

const POPUP_ANALYTICS_MAP: { [index: number]: THintType } = {
  [EAuctionTooltipView.MANAGE]: 'up_search',
  [EAuctionTooltipView.AUCTION]: 'no_offer',
  [EAuctionTooltipView.AUCTION_ON_THIS_PAGE]: 'with_offer',
  [EAuctionTooltipView.NOT_PREMIUM]: 'not_premium',
};

export function AuctionInformationTooltipContainer() {
  const { isAuctionEnabled, currentBet, auctionMaxBet, setTooltipView, tooltipView, isPersonalBet, isConcurrentBet } =
    useAuctionContext();

  const { config } = useApplicationContext();
  const { offer, maxAuctionService, maxAuctionBet, isFromSuburbanBuilder } = useOfferContext();

  const isMlSearchForAll = config.get<boolean>('isMlSearchForAll.Enabled') || false;

  const [open, setOpen] = useState(false);

  const handleOnOpen = () => {
    const hintType = POPUP_ANALYTICS_MAP[tooltipView as EAuctionTooltipView];
    trackAuctionHint(hintType, 'close', offer);
  };

  const handleOnClose = () => {
    const hintType = POPUP_ANALYTICS_MAP[tooltipView as EAuctionTooltipView];
    setOpen(false);
    trackAuctionHint(hintType, 'show', offer);
  };

  const getAdLink = (id: string | number | undefined | null, service: string, bet: number) => {
    return `/razmestit-obyavlenie/${id}/?auctionBet=${bet}` + (isMlSearchForAll ? `&auctionService=${service}` : '');
  };

  const getAdTypeName = () => {
    switch (true) {
      case offer.isPremium:
        return 'Премиум-';

      case offer.isTop3:
        return 'Топ-';

      case offer.isStandard:
        return 'Стандарт ';

      default:
        return '';
    }
  };

  const handleRaiseBetLinkClick = () => {
    setTooltipView(EAuctionTooltipView.MANAGE);
    trackAuctionHint('with_offer', 'click', offer);
  };

  const handlePublishOfferClick = () => {
    trackAuctionHint('not_premium', 'click', offer);
  };

  const getCurrentOfferServiceType = () => {
    switch (true) {
      case offer.isTop3:
        return 'top3';
      case offer.isPremium:
        return 'premium';
      case offer.isStandard:
        return 'standard';
      default:
        return 'paid';
    }
  };

  const currentServiceType = getCurrentOfferServiceType();

  if (!isAuctionEnabled || isFromSuburbanBuilder || !offer.auction) {
    return null;
  }

  return (
    <InformationTooltipDesktop
      theme="white"
      placement="top"
      onOpen={handleOnOpen}
      onClose={handleOnClose}
      open={open}
      content={
        <AuctionTooltipContent>
          {tooltipView === EAuctionTooltipView.MANAGE && <AuctionBetManagerContainer onClose={() => setOpen(false)} />}

          {tooltipView === EAuctionTooltipView.AUCTION_ON_THIS_PAGE && (
            <>
              Чтобы опередить это
              <br />
              объявление,&nbsp;
              <InlineText3 color="primary_100" onClick={handleRaiseBetLinkClick}>
                повысьте ставку
              </InlineText3>
            </>
          )}

          {tooltipView === EAuctionTooltipView.AUCTION && (
            <>
              Чтобы опередить это объявление,
              <br />
              опубликуйте {getAdTypeName()}объявление
              <br />
              со ставкой больше {currentBet}&nbsp;₽
            </>
          )}

          {tooltipView === EAuctionTooltipView.AUCTION_DIFFERENT_SERVICE && (
            <>
              Чтобы опередить это объявление,
              <br />
              <a
                href={getAdLink(
                  offer.auction.userOfferId,
                  currentServiceType,
                  offer.auction.currentBet + offer.auction.stepBet,
                )}
                target="_blank"
                rel="noreferrer"
              >
                опубликуйте
              </a>{' '}
              ваше объявление
              <br />
              со ставкой {offer.auction.currentBet + offer.auction.stepBet}&nbsp;₽.
            </>
          )}

          {tooltipView === EAuctionTooltipView.NOT_PREMIUM && (
            <>
              Хотите поднять объявление на первую
              <br />
              страницу поиска?
              <br />
              <a
                href={getAdLink(offer.id, maxAuctionService || '', auctionMaxBet)}
                target="_blank"
                onClick={handlePublishOfferClick}
                rel="noreferrer"
              >
                Опубликуйте его
              </a>
              &nbsp; как {getAuctionServiceName(maxAuctionService)}
              <br />
              со ставкой больше {maxAuctionBet || offer.auction?.stepBet}&nbsp;₽.
            </>
          )}
        </AuctionTooltipContent>
      }
    >
      <Paragraph3 textAlign="right" color="primary_100" onClick={() => setOpen(!open)}>
        {isPersonalBet && 'Поднять объявление в поиске'}
        {isConcurrentBet && 'Как опередить это объявление?'}
      </Paragraph3>
    </InformationTooltipDesktop>
  );
}

function getAuctionServiceName(maxAuctionService: EMaxAuctionService | undefined): string {
  switch (maxAuctionService) {
    case EMaxAuctionService.top:
      return 'Топ-объявление';
    case EMaxAuctionService.premium:
      return 'Премиум-объявление';
    case EMaxAuctionService.paid:
    default:
      return 'Платное объявление';
  }
}
