import { pathOr } from 'ramda';
import * as React from 'react';

import { Contact } from '../../../components/advancedFilters/Contact';
import { useContext } from '../../../utils/useContext';

export const ContactPhoneContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const contactPhone = pathOr<string | null>(null, ['contact', 'value'], jsonQuery);

  const handleChangeContactPhone = React.useCallback(
    (phone: string | null) => {
      onChange({ action: 'setContact', arguments: [phone] });
    },
    [onChange],
  );

  return <Contact contactPhone={contactPhone} onContactPhoneChange={handleChangeContactPhone} />;
};
