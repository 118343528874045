import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackOpenChatbot } from './tracking';
import { selectIsUserAgent } from '../../../packages/Filters/shared/selectors';
import { openChatbot } from '../../actions/chat';
import { ChatbotButton } from '../../components/ChatbotButton';
import { selectIsCianAssistantExperimentEnabled } from '../../selectors/experiments/selectIsCianAssistantExperimentEnabled';
import { getOfferType } from '../../selectors/getOfferType';
import { selectChat } from '../../selectors/selectChat';
import { FOfferType } from '../../utils/category';

export const ChatbotButtonContainer = () => {
  const offerType = useSelector(getOfferType);
  const { isOpened } = useSelector(selectChat);
  const isAgent = useSelector(selectIsUserAgent);
  const isChatbotEnabled = useSelector(selectIsCianAssistantExperimentEnabled);
  const dispatch = useDispatch();

  const onClick = React.useCallback(() => {
    trackOpenChatbot();
    dispatch(openChatbot());
  }, [dispatch]);

  if (!isChatbotEnabled || offerType === FOfferType.FlatNew || isAgent || isOpened) {
    return null;
  }

  return <ChatbotButton onClick={onClick} />;
};
