import * as React from 'react';

import { Button } from '../../../../common/components/button';
import { ILocation, TLocation } from '../../../../types/location';

const style = require('./index.css');

export interface ICapitalsProps {
  regions: ILocation[];
  selectedCity: ILocation | undefined;
  selectedRegion: TLocation | undefined;
  onRegionSelected(region: TLocation): void;
}

enum REGIONS {
  Moscow = 1,
  Petersburg = 2,
  MoscowArea = 4593,
  LeningradArea = 4588,
}

export class Capitals extends React.Component<ICapitalsProps> {
  private moscow: ILocation;
  private petersburg: ILocation;

  private moscowArea: ILocation;
  private petersburgArea: ILocation;

  public constructor(props: ICapitalsProps) {
    super(props);

    this.setRegions(props);
  }

  public render() {
    const { selectedRegion, selectedCity } = this.props;

    return (
      <div className={style['capitalContainer']}>
        {!selectedRegion && !selectedCity && (
          <div className={style['areaWrapper']}>
            <div className={style['areaBig']} onClick={() => this.props.onRegionSelected('moscow_mo')}>
              Москва и МО
            </div>
            <div className={style['areaBig']} onClick={() => this.props.onRegionSelected('spb_lo')}>
              Санкт-Петербург и ЛО
            </div>
          </div>
        )}
        {selectedRegion && this.getCapitalAreaButtons(selectedRegion)}
      </div>
    );
  }

  private setRegions(props: ICapitalsProps) {
    props.regions.forEach(region => {
      switch (region.id) {
        case REGIONS.Moscow:
          this.moscow = region;
          break;
        case REGIONS.Petersburg:
          this.petersburg = region;
          break;
        case REGIONS.MoscowArea:
          this.moscowArea = region;
          break;
        case REGIONS.LeningradArea:
          this.petersburgArea = region;
          break;
        default:
          break;
      }
    });
  }

  private getName = (selectedRegion: TLocation) => {
    if (selectedRegion === 'moscow_mo') {
      return 'Москва и МО';
    } else if (selectedRegion === 'spb_lo') {
      return 'Санкт-Петербург и ЛО';
    }

    return selectedRegion.displayName;
  };

  private getCapitalAreaButtons(selectedRegion: TLocation) {
    if (selectedRegion === 'moscow_mo' || selectedRegion === 'spb_lo') {
      return this.getArea(selectedRegion === 'moscow_mo' ? 1 : 2);
    } else if (selectedRegion.id === 1 || selectedRegion.id === 2) {
      return this.getArea(selectedRegion.id, true);
    }

    return '';
  }

  private getArea(locationId: number, isCapital?: boolean) {
    return (
      <div className={style['areaWrapper']}>
        {isCapital ? (
          <Button
            theme="primary"
            className={style['areaButton']}
            onClick={() => {
              this.props.onRegionSelected(locationId === 1 ? 'moscow_mo' : 'spb_lo');
            }}
          >
            {this.getName(locationId === 1 ? this.moscow : this.petersburg)}
          </Button>
        ) : (
          <div
            className={style['area']}
            onClick={() => this.props.onRegionSelected(locationId === 1 ? this.moscow : this.petersburg)}
          >
            {locationId === 1 ? 'Москва' : 'Санкт-Петербург'}
          </div>
        )}

        <div
          className={style['area']}
          onClick={() => this.props.onRegionSelected(locationId === 1 ? this.moscowArea : this.petersburgArea)}
        >
          {locationId === 1 ? 'Московская область' : 'Ленинградская область'}
        </div>
      </div>
    );
  }
}
