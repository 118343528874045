import { call, cancel, fork, put, select, take, delay } from 'redux-saga/effects';

import { ERegionalDistrictsActionTypes } from './regionalDistricts';
import { ESuburbanOfferFilterActions } from './suburban_offer_filter';
import { IAppState, TActions } from '../../common/state';
import { prepareJsonQuery } from '../../services/fetchOffers';
import { saveJsonQueryInLocalStorage } from '../../utils/save_json_query';
import { IRequestMetaResponse, requestMeta } from '../api';

export interface IMetaRefreshRequestedAction {
  type: 'filters/meta/META_REFRESH_REQUESTED';
}

export interface IMetaRefreshCompleteAction {
  type: 'filters/meta/META_REFRESH_COMPLETE';
  meta: IRequestMetaResponse;
}

export interface IMetaRefreshFailedAction {
  type: 'filters/meta/META_REFRESH_FAILED';
}

export interface IMetaRefreshStoppedAction {
  type: 'filters/meta/META_REFRESH_STOPPED';
}

const actionsThatCouldChangeMeta: TActions['type'][] = [
  'filters/advanced/MODAL_CLOSED',
  'filters/advanced/TERM_CHANGED',
  'filters/area/MAX_AREA_CHANGED',
  'filters/area/MIN_AREA_CHANGED',
  'filters/balconies/BALCONIES_CHANGED',
  'filters/balconies/BALCONY_CHANGED',
  'filters/balconies/LOGGIA_CHANGED',
  'filters/ceilingHeight/MAX_CEILING_HEIGHT_CHANGED',
  'filters/ceilingHeight/MIN_CEILING_HEIGHT_CHANGED',
  'filters/comission/AGENT_CALLS_FORBIDDEN_CHANGED',
  'filters/comission/COMISSION_TYPE_CHANGED',
  'filters/currencyType/CURRENCY_TYPE_CHANGED',
  'filters/deal_type/DEAL_TYPE_CHANGED',
  'filters/decoration/DECORATION_TYPE_CHANGED',
  'filters/developer/DEVELOPER_TYPE_CHANGED',
  'filters/directions_modal/HIGHWAYS_CHANGED',
  'filters/districts/DISTRICT_NEW_MOSCOW_SWITCHED',
  'filters/districts/DISTRICT_REMOVED',
  'filters/districts/DISTRICT_SELECTED',
  'filters/done_year/BUILDING_DONE_CHANGED',
  'filters/done_year/BUILDING_DONE_IN_YEAR_CHANGED',
  'filters/done_year/BUILDING_DONE_LATER_CHANGED',
  'filters/dwelling_status/APARTMENT_CHANGED',
  'filters/dwelling_status/PENTHOUSE_CHANGED',
  'filters/elevators/ELEVATORS_CHANGED',
  'filters/elevators/SERVICE_LIFT_CHANGED',
  'filters/facilities/FACILITIES_CHANGED',
  'filters/facilities/FURNITURE_CHANGED',
  'filters/floor/IS_BASEMENT_CHANGED',
  'filters/floor/IS_FIRST_CHANGED',
  'filters/floor/IS_SEMIBASEMENT_CHANGED',
  'filters/floor/MAX_FLOOR_CHANGED',
  'filters/floor/MIN_FLOOR_CHANGED',
  'filters/floor/NOT_LAST_CHANGED',
  'filters/from_mkad/MAX_DISTANCE_CHANGED',
  'filters/from_mkad/MIN_DISTANCE_CHANGED',
  'filters/garage/GARAGE_KIND_CHANGED',
  'filters/garage/GARAGE_MATERIAL_CHANGED',
  'filters/garage/GARAGE_STATUS_CHANGED',
  'filters/garage/GARAGE_TYPE_CHANGED',
  'filters/geo/GEO_SELECTED',
  'filters/heating/HEATING_CHANGED',
  'filters/house_material/SET',
  'filters/house_material/TOGGLED',
  'filters/house_year/MAX_HOUSE_YEAR_CHANGED',
  'filters/house_year/MIN_HOUSE_YEAR_CHANGED',
  'filters/kp/KP_CHANGED',
  'filters/land_status/LAND_STATUS_CHANGED',
  'filters/layout/LAYOUT_TYPE_CHANGED',
  'filters/location_switcher/CITY_SELECTED',
  'filters/location_switcher/CITY_SUGGESTION_SELECTED',
  'filters/location_switcher/LOCATION_SWITCHER_CLOSED',
  'filters/location_switcher/REGION_SELECTED',
  'filters/map/MAP_AREA_DELETED_ACTION',
  'filters/map/MAP_CHANGED_ACTION',
  'filters/map/MAP_CLOSED',
  'filters/map/METRO_CLOSED',
  'filters/metro/METRO_REMOVED',
  'filters/metro/METRO_SELECTED',
  'filters/offer_id/OFFER_ID_CHANGED',
  'filters/offer_type/OFFER_TYPE_SELECTED',
  'filters/parking/CHANGED',
  'filters/parking/SET',
  'filters/phone/CONTACT_CHANGED',
  'filters/price/MAX_PRICE_CHANGED',
  'filters/price/MIN_PRICE_CHANGED',
  'filters/priceType/PRICE_TYPE_CHANGED',
  'filters/publish_period/PUBLISH_PERIOD_CHANGED',
  'filters/rent_terms/MAX_PREPAY_CHANGED',
  'filters/rent_terms/WITHOUT_DEPOSIT_CHANGED',
  'filters/rent_time/RENT_TIME_CHANGED',
  'filters/repair/REPAIR_TOGGLED',
  'filters/repair/SET',
  'filters/room/ROOM_CHANGED',
  'filters/rooms_in_deal/ROOMS_IN_DEAL_CHANGED',
  'filters/rooms_total/ROOMS_TOTAL_CHANGED',
  'filters/sale_type/MORTGAGE_CHANGED',
  'filters/sale_type/SALE_TYPE_CHANGED',
  'filters/sale_type/SALE_TYPE_SET',
  'filters/sort/SORT_CHANGED',
  'filters/suburban_utilities/SUBURBAN_UTILITIES_CHANGED',
  'filters/suburban_wc/SUBURBAN_WC_TYPE_CHANGED',
  'filters/tags/ALL_TAGS_REMOVED',
  'filters/tags/TAG_REMOVED',
  'filters/tenants/TENANTS_CHANGED',
  'filters/total_floors/MAX_TOTAL_FLOORS_CHANGED',
  'filters/total_floors/MIN_TOTAL_FLOORS_CHANGED',
  'filters/underground_travel/UNDERGROUND_TRAVEL_CHANGED',
  'filters/windows/WINDOWS_TYPE_CHANGED',
  'filters/with_layout/WITH_LAYOUT_CHANGED',
  'filters/with_neighbors/WITH_NEIGHBORS_CHANGED',
  'filters/with_photo/WITH_PHOTO_CHANGED',
  'filters/words_exclude/WORD_EXCLUDE_ADDED',
  'filters/words_exclude/WORD_EXCLUDE_REMOVED',
  'filters/words_include/WORD_INCLUDE_ADDED',
  'filters/words_include/WORD_INCLUDE_REMOVED',
  ESuburbanOfferFilterActions.Changed,
  ESuburbanOfferFilterActions.Removed,
  'filters/json_query/change',
  'filters/total_bedrooms/MIN_TOTAL_BEDROOMS_CHANGED',
  'filters/total_bedrooms/MAX_TOTAL_BEDROOMS_CHANGED',
  ERegionalDistrictsActionTypes.DebouncedUpdateMeta,
  'filters/FLAT_SHARE_CHANGED',
  'filters/builder/BUILDER_CHANGED',
];

const actionsThatShouldStopUpdateMeta = [
  'filters/advanced/MODAL_CLOSED',
  'filters/location_switcher/LOCATION_SWITCHER_CLOSED',
  'filters/map/MAP_CLOSED',
  'filters/map/METRO_CLOSED',
  ERegionalDistrictsActionTypes.CloseModal,
];

export const metaRefreshRequest: IMetaRefreshRequestedAction = {
  type: 'filters/meta/META_REFRESH_REQUESTED',
};

export const metaRefreshFailed: IMetaRefreshFailedAction = {
  type: 'filters/meta/META_REFRESH_FAILED',
};

export const metaRefreshStopped: IMetaRefreshStoppedAction = {
  type: 'filters/meta/META_REFRESH_STOPPED',
};

export function updateMeta(meta: IRequestMetaResponse): IMetaRefreshCompleteAction {
  return {
    type: 'filters/meta/META_REFRESH_COMPLETE',
    meta,
  };
}

export function* updateMetaSaga() {
  const { filters }: IAppState = yield select();

  /**
   * metaRefreshRequest должен отправляться до delay, иначе возникают баги с обновлением location при сортировке.
   * Если meta в данный момент уже обновлятся - экшен metaRefreshRequest избыточен.
   */
  if (!filters.jsonQueryCountRefresing) {
    yield put(metaRefreshRequest);
  }

  /**
   * delay нужен для уменьшения количества запросов при наборе текста в фильтрах.
   * См. задачу CD-66421
   */
  yield delay(300);
  try {
    const state: IAppState = yield select();
    const {
      filters: { jsonQuery, currentLocation },
      makeRequest,
    } = state;

    const meta: IRequestMetaResponse = yield call(
      requestMeta,
      makeRequest,
      prepareJsonQuery({ jsonQuery, location: currentLocation }),
    );
    const metaRefreshComplete: IMetaRefreshCompleteAction = {
      type: 'filters/meta/META_REFRESH_COMPLETE',
      meta,
    };

    saveJsonQueryInLocalStorage(jsonQuery);

    yield put(metaRefreshComplete);
  } catch (e) {
    yield put(metaRefreshFailed);
  }
}

/* istanbul ignore next */
export function* metaSaga() {
  let lastTask;
  while (true) {
    const action: TActions = yield take(actionsThatCouldChangeMeta);
    if (lastTask) {
      yield cancel(lastTask);
    }

    if (!actionsThatShouldStopUpdateMeta.includes(action.type)) {
      // @ts-expect-error тест
      lastTask = yield fork(updateMetaSaga);
    } else {
      yield put(metaRefreshStopped);
    }
  }
}

/* istanbul ignore next */
export function metaReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/meta/META_REFRESH_REQUESTED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQueryCountRefresing: true,
        },
      };
    case 'filters/meta/META_REFRESH_COMPLETE':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQueryCount: action.meta.count,
          jsonQueryCountRefresing: false,
          jsonQueryFullUrl: action.meta.fullUrl,
          jsonQueryUrl: action.meta.url,
          queryString: action.meta.fullUrlWithoutSeo.substring(action.meta.fullUrlWithoutSeo.indexOf('?' + 1)),
          metaHeader: action.meta.h1,
        },
      };
    case 'filters/meta/META_REFRESH_FAILED':
    case 'filters/meta/META_REFRESH_STOPPED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQueryCount: undefined,
          jsonQueryCountRefresing: false,
        },
      };
    case 'filters/search/SEARCH_REQUEST_FINISHED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQueryCount: undefined,
          jsonQueryCountRefresing: false,
        },
      };
    default:
      return state;
  }
}
