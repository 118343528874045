import { Button } from '@cian/ui-kit/button';
import { ModalWindow } from '@cian/ui-kit/modal';
import { Header2, InlineText1 } from '@cian/ui-kit/typography';
import * as React from 'react';

import * as styles from './styles.css';

interface IErrorModalProps {
  open: boolean;
  onClose(): void;
}

export function ErrorModal({ open, onClose }: IErrorModalProps) {
  return (
    <ModalWindow open={open} onClose={onClose}>
      <div className={styles['container']}>
        <Header2 as="span">
          Оставьте свой номер телефона <br />и вам перезвонят
        </Header2>
        <div className={styles['warning']}>
          <InlineText1>Что-то пошло не так 🤷‍♂️</InlineText1>
        </div>
        <Button size="XS" theme="fill_primary" onClick={() => window.location.reload()}>
          Перезагрузить страницу
        </Button>
      </div>
    </ModalWindow>
  );
}
