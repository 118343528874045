import { IJsonQuery } from '../../json_query';
import { offerHelper } from '../../offer/presenters';
import { IOffer } from '../../offer/types';
import { ILocation } from '../../types/location';
import { RegularResponse, returnResponseDataIfOk } from '../../utils/api';
import { IMakeRequest } from '../../utils/request';

export interface IRequestMetaResponse {
  count: number;
  url: string;
  fullUrl: string;
  fullUrlWithoutSeo: string;
  h1: string;
}

export function requestMeta(makeRequest: IMakeRequest, reqBody: IJsonQuery): Promise<IRequestMetaResponse> {
  return makeRequest<RegularResponse<IRequestMetaResponse>>({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/offers/search/meta/',
    parameters: reqBody,
    method: 'POST',
  }).then(returnResponseDataIfOk);
}

export interface IFavoriteChangeResponse {
  status: string;
  count: {
    total: number;
  };
  folderInfo: {
    folder: {
      id?: number;
      name?: string;
    };
    hasFolders: boolean;
  };
  newbuilding_id?: number;
}

export const changeFavorite = (
  makeRequest: IMakeRequest,
  offer: IOffer,
  isFavorite: boolean,
): Promise<IFavoriteChangeResponse> => {
  const helper = offerHelper(offer);

  const dealType = helper.isSale() ? 'sale' : 'rent';

  let offerType = 'flat';
  if (helper.isCommercial()) {
    offerType = 'commercial';
  } else if (helper.isSuburban()) {
    offerType = 'suburban';
  }

  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: `/ajax/${dealType}/${offerType}/favorites`,
    parameters: {
      action: isFavorite ? 'assign' : 'remove',
      oid: offer.cianId || offer.id,
      addToFolder: isFavorite ? true : undefined,
    },
    headers: [['Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8']],
    bodyAsEncodeString: true,
    method: 'POST',
  }).then(
    (res: IFavoriteChangeResponse) => {
      return res;
    },
    err => {
      return err;
    },
  );
};

export interface IRequestCitiesResponse {
  items: ILocation[];
}

export function requestCities(makeRequest: IMakeRequest, regionId: number): Promise<IRequestCitiesResponse> {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/get-region-cities/',
    parameters: {
      regionId,
    },
    method: 'GET',
  }).then((res: { data: IRequestCitiesResponse }) => {
    return res.data;
  });
}

export interface ISearchLocationsResponse {
  items: ILocation[];
}

export function searchLocations(makeRequest: IMakeRequest, text: string): Promise<ISearchLocationsResponse> {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/search-regions-cities/',
    parameters: {
      text,
    },
    method: 'GET',
  }).then((res: { data: ISearchLocationsResponse }) => {
    return res.data;
  });
}
