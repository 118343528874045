import * as React from 'react';

export type TSelectOptionValue = string | number;
export type TSelectValue = TSelectOptionValue | TSelectOptionValue[] | null | undefined;

export type TOptionChild = React.ReactNode;

export interface IOptionProps {
  value: TSelectOptionValue;
  children: TOptionChild | TOptionChild[];
  marker?: string;
}

export interface ISelectOption {
  value: TSelectOptionValue;
  label: string;
  marker?: string;
}

export type TSelectChild = React.ReactElement<IOptionProps>;

type TSelectPropsTemp = {
  tabIndex?: number;
  multiple?: boolean;
  disabled?: boolean;
  value?: TSelectValue;
  withoutArrow?: boolean;
} & {
  placeholder?: string;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?(e: React.ChangeEvent<HTMLSelectElement>, value: TSelectValue): any;
};

export interface ISelectCustom {
  invalid?: boolean;
  size?: EVisualSize;
}

export interface ISelectStyles {
  width?: string;
}

export interface ISelectProps extends TSelectPropsTemp {
  open?: boolean;
  children: React.ReactNode;
  /**
   * Data метка для компонента(применяется в основном для автотестов)
   * */
  dataMark?: string;
  custom?: ISelectCustom;
  styles?: ISelectStyles;
  popupPostfix?: React.ReactNode;
  buttonAppearance?: TSelectButtonAppearance;
  hideTooltipCount?: boolean;
  offersCount?: number;
  offersCountLoading?: boolean;
  openTooltip?: boolean;
  onApply?(): void;
  onOpen?(): void;
  onClose?(): void;
}

export enum EVisualSize {
  DESKTOP,
  MOBILE,
  ADAPTIVE,
}

export type TSelectButtonAppearance = 'default' | 'underlined';
