import { ArticleParagraph1, Button, ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './NotAvailablePopup.css';

interface INotAvailablePopupProps {
  onClose(): void;
  onWriteMessage(): void;
  onCallAnyway(): void;
  onShow(): void;
  title: string;
  message: string;
  chatsEnabled: boolean;
}

export const NotAvailablePopup: React.VFC<INotAvailablePopupProps> = ({
  onClose,
  onWriteMessage,
  onCallAnyway,
  onShow,
  title,
  message,
  chatsEnabled,
}) => {
  React.useEffect(onShow, [onShow]);

  return (
    <ModalWindow
      open={true}
      onClose={onClose}
      title={title}
      width={637}
      content={<ArticleParagraph1>{message}</ArticleParagraph1>}
      footer={
        <div className={styles['buttons']}>
          {chatsEnabled && <Button onClick={onWriteMessage}>Написать сообщение</Button>}
          <Button theme="fill_white_primary" onClick={onCallAnyway}>
            Всё равно позвонить
          </Button>
        </div>
      }
    />
  );
};
