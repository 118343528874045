import * as React from 'react';

import { getTermValue } from '../../../../../JsonQuery';
import { OnlineBooking } from '../../../components/advancedFilters/OnlineBooking';
import { useContext } from '../../../utils/useContext';

export const OnlineBookingContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const onlineBooking = getTermValue('online_booking')(jsonQuery);

  const handleChange = React.useCallback(
    (value: boolean | null) => {
      onChange({ action: 'setOnlineBooking', arguments: [value] });
    },
    [onChange],
  );

  return <OnlineBooking onlineBooking={onlineBooking} onChange={handleChange} />;
};
