import { intersection } from 'ramda';

import {
  ERoomType,
  EBuildingStatus,
  EObjectType,
  EOfficeType,
  ECategoryType,
} from '../../api-models/common/json_query';
import { getTermsValue, getTermValue } from '../helpers';
import { FOfferType, TJsonQuery } from '../types';

export const offerTypeFromJsonQuery = (jsonQuery: TJsonQuery): FOfferType => {
  const { _type: type } = jsonQuery;

  if (type.startsWith('flat')) {
    const room = getTermsValue('room')(jsonQuery);
    const buildingStatus = getTermValue('building_status')(jsonQuery);
    const fromDeveloper = getTermValue('from_developer')(jsonQuery);
    const withNewbuildings = getTermValue('with_newobject')(jsonQuery);
    const showHotels = getTermValue('show_hotels')(jsonQuery);

    if (showHotels) {
      return FOfferType.Hotel;
    }

    if (room && room.some(r => [ERoomType.FlatShared, ERoomType.Room, ERoomType.Bed].includes(r))) {
      let offerType = FOfferType.Unexpected;

      if (room.includes(ERoomType.FlatShared)) {
        offerType |= FOfferType.FlatShared;
      }

      if (room.includes(ERoomType.Room)) {
        offerType |= FOfferType.Room;
      }

      if (room.includes(ERoomType.Bed)) {
        offerType |= FOfferType.Bed;
      }

      return offerType;
    }

    if (buildingStatus && buildingStatus === EBuildingStatus.New) {
      return FOfferType.FlatNew;
    }

    if (fromDeveloper !== null) {
      return FOfferType.FlatNew;
    }

    if (withNewbuildings === true) {
      return FOfferType.FlatNew;
    }

    if (buildingStatus && buildingStatus === EBuildingStatus.Old) {
      return FOfferType.FlatOld;
    }

    if (withNewbuildings === false) {
      return FOfferType.FlatOld;
    }

    if (isRent(jsonQuery)) {
      return FOfferType.FlatOld;
    }

    if (type === 'flatsale') {
      return FOfferType.Flat;
    }

    return FOfferType.Urban;
  }

  if (type.startsWith('suburban')) {
    const objectType = getTermsValue('object_type')(jsonQuery);

    if (objectType) {
      let offerType = FOfferType.Unexpected;

      if (objectType.includes(EObjectType.House)) {
        offerType |= FOfferType.House;
      }

      if (objectType.includes(EObjectType.Housepart)) {
        offerType |= FOfferType.HousePart;
      }

      if (objectType.includes(EObjectType.Townhouse)) {
        offerType |= FOfferType.Townhouse;
      }

      if (objectType.includes(EObjectType.Land)) {
        if (isRent(jsonQuery)) {
          offerType |= FOfferType.CommercialLand;
        } else {
          offerType |= FOfferType.Land;
        }
      }

      return offerType;
    }

    return FOfferType.Suburban;
  }

  if (type.startsWith('commercial')) {
    const officeType = getTermsValue('office_type')(jsonQuery);
    const category = getTermsValue('category')(jsonQuery);

    if (officeType) {
      let offerType = FOfferType.Unexpected;

      if (officeType.includes(EOfficeType.Office)) {
        offerType |= FOfferType.Office;
      }

      if (officeType.includes(EOfficeType.TradeArea)) {
        offerType |= FOfferType.TradeArea;
      }

      if (officeType.includes(EOfficeType.Warehouse)) {
        offerType |= FOfferType.Warehouse;
      }

      if (officeType.includes(EOfficeType.FreeAppointmentObject)) {
        offerType |= FOfferType.FreeAppointmentObject;
      }

      if (officeType.includes(EOfficeType.PublicCatering)) {
        offerType |= FOfferType.PublicCatering;
      }

      if (officeType.includes(EOfficeType.Garage)) {
        offerType |= FOfferType.Garage;
      }

      if (officeType.includes(EOfficeType.Manufacture)) {
        offerType |= FOfferType.Manufacture;
      }

      if (officeType.includes(EOfficeType.AutoService)) {
        offerType |= FOfferType.AutoService;
      }

      if (officeType.includes(EOfficeType.Business)) {
        offerType |= FOfferType.Business;
      }

      if (officeType.includes(EOfficeType.Building)) {
        offerType |= FOfferType.Building;
      }

      if (officeType.includes(EOfficeType.DomesticServices)) {
        offerType |= FOfferType.DomesticServices;
      }

      if (officeType.includes(EOfficeType.Coworking)) {
        offerType |= FOfferType.Coworking;
      }

      return offerType;
    }

    if (category && intersection([ECategoryType.CommercialLandRent, ECategoryType.CommercialLandSale], category)) {
      return FOfferType.CommercialLand;
    }

    return FOfferType.Commercial;
  }

  return FOfferType.Unexpected;
};

function isRent(jsonQuery: TJsonQuery): boolean {
  return jsonQuery._type.endsWith('rent');
}
