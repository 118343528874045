import { isInRegionSelector } from './isInRegion';
import { selectIsUserAgent } from '../../packages/Filters/shared/selectors';
import { IAppState } from '../common/state';
import { moscowMoIds } from '../utils/geo';

export const selectIsMultiplatformProfessionalSearchBannerVisible = (state: IAppState) => {
  const isAgent = selectIsUserAgent(state);
  const isInRegion = !isInRegionSelector([...moscowMoIds, -1])(state);

  return isAgent && isInRegion;
};
