import { selectIsSeoUrlOnMapExperimentEnabled } from './experiments/selectIsSeoUrlOnMapExperimentEnabled';
import { selectQueryString } from './results';
import { IAppState } from '../common/state';

export function selectMapUrl(state: IAppState): string {
  const queryString = selectQueryString(state);

  /**
   * Эксперимент "Карта на ссылках ЧПУ" AUD-2284
   * @todo удалить в рамках CD-168864
   */
  const isSeoUrlExperimentEnabled = selectIsSeoUrlOnMapExperimentEnabled(state);
  const url = (state.results.qsToUris || {})[queryString];

  if (isSeoUrlExperimentEnabled) {
    return `${url}?mapView=1`;
  }

  return ['/map/', queryString].filter(Boolean).join('?');
}
