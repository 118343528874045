/**
 * Класс создает промис и дает возможность управлять этим промисом извне
 * с помощью публичных методов reject и resolve.
 *  */
export class Defer<T = void> {
  public resolve: (value: T) => void;
  public reject: (value: Error | unknown) => void;
  public promise: Promise<T>;

  public constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}
