import { isSuburban, JsonQuery, offerTypeFromJsonQuery } from '../../../../packages/JsonQuery';
import { TLocation } from '../../../types/location';
import { getRegionId } from '../../../utils/geo';

interface IParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jsonQuery: any;
  location: TLocation;
}

/**
 * Это хак, добавленный в CD-178132. Нужен, чтобы пофиксить работу фильтров
 * при отсутствии в jsonQuery параметров, позволяющих определить регион.
 * Должно быть исправлено в AUD-2226.
 */
export const prepareJsonQuery = ({ jsonQuery, location }: IParams) => {
  // Используется только для загородки
  if (!isSuburban(offerTypeFromJsonQuery(jsonQuery))) {
    return jsonQuery;
  }

  let preparedJsonQuery = jsonQuery;
  const { region, geo, id_user } = jsonQuery;
  // Дополнительно проверяем, что не используется фильтр
  // по автору объявления `id_user`.
  const shouldAddRegion = !region && !geo && !id_user;

  if (shouldAddRegion) {
    preparedJsonQuery = new JsonQuery(jsonQuery).setRegion([getRegionId(location)]).toJSON();
  }

  return preparedJsonQuery;
};
