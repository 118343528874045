import React from 'react';

import { Thumbnails } from '../../../components/Thumbnails';
import { useOfferContext } from '../../../context';

export function ThumbnailsContainer() {
  const { thumbnails, media } = useOfferContext();

  if (thumbnails === null) {
    return null;
  }

  return <Thumbnails media={thumbnails} hasMore={media.length > 4} />;
}
