import { setCookie } from '@cian/utils/lib/browser/cookie';
import * as React from 'react';

import { Button } from '../../../common/components/button';

const style = require('./index.css');

export interface IPromoteMobileState {
  isClosed: boolean;
}

export class PromoteMobile extends React.Component<{}, IPromoteMobileState> {
  public state = {
    isClosed: false,
  };

  public render() {
    if (this.state.isClosed) {
      return null;
    }

    return (
      <div className={style.wrapper}>
        <div className={style.container}>
          <button className={style['close']} onClick={this.onClose}>
            &times;
          </button>
          <h3 className={style['header']}>Воспользуйтесь версией ЦИАН для планшетов</h3>
          <p className={style['text']}>
            Мы рекомендуем использовать версию ЦИАН, разработанную специально под планшеты, для удобства и быстроты
            поиска.
          </p>
          <Button theme="primary" mode="outline" size="large" onClick={redirectToMobile}>
            Перейти к версии для планшетов
          </Button>
        </div>
      </div>
    );
  }

  private onClose = () => {
    setCookie('promote_mobile_closed', 'true', { expires: 14, domain: '.cian.ru' });

    this.setState({
      isClosed: true,
    });
  };
}

function redirectToMobile() {
  setCookie('flavour', 'mobile', { domain: '.cian.ru' });
  location.replace(location.href);
}
