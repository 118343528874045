import { getHouses } from './getHouses';
import { IAppState } from '../../common/state';
import { IHouse } from '../../types/newbuildingHeader';

export const getHouseById =
  (id: number) =>
  // eslint-disable-next-line react/display-name
  (state: IAppState): IHouse | null => {
    const houses = getHouses(state);

    if (!houses || houses.length === 0) {
      return null;
    }

    return houses.filter(house => house.id === id)[0];
  };
