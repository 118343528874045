import { ITypedReduxAction } from '../../../types/redux';
import { IRegionalDistrict, ISelectedRegionalDistrict } from '../../../types/regionalDistricts';

export enum ERegionalDistrictsActionTypes {
  CloseModal = 'filters/regionalDistricts/MODAL_CLOSED',
  OpenModal = 'filters/regionalDistricts/OPEN_MODAL',
  SetRegionalDistricts = 'filters/regionalDistricts/SET_DISTRICTS',
  AddRegionalDistrict = 'filters/regionalDistricts/ADD_DISTRICT',
  RemoveRegionalDistrict = 'filters/regionalDistricts/REMOVE_DISTRICT',
  ApplyRegionalDistricts = 'filters/regionalDistricts/APPLY_DISTRICTS',
  SetRegionalDistrictsError = 'filters/regionalDistricts/SET_ERROR',
  SetRegionalDistrictsReady = 'filters/regionalDistricts/SET_READY',
  DebouncedUpdateMeta = 'filters/regionalDistricts/UPDATE_META',
}

export type TCloseModalAction = ITypedReduxAction<ERegionalDistrictsActionTypes.CloseModal>;

export type TOpenModalAction = ITypedReduxAction<ERegionalDistrictsActionTypes.OpenModal>;

export type TSetRegionalDistrictsAction = ITypedReduxAction<
  ERegionalDistrictsActionTypes.SetRegionalDistricts,
  IRegionalDistrict[]
>;

export type TAddRegionalDistrictAction = ITypedReduxAction<
  ERegionalDistrictsActionTypes.AddRegionalDistrict,
  ISelectedRegionalDistrict
>;

export type TRemoveRegionalDistrictAction = ITypedReduxAction<
  ERegionalDistrictsActionTypes.RemoveRegionalDistrict,
  ISelectedRegionalDistrict
>;

export type TApplyRegionalDistrictsAction = ITypedReduxAction<ERegionalDistrictsActionTypes.ApplyRegionalDistricts>;

export type TSetRegionalDistrictsErrorAction =
  ITypedReduxAction<ERegionalDistrictsActionTypes.SetRegionalDistrictsError>;

export type TSetRegionalDistrictsReadyAction =
  ITypedReduxAction<ERegionalDistrictsActionTypes.SetRegionalDistrictsReady>;

export type TDebouncedUpdateMeta = ITypedReduxAction<ERegionalDistrictsActionTypes.DebouncedUpdateMeta>;

export type TRegionalDistrictsActions =
  | TCloseModalAction
  | TOpenModalAction
  | TSetRegionalDistrictsAction
  | TAddRegionalDistrictAction
  | TRemoveRegionalDistrictAction
  | TApplyRegionalDistrictsAction
  | TSetRegionalDistrictsErrorAction
  | TSetRegionalDistrictsReadyAction
  | TDebouncedUpdateMeta;
