import { ISaleTypeType } from './types';
import { FOfferType } from '../../../../../JsonQuery';
import { ESaleType } from '../../../types/jsonQuery';

const FLAT_NEW_LIST: ISaleTypeType[] = [
  { label: 'Неважно', value: null },
  { label: '214-Ф3', value: ESaleType.F3 },
  { label: 'Договор ЖСК', value: ESaleType.GSKContract },
  { label: 'Переуступка', value: ESaleType.Reassignment },
];

const OTHERS_LIST: ISaleTypeType[] = [
  { label: 'Неважно', value: null },
  { label: 'Свободная', value: ESaleType.Free },
  { label: 'Альтернативная', value: ESaleType.Alternative },
];

export function getSaleTypesList(offerType: FOfferType): ISaleTypeType[] {
  if (offerType === FOfferType.FlatNew) {
    return FLAT_NEW_LIST;
  }

  return OTHERS_LIST;
}
