import * as React from 'react';

import { IGeoStreetTag } from '../../../../state/tags/definitions/geo';
import { TagNew } from '../../TagNew';

export interface IGeoStreetTagItemProps {
  tag: IGeoStreetTag;
  onDeleteClick(): void;
}

export const GeoStreetTagItem = (props: IGeoStreetTagItemProps) => {
  return <TagNew onDeleteClick={props.onDeleteClick}>{props.tag.text}</TagNew>;
};
