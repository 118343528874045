import { ElectronicTradingWidgetProps } from '@cian/frontend-electronic-trading-widget';
import { useToggleState } from '@cian/react-utils';
import { Button, ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import { ElectronicTradingWidgetLoadable } from '../ElectronicTradingWidgetLoadable';
import { ElectronicTradingWidgetLoadableFallback } from '../ElectronicTradingWidgetLoadableFallback';

const MODAL_WIDTHS = {
  narrow: 498,
  wide: 680,
};

type JoinElectronicTradingButtonProps = ElectronicTradingWidgetProps & {
  trackShowTradingWidgetModal: VoidFunction;
};

export const JoinElectronicTradingButton: React.FC<JoinElectronicTradingButtonProps> = props => {
  const { trackShowTradingWidgetModal, ...widgetProps } = props;
  const { state: isModalVisible, toggle: toggleModalVisibility } = useToggleState(false);

  const handleClick = React.useCallback(() => {
    toggleModalVisibility();
    trackShowTradingWidgetModal();
  }, [toggleModalVisibility, trackShowTradingWidgetModal]);

  return (
    <>
      {isModalVisible && (
        <ModalWindow
          fixed
          content={
            <ElectronicTradingWidgetLoadable fallback={<ElectronicTradingWidgetLoadableFallback />}>
              {(Lib): JSX.Element => <Lib.ElectronicTradingWidget {...widgetProps} />}
            </ElectronicTradingWidgetLoadable>
          }
          open
          size="M"
          title="Переходим к электронным торгам"
          width={widgetProps.publisherPhoneNumbers.length ? MODAL_WIDTHS.wide : MODAL_WIDTHS.narrow}
          onClose={toggleModalVisibility}
        />
      )}
      <Button
        fullWidth
        data-testid="electronic-trading.join-trading"
        size="XS"
        theme="fill_primary"
        onClick={handleClick}
      >
        Принять участие в торгах
      </Button>
    </>
  );
};
