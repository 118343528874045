import { ca } from '@cian/analytics';

import { IBrokerAnalyticsUser } from './types';
import { TJsonQuery } from '../../../../packages/JsonQuery';

export const trackSellApartmentClick = (sc: TJsonQuery, user: IBrokerAnalyticsUser, isSnippet = false) => {
  const extra = isSnippet ? { is_snippet: true } : undefined;

  ca('eventSite', {
    name: 'oldevent',
    category: 'BrokerDeal',
    action: 'Click',
    label: 'Button',
    sc,
    page: {
      pageType: 'Listing',
      siteType: 'desktop',
      extra,
    },
    user,
    useLastProducts: true,
  });
};
