import { IOffer, TLandStatus } from '../../../types/offer';
import { isSuburbanSale } from '../category/isSuburbanSale';

type TLandStatusesWithName = Extract<
  TLandStatus,
  | 'individualHousingConstruction'
  | 'farm'
  | 'gardening'
  | 'industrialLand'
  | 'suburbanNonProfitPartnership'
  | 'investmentProject'
>;

const LAND_STATUS_NAMES_MAP: {
  [key in TLandStatusesWithName]: string;
} = {
  farm: 'Фермерское хозяйство',
  individualHousingConstruction: 'ИЖС',
  gardening: 'Садоводство',
  industrialLand: 'Земля промназначения',
  suburbanNonProfitPartnership: 'ДНП',
  investmentProject: 'Инвестпроект',
};

export function getLandStatus(offer: IOffer): string | undefined {
  const { land, category } = offer;
  const landStatus = (land || {}).status;
  const isLand = category && category.indexOf('land') >= 0;
  const isHouseSale = isSuburbanSale(offer);

  if ((isLand || isHouseSale) && landStatus && landStatus in LAND_STATUS_NAMES_MAP) {
    return LAND_STATUS_NAMES_MAP[landStatus as TLandStatusesWithName];
  }

  return undefined;
}
