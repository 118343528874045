import { Outside, Button, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './SuburbanArea.css';
import { getAreaLabel } from './helpers';
import { FOfferType } from '../../../../../JsonQuery';
import { IJsonQueryRangeValue } from '../../../types/jsonQuery';
import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { RangeInput } from '../../RangeInput';
import { SearchResultsTooltip } from '../../SearchResultsTooltip';

interface ISuburbanAreaProps {
  offerType: FOfferType;
  houseArea: IJsonQueryRangeValue | null;
  landArea: IJsonQueryRangeValue | null;
  open?: boolean;
  offersCount?: number;
  offersCountLoading?: boolean;
  openTooltip?: boolean;
  onHouseAreaMinChange(value: number | null): void;
  onHouseAreaMaxChange(value: number | null): void;
  onLandAreaMinChange(value: number | null): void;
  onLandAreaMaxChange(value: number | null): void;
  onApply?(): void;
  onOpen?(): void;
  onClose?(): void;
}

export const SuburbanArea: React.FC<ISuburbanAreaProps> = ({
  offerType,
  houseArea,
  landArea,
  open,
  offersCount,
  offersCountLoading,
  openTooltip,
  onHouseAreaMinChange,
  onHouseAreaMaxChange,
  onLandAreaMinChange,
  onLandAreaMaxChange,
  onApply,
  onOpen,
  onClose,
}) => {
  const { gte: houseAreaMin = null, lte: houseAreaMax = null } = houseArea || {};
  const { gte: landAreaMin = null, lte: landAreaMax = null } = landArea || {};

  const areaLabel = getAreaLabel({ houseAreaMin, houseAreaMax, landAreaMin, landAreaMax });

  /* istanbul ignore next */
  const handleClick = React.useCallback(() => {
    if (!open && onOpen) {
      onOpen();
    } else if (open && onClose) {
      onClose();
    }
  }, [open, onOpen, onClose]);

  /* istanbul ignore next */
  const handleClose = React.useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const isHouseAreaAvailable = (offerType & ~FOfferType.Land) !== 0;

  const handleHouseAreaMinChange = useDebouncedCallback(
    (gte: number | null) => {
      onHouseAreaMinChange(gte);
    },
    300,
    [onHouseAreaMinChange],
  );

  const handleHouseAreaMaxChange = useDebouncedCallback(
    (lte: number | null) => {
      onHouseAreaMaxChange(lte);
    },
    300,
    [onHouseAreaMaxChange],
  );

  const handleLandAreaMinChange = useDebouncedCallback(
    (gte: number | null) => {
      onLandAreaMinChange(gte);
    },
    300,
    [onLandAreaMinChange],
  );

  const handleLandAreaMaxChange = useDebouncedCallback(
    (lte: number | null) => {
      onLandAreaMaxChange(lte);
    },
    300,
    [onLandAreaMaxChange],
  );

  return (
    <Outside onOutside={handleClose} active={open}>
      <div className={styles['container']}>
        <SearchResultsTooltip
          open={!open && openTooltip}
          offersCount={offersCount || 0}
          loading={offersCountLoading}
          placement="bottom-start"
          onApply={onApply}
        >
          <Button theme="stroke_secondary" size="XS" title={areaLabel} onClick={handleClick}>
            {areaLabel}
          </Button>
        </SearchResultsTooltip>
        {open && (
          <SearchResultsTooltip
            open={openTooltip}
            offersCount={offersCount || 0}
            loading={offersCountLoading}
            placement="right"
            onApply={onApply}
          >
            <div className={styles['dropdown']} data-name="SuburbanAreaDropdown">
              {isHouseAreaAvailable ? (
                <div className={styles['dropdown-filter']}>
                  <UIText2 fontWeight="bold">Дом</UIText2>
                  <RangeInput
                    min={1}
                    max={99999}
                    valueMin={houseAreaMin}
                    valueMax={houseAreaMax}
                    onChangeMin={handleHouseAreaMinChange}
                    onChangeMax={handleHouseAreaMaxChange}
                    postfix="м²"
                    joined={false}
                  />
                  <UIText2 fontWeight="bold">Участок</UIText2>
                  <RangeInput
                    min={1}
                    max={999999}
                    valueMin={landAreaMin}
                    valueMax={landAreaMax}
                    onChangeMin={handleLandAreaMinChange}
                    onChangeMax={handleLandAreaMaxChange}
                    postfix="сот."
                    joined={false}
                  />
                </div>
              ) : (
                <RangeInput
                  min={1}
                  max={999999}
                  valueMin={landAreaMin}
                  valueMax={landAreaMax}
                  onChangeMin={handleLandAreaMinChange}
                  onChangeMax={handleLandAreaMaxChange}
                  postfix="сот."
                  joined={false}
                />
              )}
            </div>
          </SearchResultsTooltip>
        )}
      </div>
    </Outside>
  );
};
