import { ca } from '@cian/analytics';

import { IBrokerAnalyticsUser } from './types';

export const trackFallbackModalRetryClick = (user: IBrokerAnalyticsUser) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'BrokerDeal',
    action: 'Click',
    label: 'BrokerDealPopUpRetry',
    page: {
      pageType: 'Listing',
      siteType: 'desktop',
      extra: {
        is_link: false,
      },
    },
    user,
    useLastProducts: true,
  });
};
