export function isSubdomainRedirectNeeded(fullUrl?: string): boolean {
  if (!fullUrl) {
    return false;
  }

  const parsedFullUrl = new URL(fullUrl);

  // Если пришел ЧПУ и поменялся домен, то нужно сделать редирект
  return !fullUrl.startsWith('/cat.php') && parsedFullUrl.hostname !== window.location.hostname;
}
