import { ca } from '@cian/analytics';

import { EComparisonNotificationCategory } from '../../../state/offersComparison';

interface ITrackClickPopup {
  offerId: number;
  category: EComparisonNotificationCategory;
}

export function trackClickPopup({ offerId, category }: ITrackClickPopup) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Compare',
    action: `click_popup_${category}`,
    label: 'to_compare',
    products: [
      {
        id: offerId,
        offerType: 'offer',
      },
    ],
  });
}
