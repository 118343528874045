export type TListElements = Record<string, HTMLLabelElement>;

export enum ERegionalDistrictsStatus {
  Initial = 'initial',
  Loading = 'loading',
  Failed = 'failed',
  Success = 'success',
  Ready = 'ready',
}

export interface ISelectedRegionalDistrict {
  id: number;
  name: string;
}
