import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackProfessionalSearchClick } from './tracking';
import { ProfessionalSearchPromo } from '../../components/ProfessionalSearchPromo';
import { selectProfessionalSearchUrl } from '../../selectors/selectProfessionalSearchUrl';

export const ProfessionalSearchPromoContainer = () => {
  const link = useSelector(selectProfessionalSearchUrl);

  return <ProfessionalSearchPromo link={link} onClick={trackProfessionalSearchClick} />;
};
