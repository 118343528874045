import { EPageTypes, EPlatformTypes, handleDynamicCalltracking } from '@cian/frontend-dynamic-calltracking-component';
import { IHttpApi } from '@cian/http-api';
import { ILogger } from '@cian/logger';
import { pathOr } from 'ramda';

import { ICalltrackingPlaceTypes } from '../types/dynamic-calltracking';

interface IHandleDynamicCalltrackin {
  httpApi: IHttpApi;
  logger: ILogger;
  blockId: number;
  placeType: string;
  announcementId?: number | null;
  queryString?: string | null;
  dynamicCalltrackingPlaceType?: ICalltrackingPlaceTypes;
}

export async function getDynamicCalltrackingPhone(props: IHandleDynamicCalltrackin): Promise<{
  phone: string | null;
}> {
  const response = await handleDynamicCalltracking({
    httpApi: props.httpApi,
    logger: props.logger,
    blockId: props.blockId,
    platformType: EPlatformTypes.WebDesktop,
    pageType: EPageTypes.OfferList,
    placeType: props.dynamicCalltrackingPlaceType || 'card',
    announcementId: props.announcementId,
    queryString: props.queryString,
  });

  return {
    phone: pathOr(null, ['notFormattedPhone'], response),
  };
}
