import classNames from 'classnames';
import * as React from 'react';

import * as styles from './Filter.css';

type TFilterProps = Pick<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'className' | 'id'
>;

export const Filter: React.FC<React.PropsWithChildren<TFilterProps>> = props => {
  const className = classNames(styles['container'], props.className);

  return (
    <div className={className} id={props.id}>
      {props.children}
    </div>
  );
};
