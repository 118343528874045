import * as React from 'react';

import { getTermValue } from '../../../../../JsonQuery';
import { RentTerms } from '../../../components/advancedFilters/RentTerms';
import { EMaxPrepay } from '../../../types/jsonQuery';
import { useContext } from '../../../utils/useContext';

export const RentTermsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const prepay = getTermValue('prepay')(jsonQuery);
  const withDeposit = getTermValue('zalog')(jsonQuery);
  const withoutCommission = getTermValue('commission_type')(jsonQuery);

  const handlePrepayChange = React.useCallback(
    (prepay: EMaxPrepay | null) => {
      onChange({ action: 'setPrepay', arguments: [prepay] });
    },
    [onChange],
  );

  const handleWithDepositChange = React.useCallback(
    (withDeposit: boolean | null) => {
      onChange({ action: 'setWithDeposit', arguments: [withDeposit] });
    },
    [onChange],
  );

  const handleCommissionTypeChange = React.useCallback(
    (commissionType: number | null) => {
      onChange({ action: 'setCommissionType', arguments: [commissionType] });
    },
    [onChange],
  );

  return (
    <RentTerms
      prepay={prepay}
      withDeposit={withDeposit}
      commissionType={withoutCommission}
      onPrepayChange={handlePrepayChange}
      onWithDepositChange={handleWithDepositChange}
      onCommissionTypeChange={handleCommissionTypeChange}
    />
  );
};
