import { IAppState, TActions } from '../../../common/state';
import { ELayoutType } from '../../../json_query';

export const ILayoutTypeChangedActionType = 'filters/layout/LAYOUT_TYPE_CHANGED';

export interface ILayoutTypeChangedAction {
  type: 'filters/layout/LAYOUT_TYPE_CHANGED';
  layoutType: ELayoutType;
}

export function changeLayoutType(layoutType: ELayoutType): ILayoutTypeChangedAction {
  return {
    type: ILayoutTypeChangedActionType,
    layoutType,
  };
}

export function layoutTypeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case ILayoutTypeChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            room_type:
              action.layoutType !== ELayoutType.Any
                ? {
                    type: 'term',
                    value: action.layoutType,
                  }
                : undefined,
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-layout') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              room_type: undefined,
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
}
