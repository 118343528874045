import classNames from 'classnames';
import * as React from 'react';

import * as styles from './GeneralInfoSectionRow.css';

interface IGeneralInfoSectionRowProps {
  className?: string;
  children?: React.ReactNode;
}

export const GeneralInfoSectionRowComponent = (
  { children, className }: IGeneralInfoSectionRowProps,
  ref: React.Ref<HTMLDivElement>,
) => {
  return (
    <div ref={ref} className={classNames(styles['row'], className)}>
      {children}
    </div>
  );
};

export const GeneralInfoSectionRow = React.forwardRef(GeneralInfoSectionRowComponent);
