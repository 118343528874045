import { ITagDefinition, TTag } from '../../';
import { IJsonQueryRailway } from '../../../../../json_query';
import { ensureSingleTagExists } from '../../helpers';

export const IGeoRailwayTagType = 'geo-railway';

export interface IGeoRailwayTag {
  type: 'geo-railway';
  value: number;
  text: string;
}

const isGeoRailwayTag = (tag: TTag): tag is IGeoRailwayTag => tag.type === IGeoRailwayTagType;

export const geoRailwayTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery, tagsData) => {
    const values =
      jsonQuery.geo &&
      (jsonQuery.geo.value.filter(geo => geo.type === 'railway') as IJsonQueryRailway[]).map(geo => geo.id);

    let nextTags = tags.filter(
      tag => tag.type !== IGeoRailwayTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce((result, value) => {
        const tagData = tagsData.railways[value];

        return ensureSingleTagExists(
          result,
          (tag: TTag): tag is IGeoRailwayTag => isGeoRailwayTag(tag) && tag.value === value,
          () => ({
            type: IGeoRailwayTagType,
            value,
            text: tagData || 'Неизвестная Ж/Д станция',
          }),
        );
      }, nextTags);
    }

    return nextTags;
  },
};
