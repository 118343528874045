import { ERoomsTotal } from '../../../../json_query';
import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IRoomsTotalTagType = 'advanced-roomsTotal';

export interface IRoomsTotalTag {
  type: 'advanced-roomsTotal';
  value: ERoomsTotal;
}

function isRoomsTotalTag(tag: TTag): tag is IRoomsTotalTag {
  return tag.type === IRoomsTotalTagType;
}

export const roomsTotalTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.rooms_count && jsonQuery.rooms_count.value;

    let nextTags = tags.filter(
      tag => tag.type !== IRoomsTotalTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: ERoomsTotal) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IRoomsTotalTag => isRoomsTotalTag(tag) && tag.value === value,
            () => ({ type: IRoomsTotalTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
