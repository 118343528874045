import { Image } from '@cian/ui-kit';
import { shortenNumber } from '@cian/utils';
import classNames from 'classnames';
import * as React from 'react';

import * as styles from './index.css';
import { ISimilarNewobject } from '../../../types/similarNewobjects';

const getShortenNumber = (num: number) => {
  let formattedPrice = shortenNumber(num);
  formattedPrice = formattedPrice.replace(/\d+(.)\d+/, t => t.replace('.', ','));

  return `${formattedPrice} руб.`;
};

export interface INewobjectsProps {
  similarNewobjects: ISimilarNewobject[];
  aggregatedOffers: number;
  fromDeveloper?: boolean;
}

const MAX_SIMILAR_OBJECTS_IN_ROW = 4;

function getNewobjectCard(newobject: ISimilarNewobject): React.ReactNode {
  return (
    <a
      key={newobject.name}
      href={newobject.fullUrl}
      target="_blank"
      className={styles['newobjectcard']}
      rel="noreferrer"
    >
      <div className={styles['vertical_card_extended_layout']}>
        <div className={styles['vertical_card_extended_layout-media']}>
          {newobject.imageUrl ? (
            <div
              className={styles['newobjectcard-img']}
              style={{
                backgroundImage: `url(${newobject.imageUrl})`,
              }}
            />
          ) : (
            <Image src="" borderRadius="0" />
          )}
        </div>
        <div className={styles['vertical_card_extended_layout-content']}>
          <div className={styles['newobjectcard-content']}>
            <div className={styles['newobjectcard-title']}>
              <span className={styles['newobjectcard-jk']}>ЖК «{newobject.name}» </span>
              <span className={styles['newobjectcard-status']}>{newobject.statusDisplay}</span>
            </div>

            {newobject.minPrice && (
              <div className={styles['newobjectcard-price']}>{`от ${getShortenNumber(newobject.minPrice)}`}</div>
            )}

            {newobject.undergroundInfo && (
              <div className={styles['underground']}>
                {newobject.undergroundInfo.lineColor && (
                  <div
                    className={styles['underground-pin']}
                    style={{
                      backgroundColor: `#${newobject.undergroundInfo.lineColor}`,
                    }}
                  />
                )}
                {newobject.undergroundInfo.name && (
                  <div className={styles['underground-name']}>{newobject.undergroundInfo.name}</div>
                )}
                {newobject.undergroundInfo.timeDisplay && (
                  <div className={styles['underground-time']}>{newobject.undergroundInfo.timeDisplay}</div>
                )}
              </div>
            )}

            {!newobject.undergroundInfo && <div className={styles['address']}>{newobject.addressDisplay}</div>}
          </div>
        </div>
        <div
          className={classNames(
            styles['vertical_card_extended_layout-footer'],
            !newobject.buildersDisplay && styles['card-footer--noborder'],
          )}
        >
          <div className={styles['newobjectcard-footer']}>
            <div className={styles['footer']}>{newobject.buildersDisplay}</div>
          </div>
        </div>
      </div>
    </a>
  );
}

export const Newobjects: React.FC<INewobjectsProps> = ({ similarNewobjects, fromDeveloper }) => {
  if (!similarNewobjects || !similarNewobjects.length) {
    return null;
  }

  return (
    <div className={styles['wrapper']}>
      <div className={styles['title']}>{fromDeveloper ? 'ЖК от застройщика' : 'Похожие ЖК'}</div>
      <div className={styles['newobjectcardGroup']}>
        {similarNewobjects.slice(0, MAX_SIMILAR_OBJECTS_IN_ROW).map(getNewobjectCard)}
      </div>
    </div>
  );
};
