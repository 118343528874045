import { isSeoSuggestAvailable } from './seo/isSeoSuggestAvailable';
import { TComputedPropMapper } from '../../types/computed-props-mapper';

export const seoSuggestionLabel: TComputedPropMapper<string | undefined> = ({
  offer,
  jsonQuery,
  suggestionDistancesSeoText,
}) => {
  return isSeoSuggestAvailable(offer, jsonQuery) ? suggestionDistancesSeoText : undefined;
};
