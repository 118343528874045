import React from 'react';

import styles from './UndergroundIconWrapper.css';

interface IUndergroundIconWrapperProps {
  color?: string;
}

export const UndergroundIconWrapper: React.FC<React.PropsWithChildren<IUndergroundIconWrapperProps>> = ({
  color,
  children,
}) => {
  return (
    <div className={styles['cont']} style={{ color }}>
      {children}
    </div>
  );
};
