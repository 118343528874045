import { getRentPrice } from './getRentPrice';
import { TOfferCategory } from '../../../../types/offer';

export interface IGetRentPriceParams {
  priceRur: number;
  category: TOfferCategory | null;
  utilitiesPriceRur: number;
  shouldAddPretext?: boolean;
}

export function getRentPriceWithUtilities({
  utilitiesPriceRur,
  category,
  priceRur,
  shouldAddPretext = false,
}: IGetRentPriceParams): string {
  let price = priceRur;

  price += utilitiesPriceRur;

  return getRentPrice({ priceRur: price, category, shouldAddPretext });
}
