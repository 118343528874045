import { ModalWindow, Button, RadioGroup } from '@cian/ui-kit';
import * as React from 'react';

import { IChangePrintOptions } from '../../../state/print';

const style = require('./index.css');

export interface IPrintPopupProps {
  open: boolean;
  showComments: boolean;
  showPhones: boolean;

  changePrintOptions(options: IChangePrintOptions): void;
  close(): void;
}

export const printPhonesValues = [
  {
    value: 1,
    label: 'С телефонами',
  },
  {
    value: 0,
    label: 'Без телефонов',
  },
];

export const printCommentValues = [
  {
    value: 1,
    label: 'С комментарием',
  },
  {
    value: 0,
    label: 'Без комментария',
  },
];

export const PrintPopup: React.FC<IPrintPopupProps> = ({
  close,
  showComments,
  showPhones,
  open,
  changePrintOptions,
}) => {
  const handlePrintPhonesChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, showPhones: number) => {
      changePrintOptions({
        showPhones: showPhones === 1,
      });
    },
    [changePrintOptions],
  );

  const handlePrintCommentChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, showComments: number) => {
      changePrintOptions({
        showComments: showComments === 1,
      });
    },
    [changePrintOptions],
  );

  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLElement>) => {
      e.preventDefault();
      changePrintOptions({
        enabled: true,
      });
      setTimeout(() => {
        window.print();
        changePrintOptions({
          enabled: false,
        });

        close();
      });
    },
    [changePrintOptions, close],
  );

  return (
    <ModalWindow
      open={open}
      fixed
      escape
      size="M"
      title="Распечатать все объявления на странице"
      onClose={close}
      footer={
        <div className={style['popup-actions']}>
          <Button theme="fill_primary" size="XS" onClick={handleSubmit}>
            Распечатать
          </Button>
          <Button theme="fill_secondary" size="XS" onClick={close}>
            Отменить
          </Button>
        </div>
      }
    >
      <form onSubmit={handleSubmit} className={style['popup-triggers']}>
        <RadioGroup value={Number(showPhones)} options={printPhonesValues} onChange={handlePrintPhonesChange} />
        <RadioGroup value={Number(showComments)} options={printCommentValues} onChange={handlePrintCommentChange} />
      </form>
    </ModalWindow>
  );
};
