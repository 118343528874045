import { dealTypeFromJsonQuery, FDealType, FOfferType, offerTypeFromJsonQuery } from '../../packages/JsonQuery';
import { IJsonQuery } from '../repositories/common/json_query';

/**
 * @description Отдаёт true, если выбран поиск только по новостройкам
 * @param {IJsonQuery} jsonQuery
 * @returns {boolean}
 */
export function getIsNewbuildingFromJsonQuery(jsonQuery: IJsonQuery): boolean {
  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const offerType = offerTypeFromJsonQuery(jsonQuery);

  return dealType === FDealType.Sale && offerType === FOfferType.FlatNew;
}
