export interface IFlatTourBookingInfo {
  isEnabled: boolean;
  orderStatus: IOrderStatus;
}

export interface IOrderStatus {
  description?: string | null;
  type: EBookingStatusType;
}

export enum EBookingStatusType {
  NoActive = 'noActive',
  SignedUp = 'signedUp',
}
