import { IOffer } from '../../offer';
import { TThunkAction, TThunkDispatch } from '../../types/redux';
import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum EChatActionTypes {
  Open = 'Chat/Open',
  OpenChatbot = 'Chat/OpenChatbot',
  Close = 'Chat/Close',
  OpenAfterAuth = 'Chat/OpenAfterAuth',
}

type TOpenChatPayload = {
  offer: IOffer;
  position: number;
};

type TOpenChatAfterAuthPayload = {
  offer: IOffer;
  position: number;
};

export type TOpenChat = ITypedReduxAction<EChatActionTypes.Open, TOpenChatPayload>;
export type TOpenChatAfterAuth = ITypedReduxAction<EChatActionTypes.OpenAfterAuth, TOpenChatAfterAuthPayload>;
export type TOpenChatbot = ITypedReduxAction<EChatActionTypes.OpenChatbot, void>;
export type TCloseChat = ITypedReduxAction<EChatActionTypes.Close>;

/**
 * Action открытия чата
 * @param TOpenChatPayload
 */
export const openChat: (payload: TOpenChatPayload) => TOpenChat = actionGenerator<
  EChatActionTypes.Open,
  TOpenChatPayload
>(EChatActionTypes.Open);

/**
 * Action открытия чатбота
 * @param TOpenChatPayload
 */
export const openChatbot: () => TOpenChatbot = actionGenerator<EChatActionTypes.OpenChatbot, void>(
  EChatActionTypes.OpenChatbot,
);

/**
 * Callback авторизации при попытке открыть чаты
 * @param TOpenChatPayload
 */
export const onChatSuccessAuth = (payload: TOpenChatPayload) => (): TThunkAction => (dispatch: TThunkDispatch) =>
  dispatch(openChat(payload));

/**
 * Action открытия чата после авторизации
 * @param TOpenChatAfterAuthPayload
 */
export const openChatAfterAuth: (payload: TOpenChatAfterAuthPayload) => TOpenChatAfterAuth = actionGenerator<
  EChatActionTypes.OpenAfterAuth,
  TOpenChatAfterAuthPayload
>(EChatActionTypes.OpenAfterAuth);

/**
 * Action закрытия чата
 */
export const closeChat: () => TCloseChat = actionGenerator<EChatActionTypes.Close>(EChatActionTypes.Close);

export type TChatActions = TOpenChat | TOpenChatbot | TCloseChat | TOpenChatAfterAuth;
