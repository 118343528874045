import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NewbuildingConsultantPopup } from './NewbuildingConsultantPopup';
import {
  trackShowBannerFastSearch,
  trackOpenChatFastSearch,
  trackCallbackClickFastSearch,
  trackCallbackSuccessFastSearch,
} from './analytics';
import { ErrorModal } from './components/ErrorModal';
import { SuccessModal } from './components/SuccessModal';
import { AGENT_PHONE, CALL_FROM, CALL_TO, MOSCOW_TIMEZONE } from './constants/agent';
import { getDayOfCallText } from './utils/time';
import { requestCallback } from '../../../../browser/services/callbackRequest';
import { isNewbuildingConsultantPopupOpen } from '../../../selectors/newbuildingConsultant';
import { newbuildingOpenConsultantPopup, newbuildingChatOpenToggle } from '../../../serp/state/newbuildingConsultant';

export enum EStatus {
  Default,
  Success,
  Error,
}

export function NewbuildingConsultantPopupContainer() {
  const [status, setStatus] = React.useState(EStatus.Default);
  const [successModalInfo, setSuccessModalInfo] = React.useState({
    phone: '',
    time: '',
    dayOfCallText: '',
  });

  const dispatch = useDispatch();
  const isOpen = useSelector(isNewbuildingConsultantPopupOpen);

  const onClose = React.useCallback(() => {
    dispatch(newbuildingOpenConsultantPopup(false));
    setStatus(EStatus.Default);
  }, [dispatch]);
  const openChat = React.useCallback(() => {
    dispatch(newbuildingOpenConsultantPopup(false));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch(newbuildingChatOpenToggle(true) as any);
    trackOpenChatFastSearch();
  }, [dispatch]);
  const onRequestCallback = async (customerPhone: string, time: string): Promise<void> => {
    trackCallbackClickFastSearch();

    const params = {
      callRightNow: false,
      customerPhone,
      agentPhone: AGENT_PHONE,
      time,
    };

    try {
      await requestCallback(params);

      setSuccessModalInfo({
        phone: customerPhone,
        time,
        dayOfCallText: getDayOfCallText(time, MOSCOW_TIMEZONE),
      });
      setStatus(EStatus.Success);

      trackCallbackSuccessFastSearch();
    } catch (_) {
      setStatus(EStatus.Error);
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      trackShowBannerFastSearch();
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <NewbuildingConsultantPopup
        open={status === EStatus.Default}
        callFrom={CALL_FROM}
        callTo={CALL_TO}
        onClose={onClose}
        openChat={openChat}
        onRequestCallback={onRequestCallback}
      />
      <SuccessModal open={status === EStatus.Success} onClose={onClose} {...successModalInfo} />
      <ErrorModal open={status === EStatus.Error} onClose={onClose} />
    </>
  );
}
