import * as React from 'react';
import { useSelector } from 'react-redux';

import { isSearchResultsTooltipHidden } from './selectors';
import { EPosition } from '../../../filters/components/filters/types';

const DISABLE_BY_SCROLL_TIMEOUT = 200;

export interface IParameters {
  filtersSectionPosition: EPosition;
}

export function useTooltipDisabled({ filtersSectionPosition }: IParameters): boolean {
  const isTooltipDisabledByState = useSelector(isSearchResultsTooltipHidden);
  const [isTooltipDisabledByPosition, setIsTooltipDisabledByPosition] = React.useState<boolean>(false);
  const [isTooltipDisabledByScroll, setIsTooltipDisabledByScroll] = React.useState(false);

  React.useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    const handleScroll = () => {
      setIsTooltipDisabledByScroll(true);
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        clearTimeout(timeoutId);
        setIsTooltipDisabledByScroll(false);
      }, DISABLE_BY_SCROLL_TIMEOUT);
    };

    document.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  React.useEffect(() => {
    setIsTooltipDisabledByPosition(filtersSectionPosition === EPosition.FixedHidden);
  }, [filtersSectionPosition]);

  return isTooltipDisabledByState || isTooltipDisabledByPosition || isTooltipDisabledByScroll;
}
