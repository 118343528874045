/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetApplyNewBetDateByAnnouncementModel,
  IGetApplyNewBetDateByAnnouncementRequest,
  IMappers,
  IGetApplyNewBetDateByAnnouncementResponse,
  IGetApplyNewBetDateByAnnouncementResponseError,
  TGetApplyNewBetDateByAnnouncementResponse,
} from './types';

const defaultConfig: TGetApplyNewBetDateByAnnouncementModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'auction',
  pathApi: '/v1/get-apply-new-bet-date-by-announcement/',
  hostType: 'api',
} as TGetApplyNewBetDateByAnnouncementModel;

function createGetApplyNewBetDateByAnnouncementModel(
  parameters: IGetApplyNewBetDateByAnnouncementRequest,
): TGetApplyNewBetDateByAnnouncementModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetApplyNewBetDateByAnnouncementOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetApplyNewBetDateByAnnouncementRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetApplyNewBetDateByAnnouncement<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetApplyNewBetDateByAnnouncementOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetApplyNewBetDateByAnnouncementResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetApplyNewBetDateByAnnouncementModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetApplyNewBetDateByAnnouncementResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetApplyNewBetDateByAnnouncementResponseError);
    }
  }

  return { response, statusCode } as TGetApplyNewBetDateByAnnouncementResponse;
}

export { defaultConfig, createGetApplyNewBetDateByAnnouncementModel, fetchGetApplyNewBetDateByAnnouncement };
