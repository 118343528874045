import { applyMiddleware, compose, createStore, Reducer, Store } from 'redux';
import createSageMiddleware, { SagaIterator } from 'redux-saga';
import thunk from 'redux-thunk';

import { TActions } from './actions';
import { IAppState, ISerializableAppState } from './app_state';
import { IApplicationContext } from '../../types/applicationContext';
import { TThunkDispatch } from '../../types/redux';
import { IMakeRequest } from '../../utils/request';

export interface IReducerAddedAction {
  type: '@@store/REDUCER_ADDED';
  name: string;
}

interface ICustomReducer extends Reducer<IAppState> {
  addReducer(reducer: Reducer<IAppState>): void;
}

function createCustomReducer(): ICustomReducer {
  const subReducers: Reducer<IAppState>[] = [];

  const reducer = ((state: IAppState, action: TActions) => {
    return subReducers.reduce((acc, subReducer) => {
      return subReducer(acc, action);
    }, state);
  }) as ICustomReducer;

  reducer.addReducer = (subReducer: Reducer<IAppState>) => {
    subReducers.push(subReducer);
  };

  return reducer;
}

export interface IAppStore extends Store<IAppState> {
  runSaga(saga: () => SagaIterator): void;
  addReducer(reducer: Reducer<IAppState>): void;
  serialize(): ISerializableAppState;
  dispatch: TThunkDispatch;
}

/**
 * Legacy
 * @deprecated
 */
interface ILegacyParameters {
  makeRequest: IMakeRequest;

  optionalParams?: {
    dispachReducerAddedAction?: boolean;
  };
}

export function createAppStore(
  context: IApplicationContext,
  initialState: ISerializableAppState,
  { makeRequest, optionalParams }: ILegacyParameters,
): IAppStore {
  const initializedParams = Object.assign(
    {
      dispachReducerAddedAction: true,
    },
    optionalParams || {},
  );

  const { dispachReducerAddedAction } = initializedParams;

  const { logger, config, httpApi } = context;

  const state = {
    ...initialState,
    makeRequest,
    httpApi,
    logger,
    config,
  };

  const sagaMiddleware = createSageMiddleware();

  const customReducer = createCustomReducer();

  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  const thunkMiddleware = thunk.withExtraArgument(context);
  const store = createStore(customReducer, state, composeEnhancers(applyMiddleware(thunkMiddleware, sagaMiddleware)));

  function addReducer(reducer: Reducer<IAppState>) {
    customReducer.addReducer(reducer);

    if (dispachReducerAddedAction) {
      store.dispatch({
        type: '@@store/REDUCER_ADDED',
        name: reducer.name,
      });
    }
  }

  function runSaga(saga: () => SagaIterator) {
    sagaMiddleware.run(saga);
  }

  function serialize(): ISerializableAppState {
    const currentState = Object.assign({}, store.getState());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delete (currentState as any).makeRequest;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delete (currentState as any).httpApi;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delete (currentState as any).logger;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delete (currentState as any).config;

    return currentState;
  }

  return { ...store, runSaga, addReducer, serialize };
}
