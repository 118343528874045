import * as React from 'react';

import { NonEmptyArray } from '../../../../../JsonQuery';
import { EDecorationType } from '../../../../../JsonQuery/types/jsonQuery/jsonQueryDecorationType';
import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface IDecoration {
  label: string;
  value: EDecorationType | null;
}

const Decorations: IDecoration[] = [
  { label: 'Неважно', value: null },
  { label: 'Чистовая с мебелью', value: EDecorationType.FineWithFurniture },
  { label: 'Чистовая', value: EDecorationType.Fine },
  { label: 'Предчистовая', value: EDecorationType.PreFine },
  { label: 'Без отделки', value: EDecorationType.Without },
];

export interface IDecorationsListProps {
  value: NonEmptyArray<EDecorationType> | null;
  onChange(value: NonEmptyArray<EDecorationType> | null): void;
}

export const DecorationsList: React.FC<IDecorationsListProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_finishing" label="Отделка">
      <CheckboxButtonGroupUnselectable options={Decorations} value={value} onChange={onChange} />
    </Filter>
  );
};
