import { clamp } from 'ramda';
import React, { useState, useCallback, useEffect } from 'react';

import { CSS_LINE_HEIGHT, Description } from '../../../components/Description';
import { useOfferContext } from '../../../context';
import { isSuburban } from '../../../utils/computedProps/category/isSuburban';

interface IDescriptionContainerProps {
  setCallback?(fn: () => void): void;
  parentHeight?: number;
}

export function DescriptionContainer({ setCallback, parentHeight }: IDescriptionContainerProps) {
  const [linesCount, setLinesCount] = useState<number | undefined>();
  const { offer, isNewbuilding } = useOfferContext();

  const calcHeight = useCallback(() => {
    if (typeof parentHeight === 'number') {
      const maxLines = isSuburban(offer) || isNewbuilding ? 4 : 5;

      setLinesCount(clamp(0, maxLines, Math.floor(parentHeight / CSS_LINE_HEIGHT)));
    }
  }, [parentHeight, offer, isNewbuilding]);

  // пересчитываем максимальное количество строк описания
  useEffect(() => {
    if (setCallback) {
      setCallback(calcHeight);
    }

    calcHeight();
  }, [calcHeight, setCallback]);

  if (!offer.description || linesCount === 0) {
    return null;
  }

  return <Description lines={linesCount} text={offer.description} />;
}
