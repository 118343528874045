import * as React from 'react';

import * as styles from './index.css';
import { TopPopupContainer } from '../top_popup/container';

export interface IHideOfferTopPopupProps {
  isError: boolean;
  lkUrl: string;
  errorMessage: string;
}

export const HideOfferTopPopup: React.FC<IHideOfferTopPopupProps> = props => {
  const { isError, lkUrl, errorMessage } = props;

  return (
    <TopPopupContainer isError={isError}>
      {isError ? (
        errorMessage
      ) : (
        <>
          Объявление скрыто из результатов поиска. Вы можете восстановить объявление в{' '}
          <a href={lkUrl} target="_blank" className={styles['popup-link']} rel="noreferrer">
            личном кабинете
          </a>
        </>
      )}
    </TopPopupContainer>
  );
};
