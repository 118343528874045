import * as React from 'react';

import { TagNew } from '../TagNew';

export interface ILoggiaTagItemProps {
  onDeleteClick(): void;
}

export const LoggiaTagItem = ({ onDeleteClick }: ILoggiaTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>Есть лоджия</TagNew>;
};
