import * as React from 'react';

import { IGeoNewbuildingTag } from '../../../../state/tags/definitions/geo';
import { TagNew } from '../../TagNew';

export interface IGeoNewobjectTagItemProps {
  tag: IGeoNewbuildingTag;
  onDeleteClick(): void;
}

export const GeoNewobjectTagItem = (props: IGeoNewobjectTagItemProps) => {
  return <TagNew onDeleteClick={props.onDeleteClick}>{props.tag.text}</TagNew>;
};
