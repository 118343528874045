import * as React from 'react';

import { FDealType } from '../../../../utils/category';
import { IRoomsInDealTag } from '../../../state/tags/definitions/rooms_in_deal';
import { TagNew } from '../TagNew';

export interface IRoomsInDealTagItemProps {
  tag: IRoomsInDealTag;
  onDeleteClick(): void;
}

export const RoomsInDealTagItem = ({ tag, onDeleteClick }: IRoomsInDealTagItemProps) => {
  return (
    <TagNew onDeleteClick={onDeleteClick}>
      Комнат {tag.dealType === FDealType.Sale ? `на продажу` : `в аренду`}: от {tag.value}
    </TagNew>
  );
};
