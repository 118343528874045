import { isPremium } from './isPremium';
import { isTop } from './isTop';
import { getOfferName } from './title/getOfferName';
import { TComputedPropMapper } from '../../types/computed-props-mapper';

export const subtitle: TComputedPropMapper<string | undefined> = (props, ctx) => {
  if ((isTop(props, ctx) || isPremium(props, ctx)) && Boolean(props.offer.title)) {
    return getOfferName(props.offer);
  }

  return undefined;
};
