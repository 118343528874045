export function scrollToElement(scrollableContainer: HTMLElement | null, node: HTMLElement | null) {
  if (!scrollableContainer || !node) {
    return;
  }

  const endLocation = node.offsetTop;
  const startLocation = scrollableContainer.scrollTop;
  const distance = endLocation - startLocation - 24;

  let timeLapsed = 0;
  let start: number | null;
  let percentage: number;
  let position: number;
  let animationHandle: number;

  // tslint:disable-next-line:variable-name
  const stopAnimateScroll = (loopPosition: number, loopEndLocation: number) => {
    const currentLocation = scrollableContainer.scrollTop;

    if (loopPosition === loopEndLocation || currentLocation === loopEndLocation || timeLapsed > 500) {
      window.cancelAnimationFrame(animationHandle);

      start = null;

      return true;
    }

    return false;
  };

  const loopAnimateScroll = (timestamp: number) => {
    if (!start) {
      start = timestamp;
    }

    timeLapsed += timestamp - start;
    percentage = timeLapsed / 500;
    percentage = percentage > 1 ? 1 : percentage;
    position = startLocation + distance * (percentage * percentage);

    scrollableContainer.scrollTop = Math.floor(position);

    if (!stopAnimateScroll(position, endLocation)) {
      animationHandle = window.requestAnimationFrame(loopAnimateScroll);
      start = timestamp;
    }
  };

  animationHandle = window.requestAnimationFrame(loopAnimateScroll);
}
