import * as React from 'react';

import { MAP_ENUM_TO_GTE, MAP_GTE_TO_ENUM } from './constants';
import { getRangeValue } from '../../../../../JsonQuery';
import { Bedrooms } from '../../../components/advancedFilters/Bedrooms';
import { EBedrooms } from '../../../types/bedrooms';
import { useContext } from '../../../utils/useContext';

export const BedroomsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();

  const value = React.useMemo(() => {
    const bedrooms = getRangeValue('bedroom_total')(jsonQuery);

    if (!bedrooms || !bedrooms.gte) {
      return null;
    }

    return MAP_GTE_TO_ENUM[bedrooms.gte];
  }, [jsonQuery]);

  const handleBedroomsChange = React.useCallback(
    (bedrooms: EBedrooms | null) => {
      const minBedroomsCount = bedrooms ? MAP_ENUM_TO_GTE[bedrooms] : null;

      onChange(
        { action: 'setBedroomsCountMin', arguments: [minBedroomsCount] },
        { action: 'setBedroomsCountMax', arguments: [null] },
      );
    },
    [onChange],
  );

  return <Bedrooms value={value} onChange={handleBedroomsChange} />;
};
