import * as React from 'react';

import { IGeoHouseTag } from '../../../../state/tags/definitions/geo';
import { TagNew } from '../../TagNew';

export interface IGeoHouseTagItemProps {
  tag: IGeoHouseTag;
  onDeleteClick(): void;
}

export const GeoHouseTagItem = (props: IGeoHouseTagItemProps) => {
  return <TagNew onDeleteClick={props.onDeleteClick}>{props.tag.text}</TagNew>;
};
