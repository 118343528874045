import { ResponseError } from '../../errors';
import {
  fetchGetDeveloperInfo,
  TGetDeveloperInfoResponse,
} from '../../repositories/monolith-python/v1/get-developer-info';
import { IApplicationContext } from '../../types/applicationContext';
import { IDeveloperInfo } from '../../types/developerInfo';

interface IFetchDeveloperInfoParameters {
  id: number;
  subdomain: string;
}

async function fetchDeveloperInfo(
  { httpApi }: IApplicationContext,
  { id: developerId, subdomain }: IFetchDeveloperInfoParameters,
): Promise<IDeveloperInfo | null> {
  const developerInfoData: TGetDeveloperInfoResponse = await fetchGetDeveloperInfo({
    httpApi,
    parameters: {
      developerId,
    },
    config: {
      subdomain,
    },
  });

  if (developerInfoData.statusCode === 404) {
    return null;
  }

  if (developerInfoData.statusCode !== 200) {
    throw new ResponseError({
      domain: 'fetchDeveloperInfo',
      message: developerInfoData.response.message,
      details: {
        error: developerInfoData.response.errors,
      },
    });
  }

  return developerInfoData.response.data.data;
}

export async function fetchDeveloperInfoSafe(
  context: IApplicationContext,
  parameters: IFetchDeveloperInfoParameters,
): Promise<IDeveloperInfo | null> {
  try {
    return await fetchDeveloperInfo(context, parameters);
  } catch (ex) {
    const { logger } = context;

    logger.warning(ex, { degradation: 'Информация о застройщике' });

    return null;
  }
}
