import { EMessageResponseTypes, PopupManager, PushManager } from '@cian/push-manager';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { IAppState } from '../../../common/state';
import { useApplicationContext } from '../../../utils/applicationContext';

export function usePushManager(isOpen: boolean, handleOpen: () => void) {
  const { config, logger, httpApi } = useApplicationContext();

  const pushBrowserUrl = config.get('push.browserUrl') as string;
  const applicationServerKey = config.get<string>('applicationServerKey') || '';
  const pushManager = React.useRef<PushManager>();
  const currentSubdomain = useSelector<IAppState, string>(state => state.currentSubdomain);
  const [pushPermission, setPushPermission] = React.useState<NotificationPermission>('default');
  const [isSubscribed, setIsSubscribed] = React.useState(false);

  const subscribeToPushAvailable = React.useMemo(
    () => !isSubscribed && pushPermission !== 'denied',
    [isSubscribed, pushPermission],
  );

  const handlePushSubscribe = React.useCallback(async () => {
    if (currentSubdomain !== 'www') {
      if (pushManager.current) {
        pushManager.current.showPopup({
          baseUrl: 'web-push-subscribe-popup',
          trackingLabel: 'From_cat_save',
        });
      }
    } else if (applicationServerKey) {
      const popup = new PopupManager({
        workerSrc: '/push-worker.js',
        applicationServerKey,
        httpApi,
      });

      try {
        await popup.register();
      } catch (error) {
        logger.warning(error, { domain: 'shared.filters.containers.SaveSearchModalContainer.PopupManager.register' });

        return;
      }

      try {
        await popup.subscribe();
      } catch (error) {
        logger.warning(error, { domain: 'shared.filters.containers.SaveSearchModalContainer.PopupManager.subscribe' });

        return;
      }
    }
  }, [applicationServerKey, currentSubdomain, httpApi, logger]);

  const handlePushManagerMessage = React.useCallback((e: MessageEvent) => {
    try {
      const data = JSON.parse(e.data);

      if (data.type === EMessageResponseTypes.subscriptionStatus && !data.value) {
        setIsSubscribed(data.value);
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }, []);

  React.useEffect(() => {
    if (isOpen) {
      pushManager.current = new PushManager({
        baseUrl: pushBrowserUrl,
        hasSubdomain: currentSubdomain !== 'www',
      });

      pushManager.current.registerWorker();
      PushManager.ready().then(PushManager.checkSubscription);

      if ('Notification' in window && currentSubdomain === 'www') {
        setPushPermission(Notification.permission);
      }

      window.addEventListener('message', handlePushManagerMessage);

      handleOpen();
    }

    return () => window.removeEventListener('message', handlePushManagerMessage);
  }, [currentSubdomain, handleOpen, handlePushManagerMessage, isOpen, pushBrowserUrl]);

  return { subscribeToPushAvailable, handlePushSubscribe };
}
