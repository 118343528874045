import { EStarRating } from '../../../../../JsonQuery';

interface IOption {
  label: string;
  value: EStarRating;
}

export const starRatingOptions: IOption[] = [
  {
    label: 'Без звёзд',
    value: EStarRating.NoRating,
  },
  {
    label: '1 звезда',
    value: EStarRating.Star1,
  },
  {
    label: '2 звезды',
    value: EStarRating.Star2,
  },
  {
    label: '3 звезды',
    value: EStarRating.Star3,
  },
  {
    label: '4 звезды',
    value: EStarRating.Star4,
  },
  {
    label: '5 звёзд',
    value: EStarRating.Star5,
  },
];
