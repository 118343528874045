import * as React from 'react';

import { IGeoLocationTag } from '../../../../state/tags/definitions/geo';
import { TagNew } from '../../TagNew';

export interface IGeoLocationTagItemProps {
  tag: IGeoLocationTag;
  onDeleteClick(): void;
}

export const GeoLocationTagItem = (props: IGeoLocationTagItemProps) => {
  return <TagNew onDeleteClick={props.onDeleteClick}>{props.tag.text}</TagNew>;
};
