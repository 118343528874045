import * as React from 'react';

import { isSuburban, offerTypeFromJsonQuery } from '../../../../../JsonQuery';
import { RadioButtonGroup } from '../../../components/RadioButtonGroup';
import { Filter } from '../../../components/advancedFilters/common/Filter';
import { InlineFilter } from '../../../components/advancedFilters/common/InlineFilter';
import { InlineFilterGroup } from '../../../components/advancedFilters/common/InlineFilterGroup';
import { useContext } from '../../../utils/useContext';

enum ECurrentLocations {
  moscowAndMO = 'Москва и область',
  spbAndLO = 'Санкт-Петербург и область',
}

export const WithNeighborsContainer: React.FC = () => {
  const {
    onChange,
    jsonQuery,
    currentLocation,
    appContext: { config },
  } = useContext();

  const isWithNeighborsByDefaultOff = React.useMemo(
    () =>
      isSuburban(offerTypeFromJsonQuery(jsonQuery)) &&
      !!config.get<boolean>('Countryside.SearchWithNeighborsByDefaultOff.Enabled'),
    [config, jsonQuery],
  );

  const withNeighbors = React.useMemo(() => {
    const { value } = jsonQuery.with_neighbors || {};

    if (value === undefined && !isWithNeighborsByDefaultOff) {
      return true;
    }

    return !!value;
  }, [isWithNeighborsByDefaultOff, jsonQuery.with_neighbors]);

  const options = React.useMemo(() => {
    let locationName;
    if (currentLocation === 'moscow_mo') {
      locationName = ECurrentLocations.moscowAndMO;
    } else if (currentLocation === 'spb_lo') {
      locationName = ECurrentLocations.spbAndLO;
    } else {
      locationName = currentLocation.displayName;
    }

    return [
      {
        label: locationName,
        value: false,
      },
      { label: `${locationName} + 20км`, value: true },
    ];
  }, [currentLocation]);

  const handleChange = React.useCallback(
    (withNeighbors: boolean) => {
      const action = 'setWithNeighbors';

      if (isWithNeighborsByDefaultOff) {
        onChange({ action, arguments: [withNeighbors ? true : null] });
      } else {
        onChange({ action, arguments: [withNeighbors ? null : false] });
      }
    },
    [onChange, isWithNeighborsByDefaultOff],
  );

  return (
    <Filter id="advancedFilter_withNeighbors" label="Радиус поиска">
      <InlineFilterGroup>
        <InlineFilter>
          <RadioButtonGroup spliced={false} options={options} value={withNeighbors} onChange={handleChange} />
        </InlineFilter>
      </InlineFilterGroup>
    </Filter>
  );
};
