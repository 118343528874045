import { chatAvailableSelector } from './chat';
import { MOSCOW_AND_REGION } from '../../../packages/Filters/shared/constants/regions';
import { IAppState } from '../../common/state';
import { isConsultantPopupExperimentActive } from '../../utils/experiments/isConsultantPopupExperimentActive';
import { isInRegionSelector } from '../isInRegion';

export function isNewbuildingConsultantPopupAvailable(state: IAppState): boolean {
  const { abUseExperiments, filters } = state;

  return (
    chatAvailableSelector(state) &&
    filters.jsonQuery._type === 'flatsale' &&
    isConsultantPopupExperimentActive(abUseExperiments) &&
    isInRegionSelector(MOSCOW_AND_REGION)(state)
  );
}

export function isNewbuildingConsultantPopupOpen(state: IAppState) {
  return state.newbuildingConsultant.consultantPopupOpen;
}
