import { Button, TooltipDesktop } from '@cian/ui-kit';
import { IconFunctionalWarningSign16 } from '@cian/ui-kit-design-tokens/icons';
import React, { useState } from 'react';

import { ComplaintModal } from './components/ComplaintModal';
import { ControlWrapper } from '../../../../components/ControlWrapper';

export function ComplainControlContainer() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ControlWrapper>
        <TooltipDesktop theme="black" placement="left" title="Пожаловаться">
          <Button
            data-mark="ComplainControl"
            theme="stroke_secondary"
            onClick={() => setOpen(true)}
            size="XS"
            beforeIcon={<IconFunctionalWarningSign16 />}
          />
        </TooltipDesktop>
      </ControlWrapper>
      <ComplaintModal onCloseModal={() => setOpen(false)} openModal={open} />
    </>
  );
}
