import { getSimilarLinkText } from './similar/getSimilarLinkText';
import { TComputedPropMapper } from '../../types/computed-props-mapper';
import { ISimilarLink } from '../../types/similar-link';

export const similarLink: TComputedPropMapper<ISimilarLink | undefined> = ({
  offer: { similar, category },
  jsonQuery: { multi_id },
}) => {
  if (!(similar && !multi_id) || !similar.count || !similar.url) {
    return undefined;
  }

  return {
    url: similar.url,
    text: getSimilarLinkText(similar.count, category),
  };
};
