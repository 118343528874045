import { Button } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';
import { ISelectActions } from '../../../types';

interface IMultiSelectDeferButtonProps {
  actions: ISelectActions;
  onComplete(): void;
}

export const MultiSelectDeferButton = ({ actions, onComplete }: IMultiSelectDeferButtonProps) => {
  const onClick = () => {
    onComplete();
    actions.closeDropdown();
  };

  return (
    <div className={styles['container']}>
      <Button onClick={onClick} size="XS">
        Применить
      </Button>
    </div>
  );
};
