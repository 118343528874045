import React from 'react';

import styles from './GeoBlock.css';
import { GeoLabel } from './GeoLabel';
import { IGeoLabel } from '../../types/geo';
import { GeneralInfoSectionRow } from '../GeneralInfoSectionRow';

interface IGeoBlockProps {
  geoLabels: IGeoLabel[];
  seoSuggestion?: React.ReactNode;
  onLabelClick(label: string): void;
}

export const GeoBlock: React.FC<React.PropsWithChildren<IGeoBlockProps>> = ({
  onLabelClick,
  geoLabels,
  seoSuggestion,
  children,
}) => {
  return (
    <GeneralInfoSectionRow>
      {children}
      <div className={styles['labels']}>
        {geoLabels.map((label, index) => {
          const item = <GeoLabel key={`offer-card-geo-${label.url}`} {...label} onLabelClick={onLabelClick} />;

          return [index > 0 && ', ', item];
        })}
        {seoSuggestion}
      </div>
    </GeneralInfoSectionRow>
  );
};
