import { ca } from '@cian/analytics';

export function trackShowSaveSearchClick() {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'click_saved_search',
    label: '', // убрал label narrow/wide, поскольку в новых фильтрах нет этой логики
  });
}
