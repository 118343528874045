import * as React from 'react';

import { IWCCountTag } from '../../../state/tags/definitions/wc_count';
import { TagNew } from '../TagNew';

export interface IWCCountTagItemProps {
  tag: IWCCountTag;
  onDeleteClick(): void;
}

export const WCCountTagItem = ({ tag, onDeleteClick }: IWCCountTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>Санузлов минимум {tag.value}</TagNew>;
};
