import * as React from 'react';

import { HeaderDefaultContainer } from './default/container';
import { HeaderDeveloperContainer } from './developer/container';
import { HeaderVillageContainer, isVillageInfoVisible } from './village';
import { HeaderNewbuildingRedesignedContainerLoadable } from '../../../containers/HeaderNewbuildingRedesigned';
import { IJsonQuery } from '../../../json_query';
import { IDeveloperInfo } from '../../../types/developerInfo';
import { INewbuilding } from '../../../types/newbuildingHeader';
import { IVillageInfo } from '../../../types/villageInfo';

export interface IHeaderProps {
  villageInfo?: IVillageInfo | null;
  jsonQuery: IJsonQuery;
  newbuildingInfo: INewbuilding | null;
  developerInfo: IDeveloperInfo | null;
}

export const Header: React.FC<IHeaderProps> = ({ villageInfo, jsonQuery, newbuildingInfo, developerInfo }) => {
  if (villageInfo && isVillageInfoVisible(villageInfo, jsonQuery)) {
    return <HeaderVillageContainer />;
  }

  if (newbuildingInfo) {
    return <HeaderNewbuildingRedesignedContainerLoadable />;
  }

  if (developerInfo) {
    return <HeaderDeveloperContainer />;
  }

  return <HeaderDefaultContainer />;
};
