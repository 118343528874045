import { ca } from '@cian/analytics';

import { IJsonQuery } from '../../../../json_query';
import { IOffer } from '../../../../offer';

interface IParams {
  identicalOfferIndex: number;
  offer: IOffer;
  jsonQuery: IJsonQuery;
}

export function trackIdenticalOfferClick(params: IParams) {
  const { offer, jsonQuery, identicalOfferIndex } = params;
  const identicalOffer = offer.identicalOffers?.items[identicalOfferIndex];

  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'to_card_identical_offers',
    label: offer.gaLabel,
    searchConditions: jsonQuery,
    products: [
      {
        id: identicalOffer?.id,
        offerType: 'offer',
        position: identicalOfferIndex + 1,
      },
    ],
  });
}
