import * as React from 'react';

import { getTermValue } from '../../../../../JsonQuery';
import { SearchResultsTooltip } from '../../../components/SearchResultsTooltip';
import { VillageMortgageAllowed } from '../../../components/filters/VillageMortgageAllowed';
import { IFilterComponentBaseProps } from '../../../types/filters';
import { useSearchResultsTooltipOpen } from '../../../utils/searchResultsTooltipOpen';
import { useContext } from '../../../utils/useContext';

export const VillagesMortgageAllowedContainer: React.VFC<IFilterComponentBaseProps> = ({ filterKey }) => {
  const { jsonQuery, onChange, jsonQueryCount, jsonQueryCountRefreshing, onApply, onApplyClick } = useContext();
  const openTooltip = useSearchResultsTooltipOpen({ filterKey, actions: ['setVillageMortgageAllowed'] });

  const isChecked = getTermValue('village_mortgage_allowed')(jsonQuery);

  const handleChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, value: boolean | null) => {
      onChange({ action: 'setVillageMortgageAllowed', arguments: [value || null] });
    },
    [onChange],
  );

  const handleApply = React.useCallback(() => {
    if (onApplyClick) {
      onApplyClick({ source: 'tooltip' });
    }

    onApply();
  }, [onApply, onApplyClick]);

  return (
    <SearchResultsTooltip
      offersCount={jsonQueryCount || 0}
      loading={jsonQueryCountRefreshing}
      placement="bottom-start"
      onApply={handleApply}
      open={openTooltip}
    >
      <VillageMortgageAllowed checked={!!isChecked} onChange={handleChange} />
    </SearchResultsTooltip>
  );
};
