import { isNil } from 'ramda';
import * as React from 'react';

import { TSelectValue, ISelectOption } from '../types';

interface ISelectButtonLabelProps {
  value: TSelectValue;
  options: ISelectOption[];
  placeholder?: string;
}

export class SelectButtonLabel extends React.Component<ISelectButtonLabelProps> {
  public render() {
    const { value, options } = this.props;
    const placeholder = this.props.placeholder || null;

    if (isNil(value)) {
      return placeholder || null;
    }

    if (!Array.isArray(value)) {
      const option = options.find(option => option.value === value);

      return option ? option.label : placeholder;
    }

    return (
      options
        .reduce((labels, option) => {
          if (value.includes(option.value)) {
            return [...labels, option.label];
          }

          return labels;
        }, [])
        .join(', ') || placeholder
    );
  }
}
