export function getCurrencySign(currency: string) {
  switch (currency) {
    case 'eur':
      return '€';
    case 'usd':
      return '$';
    default:
      return '₽';
  }
}
