import React from 'react';

import { AuctionInformationTooltipContainer } from './AuctionInformationTooltipContainer';
import { AuctionReduceBetModalContainer } from './AuctionReduceBetModalContainer';
import { PublicationDateContainer } from './PublicationDateContainer';
import { VASListContainer } from './VASListContainer';
import { DoublesRankingPopupContainer } from '../DoublesRankingPopupContainer';

export function VASContainer() {
  return (
    <>
      <PublicationDateContainer />
      <VASListContainer />
      <AuctionInformationTooltipContainer />
      <AuctionReduceBetModalContainer />
      <DoublesRankingPopupContainer />
    </>
  );
}
