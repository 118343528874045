import { ActionAfterViewed } from '@cian/action-after-viewed-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackClick, trackView } from './tracking';
import { JournalHighlightsSection, JournalHighlightsSlider } from '../../components/JournalHighlights';
import { selectJournalHighlights } from '../../selectors/journal';

export function JournalHighlightsContainer() {
  const journalHighlights = useSelector(selectJournalHighlights);

  const handleLinkClick = React.useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    const href = e.currentTarget.href;
    trackClick(href);
  }, []);

  if (journalHighlights.length === 0) {
    return null;
  }

  return (
    <ActionAfterViewed callback={trackView}>
      <JournalHighlightsSection>
        <JournalHighlightsSlider items={journalHighlights} onLinkClick={handleLinkClick} />
      </JournalHighlightsSection>
    </ActionAfterViewed>
  );
}
