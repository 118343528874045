import { IAppState, ITagsData, TActions } from '../../../common/state';
import { jq } from '../../../json_query';
import { IStationData } from '../../../types/undergroundStationData';
import { isGeoTag, isGeoUndergroundTag } from '../tags/definitions/geo';

export interface IMetroSelectedAction {
  type: 'filters/metro/METRO_SELECTED';
  stations: IStationData[];
}

export interface IMetroRemovedAction {
  type: 'filters/metro/METRO_REMOVED';
  stations: IStationData[];
}

export function selectMetro(stations: IStationData[]): IMetroSelectedAction {
  return {
    type: 'filters/metro/METRO_SELECTED',
    stations,
  };
}

export function removeMetro(stations: IStationData[]): IMetroRemovedAction {
  return {
    type: 'filters/metro/METRO_REMOVED',
    stations,
  };
}

export function metroSelectionReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/metro/METRO_SELECTED': {
      const { constructingStations } = state;
      const tagsData: ITagsData = { ...state.filters.tagsData };
      tagsData.undergrounds = {
        ...action.stations.reduce((result, station) => {
          const releaseYear =
            constructingStations && constructingStations[station.id] && constructingStations[station.id].releaseYear;

          return { ...result, [station.id]: { name: station.name, color: station.color, releaseYear } };
        }, tagsData.undergrounds),
      };

      return {
        ...state,
        filters: {
          ...state.filters,
          tagsData,
          jsonQuery: jq(state.filters.jsonQuery).addMetroStations(action.stations),
        },
      };
    }
    case 'filters/metro/METRO_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).removeMetroStations(action.stations),
        },
      };
    case 'filters/tags/TAG_REMOVED': {
      const newState = Object.assign({}, state);
      const { tag } = action;
      if (isGeoTag(tag) && isGeoUndergroundTag(tag)) {
        const stations: IStationData[] = [
          {
            id: tag.value,
            name: tag.text,
            color: tag.color,
          },
        ];

        newState.filters.jsonQuery = jq(newState.filters.jsonQuery).removeMetroStations(stations);

        const { geo } = newState.filters.jsonQuery;

        if (geo && Array.isArray(geo.value) && geo.value.filter(({ type }) => type === 'underground').length === 0) {
          newState.filters.jsonQuery = jq(newState.filters.jsonQuery).unsetUndergroundTravel();
        }

        return newState;
      }

      return state;
    }
    default:
      return state;
  }
}
