import { IDirection, IHighway } from '../../../../../types/direction';

interface INormalizedHighway {
  id: number;
  text: string;
  highways?: IHighway[];
}

function getHighwayById(highwayId: number, directions: IDirection[]): IHighway | undefined {
  let result;
  directions.find(({ highways }) => {
    const highway = highways.find(({ id }) => id === highwayId);
    if (highway) {
      result = highway;

      return true;
    }

    return false;
  });

  return result;
}

export function normalizeHighwaysData(selectedHighwaysIds: number[], directions: IDirection[]): INormalizedHighway[] {
  const result: INormalizedHighway[] = [];
  let highwaysIds = selectedHighwaysIds.slice();

  directions.reduce((acc, { highways, name }) => {
    // Если выбраны все шоссе из направления
    if (highways.every(({ id }) => highwaysIds.includes(id))) {
      highwaysIds = highwaysIds.filter(highwayId => !highways.some(({ id }) => id === highwayId));
      // Добавляется псевдотег "направление"
      acc.push({
        text: `${name} направление`,
        id: highways.reduce((sum, { id }) => sum + id, 0),
        highways,
      });
    }

    return acc;
  }, result);

  highwaysIds.reduce((acc, id) => {
    const highway = getHighwayById(id, directions);
    if (highway) {
      acc.push({
        text: highway.fullName,
        id,
      });
    }

    return acc;
  }, result);

  return result;
}
