import { getTermValue } from './helpers';
import { TJsonQuery } from './types';

export function getApartments(jsonQuery: TJsonQuery): boolean | null {
  const withoutApartments = getTermValue('only_flat')(jsonQuery);
  const onlyApartments = getTermValue('apartment')(jsonQuery);

  switch (true) {
    case !withoutApartments && onlyApartments:
      return true;

    case withoutApartments && !onlyApartments:
      return false;
  }

  return null;
}
