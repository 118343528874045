import { pathOr } from 'ramda';
import * as React from 'react';

import { OfferId } from '../../../components/advancedFilters/OfferId';
import { useContext } from '../../../utils/useContext';

export const OfferIdContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const offerId = pathOr<number | null>(null, ['id', 'value'], jsonQuery);

  const handleChangeOfferId = React.useCallback(
    (id: number | null) => {
      onChange({ action: 'setOfferId', arguments: [id] });
    },
    [onChange],
  );

  return <OfferId offerId={offerId} onOfferIdChange={handleChangeOfferId} />;
};
