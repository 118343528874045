import { mapSavedSearchLabelColor } from './mapSavedSearchLabelColor';
import { IGalleryLabel } from '../../../types/gallery-label';
import { ISearchOfferLabel } from '../../../types/offer';

export function getOfferSavedSearchLabels(savedSearchLabels: ISearchOfferLabel[]): IGalleryLabel[] {
  return savedSearchLabels.map(({ backgroundColor, text }) => {
    const label: IGalleryLabel = {
      color: mapSavedSearchLabelColor(backgroundColor),
      label: text,
    };

    return label;
  });
}
