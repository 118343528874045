import * as React from 'react';
import { useSelector } from 'react-redux';

import { MIN_QUERY_LENGTH } from './constants';
import GeoSuggest, { IGeoSuggestItem } from '../../../common/components/geo_suggest';
import { SearchIcon } from '../../../components/RegionalDistrictsModal';
import { selectRegionalDistrictsForSearch } from '../../../selectors/filters';

interface IRegionalDistrictsSearchContainer {
  onSelect: (id: number) => void;
}

export const RegionalDistrictsSearchContainer: React.FC<IRegionalDistrictsSearchContainer> = ({ onSelect }) => {
  const districts = useSelector(selectRegionalDistrictsForSearch);

  const suggestRef = React.useRef<GeoSuggest | null>(null);

  const [suggestions, setSuggestions] = React.useState<IGeoSuggestItem<number>[] | undefined>();

  const handleSelect = React.useCallback(
    ({ value }: IGeoSuggestItem<number>) => {
      onSelect(value);

      suggestRef.current?.reset();
    },
    [onSelect],
  );

  const handleQueryChange = React.useCallback(
    (value: string) => {
      const filteredSuggestions =
        value.length >= MIN_QUERY_LENGTH
          ? districts.filter(({ title }) => title.toLowerCase().includes(value.toLowerCase()))
          : undefined;

      setSuggestions(filteredSuggestions);
    },
    [districts],
  );

  return (
    <GeoSuggest
      ref={suggest => (suggestRef.current = suggest)}
      onSelect={handleSelect}
      placeholder="Название района"
      suggestions={suggestions}
      onValueChange={handleQueryChange}
      leftAdornment={<SearchIcon />}
    />
  );
};
