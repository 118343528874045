import * as React from 'react';

import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { MaskedInput } from '../../MaskedInput';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';

interface IContactPhoneProps {
  contactPhone: string | null;
  onContactPhoneChange(phone: string | null): void;
}

export const Contact: React.FC<IContactPhoneProps> = ({ contactPhone, onContactPhoneChange }) => {
  const handleChangeContactPhone = useDebouncedCallback(
    (phone: string) => {
      onContactPhoneChange(phone || null);
    },
    300,
    [onContactPhoneChange],
  );

  return (
    <Filter id="advancedFilter_contactPhone" label="Номер телефона">
      <InlineFilterGroup>
        <InlineFilter>
          <MaskedInput
            width={260}
            placeholder="+7 XXX XXX-XX-XX"
            value={contactPhone || ''}
            mask="+7 000 000-00-00"
            onAccept={handleChangeContactPhone}
          />
        </InlineFilter>
      </InlineFilterGroup>
    </Filter>
  );
};
