import { plural } from '@cian/utils';
import React, { useMemo, useCallback } from 'react';

import { SpecialGeo } from '../../../components/SpecialGeo';
import { useOfferContext } from '../../../context';
import { UndergroundIconContainer } from '../UndergroundIconContainer';

export function UndergroundContainer() {
  const { underground, tracking, qsToUris } = useOfferContext();

  const url = useMemo(() => {
    if (underground) {
      return (qsToUris && qsToUris[underground.qs]) || `/cat.php?${underground.qs}`;
    }

    return '';
  }, [qsToUris, underground]);

  const remoteness = useMemo(() => {
    if (underground && underground.time && underground.time > 0) {
      const unit = plural(underground.time, ['минута', 'минуты', 'минут']);
      const transportType = underground.transportType === 'walk' ? 'пешком' : 'на транспорте';

      return `${underground.time} ${unit} ${transportType}`;
    }

    return undefined;
  }, [underground]);

  const handleUndergroundClick = useCallback(
    (e: React.SyntheticEvent<HTMLElement>) => {
      e.stopPropagation();
      tracking.trackAddressClick('metro');
    },
    [tracking.trackAddressClick],
  );

  if (!underground) {
    return null;
  }

  return (
    <SpecialGeo
      url={url}
      label={underground.name}
      icon={<UndergroundIconContainer />}
      remoteness={remoteness}
      onClick={handleUndergroundClick}
    />
  );
}
