import { FDealType, FOfferType, isAvailable } from '../../../../JsonQuery';
import { WithNeighborsContainer } from '../../containers/advancedFilters/WithNeighborsContainer';
import { IFilter } from '../../types/advancedFilters';
import { EFeature } from '../../types/features';

export const withNeighbors: IFilter = {
  component: WithNeighborsContainer,
  availability: ({ jsonQuery, currentLocation, config, features }) => {
    if (currentLocation === 'moscow_mo' || currentLocation === 'spb_lo') {
      return false;
    }
    const offerType = config.get<FOfferType>('neighbors.availableOfferType') || FOfferType.Any;

    return (
      isAvailable(FDealType.Any, offerType)(jsonQuery) &&
      !!currentLocation.hasNeighbors &&
      features.some(feature => feature === EFeature.withNeighbors)
    );
  },
};
