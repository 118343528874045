import { IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';

export function userIdReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'userId') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jsonQuery.setUserId(undefined),
          },
        };
      }

      return state;

    default:
      return state;
  }
}
