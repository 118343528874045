import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingBlackFridayTopBanner } from '../../components/NewbuildingBlackFridayTopBanner';
import { selectNewbuildingBlackFridayTopBannerImage } from '../../selectors/newbuildingBlackFriday';

export const NewbuildingBlackFridayTopBannerContainer = () => {
  const topBannerImage = useSelector(selectNewbuildingBlackFridayTopBannerImage);

  return <NewbuildingBlackFridayTopBanner image={topBannerImage} />;
};
