import * as React from 'react';

import { trackSaveMapModalEvent } from './tracking';
import { SearchButtonContainer } from '../../search_button/container';

const style = require('./index.css');

export interface IMapFooterProps {
  mapRegions: number;
  applyMapModal(): void;
}

export class MapFooter extends React.Component<IMapFooterProps> {
  public render() {
    return (
      <div className={style['footer']}>
        <SearchButtonContainer onClick={this.handleClickOnSearchButton} />
      </div>
    );
  }

  private handleClickOnSearchButton = () => {
    trackSaveMapModalEvent(this.props.mapRegions);
    this.props.applyMapModal();
  };
}
