import { IAppState, TActions } from '../../../common/state';

export const IFlatShareActionType = 'filters/FLAT_SHARE_CHANGED';

export interface IFlatShareAction {
  type: 'filters/FLAT_SHARE_CHANGED';
  value: number | undefined;
}

export function changeFlatShare(value: number | undefined): IFlatShareAction {
  return {
    type: IFlatShareActionType,
    value,
  };
}

export function flatShareReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IFlatShareActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            flat_share:
              (action.value && {
                type: 'term',
                value: action.value,
              }) ||
              undefined,
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-flat_share') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              flat_share: undefined,
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
}
