import { ITypedReduxAction } from '../../types/redux';
import { INewbuildingLayoutWithOffers } from '../../types/residentialComplexLayouts/layoutWithOffers/layoutWithOffers';

export enum ENewbuildingLayoutOffersActionTypes {
  StartRequest = 'NewbuildingLayoutOffers/StartRequest',
  Error = 'NewbuildingLayoutOffers/Error',
  Finished = 'NewbuildingLayoutOffers/Finished',
}

export type TNewbuildingLayoutOffersFinishedPayload = INewbuildingLayoutWithOffers | null;

export type TFinishedNewbuildingLayoutOffers = ITypedReduxAction<
  ENewbuildingLayoutOffersActionTypes.Finished,
  TNewbuildingLayoutOffersFinishedPayload
>;
export type TStartRequestNewbuildingLayoutOffers = ITypedReduxAction<ENewbuildingLayoutOffersActionTypes.StartRequest>;

export type TErrorNewbuildingLayoutOffers = ITypedReduxAction<ENewbuildingLayoutOffersActionTypes.Error>;

export type TNewbuildingLayoutOffersFinishedActions =
  | TFinishedNewbuildingLayoutOffers
  | TStartRequestNewbuildingLayoutOffers
  | TErrorNewbuildingLayoutOffers;
