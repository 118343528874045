import { BaseDrawObject, IBaseMapObjectData } from './base_draw_object';
import { createPolygonGeoObject, polygonModelToDrawObject } from './helpers';
import { YMAPS_PRESETS_POLYGON } from './settings';
import { IJsonQueryPolygon, TCoordinate } from '../../../../json_query';
import { coordsArrayNumberToString } from '../../../../utils/geo';

export interface IPolygonObjectData extends IBaseMapObjectData {
  from: TCoordinate[];
  coordinates: TCoordinate[];
}

export class Polygon extends BaseDrawObject<IPolygonObjectData, IJsonQueryPolygon> {
  private startPoint: YMaps.IPointGeometry;

  public startDraw = (polygonModel?: IJsonQueryPolygon) => this.onStartDraw(polygonModel);

  public getCoords = (): IPolygonObjectData => {
    const viewSync = this.areaGeoObject.editor.getViewSync();

    if (viewSync) {
      const pointsModels = viewSync.getPathViews()[0].getVertexViews();

      const points: YMaps.TCoord[] = pointsModels.map((value: YMaps.IPathModel) =>
        value.model.geometry.getCoordinates(),
      );

      points.push(points[0]);

      this.areaObject.coordinates = coordsArrayNumberToString(points);
    }

    const areaObject = { ...this.areaObject };
    this.areaObject.from = this.areaObject.coordinates;

    return areaObject;
  };

  protected drawingStopHandler() {
    this.setDefaultPinStyle();
    super.drawingStopHandler();
  }

  protected createDrawObject(polygonModel?: IJsonQueryPolygon): IPolygonObjectData {
    return polygonModelToDrawObject(this.name, polygonModel);
  }

  protected createDrawGeoObject(ymaps: YMaps.IYMaps, areaObject: IPolygonObjectData): YMaps.IGeoObject {
    return createPolygonGeoObject(this.ymaps, areaObject);
  }

  protected bindEvents() {
    super.bindEvents();

    this.areaGeoObject.events.add('click', this.onClick);
    this.areaGeoObject.editor.events.add(['edgedragend', 'vertexdragend'], this.updateHandlerBind);
    this.areaGeoObject.editor.events.add('vertexadd', this.onVertexAdd);
  }

  protected unbindEvents() {
    super.unbindEvents();

    this.areaGeoObject.events.remove('click', this.onClick);
    this.areaGeoObject.editor.events.remove(['edgedragend', 'vertexdragend'], this.updateHandlerBind);
    this.areaGeoObject.editor.events.remove('vertexadd', this.onVertexAdd);
  }

  protected setOptions() {
    this.areaGeoObject.editor.options.set(YMAPS_PRESETS_POLYGON.POLYGON_PIN);

    this.editor.options.set({
      menuManager: (items: string[], model: YMaps.IMenuItem) => {
        if (model.getIndex() === 0) {
          this.areaGeoObject.editor.stopDrawing();
        }

        return [];
      },
    });
  }

  protected onClick = (event: YMaps.IEvent) => this.showMenu(event.get('offsetX'), event.get('offsetY'), this);

  private drawCustomVertex = (editor: YMaps.IGeometryEditor) => {
    const point = this.getFirstVertexFromEditor(editor);

    if (point) {
      point.options.set(YMAPS_PRESETS_POLYGON.START_PIN);
      this.startPoint = point;
    }
  };

  private getFirstVertexFromEditor = (editor: YMaps.IGeometryEditor): YMaps.IPointGeometry | null => {
    const viewSync = editor.getViewSync();
    if (viewSync) {
      return viewSync.getPathViews()[0].getVertexViews()[0].getPlacemark();
    } else {
      return null;
    }
  };

  private setDefaultPinStyle = () => this.startPoint.options.set(YMAPS_PRESETS_POLYGON.DEFAULT_PIN);

  private onVertexAdd = (event: YMaps.IEvent) => {
    const vertexIndex = event.get('vertexIndex');
    const editor = event.get<YMaps.IGeometryEditor>('target');
    const isDraw = editor.state.get('drawing');

    if (vertexIndex === 0 && isDraw) {
      this.drawCustomVertex(editor);
    }
  };
}
