import classNames from 'classnames';
import React from 'react';

import * as styles from './ControlWrapper.css';
import * as hoverStyles from '../Card/CardHover.css';

interface IControlWrapperProps {
  visible?: boolean;
}

export const ControlWrapper: React.FC<React.PropsWithChildren<IControlWrapperProps>> = ({ children, visible }) => {
  return (
    <div className={styles['cont']}>
      <div className={classNames(styles['inner'], hoverStyles['controls'], visible && styles['visible'])}>
        {children}
      </div>
    </div>
  );
};
