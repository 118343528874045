import { ca } from '@cian/analytics';

import { IJsonQuery } from '../../../../json_query';
import { IOffer } from '../../../../offer';

interface IParams {
  position: number;
  offer: IOffer;
  jsonQuery: IJsonQuery;
}

export function trackShowAllIdenticalOffers(params: IParams) {
  const { position, offer, jsonQuery } = params;

  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'to_identical_offers_listing',
    label: offer.gaLabel,
    searchConditions: jsonQuery,
    products: [
      {
        id: offer.id,
        offerType: 'offer',
        position,
      },
    ],
  });
}
