import { SelectDesktop, NumberInput } from '@cian/ui-kit';
import { plural } from '@cian/utils';
import * as React from 'react';

import { EMetroTravelType } from '../../../../json_query';
import { getDefaultTravelTimeForType } from '../../../../utils/underground';
import { InlineBlock, InlineLabel } from '../../advanced/markup';

const style = require('./index.css');

const METRO_TRAVEL_TYPE_OPTIONS = [
  { value: EMetroTravelType.Any, label: 'неважно' },
  { value: EMetroTravelType.MapFoot, label: 'пешком' },
  { value: EMetroTravelType.MapTransport, label: 'транспортом' },
];

export interface IMetroHeaderProps {
  minutes?: number;
  onRing?: boolean;
  insideRing?: boolean;
  travelType: EMetroTravelType;
  travelTime: number | undefined;
  fastSelectionButtons?: React.ReactNode;
  onUndergroundTravelChanged(travelType: EMetroTravelType, travelTime: number | undefined): void;
}

export const MetroModalHeader: React.FC<IMetroHeaderProps> = props => {
  const { onUndergroundTravelChanged, travelTime: initialTravelTime, travelType } = props;
  const travelTime = initialTravelTime || getDefaultTravelTimeForType(travelType);

  const handleTravelTypeChange = React.useCallback(
    (value: EMetroTravelType) => {
      onUndergroundTravelChanged(value, initialTravelTime || getDefaultTravelTimeForType(value));
    },
    [initialTravelTime, onUndergroundTravelChanged],
  );

  const handleTravelTimeChange = React.useCallback(
    (value: number | undefined) => {
      onUndergroundTravelChanged(travelType, value);
    },
    [onUndergroundTravelChanged, travelType],
  );

  return (
    <div className={style['header']}>
      <div className={style['label']}>Расстояние до метро:</div>
      <div className={style['travel-type-select-wrapper']}>
        <SelectDesktop value={travelType} onChange={handleTravelTypeChange} options={METRO_TRAVEL_TYPE_OPTIONS} />
      </div>
      {travelType !== EMetroTravelType.Any && (
        <div className={style['minutes-wrapper']}>
          <InlineLabel>Не более:</InlineLabel>
          <InlineBlock className={style['minutes']}>
            <NumberInput min={1} max={999} value={travelTime} onChange={handleTravelTimeChange} />
          </InlineBlock>
          <InlineLabel>{plural(travelTime, ['минута', 'минуты', 'минут'])}</InlineLabel>
        </div>
      )}
      <div className={style['buttons']}>{props.fastSelectionButtons}</div>
    </div>
  );
};
