import { ENotAvailablePopupActionTypes } from '../../actions/notAvailablePopup';
import { IAppState, INotAvailablePopupState, TActions } from '../../common/state';

export const defaultState: INotAvailablePopupState = {
  offer: null,
  isOpened: false,
  position: null,
};

export function notAvailablePopupReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case ENotAvailablePopupActionTypes.Open: {
      return {
        ...state,
        notAvailablePopup: {
          offer: action.payload.offer,
          isOpened: true,
          position: action.payload.position,
        },
      };
    }
    case ENotAvailablePopupActionTypes.Close: {
      return {
        ...state,
        notAvailablePopup: defaultState,
      };
    }
  }

  return state;
}
