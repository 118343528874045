import { UIText3 } from '@cian/ui-kit';
import { IconProductDocumentsVerified16 } from '@cian/ui-kit-design-tokens/icons';
import React from 'react';

import styles from './Proof.css';

interface IProofProps {
  text: string;
}

export function Proof({ text }: IProofProps) {
  return (
    <div className={styles['proof']}>
      <div className={styles['icon']}>
        <IconProductDocumentsVerified16 color="primary_100" />
      </div>
      <UIText3 color="black_100">{text}</UIText3>
    </div>
  );
}
