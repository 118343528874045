import { getMultiSelectionParamTagItem } from './fabrics/multi_selection_param_tag';
import { EGarageType } from '../../../../json_query';
import { IGarageTypesTag } from '../../../state/tags/definitions/garage_type';

const garageMap = {
  [EGarageType.Garage]: 'Гараж',
  [EGarageType.CarPlace]: 'Машиноместо',
  [EGarageType.Box]: 'Бокс',
};

export const GarageTypesTagItem = getMultiSelectionParamTagItem<EGarageType, IGarageTypesTag>(garageMap);
