export enum EPhotoFeatureIconsType {
  Has3dTour = 'has3dTour',
  HasFlatTourBooking = 'hasFlatTourBooking',
  HasVideo = 'hasVideo',
}

export interface IPhotoFeatureIcons {
  type: EPhotoFeatureIconsType;
  text: string;
  link?: string;
}
