import * as React from 'react';

import { Button } from '../../../../common/components/button';
import { ILocation, TLocation } from '../../../../types/location';

const style = require('./index.css');

export interface IAreasProps {
  areas: ILocation[] | undefined;
  selectedCity?: ILocation | undefined;
  selectedRegion?: TLocation | undefined;
  onSelected(city: ILocation): void;
  onRegionSelected(region: TLocation): void;
}

export interface IRegionGroup {
  letter: string;
  regions: ILocation[];
}

const LETTER_TITLE_HEIGHT = 4;

export class Areas extends React.Component<IAreasProps> {
  private regionGroups: IRegionGroup[];

  public render() {
    this.parseRegions();

    const result = this.altSortRegions([[], [], [], this.regionGroups]);

    return (
      <div className={style.columnWrapper}>
        {result.map((column, id) => {
          return (
            <div className={style.column} key={id}>
              {column.map((group, index) => {
                return (
                  <div key={index}>
                    <div className={style.letterTitle}>{group.letter}</div>
                    {group.regions.map((region, key) => {
                      return this.getButton(region, key);
                    })}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }

  private parseRegions() {
    this.regionGroups = [];

    if (!this.props.areas) {
      return;
    }

    this.props.areas.forEach(region => {
      if (region.id !== 1 && region.id !== 2) {
        this.addRegion(region);
      }
    });

    this.regionGroups.sort((a, b) => {
      const nameA = a.letter.toUpperCase();
      const nameB = b.letter.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  private addRegion(region: ILocation) {
    const letter: string = region.name[0];
    let isAdded = false;

    this.regionGroups.forEach((value: IRegionGroup) => {
      if (value.letter === letter) {
        value.regions.push(region);
        isAdded = true;
      }
    });

    if (!isAdded) {
      this.createGroup(letter, region);
    }
  }

  private createGroup(letter: string, region: ILocation) {
    this.regionGroups.push({
      letter,
      regions: [region],
    });
  }

  private altSortRegions(initialColumns: IRegionGroup[][]): IRegionGroup[][] {
    const columns: IRegionGroup[][] = initialColumns;

    let isMoved = false;

    isMoved = this.moveColumns(columns[2], columns[3]) ? true : isMoved;
    isMoved = this.moveColumns(columns[1], columns[2]) ? true : isMoved;
    isMoved = this.moveColumns(columns[0], columns[1]) ? true : isMoved;

    if (isMoved) {
      return this.altSortRegions(columns);
    }

    return columns;
  }

  private moveColumns(left: IRegionGroup[], right: IRegionGroup[]) {
    const leftCount = this.getColumnLength(left);
    const rightCount = this.getColumnLength(right);

    if (leftCount <= rightCount && rightCount - leftCount > LETTER_TITLE_HEIGHT) {
      const group = right.shift();
      if (group) {
        left.push(group);
      }

      return true;
    }

    return false;
  }

  private getColumnLength = (arr: IRegionGroup[]) => {
    return arr.reduce((acc, value) => {
      return acc + value.regions.length;
    }, arr.length * LETTER_TITLE_HEIGHT);
  };

  private getButton(region: ILocation, index: number) {
    if (this.props.selectedCity && this.props.selectedCity.id === region.id) {
      return (
        <div className={style.breadcrumbWrapper} key={index}>
          <div className={style.arrow} />
          <Button
            theme="primary"
            className={style['endButton']}
            onClick={() => {
              if (this.props.selectedRegion) {
                this.props.onRegionSelected(this.props.selectedRegion);
              }
            }}
          >
            {this.props.selectedCity.displayName}
          </Button>
        </div>
      );
    }

    return (
      <div
        className={style['column-item']}
        key={index}
        onClick={() => {
          this.props.onSelected(region);
        }}
      >
        {region.displayName}
      </div>
    );
  }
}
