import { getTermValue } from './helpers';
import { EFacilityType, NonEmptyArray, TJsonQuery } from './types';

export function getSuburbanFacilityTypes(jsonQuery: TJsonQuery): NonEmptyArray<EFacilityType> | null {
  const hasSauna = getTermValue('bathhouse')(jsonQuery);
  const hasGarage = getTermValue('garage')(jsonQuery);
  const hasSecurity = getTermValue('security')(jsonQuery);
  const hasPool = getTermValue('pool')(jsonQuery);

  const facilityTypes: EFacilityType[] = [];

  if (hasSauna) {
    facilityTypes.push(EFacilityType.Sauna);
  }

  if (hasGarage) {
    facilityTypes.push(EFacilityType.Garage);
  }

  if (hasSecurity) {
    facilityTypes.push(EFacilityType.Security);
  }

  if (hasPool) {
    facilityTypes.push(EFacilityType.Pool);
  }

  return facilityTypes.length > 0 ? (facilityTypes as NonEmptyArray<EFacilityType>) : null;
}
