import { INewObjectSimilarAndBuilderSchema } from '../../repositories/monolith-python/entities/schemas/NewObjectSimilarAndBuilderSchema';
import { IGetNewobjectsBuilderResponse } from '../../repositories/monolith-python/v1/get-newobjects-builder';
import { ISimilarNewobject } from '../../types/similarNewobjects';

export function prepareSimilarNewobjects(response: IGetNewobjectsBuilderResponse): ISimilarNewobject[] {
  if (!response.data.newobjects) {
    return [];
  }

  return response.data.newobjects.map(prepareSimilarNewobjectsItem);
}

function prepareSimilarNewobjectsItem(newobject: INewObjectSimilarAndBuilderSchema): ISimilarNewobject {
  return {
    ...newobject,
    priceDisplay: newobject.priceDisplay || '',
    addressDisplay: newobject.addressDisplay || '',
    imageUrl: newobject.imageUrl || '',
    fullUrl: newobject.fullUrl || '',
    undergroundInfo: {
      ...newobject.undergroundInfo,
      name: newobject.undergroundInfo?.name || '',
      lineColor: newobject.undergroundInfo?.lineColor || '',
      timeDisplay: newobject.undergroundInfo?.timeDisplay || '',
    },
    buildersDisplay: newobject.buildersDisplay || null,
    minPrice: newobject.minPrice || 0,
    statusDisplay: newobject.statusDisplay || '',
  };
}
