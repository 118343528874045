import { isNil } from 'ramda';

import { ISelectOption, TSelectValue } from '../types';

interface IParameters {
  label?: string;
  value: TSelectValue;
  options: ISelectOption[];
  placeholder?: string;
}

export function getButtonLabel(parameters: IParameters) {
  const { label, value, options, placeholder = '' } = parameters;

  if (label) {
    return label;
  }

  if (isNil(value)) {
    return placeholder || '';
  }

  if (!Array.isArray(value)) {
    const option = options.find(option => option.value === value);

    return option ? option.label : placeholder;
  }

  return (
    options
      .reduce((labels, option) => {
        if (value.includes(option.value)) {
          return [...labels, option.label];
        }

        return labels;
      }, [])
      .join(', ') || placeholder
  );
}
