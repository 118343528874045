export enum EDealType {
  Rent = 'rent',
  Sale = 'sale',
}

export enum EOfferType {
  Flat = 'flat',
  Suburban = 'suburban',
  Newobject = 'newobject',
  Commercial = 'commercial',
}
