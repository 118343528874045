import { numberToPrettyString } from '@cian/utils';

import { IOffer } from '../../../types/offer';

export function getTotalArea(offer: IOffer): string | undefined {
  if (offer.totalArea) {
    return numberToPrettyString(Number(offer.totalArea));
  }

  return undefined;
}
