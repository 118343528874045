import { IAppState } from '../../common/state';
import { MOSCOW_ID, MOSCOW_REGION_ID, SPB_ID, SPB_REGION_ID } from '../../constants/regions';
import { isInRegionSelector } from '../isInRegion';

export const selectSuburbanBlackFridayBannerUrl = (state: IAppState): string => {
  const { isActive, url } = state.featureToggle.suburbanBlackFridaySettings;

  switch (true) {
    case isActive && isInRegionSelector([MOSCOW_ID, MOSCOW_REGION_ID, -1])(state):
      return `https://cian.ru/${url}`;

    case isActive && isInRegionSelector([SPB_ID, SPB_REGION_ID, -2])(state):
      return `https://spb.cian.ru/${url}`;

    default:
      return '';
  }
};
