import * as React from 'react';

import { FDealType } from '../../../../../JsonQuery';
import { IJsonQueryRangeValue } from '../../../types/jsonQuery';
import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { Filter } from '../common/Filter';
import { RangeInput } from '../common/RangeInput';

interface IRoomsInDealProps {
  dealType: FDealType;
  rooms: IJsonQueryRangeValue | null;
  onRoomsInDealMinChange(value: number | null): void;
  onRoomsInDealMaxChange(value: number | null): void;
}

export const RoomsInDeal: React.FC<IRoomsInDealProps> = ({
  dealType,
  rooms,
  onRoomsInDealMinChange,
  onRoomsInDealMaxChange,
}) => {
  const { gte: roomsMin = null, lte: roomsMax = null } = rooms || {};
  const label = FDealType.Rent & dealType ? 'Комнат в аренду' : 'Комнат на продажу';

  const handleRoomsInDealMinChange = useDebouncedCallback(
    (gte: number | null) => {
      onRoomsInDealMinChange(gte);
    },
    300,
    [onRoomsInDealMinChange],
  );

  const handleRoomsInDealMaxChange = useDebouncedCallback(
    (lte: number | null) => {
      onRoomsInDealMaxChange(lte);
    },
    300,
    [onRoomsInDealMaxChange],
  );

  return (
    <Filter id="advancedFilter_roomsInDeal" label={label}>
      <RangeInput
        min={1}
        max={99}
        valueMin={roomsMin}
        valueMax={roomsMax}
        onChangeMin={handleRoomsInDealMinChange}
        onChangeMax={handleRoomsInDealMaxChange}
      />
    </Filter>
  );
};
