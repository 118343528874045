import React from 'react';

import { Link } from '../../../../components/Link';
import { useOfferContext } from '../../../../context';

export function AgentShowMoreOffersContainer() {
  const { agentOffersLink } = useOfferContext();

  if (!agentOffersLink) {
    return null;
  }

  return (
    <Link target="_blank" href={agentOffersLink} onClick={e => e.stopPropagation()} text="Посмотреть все объекты" />
  );
}
