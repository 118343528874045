import * as React from 'react';

import * as styles from './InlineFilter.css';

interface IInlineFilterProps {
  prefix?: React.ReactNode;
  postfix?: React.ReactNode;
}

export const InlineFilter: React.FC<React.PropsWithChildren<IInlineFilterProps>> = ({ prefix, postfix, children }) => {
  return (
    <div className={styles['container']}>
      {prefix && <span className={styles['prefix']}>{prefix}</span>}
      {children}
      {postfix && <span className={styles['postfix']}>{postfix}</span>}
    </div>
  );
};
