export enum EGalleryLabelColor {
  Black = 'black',
  Grey = 'grey',
  LightGrey = 'lightgrey',
  Orange = 'orange',
  Green = 'green',
  Blue = 'blue',
  Red = 'red',
}

export interface IGalleryLabel {
  label: string;
  color: EGalleryLabelColor;
  customColor?: string;
  iconUrl?: string;
}
