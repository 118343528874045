import { InlineText2 } from '@cian/ui-kit';
import React from 'react';

import styles from './JKName.css';
import { ContentRow } from '../ContentRow';
import { SolidJK } from '../SolidJK';

interface IJKNameProps {
  name: string;
  solid: boolean;
  url?: string;
  deadline?: string;
  onClick?(e: React.MouseEvent<HTMLElement>): void;
}

export function JKName({ url, name, solid, deadline, onClick }: IJKNameProps) {
  const Tag = url ? 'a' : 'div';

  return (
    <>
      <ContentRow>
        <Tag className={styles['jk']} href={url} target={'_blank'} onClick={onClick}>
          {solid && <SolidJK href={url} />}
          {name}
        </Tag>
      </ContentRow>
      {deadline && (
        <ContentRow>
          <InlineText2 data-mark="Deadline" color="gray60_100">
            {deadline}
          </InlineText2>
        </ContentRow>
      )}
    </>
  );
}
