import * as React from 'react';

import { EElectronicTradingType } from '../../../../../packages/JsonQuery/types/electronicTradingType';
import { useApplicationContext } from '../../../../utils/applicationContext';
import { IElectronicTradingTag } from '../../../state/tags/definitions/electronic_trading_type';
import { TagNew } from '../TagNew';

export interface IElectronicTradingTypeTagItemProps {
  tag: IElectronicTradingTag;
  onDeleteClick(): void;
}

const electronicTradingTypeMap = {
  [EElectronicTradingType.DoNotDisplay]: 'Не показывать торги и залоговую недвижимость',
  [EElectronicTradingType.ElectronicTradingOnly]: 'Только торги и залоговая недвижимость',
};

export const ElectronicTradingTypeTagItem = ({ tag, onDeleteClick }: IElectronicTradingTypeTagItemProps) => {
  const { config } = useApplicationContext();
  /**
   * @todo Удалить фича-тогл
   * @description Данный функционал появился в задаче CD-187920, будет удалён в задаче CD-189395
   */
  const isElectronicTradingEnabled = config.getStrict<boolean>('Audience.ElectronicTrading.Enabled');

  if (tag.value === null || !isElectronicTradingEnabled) {
    return null;
  }

  return <TagNew onDeleteClick={onDeleteClick}>{electronicTradingTypeMap[tag.value]}</TagNew>;
};
