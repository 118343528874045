import { plural } from '@cian/utils/lib/shared/plural';
import { connect } from 'react-redux';

import { IAppState } from '../../../../common/state';
import { getDealType } from '../../../../selectors/getDealType';
import { selectOffersCount } from '../../../../selectors/results/selectOffersCount';
import { EDealType } from '../../../../types/dealType';
import { getUniqueTotalCountTitle } from '../../../../utils/getUniqueTotalCountTitle';
import { numberToPrettyString } from '../../../../utils/numberToPrettyString';
import { SummaryHeader } from '../../../components/Summary';

export function mapStateToProps(state: IAppState) {
  const offersCount = selectOffersCount(state);
  const dealType = getDealType(state);
  const isDailyrent = dealType === EDealType.RentDaily;

  /**
   * @todo Прикрутить логику получения данных с бэка в рамках задачи CD-112615
   * @description Данный функционал появился в задаче CD-110067, был удалён в задаче CD-110558
   */
  const totalCountTitle = getUniqueTotalCountTitle(0);

  if (isDailyrent) {
    return { header: '' };
  }

  return {
    header: `Найдено ${numberToPrettyString(offersCount)} ${plural(offersCount, [
      'объявление',
      'объявления',
      'объявлений',
    ])}`.concat(totalCountTitle),
  };
}

export const SummaryHeaderContainer = connect(mapStateToProps)(SummaryHeader);
