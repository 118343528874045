import { UIText2 } from '@cian/ui-kit/typography';
import * as React from 'react';

import styles from './KPAdvantages.css';
import { advantageIcons } from './icons';
import { IVillageAdvantage } from '../../types/kp';

export function KPAdvantages({ items }: { items: IVillageAdvantage[] }) {
  return (
    <div className={styles['container']}>
      {items.map(({ advantageType, text }) => {
        const iconSrc = advantageIcons[advantageType];

        return (
          <div key={advantageType} className={styles['advantage-container']}>
            <img className={styles['advantage-icon']} src={iconSrc} alt={advantageType} />
            <div className={styles['advantage-text']}>
              <UIText2>{text}</UIText2>
            </div>
          </div>
        );
      })}
    </div>
  );
}
