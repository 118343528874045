import { IJsonQueryRangeValue } from '../../../../types/jsonQuery';
import { ESuburbanFloors } from '../../../../types/suburbanFloors';

export const getEnumValue = (floors: IJsonQueryRangeValue) => {
  switch (true) {
    case floors.lte === 1 && (!floors.gte || floors.gte === 1):
      return ESuburbanFloors.One;

    case (floors.gte === 2 && (!floors.lte || floors.lte >= 2)) || (!floors.gte && floors.lte && floors.lte >= 2):
      return ESuburbanFloors.TwoAndMore;

    default:
      return null;
  }
};
