import * as React from 'react';

import styles from './TradeRealtyTooltipContent.css';
import { useApplicationContext } from '../../../../../shared/utils/applicationContext';
import { Link } from '../Link';

export const TradeRealtyTooltipContent: React.FC = () => {
  const { config } = useApplicationContext();

  const legalDocsUrl = config.getStrict<string>('electronicTrading.legalDocsUrl');

  return (
    <>
      Объект реализуется через проведение <br />
      торгов в соответствии с ГК РФ
      <div className={styles['link-wrapper']}>
        <Link
          target="_blank"
          href={legalDocsUrl}
          text="Справочная информация"
          onClick={/* istanbul ignore next */ () => {}}
        />
      </div>
    </>
  );
};
