export enum ERoomCountType {
  /** Койко-место **/
  Bed = 'bed',
  /** Пятикомнатная квартира **/
  FiveRoom = 'fiveRoom',
  /** Четырехкомнатная квартира **/
  FourRoom = 'fourRoom',
  /** Многокомнатная квартира (6 и более комнат) **/
  Large = 'large',
  /** Однокомнатная квартира **/
  OneRoom = 'oneRoom',
  /** Квартира со свободной планировкой **/
  OpenPlan = 'openPlan',
  /** Комната **/
  Room = 'room',
  /** Доля в квартире **/
  Share = 'share',
  /** Студия **/
  Studio = 'studio',
  /** Трехкомнатная квартира **/
  ThreeRoom = 'threeRoom',
  /** Двухкомнатная квартира **/
  TwoRoom = 'twoRoom',
}

export interface IDeveloperInfoFlatStatsFlats {
  /** Кол-во объяв данной комнатности **/
  count?: number | null;
  /** Тип комнатности **/
  roomCountType?: ERoomCountType | null;
  /** URL на листинг объяв завстройщика данной комнатности **/
  url?: string | null;
}

export interface IDeveloperInfoFlatStats {
  /** undefined **/
  flats?: IDeveloperInfoFlatStatsFlats[] | null;
  /** Максимальная стоимость квартиры **/
  maxPrice?: number | null;
  /** Минимальная стоимость квартиры **/
  minPrice?: number | null;
  /** Общее кол-во квартир у застройщика **/
  total?: number | null;
  /** URL на листинг объяв завстройщика **/
  url?: string | null;
}

export interface IDeveloperInfoResidentialComplexesStats {
  /** Описание того сколько ЖК сдано или строится **/
  name?: string | null;
  /** URL на листинг ЖК соответствующего статуса **/
  url?: string | null;
}

export interface IDeveloperInfoWorkTime {
  /** Звонить с **/
  callFrom?: string | null;
  /** Звонить до **/
  callTo?: string | null;
  /** Realty User ID **/
  userId?: number | null;
}

export interface IDeveloperInfo {
  /** Статистика по квартирам **/
  flatsStat?: IDeveloperInfoFlatStats | null;
  /** ID застройщика **/
  id: number;
  /** URL логотипа **/
  logoUrl: string;
  /** Название **/
  name: string;
  /** undefined **/
  newobjectsStats?: IDeveloperInfoResidentialComplexesStats[] | null;
  /** Ссылка листинг ЖК **/
  newobjectsUrl?: string | null;
  /** Начало работы **/
  since?: number | null;
  /** Ссылка на профиль застройщика **/
  url?: string | null;
  /** Информация о рабочих часах отдела продаж **/
  workTimeInfo?: IDeveloperInfoWorkTime | null;
}
