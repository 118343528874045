import { isSuburban } from './category/isSuburban';
import { getOrder } from './geo/getOrder';
import { underground } from './underground';
import { TComputedPropMapper } from '../../types/computed-props-mapper';
import { IJsonQueryRailway } from '../../types/jsonQuery';
import { IRailway } from '../../types/offer';
/**
 * Берем Ж/Д из гео офера geo.railways
 * выводим первый элемент из списка, отсортировав в соответствии с geo.sortedGeoIds
 */
export const railway: TComputedPropMapper<IRailway | undefined> = (props, ctx) => {
  const metro = underground(props, ctx);
  const railways = props.offer.geo?.railways || [];
  const sortedGeoIds = props.offer.geo?.sortedGeoIds || [];
  const railwaysOrderMap: Map<number, number> = (
    sortedGeoIds.filter(item => item.type === 'railway') as IJsonQueryRailway[]
  ).reduce((map, item, index) => {
    map.set(item.id, index);

    return map;
  }, new Map<number, number>());

  /**
   * Сортируем согласно порядку полученному из geo.sortedGeoId
   * станции, по каким то причинам, не попавшие в geo.sortedGeoId, идут низшим приоритетом
   */
  const offerRailways = railways
    .concat()
    .sort((a, b) => getOrder(railwaysOrderMap, a.id, Infinity) - getOrder(railwaysOrderMap, b.id, Infinity));

  return isSuburban(props.offer) || !metro ? offerRailways[0] : undefined;
};
