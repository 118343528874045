import { TLocation } from '../../types/location';
import { getRegionId, MOId } from '../../utils/geo';
import { IMakeRequest } from '../../utils/request';

export interface IDirection {
  highways: IHighway[];
  name: string;
}

export interface IHighway {
  fullName: string;
  id: number;
  name: string;
}

export interface IRequestDirectionsData {
  data: IDirection[];
  regionId: number;
}

export const directions = {
  requestData(makeRequest: IMakeRequest, regionId: number): Promise<IRequestDirectionsData> {
    return makeRequest({
      apiType: 'legacy',
      microserviceName: 'monolith-cian-realty',
      pathApi: '/api/geo/get-roads',
      parameters: {
        regionId,
      },
      method: 'GET',
    }).then(data => {
      return {
        data,
        regionId,
      } as IRequestDirectionsData;
    });
  },

  getRegionId(location: TLocation): number {
    let regionId = getRegionId(location);

    if (regionId === -1) {
      regionId = MOId;
    }

    return regionId;
  },
};
