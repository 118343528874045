import { ArticleHeading1, ArticleParagraph1, LinkButton } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './SuburbanBuildersProjectsBanner.css';

export const SuburbanBuildersProjectsBanner = () => {
  return (
    <div className={styles['container']}>
      <div className={styles['content']}>
        <div className={styles['title']}>
          <ArticleHeading1>Проекты домов для любого участка</ArticleHeading1>
        </div>
        <div className={styles['description']}>
          <ArticleParagraph1>
            Большой выбор материалов и видов домов от надёжных строительных компаний
          </ArticleParagraph1>
        </div>
        <LinkButton
          size="M"
          theme="fill_primary"
          href="/country-builders-projects/?utm_medium=listing&utm_content=banner"
          target="_blank"
        >
          Посмотреть все
        </LinkButton>
      </div>
      <div className={styles['image']} />
    </div>
  );
};
