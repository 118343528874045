import { IAppState } from '../../common/state';
import { AUD_DESKTOP_AUD2776_CIAN_ASSISTANT_ENTRY_POINT } from '../../constants/experiments';

export function selectIsCianAssistantExperimentEnabled({ abUseExperiments }: IAppState) {
  const experiment = abUseExperiments.find(e => e.experimentName === AUD_DESKTOP_AUD2776_CIAN_ASSISTANT_ENTRY_POINT);

  if (!experiment) {
    return false;
  }

  return experiment.groupName === 'B' || experiment.groupName === 'C';
}
