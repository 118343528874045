import * as React from 'react';

import { IUserIdTag } from '../../../state/tags/definitions/user_id';
import { TagNew } from '../TagNew';

export interface IUserIdTagItemProps {
  tag: IUserIdTag;
  onDeleteClick(): void;
}

export const UserIdTagItem = ({ onDeleteClick, tag }: IUserIdTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>Агент №{tag.value}</TagNew>;
};
