/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TRegionalDistrictsGetByLocationIdModel,
  IRegionalDistrictsGetByLocationIdRequest,
  IMappers,
  IRegionalDistrictsGetByLocationIdResponse,
  IRegionalDistrictsGetByLocationIdResponseError,
  TRegionalDistrictsGetByLocationIdResponse,
} from './types';

const defaultConfig: TRegionalDistrictsGetByLocationIdModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'geo',
  pathApi: '/v1/regional-districts/get-by-location-id/',
  hostType: 'api',
} as TRegionalDistrictsGetByLocationIdModel;

function createRegionalDistrictsGetByLocationIdModel(
  parameters: IRegionalDistrictsGetByLocationIdRequest,
): TRegionalDistrictsGetByLocationIdModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IRegionalDistrictsGetByLocationIdOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IRegionalDistrictsGetByLocationIdRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchRegionalDistrictsGetByLocationId<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IRegionalDistrictsGetByLocationIdOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TRegionalDistrictsGetByLocationIdResponse
> {
  const { response, statusCode } = await httpApi.fetch(createRegionalDistrictsGetByLocationIdModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IRegionalDistrictsGetByLocationIdResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IRegionalDistrictsGetByLocationIdResponseError);
    }
  }

  return { response, statusCode } as TRegionalDistrictsGetByLocationIdResponse;
}

export { defaultConfig, createRegionalDistrictsGetByLocationIdModel, fetchRegionalDistrictsGetByLocationId };
