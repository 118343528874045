import { pathOr } from 'ramda';

import { IGetUserDataByRequestResponse } from '../../repositories/monolith-python/v1/get-user-data-by-request';
import { TUser } from '../../types/user';

export function prepareUser(response: IGetUserDataByRequestResponse): TUser {
  const isAuthenticated = pathOr(false, ['user', 'isAuthenticated'], response.data);

  if (!isAuthenticated) {
    return { isAuthenticated };
  }

  return {
    isAuthenticated,
    // 'natural' | 'legal'
    accountType: pathOr(null, ['user', 'accountType'], response.data),
    // 'specialist' | 'agency'
    agentAccountType: pathOr(null, ['user', 'agentAccountType'], response.data),
    balance: pathOr(0, ['user', 'balance'], response.data),
    displayName: pathOr(null, ['user', 'displayName'], response.data),
    // string
    email: pathOr(null, ['user', 'email'], response.data),
    firstName: pathOr(null, ['user', 'firstName'], response.data),
    // number
    id: pathOr(null, ['user', 'id'], response.data),
    isAgent: pathOr(false, ['user', 'isAgent'], response.data),
    isBuilder: pathOr(false, ['user', 'isBuilder'], response.data),
    isModerator: pathOr(false, ['user', 'isModerator'], response.data),
    isNew: pathOr(false, ['user', 'isNew'], response.data),
    isProfi: pathOr(false, ['user', 'isProfi'], response.data),
    isSubscriptions: pathOr(false, ['user', 'isSubscriptions'], response.data),
    lastName: pathOr(null, ['user', 'lastName'], response.data),
    // IUserPermissions
    permissions: pathOr(null, ['user', 'permissions'], response.data),
    // IUserPhone[] | null
    phones: pathOr(null, ['user', 'phones'], response.data),
    // boolean?, в API string
    publicProfile: pathOr(null, ['user', 'publicProfile'], response.data),
    // string[]
    tariff: pathOr(null, ['user', 'tariff'], response.data),
    // number
    userId: pathOr(null, ['user', 'userId'], response.data),
    // string
    userType: pathOr(null, ['user', 'userType'], response.data),
    webimHash: pathOr(null, ['user', 'webimHash'], response.data),
  } as TUser;
}
