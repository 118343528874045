import { numberToPrettyString } from '@cian/utils';

import { getFlowMetersInfo } from './getFlowMetersInfo';
import { IOffer } from '../../../../types/offer';
import { isDailyRent } from '../../category/isDailyRent';

export function getUtilitiesTermsPrice(offer: IOffer): string | null {
  if (isDailyRent(offer)) {
    return null;
  }

  const { utilitiesTerms } = offer.bargainTerms;

  /**
   * Если с бекенда не приходит utilitiesTerms, значит ком. платежи включены,
   * так исторически сложилось
   */
  if (!utilitiesTerms) {
    return 'комм. платежи включены (без счётчиков)';
  }

  if (utilitiesTerms.includedInPrice) {
    return `комм. платежи включены ${getFlowMetersInfo(utilitiesTerms)}`;
  } else {
    if (utilitiesTerms.price === 0) {
      return 'комм. платежи не включены';
    } else {
      const priceRent = offer.bargainTerms.priceRur;
      const priceUtilities = utilitiesTerms.price;

      return `${numberToPrettyString(priceRent)} ₽ + ${numberToPrettyString(
        priceUtilities,
      )} ₽ комм. платежи ${getFlowMetersInfo(utilitiesTerms)}`;
    }
  }
}
