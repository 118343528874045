import { getGenplan } from './getGenplan';
import { getHouseById } from './getHouseById';
import { IAppState } from '../../common/state';
import { IGenplanExtended } from '../../types/newbuildingHeader';

export function getExtenedGenplan(state: IAppState): IGenplanExtended | null {
  const genplan = getGenplan(state);

  if (genplan) {
    const { houses } = genplan;

    if (houses) {
      const extendedHouseData = houses.map(house => {
        const houseById = getHouseById(house.id)(state);

        const { floorsCount, fromDeveloperPropsCountDisplay, finishStatus } = houseById || {};

        return {
          ...house,
          floorsCount,
          fromDeveloperPropsCountDisplay,
          finishStatus,
        };
      });

      return {
        ...genplan,
        houses: extendedHouseData,
      };
    }
  }

  return null;
}
