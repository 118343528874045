import { IAppState } from '../../common/state';
import { isFlatRentQuery } from '../../utils/queryString/isFlatRentQuery';
import { selectQueryString } from '../results';

/**
 * Эксперимент "Изменение количества объявлений" AUD-2397
 * @todo удалить в рамках CD-168864
 */
export function selectOffersPerPage(state: IAppState): number {
  const { allowedDomains, extendedOffersPerPage, isExtendedEnabled, offersPerPage } =
    state.featureToggle.offersPerPageSettings;
  const queryString = selectQueryString(state);

  const isFlatRent = isFlatRentQuery(queryString);

  const isExtendedResults = isFlatRent && isExtendedEnabled && allowedDomains.includes(state.currentSubdomain);

  return isExtendedResults ? extendedOffersPerPage : offersPerPage;
}
