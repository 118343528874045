import { ca } from '@cian/analytics';

import { IJsonQuery } from '../../../types/jsonQuery';

interface IParams {
  offerId: number;
  position: number;
}

export function trackShowRosreestrCheckedLabel(jsonQuery: IJsonQuery, { offerId, position }: IParams) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'label',
    action: 'show',
    label: 'rosreestr_check',
    sc: jsonQuery,
    products: [
      {
        id: offerId,
        offerType: 'offer',
        position,
      },
    ],
  });
}
