import { showNotification } from './showNotification';
import { trackAuctionErrorPopupShow } from './tracking';

export function showFailNotification(retryCb: () => Promise<void>) {
  trackAuctionErrorPopupShow();

  showNotification({
    status: 'failed',
    onRetryClick: retryCb,
  });
}
