import { ContactAvatar, ContactBar, TContactType } from '@cian/contact-card-component';
import React from 'react';

import { DailyrentPartnerButtonContainer } from './AgentBookButtonContainer';
import * as styles from './AgentContainer.css';
//TODO: Это надо вернуть попапом в следующей итерации
// import { AgentProofsContainer } from './AgentProofsContainer';
import { AgentWorkHonestlyProofsContainer } from './AgentWorkHonestlyProofsContainer';
import { ContactActionsContainer } from './ContactActionsContainer/ContactActionsContainer';
import { SuperAgentLevelViewContainer } from './SuperAgentLevelViewContainer';
import { BrandingLevelWrapper } from '../../../components/BrandingLevelWrapper';
import { useOfferContext } from '../../../context';
import { getAgentTypeTitle } from '../../../utils/getAgentTypeTitle';

const AVATAR_TYPE: {
  [key: string]: string | undefined;
} = {
  builderRepresentative: 'builder',
  consultant: 'builder',
  managementCompany: 'agency',
  realtor: 'agent',
  // У электронных торгов все отображения аватара как у типа "bank"
  tradingPlatform: 'bank',
  government: 'bank',
};

export function AgentContainer() {
  const offerData = useOfferContext();
  const {
    agentType,
    agentImageLink,
    agentName,
    agentProfileLink,
    offer: { brandingLevel, dailyrent },
  } = offerData;
  const avatarType = (AVATAR_TYPE[agentType] || agentType) as TContactType;

  if (dailyrent?.hotelData?.isHotel) {
    return (
      <div data-name="DailyrentActionButtonsContainer" className={styles['hotel-button']}>
        <DailyrentPartnerButtonContainer />
      </div>
    );
  }

  return (
    <BrandingLevelWrapper brandingLevel={brandingLevel}>
      <ContactBar
        title={getAgentTypeTitle(agentType)}
        avatar={<ContactAvatar type={avatarType} src={agentImageLink} />}
        name={agentName || ''}
        href={agentProfileLink}
        target="_blank"
        qualityInfo={<SuperAgentLevelViewContainer />}
        labels={<AgentWorkHonestlyProofsContainer />}
        actions={<ContactActionsContainer />}
      />
    </BrandingLevelWrapper>
  );
}
