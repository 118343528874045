import { TComputedPropMapper } from '../../types/computed-props-mapper';

export const agentProfileLink: TComputedPropMapper<string | undefined> = ({ offer: { user } }, { config }) => {
  const { profileUri } = user?.masterAgent || user || {};

  if (profileUri) {
    const baseUrl = config.get<string>('browserApiBaseUrl') || '';

    return `${baseUrl}${profileUri}`;
  }

  return undefined;
};
