import { advancedFilters } from './advancedFilters';
import { bedrooms } from './bedrooms';
import { beds } from './beds';
import { cottageVillage } from './cottageVillage';
import { dates } from './dates';
import { dealType } from './dealType';
import { geo } from './geo';
import { offerType } from './offerType';
import { price } from './price';
import { roomType } from './roomType';
import { starRating } from './starRating';
import { suburbanArea } from './suburbanArea';
import { villageMortgageAllowed } from './villageMortgageAllowed';
import { IFilter, TFilter } from '../../types/filters';

export const FILTERS: { [key in TFilter]: IFilter } = {
  advancedFilters,
  bedrooms,
  beds,
  cottageVillage,
  dates,
  dealType,
  geo,
  offerType,
  price,
  roomType,
  villageMortgageAllowed,
  starRating,
  suburbanArea,
};
