import { plural } from '@cian/utils';

import { IOffer } from '../../../types/offer';
import { isSuburban } from '../category/isSuburban';
import { isSuburbanSale } from '../category/isSuburbanSale';

export function getBedroomCount(offer: IOffer): string | undefined {
  if (isSuburbanSale(offer)) {
    return undefined;
  }

  if (isSuburban(offer) && offer.bedroomsCount) {
    return `${offer.bedroomsCount} ${plural(offer.bedroomsCount, ['спальня', 'спальни', 'спален'])}`;
  }

  return undefined;
}
