import { ResponseError } from '../../errors';
import {
  fetchGetVillageForMap,
  TGetVillageForMapResponse,
} from '../../repositories/countryside-search/v1/get-village-for-map';
import { IApplicationContext } from '../../types/applicationContext';
import { IVillageInfo } from '../../types/villageInfo';

interface IFetchVillageInfoParameters {
  id: number;
}

async function fetchVillageInfo(
  { httpApi }: IApplicationContext,
  { id }: IFetchVillageInfoParameters,
): Promise<IVillageInfo | null> {
  const villageInfoData: TGetVillageForMapResponse = await fetchGetVillageForMap({
    httpApi,
    parameters: {
      id,
    },
  });

  if (villageInfoData.statusCode !== 200) {
    throw new ResponseError({
      domain: 'fetchVillageInfo',
      message: villageInfoData.response.message,
      details: {
        error: villageInfoData.response.errors,
      },
    });
  }

  return villageInfoData.response as IVillageInfo;
}

export async function fetchVillageInfoSafe(
  context: IApplicationContext,
  parameters: IFetchVillageInfoParameters,
): Promise<IVillageInfo | null> {
  try {
    return await fetchVillageInfo(context, parameters);
  } catch (ex) {
    const { logger } = context;

    logger.warning(ex, { degradation: 'Информация о КП' });

    return null;
  }
}
