import { Button, UIHeading2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';
import { NBSP } from '../../../constants/symbols';

interface IMortgageBrokerBannerProps {
  url: string;
  onClick: () => void;
}

/**
 * Компонент баннера "Циан.Ипотека для профи"
 */
export function MortgageBrokerBanner({ url, onClick }: IMortgageBrokerBannerProps) {
  return (
    <div className={styles['container']} data-testid="mortgage-broker-banner">
      <a
        href={`${url}?cian_source=listing_banner`}
        target="_blank"
        rel="noreferrer"
        data-testid="mortgage-broker-banner-inner-wrapper"
        onClick={onClick}
        className={styles['wrapper']}
      >
        <div className={styles['title']}>Ипотека для профи</div>
        <div className={styles['description']}>
          <UIHeading2 color="white_100">
            Клиенту — новый дом, вам{NBSP}—{NBSP}деньги{NBSP}на{NBSP}счёт
          </UIHeading2>
        </div>
        <div className={styles['button']}>
          <Button size="M" theme="fill_white_primary">
            Заработать на ипотеке
          </Button>
        </div>
      </a>
    </div>
  );
}
