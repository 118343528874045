import { IConfig } from '@cian/config/shared';

import { useApplicationContext } from '../applicationContext';

export const RS_FEATURE_TOGGLE_NAME = 'Dailyrent.OnlineBookingFrontend.Enabled';

export function getIsDailyrentOnlineBookingEnabled(config: IConfig) {
  return !!config.get<boolean>(RS_FEATURE_TOGGLE_NAME);
}

export function useIsDailyrentOnlineBookingEnabled() {
  const { config } = useApplicationContext();

  return getIsDailyrentOnlineBookingEnabled(config);
}
