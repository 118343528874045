import { IFlatTourBookingSchedule, IOrderStatus } from '../../../types/newbuildingHeader';
import { ITypedReduxAction } from '../../../types/redux/actionType';
import { actionGenerator } from '../../../utils/redux';

export enum EFlatTourBookingActionTypes {
  SetScheduleData = 'flatTourBooking/SET_SCHEDULE_DATA',
  SetFlatViewOrder = 'flatTourBooking/SET_FLAT_VIEW_ORDER',
  ToggleNotification = 'flatTourBooking/TOGGLE_NOTIFICATION',
}

export type TSetScheduleDataAction = ITypedReduxAction<
  EFlatTourBookingActionTypes.SetScheduleData,
  IFlatTourBookingSchedule
>;

export type TSetFlatViewOrderAction = ITypedReduxAction<EFlatTourBookingActionTypes.SetFlatViewOrder, IOrderStatus>;

export type TFlatTourBookingNotificationAction = ITypedReduxAction<
  EFlatTourBookingActionTypes.ToggleNotification,
  boolean
>;

export const setScheduleData = actionGenerator<EFlatTourBookingActionTypes.SetScheduleData, IFlatTourBookingSchedule>(
  EFlatTourBookingActionTypes.SetScheduleData,
);

export const setFlatViewOrder = actionGenerator<EFlatTourBookingActionTypes.SetFlatViewOrder, IOrderStatus>(
  EFlatTourBookingActionTypes.SetFlatViewOrder,
);

export const toggleFlatTourBookingNotification = actionGenerator<
  EFlatTourBookingActionTypes.ToggleNotification,
  boolean
>(EFlatTourBookingActionTypes.ToggleNotification);

export type TFlatTourBookingActions =
  | TSetScheduleDataAction
  | TSetFlatViewOrderAction
  | TFlatTourBookingNotificationAction;
