import { IJsonQueryRegionalDistrict } from '../../../../../json_query';
import { ensureSingleTagExists } from '../../helpers';
import { ITagDefinition, TTag } from '../../index';

const IGeoRegionalDistrictTagType = 'geo-regional-district';

export interface IGeoRegionalDistrictTag {
  type: 'geo-regional-district';
  value: number;
  text: string;
}

export function isGeoRegionalDistrictTag(tag: TTag): tag is IGeoRegionalDistrictTag {
  return tag.type === IGeoRegionalDistrictTagType;
}

export const geoRegionalDistrictTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery, tagsData) => {
    const values =
      jsonQuery.geo &&
      (jsonQuery.geo.value.filter(geo => geo.type === 'regional_district_id') as IJsonQueryRegionalDistrict[]).map(
        geo => geo.id,
      );

    let nextTags = tags.filter(
      tag => tag.type !== IGeoRegionalDistrictTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce((result, value) => {
        const tagData = tagsData.regionalDistricts[value];

        return ensureSingleTagExists(
          result,
          (tag: TTag): tag is IGeoRegionalDistrictTag => isGeoRegionalDistrictTag(tag) && tag.value === value,
          () => ({
            type: IGeoRegionalDistrictTagType,
            value,
            text: tagData || 'Неизвестный район',
          }),
        );
      }, nextTags);
    }

    return nextTags;
  },
};
