import { UIText3, Checkbox } from '@cian/ui-kit';
import cx from 'classnames';
import * as React from 'react';

import * as styles from './styles.css';
import { IOption } from '../../../types';

interface IMultiSelectDesktopOption {
  value: string;
  name?: string;
  focused: boolean;
  selected: boolean;
  label: IOption['label'];
  description: IOption['description'];
  rightAdornment: IOption['rightAdornment'];
  onClick?(): void;
  onMouseMove?(): void;
}

export const MultiSelectDesktopOption = React.forwardRef(
  (props: IMultiSelectDesktopOption, ref: React.Ref<HTMLDivElement>) => {
    const { value, name, focused, selected, label, description, rightAdornment, onClick, onMouseMove } = props;

    return (
      <div
        ref={ref}
        className={cx(styles['checkbox-wrapper'], focused && styles['focused'])}
        onClick={onClick}
        onMouseMove={onMouseMove}
        role="option"
        aria-selected={selected ? 'true' : undefined}
        tabIndex={0}
      >
        <div className={styles['checkbox']}>
          <Checkbox
            name={name}
            value={value}
            checked={selected}
            tabIndex={-1}
            // preventDefault нужен для того чтобы не пропадал фокус по клику внутри
            onClick={preventDefault}
          />
        </div>
        <div className={styles['checkbox-label']}>
          <div className={styles['checkbox-label-text']}>{label}</div>
        </div>
        {description && (
          <div className={styles['checkbox-description']}>
            <UIText3 color="gray60_100">{description}</UIText3>
          </div>
        )}
        {rightAdornment && <div className={styles['checkbox-adornment']}>{rightAdornment}</div>}
      </div>
    );
  },
);

function preventDefault(event: React.SyntheticEvent) {
  event.preventDefault();
}

MultiSelectDesktopOption.displayName = 'MultiSelectDesktopOption';
