import classNames from 'classnames';
import * as React from 'react';

import * as styles from './BrandingLevelWrapper.css';
import { EBrandingLevel } from '../../types/offer';

interface IBrandingLevelWrapperProps {
  brandingLevel: EBrandingLevel | null;
}

export const BrandingLevelWrapper: React.FC<React.PropsWithChildren<IBrandingLevelWrapperProps>> = ({
  brandingLevel,
  children,
}) => {
  const className = classNames(styles['branding-level-wrapper'], {
    [styles['platinum']]: brandingLevel === EBrandingLevel.ThirdLevel,
  });

  return <div className={className}>{children}</div>;
};
