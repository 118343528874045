import React from 'react';

import { TimeLabel } from '../../../../components/TimeLabel';
import { useOfferContext } from '../../../../context';

export function PublicationDateContainer() {
  const { offer } = useOfferContext();

  return <TimeLabel added={offer.added} humanizedTime={offer.humanizedTimedelta} />;
}
