import {
  IDeleteOfferFromComparisonResponseError,
  IDeleteOfferFromComparisonResponse400,
} from '../../../../repositories/offers-comparison/v1/delete-offer-from-comparison';

export const mapDeleteOfferFromComparisonResponseError = (
  response: IDeleteOfferFromComparisonResponseError,
): IDeleteOfferFromComparisonResponse400 => {
  return {
    statusCode: 400,
    headers: [],
    response: {
      errors: response.errors,
      message: response.message,
    },
  };
};
