import * as React from 'react';

import { IComissionTypeTag } from '../../../state/tags/definitions/comission_type';
import { TagNew } from '../TagNew';

export interface IComissionTypeTagItemProps {
  tag: IComissionTypeTag;
  onDeleteClick(): void;
}

export const ComissionTypeTagItem = ({ onDeleteClick, tag }: IComissionTypeTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>{tag.value === 1 ? 'Эксклюзивная комиссия' : 'Без комиссии'}</TagNew>;
};
