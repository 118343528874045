import * as React from 'react';

import { Button } from '../../../../../common/components/button';
import { IHighway } from '../../../../../types/direction';

const style = require('./index.css');

export interface IHighwayProps {
  highway: IHighway;
  selected: boolean;

  deselectHighways(highwaysIds: number[]): void;
  selectHighways(highwaysIds: number[]): void;
}

export class Highway extends React.Component<IHighwayProps> {
  public render() {
    const { selected, highway } = this.props;

    return (
      <div className={style['highway']} onClick={this.handleClick}>
        {selected ? (
          <Button theme="primary" className={style['button']}>
            {highway.fullName}
          </Button>
        ) : (
          <span className={style['highway-title']}>{highway.fullName}</span>
        )}
      </div>
    );
  }

  private handleClick = () => {
    const { deselectHighways, highway, selected, selectHighways } = this.props;

    if (selected) {
      return deselectHighways([highway.id]);
    }

    selectHighways([highway.id]);
  };
}
