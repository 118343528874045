import { IConfig } from '@cian/config/shared';

import { IJsonQuery, IJsonQueryUnderground, TGeoValue } from '../../repositories/common/json_query';
import { IUndergroundShortcuts } from '../../types/undergroundStationData';
import { includesAll } from '../includesAll';

interface IUndergroundSearchPageExtra {
  underground_all?: string;
}

export function getUndergroundSearchPageExtra(
  jsonQuery: IJsonQuery,
  region: string,
  config: IConfig,
): IUndergroundSearchPageExtra {
  const extra: IUndergroundSearchPageExtra = {};

  const undergroundIds = jsonQuery.geo?.value.filter(findUndergroundGeo).map(underground => underground.id) || [];
  const data = config.get<IUndergroundShortcuts>('undergroundMapShortcuts') || {};

  if (data[region]) {
    const shortcuts = data[region];
    for (const shortcut of shortcuts) {
      if (includesAll(undergroundIds, shortcut.ids)) {
        extra.underground_all = shortcut.label;
        break;
      }
    }
  }

  return extra;
}

function findUndergroundGeo(geo: TGeoValue): geo is IJsonQueryUnderground {
  return geo.type === 'underground';
}
