import * as React from 'react';

import { TagNew } from '../TagNew';

export interface IWithLayoutTagItemProps {
  onDeleteClick(): void;
}

export const WithLayoutTagItem = (props: IWithLayoutTagItemProps) => {
  return <TagNew onDeleteClick={props.onDeleteClick}>Со схемой планировки</TagNew>;
};
