import { plural } from '@cian/utils';

import { TOfferCategory } from '../../../types/offer';

type TPlural = [string, string, string];

const flat: TPlural = ['такая же квартира', 'такие же квартиры', 'таких же квартир'];
const suburban: TPlural = ['похожий объект', 'похожих объекта', 'похожих объектов'];

export function getSimilarLinkText(count: number, category?: TOfferCategory | null): string {
  const modifiedCount = count + 1;
  switch (category) {
    case 'newBuildingFlatSale':
      return `Ещё ${modifiedCount} ${plural(modifiedCount, flat)} в этом ЖК`;
    case 'cottageSale':
    case 'houseSale':
    case 'houseShareSale':
    case 'landSale':
    case 'townhouseSale':
      return `Ещё ${count} ${plural(count, suburban)} в этом КП`;
    default:
      return `Ещё ${modifiedCount} ${plural(modifiedCount, flat)} в этом доме`;
  }
}
