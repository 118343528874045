import * as React from 'react';

import { starRatingOptions } from './constants';
import { EStarRating, NonEmptyArray } from '../../../../../JsonQuery';
import { Select, Option } from '../../Select';

interface IStarRatingProps {
  open?: boolean;
  label: string | null;
  value: NonEmptyArray<EStarRating> | null;
  popupPostfix?: React.ReactNode;
  openTooltip?: boolean;
  onChange(value: EStarRating[] | null): void;
  onApply?(): void;
  onOpen?(): void;
  onClose?(): void;
}

export const StarRating: React.FC<IStarRatingProps> = ({
  open,
  label,
  value,
  popupPostfix,
  openTooltip,
  onChange,
  onApply,
  onOpen,
  onClose,
}) => {
  return (
    <Select
      open={open}
      multiple
      withoutArrow
      label={label || undefined}
      placeholder="Количество звёзд"
      value={value}
      openTooltip={openTooltip}
      onChange={(_, value: EStarRating[] | null) => onChange(value)}
      onApply={onApply}
      onOpen={onOpen}
      onClose={onClose}
      popupPostfix={popupPostfix}
    >
      {starRatingOptions.map(({ label, value }) => (
        <Option key={label} value={value}>
          {label}
        </Option>
      ))}
    </Select>
  );
};
