import { getServiceName } from './getServiceName';
import { EAuctionTooltipView } from '../../../types/auction';
import { IOffer } from '../../../types/offer';

export const getTooltipView = (offer: IOffer, isPersonalBet: boolean, maxAuctionService?: string): number | null => {
  if (!offer.auction) {
    return null;
  }

  const userOfferService = getServiceName<IOffer>(offer);
  const isSameService = Boolean(userOfferService && offer[userOfferService as keyof IOffer]);

  if (isPersonalBet) {
    return userOfferService.toLowerCase() === `is${maxAuctionService}`
      ? EAuctionTooltipView.MANAGE
      : EAuctionTooltipView.NOT_PREMIUM;
  }

  switch (true) {
    // На этой странице есть объявление текущего пользователя
    // и у него такой же пакет услуг, как у текущего объявления
    case offer.auction.userOfferId && isSameService:
      return EAuctionTooltipView.AUCTION_ON_THIS_PAGE;

    // На этой странице есть объявление текущего пользователя,
    // но у него другой пакет услуг, как у текущего объявления
    case offer.auction.userOfferId && !isSameService:
      return EAuctionTooltipView.AUCTION_DIFFERENT_SERVICE;

    // На этой странице нет объявлений текущего пользователя
    default:
      return EAuctionTooltipView.AUCTION;
  }
};
