import { Button } from '@cian/ui-kit';
import { plural } from '@cian/utils/lib/shared/plural';
import * as React from 'react';

import { trackSearchButtonInAdvancedFilterClick } from './tracking';
import { trackGeoSuggestNoResults } from '../../../filters/components/geo_suggest/tracking';
import { numberToPrettyString } from '../../../utils/numberToPrettyString';

export interface ISearchButtonProps {
  count: number | undefined;
  loading: boolean;
  nextQueryString: string | undefined;
  userInput?: string;
  searchButtonVariants?: [string, string, string];
  searchButtonLabelText?: string;
  onClick(): void;
}

export class SearchButton extends React.Component<ISearchButtonProps> {
  public componentDidUpdate(prevProps: ISearchButtonProps) {
    const { count, userInput } = this.props;

    if (count === 0 && userInput && userInput !== prevProps.userInput) {
      /**
       * Отправляем в аналитику то что пользователь выбрал в гео саджесте
       * (кликом мышки/нажатием на enter) и это привело к нулевой выдаче
       * (до нажатия на кнопку найти)
       */
      trackGeoSuggestNoResults(userInput);
    }
  }

  public render() {
    const { count, loading, searchButtonLabelText = 'Показать объекты' } = this.props;
    const searchButtonVariants: [string, string, string] = this.props.searchButtonVariants || [
      'объект',
      'объекта',
      'объектов',
    ];

    const searchButtonLabel =
      typeof count !== 'undefined'
        ? `${count < 1 ? 'Найдено' : 'Показать'} ` +
          `${numberToPrettyString(count)} ${plural(count, searchButtonVariants)}`
        : searchButtonLabelText;

    return (
      <Button theme="fill_primary" size="XS" loading={loading} onClick={this.handleClick}>
        {searchButtonLabel}
      </Button>
    );
  }

  private handleClick = () => {
    const { loading, onClick, nextQueryString } = this.props;

    if (loading) {
      return;
    }

    const searchString = nextQueryString || '';

    trackSearchButtonInAdvancedFilterClick(searchString);

    onClick();
  };
}
