import { Button, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './NewbuildingBrokerBlock.css';

interface INewbuildingBrokerBlockProps {
  loading: boolean;
  onClick: () => void;
  buttonText: string;
  rewardTariffsLink: string;
}

export const NewbuildingBrokerBlock: React.FC<INewbuildingBrokerBlockProps> = ({
  loading,
  onClick,
  buttonText,
  rewardTariffsLink,
}) => {
  return (
    <div className={styles['wrapper']} data-testid="NewbuildingBrokerBlock">
      <div className={styles['container']}>
        <div className={styles['item']}>
          <UIText2 whiteSpace="pre-line" color="black_100">
            Заработайте от 0,7% комиссии
          </UIText2>
        </div>
        <a
          className={styles['link']}
          href={rewardTariffsLink}
          data-testid="NewbuildingBrokerLink"
          target="_blank"
          rel="noopener noreferrer"
        >
          Сколько можно заработать
        </a>
      </div>
      <div className={styles['button']}>
        <Button
          loading={loading}
          onClick={onClick}
          size="XS"
          theme="fill_success_primary"
          type="button"
          fullWidth
          data-testid="NewbuildingBrokerButton"
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
