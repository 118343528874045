/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { ISuggestionsDataSchema } from '../../entities/entities/SuggestionsDataSchema';

export type TSuggestModel = IModel<ISuggestRequest, TSuggestResponse>;

export interface ISuggestRequest {
  /**  Тип сделки  **/
  dealType?: EDealType | null;
  /**  Идет ли поиск коворкингов  **/
  isCoworkingSearch?: boolean | null;
  /** Идет ли поиск для посутки  **/
  isDailyRent?: boolean | null;
  /**  Тип недвижимости  **/
  offerType?: EOfferType | null;
  /**  Запрос пользователя  **/
  query?: string | null;
  /**  id региона  **/
  regionId?: number | null;
  /**  Откуда пришел запрос  **/
  source?: ESource | null;
}

export type TSuggestResponse = ISuggestResponse200 | ISuggestResponse400;

export interface ISuggestResponse200 extends IModelResponse<ISuggestResponse> {
  statusCode: 200;
}

export interface ISuggestResponse400 extends IModelResponse<ISuggestResponseError> {
  statusCode: 400;
}

export interface ISuggestResponse {
  /**  Данные в ответе  **/
  data: ISuggestionsDataSchema;
  /**  Статус ответа  **/
  status: string;
}

export interface ISuggestResponseError {
  errors: any[];
  message: string;
}

export type TResponses = ISuggestResponse | ISuggestResponseError;

export enum EDealType {
  /** Аренда **/
  Rent = 'rent',
  /** Покупка **/
  Sale = 'sale',
}

export enum EOfferType {
  /** Коммерческая **/
  Commercial = 'commercial',
  /** Квартиры **/
  Flat = 'flat',
  /** Новостройки **/
  Newobject = 'newobject',
  /** Загородка **/
  Suburban = 'suburban',
}

export enum ESource {
  /** Из коммерческой недвижимости **/
  IapMap = 'iapMap',
  /** С главной **/
  Mainpage = 'mainpage',
  /** С карты **/
  Map = 'map',
  /** С мобильного сайта **/
  MobileWebsite = 'mobileWebsite',
  /** Страница акций в новостройках **/
  NewbuildingPromosPage = 'newbuildingPromosPage',
  /** С поиска **/
  Serp = 'serp',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: ISuggestResponse): TResponse200;
  400(response: ISuggestResponseError): TResponse400;
}
