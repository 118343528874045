import * as React from 'react';

import * as styles from './FakeControlWrapper.css';

type TDivElementProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
type TFakeControlWrapperProps = Omit<TDivElementProps, 'className' | 'style'>;

export const FakeControlWrapper = React.forwardRef<HTMLDivElement, React.PropsWithChildren<TFakeControlWrapperProps>>(
  (props, ref) => {
    return <div className={styles['wrapper']} ref={ref} {...props} />;
  },
);

FakeControlWrapper.displayName = 'FakeControlWrapper';
