import * as React from 'react';

import { getTermValue, getRangeValue } from '../../../../../JsonQuery';
import { UrbanBathroom } from '../../../components/advancedFilters/UrbanBathroom';
import { EWCType } from '../../../types/jsonQuery';
import { useContext } from '../../../utils/useContext';

export const UrbanBathroomContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const urbanBathroomType = getTermValue('wc_type')(jsonQuery);
  const bathroomsCount = getRangeValue('wc')(jsonQuery);
  const bathroomsCountMin = (bathroomsCount && bathroomsCount.gte) || null;

  const handleBathroomTypeChange = React.useCallback(
    (urbanBathroomType: EWCType | null) => {
      onChange({ action: 'setUrbanBathroomType', arguments: [urbanBathroomType] });
    },
    [onChange],
  );

  const handleBathroomCountMinChange = React.useCallback(
    (bathroomCountMin: number | null) => {
      onChange({ action: 'setBathroomCountMin', arguments: [bathroomCountMin] });
    },
    [onChange],
  );

  return (
    <UrbanBathroom
      bathroomType={urbanBathroomType}
      bathroomsCountMin={bathroomsCountMin}
      onBathroomTypeChange={handleBathroomTypeChange}
      onBathroomsCountMinChange={handleBathroomCountMinChange}
    />
  );
};
