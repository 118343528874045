import { ca } from '@cian/analytics';

export function trackDealSaleBannerClick() {
  ca('eventSite', {
    action: 'Click',
    category: 'Listing',
    label: 'deal_banner',
    name: 'oldevent',
  });
}

export function trackDealSaleBannerShow() {
  ca('eventSite', {
    action: 'show_sopr',
    category: 'Listing',
    label: 'deal_banner',
    name: 'oldevent',
  });
}
