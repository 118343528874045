import { EFeature } from '../types/features';
import { TAvailabilityFunction } from '../types/filters';

export function isFeaturesAvailable(features: EFeature[]): TAvailabilityFunction {
  return ({ features: availableFeatures }) => features.every(f => availableFeatures.includes(f));
}

export function isVillageMortgageFilterAvailable(): TAvailabilityFunction {
  return ({ isVillageMortgageFilterEnabled }) => !!isVillageMortgageFilterEnabled;
}
