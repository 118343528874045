import { pathOr } from 'ramda';
import * as React from 'react';

import { ContainWords } from '../../../components/advancedFilters/ContainWords';
import { useContext } from '../../../utils/useContext';

export const ContainWordsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const containWords = pathOr<string[] | null>(null, ['description_include', 'value'], jsonQuery);

  const handleChange = React.useCallback(
    (containWords: string[] | null) => {
      onChange({ action: 'setContainWords', arguments: [containWords] });
    },
    [onChange],
  );

  return <ContainWords value={containWords} onChange={handleChange} />;
};
