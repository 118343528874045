import { numberToPrettyString, plural } from '@cian/utils';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackProfessionalSearchClick } from './tracking';
import { Footer } from '../../../components/AdvancedFiltersComponent/components/Footer';
import { selectIsProfessionalSearchPromoVisible, selectProfessionalSearchUrlWithFilters } from '../../../selectors';
import { useContext } from '../../../utils/useContext';

export interface IAdvancedFiltersFooterContainerProps {
  onClearClick(): void;
  onShowClick(): void;
}

export const AdvancedFiltersFooterContainer = ({ onClearClick, onShowClick }: IAdvancedFiltersFooterContainerProps) => {
  const { jsonQueryCount, jsonQueryCountRefreshing } = useContext();
  const professionalSearchUrl = useSelector(selectProfessionalSearchUrlWithFilters);
  const isProfessionalSearchButtonVisible = useSelector(selectIsProfessionalSearchPromoVisible);
  const searchButtonText = React.useMemo(() => {
    return `Показать ${
      typeof jsonQueryCount !== 'undefined'
        ? `${numberToPrettyString(jsonQueryCount)} ${plural(jsonQueryCount, ['объект', 'объекта', 'объектов'])}`
        : 'объекты'
    }`;
  }, [jsonQueryCount]);

  const handleProfessionalSearchClick = React.useCallback(() => {
    trackProfessionalSearchClick();
  }, []);

  return (
    <Footer
      searchButtonText={searchButtonText}
      isCounterRefreshing={jsonQueryCountRefreshing}
      onClearClick={onClearClick}
      onShowClick={onShowClick}
      professionalSearchUrl={professionalSearchUrl}
      isProfessionalSearchButtonVisible={isProfessionalSearchButtonVisible}
      onProfessionalSearchClick={handleProfessionalSearchClick}
    />
  );
};
