import classNames from 'classnames';
import * as React from 'react';

import * as styles from './Select.css';
import { NativeSelect } from './nativeSelect';
import { Option } from './option';
import { SelectContainer, ISelectActions } from './selectContainer';
import { SelectPopup } from './selectPopup';
import { ISelectProps, IOptionProps, ISelectOption } from './types';
import { getButtonLabel } from './utils/getButtonLabel';
import { getDropdownDirection, EDropdownDirection } from './utils/getDropdownDirection';

interface ISelectState {
  options: ISelectOption[];
  children?: React.ReactNode;
  upward: boolean;
}

const getOptionsFromChildren = (children: React.ReactNode): ISelectOption[] => {
  return React.Children.toArray(children).reduce(
    (options, child: React.ReactElement<IOptionProps>): ISelectOption[] => {
      if (child.type === Option) {
        return [
          ...options,
          {
            value: child.props.value,
            label: React.Children.toArray(child.props.children).join(''),
            marker: child.props.marker,
          },
        ];
      }

      return options as ISelectOption[];
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [] as any,
  );
};

export class Select extends React.Component<ISelectProps, ISelectState> {
  private ref = React.createRef<HTMLDivElement>();
  private nativeSelectRef = React.createRef<NativeSelect>();
  private selectPopupRef = React.createRef<SelectPopup>();
  private selectPopupContainerRef = React.createRef<HTMLDivElement>();

  public static defaultProps = {
    dataMark: 'Select',
  };

  private constructor(props: ISelectProps) {
    super(props);

    this.state = {
      options: getOptionsFromChildren(props.children),
      children: props.children,
      upward: false,
    };
  }

  public static getDerivedStateFromProps = ({ children }: ISelectProps, state: ISelectState) => {
    if (children === state.children) {
      return null;
    }

    return {
      options: getOptionsFromChildren(children),
      children,
    };
  };

  public componentDidUpdate(prevProps: ISelectProps) {
    if (this.props.open && !prevProps.open) {
      this.setPopupDirection();
    }
  }

  public render() {
    const { label, placeholder, value, disabled, multiple, styles: selectStyles, renderButton } = this.props;
    const { options } = this.state;

    return (
      <SelectContainer
        {...this.props}
        forwardedRef={this.ref}
        className={styles['select']}
        opened={!!this.props.open}
        actions={this.actions}
        style={selectStyles}
      >
        {renderButton({
          label: getButtonLabel({ label, value, options, placeholder }),
          disabled,
          onClick: this.handleButtonClick,
        })}

        <SelectPopup
          ref={this.selectPopupRef}
          forwardedRef={this.selectPopupContainerRef}
          className={classNames(styles['select-popup'], this.state.upward && styles['upward'])}
          open={this.props.open}
          multiple={multiple}
          value={value}
          options={options}
          setOptionState={this.handleSetOptionState}
          toggleOptionState={this.handleToggleOptionState}
          postfix={this.props.popupPostfix}
        />

        <NativeSelect
          ref={this.nativeSelectRef}
          multiple={multiple}
          disabled={disabled}
          value={value}
          onChange={this.props.onChange}
          options={options}
          className={styles['native-select']}
        />
      </SelectContainer>
    );
  }

  private openDropdown = () => {
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  };

  private closeDropdown = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  private handleButtonClick = () => {
    if (this.props.open) {
      this.closeDropdown();
    } else {
      this.openDropdown();
    }
  };

  private handleSetOptionState = (index: number, selected: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.nativeSelectRef.current!.setOptionState(index, selected);
    if (!this.props.multiple) {
      this.closeDropdown();
    }
  };

  private handleToggleOptionState = (index: number) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.nativeSelectRef.current!.toggleOptionState(index);
  };

  private setPopupDirection = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const direction = getDropdownDirection(this.ref.current!, this.selectPopupContainerRef.current!);
    const upward = direction === EDropdownDirection.Up;

    if (!upward !== !this.state.upward) {
      this.setState({
        upward,
      });
    }
  };

  private actions: ISelectActions = {
    openDropdown: this.openDropdown,
    closeDropdown: this.closeDropdown,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    moveFocus: (delta: -1 | 1) => this.selectPopupRef.current!.moveFocusIndex(delta),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setFocusedOptionState: (selected: boolean) => this.selectPopupRef.current!.setFocusedOptionState(selected),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    toggleFocusedOptionState: () => this.selectPopupRef.current!.toggleFocusedOptionState(),
  };
}
