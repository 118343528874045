import { parseCookies } from '@cian/utils/lib/shared/cookie';
import { groupBy, values, prop } from 'ramda';
import { v4 as uuid } from 'uuid';

export interface ITeaserEventProps {
  soprApi: string;
  user_id: number | null;
  announcement_id?: number;
  pagenumber: number;
  position: number;
  pageType: string;
  device_type: string;
  block_type: string;
  isTeaserShow: 1 | 0;
  sc: string;
}

const LIST_ID = uuid();

export const getViewportHeight = () => Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
export const getViewportWidth = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

export function sendTeaserEvent(events: Array<ITeaserEventProps>) {
  const viewport = {
    width: getViewportWidth(),
    height: getViewportHeight(),
  };
  const cid = parseCookies(document.cookie)['_CIAN_GK'];

  const uniqEventChunks = values(groupBy(prop('sc'), events));

  uniqEventChunks.forEach(items => {
    const firstEvent = items[0];
    const { soprApi, user_id, pageType, device_type, sc, pagenumber } = firstEvent;
    fetch(`${soprApi}teaser/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id,
        list_id: LIST_ID,
        device_type,
        viewport,
        cid,
        pageType,
        pagenumber,
        sc,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        items: items.map(({ soprApi, user_id, pageType, device_type, sc, pagenumber, ...props }) => props),
      }),
    });
  });
}
