import { ELandStatusType } from '../../../../json_query';
import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const ILandStatusTypesTagType = 'advanced-landStatus';

export interface ILandStatusTypesTag {
  type: 'advanced-landStatus';
  value: ELandStatusType;
}

function isLandStatusTypesTag(tag: TTag): tag is ILandStatusTypesTag {
  return tag.type === ILandStatusTypesTagType;
}

export const landStatusTypesTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.land_status && jsonQuery.land_status.value;

    let nextTags = tags.filter(
      tag => tag.type !== ILandStatusTypesTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: ELandStatusType) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is ILandStatusTypesTag => isLandStatusTypesTag(tag) && tag.value === value,
            () => ({ type: ILandStatusTypesTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
