import { IConsumer, IEvent, createConsumer, createSubscriber } from '@cian/events-log';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getComplaints } from '../../../../../../../../shared/actions/complain';
import { IAppState } from '../../../../../../../../shared/common/state';
import { IComplain } from '../../../../../../../../shared/types/complain';
import { TThunkDispatch } from '../../../../../../../../shared/types/redux';
import { useOfferContext } from '../../../../../context';

let consumer: IConsumer;

type UseModerationComplaintProps = {
  onCloseModal(): void;
};

//instanbul ignore next
export const useModerationComplaint = ({ onCloseModal }: UseModerationComplaintProps) => {
  const { isError, manifest } = useSelector<IAppState, IComplain>(store => store.complain);
  const { offer } = useOfferContext();

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<TThunkDispatch>();

  /* istanbul ignore next */
  const complaintsSubscriber = createSubscriber((event: IEvent<unknown>) => {
    if (event.type === 'popup_close') {
      onCloseModal();
    }
  });

  const effectHandler = (): void => {
    consumer ??= createConsumer({ topic: 'complaintsModal' });
    consumer.subscribe(complaintsSubscriber);
  };

  useEffect(() => {
    if (!manifest) {
      dispatch(getComplaints());
    } else {
      setIsLoading(false);
    }
    effectHandler();

    return () => {
      consumer.unsubscribe(complaintsSubscriber);
    };
  });

  const refetch = () => {
    setIsLoading(true);
    dispatch(getComplaints());
  };

  return {
    isLoading,
    refetch,
    manifest,
    offerId: offer.id,
    isError,
  };
};
