/* eslint-disable max-lines */
// В файле уже более 460+ строк, ожидает глобального рефакторинга серпа
import { ILogger } from '@cian/logger';
import { Spinner } from '@cian/ui-kit';
import { equals, takeLast } from 'ramda';
import * as React from 'react';

import { getOffers, getSuggestionIds, IInfiniteSearchResult, IQsToUris } from '../../../api/offers';
import { IDirectionsModalState } from '../../../common/state';
import { IOffer, IBreadcrumb } from '../../../offer/types';
import { fetchGeoTagsSafe } from '../../../services/fetchGeoTags';
import { IApplicationContext } from '../../../types/applicationContext';
import { TUser } from '../../../types/user';
import { IMakeRequest } from '../../../utils/request';
import { getSuggestionDistancesSeoText } from '../../../utils/seo_text';
import { ICoordinatesOffers, ISuggestionsDistancesState } from '../../state/suggestions';
import { IOffersDispatchProps, IOffersStateProps } from '../offers';
import { getOfferCardCreator, integrateAd } from '../offers/helpers';
import { trackMLSuggestionsShown } from '../offers/tracking';

const styles = require('./index.css');
const style = require('../offers/index.css');

export interface ISuggestionsStateProps extends IOffersStateProps {
  isPersonToPersonURL: boolean;
  logger: ILogger;
  makeRequest: IMakeRequest;
  searchOffersApiBaseUrl: string;
  suggestions: IOffer[] | null;
  suggestionsDistances: ISuggestionsDistancesState | undefined;
  suggestionDistancesSeoText: string | undefined;
  suggestionsQuery: string | null;
  queryString: string;
  directionsModal: IDirectionsModalState;
  breadcrumbs: IBreadcrumb[];
  mlRankingGuid: string | null;
  mlRankingModelVersion: string | null;
  user: TUser;
  soprApi: string;
  deviceType: string;
  subdomain: string;
}

export interface ISuggestionsDispatchProps extends IOffersDispatchProps {
  requestSuggestionsDistances(coordinatesOffers?: ICoordinatesOffers[]): void;
  setQsToUris(qsToUris: IQsToUris): void;
  setSuggestionDistancesSeoText(text: string): void;
}

export interface IOwnProps {
  openChat(offer: IOffer, position: number): void;
  openNotAvailablePopup(offer: IOffer, position: number): void;
}

export type TSuggestionsProps = ISuggestionsDispatchProps & ISuggestionsStateProps & IOwnProps;

interface ISuggestionsState {
  isVisibleButtonShowMore: boolean;
  isLoading: boolean;
  additionalSuggestions: IOffer[];
  additionalSuggestionsPage: number;
  modelVersion: number;

  nextStepSuggestions: number[];
}

const defaultState = {
  isVisibleButtonShowMore: false,
  isLoading: false,
  additionalSuggestions: [],
  additionalSuggestionsPage: 1,
  nextStepSuggestions: [],
  modelVersion: 0,
};

export class Suggestions extends React.Component<
  TSuggestionsProps & { context: IApplicationContext },
  ISuggestionsState
> {
  private visibleSuggestions: IOffer[];

  public state: ISuggestionsState = { ...defaultState };

  public UNSAFE_componentWillReceiveProps(nextProps: TSuggestionsProps) {
    /**
     * Если this.props.suggestions и nextProps.suggestions не равны -
     * произошло событие поиска, и бесконечную выдачу добивок нужно сбросить в дефолтное состояние.
     * (Отображаем 25 добивок из апи search-offers-desktop + кнопка показать еще 25)
     */
    if (!equals(this.props.suggestions, nextProps.suggestions)) {
      this.setState({
        ...defaultState,
      });
      //запрашиваем похожие оферы на шаг вперед чтобы знать показывать ли кнопку "Показать еще"
      this.getNextStepSuggestions();

      const { additionalSuggestionsPage } = defaultState;

      this.handleTracking(nextProps.suggestions, additionalSuggestionsPage - 1, nextProps);
    }
  }

  public componentDidUpdate(prevProps: TSuggestionsProps, prevState: ISuggestionsState) {
    const currentShownSuggestions = this.state.additionalSuggestions;
    const prevShownSuggestions = prevState.additionalSuggestions;
    const { requestSuggestionsDistances, suggestions } = this.props;

    if (
      (suggestions !== prevProps.suggestions || currentShownSuggestions !== prevShownSuggestions) &&
      !equals(defaultState, this.state)
    ) {
      const { suggestions } = prevProps;
      const { additionalSuggestionsPage } = this.state;

      const differenceCount = currentShownSuggestions.length - prevShownSuggestions.length;
      const newSuggestions = takeLast(differenceCount, currentShownSuggestions);

      this.handleTracking(
        suggestions,
        additionalSuggestionsPage - 1,
        this.props,
        [...(suggestions || []), ...currentShownSuggestions],
        newSuggestions,
      );
    }

    if (suggestions && suggestions.length && !equals(suggestions, prevProps.suggestions)) {
      this.getSuggestionDistancesSeoText();
      requestSuggestionsDistances();
    }
  }

  public componentDidMount() {
    const { suggestions, isPersonToPersonURL, requestSuggestionsDistances } = this.props;
    const { additionalSuggestionsPage } = this.state;

    //запрашиваем похожие оферы на шаг вперед чтобы знать показывать ли кнопку "Показать еще"
    this.getNextStepSuggestions();

    /**
     * Заправшиваем расстояние до текущего гео только на нулевых выдачах
     */
    if (suggestions && suggestions.length > 0 && isPersonToPersonURL) {
      requestSuggestionsDistances();
    }

    /**
     * Первый показ добивок (которые пришли с бекенда внутри search-offers-desktop)
     * отправляем в аналитику с page_num=0
     */
    if (this.visibleSuggestions) {
      this.handleTracking(suggestions, additionalSuggestionsPage - 1, this.props);
    }
  }

  public render() {
    const {
      currentPageNumber,
      isPrintEnabled,
      offers,
      offersPerPage,
      suggestions,
      jsonQuery,
      hideOffer: { hiddenOffers, hiddenSuggestions },
      mlRankingGuid,
      mlRankingModelVersion,
      user,
      servicesBannerPositions,
    } = this.props;

    this.visibleSuggestions = suggestions && suggestions.length > 0 ? suggestions : [];

    const offerCardCreatorProps = {
      ...this.props,
    };

    if (this.visibleSuggestions.length > 0) {
      const { isLoading, additionalSuggestions, additionalSuggestionsPage, isVisibleButtonShowMore } = this.state;

      const suggestionsWithAd = integrateAd({
        currentPageNumber,
        perPage: offersPerPage,
        jsonQuery,
        offers: this.getSuggestionsWithDistances(this.visibleSuggestions),
        offerCardCreator: getOfferCardCreator({
          props: offerCardCreatorProps,
          isSimilar: true,
          isSimilarFromML: true,
          pageNum: additionalSuggestionsPage,
        }),
        isPrintEnabled,
        positionOffset: offers.length,
        suggestionFragment: { isSimilar: true, startIndex: offers.length },
        hiddenOffers,
        hiddenSuggestions,
        mlRankingGuid,
        mlRankingModelVersion,
        isAgent: user.isAuthenticated && user.isAgent,
        keyPrefix: 'suggestions',
        servicesBannerPositions,
      });

      const additionalSuggestionsWithAd = integrateAd({
        currentPageNumber: additionalSuggestionsPage,
        perPage: offersPerPage,
        jsonQuery,
        offers: this.getSuggestionsWithDistances(additionalSuggestions),
        offerCardCreator: getOfferCardCreator({
          props: offerCardCreatorProps,
          isSimilar: true,
          isSimilarFromML: true,
          pageNum: additionalSuggestionsPage,
        }),
        isPrintEnabled,
        positionOffset: 0,
        suggestionFragment: { isSimilar: true, startIndex: offers.length + this.visibleSuggestions.length },
        hiddenOffers,
        hiddenSuggestions,
        mlRankingGuid,
        mlRankingModelVersion,
        isAgent: user.isAuthenticated && user.isAgent,
        keyPrefix: 'additional',
        servicesBannerPositions,
      });

      return (
        <div className={style['wrapper']}>
          <h3 className={style['title']}>
            {offers.length ? 'Дополнительные предложения по вашему запросу' : 'Похожие предложения'}
          </h3>

          {suggestionsWithAd}

          {additionalSuggestionsWithAd}

          {isLoading && (
            <div className={styles['preloader-container']}>
              <Spinner size={32} />
            </div>
          )}

          {!isLoading && isVisibleButtonShowMore ? (
            <div className={style['moreSuggestionsButtonContainer']}>
              <a href="#" className={styles['more-button']} onClick={event => this.handleLoadMoreClick(event)}>
                Показать ещё
              </a>
            </div>
          ) : null}
        </div>
      );
    }

    return null;
  }

  private getSuggestionsWithDistances = (suggestions: IOffer[]): IOffer[] => {
    const { suggestionsDistances } = this.props;

    if (suggestionsDistances && suggestions.length > 0) {
      return suggestions.map(suggest => {
        const distaces =
          suggestionsDistances.data && suggestionsDistances.data.find(distance => distance.offerId === suggest.id);

        return distaces ? { ...suggest, suggestionDistances: distaces.distance } : suggest;
      });
    }

    return suggestions;
  };

  private handleTracking = (
    suggestions: IOffer[] | null,
    pageNumber: number,
    props: TSuggestionsProps,
    allSuggestions?: IOffer[] | null,
    addedSuggestions?: IOffer[],
  ) => {
    if (suggestions && suggestions.length > 0) {
      const { jsonQuery, breadcrumbs, queryString, offersQty, user, mlRankingGuid, mlRankingModelVersion } = props;

      const offerWithModelVersion = (addedSuggestions || suggestions || []).find(
        offer => typeof offer.modelVersion !== 'object',
      );

      const modelVersion = offerWithModelVersion ? offerWithModelVersion.modelVersion : null;

      trackMLSuggestionsShown({
        jsonQuery,
        recommendations: addedSuggestions || suggestions || [],
        allRecommendations: allSuggestions || [],
        modelVersion,
        pageNumber,
        breadcrumbs,
        queryString,
        offersQty,
        user,
        mlRankingGuid,
        mlRankingModelVersion,
      });
    }
  };

  private getAllOffersIds() {
    const { additionalSuggestions } = this.state;
    const { suggestions, offers } = this.props;

    /**
     * Собираем все id оферов отображаемых на выдаче в том числе:
     *  - офферы, которые пришли из search-offers
     *  - добивки, которые пришли из search-offers
     *  - добивки, которые подгрузили ajax'ом
     */
    const offersIds = offers.map(offer => offer.id);
    const suggestionsIds = suggestions ? suggestions.map(suggestion => suggestion.id) : [];
    const additionalSuggestionsIds = additionalSuggestions.map(additionalSuggestion => additionalSuggestion.id);

    return [...offersIds, ...suggestionsIds, ...additionalSuggestionsIds];
  }

  private getNextStepSuggestions() {
    const { additionalSuggestionsPage } = this.state;
    const { makeRequest, searchOffersApiBaseUrl, jsonQuery, queryString } = this.props;

    const allVisibleOffersIds = this.getAllOffersIds();

    getSuggestionIds(makeRequest, searchOffersApiBaseUrl, {
      jsonQuery,
      offerIds: allVisibleOffersIds,
      pageNumber: additionalSuggestionsPage,
      queryString,
    })
      .then((response: IInfiniteSearchResult[]) => {
        const nextStepSuggestions = response.map(searchResult => searchResult.itemId);
        if (nextStepSuggestions.length) {
          this.setState({ nextStepSuggestions, isVisibleButtonShowMore: true });
        }
      })
      .catch(() => {
        const dataError = new Error('Failed get-infinite-search-result-desktop');
        this.props.logger.error(dataError);
      });
  }

  private handleLoadMoreClick(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    const { additionalSuggestions, additionalSuggestionsPage, nextStepSuggestions } = this.state;
    const { makeRequest, searchOffersApiBaseUrl, jsonQuery, queryString, setQsToUris } = this.props;

    const allVisibleOffersIds = this.getAllOffersIds();
    const nextAllVisibleOffersIds = [...allVisibleOffersIds, ...nextStepSuggestions];

    this.setState({
      isLoading: true,
    });

    const nextStepPromise = getSuggestionIds(makeRequest, searchOffersApiBaseUrl, {
      jsonQuery,
      /**
       * В api отправляем крайние 100 id отображаемых офферов
       */
      offerIds: nextAllVisibleOffersIds.length > 100 ? takeLast(100, nextAllVisibleOffersIds) : nextAllVisibleOffersIds,
      pageNumber: additionalSuggestionsPage + 1,
      queryString,
    });
    Promise.all([nextStepPromise, getOffers(makeRequest, searchOffersApiBaseUrl, nextStepSuggestions, jsonQuery)])
      .then(([nextStepData, data]) => {
        const { offersSerialized: offers, qsToUris } = data;
        const newNextStepSuggestionsIds = nextStepData.map(nextStepData => nextStepData.itemId);
        const showButton = newNextStepSuggestionsIds.length > 0;

        const newModelVersion = nextStepData && nextStepData.length > 0 ? nextStepData[0].modelVersion : null;

        if (newModelVersion) {
          this.setState({ modelVersion: newModelVersion });
        }

        setQsToUris(qsToUris);

        this.setState(
          {
            additionalSuggestions: [
              ...additionalSuggestions,
              ...offers.map(offer => ({ ...offer, modelVersion: newModelVersion || this.state.modelVersion })),
            ],
            additionalSuggestionsPage: additionalSuggestionsPage + 1,
            isLoading: false,
            nextStepSuggestions: [...newNextStepSuggestionsIds],
            isVisibleButtonShowMore: showButton,
          },
          () => {
            const { additionalSuggestions } = this.state;

            const coordinatesOffers = additionalSuggestions
              .map(suggestion => {
                const { geo } = suggestion;

                if (!geo) {
                  return null;
                }

                return {
                  offerId: suggestion.id,
                  coordinates: {
                    lat: geo.coordinates.lat,
                    lng: geo.coordinates.lng,
                  },
                };
              })
              .filter(Boolean);

            if (coordinatesOffers.length > 0) {
              this.props.requestSuggestionsDistances(coordinatesOffers as ICoordinatesOffers[]);
            }
          },
        );
      })
      .catch(() => {
        const { logger } = this.props;

        const dataError = new Error(
          `Failed to get additional suggestions info,
        suggestions ids: ${nextStepSuggestions}
        next suggestions ids: ${nextAllVisibleOffersIds}`,
        );
        logger.error(dataError);

        this.setState({
          isLoading: false,
        });
      });
  }

  private async getSuggestionDistancesSeoText() {
    let suggestionDistancesSeoText = '';
    const { context, subdomain, jsonQuery, setSuggestionDistancesSeoText } = this.props;
    const directions = this.props.directionsModal;

    try {
      const geoTags = await fetchGeoTagsSafe(context, { subdomain, jsonQuery });

      const isPersonToPersonURL = window.location.pathname !== '/cat.php';
      /**
       * Если есть добивки, текущая страница это ЧПУ, у нас есть данные для гео тэга и
       * это выдача без объявлений - пытаемся получить гео текст из текущей ЧПУ
       */
      if (isPersonToPersonURL) {
        suggestionDistancesSeoText = getSuggestionDistancesSeoText(geoTags, directions && directions.data);
      }
      setSuggestionDistancesSeoText(suggestionDistancesSeoText);
    } catch (ex) {
      setSuggestionDistancesSeoText('');
    }
  }
}
