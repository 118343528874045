import classNames from 'classnames';
import * as React from 'react';

import * as styles from './Note.css';

interface INoteProps {
  beforeIcon?: React.ReactNode;
  theme?: 'danger';
}

export const Note: React.FC<React.PropsWithChildren<INoteProps>> = ({ theme, beforeIcon, children }) => {
  return (
    <div className={classNames(styles['note'], theme === 'danger' && styles['danger'])}>
      {beforeIcon && <div className={styles['icon']}>{beforeIcon}</div>}
      <div className={styles['cont']}>{children}</div>
    </div>
  );
};
