import { IconFunctionalWarningSign16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import styles from './PessimizationFlexible.css';
import {
  EBackground,
  IWarningMessageSchema,
} from '../../../../../shared/repositories/search-offers.legacy/v2/types/WarningMessageSchema';
import { Note } from '../Note';

export interface IPessimizationFlexibleProps {
  warningMessage: IWarningMessageSchema;
}

export const PessimizationFlexible = ({ warningMessage }: IPessimizationFlexibleProps) => {
  const { title, text, background, helpInfo } = warningMessage;
  const isStrong = background === EBackground.Red;

  return (
    <Note
      beforeIcon={<IconFunctionalWarningSign16 color={isStrong ? 'error_100' : 'gray_icons_100'} />}
      theme={isStrong ? 'danger' : undefined}
    >
      {title && <div className={styles['title']}>{title}</div>}
      {text && (
        <div>
          {text}
          {helpInfo && (
            <a href={`${helpInfo.helpInfoLink}`} target="_blank" rel="noreferrer">
              {`${helpInfo.helpInfoText}`}
            </a>
          )}
        </div>
      )}
    </Note>
  );
};

PessimizationFlexible.displayName = 'PessimizationFlexible';
