import React from 'react';

import styles from './SpecialGeo.css';

interface ISpecialGeoProps {
  url: string;
  label: string;
  icon: React.ReactNode;
  remoteness?: string;
  onClick(e: React.SyntheticEvent<HTMLElement>): void;
}

export function SpecialGeo({ icon, label, url, remoteness, onClick }: ISpecialGeoProps) {
  return (
    <div className={styles['container']}>
      <a className={styles['link']} href={url} target="_blank" onClick={onClick} rel="noreferrer">
        <div className={styles['icon']}>{icon}</div>
        <div className={styles['name']}>{label}</div>
      </a>
      {remoteness && <div className={styles['remoteness']}>{remoteness}</div>}
    </div>
  );
}
