import { TAvailableModifierActions } from '../../types/modifier';

export const EXCLUDED_ADVANCED_FILTERS_TOOLTIP_ACTIONS: TAvailableModifierActions[] = [
  'selectGeoFake',
  'setAreaMax',
  'setAreaMin',
  'setBedroomsCountMax',
  'setBedroomsCountMin',
  'setDealType',
  'setJsonQuery',
  'setKPIdFake',
  'setLandAreaMax',
  'setLandAreaMin',
  'setOfferType',
  'setPriceMax',
  'setPriceMin',
  'setPriceType',
  'setRoomTypes',
  'setBeds',
  'setDates',
  'setVillageMortgageAllowed',
];
