import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackMortgageBrokerBannerClick, trackMortgageBrokerBannerShow } from './tracking';
import { configSelector } from '../../../selectors/configSelector';
import { MortgageBrokerBanner } from '../../components/MortgageBrokerBanner';

/**
 * Баннер "Циан.Ипотека для профи"
 */
export const MortgageBrokerBannerContainer = () => {
  const config = useSelector(configSelector);
  const url = config.get<string>('mortgage-broker-application-frontend.promoUrl') || '';

  return (
    <ActionAfterViewObserver callback={trackMortgageBrokerBannerShow} percentVisible={0.8} triggerOnce>
      <MortgageBrokerBanner url={url} onClick={trackMortgageBrokerBannerClick} />
    </ActionAfterViewObserver>
  );
};

MortgageBrokerBannerContainer.displayName = 'MortgageBrokerBannerContainer';
