import React from 'react';
import { useSelector } from 'react-redux';

import { SeoCrossLinksContainer as SeoCrossLinksContainerNew } from './SeoCrossLinksContainerNew';
import { SeoCrossLinksContainer as SeoCrossLinksContainerOld } from './SeoCrossLinksContainerOld';
import { selectIsSeoCrossLinksV2Enabled } from '../../../selectors/seoCrossLinks';

export function SeoCrossLinksContainer() {
  const isSeoCrossLinksV2Enabled = useSelector(selectIsSeoCrossLinksV2Enabled);

  if (isSeoCrossLinksV2Enabled) {
    return <SeoCrossLinksContainerNew />;
  }

  return <SeoCrossLinksContainerOld />;
}
