import React, { useCallback } from 'react';

import { trackSuburbanMultiOfferClick } from './tracking';
import { SimilarLink } from '../../../components/SimilarLink';
import { useOfferContext } from '../../../context';

export function SimilarLinkContainer() {
  const { similarLink, offer, tracking, position } = useOfferContext();

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>): void => {
      e.stopPropagation();

      const { kp, gaLabel } = offer;

      if (gaLabel) {
        if (kp) {
          trackSuburbanMultiOfferClick(kp.id, gaLabel, position);
        } else {
          tracking.trackMultiOfferClick(gaLabel);
        }
      }
    },
    [offer, tracking, position],
  );

  if (!similarLink) {
    return null;
  }

  return <SimilarLink {...similarLink} onClick={handleClick} />;
}
