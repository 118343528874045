import { MOSCOW_WITH_SPB_AND_REGIONS } from '../../../../constants/regions';
import { selectVillagesLink } from '../../../../selectors';
import { isInRegionSelector } from '../../../../selectors/isInRegion';
import { TSuburbanCatalog } from '../../../../types/suburbanCatalog';
import { isSuburban, offerTypeFromJsonQuery } from '../../../../utils/category';
import { IAppState } from '../../../state';

export function getSuburbanCatalogsTypes(state: IAppState): TSuburbanCatalog[] {
  const offerType = offerTypeFromJsonQuery(state.results.jsonQuery);
  const villagesLink = selectVillagesLink(state);
  const showBuildersCatalog = isInRegionSelector(MOSCOW_WITH_SPB_AND_REGIONS)(state);
  const isSuburbanListing = isSuburban(offerType);

  const catalogs: TSuburbanCatalog[] = [];

  if (!isSuburbanListing) {
    return catalogs;
  }

  if (villagesLink) {
    catalogs.push('villages');
  }

  if (showBuildersCatalog) {
    catalogs.push('builders');
  }

  return catalogs;
}
