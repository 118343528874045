import { IResidentialComplexInfo } from '../../../../types/residentialComplexInfo';

export const getDeveloperContactsLabel = (newbuildingInfo: IResidentialComplexInfo | null): string => {
  let moId = '';
  let oblId = '';
  let cityId = '';
  let newbuildingId = '';

  if (newbuildingInfo) {
    if (newbuildingInfo.trackingData) {
      moId = String(newbuildingInfo.trackingData.moId);
      oblId = String(newbuildingInfo.trackingData.oblId);
      cityId = String(newbuildingInfo.trackingData.cityId);
    }
    newbuildingId = String(newbuildingInfo.id);
  }

  return (
    `/sale/jk/mo_id=${moId}/obl_id=${oblId}/city_id=${cityId}` +
    `/object_type=2/ga_obj_type=0/spec=none/${newbuildingId}/from_developer=1/nv=1/`
  );
};
