import * as React from 'react';

import { ELandStatusType } from '../../../../json_query';
import { ILandStatusTypesTag } from '../../../state/tags/definitions/land_status';
import { TagNew } from '../TagNew';

export interface ILandStatusTypesTagItemProps {
  tag: ILandStatusTypesTag;
  onDeleteClick(): void;
}

const landStatusTypeMap = {
  [ELandStatusType.Farm]: 'Фермерское хозяйство',
  [ELandStatusType.PrivateFarm]: 'Личное подсобное хозяйство',
  [ELandStatusType.Gardening]: 'Садоводство',
  [ELandStatusType.IndustrialLand]: 'Земля промназначения',
  [ELandStatusType.InvestmentProject]: 'Инвестпроект',
  [ELandStatusType.IndividualHousingConstruction]: 'ИЖС',
  [ELandStatusType.CountryhouseNonCommercialPartnership]: 'ДНП',
};

export const LandStatusTypesTagItem = ({ onDeleteClick, tag }: ILandStatusTypesTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>{landStatusTypeMap[tag.value]}</TagNew>;
};
