import classNames from 'classnames';
import * as React from 'react';

const style = require('./index.css');

export interface IInlineElement {
  children?: React.ReactNode;
  className?: string;
}

export const InlineBlock: React.FC<IInlineElement> = ({ children, className }) => (
  <div className={classNames(style['inlineBlock'], className)}>{children}</div>
);

export const InlineLabel: React.FC<IInlineElement> = ({ children, className }) => (
  <div className={classNames(style['inlineLabel'], className)}>{children}</div>
);
