import * as React from 'react';

import * as styles from './Header.css';

export const Header: React.FC = () => {
  return (
    <div className={styles['container']} title="Нужно больше фильтров">
      Ещё фильтры
    </div>
  );
};
