import { Checkbox, SelectDesktop } from '@cian/ui-kit';
import * as React from 'react';

import { EMaxPrepay } from '../../../types/jsonQuery';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';

interface IPrepayOption {
  label: string;
  value: EMaxPrepay;
}

const PREPAY_OPTIONS: IPrepayOption[] = [
  { label: 'Неважно', value: EMaxPrepay.Any },
  { label: 'Не более 1 месяца', value: EMaxPrepay.Month },
  { label: 'Не более 2 месяцев', value: EMaxPrepay.TwoMonths },
  { label: 'Не более 3 месяцев', value: EMaxPrepay.ThreeMonths },
];

interface IRentTermsProps {
  prepay: EMaxPrepay | null;
  withDeposit: boolean | null;
  commissionType: number | null;
  onPrepayChange(value: number | null): void;
  onWithDepositChange(value: boolean | null): void;
  onCommissionTypeChange(value: number | null): void;
}

export const RentTerms: React.FC<IRentTermsProps> = ({
  prepay,
  withDeposit,
  commissionType,
  onPrepayChange,
  onWithDepositChange,
  onCommissionTypeChange,
}) => {
  return (
    <Filter id="advancedFilter_rentTerms" label="Условия сделки">
      <InlineFilterGroup>
        <InlineFilter prefix="Предоплата">
          <SelectDesktop
            inline
            options={PREPAY_OPTIONS}
            value={prepay !== null ? prepay : EMaxPrepay.Any}
            onChange={(value: EMaxPrepay) => onPrepayChange(value === EMaxPrepay.Any ? null : value)}
          />
        </InlineFilter>
        <InlineFilter>
          <Checkbox
            label="Без залога"
            checked={withDeposit === false}
            onChange={event => onWithDepositChange(event.target.checked ? false : null)}
          />
        </InlineFilter>
        <InlineFilter>
          <Checkbox
            label="Без комиссии"
            checked={commissionType === 0}
            onChange={event => onCommissionTypeChange(event.target.checked ? 0 : null)}
          />
        </InlineFilter>
      </InlineFilterGroup>
    </Filter>
  );
};
