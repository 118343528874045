import * as React from 'react';

import { ESuburbanOfferFilter } from '../../../../json_query';
import { IPossessorTag } from '../../../state/tags/definitions/possessor';
import { TagNew } from '../TagNew';

export interface ISuburbanHouseTypeTagItemProps {
  tag: IPossessorTag;
  onDeleteClick(): void;
}

export const PossessorTagItem: React.VFC<ISuburbanHouseTypeTagItemProps> = ({ tag, onDeleteClick }) => {
  return (
    <TagNew onDeleteClick={onDeleteClick}>
      {tag.value === ESuburbanOfferFilter.Builder ? 'От застройщика' : 'От собственников и агентов'}
    </TagNew>
  );
};
