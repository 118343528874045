import { getMultiSelectionParamTagItem } from './fabrics/multi_selection_param_tag';
import { EGarageMaterial } from '../../../../json_query';
import { IGarageMaterialsTag } from '../../../state/tags/definitions/garage_material';

const garageMaterialsMap = {
  [EGarageMaterial.Metal]: 'Металлический',
  [EGarageMaterial.Brick]: 'Кирпичный',
};

export const GarageMaterialsTagItem = getMultiSelectionParamTagItem<EGarageMaterial, IGarageMaterialsTag>(
  garageMaterialsMap,
);
