import { plural } from '@cian/utils';

import { IOffer } from '../../../types/offer';
import { isSuburban } from '../category/isSuburban';
import { isSuburbanSale } from '../category/isSuburbanSale';

export function getFloorsForSuburban(offer: IOffer): string | undefined {
  if (isSuburbanSale(offer)) {
    return undefined;
  }
  if (isSuburban(offer) && offer.building && offer.building.floorsCount) {
    return `${offer.building.floorsCount} ${plural(offer.building.floorsCount, ['этаж', 'этажа', 'этажей'])}`;
  }

  return undefined;
}
