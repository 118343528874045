import * as React from 'react';

import { IFurnitureTag } from '../../../state/tags/definitions/furniture';
import { TagNew } from '../TagNew';

export interface IFurnitureTagItemProps {
  tag: IFurnitureTag;
  onDeleteClick(): void;
}

export const FurnitureTagItem = ({ tag, onDeleteClick }: IFurnitureTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>{tag.value ? 'С мебелью' : 'Без мебели'}</TagNew>;
};
