import { getMultiSelectionParamTagItem } from './fabrics/multi_selection_param_tag';
import { EGarageKind } from '../../../../json_query';
import { IGarageKindsTag } from '../../../state/tags/definitions/garage_kind';

const garageKindsMap = {
  [EGarageKind.Builtin]: 'Встроенный',
  [EGarageKind.Capital]: 'Капитальный',
  [EGarageKind.Selfbuilt]: 'Самострой',
  [EGarageKind.Shell]: 'Ракушка',
};

export const GarageKindsTagItem = getMultiSelectionParamTagItem<EGarageKind, IGarageKindsTag>(garageKindsMap);
