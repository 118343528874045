import { IAppState } from '../../common/state';

export const getIsSnippetBrokerVisible = (state: IAppState) => {
  const partnerRewardsRegistrationStatus =
    state.newbuildingInformation?.newbuildingBrokerCommon?.partnerRewardsRegistrationStatus;
  const isCorrectPartnerRewardsRegistrationStatus =
    partnerRewardsRegistrationStatus === 'available' ||
    partnerRewardsRegistrationStatus === 'connected' ||
    partnerRewardsRegistrationStatus === 'inProgress';

  return isCorrectPartnerRewardsRegistrationStatus;
};
