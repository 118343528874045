import { Checkbox } from '@cian/ui-kit';
import * as React from 'react';

import { EWCType } from '../../../types/jsonQuery';
import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';

interface IBathroom {
  label: string;
  value: EWCType | null;
}

const BATHROOM_TYPES: IBathroom[] = [
  { label: 'Неважно', value: null },
  { label: 'Совмещённый', value: EWCType.United },
  { label: 'Раздельный', value: EWCType.Separated },
];

interface IBathroomProps {
  bathroomType: EWCType | null;
  bathroomsCountMin: number | null;
  onBathroomTypeChange(value: EWCType | null): void;
  onBathroomsCountMinChange(value: number | null): void;
}

export const UrbanBathroom: React.FC<IBathroomProps> = ({
  bathroomType,
  bathroomsCountMin,
  onBathroomTypeChange,
  onBathroomsCountMinChange,
}) => {
  return (
    <Filter id="advancedFilter_urbanBathroom" label="Санузел">
      <InlineFilterGroup>
        <InlineFilter>
          <RadioButtonGroup
            options={BATHROOM_TYPES}
            value={bathroomType}
            onChange={onBathroomTypeChange}
            spliced={false}
          />
        </InlineFilter>
        <InlineFilter>
          <Checkbox
            label="Два и более"
            checked={!!bathroomsCountMin && bathroomsCountMin > 1}
            onChange={event => onBathroomsCountMinChange(event.target.checked ? 2 : null)}
          />
        </InlineFilter>
      </InlineFilterGroup>
    </Filter>
  );
};
