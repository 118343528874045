import * as React from 'react';

import { getSuburbanBathroomOptions } from './helpers';
import { FDealType } from '../../../../../JsonQuery';
import { ESuburbanWCType } from '../../../types/jsonQuery';
import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';

interface IBathroomProps {
  value: ESuburbanWCType | null;
  onChange(value: ESuburbanWCType | null): void;
  dealType: FDealType;
}

export const SuburbanBathroom: React.FC<IBathroomProps> = ({ value, onChange, dealType }) => {
  const bathroomOptions = getSuburbanBathroomOptions(dealType);

  return (
    <Filter id="advancedFilter_suburbanBathroom" label="Санузел">
      <RadioButtonGroup options={bathroomOptions} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
