import { IMakeRequest } from '../utils/request';

export function fetchAddNewbuildingToFavorites(makeRequest: IMakeRequest, newbuildingId: number) {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/add-newbuilding-to-favorites/',
    parameters: {
      newbuildingId,
    },
    method: 'POST',
  });
}

export function fetchRemoveNewbuildingFromFavorites(makeRequest: IMakeRequest, newbuildingId: number) {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/remove-newbuilding-from-favorites/',
    parameters: {
      newbuildingId,
    },
    method: 'POST',
  });
}
