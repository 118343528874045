import { EBedrooms } from '../../../types/bedrooms';

export const MAP_GTE_TO_ENUM: Record<number, EBedrooms> = {
  1: EBedrooms.OneAndMore,
  2: EBedrooms.TwoAndMore,
  3: EBedrooms.ThreeAndMore,
  4: EBedrooms.FourAndMore,
  5: EBedrooms.FiveAndMore,
};

export const MAP_ENUM_TO_GTE: Record<EBedrooms, number> = {
  [EBedrooms.OneAndMore]: 1,
  [EBedrooms.TwoAndMore]: 2,
  [EBedrooms.ThreeAndMore]: 3,
  [EBedrooms.FourAndMore]: 4,
  [EBedrooms.FiveAndMore]: 5,
};
