import { plural } from '@cian/utils/lib/shared/plural';
import * as React from 'react';

import { IBreadcrumb } from '../../../../offer';
import { ERoomCountType, IDeveloperInfo } from '../../../../types/developerInfo';
import { numberToPrettyString } from '../../../../utils/numberToPrettyString';

const style = require('./index.css');

export interface IHeaderDeveloperProps {
  breadcrumbs: IBreadcrumb[];
  developerInfo: IDeveloperInfo;
}

export class HeaderDeveloper extends React.Component<IHeaderDeveloperProps> {
  public render() {
    const { developerInfo } = this.props;

    return (
      <div className={style.container}>
        <div itemScope className={style.wrapper} itemType="http://schema.org/BreadcrumbList">
          {this.props.breadcrumbs.length > 0 && (
            <div
              itemProp="itemListElement"
              itemType="http://schema.org/ListItem"
              itemScope
              className={style['breadcrumbs']}
            >
              {this.props.breadcrumbs.map((breadcrumb, index, arr) => {
                return (
                  <a
                    key={index}
                    href={breadcrumb.url || undefined}
                    title={breadcrumb.title || ''}
                    itemProp="url"
                    className={style['breadcrumbs-link']}
                  >
                    <span itemProp="name">{breadcrumb.title}</span>
                    {index < arr.length - 1 ? <div className={style['breadcrumbs-delimiter']} /> : null}
                  </a>
                );
              })}
            </div>
          )}
          <h1 className={style['title']}>
            Купить квартиру от застройщика{' '}
            <a href={developerInfo.url || undefined} target="_blank" rel="noreferrer">
              &laquo;{developerInfo.name}&raquo;
            </a>
          </h1>
          <div className={style['content']}>
            <div className={style['content-logotype']}>
              <a href={developerInfo.url || undefined} target="_blank" rel="noreferrer">
                <img src={developerInfo.logoUrl} alt={developerInfo.name} />
              </a>
            </div>
            <div className={style['content-main']}>
              <div className={style['content-info']}>
                &laquo;{developerInfo.name}&raquo;{developerInfo.since && ` на ЦИАН с ${developerInfo.since} г.`}
              </div>
              {developerInfo.newobjectsStats && developerInfo.newobjectsStats.length > 0 && (
                <div className={style['content-projects']}>
                  <div className={style['content-projectsLabel']}>Проектов в базе ЦИАН:</div>
                  <div className={style['content-projectsData']}>
                    <ul className={style['content-projectsList']}>
                      {developerInfo.newobjectsStats.map((stat, index) => (
                        <li key={`stat-${index}`}>
                          <a href={stat.url || undefined} target="_blank" rel="noreferrer">
                            {stat.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              <div className={style['content-description']}>
                <p>
                  Все новостройки от застройщика{' '}
                  <a href={developerInfo.newobjectsUrl || undefined}>{developerInfo.name}</a>: описание объектов,
                  фотографии, планировка, цены.
                  {developerInfo.flatsStat &&
                    developerInfo.flatsStat.flats &&
                    developerInfo.flatsStat.flats.length > 0 && (
                      <span>
                        В базе ЦИАН найдено{' '}
                        <a href={developerInfo.flatsStat.url || undefined}>
                          {developerInfo.flatsStat.total}{' '}
                          {plural(developerInfo.flatsStat.total || 0, ['квартира', 'квартиры', 'квартир'])}
                        </a>{' '}
                        от застройщика <a href={developerInfo.newobjectsUrl || undefined}>{developerInfo.name}</a>:{' '}
                        {this.renderFlatsList()}.
                        {developerInfo.flatsStat.minPrice &&
                          developerInfo.flatsStat.maxPrice &&
                          ` Минимальная цена квартиры - ${numberToPrettyString(
                            developerInfo.flatsStat.minPrice,
                          )} ${plural(developerInfo.flatsStat.minPrice, [
                            'рубль',
                            'рубля',
                            'рублей',
                          ])}, максимальная - ${numberToPrettyString(developerInfo.flatsStat.maxPrice)} ${plural(
                            developerInfo.flatsStat.maxPrice,
                            ['рубль', 'рубля', 'рублей'],
                          )}.`}
                      </span>
                    )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private renderFlatsList() {
    const flats = (this.props.developerInfo.flatsStat && this.props.developerInfo.flatsStat.flats) || [];
    if (flats.length === 0) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const flatsList: (React.ReactElement<any> | string)[] = [];

    const oneRoom = flats.find(flat => flat.roomCountType === ERoomCountType.OneRoom);
    if (oneRoom) {
      flatsList.push(
        <a key="flat-1" href={oneRoom.url || undefined}>
          однокомнатных - {oneRoom.count}
        </a>,
      );
    }

    const twoRooms = flats.find(flat => flat.roomCountType === ERoomCountType.TwoRoom);
    if (twoRooms) {
      flatsList.push(
        <a key="flat-2" href={twoRooms.url || undefined}>
          двухкомнатных - {twoRooms.count}
        </a>,
      );
    }

    const threeRooms = flats.find(flat => flat.roomCountType === ERoomCountType.ThreeRoom);
    if (threeRooms) {
      flatsList.push(
        <a key="flat-3" href={threeRooms.url || undefined}>
          трехкомнатных - {threeRooms.count}
        </a>,
      );
    }

    const fourRooms = flats.find(flat => flat.roomCountType === ERoomCountType.FourRoom);
    if (fourRooms) {
      flatsList.push(
        <a key="flat-4" href={fourRooms.url || undefined}>
          четырехкомнатных - {fourRooms.count}
        </a>,
      );
    }

    const fiveRooms = flats.find(flat => flat.roomCountType === ERoomCountType.FiveRoom);
    if (fiveRooms) {
      flatsList.push(
        <a key="flat-5" href={fiveRooms.url || undefined}>
          пятикомнатных - {fiveRooms.count}
        </a>,
      );
    }

    const multiRooms = flats.find(flat => flat.roomCountType === ERoomCountType.Large);
    if (multiRooms) {
      flatsList.push(
        <a key="flat-6" href={multiRooms.url || undefined}>
          многокомнатных - {multiRooms.count}
        </a>,
      );
    }

    const studio = flats.find(flat => flat.roomCountType === ERoomCountType.Studio);
    if (studio) {
      flatsList.push(
        <a key="flat-7" href={studio.url || undefined}>
          студий - {studio.count}
        </a>,
      );
    }

    const openPlan = flats.find(flat => flat.roomCountType === ERoomCountType.OpenPlan);
    if (openPlan) {
      flatsList.push(
        <a key="flat-8" href={openPlan.url || undefined}>
          свободной планировки - {openPlan.count}
        </a>,
      );
    }

    return flatsList.slice(1).reduce(
      (previousValue, currentValue) => {
        return previousValue.concat([', ', currentValue]);
      },
      [flatsList[0]],
    );
  }
}
