export function getCommercialLink(regionId?: number): string {
  /* istanbul ignore next */
  if (typeof window === 'undefined') {
    return '#';
  }

  const parsedSearchParams = new URLSearchParams(window.location.search);
  const newSearchParams = new URLSearchParams();

  const dealType = parsedSearchParams.get('deal_type');
  const region = parsedSearchParams.get('region') || regionId;

  if (dealType) {
    newSearchParams.set('deal_type', dealType);
  }

  newSearchParams.set('engine_version', '2');
  newSearchParams.set('offer_type', 'offices');
  newSearchParams.set('office_type[0]', '1');

  if (region) {
    newSearchParams.set('region', `${region}`);
  }

  return `/cat.php?${newSearchParams.toString()}`;
}
