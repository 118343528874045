import { ArticleHeading4, UIText2 } from '@cian/ui-kit';
import { IconActionChevronRight12 } from '@cian/ui-kit-design-tokens/icons';
import classNames from 'classnames';
import * as React from 'react';

import * as styles from './SuburbanCatalog.css';
import { ISuburbanCatalog } from '../../../../../types/suburbanCatalog';

type ISuburbanCatalogProps = ISuburbanCatalog;

export const SuburbanCatalog: React.VFC<ISuburbanCatalogProps> = ({ title, description, icon, link, onClick }) => {
  return (
    <a href={link} onClick={onClick} className={styles['container']} target="_blank" rel="noreferrer">
      <div className={classNames(styles['icon'], styles[icon])} />
      <div className={styles['text']}>
        <div className={styles['title']}>
          <ArticleHeading4>{title}</ArticleHeading4>
        </div>
        <UIText2 color="gray60_100">{description}</UIText2>
      </div>
      <div className={styles['arrow']}>
        <IconActionChevronRight12 color="primary_100" />
      </div>
    </a>
  );
};
