import { getTermValue } from './helpers';
import { EFacilityType, NonEmptyArray, TJsonQuery } from './types';

export function getBathTypes(jsonQuery: TJsonQuery): NonEmptyArray<EFacilityType> | null {
  const hasBath = getTermValue('bath')(jsonQuery);
  const hasShower = getTermValue('shower')(jsonQuery);

  const bathTypes: EFacilityType[] = [];

  if (hasBath) {
    bathTypes.push(EFacilityType.Bath);
  }

  if (hasShower) {
    bathTypes.push(EFacilityType.Shower);
  }

  return bathTypes.length > 0 ? (bathTypes as NonEmptyArray<EFacilityType>) : null;
}
