import { connect } from 'react-redux';

import { FitInOneLine } from './index';
import { IAppState, Dispatch } from '../../../../common/state';
import { fitInOneLine } from '../../../state/tags/fit_tags_in_one_line';

export const FitInOneLineContainer = connect(
  (state: IAppState) => {
    const { isFitInOneLineEnabled } = state.filters.tagsOptions;

    return {
      enabled: isFitInOneLineEnabled,
    };
  },
  (dispatch: Dispatch) => ({
    toggle: (value: boolean) => {
      dispatch(fitInOneLine(value));
    },
  }),
)(FitInOneLine);
