import * as React from 'react';

import { trackHouseTypeChoice } from './tracking';
import { getTermValue } from '../../../../../JsonQuery';
import { SuburbanHouseType } from '../../../components/advancedFilters/SuburbanHouseType';
import { useContext } from '../../../utils/useContext';

export const SuburbanHouseTypeContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const suburbanHouseType = getTermValue('is_dacha')(jsonQuery);

  const handleChange = React.useCallback(
    (isDacha: boolean | null) => {
      onChange({ action: 'setIsDacha', arguments: [isDacha] });

      trackHouseTypeChoice(isDacha);
    },
    [onChange],
  );

  return <SuburbanHouseType value={suburbanHouseType} onChange={handleChange} />;
};
