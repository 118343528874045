import { debounce } from 'throttle-debounce';

import {
  ERegionalDistrictsActionTypes,
  TAddRegionalDistrictAction,
  TApplyRegionalDistrictsAction,
  TCloseModalAction,
  TOpenModalAction,
  TRemoveRegionalDistrictAction,
  TSetRegionalDistrictsAction,
  TSetRegionalDistrictsErrorAction,
  TSetRegionalDistrictsReadyAction,
  TDebouncedUpdateMeta,
} from './types';
import {
  addRegionalDistrictToJsonQuery,
  getRegionalDistrictsFromJsonQuery,
  removeRegionalDistrictFromJsonQuery,
} from './utils';
import { Dispatch, IAppState, TActions } from '../../../common/state';
import { selectSelectedRegionalDistricts } from '../../../selectors/filters';
import { selectRegionalDistrictById } from '../../../selectors/filters/regionalDistricts/selectRegionalDistrictById';
import { getCurrentRegionId } from '../../../selectors/getCurrentRegionId';
import { fetchRegionalDistrictsByLocationSafe } from '../../../services/fetchRegionalDistrictsByLocation';
import { IApplicationContext } from '../../../types/applicationContext';
import { TThunkAction } from '../../../types/redux';
import {
  ERegionalDistrictsStatus,
  IRegionalDistrict,
  ISelectedRegionalDistrict,
} from '../../../types/regionalDistricts';
import { actionGenerator } from '../../../utils/redux';

export const closeRegionalDistrictsModal: () => TCloseModalAction =
  actionGenerator<ERegionalDistrictsActionTypes.CloseModal>(ERegionalDistrictsActionTypes.CloseModal);

const openModal: () => TOpenModalAction = actionGenerator<ERegionalDistrictsActionTypes.OpenModal>(
  ERegionalDistrictsActionTypes.OpenModal,
);

export const setRegionalDistricts: (districts: IRegionalDistrict[]) => TSetRegionalDistrictsAction = actionGenerator<
  ERegionalDistrictsActionTypes.SetRegionalDistricts,
  IRegionalDistrict[]
>(ERegionalDistrictsActionTypes.SetRegionalDistricts);

export const addRegionalDistrict: (district: ISelectedRegionalDistrict) => TAddRegionalDistrictAction = actionGenerator<
  ERegionalDistrictsActionTypes.AddRegionalDistrict,
  ISelectedRegionalDistrict
>(ERegionalDistrictsActionTypes.AddRegionalDistrict);

export const removeRegionalDistrict: (district: ISelectedRegionalDistrict) => TRemoveRegionalDistrictAction =
  actionGenerator<ERegionalDistrictsActionTypes.RemoveRegionalDistrict, ISelectedRegionalDistrict>(
    ERegionalDistrictsActionTypes.RemoveRegionalDistrict,
  );

export const applyRegionalDistricts: () => TApplyRegionalDistrictsAction =
  actionGenerator<ERegionalDistrictsActionTypes.ApplyRegionalDistricts>(
    ERegionalDistrictsActionTypes.ApplyRegionalDistricts,
  );

const setError: () => TSetRegionalDistrictsErrorAction =
  actionGenerator<ERegionalDistrictsActionTypes.SetRegionalDistrictsError>(
    ERegionalDistrictsActionTypes.SetRegionalDistrictsError,
  );

export const setRegionalDistrictsReady: () => TSetRegionalDistrictsReadyAction =
  actionGenerator<ERegionalDistrictsActionTypes.SetRegionalDistrictsReady>(
    ERegionalDistrictsActionTypes.SetRegionalDistrictsReady,
  );

const updateMeta: () => TDebouncedUpdateMeta = actionGenerator<ERegionalDistrictsActionTypes.DebouncedUpdateMeta>(
  ERegionalDistrictsActionTypes.DebouncedUpdateMeta,
);

export const debouncedUpdateMeta = debounce(800, (dispatch: Dispatch) => {
  dispatch(updateMeta());
});

export const openRegionalDistrictsModal = (): TThunkAction<Promise<void>> => {
  return async (dispatch, getState) => {
    const state = getState();
    const locationId = getCurrentRegionId(state);
    const { httpApi, logger } = state;
    const context = { httpApi, logger } as IApplicationContext;

    dispatch(openModal());

    const regionalDistricts: IRegionalDistrict[] = await fetchRegionalDistrictsByLocationSafe(context, { locationId });

    if (regionalDistricts.length) {
      dispatch(setRegionalDistricts(regionalDistricts));
    } else {
      dispatch(setError());
    }
  };
};

export const selectRegionalDistrict = (districtId: number): TThunkAction<Promise<void>> => {
  return async (dispatch, getState) => {
    const state = getState();
    const selectedDistricts = selectSelectedRegionalDistricts(state);
    const district = selectRegionalDistrictById(districtId)(state);

    if (selectedDistricts.includes(districtId)) {
      dispatch(removeRegionalDistrict(district));
    } else {
      dispatch(addRegionalDistrict(district));
    }

    debouncedUpdateMeta(dispatch);
  };
};

export function regionalDistrictsModalReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case ERegionalDistrictsActionTypes.OpenModal:
      return {
        ...state,
        filters: {
          ...state.filters,
          backup: {
            jsonQuery: state.filters.jsonQuery,
            tags: state.filters.tags,
          },
          regionalDistrictsModal: {
            ...state.filters.regionalDistrictsModal,
            isOpened: true,
            status: ERegionalDistrictsStatus.Loading,
            districts: [],
            selectedDistricts: getRegionalDistrictsFromJsonQuery(state.filters.jsonQuery.geo),
          },
        },
      };

    case ERegionalDistrictsActionTypes.CloseModal:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...state.filters.backup,
          backup: undefined,
          regionalDistrictsModal: {
            ...state.filters.regionalDistrictsModal,
            isOpened: false,
            status: ERegionalDistrictsStatus.Initial,
          },
        },
      };

    case ERegionalDistrictsActionTypes.SetRegionalDistricts:
      return {
        ...state,
        filters: {
          ...state.filters,
          regionalDistrictsModal: {
            ...state.filters.regionalDistrictsModal,
            status: ERegionalDistrictsStatus.Success,
            districts: action.payload,
          },
        },
      };

    case ERegionalDistrictsActionTypes.AddRegionalDistrict: {
      const { id, name } = action.payload;

      return {
        ...state,
        filters: {
          ...state.filters,
          tagsData: {
            ...state.filters.tagsData,
            regionalDistricts: {
              ...state.filters.tagsData.regionalDistricts,
              [id]: name,
            },
          },
          jsonQuery: {
            ...state.filters.jsonQuery,
            geo: addRegionalDistrictToJsonQuery(state.filters.jsonQuery.geo, id),
          },
          regionalDistrictsModal: {
            ...state.filters.regionalDistrictsModal,
            selectedDistricts: [
              ...state.filters.regionalDistrictsModal.selectedDistricts,
              { id, type: 'regional_district_id' },
            ],
          },
        },
      };
    }

    case ERegionalDistrictsActionTypes.RemoveRegionalDistrict: {
      const { id } = action.payload;

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            geo: removeRegionalDistrictFromJsonQuery(state.filters.jsonQuery.geo, id),
          },
          regionalDistrictsModal: {
            ...state.filters.regionalDistrictsModal,
            selectedDistricts: state.filters.regionalDistrictsModal.selectedDistricts.filter(value => value.id !== id),
          },
        },
      };
    }

    case ERegionalDistrictsActionTypes.ApplyRegionalDistricts:
      return {
        ...state,
        filters: {
          ...state.filters,
          backup: undefined,
          regionalDistrictsModal: {
            ...state.filters.regionalDistrictsModal,
            isOpened: false,
          },
        },
      };

    case ERegionalDistrictsActionTypes.SetRegionalDistrictsError:
      return {
        ...state,
        filters: {
          ...state.filters,
          regionalDistrictsModal: {
            ...state.filters.regionalDistrictsModal,
            status: ERegionalDistrictsStatus.Failed,
          },
        },
      };

    case ERegionalDistrictsActionTypes.SetRegionalDistrictsReady:
      return {
        ...state,
        filters: {
          ...state.filters,
          regionalDistrictsModal: {
            ...state.filters.regionalDistrictsModal,
            status: ERegionalDistrictsStatus.Ready,
          },
        },
      };

    default:
      return state;
  }
}
