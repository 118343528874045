import { ESuburbanOfferFilter } from '../../../../json_query';
import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const IPossessorTagType = 'advanced-possessor';

export interface IPossessorTag {
  type: 'advanced-possessor';
  value: ESuburbanOfferFilter;
}

export const isPossessorTag = (tag: TTag): tag is IPossessorTag => tag.type === IPossessorTagType;

export const possessorTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.suburban_offer_filter?.value;

    if (value) {
      return ensureSingleTagExists(tags, isPossessorTag, () => ({ type: IPossessorTagType, value }));
    } else {
      return ensureTagNotExists(tags, isPossessorTag);
    }
  },
};
