import { EPageTypes, EPlatformTypes } from '@cian/frontend-dynamic-calltracking-component';
import { SimpleCall, ISimpleCallProps } from '@cian/frontend-newbuilding-call-component';
import { IBuilder } from '@cian/frontend-newbuilding-call-component/es/types/builder';
import { ISeller } from '@cian/frontend-newbuilding-call-component/es/types/seller';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { getBuilders, getSellers } from '../../selectors/newbuildingHeader';
import { TNewbuildingCallPlaceType } from '../../types/newbuildingHeader';
import { useApplicationContext } from '../../utils/applicationContext';

interface IShowPhoneParams {
  realtyUserId: number;
  phone: string;
}

type TSimpleCallProps = Pick<ISimpleCallProps, 'openContactsStyles' | 'showPhoneStyles'>;

interface INewbuildingCallButtonContainerProps extends TSimpleCallProps {
  placeType: TNewbuildingCallPlaceType;
  onShowPhoneClick?(params: IShowPhoneParams): void;
}

export const NewbuildingCallButtonContainer: React.FC<INewbuildingCallButtonContainerProps> = ({
  placeType,
  openContactsStyles,
  showPhoneStyles,
  onShowPhoneClick,
}) => {
  const { logger, httpApi } = useApplicationContext();

  const builders = useSelector(getBuilders);
  const sellers = useSelector(getSellers);

  const pageType = EPageTypes.OfferList;
  const platformType = EPlatformTypes.WebDesktop;

  const handleShowPhoneClick = React.useCallback(
    (params: IShowPhoneParams) => {
      if (onShowPhoneClick) {
        onShowPhoneClick(params);
      }
    },
    [onShowPhoneClick],
  );

  if (!builders || !sellers) {
    return null;
  }

  return (
    <SimpleCall
      builders={builders as IBuilder[]}
      sellers={sellers as ISeller[]}
      onShowPhoneClick={handleShowPhoneClick}
      openContactsStyles={openContactsStyles}
      showPhoneStyles={showPhoneStyles}
      httpApi={httpApi}
      logger={logger}
      pageType={pageType}
      platformType={platformType}
      placeType={placeType}
    />
  );
};
