import * as React from 'react';

import * as styles from './SeoMarkedList.css';
import { SeoMarkedListItem } from './components/SeoMarkedListItem';
import { IMarkedListItem } from '../../repositories/search-offers.legacy/v2/search-offers-desktop.data';

interface ISeoMarkedListProps {
  items: IMarkedListItem[];
}

export const SeoMarkedList: React.VFC<ISeoMarkedListProps> = ({ items }) => (
  <div className={styles['container']}>
    {items.map((item, index) => {
      const id = `SeoMarkedListItem_${index}`;

      return <SeoMarkedListItem key={id} id={id} {...item} />;
    })}
  </div>
);
