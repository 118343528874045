import * as React from 'react';

import { TagNew } from '../TagNew';

export interface IPenthouseTagItemProps {
  onDeleteClick(): void;
}

export const PenthouseTagItem = ({ onDeleteClick }: IPenthouseTagItemProps) => {
  return <TagNew onDeleteClick={onDeleteClick}>Пентхаус</TagNew>;
};
