import { LinkButton } from '@cian/ui-kit';
import * as React from 'react';

import { getCaLabelByActionType, getTitleByActionType } from './helpers';
import { trackOnlineBookingBookButton } from './tracking';
import { useOfferContext } from '../../../../context';

export const DailyrentOnlineBookingButtonContainer = () => {
  const {
    trackingData: {
      page: { pageNumber, offersQty, queryString },
    },
    offer: { fullUrl, id, dailyrent },
    position,
  } = useOfferContext();

  const title = getTitleByActionType(dailyrent?.actionType);
  const caLabel = getCaLabelByActionType(dailyrent?.actionType);

  const onClick = React.useCallback(
    (evt: React.MouseEvent<HTMLAnchorElement>) => {
      if (!caLabel) {
        return;
      }

      evt.stopPropagation();
      trackOnlineBookingBookButton({
        id,
        position,
        offersQty,
        pageNumber,
        queryString,
        caLabel,
      });
    },
    [caLabel, id, position, offersQty, pageNumber, queryString],
  );

  return (
    <LinkButton
      href={fullUrl}
      onClick={onClick}
      fullWidth
      size="XS"
      role="button"
      theme="fill_primary"
      target="_blank"
      rel="noopener noreferrer"
    >
      {title}
    </LinkButton>
  );
};
