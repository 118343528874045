/* eslint-disable react/display-name */
import * as React from 'react';

import { TagNew } from '../../TagNew';

interface IMultiSelectionParamTagItemProps<TParamTag> {
  tag: TParamTag;
  paramsDescription?: string;
  onDeleteClick(): void;
}

export const getMultiSelectionParamTagItem =
  <TParamType, TParamTag extends { value: TParamType }>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    paramTypesNamesMap: any,
  ) =>
  (props: IMultiSelectionParamTagItemProps<TParamTag>) => {
    return (
      <TagNew onDeleteClick={props.onDeleteClick}>
        {paramTypesNamesMap[props.tag.value] + ' ' + (props.paramsDescription || '')}
      </TagNew>
    );
  };
